import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Col, Table, Row } from 'reactstrap'
import moment from 'moment'
import { useSelector } from 'react-redux'

import AccountService from '../../../network/services/account'
import { useTranslation } from 'react-i18next'

const MonitoringGraphical = () => {
  const { t } = useTranslation()
  const [currency, setCurrency] = useState()
  const [mt5, setMt5] = useState({})
  const [account, setAccount] = useState(null)
  const [count, setCount] = useState(0)
  const [profit, setProfit] = useState(0)
  const selectedAccount = useSelector((state) => state.monitoring.account)
  const currencies = useSelector((state) => state.currency.currencies)

  const fetchAccount = async (id) => {
    const result = await AccountService.get(id)
    // console.log(result)
    setAccount(result.account)
    if (result.mt5) {
      setMt5(result.mt5)
    }
  }

  const fetchSummary = async (id) => {
    const result = await AccountService.getOpenOrdersCount(id)
    const profit = await AccountService.getAccountProfit(id)
    setCount(result.count)
    setProfit(profit.total)
  }

  useEffect(() => {
    let fCurrency = currencies.find((e) => e.id === selectedAccount.currency_id)
    setCurrency(fCurrency)

    fetchAccount(selectedAccount.id)
    // eslint-disable-next-line
  }, [selectedAccount])

  useEffect(() => {
    fetchSummary(selectedAccount.id)
    // eslint-disable-next-line
  }, [])

  return account != null ? (
    <Row>
      <Col lg={6}>
        <Card>
          <CardHeader style={{ padding: 20 }} className="bg-primary">
            <h5 className="text-center">{t("monitoring.accountStats")}</h5>
          </CardHeader>
          <CardBody style={{ padding: 16 }}>
            <Table borderless>
              <tbody>
                <tr>
                  <td className="text-right" width="50%">
                    {t("monitoring.currentBalance")} {currency?.name} :
                  </td>
                  <td>
                    {account?.balance === null ? '-' : `${account?.balance?.toFixed(2) ?? '-'}`}
                  </td>
                </tr>
                <tr>
                  <td className="text-right">{t("monitoring.currentEquity")} {currency?.name} :</td>
                  <td>{account?.equity === null ? '-' : `${account?.equity ?? '-'}`}</td>
                </tr>
                <tr>
                  <td className="text-right">{t("monitoring.totalAccountProfitability")}</td>
                  <td>
                    {selectedAccount?.profit === null ? '-' : `${selectedAccount?.profit ?? '-'}`}
                  </td>
                </tr>
                <tr>
                  <td className="text-right">{t("monitoring.openOrdersCount")}</td>
                  <td>{count ?? '-'}</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <CardHeader style={{ padding: 20 }} className="bg-primary">
            <h5 className="text-center">{t("monitoring.accountData")}</h5>
          </CardHeader>
          <CardBody style={{ padding: 16 }}>
            <Table borderless>
              <tbody>
                <tr>
                  <td className="text-right" width="50%">
                  {t("monitoring.account")}
                  </td>
                  <td>{account?.account_login ?? '-'}</td>
                </tr>
                <tr>
                  <td className="text-right">{t("monitoring.accountHolder")}</td>
                  <td>{mt5?.Name ?? '-'}</td>
                </tr>
                <tr>
                  <td className="text-right">{t("monitoring.registrationDate")}</td>
                  <td>
                    {mt5?.Registration != null
                      ? moment.unix(mt5.Registration).format('yyyy-MM-DD')
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="text-right">{t("monitoring.accountType")}</td>
                  <td>{account?.account_type === 0 ? t("monitoring.Live") : t("monitoring.Demo")}</td>
                </tr>
                <tr>
                  <td className="text-right">{t("monitoring.country")}</td>
                  <td>{mt5?.Country ?? '-'}</td>
                </tr>
                <tr>
                  <td className="text-right">{t("monitoring.currency")}</td>
                  <td>{currency?.name ?? '-'}</td>
                </tr>
                <tr>
                  <td className="text-right">{t("monitoring.leverage")}</td>
                  <td>{account?.leverage === null ? '-' : `1:${account?.leverage ?? '-'}`}</td>
                </tr>
                <tr>
                  <td className="text-right">{t("monitoring.balance")}</td>
                  <td>
                    {account?.balance === null ? '-' : `${account?.balance?.toFixed(2) ?? '-'}`}
                  </td>
                </tr>
                <tr>
                  <td className="text-right">{t("monitoring.credit")}</td>
                  <td>{account?.credit === null ? '-' : `${account?.credit ?? '-'}`}</td>
                </tr>
                <tr>
                  <td className="text-right">{t("monitoring.freeMargin")}</td>
                  <td>{account?.free_margin === null ? '-' : `${account?.free_margin}` ?? '-'}</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  ) : (
      <>{t("monitoring.loading")}</>
    )
}

export default MonitoringGraphical
