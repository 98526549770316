import React from "react";
import ClaimedVoucherTable from "./table";

const Pending = () => {
  return (
    <ClaimedVoucherTable />
  );
};

export default Pending;
