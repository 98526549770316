
let premiumPartibanPackage

export default premiumPartibanPackage = {
    stopLostHunting1000: {
        benefits: [


            {
                name: 'Introduction to Forex',
                included: 'yes'
            }
            ,
            {
                name: 'Stop Loss Hunting Program',
                included: 'yes'
            },
            {
                // name: '个人专属顾问',
                // name: 'vePackageInfo.ExclusivePersonalConsultant',
                name: 'Webinar Series',
                included: 'yes'
            }
        ]
    },

    stopLostHunting5000: {
        benefits: [
            
            {
                name: 'Introduction to Forex',
                included: 'yes'
            }
            ,
            {
                name: 'Stop Loss Hunting Program',
                included: 'yes'
            },
            {
                // name: '个人专属顾问',
                // name: 'vePackageInfo.ExclusivePersonalConsultant',
                name: 'Webinar Series',
                included: 'yes'
            },
            {
                // name: '个人专属顾问',
                name: 'Telegram Channel Group',
                included: 'yes'
            }
            ,
            {
                name: '60min Consultation Session',
                included: 'yes'
            },

            {
                name: 'Weekly Market Analysis',
                included: 'yes'
            },
            {
                name: 'ECN PRO Account',
                included: 'yes'
            },


        ]
    },

    personalCoaching10000: {
        benefits: [
            {
                name: 'Introduction to Forex',
                included: 'yes'
            }
            ,
            {
                name: 'Stop Loss Hunting Program',
                included: 'yes'
            },
            {
                // name: '个人专属顾问',
                // name: 'vePackageInfo.ExclusivePersonalConsultant',
                name: 'Webinar Series',
                included: 'yes'
            },
            {
                // name: '个人专属顾问',
                name: 'Telegram Channel Group',
                included: 'yes'
            }
            ,
            {
                name: '60min Consultation Session',
                included: 'yes'
            },

            {
                name: 'Weekly Market Analysis',
                included: 'yes'
            },
            {
                name: 'ECN VIP Account',
                included: 'yes'
            },
            ,
            {
                name: '5 Coaching Session One to One',
                included: 'yes'
            },

            {
                name: '10% Credit Bonus',
                included: 'yes'
            },
            {
                name: 'Special Trade 1,800 USD',
                included: 'yes'
            },
        ]
    }

}


