import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Media, CardBody, Spinner, Button } from 'reactstrap'
import styled from 'styled-components'
import _ from 'lodash'

import Breadcrumb from '../../layout/breadcrumb'
import SettingService from '../../network/services/promotion'

import { useSelector } from 'react-redux'
import Banner from './banner'
import BonusTable from "./bonus/table.jsx";
import { useTranslation } from 'react-i18next'

const Platform = () => {
  const { t } = useTranslation()
  const [settings, setSettings] = useState({})
  const promotions = useSelector((state) => state.promotion.promotions)

  const init = async () => {
    try {
      const result = await SettingService.getAll()
      setSettings(result.settings ?? {})
    } catch (error) {
      setSettings({})
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      <Breadcrumb parent={t("promotion.Promotion")} title={t("promotion.Promotion")} />
      <Container>
        <Card>
          <CardBody>
            <h5>{t("promotion.Promotion")}</h5>
            <p>{t("promotion.TheMoreYouRedeem")}</p>
          </CardBody>
        </Card>
        <Row>
          {promotions?.length > 0 ? (
            promotions.map((promo) => {
              return (
                <Col lg="4" key={promo.id}>
                  <Banner promotion={promo} />
                </Col>
              )
            })
          ) : (
              <Col>
                <Card>
                  <CardBody style={{ textAlign: 'left' }}>
                    <h4>{t("promotion.NoPromotionYet")}</h4>
                  </CardBody>
                </Card>
              </Col>
            )}
        </Row>

        <Card>
          <CardBody>
            <BonusTable />
          </CardBody>
        </Card>

      </Container>
    </>
  )
}

export default Platform
