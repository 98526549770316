import React, { useState, useEffect } from 'react'
import CourseService from '../../network/services/courses'
import {
  BrowserRouter as Router,
  useParams,
} from "react-router-dom";



const VimeoPublic = () => {
  const { id } = useParams()

  const [videoID, setVideoID] = useState('')
  const [video, setVideo] = useState('')
  const [videoLink, setVideoLink] = useState(`https://player.vimeo.com/video/${id}?color=ececec&amp;title=0&amp;byline=0&amp;portrait=0;`)
  const [allChapter, setAllChapter] = useState([])
  const [courseResult, setCourseResult] = useState({})

  return (

    <div>
      <div style={{ padding: '6% 0 50% 0', position: 'relative', backgroundColor: videoLink && 'black' }}>
        <iframe src={videoLink}
          frameborder="0"
          allow="fullscreen" 
          webkitallowfullscreen 
          mozallowfullscreen 
          allowfullscreen
          style={{ position: 'absolute', top: '0', left: '0' }}
          id='frame-vimeo'
          
        >
        </iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>

  )
}

export default VimeoPublic
