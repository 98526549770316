import { SET_DASHBOARD_ACCOUNT } from "../actionTypes"

const initial_state = {
  dashboard: null,
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_DASHBOARD_ACCOUNT:
      return { ...state, account: action.account }

    default:
      return { ...state }
  }
}
