import React from "react"
import { Row, Col, ListGroup, ListGroupItem } from "reactstrap"
import { useTranslation } from 'react-i18next'

const Passport = () => {
  const { t } = useTranslation()
  return (
    <>
      <Row>
        <Col md="12">
          <ListGroup className="list-group-flush">
          <ListGroupItem>
              {t("user.MustBeValidAndHaveExpiry")}
            </ListGroupItem>
            <ListGroupItem>{t("user.AllInformationAndDetails")}</ListGroupItem>
            <ListGroupItem>{t("user.AllScannedFilesOrPhotos")}</ListGroupItem>
            <ListGroupItem>{t("user.MustContainSignature")}</ListGroupItem>
            <ListGroupItem>{t("user.AllSubmitteDocumentsNotDamaged")}</ListGroupItem>
            <ListGroupItem>
              {t("user.ProvidePhotoOrScan")}
            </ListGroupItem>
            <ListGroupItem>{t("user.BothSideOfIDCard")}</ListGroupItem>
            <ListGroupItem>{t("user.TheEdgesOfDocumentsShouldNotCutOff")}</ListGroupItem>
          </ListGroup>
        </Col>
        {/* <Col md="6">
          <img src="/passport.jpg" alt="passport" style={{ maxWidth: "100%" }} />
        </Col> */}
      </Row>
    </>
  )
}

export default Passport
