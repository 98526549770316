import React, { useEffect, useState } from 'react'
import {
    Card,
    CardBody,
    Col,
    Row,
    Button,
    Spinner,
    Navbar,
    NavbarText,
    Form,
    Input,
    FormGroup,
    Tooltip
} from 'reactstrap'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import AccountService from '../../../network/services/account'
import EventService from '../../../network/services/event'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'

import { HelpCircle } from 'react-feather'


import { makeStyles } from '@material-ui/core/styles';

// import ProfitRanking from './rankingTable/profitRank';
// import TradeVolume from './rankingTable/tradeVolumeRank';
// import CompletedRank from './rankingTable/completedRank';

import './index.scss'


const CarnivalRanking = (props) => {
    const useStyles = makeStyles((theme) => ({
        customWidth: {
            maxWidth: 500,
        },
        noMaxWidth: {
            maxWidth: 'none',
        },
    }));

    const classes = useStyles();

    const { t } = useTranslation()
    let history = useHistory()
    const dispatch = useDispatch()
    const [nav_Graph, setNav_Graph] = useState('')

    const [qualifyAccounts, setQualifyAccounts] = useState()
    const [myEventDetails, setMyEventDetails] = useState(null)
    const [diff_with_top_profit, setDiffWithTopProfit] = useState(null)
    const [diff_with_top_volume, setDiffWithTopVolume] = useState(null)
    const [myProfitRanking, setMyProfitRanking] = useState(null)
    const [myLotSizeRanking, setMyLotSizeRanking] = useState(null)
    const [myCompletedSpeedRanking, setMyCompletedSpeedRanking] = useState(null)
    const [tooltipOpen1Rank, setTooltip1RankOpen] = useState(false)
    const [tooltipOpen2Rank, setTooltip2RankOpen] = useState(false)



    const toggleTooltip1 = () => {
        setTooltip1RankOpen(!tooltipOpen1Rank)
    }

    const toggleTooltip2 = () => {
        setTooltip2RankOpen(!tooltipOpen2Rank)
    }

    const [isBusy, setIsBusy] = useState(false)

    const { register, handleSubmit, errors, reset } = useForm()


    const init = async () => {
        setIsBusy(true)
        const accountResponse = await AccountService.getAll()
        const eventResponse = await EventService.carnivalDetails(1)

        setQualifyAccounts(
            accountResponse?.accounts?.filter((acc) => {
                return (acc?.account_type === 0 || acc?.account_type === 2) && (acc?.package_id !== 7 && acc?.package_id !== 9)
            })
        )
        // console.log('eventResponse', eventResponse)
        setMyEventDetails(eventResponse?.event_participation)
        setMyProfitRanking(eventResponse?.my_profit_ranking)
        setMyLotSizeRanking(eventResponse?.my_lot_size_ranking)
        setMyCompletedSpeedRanking(eventResponse?.my_completed_speed_ranking)
        setDiffWithTopProfit(eventResponse?.diff_with_top_profit)
        setDiffWithTopVolume(eventResponse?.diff_with_top_volume)
        setIsBusy(false)
    }


    useEffect(() => {
        init()
    }, [])

    const registerCarnival = async (data) => {

        try {
            if (data !== '') {
                const result = await EventService.registerCarnival({
                    account_id: data?.account_id,
                    event_id: 1
                })


                if (result) {

                    SweetAlert.fire({
                        title: t("carnival.ThanksForYourParticipation"),
                        text: t("carnival.WishYouHaveAVictoryAtTheCarnival"),
                        icon: 'success'
                    }).then(() => {
                        window.location.reload()
                    })
                }
            } else {
                errors.showMessages()
            }
        } catch (error) {

            const errorCode = error.code
            const errorTranslate = error.translate_params
            SweetAlert.fire({
                title: t(errorCode, errorTranslate),
                icon: 'error'
            })
        }
    }



    const displayTable = () => {
        if (nav_Graph === "Account Details" || nav_Graph === "" || nav_Graph === "") {
            return (
                <Col lg="12" style={{ padding: '0' }}>
                    <p>*{t("carnival.TheRankingTableWill")}</p>
                    <Row >

                        <Col lg='3'>
                            <Card className="responsive-card-cardnival" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                <CardBody style={{ padding: '20px 40px' }}>
                                    {/* <Row style={{ justifyContent: 'space-between' }}> */}
                                    <div>

                                        <h6>
                                            {t("carnival.Account")}
                                        </h6>

                                        <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                            {myEventDetails?.account?.account_login}
                                        </h3>

                                    </div>


                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg='3'>
                            <Card className="responsive-card-cardnival" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                <CardBody style={{ padding: '20px 40px' }}>

                                    <div >

                                        <h6>
                                            {t("carnival.AccountBalance")}
                                        </h6>

                                        <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                            ${myEventDetails?.account?.balance}
                                        </h3>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg='3'>
                            <Card className="responsive-card-cardnival" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                <CardBody style={{ padding: '20px 40px' }}>
                                    <div>
                                        <h6>
                                            {t("carnival.AccountCredit")}
                                        </h6>
                                        <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                            ${myEventDetails?.account?.credit}
                                        </h3>

                                    </div>

                                </CardBody>
                            </Card>
                        </Col>

                        {/* <Col lg='3'>
                            <Card className="responsive-card-cardnival" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                <CardBody style={{ padding: '20px 40px' }}>
                                    <div>

                                        <h6>
                                            {t("carnival.ProfitRank")}
                                        </h6>
                                        <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                            {myProfitRanking}
                                        </h3>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg='3'>
                            <Card className="responsive-card-cardnival" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                <CardBody style={{ padding: '20px 40px' }}>
                                    <div>

                                        <h6>
                                            {t("carnival.VolumeRank")}
                                        </h6>
                                        <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                            {myLotSizeRanking}
                                        </h3>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg='3'>
                            <Card className="responsive-card-cardnival" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                <CardBody style={{ padding: '20px 40px' }}>
                                    <div>

                                        <h6>
                                            {t("carnival.CompletedRank")}
                                        </h6>
                                        <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                            {myCompletedSpeedRanking}
                                        </h3>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col> */}
                        <Col lg='3'>
                            <Card className="responsive-card-cardnival" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                <CardBody style={{ padding: '20px 40px' }}>
                                    <div>

                                        <h6>
                                            Top Trader User Profit <HelpCircle id='Tooltip1Carnival' style={{ width: '18px', color: '#ffcc00' }} />
                                            <Tooltip placement="right" isOpen={tooltipOpen1Rank} target={`Tooltip1Carnival`} toggle={toggleTooltip1}>
                                                {diff_with_top_profit ? `Need ${diff_with_top_profit?.toFixed(2)} more to reach First Place` : 'Congratulations! You are the First Place'}
                                            </Tooltip>
                                        </h6>
                                        <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                            {diff_with_top_profit ? diff_with_top_profit?.toFixed(2) : 'First Place'}
                                        </h3>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg='3'>
                            <Card className="responsive-card-cardnival" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                <CardBody style={{ padding: '20px 40px' }}>
                                    <div>

                                        <h6>
                                            Top Trader User Volume <HelpCircle id='Tooltip2Carnival' style={{ width: '18px', color: '#ffcc00' }} />
                                            <Tooltip placement="right" isOpen={tooltipOpen2Rank} target='Tooltip2Carnival' toggle={toggleTooltip2}>
                                                {diff_with_top_volume ? `Need ${diff_with_top_volume?.toFixed(2)} more to reach First Place` : 'Congratulations! You are the First Place'}

                                            </Tooltip>
                                        </h6>
                                        <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                            {diff_with_top_volume ? diff_with_top_volume?.toFixed(2) : 'First Place'}
                                        </h3>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </Col>
            )
        }
        else if (nav_Graph === "Profit Ranking" || nav_Graph === "统计数据") {
            return (<Row style={{ margin: '0', flexDirection: 'row' }}>
                Profit Ranking

            </Row>)
        }
        else if (nav_Graph === "Trade Volume Ranking" || nav_Graph === "交易记录") {
            return (
                <p>Trade Volume</p>
            )
        }
        else if (nav_Graph === "Completed Rank (Stay Tuned)" || nav_Graph === "说明") {
            return (
                <p>Coming Soon</p>
            )
        }

        else {
            return ""
        }
    }

    return (
        <>
            {!isBusy ?
                myEventDetails ?
                    < Row
                        style={{
                            margin: '0px 0px 20px 0px',
                            padding: '20px 0px',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            backgroundColor: 'white',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                            borderRadius: '15px',
                            color: 'black'
                        }}

                    >
                        <Col lg='12' style={{ fontSize: '13px', fontWeight: 'bold' }}>
                            <Navbar id='NavBar' className="mobile-font-size" expand="md" style={{ padding: '0 10px', justifyContent: 'flex-start' }}>

                                <div style={{ cursor: 'pointer' }} className={`${nav_Graph === 'Account Details' || nav_Graph === "" ? 'isActive' : ''}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                                    <NavbarText className={`${nav_Graph === 'Account Details' || nav_Graph === "" ? 'isActive' : ''} mr-3 ml-3`}>
                                        Account Details
                                </NavbarText>
                                </div>

                                {/* Open in week 6  */}
                                {/* <div style={{ cursor: 'pointer' }} className={`${nav_Graph === 'Profit Ranking' ? 'isActive' : ''}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                                    <NavbarText className={`${nav_Graph === 'Profit Ranking' ? 'isActive' : ''} mr-3 ml-3`}>
                                        Profit Ranking
                                </NavbarText>
                                </div>

                                <div style={{ cursor: 'pointer' }} className={`${nav_Graph === 'Trade Volume Ranking' ? 'isActive' : ''}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                                    <NavbarText
                                        className={`${nav_Graph === 'Trade Volume Ranking' ? 'isActive' : ''} mr-3 ml-3`}
                                    >
                                        Trade Volume Ranking
                                </NavbarText>
                                </div>

                                <div style={{ cursor: 'pointer' }} className={`${nav_Graph === 'Completed Rank (Stay Tuned)' ? 'isActive' : ''}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                                    <NavbarText
                                        className={`${nav_Graph === 'Completed Rank (Stay Tuned)' ? 'isActive' : ''} mr-3 ml-3`}
                                    >
                                        Completed Rank (Stay Tuned)
                                </NavbarText>
                                </div> */}

                            </Navbar>
                        </Col>
                        <Col id='displayGraph' >
                            <CardBody style={{ padding: '20px 0px' }}>
                                {displayTable()}
                            </CardBody>
                        </Col>
                    </Row>
                    :
                    <Row>


                        <Col lg='6'>


                            <Card style={{ padding: '30px', backgroundColor: '#0281fa', color: 'white' }}>
                                {/* <h3>Register TradeHall Carnival 2022</h3> */}
                                <Form className="theme-form" style={{ marginBottom: '20px' }} onSubmit={handleSubmit(registerCarnival)}>

                                    <Row className='mb-2'>
                                        <Col>
                                            {/* <FormGroup>

                                                <Input
                                                    id='input-carnival-select'
                                                    className="form-control"
                                                    type="select"
                                                    name="account_id"
                                                    innerRef={register({ required: true })}
                                                >
                                                    <option value="" >
                                                        {t("carnival.SelectMT5AccountToJoinCarnival")}
                                                    </option>

                                                    {qualifyAccounts?.map((account) => {

                                                        return <option value={account?.id}>{account?.account_login} - ${account.balance}</option>
                                                    })}
                                                </Input>
                                            </FormGroup>

                                            <FormGroup className="mb-0" >
                                                <Button color="primary" >
                                                    {t("carnival.JoinNow")}

                                                </Button>
                                            </FormGroup> */}
                                            <h2 style={{ color: '#f9b600' }} >Registration closed</h2>
                                        </Col>


                                    </Row>


                                </Form>

                                <h3>{t("event.TermsAndConditions")}</h3>
                                <p>1. {t("carnival.ParticipantsMustRegisterBefore")}</p>
                                <p>2. {t("carnival.TheTradingAccountWith")}</p>
                                <p>3. {t("carnival.OnTheLastTradingDayOfEvery")}</p>
                                <p>4. {t("carnival.ParticipantsWhoFailedToComplete")}</p>
                                <p>5. {t("carnival.ThereWillBeNoDeposit")}</p>
                                <p>6. {t("carnival.TheCalculationOfTheMostProfit")}
                                    <br />
                                    a) {t("carnival.OnlyTradesThatAreOpenAnd")}
                                    <br />
                                    b) {t("carnival.FloatingOrdersWillBeExcluded")}
                                    <br />
                                    c) {t("carnival.TheOrdersThatAreNotIncluded")}
                                </p>

                                <br />


                                <a style={{ textAlign: 'left' }} href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/T%26C+TradeHall+Carnival.pdf' target='_blank' rel="noopener noreferrer">
                                    <Button color='primary' >{t("dashboard.View")}</Button>
                                </a>
                            </Card>


                        </Col>


                    </Row>


                :
                <div
                    style={{

                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Spinner color="warning" />
                </div>
            }


        </>
    )
}

export default CarnivalRanking
