import React, { useState, useEffect } from 'react'
import {
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Spinner,
  Row,
  Container

} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import EventService from '../../../../network/services/event'
import AccountService from '../../../../network/services/account'

import _ from 'lodash'


import { useHistory, Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'


const RegisterDemoContest = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const demoAccounts = useSelector(state => state.account?.accounts?.filter(item => item.account_type === 1))

  const { register, handleSubmit } = useForm({})


  const onSubmit = async (data) => {
    setLoading(true)

    try {

      const competitionId = 4
      const result = await EventService.submitCompetition({
        name: data['name'],
        competition_id: competitionId,
      })

      if (result) {

        const accountResponse = await AccountService.getDemoAccount()
        const record = await EventService.getMyCompetition({
          competition_id: 4
        })

        const accountCreated = accountResponse?.accounts?.filter(account => {
          return parseInt(account?.account_login) === record?.submitted_registrations[0]?.account_login
        })


        setLoading(false)
        SweetAlert.fire({
          title: t('event.CongratulationYourNewDemo'),
          html: `
          </br>
            <ul>
              <li>Account Login : ${accountCreated[0]?.account_login}</li>
              <li>${t('event.ServerName')} : TradehallLimited-Demo</li> 
              <li>${t('event.BalanceAmount')} : ${accountCreated[0]?.balance?.toFixed(2)} USD</li>
            </ul>
            </br>
            <p>${t('event.KindlyUseTradehallLimited')}</p>
          `,
          icon: 'success'
        }).then((result) => {
          if (result) {
            window.location.reload()

          }
        })
      }
    } catch (error) {
      setLoading(false)

      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }

  const [isEligible, setIsEligible] = useState(false)
  const [myRegistrationRecord, setMyRegistrationRecord] = useState([])

  const fetchMyRegistrationRecord = async () => {
    // Hardcode competition id
    const record = await EventService.getMyCompetition({
      competition_id: 4
    })

    setMyRegistrationRecord(record?.submitted_registrations)
    setIsEligible(record?.is_eligible)
  }

  useEffect(() => {
    // Hardcode competition id
    fetchMyRegistrationRecord()
  }, [])

  return (
    <>

      <Form
        className="theme-form verify-auth-form"
        id="student-event-form"
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'flex-start'
        }}
      >
        <Col lg="7" id="term-col">
          <div style={{ maxWidth: '100%' }}>
            <img src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/CRM+Banner-01+(3).jpg' alt='tradehall contest' style={{ width: '100%' }} />
          </div>

          <Card style={{ padding: '24px 0px', borderRadius: '0' }}>
            <Row style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
              <div style={{ textAlign: 'center' }}>
                <Col>01/5/2022 (GMT+0) 00:00:00</Col>
                <Col>{t('event.RegistrationDate')}</Col>
              </div>

              <div style={{ textAlign: 'center' }}>
                <Col>01/6/2022 (GMT+0) 00:00:00</Col>
                <Col>{t('event.TradingPeriod')}</Col>
              </div>


            </Row>
          </Card>
        </Col>

        <Col lg="5" id="form-col">
          <Card style={{ borderRadius: '0px' }} >
            <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
              <h3>TradeHall Demo Contest 2022</h3>
              <p style={{ marginBottom: '0' }}>{t('event.RegistrationDate')}: 01/05/2022 - 01/06/2022</p>
              <p>{t('event.ContestStartDate')}: 01/06/2022 - 01/07/2022</p>

              <FormGroup>
                <Label className="m-t-10">{t('event.DisplayName')}: </Label>
                <Input
                  innerRef={register({ required: true })}
                  className="form-control input-txt-bx"
                  type="text"
                  name="name"
                  placeholder={t('event.EnterDisplayName')}
                />
              </FormGroup>

              <div style={{ display: 'flex', marginLeft: '20px', marginTop: '10px' }}>
                <Input type="checkbox" required />
                <p>
                  {t('event.ByCheckingThis')}
                  <span style={{ cursor: 'pointer', color: '#f9b600' }}>
                    <a
                      href="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/TradeHall+Demo+Contest+2022+Terms+and+Conditions.pdf"
                      target="_blank"
                      style={{ color: 'black' }}
                    >
                      {' '}
                      {t('event.TermsAndConditions')}
                    </a>
                  </span>
                </p>
              </div>

              <div className="m-t-20" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button color="primary" disabled={loading}>
                  {t('event.Join')}
                </Button>

                <Button color="secondary" disabled={loading}>
                  <a href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/TradeHall+Demo+Contest+2022+Terms+and+Conditions.pdf' target='_blank'>
                    T&Cs
                  </a>
                </Button>
              </div>

            </CardBody>
            <div style={{ display: 'flex', justifyContent: 'center' }} className="m-b-20">

              {loading ? (
                <div>
                  <Spinner size="lg" color="warning" />
                </div>
              ) : (
                  ''
                )}
            </div>
          </Card>
        </Col>
      </Form>
    </>
  )
}

export default RegisterDemoContest
