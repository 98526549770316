import React, { useEffect, useState } from 'react'
import { Row, Col, Spinner, Input, Card, CardBody, Button } from 'reactstrap'
import { useSelector } from 'react-redux'
import moment from 'moment'
import {
  TreeDataState,
  CustomTreeData,
  FilteringState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableTreeColumn,
  TableColumnResizing,
  TableFilterRow,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ClientService from '../../../network/services/client'

const colors = [
  '#d34b29',
  '#3fc06f',
  '#a350cb',
  '#79bd42',
  '#7163d4',
  '#b0b244',
  '#cf5cc4',
  '#4d8a35',
  '#e54181',
  '#73bd87',
  '#cd4e9c',
  '#34825c',
  '#d2404e',
  '#40c0bc',
  '#b03565',
  '#d69f35',
  '#5179da',
  '#e0814b',
  '#55aedf',
  '#a4562d',
  '#4f78b6',
  '#8d6e2c',
  '#8155a2',
  '#707a32',
  '#ab96e1',
  '#d1a770',
  '#d789c4',
  '#a75355',
  '#985078',
  '#e4818f'
]

const getChildRows = (row, rootRows) => {
  return row?.children ?? rootRows
}

const Cell = (props) => {
  if (
    props.column.name === 'volume' ||
    props.column.name === 'role_id' ||
    props.column.name === 'commission' ||
    props.column.name === 'total_deposit' ||
    props.column.name === 'total_deposit_all_time' ||
    props.column.name === 'verified'
  ) {
    return <TableFilterRow.Cell {...props}>&nbsp;</TableFilterRow.Cell>
  }
  return <TableFilterRow.Cell {...props} />
}

const ContentCell = (props) => {
  
  if (props.column.name === 'commission') {
    return <Table.Cell {...props} value={props.row?.commission?.toFixed(2) ?? '-'} />
  }

  if (props.column.name === 'volume') {
    return <Table.Cell {...props} value={props.row?.volume?.toFixed(2) ?? '-'} />
  }

  if (props.column.name === 'role_id') {
    return <Table.Cell {...props} value={props.row?.role?.name} />
  }

  if (props.column.name === 'verified') {
    return <Table.Cell {...props} value={props.row?.verified ? 'Approved' : '-'} />
  }

  if (props.column.name === 'total_deposit') {
    return <Table.Cell {...props} value={props.row?.total_deposit.toFixed(2) ?? '-'} />
  }

  if (props.column.name === 'total_deposit_all_time') {
    return <Table.Cell {...props} value={props.row?.total_deposit_all_time.toFixed(2) ?? '-'} />
  }

  return <Table.Cell {...props} />
}

const LevelCell = (props) => {
  return <TableTreeColumn.Cell {...props} style={{ color: colors[props.row?.level ?? 0] }} />
}

const ClientTree = () => {
  const { t } = useTranslation()
  const user = useSelector((state) => state.user.user)

  const [data, setData] = useState([])
  const [fromDate, setFromDate] = useState(
    moment().startOf('month').startOf('day').format('yyyy-MM-DD HH:mm:ss')
  )
  const [toDate, setToDate] = useState(
    moment().endOf('month').endOf('day').format('yyyy-MM-DD HH:mm:ss')
  )
  const [isLoading, setIsLoading] = useState(false)
  const [calculatedDeposit, setCalculatedDeposit] = useState(0)
  const [calculatedAllTimeDeposit, setCalculatedAllTimeDeposit] = useState(0)

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const fetchClients = async () => {
    setIsLoading(true)
    const result = await ClientService.getAll({
      from_date: fromDate,
      to_date: toDate
    })

    setData(result.clients)

    // console.log(result.clients)

    let sumDeposit = 0
    let sumDepositAllTime = 0
    if (result.clients.length > 0) {
      sumDeposit = result.clients.map((item) => item.total_deposit).reduce((a, b) => a + b)
      sumDepositAllTime = result.clients
        .map((item) => item.total_deposit_all_time)
        .reduce((a, b) => a + b)

      const ibs = result.clients.filter((item) => item.children?.length > 0)
      const ibClients = ibs?.length > 0 ? ibs.flatMap((item) => item.children) : []
      const ibClientsTotalDeposit =
        ibClients?.length > 0
          ? ibClients
            .map((item) => item.total_deposit)
            .reduce((a, b) => {
              return a + b
            })
          : 0

      const ibClientsAllTotalDeposit =
        ibClients?.length > 0
          ? ibClients
            .map((item) => item.total_deposit_all_time)
            .reduce((a, b) => {
              return a + b
            })
          : 0
      sumDeposit += ibClientsTotalDeposit
      sumDepositAllTime += ibClientsAllTotalDeposit

      setCalculatedDeposit(sumDeposit ?? 0)
      setCalculatedAllTimeDeposit(sumDepositAllTime ?? 0)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchClients()
  }, [])

  // tree
  const [columns] = useState([
    { name: 'level', title: 'Level' },
    { name: 'email', title: 'Email' },
    { name: 'role_id', title: 'Role' },
    { name: 'commission', title: 'Commission Earned' },
    { name: 'volume', title: 'Traded Volume' },
    { name: 'total_deposit', title: 'Total Deposit(USD)' },
    { name: 'total_deposit_all_time', title: 'All Time Total Deposit(USD)' },
    { name: 'verified', title: 'KYC Verified' }
  ])

  const [defaultColumnWidths] = useState([
    { columnName: 'level', width: 180 },
    { columnName: 'email', width: 240 },
    { columnName: 'role_id', width: 180 },
    { columnName: 'commission', width: 180 },
    { columnName: 'volume', width: 180 },
    { columnName: 'total_deposit', width: 240 },
    { columnName: 'total_deposit_all_time', width: 240 },
    { columnName: 'verified', width: 180 }
  ])
  const [filteringStateColumnExtensions] = useState([
    { columnName: 'volume', filteringEnabled: false }
  ])

  return (
    <>
      {data != null ? (
        <>
          {/* <Row>
            <Col xs={12} md={6}>
              {t("partner.email")}
            </Col>
            <Col xs={12} md={6} style={{ fontWeight: 'bold' }}>
              {user?.email}
            </Col>
            <Col xs={12} md={6}>
              {t("partner.myCommission")}
            </Col>
            <Col xs={12} md={6} style={{ fontWeight: 'bold' }}>
              {user?.commission?.toFixed(2) ?? 0}
            </Col>
            <Col xs={12} md={6}>
              {t("partner.tradedVolume")}
            </Col>
            <Col xs={12} md={6} style={{ fontWeight: 'bold' }}>
              {user?.volume?.toFixed(2) ?? 0}
            </Col>
          </Row> */}

          <ResponsiveDiv>
            <Col lg="8">
              <div id='totalDepositRow' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Card
                  id='totalDepositPartnerCard'
                  style={{
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    marginBottom: '10px',
                    width: '300px',
                    height: '130px'
                  }}
                >
                  <CardBody style={{ padding: '20px' }}>
                    {/* <Row style={{ justifyContent: 'space-between' }}> */}
                    <div>
                      <h6>
                        {t('partner.allTime')} <br />
                        {t('partner.totalDeposit')}
                      </h6>
                    </div>

                    <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                      {isLoading ? <Spinner /> : formatter.format(calculatedAllTimeDeposit)}
                    </h3>

                    {/* </Row> */}
                  </CardBody>
                </Card>

                <Card
                  style={{
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    marginBottom: '10px',
                    width: '300px',
                    height: '130px'
                  }}
                >
                  <CardBody style={{ padding: '20px' }}>
                    {/* <Row style={{ justifyContent: 'space-between' }}> */}
                    <div>
                      <h6>
                        {t('partner.Total')} <br />
                        {t('partner.Deposit')}{' '}
                      </h6>
                    </div>

                    <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                      {isLoading ? <Spinner /> : formatter.format(calculatedDeposit)}
                    </h3>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </div>
            </Col>

            <Col lg="4">
              <div className="product-filter m-r-20">
                <h6 className="f-w-600">
                  {t('partner.Total')}
                  {t('partner.Deposit')} {t('partner.From')}
                </h6>
                <input
                  className="form-control digits"
                  type="date"
                  defaultValue={moment().startOf('month').format('yyyy-MM-DD')}
                  onChange={(e) => {
                    setFromDate(
                      moment(e.currentTarget.value).startOf('day').format('yyyy-MM-DD HH:mm:ss')
                    )
                  }}
                />
              </div>

              <div className="product-filter m-r-20">
                <h6 className="f-w-600">{t('partner.To')}</h6>
                <Input
                  className="form-control digits"
                  type="date"
                  defaultValue={moment().endOf('month').format('yyyy-MM-DD')}
                  onChange={(e) => {
                    setToDate(
                      moment(e.currentTarget.value).endOf('day').format('yyyy-MM-DD HH:mm:ss')
                    )
                  }}
                />
              </div>

              <Button
                style={{ marginTop: '20px', marginRight: '20px', float: 'right' }}
                onClick={() => {
                  fetchClients()
                }}
              >
                Search
              </Button>
            </Col>
          </ResponsiveDiv>

          <div style={{ padding: 10 }} />
          {isLoading ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <h3 style={{ marginTop: '20px', marginBottom: '20px', color: '#f9b700' }}>
                <Spinner />
              </h3>
            </div>
          ) : (
              <Grid rows={data} columns={columns}>
                <PagingState defaultCurrentPage={0} pageSize={10} />

                <TreeDataState />
                <CustomTreeData getChildRows={getChildRows} />
                <SortingState
                  defaultSorting={[
                    { columnName: 'total_deposit', direction: 'asc' },
                    { columnName: 'total_deposit_all_time', direction: 'asc' },
                    { columnName: 'volume', direction: 'asc' },
                    { columnName: 'commission', direction: 'asc' },
                    { columnName: 'verified', direction: 'asc' }
                  ]}
                />
                <IntegratedSorting />
                <FilteringState
                  defaultFilters={[]}
                  columnExtensions={filteringStateColumnExtensions}
                />
                <IntegratedFiltering />
                <IntegratedPaging />
                <Table cellComponent={ContentCell} />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableFilterRow cellComponent={Cell} />
                <TableTreeColumn for="level" cellComponent={LevelCell} />
                <PagingPanel />
              </Grid>
            )}
        </>
      ) : (
          <Spinner color="warning" />
        )}
    </>
  )
}

const ResponsiveDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export default ClientTree
