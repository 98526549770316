import React, { useEffect, useState } from 'react'
import {
    Card,
    Col,
    Row,
    Button,
    Spinner,
    Form,
    Input,
    FormGroup,
    Tooltip
} from 'reactstrap'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import AccountService from '../../../network/services/account'
import EventService from '../../../network/services/event'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'

import { HelpCircle } from 'react-feather'

import { makeStyles } from '@material-ui/core/styles';

import banner from './banner.jpg'

import DesktopBanner from './banner.png'
import GoalProcessMobile from './goalProcess.png'
import GoalProcess from './goalProcessV2.png'

import Icon1 from './icon1.png'
import Icon2 from './icon2.png'
import Icon3 from './icon3.png'
import Icon4 from './icon4.png'

import Icon5 from './icon5.png'
import Icon6 from './icon6.png'
import Icon7 from './icon7.png'
import Icon8 from './icon8.png'

import Arrow from './arrow.png'

import './index.scss'

import moment from 'moment'


const Statistic = () => {

    const { register, handleSubmit, errors, reset } = useForm()

    const { t } = useTranslation()
    let history = useHistory()
    const dispatch = useDispatch()

    const [isBusy, setIsBusy] = useState(false)
    const [myReferral, setMyReferral] = useState('')

    const [myEventDetails, setMyEventDetails] = useState(null)
    const [qualifyAccounts, setQualifyAccounts] = useState()
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const currentDate = moment().utc().format('YYYY-MM-DD')
    const week1StartDate = '2022-03-14'
    const week2StartDate = '2022-03-21'
    const week3StartDate = '2022-03-28'
    const week4StartDate = '2022-04-04'
    const week5StartDate = '2022-04-11'
    const week6StartDate = '2022-04-18'

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen)
    }

    const [tooltipOpen2, setTooltipOpen2] = useState(false)

    const toggleTooltip2 = () => {
        setTooltipOpen2(!tooltipOpen2)
    }

    const [tooltipOpen3, setTooltipOpen3] = useState(false)

    const toggleTooltip3 = () => {
        setTooltipOpen3(!tooltipOpen3)
    }

    const [tooltipOpen4, setTooltipOpen4] = useState(false)

    const toggleTooltip4 = () => {
        setTooltipOpen4(!tooltipOpen4)
    }

    const [tooltipOpen5, setTooltipOpen5] = useState(false)

    const toggleTooltip5 = () => {
        setTooltipOpen5(!tooltipOpen5)
    }

    const [tooltipOpen6, setTooltipOpen6] = useState(false)

    const toggleTooltip6 = () => {
        setTooltipOpen6(!tooltipOpen6)
    }

    const [challengeModal, setChallengeModal] = useState(false)
    const [challengeModalNumber, setChallengeModalNumber] = useState(0)
    const toggleChallengeModal = () => {
        setChallengeModal(!challengeModal)
    }

    const registerCarnival = async (data) => {

        try {
            if (data !== '') {
                const result = await EventService.registerCarnival({
                    account_id: data?.account_id,
                    event_id: 1
                })


                if (result) {

                    SweetAlert.fire({
                        title: t("carnival.ThanksForYourParticipation"),
                        text: t("carnival.WishYouHaveAVictoryAtTheCarnival"),
                        icon: 'success'
                    }).then(() => {
                        window.location.reload()
                    })
                }
            } else {
                errors.showMessages()
            }
        } catch (error) {

            const errorCode = error.code
            const errorTranslate = error.translate_params
            SweetAlert.fire({
                title: t(errorCode, errorTranslate),
                icon: 'error'
            })
        }
    }


    const init = async () => {
        setIsBusy(true)
        const accountResponse = await AccountService.getAll()
        const eventResponse = await EventService.carnivalDetails(1)

        setQualifyAccounts(
            accountResponse?.accounts?.filter((acc) => {
                return (acc?.account_type === 0 || acc?.account_type === 2) && (acc?.package_id !== 7 && acc?.package_id !== 9)
            })
        )
        // console.log('eventResponse', eventResponse)
        setMyEventDetails(eventResponse?.event_participation)
        setIsBusy(false)
    }


    useEffect(() => {
        init()
    }, [])



    return (
        !isBusy ?
            myEventDetails !== null ?
                <>
                    <div style={{ backgroundColor: '#0281fa', borderRadius: '15px', marginBottom: '15px' }}>
                        <Row className='d-flex d-lg-none d-xl-none ' style={{ justifyContent: 'center' }}>

                            {/* Mobile version */}
                            <Col id='responsive-carnival-mobile' lg='12' style={{ color: 'black' }} >
                                <div >
                                    <img style={{ width: '100%', borderRadius: '15px 15px 0px 0px' }} src={banner} alt='carnival mobile banner' />
                                </div>
                                <div style={{ textAlign: 'center', backgroundColor: '#0281fa', paddingBottom: '20px', borderRadius: '0px 0px 15px 15px' }}>
                                    <img style={{ width: '90%' }} src={GoalProcessMobile} alt='carnival mobile banner' />
                                </div>

                                <div id='container-icon-carnival' style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px', marginLeft: '20px', marginRight: '20px' }}>
                                    <img
                                        className='carnivalIcon carnivalMobileIcon'
                                        style={{

                                            filter: currentDate >= week1StartDate ? '' : 'grayscale(100%)'
                                        }}
                                        src={Icon1}
                                        alt='forex'
                                    />


                                    <img
                                        className='carnivalIcon carnivalMobileIcon'
                                        style={{

                                            filter: currentDate >= week2StartDate ? '' : 'grayscale(100%)'
                                        }}
                                        src={Icon2}
                                        alt='commonities'
                                    />


                                    <img
                                        className='carnivalIcon carnivalMobileIcon'
                                        style={{

                                            filter: currentDate >= week3StartDate ? '' : 'grayscale(100%)'
                                        }}
                                        src={Icon3}
                                        alt='crypto'
                                    />


                                    <img
                                        className='carnivalIcon carnivalMobileIcon'
                                        style={{

                                            filter: 'grayscale(100%)'
                                        }}
                                        src={Icon4}
                                        alt='Reward 1' />
                                </div>



                                <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '75px', marginLeft: '20px', marginRight: '20px' }}>
                                    <img
                                        className='carnivalIcon carnivalMobileIcon'
                                        style={{

                                            filter: currentDate >= week5StartDate ? '' : 'grayscale(100%)'
                                        }}
                                        src={Icon5}
                                        alt='index'
                                    />


                                    <img
                                        className='carnivalIcon carnivalMobileIcon'
                                        style={{

                                            filter: currentDate >= week5StartDate ? '' : 'grayscale(100%)'
                                        }}
                                        src={Icon6}
                                        alt='stock'
                                    />


                                    <img
                                        className='carnivalIcon carnivalMobileIcon'
                                        style={{

                                            filter: currentDate >= week6StartDate ? '' : 'grayscale(100%)'
                                        }}
                                        src={Icon7}
                                        alt='etf'
                                    />


                                    <img className='carnivalIcon carnivalMobileIcon' style={{ filter: 'grayscale(100%)' }} src={Icon8} alt='Reward 1' />
                                </div>

                            </Col>


                            {myEventDetails?.eventProgresses?.map((progress, index) => {

                                return <>
                                    <Col xs='9' id='responsive-carnival-mobile' style={{ paddingRight: '0px' }}>
                                        <Card style={{ textAlign: 'center', backgroundColor: '#f7f7f7', color: '#130a5d', border: '1px solid #130a5d' }}>
                                            <h6 style={{ marginTop: '20px', color: '#130a5d' }}>{t(`carnival.Week${index + 1}`)} <HelpCircle id={`Tooltip${index + 1}`} style={{ width: '18px', color: '#ffcc00' }} /></h6>
                                            <p style={{ marginBottom: '0px', color: '#130a5d' }}>{t("carnival.ValidDate")} {moment(progress?.eventMission?.start_date).utc().format('MM-DD')} - {moment(progress?.eventMission?.end_date).utc().format('MM-DD')}</p>

                                            <hr style={{ borderTop: '1px solid #130a5d' }} />
                                            <p>Trade {progress?.eventMission?.target_value} {progress?.eventMission?.calculation_type === 0 ? 'lot size in' : 'lot size/per day in'}  <br /><strong style={{ color: '#ffcc00' }}>{progress?.eventMission?.symbol_category}</strong></p>
                                            <p>{progress?.eventMission?.calculation_type === 0 ?
                                                progress?.completed_lot_size === null ? '0 / ' : progress?.completed_lot_size + ' / '
                                                :
                                                progress?.completed_transactions === null ? '0 / ' : progress?.completed_transactions + ' / '
                                            } {progress?.eventMission?.calculation_type === 0 ? progress?.eventMission?.target_value : progress?.eventMission?.closed_trades_required} {progress?.eventMission?.calculation_type === 0 ? 'lot size per week' : 'transactions per week'}</p>
                                            <Tooltip placement="right" isOpen={tooltipOpen} target={`Tooltip${index + 1}`} toggle={toggleTooltip}>
                                                {t("carnival.AllThe")} {t("carnival.dealsWillOnlyCalculateWithinValidDatePeriod")}
                                            </Tooltip>
                                        </Card>
                                    </Col>

                                </>
                            })}






                            <Col xs='9' id='responsive-carnival-mobile' style={{ paddingRight: '0px' }}>
                                <Card style={{ textAlign: 'center', backgroundColor: '#f7f7f7', color: '#130a5d', border: '1px solid #130a5d' }}>
                                    <h6 style={{ marginTop: '20px', color: '#130a5d' }}>Reward 1 <br /> Free 100 Credit</h6>
                                    <hr style={{ borderTop: '1px solid #130a5d' }} />

                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                        <p>{t("carnival.Week1")}</p>
                                        <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 18 }}></i>

                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                        <p>{t("carnival.Week2")}</p>
                                        <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 18 }}></i>

                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                        <p>{t("carnival.Week3")}</p>
                                        <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 18 }}></i>

                                    </div>

                                    <hr style={{ borderTop: '1px solid #130a5d' }} />

                                    <p>{t("carnival.RewardWillBeAutomatically")} <br /> {t("carnival.distributedToYourParticipatingAccount")}</p>
                                </Card>
                            </Col>

                            <Col xs='9' id='responsive-carnival-mobile' style={{ paddingRight: '0px' }}>
                                <Card style={{ textAlign: 'center', backgroundColor: '#f7f7f7', color: '#130a5d', border: '1px solid #130a5d' }}>
                                    <h6 style={{ marginTop: '20px', color: '#130a5d' }}>{t("carnival.Reward2")} <br /> {t("carnival.20DepositBonusVoucher")} <br /> {t("carnival.and1NFT")}</h6>
                                    <hr style={{ borderTop: '1px solid #130a5d' }} />

                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                        <p>{t("carnival.Week4")}</p>
                                        <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 18 }}></i>

                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                        <p>{t("carnival.Week5")}</p>
                                        <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 18 }}></i>

                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                        <p>{t("carnival.Week6")}</p>
                                        <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 18 }}></i>

                                    </div>

                                    <hr style={{ borderTop: '1px solid #130a5d' }} />

                                    <p>{t("carnival.RewardWillBeSentToYourEmail")} <br /> {t("carnival.andReviewHowToRedeemTheReward")}</p>

                                </Card>
                            </Col>


                        </Row>

                        <Row className='d-none d-lg-block d-xl-block ' style={{ justifyContent: 'center' }}>
                            {/* Desktop Version */}
                            <Col>
                                <div style={{ display: 'flex', backgroundColor: '#0281fa', borderRadius: '15px', padding: '20px 0px 40px 0px' }}>
                                    <Col lg='6'  >
                                        <div style={{ textAlign: 'center', marginTop: '30px' }}>
                                            <img style={{ width: '113%', marginTop: '13px' }} src={DesktopBanner} alt='carnival mobile banner' />
                                        </div>
                                    </Col>

                                    <Col lg='6'  >

                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: '100%' }} src={GoalProcessMobile} alt='carnival mobile banner' />
                                        </div>

                                        <div id='responsive-carnival' style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '30px' }}>
                                            <img

                                                className='carnivalIcon'
                                                style={{ width: '80px', height: '80px', filter: currentDate >= week1StartDate ? '' : 'grayscale(100%)' }}
                                                src={Icon1}
                                                alt='forex'
                                            />


                                            <img
                                                className='carnivalIcon'
                                                style={{ width: '80px', height: '80px', filter: currentDate >= week2StartDate ? '' : 'grayscale(100%)' }}
                                                src={Icon2}
                                                alt='commonities'
                                            />


                                            <img className='carnivalIcon'
                                                style={{ width: '80px', height: '80px', filter: currentDate >= week3StartDate ? '' : 'grayscale(100%)' }}
                                                src={Icon3}
                                                alt='crypto'
                                            />


                                            <img className='carnivalIcon' style={{ width: '80px', height: '80px', filter: 'grayscale(100%)' }} src={Icon4} alt='Reward 1' />
                                        </div>



                                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                            <img
                                                className='carnivalIcon'
                                                style={{ width: '80px', height: '80px', filter: currentDate >= week4StartDate ? '' : 'grayscale(100%)' }}
                                                src={Icon5} alt='index' />


                                            <img
                                                className='carnivalIcon'
                                                style={{ width: '80px', height: '80px', filter: currentDate >= week5StartDate ? '' : 'grayscale(100%)' }}
                                                src={Icon6}
                                                alt='stock'
                                            />


                                            <img
                                                className='carnivalIcon'
                                                style={{ width: '80px', height: '80px', filter: currentDate >= week6StartDate ? '' : 'grayscale(100%)' }}
                                                src={Icon7}
                                                alt='etf'
                                            />


                                            <img className='carnivalIcon' style={{ width: '80px', height: '80px', filter: 'grayscale(100%)' }} src={Icon8} alt='Reward 1' />
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                            <p style={{ color: '#130a5d' }}>*{t("carnival.AllTheTradingDealsOnlyCalculateWithinValidDatePeriod")}</p>
                                        </div>

                                    </Col>
                                </div>
                            </Col>
                        </Row>

                        <Row id='responsive-carnival' className='d-none d-lg-flex d-xl-flex ' style={{ marginTop: '50px', justifyContent: 'center' }}>
                            {myEventDetails?.eventProgresses?.map((progress, index) => {

                                return <>
                                    <Col lg='3'>
                                        <Card style={{ textAlign: 'center', backgroundColor: '#f7f7f7', color: '#130a5d', border: '1px solid #130a5d' }}>
                                            <h6 style={{ marginTop: '20px', color: '#130a5d' }}>{t(`carnival.Week${index + 1}`)} <HelpCircle id={`Tooltip${index + 1}`} style={{ width: '18px', color: '#ffcc00' }} /></h6>
                                            <p style={{ marginBottom: '0px', color: '#130a5d' }}>{t("carnival.ValidDate")} {moment(progress?.eventMission?.start_date).utc().format('MM-DD')} - {moment(progress?.eventMission?.end_date).utc().format('MM-DD')}</p>

                                            <hr style={{ borderTop: '1px solid #130a5d' }} />
                                            <p>Trade {progress?.eventMission?.target_value} {progress?.eventMission?.calculation_type === 0 ? 'lot size in' : 'lot size/per day in'}  <br /><strong style={{ color: '#ffcc00' }}>{progress?.eventMission?.symbol_category}</strong></p>

                                            {}
                                            <p>{progress?.eventMission?.calculation_type === 0 ?
                                                //if is trade lot side, check is complete or not
                                                progress?.completed_lot_size >= progress?.eventMission?.target_value ?
                                                    <i
                                                        className="fa fa-check-circle"
                                                        style={{ color: 'green', fontSize: 20, marginRight: 4 }}
                                                    ></i>
                                                    :
                                                    progress?.completed_lot_size === null ? '0 / ' : progress?.completed_lot_size + ' / '
                                                :
                                                //if is trade times transaction, check is complete or not
                                                progress?.completed_transactions >= progress?.eventMission?.target_value ?
                                                    <i
                                                        className="fa fa-check-circle"
                                                        style={{ color: 'green', fontSize: 20, marginRight: 4 }}
                                                    ></i>
                                                    :
                                                    progress?.completed_transactions === null ? '0 / ' : progress?.completed_transactions + ' / '
                                            }  {
                                                    progress?.eventMission?.calculation_type === 0 ?
                                                        progress?.completed_lot_size >= progress?.eventMission?.target_value ?
                                                            ''
                                                            :
                                                            progress?.eventMission?.target_value
                                                        :
                                                        progress?.completed_transactions >= progress?.eventMission?.target_value ?
                                                            ''
                                                            :
                                                            progress?.eventMission?.closed_trades_required
                                                } {progress?.eventMission?.calculation_type === 0 ?
                                                    progress?.completed_lot_size >= progress?.eventMission?.target_value ?
                                                        ''
                                                        :
                                                        'lot size per week' :
                                                    progress?.completed_transactions >= progress?.eventMission?.target_value ?
                                                        ''
                                                        :
                                                        'transactions per week'
                                                }
                                            </p>
                                            <Tooltip placement="right" isOpen={tooltipOpen} target={`Tooltip${index + 1}`} toggle={toggleTooltip}>
                                                {t("carnival.AllThe")} <strong>{progress?.eventMission?.symbol_category}</strong> {t("carnival.dealsWillOnlyCalculateWithinValidDatePeriod")}
                                            </Tooltip>
                                        </Card>
                                    </Col>
                                    {index == 2 || index == 5 ?

                                        ''
                                        :
                                        <Col lg='1'>
                                            <img style={{ width: '100px', height: '150px', marginTop: "29px" }} src={Arrow} alt='arrow' />
                                        </Col>

                                    }

                                </>
                            })}

                        </Row>

                        <Row id='responsive-carnival' className='d-none d-lg-flex d-xl-flex ' style={{ marginTop: '50px', justifyContent: 'center' }}>
                            <Col lg='4'>
                                <Card style={{ textAlign: 'center', backgroundColor: '#f7f7f7', color: '#130a5d', border: '1px solid #130a5d' }}>
                                    <h6 style={{ marginTop: '20px', color: '#130a5d' }}>Reward 1 <br /> Free 100 Credit</h6>
                                    <hr style={{ borderTop: '1px solid #130a5d' }} />

                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                        <p>{t("carnival.Week1")}</p>
                                        {myEventDetails?.eventProgresses[0]?.status == 1 ?
                                            <i className="fa fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>
                                            : myEventDetails?.eventProgresses[0]?.status == 0 ?
                                                <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 18 }}></i>
                                                : myEventDetails?.eventProgresses[0]?.status == 2 &&
                                                <i className="fa fa-times-circle" style={{ color: 'red', fontSize: 25 }}></i>
                                        }


                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                        <p>{t("carnival.Week2")}</p>
                                        {myEventDetails?.eventProgresses[1]?.status == 0 ?
                                            <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 18 }}></i>
                                            : myEventDetails?.eventProgresses[1]?.status == 1 ?
                                                <i className="fa fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>
                                                : myEventDetails?.eventProgresses[1]?.status == 2 &&
                                                <i className="fa fa-times-circle" style={{ color: 'red', fontSize: 25 }}></i>
                                        }

                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                        <p>{t("carnival.Week3")}</p>
                                        {myEventDetails?.eventProgresses[2]?.status == 0 ?
                                            <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 18 }}></i>
                                            : myEventDetails?.eventProgresses[2]?.status == 1 ?
                                                <i className="fa fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>
                                                : myEventDetails?.eventProgresses[2]?.status == 2 &&
                                                <i className="fa fa-times-circle" style={{ color: 'red', fontSize: 25 }}></i>
                                        }

                                    </div>

                                    <hr style={{ borderTop: '1px solid #130a5d' }} />

                                    <p>{t("carnival.RewardWillBeSentToYourEmail")} <br /> {t("carnival.andReviewHowToRedeemTheReward")}</p>
                                </Card>
                            </Col>

                            <Col lg='4'>
                                <Card style={{ textAlign: 'center', backgroundColor: '#f7f7f7', color: '#130a5d', border: '1px solid #130a5d' }}>
                                    <h6 style={{ marginTop: '20px', color: '#130a5d' }}>{t("carnival.Reward2")} <br /> {t("carnival.20DepositBonusVoucher")} {t("carnival.and1NFT")}</h6>
                                    <hr style={{ borderTop: '1px solid #130a5d' }} />

                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                        <p>{t("carnival.Week4")}</p>
                                        {myEventDetails?.eventProgresses[3]?.status == 0 ?
                                            <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 18 }}></i>
                                            : myEventDetails?.eventProgresses[3]?.status == 1 ?
                                                <i className="fa fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>
                                                : myEventDetails?.eventProgresses[3]?.status == 2 &&
                                                <i className="fa fa-times-circle" style={{ color: 'red', fontSize: 25 }}></i>
                                        }

                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                        <p>{t("carnival.Week5")}</p>
                                        {myEventDetails?.eventProgresses[4]?.status == 0 ?
                                            <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 18 }}></i>
                                            : myEventDetails?.eventProgresses[4]?.status == 1 ?
                                                <i className="fa fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>
                                                : myEventDetails?.eventProgresses[4]?.status == 2 &&
                                                <i className="fa fa-times-circle" style={{ color: 'red', fontSize: 25 }}></i>
                                        }

                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                        <p>{t("carnival.Week6")}</p>
                                        {myEventDetails?.eventProgresses[5]?.status == 0 ?
                                            <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 18 }}></i>
                                            : myEventDetails?.eventProgresses[5]?.status == 1 ?
                                                <i className="fa fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>
                                                : myEventDetails?.eventProgresses[5]?.status == 2 &&
                                                <i className="fa fa-times-circle" style={{ color: 'red', fontSize: 25 }}></i>
                                        }

                                    </div>

                                    <hr style={{ borderTop: '1px solid #130a5d' }} />

                                    <p>{t("carnival.RewardWillBeSentToYourEmail")} <br /> {t("carnival.andReviewHowToRedeemTheReward")}</p>

                                </Card>
                            </Col>

                        </Row>
                    </div>
                </>
                :

                <Row>


                    <Col lg='6'>


                        <Card style={{ padding: '30px', backgroundColor: '#0281fa', color: 'white' }}>
                            {/* <h3>Register TradeHall Carnival 2022</h3> */}
                            <Form className="theme-form" style={{ marginBottom: '20px' }} onSubmit={handleSubmit(registerCarnival)}>

                                <Row className='mb-2'>
                                    <Col>
                                        {/* <FormGroup>

                                            <Input
                                                id='input-carnival-select'
                                                className="form-control"
                                                type="select"
                                                name="account_id"
                                                innerRef={register({ required: true })}
                                            >
                                                <option value="" >
                                                    Select MT5 Account To Join Carnival
                                            </option>

                                                {qualifyAccounts?.map((account) => {

                                                    return <option value={account?.id}>{account?.account_login} - ${account.balance}</option>
                                                })}
                                            </Input>
                                        </FormGroup>

                                        <FormGroup className="mb-0" >
                                            <Button color="primary" >
                                                Join Now
                                        </Button>
                                        </FormGroup> */}
                                        <h2 style={{ color: '#f9b600' }} >Registration closed</h2>
                                    </Col>


                                </Row>


                            </Form>

                            <h3>{t("event.TermsAndConditions")}</h3>
                            <p>1. {t("carnival.ParticipantsMustRegisterBefore")}</p>
                            <p>2. {t("carnival.TheTradingAccountWith")}</p>
                            <p>3. {t("carnival.OnTheLastTradingDayOfEvery")}</p>
                            <p>4. {t("carnival.ParticipantsWhoFailedToComplete")}</p>
                            <p>5. {t("carnival.ThereWillBeNoDeposit")}</p>
                            <p>6. {t("carnival.TheCalculationOfTheMostProfit")}
                                <br />
                                    a) {t("carnival.OnlyTradesThatAreOpenAnd")}
                                <br />
                                    b) {t("carnival.FloatingOrdersWillBeExcluded")}
                                <br />
                                    c) {t("carnival.TheOrdersThatAreNotIncluded")}
                            </p>

                            <br />

                            <Button color='primary' >{t("dashboard.View")}</Button>
                        </Card>


                    </Col>


                </Row>

            :

            <div
                style={{

                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Spinner color="warning" />
            </div>
    )
}

export default Statistic
