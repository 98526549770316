import React, { useState } from 'react'
import { Card, CardBody, Table, Button } from 'reactstrap'
import BankForm from './form'
import { bankList } from '../../../network/constants'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'


const translateBank = (bank) => {
  
  const findBank = _.find(bankList, { code: bank })
  return findBank?.name ?? bank
}

const BankCard = ({ bank }) => {
  const { t } = useTranslation()
  const translateStatus = (status) => {
    switch (status) {
      case 0:
        return t("user.Pending")
      case 1:
        return t("user.Verified")
      case 2:
        return t("user.Failed")
      case 3:
        return t("user.Rejected")
      default:
        return '-'
    }
  }

  const [isEditable, setIsEditable] = useState(false)

  return (
    <Card>
      <CardBody>
        {isEditable ? (
          <>
            <BankForm bank={bank} setIsEditable={setIsEditable} />
          </>
        ) : (
          <>
            <Table borderless>
              <tbody>
                <tr>
                  <td width="50%">{t("user.AccountHolder")}</td>
                  <td>{bank?.account_holder ?? '-'}</td>
                </tr>
                <tr>
                  <td>{t("user.Branch")}</td>
                  <td>{bank?.bank_branch ?? '-'}</td>
                </tr>
                <tr>
                  <td>{t("user.AccountNumber")}</td>
                  <td>{bank.account_number ?? '-'}</td>
                </tr>
                <tr>
                  <td>{t("user.SwiftCode")}</td>
                  <td>{bank?.swift_code ?? '-'}</td>
                </tr>
                <tr>
                  <td>{t("user.BankName")}</td>
                  <td>{translateBank(bank?.bank_name)}</td>
                </tr>
                <tr>
                  <td>{t("user.Status")}</td>
                  <td
                    style={{
                      fontWeight: 'bold',
                      color: bank.status === 1 ? 'green' : bank.status === 3 ? 'red' : 'black'
                    }}
                  >
                    {translateStatus(bank?.status)}
                  </td>
                </tr>
                {bank?.reason && (
                  <tr>
                    <td>{t("user.Reason")}</td>
                    <td>{bank.reason}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {bank?.status === 0 && (
              <Button
                className="pull-right"
                color="primary"
                onClick={() => {
                  setIsEditable(true)
                }}
              >
                {t("user.Edit")}
              </Button>
            )}
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default BankCard
