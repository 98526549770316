import React, { useEffect, useState } from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Table,
    Row,
    Button,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    Input
} from 'reactstrap'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import PartnerService from '../../../network/services/partner'
import ReferralService from '../../../network/services/referral'
import { useTranslation } from 'react-i18next'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'

import { HelpCircle } from 'react-feather'

import Commissions from './commission'

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import Step from '../landing_page/Step.png'
import ActiveClient from './icon2.svg'
import TotalClient from './icon1.svg'
import Caption from './icon4.svg'
import MonthlyEarn from './icon3.svg'

import Step1 from '.././landing_page/Step1.svg'
import Step2 from '.././landing_page/Step2.svg'
import Step3 from '.././landing_page/Step3.svg'

const StyledCol = styled.div`
  text-align: center;

  @media (max-width: 1549px) {
    margin-bottom: 12px;
  }

`

const IbRoom = () => {

    const useStyles = makeStyles((theme) => ({
        customWidth: {
            maxWidth: 500,
        },
        noMaxWidth: {
            maxWidth: 'none',
        },
    }));

    const classes = useStyles();

    const { t } = useTranslation()
    let history = useHistory()
    const dispatch = useDispatch()
    const partners = useSelector((state) => state.user.partners)
    const currencies = useSelector((state) => state.currency.currencies)
    const [currency, setCurrency] = useState(null)

    const [tutorialModal, setTutorialModal] = useState(false);
    const toggleTutorial = () => setTutorialModal(!tutorialModal);

    const [activeFriendModal, setActiveFriendModal] = useState(false);
    const toggleActiveFriend = () => setActiveFriendModal(!activeFriendModal);

    const [achievementModal, setAchievementModal] = useState(false);
    const toggleAchievement = () => setAchievementModal(!achievementModal);

    const user = useSelector(state => state?.user?.user)
    const userReferralCOde = useSelector(state => state?.user?.user?.referral_code)
    const totalAccountDeposit = useSelector(state => state?.wallet?.totalAccountDeposit)

    const [isBusy, setIsBusy] = useState(false)
    const [myReferral, setMyReferral] = useState('')

    const fetchReferral = async () => {
        try {
            setIsBusy(true)
            const result = await ReferralService.getAll()
            
            setMyReferral(result ?? '')
            setIsBusy(false)
        } catch (error) {
            // console.log(error)
            setIsBusy(false)
        }
    }

    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: path })
    }

    useEffect(() => {
        // init()
        fetchReferral()
        // eslint-disable-next-line
    }, [])


    const checkCaption = () => {
        if (myReferral?.my_successful_referrals >= 0 && myReferral?.my_successful_referrals <= 5) {
            return t("referralFriend.Bronze")
        } else if (myReferral?.my_successful_referrals >= 6 && myReferral?.my_successful_referrals <= 10) {
            return t("referralFriend.Silver")
        } else if (myReferral?.my_successful_referrals >= 11) {
            return t("referralFriend.Gold")
        }
    }



    return (
        <>
            {totalAccountDeposit >= 200 ?
                <>

                    <Row>
                        <Col lg={12}>
                            <Row>
                                <Col>
                                    <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                        <CardBody style={{ padding: '20px 40px' }}>
                                            {/* <Row style={{ justifyContent: 'space-between' }}> */}
                                            <div>

                                                <h6>
                                                    {t('partner.Total')}{t('referralFriend.Friends')}<br />
                                                    {t('referralFriend.Invited')}
                                                </h6>
                                            </div>


                                            <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                                {isBusy ? (
                                                    <Spinner />
                                                ) : (
                                                        myReferral?.my_referral_history?.meta?.total ?? '-'
                                                    )}
                                            </h3>
                                            {/* </div> */}
                                            {/* </Row> */}
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                        <CardBody style={{ padding: '20px 40px' }}>

                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                <h6>
                                                    {t('referralFriend.Active')}  <br />
                                                    {t('referralFriend.Friends')}{' '}
                                                </h6>

                                                <HelpCircle onClick={toggleActiveFriend} style={{ width: '20px', marginLeft: 'auto', color: '#f9b700', cursor: 'pointer' }} />

                                                <Modal id='achievementModal' isOpen={activeFriendModal} toggle={toggleActiveFriend} >
                                                    <ModalHeader toggle={toggleActiveFriend}>{t("referralFriend.HowToEarn")}</ModalHeader>
                                                    <ModalBody>
                                                        <Col style={{ marginBottom: '25px' }}>


                                                            <p>1. {t("referralFriend.FriendShouldSuccessfully")}</p>
                                                            <p>2. {t("referralFriend.FriendDepositsAMinimum")}</p>
                                                            <p>3. {t("referralFriend.TradingCompletesTrading")}
                                                                <br />
                                                            </p>

                                                            <a style={{ textAlign: 'center' }} href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tnc/TradeHall+Refer+and+Earn.pdf' target='_blank' rel="noopener noreferrer">
                                                                <Button color='primary' >{t("dashboard.View")}</Button>
                                                            </a>

                                                        </Col>

                                                    </ModalBody>

                                                </Modal>
                                            </div>

                                            <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                                {isBusy ? (
                                                    <Spinner />
                                                ) : (
                                                        myReferral?.my_successful_referrals ?? '-'
                                                    )}
                                            </h3>

                                            {/* </Row> */}
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                        <CardBody style={{ padding: '20px 40px' }}>
                                            <div>

                                                <h6>
                                                    {t('partner.Total')} <br />
                                                    {t('referralFriend.TotalEarned')}{' '}
                                                </h6>
                                            </div>

                                            <div style={{ alignSelf: 'flex-end' }}>
                                                <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                                    {isBusy ? (
                                                        <Spinner />
                                                    ) : (
                                                            myReferral?.my_total_rewards ?? '-'
                                                        )}
                                                </h3>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                        <CardBody style={{ padding: '20px 40px' }}>

                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <h6>
                                                    {t('referralFriend.Rank')}
                                                    <br />
                                                </h6>

                                                <HelpCircle onClick={toggleAchievement} style={{ width: '20px', marginLeft: 'auto', color: '#f9b700', cursor: 'pointer' }} />

                                                <Modal id='achievementModal' isOpen={achievementModal} toggle={toggleAchievement} >
                                                    <ModalHeader toggle={toggleAchievement}>{t("referralFriend.AchievementBoard")}</ModalHeader>
                                                    <ModalBody>
                                                        <table style={{ width: '100%' }}>
                                                            <tr style={{ margin: '0px 5px' }}>
                                                                <th>{t("referralFriend.Rank")}</th>
                                                                <th>{t("referralFriend.ActiveFriendsRange")}</th>
                                                                <th>{t("referralFriend.MoneyEarn")}</th>
                                                            </tr>
                                                            <tr style={{ margin: '0px 5px' }}>
                                                                <td>{t("referralFriend.Bronze")}</td>
                                                                <td>1 - 5</td>
                                                                <td>$50</td>
                                                            </tr>
                                                            <tr style={{ margin: '0px 5px' }}>
                                                                <td>{t("referralFriend.Silver")}</td>
                                                                <td>6 - 10</td>
                                                                <td>$70</td>
                                                            </tr>
                                                            <tr style={{ margin: '0px 5px' }}>
                                                                <td>{t("referralFriend.Gold")}</td>
                                                                <td>{t("referralFriend.moreThan11")}</td>
                                                                <td>$100</td>
                                                            </tr>
                                                        </table>
                                                    </ModalBody>

                                                </Modal>
                                            </div>

                                            <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                                {isBusy ? <Spinner /> : checkCaption() ?? '-'}
                                            </h3>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>

                            </Row>

                        </Col>
                    </Row>
                    <Row>

                        <Col lg={6}>
                            <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                <CardHeader className="bg-secondary" style={{ padding: 20 }}>
                                    <h5 className="text-center">
                                        {user?.name}
                                    </h5>
                                </CardHeader>
                                <CardBody style={{ padding: 16 }}>

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-start'
                                        }}
                                    >
                                        <div className="m-r-20">

                                        </div>

                                    </div>



                                    <Row style={{ padding: 28, flexDirection: 'column' }}>
                                        <h6>
                                            <h6>{t("referralFriend.HowToInvite")} <span style={{ color: "#f9b700", cursor: 'pointer' }} onClick={toggleTutorial}><HelpCircle width="15px" /></span> </h6>

                                        </h6>
                                        <CardBody
                                            style={{
                                                padding: '0',
                                                textAlign: 'center',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Input
                                                type="text"
                                                style={{ fontSize: '14px', marginRight: '15px', width: '70%' }}
                                                defaultValue={`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${userReferralCOde}`}
                                                disabled
                                            />

                                            <Modal id='tutorialModal' isOpen={tutorialModal} toggle={toggleTutorial} >
                                                <ModalHeader toggle={toggleTutorial}>{t("referralFriend.HowToInviteFriends")}</ModalHeader>
                                                <ModalBody>
                                                    <div>
                                                        <Col className='d-xs-flex d-sm-flex d-mg-none d-lg-none d-xl-none' lg='12' style={{ marginBottom: '75px', flexDirection: 'column' }}>
                                                            <h1 style={{ textAlign: 'center' }}>{t("referralFriend.UnderstandHowToInvite")}</h1>
                                                            <p style={{ textAlign: 'center' }}>{t("referralFriend.JoinOurInvite")}</p>

                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginBottom: '25px' }}>

                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img src={Step1} alt="step 1" style={{ width: '75px' }} />
                                                                    <h5 >{t("referralFriend.CopyYourReferral")}</h5>
                                                                </div>


                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img src={Step2} alt="step 2" style={{ width: '75px' }} />
                                                                    <h5 >{t("referralFriend.PasteTheReferralLinkToYour")}</h5>
                                                                </div>


                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img src={Step3} alt="step 3" style={{ width: '75px' }} />
                                                                    <h5 >{t("referralFriend.WaitForYourFriendsFinished")}</h5>
                                                                </div>


                                                            </div>

                                                            <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <h6 style={{ color: '#0f3c5a', marginBottom: '0px', marginRight: '25px' }}>{process.env.REACT_APP_BASE_URL}/sign_up?referral_code={userReferralCOde}</h6>
                                                                <div>
                                                                    <CopyToClipboard
                                                                        text={`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${userReferralCOde}`}
                                                                    >
                                                                        <Button
                                                                            color="primary"
                                                                            style={{ borderRadius: '0px' }}
                                                                            onClick={() => {
                                                                                toast.success(`${t("partner.linkCopiedToClipboard")}`, {
                                                                                    position: toast.POSITION.BOTTOM_RIGHT
                                                                                })
                                                                            }}
                                                                        >
                                                                            {t("partner.copyLink")}
                                                                        </Button>
                                                                    </CopyToClipboard>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col className='d-none d-mg-flex d-lg-flex d-xl-flex' lg='12' style={{ textAlign: 'center', marginBottom: '50px', display: 'flex', flexDirection: 'column' }}>
                                                            <h1>{t("referralFriend.UnderstandHowToInvite")}</h1>
                                                            <p>{t("referralFriend.JoinOurInvite")}</p>

                                                            <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '25px' }}>

                                                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', }}>
                                                                    <img src={Step1} alt="step 1" style={{ width: '150px', marginLeft: '-25px' }} />
                                                                    <h3 style={{ marginLeft: '-25px' }}>{t("referralFriend.CopyYour")} <br />{t("referralFriend.referralLink")}</h3>
                                                                </div>


                                                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                                                                    <img src={Step2} alt="step 2" style={{ width: '150px', marginLeft: '-25px' }} />
                                                                    <h3 style={{ marginLeft: '-25px' }}>{t("referralFriend.PasteTheReferralLink")} <br /> {t("referralFriend.toYourFriends")}</h3>
                                                                </div>


                                                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                                                                    <img src={Step3} alt="step 3" style={{ width: '150px', marginLeft: '-25px' }} />
                                                                    <h3 style={{ marginLeft: '-25px' }}>{t("referralFriend.WaitForYourFriends")} <br />{t("referralFriend.finishedRegistration")}</h3>
                                                                </div>


                                                            </div>

                                                            <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <h6 style={{ color: '#0f3c5a', marginBottom: '0px', marginRight: '25px' }}>{process.env.REACT_APP_BASE_URL}/sign_up?referral_code={userReferralCOde}</h6>
                                                                <div>
                                                                    <CopyToClipboard
                                                                        text={`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${userReferralCOde}`}
                                                                    >
                                                                        <Button
                                                                            style={{ borderRadius: '0px' }}
                                                                            onClick={() => {
                                                                                toast.success(`${t("partner.linkCopiedToClipboard")}`, {
                                                                                    position: toast.POSITION.BOTTOM_RIGHT
                                                                                })
                                                                            }}
                                                                        >
                                                                            {t("partner.copyLink")}
                                                                        </Button>
                                                                    </CopyToClipboard>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </ModalBody>

                                            </Modal>

                                            <Row>
                                                <Col className="text-left">
                                                    <CopyToClipboard
                                                        // text={`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${partner?.referral_code}`}
                                                        text={`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${userReferralCOde}`}
                                                    >
                                                        <Button
                                                            color="secondary"
                                                            onClick={() => {
                                                                toast.success(`${t('partner.linkCopiedToClipboard')}`, {
                                                                    position: toast.POSITION.BOTTOM_RIGHT
                                                                })
                                                            }}
                                                        >
                                                            {t('partner.copyLink')}
                                                        </Button>
                                                    </CopyToClipboard>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Row>

                                    <Row
                                        className="m-5"
                                        style={{ marginTop: 12, justifyContent: 'space-between' }}
                                    ></Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                <CardHeader className="bg-secondary" style={{ padding: 20 }}>
                                    <h5 className="text-center">{t("referralFriend.HowToEarn")} </h5>
                                </CardHeader>
                                <CardBody style={{ padding: 16 }}>
                                    {/* <p className="text-left" style={{ padding: '0.75rem' }}>
                            {t("partner.clientsAccounts")} : {partner?.clients?.length ?? 0}
                          </p> */}

                                    <p>1. {t("referralFriend.FriendShouldSuccessfully")}</p>
                                    <p>2. {t("referralFriend.FriendDepositsAMinimum")}</p>
                                    <p>3. {t("referralFriend.TradingCompletesTrading")}</p>

                                    <div style={{ textAlign: 'center' }}>
                                        <a href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tnc/TradeHall+Refer+and+Earn.pdf' target='_blank' rel="noopener noreferrer">
                                            {t("dashboard.View")} Terms and Conditions
                                        </a>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                        {/* {isBusy ?
                            <CardBody style={{ textAlign: "center" }}>
                                <Spinner />
                            </CardBody>

                            :
                            <Col sm={12} md={6} lg={12} xl={6} style={{ margin: 'auto' }}>

                                <Row>
                                    <Col>
                                        <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                            <CardBody style={{ padding: '20px 40px' }}>
                                                <Row style={{ justifyContent: 'space-between' }}>
                                                    <div>
                                                        <img src={TotalClient} alt="Total Client" style={{ width: '60px' }} />
                                                        <h6>{t("referralFriend.TotalFriendsInvited")}</h6>
                                                    </div>
                                                    <div style={{ alignSelf: "flex-end" }}>
                                                        <h1 style={{ marginBottom: '0px', color: '#f9b700' }}>{myReferral?.my_referral_history?.meta?.total}</h1>
                                                    </div>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col>
                                        <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                            <CardBody style={{ padding: '20px 40px' }}>
                                                <Row style={{ justifyContent: 'space-between' }}>
                                                    <div>
                                                        <img src={ActiveClient} alt="Active Client" style={{ width: '60px' }} />
                                                        <h6>{t("referralFriend.ActiveFriends")}</h6>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                                                        <HelpCircle onClick={toggleActiveFriend} style={{ width: '20px', marginLeft: 'auto', color: '#f9b700', cursor: 'pointer' }} />

                                                        <Modal id='achievementModal' isOpen={activeFriendModal} toggle={toggleActiveFriend} >
                                                            <ModalHeader toggle={toggleActiveFriend}>{t("referralFriend.HowToEarn")}</ModalHeader>
                                                            <ModalBody>
                                                                <Col style={{ marginBottom: '25px' }}>


                                                                    <p>1. {t("referralFriend.FriendShouldSuccessfully")}</p>
                                                                    <p>2. {t("referralFriend.FriendDepositsAMinimum")}</p>
                                                                    <p>3. {t("referralFriend.TradingCompletesTrading")}
                                                                        <br />
                                                                    </p>

                                                                    <a style={{ textAlign: 'center' }} href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tnc/TradeHall+Refer+and+Earn.pdf' target='_blank'>
                                                                        <Button color='primary' >{t("dashboard.View")}</Button>
                                                                    </a>

                                                                </Col>

                                                            </ModalBody>

                                                        </Modal>

                                                        <h1 style={{ marginBottom: '0px', color: '#f9b700' }}>{myReferral?.my_successful_referrals}</h1>

                                                    </div>

                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                            <CardBody style={{ padding: '20px 40px' }}>
                                                <Row style={{ justifyContent: 'space-between' }}>
                                                    <div>
                                                        <img src={MonthlyEarn} alt="Montly Earn" style={{ width: '60px' }} />
                                                        <h6>{t("referralFriend.TotalEarned")}</h6>
                                                    </div>

                                                    <div style={{ alignSelf: "flex-end" }}>
                                                        <p className="text-right" style={{ marginBottom: '0px', color: '#f9b700' }}>USD</p>
                                                        <h1 style={{ marginBottom: '0px', color: '#f9b700' }}>{myReferral?.my_total_rewards}</h1>
                                                    </div>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col>
                                        <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                                            <CardBody style={{ padding: '20px 40px' }}>
                                                <Row style={{ justifyContent: 'space-between' }}>
                                                    <div>
                                                        <img src={Caption} alt="Caption" style={{ width: '60px' }} />
                                                        <h6>{t("referralFriend.Rank")}</h6>
                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                                                        <HelpCircle onClick={toggleAchievement} style={{ width: '20px', marginLeft: 'auto', color: '#f9b700', cursor: 'pointer' }} />

                                                        <Modal id='achievementModal' isOpen={achievementModal} toggle={toggleAchievement} >
                                                            <ModalHeader toggle={toggleAchievement}>{t("referralFriend.AchievementBoard")}</ModalHeader>
                                                            <ModalBody>
                                                                <table style={{ width: '100%' }}>
                                                                    <tr style={{ margin: '0px 5px' }}>
                                                                        <th>{t("referralFriend.Rank")}</th>
                                                                        <th>{t("referralFriend.ActiveFriendsRange")}</th>
                                                                        <th>{t("referralFriend.MoneyEarn")}</th>
                                                                    </tr>
                                                                    <tr style={{ margin: '0px 5px' }}>
                                                                        <td>{t("referralFriend.Bronze")}</td>
                                                                        <td>1 - 5</td>
                                                                        <td>80USD</td>
                                                                    </tr>
                                                                    <tr style={{ margin: '0px 5px' }}>
                                                                        <td>{t("referralFriend.Silver")}</td>
                                                                        <td>6 - 10</td>
                                                                        <td>100USD</td>
                                                                    </tr>
                                                                    <tr style={{ margin: '0px 5px' }}>
                                                                        <td>{t("referralFriend.Gold")}</td>
                                                                        <td>{t("referralFriend.moreThan11")}</td>
                                                                        <td>150USD</td>
                                                                    </tr>
                                                                </table>
                                                            </ModalBody>

                                                        </Modal>

                                                        <h1 style={{ marginBottom: '0px', color: '#f9b700' }}>{checkCaption()}</h1>
                                                    </div>




                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                

                            </Col>} */}




                    </Row>

                    <Commissions />
                </>
                :
                <Col >
                    <Card style={{ padding: '30px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', backgroundColor: '#0f3c5a', color: 'white' }}>
                        <h3>{t("event.TermsAndConditions")}</h3>
                        <h5>{t("referralFriend.HowToRefer")}</h5>
                        <p>1. {t("referralFriend.TotalDepositMust")}</p>
                        <p>2. {t("referralFriend.AllClientsAreEligible")}</p>
                        <p>3. {t("referralFriend.OnlyNewClients")}</p>
                        <p>4. {t("referralFriend.TheCommissionIs")}</p>
                        <p>5. {t("referralFriend.TheClientCanTransfer")}</p>
                        <br />

                        <h5>{t("referralFriend.HowToEarn")}</h5>
                        <p>1. {t("referralFriend.FriendShouldSuccessfully")}</p>
                        <p>2. {t("referralFriend.FriendDepositsAMinimum")}</p>
                        <p>3. {t("referralFriend.TradingCompletesTrading")}
                            <br />
                        </p>

                        <a style={{ textAlign: 'center' }} href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tnc/TradeHall+Refer+and+Earn.pdf' target='_blank' rel="noopener noreferrer">
                            <Button color='primary' >{t("dashboard.View")}</Button>
                        </a>
                    </Card>
                </Col>

            }







            {/* {
        selectedSetting !== null && selectedAccount !== null && (
          <Modal isOpen={showModal} backdrop={true} centered={true}>
            <ModalHeader toggle={toggleModal}>{settings[selectedSetting].title}</ModalHeader>
            <ModalBody>{settings[selectedSetting].cta}</ModalBody>
          </Modal>
        )
      } */}
        </>
    )
}

export default IbRoom
