import React, { useState } from 'react'
import { Container, Row, Col, Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import _ from 'lodash'

import Breadcrumb from '../../layout/breadcrumb'
import IbRoom from './ib_room'
import Income from './income'
import LegalDocuments from './legal'
import Referral from './referral'

import Clients from './client'
import Commissions from './commission'
import { useTranslation } from 'react-i18next'

const Partner = () => {
  const { t } = useTranslation()
  const tabs = {
    // beneficial: 'Beneficial become IB',
    ib_room: t("partner.IBRoom"),
    clients: t("partner.attractedClients"),
    income_details: t("partner.incomeDetails"),
    history: t("partner.commissionHistory"),
    referral: t("partner.referralLink"),
    legal: t("partner.legalDocuments")
  }
  const [activeTab, setActiveTab] = useState('ib_room')


  // useEffect(() => {
  //   if (verification?.status === 1 && user?.role?.handle !== 'client' && partners?.length > 0) {
  //     // do nothing
  //   } else {
  //     history.push('/dashboard')
  //   }
  // }, [verification, user, partners])

  return (
    <>
      <Breadcrumb parent={t("partner.PartnerRoom")} title={tabs[activeTab]} />
      <Container>
        <Col lg='12' className="d-none d-md-block d-lg-block d-xl-block" style={{ padding: '0px' }}>
          <Card>
            <Row>
              <Col>
                <Nav tabs className="border-tab" style={{ marginBottom: '0px' }}>
                  {/* <NavItem>
                    <NavLink
                      className={activeTab === 'beneficial' ? 'active' : ''}
                      onClick={() => setActiveTab('beneficial')}
                    >
                      <i className="fa fa-building"></i>
                      {tabs.beneficial}
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={activeTab === 'ib_room' ? 'active' : ''}
                      onClick={() => setActiveTab('ib_room')}
                    >
                      <i className="fa fa-building"></i>
                      {tabs.ib_room}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'clients' ? 'active' : ''}
                      onClick={() => setActiveTab('clients')}
                    >
                      <i className="fa fa-users"></i>
                      {tabs.clients}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'history' ? 'active' : ''}
                      onClick={() => setActiveTab('history')}
                    >
                      <i className="fa fa-history"></i>
                      {tabs.history}
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={activeTab === 'referral' ? 'active' : ''}
                      onClick={() => setActiveTab('referral')}
                    >
                      <i className="fa fa-user"></i>
                      {tabs.referral}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'legal' ? 'active' : ''}
                      onClick={() => setActiveTab('legal')}
                    >
                      <i className="fa fa-file-text"></i>
                      {tabs.legal}
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </Col>
            </Row>
          </Card>
        </Col>

        <Row>
          <Col sm="12" lg="4" xl="3" className="project-list d-md-none d-lg-none d-xl-none">
            <Card>
              <Row>
                <Col>
                  <Nav tabs className="border-tab">
                    {/* <NavItem>
                      <NavLink
                        className={activeTab === 'beneficial' ? 'active' : ''}
                        onClick={() => setActiveTab('beneficial')}
                      >
                        <i className="fa fa-building"></i>
                        {tabs.beneficial}
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        className={activeTab === 'ib_room' ? 'active' : ''}
                        onClick={() => setActiveTab('ib_room')}
                      >
                        <i className="fa fa-building"></i>
                        {tabs.ib_room}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'clients' ? 'active' : ''}
                        onClick={() => setActiveTab('clients')}
                      >
                        <i className="fa fa-users"></i>
                        {tabs.clients}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'history' ? 'active' : ''}
                        onClick={() => setActiveTab('history')}
                      >
                        <i className="fa fa-history"></i>
                        {tabs.history}
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={activeTab === 'referral' ? 'active' : ''}
                        onClick={() => setActiveTab('referral')}
                      >
                        <i className="fa fa-user"></i>
                        {tabs.referral}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'legal' ? 'active' : ''}
                        onClick={() => setActiveTab('legal')}
                      >
                        <i className="fa fa-file-text"></i>
                        {tabs.legal}
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col sm="12" lg="12" xl="12">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="ib_room">
                <IbRoom />
              </TabPane>
              <TabPane tabId="clients">
                <Clients />
              </TabPane>
              <TabPane tabId="income_details">
                <Income />
              </TabPane>
              <TabPane tabId="history">
                <Commissions />
              </TabPane>
              <TabPane tabId="referral">
                <Referral />
              </TabPane>
              <TabPane tabId="legal">
                <LegalDocuments />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Partner
