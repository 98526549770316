import React, { useRef, useEffect, useState } from 'react'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    Label,
    FormGroup,
    CardImg
} from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import SweetAlert, { swal } from 'sweetalert2'
import moment from 'moment'
import { useSelector } from 'react-redux'

import Breadcrumb from '../../../layout/breadcrumb'
import { BASE_URL } from '../../../network/constants'
import PromoteService from '../../../network/services/promotionLanding'
import AccountService from "../../../network/services/account"
import { useTranslation } from 'react-i18next'

const PromotionLanding = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [promotionDetails, setPromotionDetails] = useState([])
    const [accounts, setAccounts] = useState()
    const [error, setError] = useState(null)
    const user = useSelector((state) => state.user.user)
    const { register, handleSubmit, errors, reset } = useForm()

    const fetchPromotion = async () => {
        const result = await PromoteService.get(id)
        setPromotionDetails(result.promotion)
    }

    const init = async () => {
        // live accounts
        const result = await AccountService.getAll({ filters: { account_type: 0 } })
        setAccounts(result.accounts)
    }

    useEffect(() => {
        fetchPromotion()
        init()
        // eslint-disable-next-line
    }, [])

    const termAndCondition = () => {
        return (<a
            href={promotionDetails.file_url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'inline-block', width: '75%' }}
            className="mb-3"
        >
            {t("promotion.TermsConditions")}
        </a>
        )
    }

    const onSubmit = async data => {

        SweetAlert.fire({
            
            title: `${t("promotion.AreYouSureRedeem")}`,
            html: `<p>${t("promotion.ByClicking")} <strong>${t("promotion.Confirm")}</strong>, ${t("promotion.youAgreeToOur")} <a
            href="${promotionDetails.file_url}"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'inline-block', width: '75%' }}
            className="mb-3"
        >
            <span>${t("promotion.TermsConditions")}</span>
        </a></p>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("promotion.Confirm"),
            cancelButtonText: t("promotion.Cancel"),
            reverseButtons: true
        }).then(async (result) => {
            if (result.value) {
                try {
                    if (data !== '') {
                        const result = await PromoteService.create(promotionDetails.id, data)
                        SweetAlert.fire({
                            title: t("promotion.ThankYouForYourApplication"),
                            html: `${t("promotion.YourRequestWillReviewDdmin")} <br/>${t("promotion.PleaseCheckYourApplicationStatusOnPromotionPage")}`,
                            icon: 'success'
                        }).then(function() {
                            window.location.href = `/promotion`;
                        })
                    
                        if (result.user) {
                            reset()
                            setError(null)

                        }
                    } else {
                        errors.showMessages()
                    }

                } catch (error) {
                    // SweetAlert.fire({
                    //     title: `${error.message}`,
                    //     icon: "error"
                    // })
                    const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
                    setError(error.message)
                }
            }
        })
    }



    return (
        <>
            <Breadcrumb parent={`${t("promotion.Promotion")} / ${promotionDetails.name}`} title={t("promotion.Promotion")} />

            <Row style={{ margin: '0' }}>


                <CardBody style={{ padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Col lg='9'>
                        <CardImg style={{ borderRadius: '20px 20px 0 0', backgroundColor: 'white' }} src={promotionDetails.image_url} />
                    </Col>
                    <Col lg='9'>
                        <Card id="cardFlexDirection" style={{ borderRadius: '0px 0px 20px 20px', paddingTop: '20px' }}>
                            <Col lg='7' className="mb-3">
                                <h5>{promotionDetails.name}</h5>
                                <p style={{ whiteSpace: 'pre-wrap', paddingRight: '30px', textAlign:'justify' }}>{promotionDetails.description}</p>
                                <a
                                    href={promotionDetails.file_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ display: 'inline-block', width: '75%' }}
                                    className="mb-3"
                                >
                                    <Button
                                        
                                        style={{ borderRadius: '5px', width: '100%', fontSize:'12px' }}
                                    // disabled={!promotion.available}
                                    >
                                        {t("promotion.TermsConditions")}
                                    </Button>
                                </a>
                            </Col>

                            <Col lg='5' className="mb-3">

                                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>

                                    <Row>
                                        <Col>

                                            <FormGroup>
                                                <h5 className="mb-3">{t("promotion.SelectMT5LIVEAccount")}</h5>
                                                <Input type="hidden" innerRef={register({ required: true })} name="promotion_id" value={promotionDetails.id} />

                                                <Input
                                                    type="select"
                                                    name="account"
                                                    className="form-control digits mb-2"
                                                    innerRef={register({ required: true })}
                                                >
                                                    <option value="">{t("promotion.PleaseSelectAnAccount")}</option>
                                                    {accounts?.length > 0 &&
                                                        accounts.map((account, index) => {
                                                            return (
                                                                <option key={index} value={account.account_login}>{account.account_login}</option>
                                                            )
                                                        })}
                                                </Input>

                                                <Input type="checkbox" required style={{ marginLeft: '0' }} />
                                                <p style={{ marginLeft: '1.25rem', marginBottom: '0' }}>{t("promotion.ByClicking")} <strong>{t("promotion.Apply")}</strong>, {t("promotion.youAgreeToOur")} <a style={{ fontSize: '' }} href={promotionDetails.file_url}
                                                    target="_blank">{t("promotion.TC")}</a></p>
                                                {error != null && <span className="mb-3" style={{ color: 'red' }}>{error}</span>}
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-0">
                                                <Button color="primary" className="mt-1" style={{fontSize:'12px'}}>
                                                {t("promotion.Apply")}
                                            </Button>

                                            </FormGroup>

                                        </Col>
                                    </Row>
                                </Form>


                            </Col>
                        </Card>
                    </Col>
                </CardBody>


            </Row>



            {/* 


            <Row>
                <Card>
                    
                </Card>

                <Card>
                    
                </Card>
            </Row> */}



        </>
    )
}

export default PromotionLanding
