import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { Link } from 'react-router-dom'

import FeedbackService from '../../network/services/feedback'
import NotificationService from '../../network/services/notification'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Col, Row } from 'reactstrap'


const TicketTable = ({ refresh }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const readNotification = async (id) => {
    const readNotification = await NotificationService.readTheNotification(id)
    const notificationResponse = await NotificationService.getAll()
        const filterUnreadResponse = notificationResponse?.user_notifications?.data.filter(notification=>notification.is_read == false)

    dispatch({
      type: 'SET_UNREAD_NOTIFICATION',
      unread_notifications: filterUnreadResponse
    })
  }

  const columns = [
    {
      name: t("monitoring.ticket"),
      selector: 'id',
      sortable: true
    },
    {
      name: t("support.subject"),
      selector: 'subject',
      width: '200px',
      sortable: true
    },
    // {
    //   name: t("support.message"),
    //   selector: 'message',
    //   sortable: true
    // },
    // {
    //   name: t("support.updated"),
    //   selector: 'created_at',
    //   sortable: true,
    //   width: '200px',
    //   format: (row) => {
    //     return moment(row.updated_at).format('yyyy-MM-DD hh:mmA')
    //   }
    // },
    {
      name: t("support.Status"),
      selector: 'is_read',
      sortable: true,
      format: (row) => {
        return row?.is_read === true ?
          row?.status === 0 ? t("support.New") :
            row?.status === 1 ? t("support.InProgress") :
              row?.status === 2 ? t("support.Resolved") :
                row?.status === 3 ? t("support.Rejected") :
                  ''
          : row?.is_read === false ? <span style={{ color: 'green' }}>{t("support.NewMessage")}</span>
            : '-'
      }
    },
    {
      name: t("support.action"),
      selector: 'actions',
      format: (row) => {
        return row?.status !== 2 ? <Link
          onClick={() => { readNotification(row.id) }}
          to={`support/${row.id}`}>
          {t("support.Reply")}
        </Link> :

          <Link
            style={{ color: '#103c5a' }}
            onClick={() => { readNotification(row.id) }}
            to={`support/${row.id}`}>
            {t("partner.View")}
          </Link>
      }
    }
  ]

  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [selectedFilter, setSelectedFilter] = useState('')

  const handlePageChange = async (page) => {
    const result = await FeedbackService.getAll({ page })
    setData(result.feedbacks.data)
  }

  const init = async () => {
    const result = await FeedbackService.getAll({ status: selectedFilter === 0 ? '' : selectedFilter })


    setTotal(result.feedbacks?.meta?.total ?? 0)
    setData(result.feedbacks?.data ?? [])
  }

  useEffect(() => {
    init()
  }, [refresh, selectedFilter])



  return (
    <>
      {/* <h5>{t("support.tickets")}</h5> */}
      <Row style={{ justifyContent: 'space-evenly' }}>

        <div style={{ cursor: 'pointer', marginBottom: '10px' }} className={`${selectedFilter === 0 || selectedFilter === "" ? 'isFeedbackActive' : ''}`} onClick={(e) => setSelectedFilter(0)}>
          <h6 className={`${selectedFilter === 0 || selectedFilter === "" ? 'isFeedbackActive' : ''} mt-1 mb-1 ml-2 mr-2`}>
            {t("user.All")}
          </h6>
        </div>

        <div style={{ cursor: 'pointer', marginBottom: '10px' }} className={`${selectedFilter === 1 ? 'isFeedbackActive' : ''}`} onClick={(e) => setSelectedFilter(1)}>
          <h6 className={`${selectedFilter === 1 ? 'isFeedbackActive' : ''} mt-1 mb-1 ml-2 mr-2`}>
            {t("support.InProgress")}
          </h6>
        </div>

        <div style={{ cursor: 'pointer', marginBottom: '10px' }} className={`${selectedFilter === 2 ? 'isFeedbackActive' : ''}`} onClick={(e) => setSelectedFilter(2)}>
          <h6 className={`${selectedFilter === 2 ? 'isFeedbackActive' : ''} mt-1 mb-1 ml-2 mr-2`}>
            {t("support.Resolved")}
          </h6>
        </div>
      </Row>

      <div id="supportTable" className="product-wrapper-grid" style={{ marginTop: 16 }}>
        <DataTable
          noHeader
          data={data}
          columns={columns}
          striped={true}
          center={true}
          pagination
          paginationComponentOptions={{ noRowsPerPage: true }}
          paginationServer
          paginationTotalRows={total}
          onChangePage={handlePageChange}
          style={{ paddingBottom: 8 }}


          subHeader
          subHeaderComponent={
            <p id='sub-header-support' style={{ textAlign: 'left', height: 'auto', marginBottom: '4px', fontWeight:'500' }}>
              *{t("support.OperationHour")}
          </p>
          }
          subHeaderAlign="left"
        />
      </div>
    </>
  )
}

export default TicketTable
