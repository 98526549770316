import React, { useEffect } from 'react'
import { Row, Col, Spinner, CardBody, Card } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'

import { HoverCard, ResponsiveCard, ResponsiveIcon, ResponsiveDiv } from '../../components.style'
import { useTranslation } from 'react-i18next'
import AccountService from '../../../../network/services/account'

const SelectAccount = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const liveAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter((e) => e.account_type !== 1)
    }

    return []
  })
  const withdrawal = useSelector((state) => state.withdrawal)

  useEffect(() => {
    if (liveAccounts?.length > 0 && withdrawal?.mt5?.account == null) {
      dispatch({ type: 'SET_WITHDRAWAL_MT5_ACCOUNT', account: liveAccounts[0].id })
    }
    // eslint-disable-next-line
  }, [liveAccounts])

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const init = async () => {
    const accountResponse = await AccountService.getAll()
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
  }

  return (
    <>
      <h5>{t('financial.selectMT5Account')}</h5>

      <Row>
        {liveAccounts != null ? (
          liveAccounts?.length > 0 &&
          liveAccounts.map((account) => {
            return (
              <Col sm="12" lg="6" key={account.id}>
                <HoverCard
                  isSelected={withdrawal?.mt5?.account === account.id ? '#f9b600' : null}
                  onClick={() => {
                    dispatch({ type: 'SET_WITHDRAWAL_MT5_ACCOUNT', account: account.id })
                  }}
                >
                  <ResponsiveCard>
                    <ResponsiveIcon className="icofont icofont-chart-line-alt" />
                    <ResponsiveDiv>
                      <h6>{`${account.account_login} - ${account.plan?.name}`}</h6>
                      <p style={{ color: '#038004' }}>{`${t(
                        'financial.availableBalanceInAccount'
                      )} ${account.balance === null ? '-' : account.balance?.toFixed(2)}`}</p>
                    </ResponsiveDiv>
                  </ResponsiveCard>
                </HoverCard>
              </Col>
            )
          })
        ) : (
          <Col>
            <Card>
              <CardBody style={{ textAlign: 'center' }}>
                <Spinner color="warning"/>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </>
  )
}

export default SelectAccount
