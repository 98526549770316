import React, { useState, useEffect } from 'react'
import { Minimize, Bell } from 'react-feather'
import { translate } from 'react-switch-lang'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'
import _ from 'lodash'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import DarkMode from '../../components/header/dark_mode'
import Profile from '../../components/header/profile'
import LanguageIcon from '../../language.png'



import { toast } from "react-toastify"

import { useTranslation } from 'react-i18next'

import ReactGA from 'react-ga'

const HighlightDiv = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  &:hover {
    transition: all 0.5s ease-in-out;
    border-bottom: 1px solid rgb(249, 182, 0);
  }
`

// const MobileOnly = styled.div`
//   display: none;

//   @media (max-width: 575px) {
//     display: block;
//   }
// `

const Rightbar = () => {

  const trackPersonal = () => {
    ReactGA.event({
      category: 'Top Header',
      action: 'Click Personal Details',
      label: 'Track Personal Details'
    })
  }

  const trackVerify = () => {
    ReactGA.event({
      category: 'Top Header',
      action: 'Click KYC Verify',
      label: 'Track KYC Verify'
    })
  }

  let history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const verification = useSelector((state) => _.last(state.user.verifications))
  const unread_notification = useSelector((state) => state.notification?.unread_notifications)

  // const [unReadNotificationCount, setUnReadNotificationCount] = useState([])

  // const [languageSelected, setLanguageSelected] = useState('en')
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

  const language = sessionStorage.getItem('language')

  const languageToggle = () => setLanguageDropdownOpen(prevState => !prevState);

  // full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  const { t, i18n } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    sessionStorage.setItem('language', language)
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  // const init = async () => {
  //   const result = await NotificationService.getUnredNotication()

  //   setUnReadNotificationCount(result?.my_unread_feedbacks?.length)

  //   if (result?.my_unread_feedbacks?.length) {
  //     return <>
  //       {toast.success(
  //         <Link to='/notification' style={{ color: 'white' }}>
  //           You have {result?.my_unread_feedbacks?.length} notification
  //           </Link>
  //         , {
  //           position: toast.POSITION.BOTTOM_RIGHT,
  //         },

  //       )}
  //     </>

  //   }
  // }

  useEffect(() => {
    if (unread_notification?.length && !window.location.toString().includes("notification")) {
      return <>

        {toast.success(
          <Link to='/notification' style={{ color: 'white' }}>
            {t("header.YouHave")} {unread_notification?.length} {t("header.notification")}
          </Link>
          , {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            className: 'toast-notification'
          },

        )}

      </>

    }
  }, [])


  return (
    <>
      <div className="nav-right col-10 pull-right right-menu">
        <ul className="nav-menus">
          <li onClick={() => trackPersonal()}>
            <HighlightDiv
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/account`)
              }}
            >
              {user?.profile != null && !_.isEmpty(user.profile) ? (
                <i
                  className="fa fa-check-circle"
                  style={{ color: 'green', fontSize: 20, marginRight: 4 }}
                ></i>
              ) : (
                  <i
                    className="fa fa-times-circle"
                    style={{ color: 'red', fontSize: 20, marginRight: 4 }}
                  ></i>
                )}
              {t("header.personalInformation")}
            </HighlightDiv>
          </li>
          <li onClick={() => trackVerify()}>
            <HighlightDiv
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/account#verification`)
              }}
            >
              {verification?.status === 1 ? (
                <i
                  className="fa fa-check-circle"
                  style={{ color: 'green', fontSize: 20, marginRight: 4 }}
                ></i>
              ) : (
                  <i
                    className="fa fa-times-circle"
                    style={{ color: 'red', fontSize: 20, marginRight: 4 }}
                  ></i>
                )}
              {t("header.verification")}
            </HighlightDiv>
          </li>

          <li className="onhover-dropdown d-none d-md-block d-lg-block d-xl-block">
            <div
              className="notification-box"
            >
              <Link to='/notification'>
                <Bell />
              </Link>
              <span className="badge badge-pill badge-primary">{unread_notification?.length}</span>
            </div>
          </li>

          {/* <MobileOnly>
            <li className=" d-lg-none d-xl-none">
            <Dropdown isOpen={languageDropdownOpen} toggle={languageToggle}   style={{ display: 'flex', justifyContent: 'flex-end', backgroundColor: '#f8f8f8' }}>
              <DropdownToggle id="mobileLangugeDropdown"  style={{padding:'0', backgroundColor:'white', borderColor:'white'}}>
                <img src={LanguageIcon} alt="languageIcon" width='18px' height="18px" className="m-r-5" style={{marginBottom:'5px'}} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => changeLanguage('en')} >English</DropdownItem>
                <DropdownItem onClick={() => changeLanguage('zh')}>中文简体</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
          </MobileOnly> */}
          <li className="maximize">
            <DarkMode />
          </li>
          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Minimize />
            </a>
          </li>
          <li className="onhover-dropdown p-0">
            <Profile />
          </li>

          {/* 中文切换 */}
          <li onMouseEnter={languageToggle} onMouseLeave={languageToggle}>
            <Dropdown isOpen={languageDropdownOpen} toggle={languageToggle} style={{ display: 'flex', justifyContent: 'flex-end', backgroundColor: '#f8f8f8', borderRadius: '5px' }}>
              <DropdownToggle id="langugeDropdown" style={{ display: 'flex' }}>
                <img src={LanguageIcon} alt="languageIcon" width='18px' height="18px" className="m-r-5" />
                {language === "en" && <span className="d-none d-lg-block d-xl-block">EN</span>}
                {language === "zh" && <span className="d-none d-lg-block d-xl-block">ZH</span>}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => changeLanguage('en')} >English</DropdownItem>
                <DropdownItem onClick={() => changeLanguage('zh')}>中文简体</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </>
  )
}
export default translate(Rightbar)
