import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Button,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    Progress,
    Badge,
    FormGroup,
    Label,
    Input,
    CardTitle,
    CardText,

} from 'reactstrap'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import LiveSettings from '../common/settings'
import MasterPassword from '../common/settings/master_password'
import InvestorPassword from '../common/settings/investor_password'
import ChangeLeverage from '../common/settings/leverage'
import Deposit from '../common/settings/deposit'
// import RedeemBonus from '../common/settings/redeem_bonus'

import { StyledRow, ResponsiveLiveDiv } from '../common/components.style'

import AccountService from '../../network/services/account'
import WalletService from '../../network/services/wallet'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

// import RedeemIcon from './redeemIcon.svg'
import { Steps, Hints } from 'intro.js-react'

import GoldenClude from './Premium-01.png'
import DiamondClub from './Premium-02.png'
import VVIP from './Premium-03.png'

import VePackage from './VePackage/packageInfo'
import SweetAlert from 'sweetalert2'

import { useForm } from 'react-hook-form'

import styled from 'styled-components'

import './index.scss'

import ScrollDownIcon from './down-arrow.png'

import 'react-accessible-accordion/dist/fancy-example.css';

import GreenChecked from './checked.png'

function VePackageModal({ account, accountDetails, vePremiumUpgradeModal, setVePremiumUpgradeModal, upgradeVePremiumModalToggle }) {


    const { t } = useTranslation()

    const [personalDetailsModal, setPersonalDetailsModal] = useState(false)
    const [packageSelect, setPackageSelect] = useState('')
    const [applicateName, setApplicateName] = useState('')
    const [identity, setIdentity] = useState('')
    const [isBusy, setIsBusy] = useState(false)
    const userProfile = useSelector((state) => state.user.profile)

    const { register, handleSubmit, errors, formState } = useForm()

    const personalDetailsModalToggle = () => {
        setPersonalDetailsModal(!personalDetailsModal)
    }

    const [selectedPackage, setSelectedPackage] = useState(2)

    const handlePackageApplication = () => {

        setIsBusy(true)
        SweetAlert.fire({
            title: 'Are you sure you want to upgrade package?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('financial.confirm'),
            cancelButtonText: t('financial.cancel'),
            reverseButtons: true
        }).then(async (result) => {
            setIsBusy(false)
            if (result.value) {
                try {

                    let result = await AccountService.upgradePackage(accountDetails.id, {
                        package_id: packageSelect,
                        applicant_name: applicateName,
                        identification_number: identity
                    })

                    if (result?.package_application?.id) {
                        SweetAlert.fire({
                            title: 'Applied Package Successfully',
                            icon: 'success'
                        }).then(() => {
                            setIsBusy(false)
                            setVePremiumUpgradeModal(!vePremiumUpgradeModal)
                            window.location.reload()
                        })
                    }

                } catch (error) {
                    // SweetAlert.fire({
                    //   title: error.message,
                    //   icon: 'error'
                    // })
                    setIsBusy(false)
                    const errorCode = error.code
                    const errorTranslate = error.translate_params
                    SweetAlert.fire({
                        title: t(errorCode, errorTranslate),
                        icon: 'error'
                    }).then(() => {
                        window.location.href = '/financial'
                    })
                }
            }
        })
    }

    const faqItems = [

        {
            heading: t('live.WhatBenefitsCanIget'),
            content: ''
        },
        {
            heading: t('live.CanIUpgradeMyStarterPackage'),
            content: t('live.YesIfYouDepositUSD')
        },
        {
            heading: t('live.IfMyExistingAccount'),
            content: t('live.NoInTheFuture')
        },
        {
            heading: t('live.DoesGoldenDiamondClub'),
            content: t('live.YesDifferentPackages')
        },
        {
            heading: t('live.IsItPossibleToReview'),
            content: t('live.AbsolutelyAllOfOur')
        },
        {
            heading: t('live.AreGoldenDiamondClubClasses'),
            content: t('live.WeHaveRecordedAndUploaded')
        }
    ]

    return (
        userProfile.hasOwnProperty('id') ?
            <Modal
                id="upgrade-live-modal"
                isOpen={account.id === accountDetails?.id && vePremiumUpgradeModal}
                toggle={upgradeVePremiumModalToggle}
                backdrop='static'

            >
                <ModalHeader toggle={upgradeVePremiumModalToggle} centered className='modalHeader-Package'>

                </ModalHeader>
                <ModalBody>
                    {/* Control CSS display property  */}
                    <Row style={{ justifyContent: 'center', paddingBottom: '20px' }}>
                        <h2 style={{ color: 'white', fontWeight: '300' }}>
                            {t('live.PremiumPackage')}
                        </h2>
                    </Row>
                    <div
                        className="d-block d-sm-block d-md-flex d-lg-flex d-xl-flex"
                        style={{ justifyContent: 'space-between', marginTop: '20px' }}
                    >
                        <Col lg='1'></Col>
                        {/* <Col lg='3'>
                            <img
                                style={{
                                    width: '100%',
                                    margin: '0 auto'
                                }}
                                src={GoldenClude}
                                alt="golden club"
                            />
                            <Card
                                className='responsiveCardVePack'
                                style={{
                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                    paddingBottom: '65px',

                                }}
                                onClick={() => {
                                    setSelectedPackage(1)
                                }}
                                className='premiumPackageCard'
                            >
                                <div
                                    style={{
                                        padding: '1rem 0',
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px'
                                    }}
                                >

                                    <Button
                                        style={{
                                            width: '75%',
                                            margin: '10px auto 15px auto',

                                        }}
                                        className='packageButton'
                                        onClick={() => {
                                            // handlePackageApplication(4)
                                            setPackageSelect(4)
                                            personalDetailsModalToggle(account)
                                        }}
                                    >
                                        {t('live.UpgradeToGoldenClub')}
                                    </Button>

                                    <div style={{ display: 'flex' }}>
                                        <div style={{ margin: '0 auto' }}>
                                            {VePackage?.goldenClub?.benefits?.map((benefit, index) => {
                                                return (
                                                    <div style={{ display: 'flex' }} key={index}>
                                                        <div style={{ marginRight: '10px' }}>
                                                            <img src={GreenChecked} style={{ width: '16px' }} alt='greenChecked' />
                                                        </div>
                                                        <p style={{ marginBottom: "10px", fontSize: '14px' }}>
                                                            {t(benefit?.name)}
                                                        </p>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>

                            </Card>




                        </Col> */}

                        <Col lg='3'>
                            <img
                                style={{
                                    width: '100%',
                                    margin: '0 auto'
                                }}
                                src={DiamondClub}
                                alt="diamond club"
                            />
                            <Card
                                className='responsiveCardVePack'
                                style={{
                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                    paddingBottom: '30px',


                                }}
                                onClick={() => {
                                    setSelectedPackage(2)
                                }}
                                className='premiumPackageCard'
                            >
                                <div
                                    style={{
                                        padding: '1rem 0',
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px'
                                    }}
                                >

                                    <Button
                                        style={{
                                            width: '75%',
                                            margin: '10px auto 15px auto',

                                        }}
                                        className='packageButton'
                                        onClick={() => {
                                            // handlePackageApplication(5)
                                            setPackageSelect(5)
                                            personalDetailsModalToggle(account)
                                        }}
                                    >
                                        {t('live.UpgradeToDiamondClub')}
                                    </Button>

                                    <div style={{ display: 'flex' }}>
                                        <div style={{ margin: '0 auto' }}>
                                            <p style={{ marginBottom: "10px", fontSize: '16px' }}>
                                                {t('live.EverythingInGoldenClub')}
                                            </p>
                                            {VePackage?.diamondClub?.benefits?.map((benefit, index) => {
                                                return (
                                                    <div style={{ display: 'flex' }} key={index}>
                                                        <div style={{ marginRight: '10px' }}>
                                                            <img src={GreenChecked} style={{ width: '16px' }} alt='greenChecked' />
                                                        </div>
                                                        <p style={{ marginBottom: "10px", fontSize: '14px' }}>
                                                            {t(benefit?.name)}
                                                        </p>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </Card>

                        </Col>

                        <Col lg='3'>
                            <img
                                style={{
                                    width: '100%',
                                    margin: '0 auto'
                                }}
                                src={VVIP}
                                alt="VVIP"
                            />
                            <Card
                                className='responsiveCardVePack'
                                style={{
                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                    borderRadius: '5px',
                                    textAlign: 'center',


                                }}
                                onClick={() => {
                                    setSelectedPackage(3)
                                }}
                                className='premiumPackageCard'
                            >
                                <div
                                    style={{
                                        padding: '1rem 0',
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px'
                                    }}
                                >
                                    <Button
                                        style={{
                                            width: '75%',
                                            margin: '10px auto 15px auto',

                                        }}
                                        className='packageButton'
                                        onClick={() => {
                                            // handlePackageApplication(6)
                                            setPackageSelect(6)
                                            personalDetailsModalToggle(account)
                                        }}
                                    >
                                        {t('live.UpgradeToVVIP')}
                                    </Button>

                                    <div style={{ display: 'flex' }}>
                                        <div style={{ margin: '0 auto' }}>
                                            <p style={{ marginBottom: "10px", fontSize: '16px' }}>
                                                {t('live.EverythingInDiamondClub')}
                                            </p>
                                            {VePackage?.VVIP?.benefits?.map((benefit, index) => {
                                                return (
                                                    <div style={{ display: 'flex' }} key={index}>
                                                        <div style={{ marginRight: '10px' }}>
                                                            <img src={GreenChecked} style={{ width: '16px' }} alt='greenChecked' />
                                                        </div>
                                                        <p style={{ marginBottom: "10px", fontSize: '14px' }}>
                                                            {t(benefit?.name)}
                                                        </p>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>

                            </Card>


                        </Col>

                        <Col lg='1'></Col>
                    </div>

                    <Row style={{ justifyContent: 'center', marginTop: '25px', marginBottom: '25px', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ marginBottom: '10px', color: 'white' }}>{t('live.ScrollFor')}</p>
                        <img src={ScrollDownIcon} alt='scroll down icon' style={{ width: '30px' }} />
                    </Row>

                    <Row style={{ justifyContent: 'center', backgroundColor: '#F5F7FA', padding: '2rem 0rem' }}>
                        <Col lg='8'>
                            <h5 style={{ textAlign: 'center', marginBottom: '25px' }}>{t('live.FrequentlyAskedQuestions')}</h5>

                            <Accordion allowZeroExpanded allowMultipleExpanded>
                                {faqItems.map((item, index) => (
                                    <AccordionItem key={index}  >
                                        <AccordionItemHeading>
                                            <AccordionItemButton style={{ backgroundColor: '#FFF' }}>
                                                {item.heading}
                                            </AccordionItemButton>

                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            {item.content}
                                            {item.heading === t('live.WhatBenefitsCanIget') &&
                                                <a
                                                    href={sessionStorage.getItem('language') === 'en' ? 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/CRM+package-01.jpg' : 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/CRM+package-01.jpg'}
                                                    target='_blank'
                                                >
                                                    {t('live.ClickForMoreDetails')}
                                                </a>
                                            }
                                        </AccordionItemPanel>

                                    </AccordionItem>
                                ))}
                            </Accordion>

                        </Col>
                    </Row>

                    {/* Modal for personal details  */}
                    <Modal
                        // id="upgrade-live-modal"
                        isOpen={account.id === accountDetails?.id && personalDetailsModal}
                        toggle={personalDetailsModalToggle}
                        centered
                    >
                        <ModalHeader toggle={personalDetailsModalToggle} centered>
                            {t("live.FillInYourDetails")}
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label className="col-form-label pt-0">
                                    {t("live.ApplicantName")}
                                    <span style={{ color: 'red' }}>*</span>
                                    <br />
                                    <span style={{ fontSize: '12px' }}>{t("live.EnterYourNameAccording")}</span>
                                </Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name="identification_number"
                                    innerRef={register({ required: true })}
                                    defaultValue=""
                                    onChange={(e) => { setApplicateName(e.target.value) }}
                                    placeholder="e.g. JohnSon"
                                >
                                </Input>
                                <span style={{ color: 'red' }}>{errors.identity && t("user.IdentityIsRequired")}</span>
                            </FormGroup>

                            <FormGroup>
                                <Label className="col-form-label pt-0">
                                    {t("user.IdentityCardPassportNumber")}
                                    <span style={{ color: 'red' }}>*</span>
                                </Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name="identification_number"
                                    innerRef={register({ required: true })}
                                    defaultValue=""
                                    onChange={(e) => { setIdentity(e.target.value) }}
                                    placeholder="e.g. 901111012345"
                                >
                                </Input>
                                <span style={{ color: 'red' }}>{errors.identity && t("user.IdentityIsRequired")}</span>
                            </FormGroup>

                            <p style={{ color: 'red' }}>
                                {packageSelect === 4 && t("live.Reminder400")}
                                {packageSelect === 5 && t("live.Reminder2000")}
                                {packageSelect === 6 && t("live.Reminder4000")}
                            </p>

                            <a href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Enrollment+Form+Sep+2022+-+EN.pdf' style={{ textDecoration: 'underline' }} target="_blank">
                                {t("live.ReadTermsConditions")}
                            </a>
                            <p>
                                {t("live.ThePurposeOfThisAction")}
                            </p>

                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => handlePackageApplication()} disabled={applicateName === '' || identity === '' || isBusy} color='primary' style={{ float: 'right' }}>{t('demo.submit')}</Button>
                                {isBusy && <Spinner color='warning' />}

                            </div>
                        </ModalBody>
                    </ Modal>

                </ModalBody>
            </Modal>
            :
            // Person who not fill in personal details
            <Modal

                isOpen={account.id === accountDetails?.id && vePremiumUpgradeModal}
                toggle={upgradeVePremiumModalToggle}
                centered
            >
                <ModalHeader toggle={upgradeVePremiumModalToggle} centered>
                    Premium Package Upgrade
        </ModalHeader>
                <ModalBody>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ marginBottom: '0' }}>
                            Fill In Personal Details First
              </p>
                        <Link to='/account#details'>
                            <Button>Fill In</Button>
                        </Link>
                    </div>
                </ModalBody>
            </ Modal>

    )
}

export default VePackageModal
