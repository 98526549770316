import React from 'react'
import { Card, CardBody, Button } from 'reactstrap'
import TransferForm from './form'
import { useTranslation } from 'react-i18next'

const MT5Transfer = ({ setOption }) => {
  const { t } = useTranslation()
  return (
    <>
      <Card>
        <CardBody>
          <TransferForm />
        </CardBody>
      </Card>

      <Button
        color="primary"
        onClick={() => {
          setOption(null)
        }}
      >
        {t("financial.back")}
      </Button>

      {/* Add gap */}
      <div style={{ padding: 24, clear: 'both' }} />
    </>
  )
}

export default MT5Transfer
