import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Card,
    Badge,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap'

import CourseService from '../../../network/services/courses'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'



import './pammManager.scss'

import "intro.js/introjs.css";
import { Steps } from 'intro.js-react';

import ReactGA from 'react-ga'

const EnglishCourse = (props) => {
    let history = useHistory()
    const dispatch = useDispatch()
    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: path })
    }

    const { t } = useTranslation()


    const toChapterPage = (course, courseID) => {
        console.log('course',course)
        if (course?.is_course_available) {
            navigate(`${process.env.PUBLIC_URL}/courses/${course?.id}/chapters`)
        } else if (course.id === 14 || course.id === 15 || course.id === 16 || course.id === 17 || course.id === 18 || course.id === 19) {
            SweetAlert.fire({
                title: `${t('courses.ThisCourseIsBelongsTo')} ${course?.name}`,
                html: `<p>${t('courses.YouMay')} <a href="/live" target="_blank">${t('courses.Upgrade')}</a> ${t('courses.ToThisPackageToViewThisContent')}</p>`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: t('financial.confirm'),
                reverseButtons: true
            })
        }
        else {
            SweetAlert.fire({
                title: `${t('courses.ThisCourseIsBelongsTo')} ${course?.name}`,
                html: `<p>${t('courses.YouMay')} Find <a href="/support" target="_blank">Customer Service</a> To View This Content</p>`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: t('financial.confirm'),
                reverseButtons: true
            })
        }
    }



    return (
        <>

            <>
                {props?.starterEnglishCourse?.length > 0 &&
                    <>
                        <h4>Starter Package</h4>
                        <Row style={{ marginTop: "25px" }}>

                            {props?.starterEnglishCourse?.map(course => {
                                return (
                                    course?.status &&
                                    <Col xl='4' lg='6' className="result">
                                        {/* <Link to='courses/course/123'> */}
                                        <Card
                                            onClick={() => {
                                                toChapterPage(course)
                                                ReactGA.event({
                                                    category: 'Courses',
                                                    action: 'Click not available courses ',
                                                    label: 'Click not available courses '
                                                })
                                            }}
                                            style={{
                                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                backgroundColor: 'white',
                                                color: 'black',

                                            }}
                                        >

                                            <div style={{
                                                cursor: 'pointer',
                                                filter: course?.is_course_available === false ? 'contrast(0.5)' : ''
                                            }}>

                                                <div >
                                                    {
                                                        course?.is_course_available === false &&
                                                        <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>

                                                    }
                                                    <img alt='course background' src={course?.cover_image_url}
                                                        style={{
                                                            width: '100%',
                                                            borderTopLeftRadius: '15px',
                                                            borderTopRightRadius: '5px'
                                                        }}
                                                    />

                                                </div>

                                                <Row className="mt-3" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center' }}>

                                                    <Badge color={
                                                        course?.difficulty === 'Beginner' ? 'success' :
                                                            course?.difficulty === 'Intermediate' ? 'secondary' :
                                                                course?.difficulty === 'Advanced' ? 'danger' :
                                                                    'success'}>
                                                        {course?.difficulty}
                                                    </Badge>
                                                    <Badge color='dark' style={{ textTransform: 'capitalize' }}>{course?.course_language}</Badge>


                                                </Row>

                                                <Row style={{ color: 'black', marginLeft: '20px', marginBottom: '10px', marginTop: '10px', alignItems: 'center' }}>

                                                    <h4>{course?.name}</h4>

                                                </Row>

                                                <Row className="mt-2" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <p style={{ marginBottom: '0', marginTop: '6px' }}>{course?.author}</p>
                                                    </div>

                                                    <div style={{ marginRight: '30px' }}>
                                                        <h5 style={{ margin: '0', textAlign: 'right', color: '#EEC256' }}>{course?.course_progress}% </h5>
                                                        <p >{t('courses.Complete')}</p>
                                                    </div>

                                                </Row>

                                            </div>


                                        </Card>
                                        {/* </Link> */}

                                    </Col>
                                )

                            })}
                        </Row>
                    </>
                }



                {props?.premiumEnglishCourse?.length > 0 &&
                    <>
                        <hr />

                        <h4>Premium Package</h4>
                        <Row style={{ marginTop: "25px" }}>

                            {props?.premiumEnglishCourse?.map(course => {
                                return (
                                    course?.status &&
                                    <Col xl='4' lg='6' className="result">
                                        {/* <Link to='courses/course/123'> */}
                                        <Card
                                            onClick={() => {
                                                toChapterPage(course)
                                                ReactGA.event({
                                                    category: 'Courses',
                                                    action: 'Click not available courses ',
                                                    label: 'Click not available courses '
                                                })
                                            }}
                                            style={{
                                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                backgroundColor: 'white',
                                                color: 'black',

                                            }}
                                        >

                                            <div style={{
                                                cursor: 'pointer',
                                                filter: course?.is_course_available === false ? 'contrast(0.5)' : ''
                                            }}>

                                                <div >
                                                    {
                                                        course?.is_course_available === false &&
                                                        <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>

                                                    }
                                                    <img alt='course background' src={course?.cover_image_url}
                                                        style={{
                                                            width: '100%',
                                                            borderTopLeftRadius: '15px',
                                                            borderTopRightRadius: '5px'
                                                        }}
                                                    />

                                                </div>

                                                <Row className="mt-3" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center' }}>

                                                    <Badge color={
                                                        course?.difficulty === 'Beginner' ? 'success' :
                                                            course?.difficulty === 'Intermediate' ? 'secondary' :
                                                                course?.difficulty === 'Advanced' ? 'danger' :
                                                                    'success'}>
                                                        {course?.difficulty}
                                                    </Badge>
                                                    <Badge color='dark' style={{ textTransform: 'capitalize' }}>{course?.course_language}</Badge>



                                                </Row>

                                                <Row style={{ color: 'black', marginLeft: '20px', marginBottom: '10px', marginTop: '10px', alignItems: 'center' }}>

                                                    <h4>{course?.name}</h4>

                                                </Row>

                                                <Row className="mt-2" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <p style={{ marginBottom: '0', marginTop: '6px' }}>{course?.author}</p>
                                                    </div>

                                                    <div style={{ marginRight: '30px' }}>
                                                        <h5 style={{ margin: '0', textAlign: 'right', color: '#EEC256' }}>{course?.course_progress}% </h5>
                                                        <p >{t('courses.Complete')}</p>
                                                    </div>

                                                </Row>

                                            </div>


                                        </Card>
                                        {/* </Link> */}

                                    </Col>
                                )

                            })}

                        </Row>
                    </>
                }


                {props?.otherEnglishCourse?.length > 0 &&
                    <>
                        <hr />

                        <h4>Other Courses</h4>

                        <Row style={{ marginTop: "25px" }}>

                            {props?.otherEnglishCourse?.map(course => {
                                return (
                                    course?.status &&
                                    <Col xl='4' lg='6' className="result">
                                        {/* <Link to='courses/course/123'> */}
                                        <Card
                                            onClick={() => {
                                                toChapterPage(course, course?.id)
                                                ReactGA.event({
                                                    category: 'Courses',
                                                    action: 'Click not available courses ',
                                                    label: 'Click not available courses '
                                                })
                                            }}
                                            style={{
                                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                backgroundColor: 'white',
                                                color: 'black',

                                            }}
                                        >

                                            <div style={{
                                                cursor: 'pointer',
                                                filter: course?.is_course_available === false ? 'contrast(0.5)' : ''
                                            }}>

                                                <div >
                                                    {
                                                        course?.is_course_available === false &&
                                                        <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>

                                                    }
                                                    <img alt='course background' src={course?.cover_image_url}
                                                        style={{
                                                            width: '100%',
                                                            borderTopLeftRadius: '15px',
                                                            borderTopRightRadius: '5px'
                                                        }}
                                                    />

                                                </div>

                                                <Row className="mt-3" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center' }}>

                                                    <Badge color={
                                                        course?.difficulty === 'Beginner' ? 'success' :
                                                            course?.difficulty === 'Intermediate' ? 'secondary' :
                                                                course?.difficulty === 'Advanced' ? 'danger' :
                                                                    'success'}>
                                                        {course?.difficulty}
                                                    </Badge>
                                                    <Badge color='dark' style={{ textTransform: 'capitalize' }}>{course?.course_language}</Badge>



                                                </Row>

                                                <Row style={{ color: 'black', marginLeft: '20px', marginBottom: '10px', marginTop: '10px', alignItems: 'center' }}>

                                                    <h4>{course?.name}</h4>

                                                </Row>

                                                <Row className="mt-2" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <p style={{ marginBottom: '0', marginTop: '6px' }}>{course?.author}</p>
                                                    </div>

                                                    <div style={{ marginRight: '30px' }}>
                                                        <h5 style={{ margin: '0', textAlign: 'right', color: '#EEC256' }}>{course?.course_progress}% </h5>
                                                        <p >{t('courses.Complete')}</p>
                                                    </div>

                                                </Row>

                                            </div>


                                        </Card>
                                        {/* </Link> */}

                                    </Col>
                                )

                            })}


                        </Row>
                    </>
                }
            </>



        </>



    )
}

export default EnglishCourse