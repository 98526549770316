import {
  SET_USER_EVENT_STATUS,
  SET_MY_COMPETITION_STATUS,
  SET_LEADERBOARD_STATUS,
  SET_ALL_COMPETITION
} from '../actionTypes'

const initial_state = {
  competitions: [],
  user_student_competition_profile: null,
  leaderBoard: [],
  myCompetitionResult: {}
}

export default (state = initial_state, action) => {
  switch (action.type) {

    case SET_ALL_COMPETITION:
      return { ...state, competitions: action.competitions }

    case SET_USER_EVENT_STATUS:
      return { ...state, user_student_competition_profile: action.user_student_competition_profile }

    case SET_MY_COMPETITION_STATUS:
      return { ...state, myCompetitionResult: action.myCompetitionResult }

    case SET_LEADERBOARD_STATUS:
      return { ...state, leaderBoard: action.leaderBoard }

    default:
      return { ...state }
  }
}
