import React, { useState } from 'react'
import { Card, CardBody, Table, Button } from 'reactstrap'
import BankForm from './form'
import { bankList } from '../../../network/constants'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'


const translateBank = (creditCard) => {

  const findBank = _.find(bankList, { code: creditCard })
  return findBank?.name ?? creditCard
}

const BankCard = ({ creditCard }) => {
  const { t } = useTranslation()
  const translateStatus = (status) => {
    switch (status) {
      case 0:
        return t("user.Pending")
      case 1:
        return t("user.Verified")
      case 2:
        return t("user.Failed")
      case 3:
        return t("user.Rejected")
      default:
        return '-'
    }
  }

  const [isEditable, setIsEditable] = useState(false)

  // console.log('creditCardcreditCard', creditCard)
  return (
    <Card>
      <CardBody>
        {isEditable ? (
          <>
            <BankForm bank={creditCard} setIsEditable={setIsEditable} />
          </>
        ) : (
            <>
              <Table borderless>
                <tbody>
                  <tr>
                    <td width="50%">{t('financial.CardHolderName')}</td>
                    <td>{creditCard?.card_holder ?? '-'}</td>
                  </tr>
                  <tr>
                    <td>{t('financial.CardNumber')}</td>
                    <td>{creditCard?.card_number.substring(0, 4) + ' **** **** ' + creditCard?.card_number.substring(15, 19) ?? '-'}</td>
                  </tr>
                  <tr>
                    <td>{t('financial.CardType')}</td>
                    <td>{creditCard.card_type === 0 ? 'VISA' : "MASTER"}</td>
                  </tr>
                  <tr>
                    <td>{t('financial.ExpiryDate')}</td>
                    <td>{creditCard?.expired_date ?? '-'}</td>
                  </tr>
                  {creditCard.status !== 1 &&
                    <tr>
                      <td>Credit Card Front</td>
                      <td><a href={creditCard?.image_prove} target="_blaknk">View</a></td>
                    </tr>
                  }

                  <tr>
                    <td>{t("user.Status")}</td>
                    <td
                      style={{
                        fontWeight: 'bold',
                        color: creditCard.status === 1 ? 'green' : creditCard.status === 3 ? 'red' : 'black'
                      }}
                    >
                      {translateStatus(creditCard?.status)}
                    </td>
                  </tr>
                  {creditCard?.reason && (
                    <tr>
                      <td>{t("user.Reason")}</td>
                      <td>{creditCard.reason}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {creditCard?.status === 0 && (
                <Button
                  className="pull-right"
                  color="primary"
                  onClick={() => {
                    setIsEditable(true)
                  }}
                >
                  {t("user.Edit")}
                </Button>
              )}
            </>
          )}
      </CardBody>
    </Card>
  )
}

export default BankCard
