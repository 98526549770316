import React from "react";
import PammPendingTable from "./table";

const Pending = () => {
  return (
    <PammPendingTable />
  );
};

export default Pending;
