import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, ListGroup, ListGroupItem, Input, FormText } from 'reactstrap'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const MobileGap = styled.div`
  padding: 0;

  @media (max-width: 575px) {
    padding: 10px;
  }
`

const AddressUpload = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const addressFront = useSelector((state) => state.verification.addressFront)

  return (
    <>
      <Row>
        <Col md="12" style={{ textAlign: 'center' }}>
          {/* For Laptop Version */}

          <div className='d-none d-lg-flex d-xl-flex m-t-20 verify-KYC-image'>
            <div>
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotCutAddress.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustNotBeCutOff")}</p>
            </div>
            <div>
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotblurAddress.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustNotBeBlur")}</p>
            </div>

          </div>

          <div className="d-none d-lg-flex d-xl-flex m-t-20 verify-KYC-image ">
          <div>
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustBeValid.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustBeValidUntil")} <br/>{t("verifyAuth.6month")}</p>
            </div>
            
            <div>
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/goodExampleAddress.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.Example")}</p>
            </div>
          </div>


          {/* For Tab and Mobile Version */}
          <div className="d-flex d-lg-none d-xl-none verify-KYC-Mobileimage ">
            <div className="text-center-sm m-b-5">
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotCutAddress.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustNotBeCutOff")}</p>
            </div>
            <div className="text-center-sm m-b-5">
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustBeValid.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustBeValidUntil")} <br/>{t("verifyAuth.6month")}</p>
            </div>
            <div className="text-center-sm m-b-5">
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotblurAddress.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustNotBeBlur")}</p>
            </div>
            <div className="text-center-sm m-b-5">
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/goodExampleAddress.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.Example")}</p>
            </div>
          </div>
        </Col>
      </Row>

      <hr />
      <p>{t("verifyAuth.PleaseUploadYour")}</p>

      <Row>
        <Col>
          <FormText color="muted">{t("verifyAuth.Address")}</FormText>
          <Input
            type="file"
            name="file"
            accept="image/png, image/jpeg, application/pdf"
            onChange={(e) => {
              dispatch({
                type: 'SET_ADDRESS_FRONT',
                addressFront: { file: e?.currentTarget?.files[0] }
              })
            }}
          />
          <FormText color="muted">.jpg, .png, pdf, max 10 MB</FormText>
          <span style={{ color: 'red' }}>{addressFront?.error != null && addressFront.error}</span>
          <MobileGap />
        </Col>
      </Row>
    </>
  )
}

export default AddressUpload
