import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import {
    Row,
    Col,
    Card,
    Button,
    Spinner
} from 'reactstrap'

import PammSubscriptionService from '../../../network/services/pammSubscription'
import { useTranslation } from 'react-i18next'

import PammPendingTable from './pending/index'

const PammDeactiveSubscription = (props) => {
    const { t } = useTranslation()
    const [deactiveSubscribe, setDeactiveSubscribe] = useState([])
    const deActiveList = deactiveSubscribe
    const [isBusy, setIsBusy] = useState(false)
    const init = async () => {
        try {
            setIsBusy(true)
            const result = await PammSubscriptionService.getAll()
            setDeactiveSubscribe(result.deactive ?? {})
            setIsBusy(false)
        } catch (error) {
            setIsBusy(false)
            console.log(error)
        }
        setIsBusy(false)
    }

    useEffect(() => {
        init()
    }, [])


    const PammDeactiveList = deActiveList.map(user => {
        return (deActiveList.length > 0 ?
            <Col lg='5' key={user.id} className="result">
                <Card >
                    <Row style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>

                        <Col lg='3' className="mt-3 mb-3" >
                            <div style={{ textAlign: 'center' }}>
                                <img className="ml-3" style={{ width: "100px", height: "100px", borderRadius: '10px' }} src={user.pammManager.avatar !== "" ? user.pammManager.avatar : `https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/User+photo-05.jpg`} alt={user.pammManager.name} />
                            </div>
                        </Col>

                        <Col lg='9' className="ml-3 mb-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="mt-3">
                                <Col lg="9">
                                    <h5 className="mt-1" style={{ color: '#fbc54d' }}>{user.pammManager.name}</h5>
                                </Col>
                            </div>

                            <div >
                                <Link to={`/pamm/${user.pammManager.id}`}>
                                    <Col lg="6">
                                        <p style={{ color: '#103c5a', lineHeight: '1.3', letterSpacing: '0', fontWeight: 'bold' }}>{t("pammSubsccription.ReviewStatistic")}</p>
                                    </Col>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            : <h1 style={{ marginLeft: '15px' }}>{t("pammSubsccription.youDoNotHaveAnyUnsubscribe")}</h1>

        )
    })
    return (
        <>
            {/* <h3 style={{ color: '#103c5a', marginLeft: '15px' }}>{t("pammSubsccription.PAMMSubscriptions")}</h3>
            <p style={{ color: '#868686', marginLeft: '15px' }}>{t("pammSubsccription.viewingAllUnsubscribedManagers")}</p> */}
            <Row style={{ margin: '0' }}>
                {isBusy ? <><Spinner size="md" color="warning"></Spinner><h4>{t("pammSubsccription.loading")}...</h4><br /></> : ''}

                {deActiveList.length > 0 && PammDeactiveList}

                {/* Show when deactive list length is 0 */}
                {deActiveList.length <= 0 && <Link to="/pamm">
                    <Button style={{ marginLeft: '15px' }}>
                        {t("pammSubsccription.pleaseSubscribePammManager")}
                    </Button>
                </Link>}
            </Row>

            <div className="mt-3">

                <PammPendingTable />
            </div>
        </>
    )
}

export default PammDeactiveSubscription

