import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Card,
    Badge,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Button
} from 'reactstrap'

import CourseService from '../../../network/services/courses'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'



import './pammManager.scss'

import "intro.js/introjs.css";
import { Steps } from 'intro.js-react';

import ReactGA from 'react-ga'
import EnglishCourse from './englishCourse'

const Courses = (props) => {
    let history = useHistory()
    const dispatch = useDispatch()
    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: path })
    }

    const { t } = useTranslation()
    // const [pammManager, setPammManager] = useState([])

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [courseLanguageSelected, setCourseLanguage] = useState('Chinese Courses')

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const [allCourses, setAllCourses] = useState([])

    const [availableCourses, setAvailableCourses] = useState([])
    const [notAvailableCourses, setNotAvailableCourses] = useState([])
    const [starterCourse, setStarterCourse] = useState([])
    const [premiumCourse, setPremiumCourse] = useState([])
    const [otherChineseCourse, setOtherChineseCourse] = useState([])

    const [starterEnglishCourse, setStarterEnglishCourse] = useState([])
    const [premiumEnglishCourse, setPremiumEnglishCourse] = useState([])
    const [otherEnglishCourse, setOtherEnglishCourse] = useState([])

    const [searchResults, setSearchResults] = useState([])

    const init = async () => {
        const coursesResult = await CourseService.getAll()
        setAllCourses(coursesResult?.courses)

        setStarterCourse(coursesResult?.courses?.filter((course => {
            return (course.id === 1 || course.id === 2 ) && !course.is_course_available && course.course_language === "chinese"
        })))

        setPremiumCourse(coursesResult?.courses?.filter((course => {
            return (course.id === 4 || course.id === 5 || course.id === 6) && !course.is_course_available && course.course_language === "chinese"
        })))

        setOtherChineseCourse(coursesResult?.courses?.filter((course => {
            return (course.id === 9 || course.id === 10 || course.id === 11 || course.id === 12 || course.id === 13) && !course.is_course_available && course.course_language === "chinese"
        })))

        //English Course
        setStarterEnglishCourse(coursesResult?.courses?.filter((course => {
            return (course.id === 14 || course.id === 15 || course.id === 16) && !course.is_course_available && course.course_language === "english"
        })))

        setPremiumEnglishCourse(coursesResult?.courses?.filter((course => {
            return (course.id === 17 || course.id === 18 || course.id === 19) && !course.is_course_available && course.course_language === "english"
        })))

        setOtherEnglishCourse(coursesResult?.courses?.filter((course => {
            return (course.id === 9 || course.id === 10 || course.id === 11 || course.id === 12 || course.id === 13) && !course.is_course_available && course.course_language === "english"
        })))

    }

    useEffect(() => {
        init()
        // eslint-disable-next-line
    }, [])

    const toChapterPage = (course, courseID) => {

        if (course?.is_course_available) {
            navigate(`${process.env.PUBLIC_URL}/courses/${course?.id}/chapters`)
        } else if (courseID === 9 || courseID === 10) {
            SweetAlert.fire({
                title: `${t('courses.ThisCourseIsBelongsTo')} ${course?.name}`,
                html: `<p>${t('courses.YouMay')} Find <a href="/support" target="_blank">Customer Service</a> To View This Content</p>`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: t('financial.confirm'),
                reverseButtons: true
            })
        }
        else {
            SweetAlert.fire({
                title: `${t('courses.ThisCourseIsBelongsTo')} ${course?.name}`,
                html: `<p>${t('courses.YouMay')} <a href="/live" target="_blank">${t('courses.Upgrade')}</a> ${t('courses.ToThisPackageToViewThisContent')}</p>`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: t('financial.confirm'),
                reverseButtons: true
            })
        }
    }



    return (
        <>


            {/* <Dropdown isOpen={dropdownOpen} toggle={() => { toggle() }} style={{ marginBottom: '15px' }}>
                <DropdownToggle id='course_language_dropdown' caret>
                    {courseLanguageSelected}
                </DropdownToggle>
                <DropdownMenu >
                    <DropdownItem onClick={(e) => { setCourseLanguage(e.target.innerHTML) }}>Chinese</DropdownItem>
                    <DropdownItem onClick={(e) => { setCourseLanguage(e.target.innerHTML) }}>English</DropdownItem>
                </DropdownMenu>
            </Dropdown> */}

            <div style={{ display: 'flex', marginBottom: '15px' }}>
                <div style={{ marginRight: '10px' }}>
                    <Button
                        color={courseLanguageSelected === 'Chinese Courses' ? 'primary' : 'secondary'}
                        onClick={(e) => { setCourseLanguage(e.target.innerHTML) }}
                    >
                        Chinese Courses
                    </Button>
                </div>

                <div>
                    <Button
                        color={courseLanguageSelected === 'English Courses' ? 'primary' : 'secondary'}
                        onClick={(e) => { setCourseLanguage(e.target.innerHTML) }}>
                        English Courses
                    </Button>
                </div>
            </div>

            {courseLanguageSelected === 'Chinese Courses' ?


                <>
                    {starterCourse?.length > 0 &&
                        <>
                            <h4>Starter Package</h4>
                            <Row style={{ marginTop: "25px" }}>

                                {starterCourse?.map(course => {
                                    return (
                                        course?.status &&
                                        <Col xl='4' lg='6' className="result">
                                            {/* <Link to='courses/course/123'> */}
                                            <Card
                                                onClick={() => {
                                                    toChapterPage(course)
                                                    ReactGA.event({
                                                        category: 'Courses',
                                                        action: 'Click not available courses ',
                                                        label: 'Click not available courses '
                                                    })
                                                }}
                                                style={{
                                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                    backgroundColor: 'white',
                                                    color: 'black',

                                                }}
                                            >

                                                <div style={{
                                                    cursor: 'pointer',
                                                    filter: course?.is_course_available === false ? 'contrast(0.5)' : ''
                                                }}>

                                                    <div >
                                                        {
                                                            course?.is_course_available === false &&
                                                            <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>

                                                        }
                                                        <img alt='course background' src={course?.cover_image_url}
                                                            style={{
                                                                width: '100%',
                                                                borderTopLeftRadius: '15px',
                                                                borderTopRightRadius: '5px'
                                                            }}
                                                        />

                                                    </div>

                                                    <Row className="mt-3" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center' }}>

                                                        <Badge color={
                                                            course?.difficulty === 'Beginner' ? 'success' :
                                                                course?.difficulty === 'Intermediate' ? 'secondary' :
                                                                    course?.difficulty === 'Advanced' ? 'danger' :
                                                                        'success'}>
                                                            {course?.difficulty}
                                                        </Badge>
                                                        <Badge color='dark' style={{ textTransform: 'capitalize' }}>{course?.course_language}</Badge>


                                                    </Row>

                                                    <Row style={{ color: 'black', marginLeft: '20px', marginBottom: '10px', marginTop: '10px', alignItems: 'center' }}>

                                                        <h4>{course?.name}</h4>

                                                    </Row>

                                                    <Row className="mt-2" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <div>
                                                            <p style={{ marginBottom: '0', marginTop: '6px' }}>{course?.author}</p>
                                                        </div>

                                                        <div style={{ marginRight: '30px' }}>
                                                            <h5 style={{ margin: '0', textAlign: 'right', color: '#EEC256' }}>{course?.course_progress}% </h5>
                                                            <p >{t('courses.Complete')}</p>
                                                        </div>

                                                    </Row>

                                                </div>


                                            </Card>
                                            {/* </Link> */}

                                        </Col>
                                    )

                                })}
                            </Row>
                        </>
                    }



                    {premiumCourse?.length > 0 &&
                        <>
                            <hr />

                            <h4>Premium Package</h4>
                            <Row style={{ marginTop: "25px" }}>

                                {premiumCourse?.map(course => {
                                    return (
                                        course?.status &&
                                        <Col xl='4' lg='6' className="result">
                                            {/* <Link to='courses/course/123'> */}
                                            <Card
                                                onClick={() => {
                                                    toChapterPage(course)
                                                    ReactGA.event({
                                                        category: 'Courses',
                                                        action: 'Click not available courses ',
                                                        label: 'Click not available courses '
                                                    })
                                                }}
                                                style={{
                                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                    backgroundColor: 'white',
                                                    color: 'black',

                                                }}
                                            >

                                                <div style={{
                                                    cursor: 'pointer',
                                                    filter: course?.is_course_available === false ? 'contrast(0.5)' : ''
                                                }}>

                                                    <div >
                                                        {
                                                            course?.is_course_available === false &&
                                                            <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>

                                                        }
                                                        <img alt='course background' src={course?.cover_image_url}
                                                            style={{
                                                                width: '100%',
                                                                borderTopLeftRadius: '15px',
                                                                borderTopRightRadius: '5px'
                                                            }}
                                                        />

                                                    </div>

                                                    <Row className="mt-3" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center' }}>

                                                        <Badge color={
                                                            course?.difficulty === 'Beginner' ? 'success' :
                                                                course?.difficulty === 'Intermediate' ? 'secondary' :
                                                                    course?.difficulty === 'Advanced' ? 'danger' :
                                                                        'success'}>
                                                            {course?.difficulty}
                                                        </Badge>
                                                        <Badge color='dark' style={{ textTransform: 'capitalize' }}>{course?.course_language}</Badge>



                                                    </Row>

                                                    <Row style={{ color: 'black', marginLeft: '20px', marginBottom: '10px', marginTop: '10px', alignItems: 'center' }}>

                                                        <h4>{course?.name}</h4>

                                                    </Row>

                                                    <Row className="mt-2" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <div>
                                                            <p style={{ marginBottom: '0', marginTop: '6px' }}>{course?.author}</p>
                                                        </div>

                                                        <div style={{ marginRight: '30px' }}>
                                                            <h5 style={{ margin: '0', textAlign: 'right', color: '#EEC256' }}>{course?.course_progress}% </h5>
                                                            <p >{t('courses.Complete')}</p>
                                                        </div>

                                                    </Row>

                                                </div>


                                            </Card>
                                            {/* </Link> */}

                                        </Col>
                                    )

                                })}

                            </Row>
                        </>
                    }


                    {otherChineseCourse?.length > 0 &&
                        <>
                            <hr />

                            <h4>Other Courses</h4>

                            <Row style={{ marginTop: "25px" }}>

                                {otherChineseCourse?.map(course => {
                                    return (
                                        course?.status &&
                                        <Col xl='4' lg='6' className="result">
                                            {/* <Link to='courses/course/123'> */}
                                            <Card
                                                onClick={() => {
                                                    toChapterPage(course, course?.id)
                                                    ReactGA.event({
                                                        category: 'Courses',
                                                        action: 'Click not available courses ',
                                                        label: 'Click not available courses '
                                                    })
                                                }}
                                                style={{
                                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                    backgroundColor: 'white',
                                                    color: 'black',

                                                }}
                                            >

                                                <div style={{
                                                    cursor: 'pointer',
                                                    filter: course?.is_course_available === false ? 'contrast(0.5)' : ''
                                                }}>

                                                    <div >
                                                        {
                                                            course?.is_course_available === false &&
                                                            <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>

                                                        }
                                                        <img alt='course background' src={course?.cover_image_url}
                                                            style={{
                                                                width: '100%',
                                                                borderTopLeftRadius: '15px',
                                                                borderTopRightRadius: '5px'
                                                            }}
                                                        />

                                                    </div>

                                                    <Row className="mt-3" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center' }}>

                                                        <Badge color={
                                                            course?.difficulty === 'Beginner' ? 'success' :
                                                                course?.difficulty === 'Intermediate' ? 'secondary' :
                                                                    course?.difficulty === 'Advanced' ? 'danger' :
                                                                        'success'}>
                                                            {course?.difficulty}
                                                        </Badge>
                                                        <Badge color='dark' style={{ textTransform: 'capitalize' }}>{course?.course_language}</Badge>



                                                    </Row>

                                                    <Row style={{ color: 'black', marginLeft: '20px', marginBottom: '10px', marginTop: '10px', alignItems: 'center' }}>

                                                        <h4>{course?.name}</h4>

                                                    </Row>

                                                    <Row className="mt-2" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <div>
                                                            <p style={{ marginBottom: '0', marginTop: '6px' }}>{course?.author}</p>
                                                        </div>

                                                        <div style={{ marginRight: '30px' }}>
                                                            <h5 style={{ margin: '0', textAlign: 'right', color: '#EEC256' }}>{course?.course_progress}% </h5>
                                                            <p >{t('courses.Complete')}</p>
                                                        </div>

                                                    </Row>

                                                </div>


                                            </Card>
                                            {/* </Link> */}

                                        </Col>
                                    )

                                })}


                            </Row>
                        </>
                    }
                </>

                :

                courseLanguageSelected === 'English Courses' &&

                <EnglishCourse
                    starterEnglishCourse={starterEnglishCourse}
                    premiumEnglishCourse={premiumEnglishCourse}
                    otherEnglishCourse={otherEnglishCourse}
                />

            }

        </>



    )
}

export default Courses