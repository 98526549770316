import client from '../request'

const getAll = () => {

    return client.get('/pamms?status=1')
}


export default {
  getAll
}
