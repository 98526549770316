import React, { useEffect, useState } from 'react'
import WalletOption from '../options'
import WalletTransfer from './wallet'
import MT5Transfer from './mt5'
import { useSelector } from 'react-redux'

const Transfer = ({ refresh }) => {
  const [option, setOption] = useState()
  const user = useSelector((state) => state.user.user)

  // reset options on tab change
  useEffect(() => {
    setOption(null)
  }, [refresh])

  return (
    <>
      {option == null ? (
        <WalletOption
          setOption={setOption}
          type="transfer"
          hasWallet={user?.role?.handle === 'mib'}
        />
      ) : (
        <>
          {option === 1 ? (
            <WalletTransfer setOption={setOption} />
          ) : (
            <MT5Transfer setOption={setOption} />
          )}
        </>
      )}
    </>
  )
}

export default Transfer
