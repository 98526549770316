import React, { useMemo, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Tooltip,
  ModalHeader,
  ModalBody,
  Table
} from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AccountService from '../../../../network/services/account'
import PromocodeService from '../../../../network/services/promotion'
import promotion from '../../../../network/services/promotion'
import { HelpCircle } from 'react-feather'
import WelcomeBonusIcon from './welcomeBonusIcon.svg'

const ChooseAmount = (props) => {
  const { t } = useTranslation()
  const { register, errors, formState } = useFormContext()
  const { isSubmitting } = formState
  const dispatch = useDispatch()
  const wallets = useSelector((state) => state.wallet.wallets)
  const accounts = useSelector((state) => state.account.accounts)
  const currencies = useSelector((state) => state.currency.currencies)
  const selectedAccountId = useSelector((state) => state.deposit.mt5.account)
  const selectedWalletId = useSelector((state) => state.deposit.mt5.wallet)
  const promotions = useSelector((state) => state.promotion.promotions)
  const myAvailablePromotions = useSelector((state) => state.promotion.myAvailablePromotions)
  const firstDepositBonus = myAvailablePromotions.find((item) => item.id == 6)
  const [nextSettlementTooltipOpen, setNextSettlementTooltipOpen] = useState(false)
  const nextSettlementToggle = () => setNextSettlementTooltipOpen(!nextSettlementTooltipOpen)
  const [allPromotecode, setAllPromoteCode] = useState([])
  const [matchedPromotecode, setMatchedPromotecode] = useState('')

  const selectedWallet = useMemo(() => {
    return wallets.find((e) => e.id === selectedWalletId)
    // eslint-disable-next-line
  }, [selectedWalletId])

  const selectedAccount = useMemo(() => {
    return accounts.find((e) => e.id === selectedAccountId)
    // eslint-disable-next-line
  }, [selectedAccountId])

  // console.log('selectedAccount', selectedAccount)

  const selectedCurrency = useMemo(() => {
    return currencies.find((e) => e.id === selectedAccount?.currency_id)
    // eslint-disable-next-line
  }, [currencies, selectedAccount])

  const maxRate = useMemo(() => {
    let rate = 0

    if (promotions?.length > 0) {
      promotions.forEach((promotion) => {
        if (rate < promotion.bonus_rate) {
          rate = promotion.bonus_rate
        }
      })
    }

    return rate
  }, [promotions])

  const [modalOpen, setModalOpen] = useState(false)
  const toggleModal = () => setModalOpen(!modalOpen)

  const [firstTimeOpenPromotion, setFirstTimeOpenPromotion] = useState(true)
  const [warningNotePromotion, setWarningNotePromotion] = useState(false)
  const toggleWarningNoteModal = () => {
    setWarningNotePromotion(true)
  }

  const [timer, setTimer] = useState(3)

  useEffect(() => {
    // exit early when we reach 0
    if (!timer) return

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimer(timer - 1)
    }, 1000)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timer])

  const options = useMemo(() => {
    let available = []
    if (maxRate > 0) {
      if (maxRate >= 5) {
        available.push(5)
      }

      if (maxRate >= 10) {
        available.push(10)
      }

      if (maxRate >= 15) {
        available.push(15)
      }

      if (maxRate >= 20) {
        available.push(20)
      }

      if (maxRate >= 25) {
        available.push(25)
      }

      if (maxRate >= 50) {
        available.push(50)
      }
    }

    return available
  }, [maxRate])

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [matchedPromotecode])

  const init = async () => {
    const accountResponse = await AccountService.getAll()
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })

    const promoteCodeResponse = await PromocodeService.getPromotecodes()
    // console.log('promoteCodeResponse', promoteCodeResponse)
    setAllPromoteCode(promoteCodeResponse?.promocodes?.data)
  }

  const [checkedBonus, setCheckedBonus] = useState(false)

  const checkPrmotecodeItem = (e) => {
    const matchedPromotecode = allPromotecode?.filter(
      (promoteCode) => promoteCode?.code === e.target.value.toUpperCase()
    )
    // console.log('matchedPromotecode', matchedPromotecode)
    if (matchedPromotecode?.length > 0) {
      setMatchedPromotecode(matchedPromotecode[0])
    } else {
      setMatchedPromotecode('')
    }
  }

  return (
    <>
      <Card>
        <CardHeader>
          <h5>{t('financial.chooseAmount')}</h5>
          <p style={{ margin: 0 }}>{`${t(
            'financial.availableBalance'
          )} ${selectedWallet?.balance?.toFixed(2)} ${selectedWallet?.currency?.name}`}</p>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  {t('financial.depositAmount')}
                  <span
                    style={{ color: 'green', textTransform: 'uppercase' }}
                  >{` (${selectedCurrency?.abbreviation})`}</span>
                  <span style={{ color: 'red', textTransform: 'uppercase' }}>*</span>
                </Label>
                <Input
                  className="form-control"
                  type="number"
                  step=".01"
                  name="amount"
                  innerRef={register({
                    required: t('financial.amountIsRequired'),
                    min: {
                      value:
                        selectedAccount?.account_deposits?.length > 0 ||
                        (selectedAccount?.package_id >= 4 && selectedAccount?.package_id <= 6)
                          ? 0.01
                          : selectedAccount?.plan?.min_deposit,
                      message:
                        t('financial.minimumDepositOf') +
                        ' ' +
                        (selectedAccount?.account_deposits?.length > 0 ||
                        (selectedAccount?.package_id >= 4 && selectedAccount?.package_id <= 6)
                          ? 0.01
                          : selectedAccount?.plan?.min_deposit) +
                        selectedCurrency?.abbreviation
                    }
                  })}
                />
                <span style={{ color: 'red' }}>{errors.amount && errors.amount.message}</span>
                <p />
              </FormGroup>

              <Row className="m-t-20">
                <Col lg="4">
                  <FormGroup>
                    <Label>
                      {t('promo.code.redeem')}
                      <HelpCircle
                        id="nextSettlement"
                        style={{
                          width: '16px',
                          color: '#f9b700',
                          marginLeft: '3px',
                          cursor: 'pointer'
                        }}
                      />
                      <Tooltip
                        placement="right"
                        id="settlementTooltip"
                        style={{ textAlign: 'left', backgroundColor: '#f7f7f7', color: 'black' }}
                        isOpen={nextSettlementTooltipOpen}
                        target="nextSettlement"
                        toggle={nextSettlementToggle}
                      >
                        <h5>{t('pamm.PleaseTakeNote')}</h5>
                        {t('promo.code.typeHere')}
                      </Tooltip>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      onChange={(e) => {
                        checkPrmotecodeItem(e)
                      }}
                      name="promocode"
                      innerRef={register()}
                    />

                    <p />
                  </FormGroup>
                </Col>

                {matchedPromotecode && (
                  <Col lg="6">
                    <FormGroup>
                      {/* <Label style={{ marginTop: '10px' }}>{t('promo.code.itemRedeem')}</Label>
                      <p>
                        <a href="/" target="_blank">
                          {matchedPromotecode?.promotionReward?.name}
                          {console.log('matchedPromotecode', matchedPromotecode)}
                        </a>
                      </p> */}

                      <p style={{ marginBottom: '0px', marginTop: '10px', fontWeight: 'bold' }}>
                        Reward:
                      </p>

                      {matchedPromotecode?.free_credit > 0 && (
                        <p style={{ marginBottom: '0px' }}>Free Credit</p>
                      )}
                      {matchedPromotecode?.bonus_credit_percentage > 0 && (
                        <p style={{ marginBottom: '0px' }}>MT5 Deposit Bonus Credit</p>
                      )}
                      {matchedPromotecode?.promocodeCourses.length > 0 && (
                        <p style={{ marginBottom: '0px' }}>Courses</p>
                      )}
                    </FormGroup>
                  </Col>
                )}
              </Row>

              <FormGroup className="m-t-20">
                <span style={{ color: 'red' }}>
                  {t('financial.warningMakeSureYourAccountHaveTheEnoughBalance')}
                </span>

                <div className="custom-control custom-checkbox mb-3">
                  <Input
                    className="custom-control-input"
                    id="terms_validation"
                    type="checkbox"
                    required
                  />

                  <Label className="custom-control-label" htmlFor="terms_validation">
                    {t('financial.iHaveReadAllInstructions')}
                  </Label>
                  <div className="invalid-feedback">{t('financial.pleaseAgreeToTheTerms')}</div>
                </div>
              </FormGroup>

              <Row style={{ justifyContent: 'flex-end' }}>
                <Button
                  color="primary btn-block"
                  type="submit"
                  style={{ maxWidth: 150, marginRight: '5px' }}
                  disabled={props.isSubmitting}
                >
                  {props.isSubmitting ? t('financial.loading') : t('financial.submit')}
                </Button>

                {props.isSubmitting && <Spinner color="warning" />}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default ChooseAmount
