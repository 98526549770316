import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div className="logo-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
        <img
          className="img-fluid for-light"
          src={require("../../../assets/images/logo/logoTradeHall.svg")}
          alt=""
        />
        <img
          className="img-fluid for-dark"
          src={require("../../../assets/images/logo/logo_dark.png")}
          alt=""
        />
      </Link>
    </div>
  );
};

export default Logo;
