import React, { useState, useRef, useEffect } from "react"
import DataTable from "react-data-table-component"
import { get } from "react-hook-form"

import { useParams } from 'react-router-dom'

import PammManagerStatistic from '../../../network/services/pammManagerStatistic'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

const GrowthTable = (props) => {
    const { t } = useTranslation()
    const columns = [
        {
            name: t("pamm.year"),
            selector: "year",
            sortable: true,
            // width:'100px',
            cell: row => <span style={{ cursor: 'pointer' }} onClick={props.onClickChangeState}>{[Object.keys(row)]}</span>
        },
        {
            name: t("pamm.JAN"),
            selector: "JAN",
            // width:'100px',
            cell: row => row[Object.keys(row)].hasOwnProperty('1') ? <div style={{ color: row[Object.keys(row)]['1'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['1'].monthly_growth}%</div> : '-'
        },
        {
            name: t("pamm.FEB"),
            selector: "FEB",
            // width:'100px',
            cell: row => row[Object.keys(row)].hasOwnProperty('2') ? <div style={{ color: row[Object.keys(row)]['2'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['2'].monthly_growth}%</div> : '-'
        }, {
            name: t("pamm.MAR"),
            selector: "MAR",
            // width:'100px',
            cell: row => row[Object.keys(row)].hasOwnProperty('3') ? <div style={{ color: row[Object.keys(row)]['3'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['3'].monthly_growth}%</div> : '-'
        }, {
            name: t("pamm.APR"),
            selector: "APR",
            // width:'100px',
            cell: row => row[Object.keys(row)].hasOwnProperty('4') ? <div style={{ color: row[Object.keys(row)]['4'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['4'].monthly_growth}%</div> : '-'
        }, {
            name: t("pamm.MAY"),
            selector: "MAY",
            // width:'100px',
            cell: row => row[Object.keys(row)].hasOwnProperty('5') ? <div style={{ color: row[Object.keys(row)]['5'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['5'].monthly_growth}%</div> : '-'
        }, {
            name: t("pamm.JUN"),
            selector: "JUN",
            // width:'100px',
            cell: row => row[Object.keys(row)].hasOwnProperty('6') ? <div style={{ color: row[Object.keys(row)]['6'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['6'].monthly_growth}%</div> : '-'
        },
        {
            name: t("pamm.JUL"),
            selector: "JUL",
            // width:'100px',
            cell: row => row[Object.keys(row)].hasOwnProperty('7') ? <div style={{ color: row[Object.keys(row)]['7'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['7'].monthly_growth}%</div> : '-'
        }, {
            name: t("pamm.AUG"),
            selector: "AUG",
            // width:'100px',
            cell: row => row[Object.keys(row)].hasOwnProperty('8') ? <div style={{ color: row[Object.keys(row)]['8'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['8'].monthly_growth}%</div> : '-'
        }, {
            name: t("pamm.SEP"),
            selector: "SEP",
            // width:'100px',
            cell: row => row[Object.keys(row)].hasOwnProperty('9') ? <div style={{ color: row[Object.keys(row)]['9'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['9'].monthly_growth}%</div> : '-'
        }, {
            name: t("pamm.OCT"),
            selector: "OCT",
            // width:'100px',
            cell: row => row[Object.keys(row)].hasOwnProperty('10') ? <div style={{ color: row[Object.keys(row)]['10'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['10'].monthly_growth}%</div> : '-'
        }, {
            name: t("pamm.NOV"),
            selector: "NOV",
            // width:'100px',
            cell: row => row[Object.keys(row)].hasOwnProperty('11') ? <div style={{ color: row[Object.keys(row)]['11'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['11'].monthly_growth}%</div> : '-'
        }, {
            name: t("pamm.DEC"),
            selector: "DEC",
            // width:'100px',
            cell: row => row[Object.keys(row)].hasOwnProperty('12') ? <div style={{ color: row[Object.keys(row)]['12'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['12'].monthly_growth}%</div> : '-'
        },
        {
            name: t("pamm.TotalGrowth"),
            // width:'100px',
            cell: (row) => {

                const lastKey = _.findLastKey(row[Object.keys(row)])
                const lastMonthYearlyGrowth = row[Object.keys(row)][lastKey]?.yearly_growth

                return lastMonthYearlyGrowth ?
                    <div style={{ color: lastMonthYearlyGrowth < 0 ? 'red' : 'green' }}>{lastMonthYearlyGrowth}%</div>
                    : '-'

            }
        },
    ]

    const [isBusy, setIsBusy] = useState(false)
    const [data, setData] = useState([])
    const wrapperRef = useRef()
    const { id } = useParams()
    const getGrowthYear = []

    useEffect(() => {
        const init = async () => {
            setIsBusy(true)

            const result = await PammManagerStatistic.get(id)

            result.growth.map(result => {
                getGrowthYear.push(parseInt(Object.keys(result).toString()))
            })


            if (getGrowthYear.includes(new Date().getFullYear())) {
                setData(result.growth.reverse() ?? [])
            } else {

                const element = { [new Date().getFullYear()]: {} }
                result.growth.push(element)
                setData(result.growth.reverse() ?? [])
            }


            setIsBusy(false)
            
        }

        init()
    }, [])

    return (
        <div className="product-wrapper" ref={wrapperRef} >
            <div className="product-grid" style={{ marginTop: '15px' }}>
                <h5 >{t("pamm.PerformanceRecord")}</h5>
                <div className="product-wrapper-grid" >

                    <DataTable
                        noHeader
                        data={data}
                        columns={columns}
                        striped={true}
                        center={true}
                        progressPending={isBusy}
                        defaultContent="<i>Not set</i>"
                    />
                </div>
            </div>
        </div>
    )
}

export default GrowthTable