import React, { useState } from 'react'
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input
} from 'reactstrap'
import { useSelector } from 'react-redux'
import SweetAlert from 'sweetalert2'
import _ from 'lodash'

import UserService from '../../../network/services/user'
import AuthService from '../../../network/services/auth'
import { useTranslation } from 'react-i18next'

const TwoFactorAuthentication = () => {
  const { t } = useTranslation()
  // modal
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const [authCode, setAuthCode] = useState(null)
  const [errors, setErrors] = useState({ verification: null, twoFA: null })
  const email = useSelector((state) => state.user.user.email)
  const twoFA = useSelector((state) => state.user.twoFA)
  const userVerification = useSelector((state) => _.last(state.user.verifications))
  const handle2fa = async () => {
    try {
      let result
      result = await UserService.update2fa({ auth_code: authCode })

      // if (twoFA != null) {
      //   // disable
      // } else {
      //   // enable
      // }

      // console.log(result)

      if (result.user) {
        setShowModal(false)

        SweetAlert.fire({
          title: t("user.2FAStatusUpdatedSuccessfully"),
          icon: 'success'
        }).then(() => {
          window.location.reload()
        })
      }
    } catch (error) {
      console.log(error)
      setErrors({ twoFa: error.message })
    }
  }

  if (userVerification?.status === 1) {
    return (
      <>
        <Card>
          <CardHeader>
            <div style={{ display: 'flex' }}>
              <h5>{t("user.TwoFactorAuthentication")}</h5>
              <Badge color={twoFA != null ? 'success' : 'danger'} pill className="ml-2">
                {twoFA != null ? t("user.On") : t("user.Off")}
              </Badge>
            </div>
            <div className="p-1" />
            <p style={{ margin: 0 }}>
              {t("user.ProtectYourAccountAndYour")}
            </p>
          </CardHeader>
          <CardBody>
            <div>
              <h6>{t("user.HowToUseTwoFactorAuthentication")}</h6>
              <p style={{ margin: 0 }}>
                {t("user.WhenYouSigninAfterEntering")}
              </p>
              <div className="p-2" />
              <Container style = {{padding: '0px'}}>
                <Card>
                  <CardBody>
                    <p style={{ margin: 0 }}>
                    {t("user.YourWalletIs")} <b>{twoFA != null ? '' : t("user.not")}</b> {t("user.protectedWithTwoFactorAuthentication")}
                    </p>
                    <div className="p-0" />
                    {twoFA == null && (
                      <p style={{ margin: 0 }}>
                        {t("user.SecureLoginAndOrPayout")}
                      </p>
                    )}
                    <div className="p-1" />
                    <Button
                      color={twoFA != null ? 'danger' : 'success'}
                      onClick={async () => {
                        if (email != null) {
                          const codeResult = await AuthService.sendVerification({
                            email: email
                          })

                          if (codeResult.success) {
                            setShowModal(true)
                          } else {
                            setErrors({ verification: codeResult.message })
                          }
                        } else {
                          setErrors({ verification: 'Missing email' })
                        }
                      }}
                    >
                      {twoFA != null ? t('user.disable2FA') : t('user.enable2FA')}
                    </Button>
                    <div>
                      <span style={{ color: 'red' }}>{errors?.verification}</span>
                    </div>
                  </CardBody>
                </Card>
              </Container>
            </div>

          </CardBody>
        </Card>

        {showModal && (
          <Modal isOpen={showModal} backdrop={true} centered={true}>
            <ModalHeader toggle={toggleModal}>{t("user.PleaseVerifyYourIdentity")}</ModalHeader>
            <ModalBody>
              <p className="mb-1">{t("user.CheckYourEmail")}</p>
              <Input
                className="form-control"
                placeholder="Verification Code"
                required
                onChange={(e) => {
                  setAuthCode(e.target.value)
                }}
              />
              <span style={{ color: 'red' }}>{errors?.twoFA}</span>
              <Button
                className="mt-3 pull-right"
                onClick={() => {
                  handle2fa()
                }}
              >
                {t("user.Submit")}
              </Button>
            </ModalBody>
          </Modal>
        )}
      </>
    )
  }

  return (
    <Card>
      <CardBody>
      {t("user.PleaseCompleteThe")} <a href="#verification">{t("user.KYCVerificationProcess")}</a> {t("user.toContinueTheProcess")}
      </CardBody>
    </Card>
  )
}

export default TwoFactorAuthentication
