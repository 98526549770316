import React from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import CommissionTable from './table'
import { useTranslation } from 'react-i18next'

const Commissions = () => {
  const { t } = useTranslation()
  return (
    <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
      {/* <CardHeader>
        <h5>{t("partner.commissionHistory")}</h5>
      </CardHeader> */}
      <CardBody>
        <CommissionTable />
      </CardBody>
    </Card>
  )
}

export default Commissions
