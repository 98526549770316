import {
  SET_PAMM_LPOA_UPLOAD,
  SET_PAMM_OP_UPLOAD,
} from '../actionTypes'

const initial_state = {
  lpoa: {
    file: null,
    error: null
  },
  op: {
    file: null,
    error: null
  }
}

export default (state = initial_state, action) => {
  switch (action.type) {

    case SET_PAMM_LPOA_UPLOAD:
      return { ...state, lpoa: action.lpoa }

    case SET_PAMM_OP_UPLOAD:
      return { ...state, op: action.op }
    default:
      return { ...state }
  }
}
