import React, { useState, useEffect } from 'react'
import { Container, Card, CardBody, Form, FormGroup, Input, Label, Button, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'

import AuthService from '../../network/services/auth'
import UserService from '../../network/services/user'

import SettingService from '../../network/services/setting'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import newLogo from '../../assets/images/logo/logo.png'
import LanguageIcon from '../../language.png'

const SubmitButton = styled.button`
  background-color: #0d1731;
  width: 100%;
  color: white;
  border-radius: 5px;
  padding: 5px;
  border: 0;
`

const ForgotPassword = ({ login }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [logo, setLogo] = useState(null)
  const init = async () => {
    try {
      const result = await SettingService.getAll()
      // console.log(result)
      if (result.settings) {
        let logo2 = result.settings.find((s) => {
          return s.handle === 'crm_logo_url'
        })

        setLogo(logo2 ?? {})
      } else {
        setLogo({})
      }
    } catch (error) {
      setLogo({})
    }
  }

  useEffect(() => {
    init()
  }, [])

  const { register, handleSubmit, watch, errors, clearErrors, setError } = useForm()
  const watchPassword = watch('password')
  const { i18n } = useTranslation()

  const [email, setEmail] = useState('')
  const [isEmailVerified, setIsEmailVerified] = useState(false)

  const [languageSelected, setLanguageSelected] = useState('en')

  const language = sessionStorage.getItem('language')

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    sessionStorage.setItem('language', language)
  }

  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false)

  const languageToggle = () => setLanguageDropdownOpen((prevState) => !prevState)

  const submit = async (data) => {
    try {
      const result = await AuthService.forgotPassword({
        email: email,
        auth_code: data.auth_code,
        password: data.password,
        password_confirmation: data.confirm_password
      })
      // console.log(result)

      SweetAlert.fire({
        title: t("forgotPassword.passwordResetSuccess"),
        icon: 'success'
      }).then(() => {
        history.push('/auth')
      })
    } catch (error) {
      console.log(error)

      // SweetAlert.fire({
      //   title: error.message,
      //   icon: 'error'
      // })
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }

  const verifyEmail = async () => {
    if (email == null || email === '') {
      setError('email', {
        type: 'manual',
        message: t("forgotPassword.emailIsRequired")
      })
      return
    }

    try {
      const result = await UserService.verifyEmail({
        email: email
      })

      if (!result.success) {
        const codeResult = await AuthService.sendVerification({
          email: email
        })

        if (codeResult.success) {
          setIsEmailVerified(true)
          clearErrors('email')
        } else {
          setError('email', {
            type: 'manual',
            message: codeResult.message ?? t("forgotPassword.pleseTryAgain")
          })
          // throw codeResult
        }
      } else {
        // console.log('?')
        setError('email', {
          type: 'manual',
          message: t("forgotPassword.emailIsNotValid")
        })
        // throw result
      }
    } catch (error) {
      console.log(error)

      // SweetAlert.fire({
      //   title: error.message,
      //   icon: 'error'
      // })
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }
  return (
    
    <div className="page-wrapper" style={{ height: '100vh' }}>
      <Container fluid={true} className="p-0" id="mobile_scroll" style={{  height: '100vh' , backgroundImage: "url(https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/LoginPageBG.svg)", backgroundSize: 'cover' }}>
      <Dropdown
                isOpen={languageDropdownOpen}
                onMouseLeave={() => setLanguageDropdownOpen(false)}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '30px',
                  paddingRight: '30px',
                  backgroundColor: 'transparent',
                  zIndex:'5'
                }}
              >
                <DropdownToggle
                  onMouseEnter={() => setLanguageDropdownOpen(true)}
                  id="langugeDropdown"
                >
                  <img
                    src={LanguageIcon}
                    alt="languageIcon"
                    width="18px"
                    height="18px"
                    className="m-r-5"
                  />
                  {language === 'en' && 'EN'}
                  {language === 'zh' && 'ZH'}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => changeLanguage('en')}>English</DropdownItem>
                  <DropdownItem onClick={() => changeLanguage('zh')}>中文简体</DropdownItem>
                </DropdownMenu>
              </Dropdown> 
        
        
        <CardBody className="h-100-d-center" style={{ flexDirection: 'column', justifyContent: 'center' }} >

          <div className="mb-3" style={{ position:'fixed', top:'20px', left:'20px',zIndex: '3' }}>
            {logo?.value && (
              <Link style={{ margin: '0 auto', marginBottom: '30px' }} to={`${process.env.PUBLIC_URL}/auth`}>
                <img
                  src={logo.value}
                  // src={newLogo}
                  alt="logo"
                  style={{
                    maxHeight: 175,
                    maxWidth: 175,
                  }}
                />
              </Link>
            )}
          </div>
          
          {/*circle*/}
          <div style={{position:'fixed', top:'-85px', right:'-85px', width:'266px', height:'266px', backgroundColor:'#F0F0F0', borderRadius:'50%'}}>
          </div>


          
          <Row style={{justifyContent:'center',zIndex:'4'}}>
          <div className='d-none d-lg-block' width='100%' style={{backgroundImage:"url(https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/HomeLoginBG.svg)",width:'460px', height:'310px'}}>
                  {/* <img src={HomeLoginBG} alt='HomeLoginlBG'>
                    
                  </img> */}
                  <p style={{zIndex:'3', color:'white', textAlign:'left',width:'320px',height:'200px', fontSize:'28px',margin:'50px'}}>
                    {t('auth.join')}
                  </p>
                </div>
          <div className="cont" style={{ borderRadius: '0px 0px 50px 0px',zIndex:'4', border:'none' }}>
            

            <Form 
              onSubmit={handleSubmit(submit)}
              id="registerForm"
              className="theme-form cont mb-3"
              style={{margin:'0px', minHeight:'310px', borderRadius: '0px 0px 50px 0px',border:'none'}}
            >
              <h4>{t("forgotPassword.forgotPassword")}</h4>
              {/* <p style={{color:'#898989'}}>Please enter your email</p> */}
              
              <Label className="col-form-label pt-1 pb-0">{t("auth.emailAddress")}</Label>
                <FormGroup>
                <Input
                  id="input-form"
                  style={{ backgroundColor: "#f8f8f8" }}
                  placeholder={t("forgotPassword.emailAddress")}
                  className="form-control"
                  type="text"
                  required=""
                  name="email"
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  disabled={isEmailVerified ? true : false}
                  innerRef={register({ required: true })}
                />
                <span style={{ color: 'red' }}>{errors.email && errors.email.message}</span>
              </FormGroup>
              {/* {loginError != null && <span style={{ color: 'red' }}>{loginError}</span>} */}

              {isEmailVerified ? (
                <>
                  <FormGroup>
                    <Label className="col-form-label pt-0">Verification Code</Label>
                    <Input
                      id="input-form"
                      style={{ backgroundColor: "#f8f8f8" }}
                      className="form-control"
                      type="text"
                      placeholder={t("forgotPassword.verificationCode")}
                      name="auth_code"
                      innerRef={register({ required: t("forgotPassword.verificationCodeIsRequired") })}
                    />
                    <span
                      style={{ color: 'green' }}
                    >
                      {t("forgotPassword.pleaseCheckEmailForVerification", { email: email })}
                      {/* {`Please check ${email} for verification code`} */}
                    </span>
                    <br />
                    <span style={{ color: 'red' }}>
                      {errors.auth_code && errors.auth_code.message}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label pt-0">New Password</Label>
                    <Input
                      id="input-form"
                      style={{ backgroundColor: "#f8f8f8" }}
                      className="form-control"
                      type="password"
                      placeholder={t("forgotPassword.password")}
                      name="password"
                      innerRef={register({
                        required: t("forgotPassword.passwordIsRequired"),
                        minLength: {
                          value: 8,
                          message: t("forgotPassword.passwordMinumumLength")
                        }
                      })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.password && errors.password.message}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label pt-0">Comfirm New Password</Label>
                    <Input
                      id="input-form"
                      style={{ backgroundColor: "#f8f8f8" }}
                      className="form-control"
                      type="password"
                      placeholder={t("forgotPassword.confirmPassword")}
                      name="confirm_password"
                      innerRef={register({
                        required: t("forgotPassword.confirmPasswordIsRequired"),
                        minLength: 8,
                        validate: (value) => {
                          return value === watchPassword || t("forgotPassword.passwordNotMatch")
                        }
                      })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.confirm_password && errors.confirm_password.message}
                    </span>
                  </FormGroup>
                  <FormGroup className=" mb-0">
                    <Button color="primary btn-block" type="submit">
                      {t("forgotPassword.submit")}
                    </Button>
                  </FormGroup>
                </>
              ) : (
                  <FormGroup className="mb-0">
                    <Button color="primary btn-block" onClick={() => verifyEmail()}>
                      {t("forgotPassword.submit")}
                    </Button>
                  </FormGroup>
                )}
              <div className="mt-3" />
              <div style={{ display: 'flex' }}>
                <p style={{ color: '#898989' }}>{t("forgotPassword.alreadyHaveAnAccount")} </p>
                <Link to={`${process.env.PUBLIC_URL}/auth`} href="#javascript">
                  <p className="ml-1" style={{ color: '#f9b700' }}>{t("forgotPassword.login")}</p>
                </Link>
              </div>
            </Form>
            

          </div>
          </Row>
        </CardBody>

      </Container>
      <div style={{position:'fixed', bottom:'-180px', left:'-128px', width:'428px', height:'428px', backgroundColor:'#EFB935', borderRadius:'50%'}}>

        </div>
        <div style={{position:'fixed',bottom:'10px',left:'20px',zIndex:'1',fontSize:'16px'}}>
          <p style={{color:'black'}}>support@tradehall.co</p>
        </div>
    </div>


  )
}

export default ForgotPassword
