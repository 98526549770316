import React from "react";
import { Card, CardBody } from "reactstrap";
import BonusTable from "./table";

const Bonus = () => {
  return (
    <Card>
      <CardBody>
        <BonusTable />
      </CardBody>
    </Card>
  );
};

export default Bonus;
