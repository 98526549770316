import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'

import { HoverCard, ResponsiveCard, ResponsiveIcon, ResponsiveDiv } from '../../components.style'
import { useTranslation } from 'react-i18next'

const SelectCurrency = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const wallets = useSelector((state) => state.wallet.wallets)
  const withdrawal = useSelector((state) => state.withdrawal)

  useEffect(() => {
    // ToDo: check wallet == null
    if (wallets?.length > 0 && withdrawal?.mt5?.wallet == null) {
      dispatch({ type: 'SET_WITHDRAWAL_MT5_WALLET', wallet: wallets[0].id })
    }
    // eslint-disable-next-line
  }, [wallets])

  useEffect(() => {
    // console.log(withdrawal)
  }, [withdrawal])

  return (
    <>
      <h5>{t("financial.selectCurrency")}</h5>
      <Row>
        {wallets?.length > 0 &&
          wallets.map((wallet) => {
            return (
              <Col sm="12" lg="6" key={wallet.id}>
                <HoverCard
                  isSelected={withdrawal?.mt5?.wallet === wallet.id ? '#f9b600' : null}
                  onClick={() => {
                    dispatch({ type: 'SET_WITHDRAWAL_MT5_WALLET', wallet: wallet.id })
                  }}
                >
                  <ResponsiveCard>
                    <ResponsiveIcon className="icofont icofont-cur-dollar" />
                    <ResponsiveDiv>
                      <h6>{`${wallet.currency?.name}`}</h6>
                      <p
                        style={{ color: '#038004' }}
                      >{`${t("financial.availableBalanceInWallet")} ${wallet.balance?.toFixed(2)}`}</p>
                    </ResponsiveDiv>
                  </ResponsiveCard>
                </HoverCard>
              </Col>
            )
          })}
      </Row>
    </>
  )
}

export default SelectCurrency
