import { SET_PAYMENTS } from "../actionTypes"

const initial_state = {
  payments: [],
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_PAYMENTS:
      return { ...state, payments: action.payments }

    default:
      return { ...state }
  }
}
