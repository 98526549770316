import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Label, Input, Button } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AuthService from '../../../../../network/services/auth'
import { bankList } from '../../../../../network/constants'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const translateBank = (bank) => {

  const findBank = _.find(bankList, { code: bank })
  return findBank?.name ?? bank
}

const AwepayAmount = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { register, errors, setValue, formState, watch } = useFormContext()
  const { isSubmitting } = formState
  const watchAmount = watch('amount')

  const wallets = useSelector((state) => state.wallet.wallets)
  const banks = useSelector((state) => state.user.banks.filter((b) => b.status === 1))
  const payments = useSelector((state) => state.payment.payments)
  const selectedWalletId = useSelector((state) => state.withdrawal.wallet.id)
  const { user, twoFA } = useSelector((state) => state.user)
  // const depositCurrency = useSelector((state) => state.depositCurrency.deposit_currency)
  

  const [selectedBank, setSelectedBank] = useState(null)
  const [selectedCurrency, setSelectedCurrency] = useState()
  const [availableCurrencies, setAvailableCurrencies] = useState([])
  const [baseCurrency, setBaseCurrency] = useState('')
    const [maxWithdrawal, setMaxWithdrawal] = useState(0)

  const handleBank = (v) => {
    setSelectedBank(v)
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  const selectedPayment = useMemo(() => {
    const payment = payments.find((item) => item.handle == 'awepay')
    if (payment?.supportedCurrencies.length > 0) {
      const paymentCurrencies = payment?.supportedCurrencies
        ?.filter((item) => item.allow_withdraw == true)
        .map((item) => item.currency)

      setAvailableCurrencies(paymentCurrencies)
      setSelectedCurrency(paymentCurrencies[0].id)
      setBaseCurrency(paymentCurrencies[0].base_currency)

      const currencyDetails = payment?.supportedCurrencies?.find(
        (item) => item.currency_id == paymentCurrencies[0].id
      )

      if (currencyDetails) {
        setMaxWithdrawal(currencyDetails.withdraw_limit)
      }
    }
    return payments.find((e) => e.handle === 'awepay')
    // eslint-disable-next-line
  }, [payments])

  const selectedWallet = useMemo(() => {
    return wallets.find((e) => e.id === selectedWalletId)
    // eslint-disable-next-line
  }, [selectedWalletId])

  useEffect(() => {
    // first time
    if (selectedBank == null && banks?.length > 0) {
      setSelectedBank(banks[0].id)
    }
    // eslint-disable-next-line
  }, [banks])

  const [accountHolder, setAccountHolder] = useState('')
  const [bank_branch, setBankBranch] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [swiftCode, setSwiftCode] = useState('')
  const [bankName, setBankName] = useState('')
  const [otherOption, setOtherOption] = useState('')

  useEffect(() => {
    let bank = banks.find((b) => {
      return b.id?.toString() === selectedBank?.toString()
    })
    setValue('account_holder', bank?.account_holder)
    setValue('bank_branch', bank?.bank_branch)
    setValue('account_number', bank?.account_number)
    setValue('swift_code', bank?.swift_code)
    setValue('bank_name', translateBank(bank?.bank_name))

    setAccountHolder(bank?.account_holder)
    setBankBranch(bank?.bank_branch)
    setAccountNumber(bank?.account_number)
    setSwiftCode(bank?.swift_code)
    setBankName(translateBank(bank?.bank_name))

    // eslint-disable-next-line
  }, [selectedBank])

  const [authError, setAuthError] = useState(null)
  const [authPending, setAuthPending] = useState(null)
  const send2fa = async () => {
    try {
      const result = await AuthService.sendVerification({
        email: user.email
      })

      if (result.success) {
        setAuthPending(`${t("financial.pleaseCheck")} ${user.email} ${t("financial.forAuthenticationCode")}`)
        setAuthError(null)
      } else {
        setAuthPending(null)
        setAuthError(result.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleClick = (e) => {
    const currency = availableCurrencies.find((item) => item.id == e.target.value)
    setBaseCurrency(currency.base_currency ?? 'USD')
    setSelectedCurrency(parseInt(e.target.value))

    const payment = payments.find((item) => item.handle == 'awepay')
    const currencyDetails = payment?.supportedCurrencies?.find(
      (item) => item.currency_id == parseInt(e.target.value)
    )
    if (currencyDetails) {
      setMaxWithdrawal(currencyDetails.withdraw_limit)
    }
  }

  return (
    <>
      {banks?.length > 0 ? (
        <Card>
          <CardHeader>
            <h5>{t("financial.amount")}</h5>
            <p style={{ margin: 0 }}>{`${t("financial.availableBalance")} ${selectedWallet?.balance?.toFixed(2)} ${selectedWallet?.currency?.name
              }`}</p>
            {/* <span style={{ color: 'green' }}>
              {`Rate of 1 ${baseCurrency.toUpperCase()} to ${selectedPayment?.currency?.withdraw_rate
                } ${selectedPayment?.currency?.unit}`}
            </span> */}
            {
              availableCurrencies.map((currency) => {
                if (currency.id === selectedCurrency) {
                  return (
                    <span style={{ color: 'green' }}>
                      {`Rate of 1 ${baseCurrency.toUpperCase()} to ${currency?.withdraw_rate.toFixed(2)
                        } ${currency?.unit}`}
                    </span>
                  )
                }
              })
            }
          </CardHeader>
          <CardBody>
            <Row style={{ flexDirection: 'column' }}>
              <Col>
                <Label style={{ fontSize: '20px', fontWeight: '500' }}>{t("financial.Step1ChooseYourBank")}</Label>
              </Col>
              <Col sm={12}>
                <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                  <CardBody >
                    <FormGroup>

                      <Row >
                        <Col sm={12} >

                          <Label style={{ fontSize: '20px', fontWeight: '500' }}>
                            {t("financial.yourBanks")}

                          </Label>
                          <Input
                            className="form-control"
                            type="select"
                            name="bank_id"
                            innerRef={register({ required: true })}
                            onChange={(v) => handleBank(v.target.value)}
                          >
                            {banks?.length > 0 &&
                              banks.map((bank) => {
                                if (_.find(bankList, { code: bank.bank_name })) {
                                  return (
                                    <option key={bank.id} value={bank.id}>{`${bank.account_number
                                      } - ${translateBank(bank.bank_name)}`}</option>
                                  )
                                } else {
                                  return (
                                    <option key={bank.id} value={bank.id}>{`${bank.account_number
                                      } - ${translateBank(bank.bank_name)}`}</option>
                                  )
                                }
                              })}
                          </Input>

                          <Row>
                            <Col sm={6} className='m-t-20'>
                              <FormGroup>
                                <Label>{t("financial.accountHolderName")}: {accountHolder} </Label>
                              </FormGroup>
                            </Col>
                            <Col sm={6} className='m-t-20'>
                              <FormGroup>
                                <Label>{t("financial.bankName")}: {bankName}</Label>

                              </FormGroup>
                            </Col>

                            <Col sm={6}>
                              <FormGroup>
                                <Label>{t("financial.bankAccount")}: {accountNumber}</Label>

                              </FormGroup>
                            </Col>

                            <Col sm={6}>
                              <FormGroup>
                                <Label>{t("financial.bankSWIFTCode")}: {swiftCode}</Label>

                              </FormGroup>
                            </Col>

                          </Row>

                        </Col>

                      </Row>




                    </FormGroup>
                  </CardBody>
                </Card>


              </Col>

              <Col>
                <Label style={{ fontSize: '20px', fontWeight: '500' }}>{t("financial.Step2FillWithdrawDetails")}</Label>
              </Col>

              <Col sm={12}>
                <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                  <CardBody >
                    <FormGroup>

                      <Row>
                        <Col sm={6}>
                          <Label style={{ fontSize: '20px', fontWeight: '500' }}>
                            {t("financial.withdrawalAmount")}
                            <span
                              style={{ color: 'green', textTransform: 'uppercase' }}
                            >{` (${selectedPayment?.currency?.base_currency})`}</span>
                          </Label>


                          <Input
                            className="form-control"
                            type="number"
                            step=".01"
                            name="amount"
                            placeholder={t("financial.PleaseEnterAmount")}
                            innerRef={register({
                              required: t("financial.amountIsRequired"),
                              min: {
                                // value: 100 / (selectedPayment?.currency?.withdraw_rate ?? 1),
                                value: user.min_withdraw_amount.toString(),
                                message: t("plan.min.withdraw", {amount: user.min_withdraw_amount})
                              },
                              max: {
                                value: maxWithdrawal.toString(),
                                message: t("plan.max.withdraw", {amount: maxWithdrawal})
                              }
                            })}
                          />
                          <span style={{ color: 'red' }}>{errors.amount && errors.amount.message}</span>
                        </Col>

                        <Col sm={6}>
                          <Label style={{ fontSize: '20px', fontWeight: '500' }}>
                            Withdrawal Currency
                          </Label>
                          <Input type='select' name='currency_id' onChange={handleClick} innerRef={register({ required: true })}>
                            {availableCurrencies.map(currecy =>
                              <option value={currecy.id}>{currecy?.abbreviation}</option>
                            )}
                          </Input>
                          <p />
                        </Col>

                      </Row>


                      <Row>    
                   
                          {maxWithdrawal && maxWithdrawal != 9999999 && (

                            <Col sm={6}>
                               <p style={{ color: 'red' }}>
                                  {t('financial.MaximumWithdrawal')} {maxWithdrawal.toFixed(2)}USD{' '}
                                  {t('financial.perTransaction')}
                               </p>
                            </Col>
                          )}
                  
                        <Col sm={6}>
                          {
                            availableCurrencies.map((currency) => {
                              if (currency.id === selectedCurrency) {
                                return (
                                  <div style={{ color: 'green', marginTop: '10px' }}>
                                    {/* {t('financial.CurrentWithdrawRateOf1')} {currency?.base_currency?.toUpperCase()} ={' '}
                                    {(currency?.withdraw_rate).toFixed(2)} {currency?.abbreviation}
                                    <br /> */}
                                    {`${t("financial.youWillReceiveApproximately")} ${(watchAmount * currency?.withdraw_rate).toFixed(2)} ${currency?.abbreviation}`}
                                  </div>
                                )
                              }
                            })
                          }
                        </Col>

                        <Col sm={6}>
                          {
                            availableCurrencies.map((currency) => {
                              if (currency.id === selectedCurrency) {
                                return (
                                  <div style={{ color: 'green', marginTop: '10px' }}>
                                    {t('financial.CurrentWithdrawRateOf1')} {currency?.base_currency?.toUpperCase()} ={' '}
                                    {(currency?.withdraw_rate).toFixed(2)} {currency?.abbreviation}
                                    {/* <br />
                                    {`${t("financial.youWillReceiveApproximately")} ${(watchAmount * currency?.withdraw_rate).toFixed(2)} ${currency?.abbreviation}`} */}
                                  </div>
                                )
                              }
                            })
                          }
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <Label style={{ fontSize: '20px', fontWeight: '500' }} className="m-t-20">{t("financial.Reason")} </Label>
                            <Input
                              className="form-control"
                              type="select"
                              name="withdraw_reason"
                              innerRef={register({ required: true })}
                              onChange={(e) => {
                                setOtherOption(e.target.value)
                              }}
                            >
                              <option value=''>{t("financial.SelectReason")}</option>

                              <option value="Need funds urgently">{t("financial.NeedFundsUrgently")}</option>
                              <option value="Profit withdrawal">{t("financial.ProfitWithdrawal")}</option>
                              <option value="Competitors provide better benefits & services">{t("financial.CompetitorsProvideBetterBenefits")}</option>
                              <option value="The system is not user-friendly">{t("financial.TheSystemIsNot")}</option>
                              <option>{t("financial.Other")}</option>
                            </Input>
                            <span style={{ color: 'red', marginTop: '10px' }}>
                              {errors.withdraw_reason && t("financial.PleaseAtLeastSelect")}
                            </span>
                          </FormGroup>

                          {otherOption === 'Other' && (
                            <FormGroup>
                              <Label className="m-t-10">{t("financial.OtherReason")} </Label>
                              <Input
                                innerRef={register({ required: true })}
                                className="form-control input-txt-bx"
                                type="text"
                                name="withdraw_reason"
                                placeholder={t("financial.EnterYourReason")}
                              />
                            </FormGroup>
                          )}
                          {otherOption === "其他" && (
                            <FormGroup>
                              <Label className="m-t-10">{t("financial.OtherReason")} </Label>
                              <Input
                                innerRef={register({ required: true })}
                                className="form-control input-txt-bx"
                                type="text"
                                name="withdraw_reason"
                                placeholder={t("financial.EnterYourReason")}
                              />
                            </FormGroup>
                          )}
                          {otherOption === 'Competitors provide better benefits & services' && (
                            <FormGroup>
                              <Label className="m-t-10">{t('financial.competitorName')}</Label>
                              <Input
                                innerRef={register({ required: true })}
                                className="form-control input-txt-bx"
                                type="text"
                                name="competitor"
                                placeholder={t('financial.EnterCompetitorName')}
                              />
                            </FormGroup>
                          )}
                        </Col>

                      </Row>

                    </FormGroup>
                  </CardBody>
                </Card>


              </Col>


              {twoFA != null && (
                <>
                  <Col className="m-t-20">
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label style={{ fontSize: '20px', fontWeight: '500' }}>{t("financial.2FACode")}</Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="auth_code"
                            innerRef={register({ required: true })}
                          />
                          <span style={{ color: 'red' }}>
                            {errors.auth_code && t("financial.2FACodeIsRequired")}
                          </span>
                        </FormGroup>
                      </Col>
                      <Col sm={6} style={{ alignSelf: 'flex-end' }}>
                        <FormGroup>
                          <Button color="primary btn-block" onClick={send2fa} style={{ maxWidth: 250 }}>
                            {t("financial.sendAuthenticationCode")}
                          </Button>

                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <span style={{ color: 'green', display: 'block' }}>{authPending}</span>
                    <span style={{ color: 'red', display: 'block' }}>{authError}</span>
                  </Col>
                </>
              )}
              <Col sm={12} className="m-t-20">
                <FormGroup>
                  <div className="custom-control custom-checkbox mb-3">
                    <Input className="custom-control-input" id="agreement" type="checkbox" required />
                    <Label className="custom-control-label" htmlFor="agreement">
                      {t("financial.IEnsureThatTheDetails")}
                    </Label>
                    <div className="invalid-feedback">{t("user.PleaseAgreeToTerms")}</div>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Button
              color="primary btn-block"
              type="submit"
              style={{ maxWidth: 150, float: 'right' }}
              disabled={watchAmount > maxWithdrawal ?? 9999999}
            >
              {isSubmitting ? t("financial.loading") : t("financial.submit")}
            </Button>
          </CardBody>
        </Card>
      ) : (
          <Card>
            <CardBody>
              <p>{t("financial.noRecordOfBankAccountsFound")}</p>
              <Button onClick={() => navigate(`${process.env.PUBLIC_URL}/account#bank`)}>
                {t("financial.registerOneHere")}
              </Button>
            </CardBody>
          </Card>
        )}
    </>
  )
}

export default AwepayAmount
