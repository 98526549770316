import client from "../request"

const getAll = (data) => {
  return client.get("/promotions")
}

const getPromotionBanner = () => {
  return client.get("/dashboard_carousel_banners")
}

const getPromotions = (id) => {
  return client.get(`/promotions/${id}/limited_time`)
}

const getPromotecodes = (props = null) => {

  let limit = props?.limit ?? 100

  return client.get(`/promocodes?limit=${limit}`)
}


const claimPromotion = (id, data) => {
  return client.post(`/promotions/${id}/limited_time`, data)
}

export default {
  getAll,
  getPromotionBanner,
  getPromotions,
  claimPromotion,
  getPromotecodes
}
