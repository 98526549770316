import client from '../request'

const get = (id, props = null) => {
  let page = props?.page ?? 1
  let limit = props?.limit ?? 10

  return client.get(`/pamms/${id}/stats?page=${page}&limit=${limit}`)
}

export default {
  get
}
