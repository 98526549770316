import React, { Fragment, useState } from 'react'
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Spinner,
  Row,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  Tooltip
} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
// import ImageUploader from 'react-images-upload'
// import imageCompression from 'browser-image-compression'
// import SocialProfileService from '../../../network/services/social'
import FilesService from '../../../../network/services/files'
import EventService from '../../../../network/services/event'
import _ from 'lodash'
import styled from 'styled-components'

import { useHistory, Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

const MobileGap = styled.div`
  padding: 0;

  @media (max-width: 575px) {
    padding: 10px;
  }
`

const Step1 = () => {
  const { t, i18n } = useTranslation()

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const [tncModal, setTncModal] = useState(false)

  const tncToggle = () => setTncModal(!tncModal)

  const dispatch = useDispatch()
  const campusCompetition = useSelector(state => state.event?.competitions?.competitions?.filter(item => item.name === 'Tradehall Campus CFD Trading Contest Malaysia 2021'))
  // const myprofile = useSelector((state) => state.mySocialProfile.profiles)
  const profile = useSelector((state) => state.user.profile)

  const verification = useSelector((state) => state.verification)
  const demoAccounts = useSelector((state) =>
    state.account?.accounts?.filter((item) => item.account_type === 1)
  )

  const [name, setName] = useState('')
  const [avatar, setAvatar] = useState([])
  const [loading, setLoading] = useState(false)
  const [otherOption, setOtherOption] = useState('')

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toolTiptoggle = () => setTooltipOpen(!tooltipOpen)

  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false)

  const language = sessionStorage.getItem('language')

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    sessionStorage.setItem('language', language)
  }
  const { register, handleSubmit } = useForm({})

  const [isBusy, setIsBusy] = useState(false)
  const history = useHistory()
  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  const studentFront = useSelector((state) => state.verification.studentFront)
  const studentBack = useSelector((state) => state.verification.studentBack)

  const onSubmit = async (data) => {
    setLoading(true)
    setIsBusy(true)

    try {
      const studentFrontRes = await FilesService.create(verification.studentFront?.file)
      const studentBackRes = await FilesService.create(verification.studentBack?.file)

      const competitionId = 1
      const result = await EventService.submitCompetition({
        name: data['name'],
        university: otherOption === 'Other' ? data['otherUniversity'] : data['university'],
        competition_id: competitionId,
        attachment_1: studentFrontRes.data,
        attachment_2: studentBackRes.data
      })

      setLoading(false)
      if (result) {
        SweetAlert.fire({
          title: t('event.ThankYouYourAccount'),
          icon: 'success'
        }).then(() => {
          window.location.reload()
        })
        setIsBusy(false)
      }
    } catch (error) {
      setIsBusy(false)
      // console.log(error)

      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }

  return (
    <>
      <Form
        className="theme-form verify-auth-form"
        id="student-event-form"
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
      >
        <Col lg="6" id="term-col">
          <Card
            style={{
              height: '600px',
              backgroundColor: '#103c5a',
              color: 'white',
              borderRadius: '0px'
            }}
          >
            <CardBody
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <div>
                <h4>{t('event.TermsAndConditions')}</h4>
                <p className="m-t-20">1. {t('event.AvailableToAllCollege')}</p>
                <p>2. {t('event.ClientsMustHaveCompleted')}</p>
                <p>3. {t('event.ClientsMustProvide')}</p>
                <p>4. {t('event.ClientsCanOnlySubmit')}</p>
                <p>5. {t('event.CampusCFDTradingContest')}</p>
                <p>6. {t('event.AllTheSettings')}</p>
              </div>

              {/* For Laptop Version */}
              <div
                className="d-flex d-lg-flex d-xl-flex verify-KYC-image m-t-15"
                style={{ justifyContent: 'center', textAlign: 'center' }}
              >
                <div>
                  <img
                    src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotBlur.png"
                    alt=""
                    className="KYC-example-image"
                    style={{ width: '75%' }}
                  />
                  <p>{t('event.MustNotBeBlur')}</p>
                </div>
                <div>
                  <img
                    src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotCut.png"
                    alt=""
                    className="KYC-example-image"
                    style={{ width: '75%' }}
                  />
                  <p>{t('event.MustNotBeCutOff')}</p>
                </div>
              </div>
              {/* <div className='d-none d-lg-flex d-xl-flex m-t-20 verify-KYC-image'>
                                <div>
                                    <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustColor.png" alt="" className="KYC-example-image" style={{width:'100%'}} />
                                    <p>{t("verifyAuth.MustBeInColor")}</p>
                                </div>
                                <div>
                                    <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/goodExampleId.png" alt="" className="KYC-example-image" style={{width:'100%'}} />
                                    <p>{t("verifyAuth.Example")}</p>
                                </div>
                            </div> */}
            </CardBody>
          </Card>
        </Col>

        <Col lg="6" className="padding-verify-left" id="form-col">
          <Card style={{ borderRadius: '0px' }} id="event-card-height">
            <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
              <h4>{t('event.TradehallCampusCFDTrading')}</h4>

              <FormGroup>
                <Label className="m-t-10">{t('event.DisplayName')}: </Label>
                <Input
                  innerRef={register({ required: true })}
                  className="form-control input-txt-bx"
                  type="text"
                  name="name"
                  placeholder={t('event.EnterDisplayName')}
                />
              </FormGroup>

              <FormGroup>
                <Label className="m-t-20">{t('event.SchoolName')}: </Label>
                <Input
                  className="form-control"
                  type="select"
                  name="university"
                  innerRef={register({ required: true })}
                  onChange={(e) => setOtherOption(e.target.value)}
                >
                  <option value="" disabled>
                    {t('user.Select')}
                  </option>

                  <option value="AIMST University">AIMST University</option>
                  <option value="Asia e University (AeU)">Asia e University (AeU)</option>
                  <option value="Asia Pacific University of Technology and Innovation (APU)">
                    Asia Pacific University of Technology and Innovation (APU)
                  </option>
                  <option value="Asia Metropolitan University">Asia Metropolitan University</option>
                  <option value="Asia School of Business">Asia School of Business</option>

                  <option value="Binary University of Management and Entrepreneurship">
                    Binary University of Management and Entrepreneurship
                  </option>

                  <option value="City University">City University</option>
                  <option value="Curtin University, Sarawak Malaysia">
                    Curtin University, Sarawak Malaysia
                  </option>

                  <option value="DRB-HICOM University of Automotive Malaysia">
                    DRB-HICOM University of Automotive Malaysia
                  </option>

                  <option value="Global NXT University">Global NXT University</option>

                  <option value="HELP University">HELP University</option>
                  <option value="Heriot-Watt University Malaysia">
                    Heriot-Watt University Malaysia
                  </option>

                  <option value="INTI International University (IIU)">
                    INTI International University (IIU)
                  </option>
                  <option value="International Medical University (IMU)">
                    International Medical University (IMU)
                  </option>
                  <option value="Infrastructure University Kuala Lumpur (IUKL)">
                    Infrastructure University Kuala Lumpur (IUKL)
                  </option>
                  <option value="International University of Malaya-Wales">
                    International University of Malaya-Wales
                  </option>

                  <option value="Limkokwing University of Creative Technology">
                    Limkokwing University of Creative Technology
                  </option>

                  <option value="Newcastle University Medicine Malaysia">
                    Newcastle University Medicine Malaysia
                  </option>
                  <option value="Nilai University">Nilai University</option>

                  <option value="Malaysia University of Science & Technology (MUST)">
                    Malaysia University of Science & Technology (MUST)
                  </option>
                  <option value="Malaysia Institute of Supply Chain Innovation (MISI)">
                    Malaysia Institute of Supply Chain Innovation (MISI)
                  </option>
                  <option value="Management and Science University (MSU)">
                    Management and Science University (MSU)
                  </option>
                  <option value="Manipal International University">
                    Manipal International University
                  </option>
                  <option value="MAHSA University">MAHSA University</option>
                  <option value="Meritus University">Meritus University</option>
                  <option value="Monash University Malaysia">Monash University Malaysia</option>
                  <option value="Multimedia University (MMU)">Multimedia University (MMU)</option>

                  <option value="Open University Malaysia (OUM)">
                    Open University Malaysia (OUM)
                  </option>
                  <option>Other</option>

                  <option value="Perdana University">Perdana University</option>
                  <option value="Putra Business School">Putra Business School</option>

                  <option value="Quest International University Perak	">
                    Quest International University Perak
                  </option>

                  <option value="Raffles University Iskandar (RUI)">
                    Raffles University Iskandar (RUI)
                  </option>

                  <option value="Sunway University">Sunway University</option>
                  <option value="SEGi University">SEGi University</option>
                  <option value="Swinburne University of Technology, Sarawak Campus">
                    Swinburne University of Technology, Sarawak Campus
                  </option>

                  <option value="Taylor’s University">Taylor’s University</option>
                  <option value="The University of Nottingham Malaysia Campus">
                    The University of Nottingham Malaysia Campus
                  </option>

                  <option value="Universiti Islam Antarabangsa Malaysia (UIAM)">
                    Universiti Islam Antarabangsa Malaysia (UIAM)
                  </option>
                  <option value="Universiti Islam Malaysia, Cyberjaya">
                    Universiti Islam Malaysia, Cyberjaya
                  </option>
                  <option value="UNITAR International University">
                    UNITAR International University
                  </option>
                  <option value="Universiti Kebangsaan Malaysia (UKM)">
                    Universiti Kebangsaan Malaysia (UKM)
                  </option>
                  <option value="Universiti Kuala Lumpur (UniKL)">
                    Universiti Kuala Lumpur (UniKL)
                  </option>
                  <option value="Universiti Malaya (UM)">Universiti Malaya (UM)</option>
                  <option value="Universiti Malaysia Perlis (UniMAP)">
                    Universiti Malaysia Perlis (UniMAP)
                  </option>
                  <option value="University Malaysia of Computer Science and Engineering">
                    University Malaysia of Computer Science and Engineering
                  </option>
                  <option value="Universiti Malaysia Kelantan (UMK)">
                    Universiti Malaysia Kelantan (UMK)
                  </option>
                  <option value="Universiti Malaysia Sabah (UMS)">
                    Universiti Malaysia Sabah (UMS)
                  </option>
                  <option value="Universiti Malaysia Terengganu (UMT)">
                    Universiti Malaysia Terengganu (UMT)
                  </option>
                  <option value="Universiti Malaysia Pahang (UMP)">
                    Universiti Malaysia Pahang (UMP)
                  </option>
                  <option value="Universiti Malaysia Sarawak (UNIMAS)">
                    Universiti Malaysia Sarawak (UNIMAS)
                  </option>
                  <option value="Universiti Putra Malaysia (UPM)">
                    Universiti Putra Malaysia (UPM)
                  </option>
                  <option value="Universiti Pertahanan Nasional Malaysia, (UPNM)">
                    Universiti Pertahanan Nasional Malaysia, (UPNM)
                  </option>
                  <option value="Universiti Pendidikan Sultan Idris (UPSI)">
                    Universiti Pendidikan Sultan Idris (UPSI)
                  </option>
                  <option value="University of Reading Malaysia">
                    University of Reading Malaysia
                  </option>
                  <option value="Universiti Sains Malaysia (USM)">
                    Universiti Sains Malaysia (USM)
                  </option>
                  <option value="Universiti Sains Islam Malaysia (USIM)">
                    Universiti Sains Islam Malaysia (USIM)
                  </option>
                  <option value="Universiti Selangor (UNISEL)	">
                    Universiti Selangor (UNISEL){' '}
                  </option>
                  <option value="University of Southampton Malaysia Campus">
                    University of Southampton Malaysia Campus
                  </option>
                  <option value="Universiti Teknologi Malaysia (UTM)">
                    Universiti Teknologi Malaysia (UTM)
                  </option>
                  <option value="Universiti Tun Hussein Onn Malaysia (UTHM)">
                    Universiti Tun Hussein Onn Malaysia (UTHM)
                  </option>
                  <option value="Universiti Teknikal Malaysia Melaka (UTeM)">
                    Universiti Teknikal Malaysia Melaka (UTeM)
                  </option>
                  <option value="Universiti Teknologi MARA (UiTM)">
                    Universiti Teknologi MARA (UiTM)
                  </option>
                  <option value="Universiti Tenaga Nasional (UNITEN)">
                    Universiti Tenaga Nasional (UNITEN)
                  </option>
                  <option value="Universiti Tun Abdul Razak (UniRAZAK)">
                    Universiti Tun Abdul Razak (UniRAZAK)
                  </option>
                  <option value="Universiti Teknologi Petronas (UTP)">
                    Universiti Teknologi Petronas (UTP)
                  </option>
                  <option value="Universiti Tunku Abdul Rahman (UTAR)">
                    Universiti Tunku Abdul Rahman (UTAR)
                  </option>
                  <option value="Universiti Utara Malaysia (UUM)">
                    Universiti Utara Malaysia (UUM)
                  </option>
                  <option value="UCSI University">UCSI University</option>

                  <option value="Wawasan Open University">Wawasan Open University</option>
                  <option value="Xiamen University Malaysia Campus">
                    Xiamen University Malaysia Campus
                  </option>
                </Input>
              </FormGroup>

              {otherOption === 'Other' && (
                <FormGroup>
                  <Label className="m-t-10">{t('event.YourSchoolName')}: </Label>
                  <Input
                    innerRef={register({ required: true })}
                    className="form-control input-txt-bx"
                    type="text"
                    name="otherUniversity"
                    placeholder={t('event.EnterSchoolName')}
                  />
                </FormGroup>
              )}

              <hr />
              <p>
                {t('user.FrontAndBackOfYour')} {t('event.studentPass')}
              </p>
              <Row>
                <Col sm={12} md={6}>
                  <FormText color="muted" style={{ textTransform: 'capitalize' }}>
                    {t('user.Front')}
                  </FormText>
                  <Input
                    type="file"
                    name="file"
                    accept="image/png, image/jpeg, application/pdf"
                    onChange={(e) => {
                      dispatch({
                        type: 'SET_STUDENT_FRONT',
                        studentFront: { file: e?.currentTarget?.files[0] }
                      })
                    }}
                    required
                  />
                  <FormText color="muted">.jpg, .png, pdf, max 10 MB</FormText>
                  <span style={{ color: 'red' }}>
                    {studentFront?.error != null && studentFront.error}
                  </span>
                  <MobileGap />
                </Col>
                <Col sm={12} md={6}>
                  <FormText color="muted" style={{ textTransform: 'capitalize' }}>
                    {t('user.Back')}
                  </FormText>
                  <Input
                    type="file"
                    name="file"
                    accept="image/png, image/jpeg, application/pdf"
                    onChange={(e) => {
                      dispatch({
                        type: 'SET_STUDENT_BACK',
                        studentBack: { file: e?.currentTarget?.files[0] }
                      })
                    }}
                    required
                  />
                  <FormText color="muted">.jpg, .png, pdf, max 10 MB</FormText>
                  <span style={{ color: 'red' }}>
                    {studentBack?.error != null && studentBack.error}
                  </span>
                </Col>

                <div className="m-t-20" style={{ display: 'flex', marginLeft: '35px' }}>
                  <Input type="checkbox" required />
                  <p>
                    {t('event.ByCheckingThis')}
                    <span style={{ cursor: 'pointer', color: '#f9b600' }}>
                      <a
                        href={
                          sessionStorage.getItem('language') == 'en'
                            ? campusCompetition[0]?.terms_and_conditions
                            : campusCompetition[0]?.terms_and_conditions_chinese
                        }
                        target="_blank"
                      >
                        {' '}
                        {t('event.TermsAndConditions')}
                      </a>
                    </span>
                  </p>
                </div>
              </Row>
            </CardBody>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="m-b-20 m-r-20 ">
              <Button color="primary" disabled={loading}>
                {t('event.Submit')}
              </Button>
              {loading ? (
                <div
                  style={{
                    marginLeft: '10px'
                  }}
                >
                  <Spinner size="lg" color="warning" />
                </div>
              ) : (
                ''
              )}
            </div>
          </Card>
        </Col>
      </Form>
    </>
  )
}

export default Step1
