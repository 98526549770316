import React, { useState, useEffect, Fragment } from 'react'
import { Input } from 'reactstrap'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const BonusFilters = ({ handleFilter, filters }) => {
  const { t } = useTranslation()
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  useEffect(() => {
    let filterObject = { ...filters }

    if (fromDate != null) {
      filterObject['from_date'] = fromDate
    }
    if (toDate != null) {
      filterObject['to_date'] = toDate
    }

    handleFilter(filterObject)
    // eslint-disable-next-line
  }, [fromDate, toDate])

  return (
    <Fragment>
      <div className="product-filter">
        <h6 className="f-w-600">{t("financial.from")}</h6>
        <Input
          className="form-control digits"
          type="date"
          defaultValue={fromDate}
          onChange={(e) => {
            setFromDate(moment(e.currentTarget.value).utc().format())
          }}
        />
      </div>

      <div className="product-filter">
        <h6 className="f-w-600">{t("financial.to")}</h6>
        <Input
          className="form-control digits"
          type="date"
          defaultValue={toDate}
          onChange={(e) => {
            setToDate(moment(e.currentTarget.value).utc().format())
          }}
        />
      </div>
    </Fragment>
  )
}

export default BonusFilters
