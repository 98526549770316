import {
  SET_MONITORING_ACCOUNT,
  TOGGLE_MONITORING_PRESENTATION,
} from "../actionTypes";

const initial_state = {
  account: null,
  isGraphical: true,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_MONITORING_ACCOUNT:
      return { ...state, account: action.account };

    case TOGGLE_MONITORING_PRESENTATION:
      return { ...state, isGraphical: !state.isGraphical };

    default:
      return { ...state };
  }
};
