import React, { useState } from 'react'
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'

import UserService from '../../../network/services/user'
import { useTranslation } from 'react-i18next'

const ChangePasswordForm = () => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors, reset, formState } = useForm()
  const [error, setError] = useState(null)
  const { isSubmitting } = formState

  const handleChangePassword = async (data) => {
    // console.log(data)
    try {
      if (data !== '') {
        const result = await UserService.changePassword(data)
        // console.log(result)

        if (result.user) {
          reset()
          setError(null)
          SweetAlert.fire({
            title: t("user.PasswordChangedSuccessfully"),
            icon: 'success'
          })
        }
      } else {
        errors.showMessages()
      }
    } catch (error) {
      console.log(error)
      setError(error.message)
    }
  }

  return (
    <Form className="theme-form" onSubmit={handleSubmit(handleChangePassword)}>
      <Row>
        <Col>
          <FormGroup>
            <Label>{t("user.OldPassword")}</Label>
            <Input
              className="form-control"
              type="password"
              name="password"
              innerRef={register({ required: true })}
            />
            <span style={{ color: 'red' }}>{errors.password && t("user.OldpasswodRequired")}</span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>{t("user.NewPassword")}</Label>
            <Input
              className="form-control"
              type="password"
              name="new_password"
              innerRef={register({ required: true })}
            />
            <span style={{ color: 'red' }}>
              {errors.new_password && t("user.NewpasswordRequired")}
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>{t("user.ConfirmPassword")}</Label>
            <Input
              className="form-control"
              type="password"
              name="new_password_confirmation"
              innerRef={register({ required: true })}
            />
            <span style={{ color: 'red' }}>
              {errors.new_password_confirmation && t("user.ConfirmPasswordRequire")}
            </span>
            {error != null && <span style={{ color: 'red' }}>{error}</span>}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-0">
            <Button color="success" className="mt-2" disabled={isSubmitting} style = {{float: 'right'}}>
              {isSubmitting ? t("user.Loading") : t("user.ChangePassword")}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default ChangePasswordForm
