import client from '../request'
import _ from 'lodash'

const getAll = (props) => {
  let page = props?.page ?? 1
  let limit = props?.limit ?? 10
  let filters = props?.filters ?? null
  let filterString = ''
  let from_date = props?.from_date ?? 10
  let to_date = props?.to_date ?? 10

  if (!_.isEmpty(filters)) {
    // console.log('?')
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }

  return client.get(`/me/clients?page=${page}&from_date=${from_date}&to_date=${to_date}${filterString}`)
}

export default {
  getAll
}
