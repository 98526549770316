import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Row, Col, CardHeader, Label } from 'reactstrap'
import Breadcrumb from '../../layout/breadcrumb'
import { useSelector } from 'react-redux'

import MyPammAccountService from '../../network/services/myPammAccount'
import RegisterForm from './register/registerForm'
import PammRecord from './pammRecord/pammRecord'

import _ from 'lodash'
import { useTranslation } from 'react-i18next'


const MyPamm = () => {
  const { t } = useTranslation()
  const verification = useSelector((state) => _.last(state.user.verifications))
  const [pammManagerRecord, setPammManagerRecord] = useState(null)

  useEffect(() => {

    const checkUserStatus = async () => {
      const result = await MyPammAccountService.get()
      setPammManagerRecord(result.pamm_manager_application_record)
    }
    checkUserStatus()

  }, [])

  return (
    <>
      <Breadcrumb title={t("myPamm.myPAMMAccount")}></Breadcrumb>
      {verification?.status !== 1 ? (
        <>
          <h5 className="mb-3">{t("myPamm.withdrawal")}</h5>
          <Card>
            <CardBody>
              <p style={{ color: 'red', fontWeight: 'bold' }}>
                {t("myPamm.yourProfileIsntVerified")}
              </p>
            </CardBody>
          </Card>
        </>
      ) : <>
          {pammManagerRecord === null ?
            <Card className="m-3">
              <CardHeader>
                <h5>{t("myPamm.registerBecomePammManager")}</h5>
              </CardHeader>
              <CardBody style={{ display: 'flex' }}>
                <Col lg='6' className='p-0' style={{ display: 'flex', flexDirection: 'column' }}>
                  <h6 className="mb-3 mt-4">
                  {t("myPamm.benefitBecomePAMMManager")}
                  </h6>

                  <ol style={{ display: "flex", flexDirection: 'column', paddingInlineStart: '15px' }}>
                    <li className="mb-2">{t("myPamm.theOpportunityToGetExtra")}</li>
                    <li className="mb-2">{t("myPamm.transparentAndHighProfile")}</li>
                    <li className="mb-2">{t("myPamm.accountSettingsProtect")}</li>
                    <li className="mb-2">{t("myPamm.managementOfAllInvestments")}</li>
                    <li className="mb-2">{t("myPamm.automatedCalculationOfProfitSharing")}</li>
                    <a href="http://pamm.tradehall.co/opt-in" target="_blank">
                      {t("myPamm.moreInformation")}
                      </a>
                  </ol>
                  <Button className="mt-5" style={{ width: '75%' }} onClick={() => setPammManagerRecord('register-ing')}>{t("myPamm.becomePammManager")}</Button>
                </Col>

                <Col lg='6' className='d-none d-lg-block d-xl-block' style={{ margin: '0 auto', textAlign: 'center' }}>
                  <img width="400px" height="400px" src='https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/Wavy_Bus-23_Single-11+%5BConverted%5D-01.png' alt="registerIcon" style={{ margin: '0 auto' }} />
                </Col>
              </CardBody>
            </Card>


            : ''}
          {pammManagerRecord === 'register-ing' ? <RegisterForm></RegisterForm> : ''}
          {pammManagerRecord !== null && pammManagerRecord !== 'register-ing' ?
            <Row className="m-0">
              <Col sm="12">
                <Card>
                  <CardBody>
                    <PammRecord pammRecord={pammManagerRecord} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            : ''}
        </>
      }
    </>
  )

}

export default MyPamm