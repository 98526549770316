import React, { useState, useEffect } from 'react'
import {
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Spinner,
  Row,
  Container

} from 'reactstrap'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import EventService from '../../../../network/services/event'
import AccountService from '../../../../network/services/account'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import _ from 'lodash'

import { useTranslation } from 'react-i18next'

const RegisterChampionContest = () => {
  const { t, i18n } = useTranslation()
  let history = useHistory()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const { register, handleSubmit } = useForm({})
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [accountIsEligible, setAccountIsEligible] = useState(false)

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  const competitionAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter((e) => e.account_type === 3)
    } else if (state.account.accounts?.length === 0) {
      return []
    } else {
      return null
    }
  })

  // const contestAccount = async () => {
  //   const accountResponse = await AccountService.getAll()
  //   setQualifyAccounts(
  //     accountResponse?.accounts?.filter((acc) => {
  //       return (acc?.account_type === 0 || acc?.account_type === 2) && (acc?.package_id !== 7 && acc?.package_id !== 9)
  //     })
  //   )
  // }

  const checkIsEligible = (id) => {
    const selectedAccount = competitionAccounts?.filter((account) => account?.id === parseInt(id))
    // check selected account = 500 USD
    if (parseFloat(selectedAccount[0]?.balance) === 500) {
      setAccountIsEligible(true)
    } else {

      setAccountIsEligible(false)
    }
  }

  const onSubmit = async (data) => {

    // if (accountIsEligible === true) {
      setLoading(true)
      try {

        const competitionId = 6
        const result = await EventService.submitCompetitionLive({
          name: data['name'],
          competition_id: competitionId,
          account_id: data['account_id'],
          is_attend_live: 0
        })



        if (result) {

          const accountResponse = await AccountService.getAll()
          const record = await EventService.getMyCompetition({
            competition_id: 6
          })

          const accountCreated = accountResponse?.accounts?.filter(account => {
            return parseInt(account?.account_login) === record?.submitted_registrations[0]?.account_login
          })


          setLoading(false)
          SweetAlert.fire({
            title: t('event.CongratulationSuccessRegisterChampion'),
            // html: `
            // </br>
            //   <ul>
            //     <li>Account Login : ${accountCreated[0]?.account_login}</li>
            //     <li>${t('event.ServerName')} : TradehallLimited-Demo</li> 
            //     <li>${t('event.BalanceAmount')} : ${accountCreated[0]?.balance?.toFixed(2)} USD</li>
            //     <li>${t('event.LoginAccount')} : ${selectedAccount}</li>
            //   </ul>
            //   </br>
            //   <p>${t('event.KindlyUseTradehallLimited')}</p>
            // `,
            icon: 'success'
          }).then((result) => {
            if (result) {
              window.location.reload()

            }
          })
        }
      } catch (error) {
        setLoading(false)

        const errorCode = error.code
        const errorTranslate = error.translate_params
        SweetAlert.fire({
          title: t(errorCode, errorTranslate),
          icon: 'error'
        })
      }
    // } else {
    //   SweetAlert.fire({
    //     title: t('event.balanceNotSufficient'),
    //     icon: 'fail',
    //     confirmButtonText: 'Deposit'
    //   }).then((result) => {
    //     if (result) {
    //       navigate(`${process.env.PUBLIC_URL}/financial`)

    //     }
    //   })


    // }


  }

  const [isEligible, setIsEligible] = useState(false)
  const [myRegistrationRecord, setMyRegistrationRecord] = useState([])

  const fetchMyRegistrationRecord = async () => {
    // Hardcode competition id
    const record = await EventService.getMyCompetition({
      competition_id: 6
    })

    setMyRegistrationRecord(record?.submitted_registrations)
    setIsEligible(record?.is_eligible)
  }

  useEffect(() => {
    // Hardcode competition id
    fetchMyRegistrationRecord()
  }, [])

  return (
    <>
      {/*desktop size */}
      <Container>
        <Row className='m-0'>
          <Col className="d-none d-lg-block d-md-block mt-5 p-0" xl='6' lg='6' md='12' style={{ marginBottom: '20px' }}>
            <Row style={{ justifyContent: 'center', marginRight: '-50px' }}>
              <Col
                style={{
                  backgroundImage: "url('https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Register+poster-01.jpg  ')",
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '556px',
                  maxWidth: '556px',
                  width: '100%',
                  height: "100%",
                  maxHeight: '556px',
                  position: 'absolute',
                  zIndex: '2',
                  marginLeft: '-100px',
                  marginTop: '-50px',
                  borderRadius: '30px'
                }}
              >

              </Col>
              <Col
                style={{
                  background: '#EFB935',
                  maxWidth: '556px',
                  width: '100%',
                  height: "100%",
                  maxHeight: '556px',
                  zIndex: '1',
                  boxShadow: '0px 7px 20px #F8DE8680',
                  borderRadius: '30px',
                  writingMode: 'vertical-rl',
                  marginRight: "10px"
                }}
              >
                <p style={{ color: 'white', marginTop: '20px', marginRight: '0px', fontSize: '14px' }}>Tradehall Champion Cup 2022</p>
              </Col>
            </Row>
          </Col>
          {/*mobile size */}
          <Col className='d-lg-none d-md-none mt-5 p-0' xl='6' lg='6' md='12' style={{ marginBottom: '20px' }}>
            <Row style={{ justifyContent: 'center', marginLeft: '35px' }}>
              <Col
                style={{
                  backgroundImage: "url('https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Register+poster-01.jpg')",
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '300px',
                  maxWidth: '300px',
                  width: '100%',
                  height: "300px",
                  position: 'absolute',
                  zIndex: '2',
                  marginLeft: '-100px',
                  marginTop: '-50px',
                  borderRadius: '30px'
                }}
              >

              </Col>
              <Col
                style={{
                  background: '#EFB935',
                  maxWidth: '300px',
                  width: '100%',
                  height: "300px",
                  zIndex: '1',
                  boxShadow: '0px 7px 20px #F8DE8680',
                  borderRadius: '30px',
                  writingMode: 'vertical-rl',
                  marginRight: "10px"
                }}
              >
                <p style={{ color: 'white', marginTop: '20px', marginRight: '0px', fontSize: '14px' }}>Tradehall Champion Cup 2022</p>
              </Col>
            </Row>
          </Col>




          {/* <Card
              style={{
                backgroundColor: 'black',
                top: '0px',
                left: '60px',
                width: '556px',
                height: '556px',
                borderRadius: '30px',
                zIndex: '2'
              }}
            >

            </Card>
            <Card
              style={{
                backgroundColor: 'red',
                top: '-550px',
                left: '110px',
                height: '562px',
                width: '556px',
                borderRadius: '30px',
                writingMode: 'vertical-rl',
                zIndex: '1'


              }}
            >
              <p style={{ color: 'white', marginTop: '30px', fontSize: '14px' }}>testing texting</p>

            </Card> */}

          <Col lg='6 ' md='12'>
            <Form
              className="theme-form verify-auth-form"
              id="student-event-form"
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'flex-start'
              }}
            >
              <Card style={{ borderRadius: '0px', boxShadow: '0px 7px 20px #00000014', borderRadius: '30px' }} >
                <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3>Tradehall Champion Cup 2022</h3>
                  <p style={{ marginBottom: '0' }}>{t('event.RegistrationDate')}: 15/11/2022 - 31/12/2022</p>
                  <p>{t('event.ContestStartDate')}: 01/01/2023 - 31/03/2023</p>

                  <FormGroup>
                    <Label className="m-t-10">{t('event.DisplayName')}: </Label>
                    <Input
                      innerRef={register({ required: true })}
                      className="form-control input-txt-bx"
                      type="text"
                      name="name"
                      placeholder={t('event.EnterDisplayName')}
                    />
                  </FormGroup>

                  <Label className="m-t-10">{t('event.SelectContestAccount')}{' '}{t('event.ChampionRequirement')}: </Label>

                  <Input
                    type="select"
                    name="account_id"
                    className="form-control digits"
                    innerRef={register({ required: true })}
                    style={{
                      border: '1.5px solid #868686',
                      marginBottom: '5px'
                    }}
                    required
                    onChange={(e) => {
                      checkIsEligible(e.target.value)

                    }}
                  >
                    <option value="">{t('event.SelectAContestAccount')}</option>

                    {competitionAccounts?.length > 0 &&
                      competitionAccounts.map((account, index) => {
                        return (
                          <option key={index} value={account.id}>
                            {account.account_login} {account?.plan?.name} -{' '}
                            {formatter.format(account?.balance)}

                          </option>
                        )

                      })}
                  </Input>

                  <div style={{ display: 'flex', marginLeft: '20px', marginTop: '10px' }}>
                    <Input type="checkbox" required />
                    <p>
                      {t('event.ByCheckingThis')}
                      <span style={{ cursor: 'pointer', color: '#f9b600' }}>
                        <a
                          href="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/T%26C+TradeHall+Champion+Cup+2022+updated.pdf"
                          target="_blank"
                          style={{ color: '#f9b600' }}
                        >
                          {' '}
                          {t('event.TermsAndConditions')}
                        </a>
                      </span>
                    </p>
                  </div>
                  <Row className="m-t-20" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Col style={{ textAlign: 'center  ' }}>
                      <Button color='primary' disabled={loading} style={{ backgroundColor: "#EFB935", color: 'black', width: '233px', height: '57px', borderRadius: '30px', margin: '10px', boxShadow: '0px 7px 20px #00000014', fontSize: '18px' }}>
                        {t('event.Join')}
                      </Button>

                      <Button color='light-color' href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/T%26C+TradeHall+Champion+Cup+2022+updated.pdf' target='_blank' style={{ background: 'white', color: 'black', width: '233px', height: '57px', borderRadius: '30px', margin: '10px', boxShadow: '0px 7px 20px #00000014' }}>
                        {/* <a href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/TradeHall+Demo+Contest+2022+Terms+and+Conditions.pdf' target='_blank' style={{ fontSize: '18px', color: 'black' }}>
                          T&Cs
                        </a> */}
                        <p className='m-5' style={{ fontSize: '18px' }}>{t('event.TnCs')}</p>
                      </Button>
                    </Col>
                  </Row>



                </CardBody>
                <div style={{ display: 'flex', justifyContent: 'center' }} className="m-b-20">

                  {loading ? (
                    <div>
                      <Spinner size="lg" color="warning" />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Card>
            </Form>
          </Col>
        </Row>

      </Container>
      {/* <Form
        className="theme-form verify-auth-form"
        id="student-event-form"
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'flex-start'
        }}
      >
        <Col lg="7" id="term-col">
          <div style={{ maxWidth: '100%' }}>
            <img src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/CRM+Banner-01+(3).jpg' alt='tradehall contest' style={{ width: '100%' }} />
          </div>

          <Card style={{ padding: '24px 0px', borderRadius: '0' }}>
            <Row style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
              <div style={{ textAlign: 'center' }}>
                <Col>01/5/2022 (GMT+0) 00:00:00</Col>
                <Col>{t('event.RegistrationDate')}</Col>
              </div>

              <div style={{ textAlign: 'center' }}>
                <Col>01/6/2022 (GMT+0) 00:00:00</Col>
                <Col>{t('event.TradingPeriod')}</Col>
              </div>


            </Row>
          </Card>
        </Col>

        <Col lg="5" id="form-col">
          <Card style={{ borderRadius: '0px' }} >
            <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
              <h3>TradeHall Demo Contest 2022</h3>
              <p style={{ marginBottom: '0' }}>{t('event.RegistrationDate')}: 01/05/2022 - 01/06/2022</p>
              <p>{t('event.ContestStartDate')}: 01/06/2022 - 01/07/2022</p>

              <FormGroup>
                <Label className="m-t-10">{t('event.DisplayName')}: </Label>
                <Input
                  innerRef={register({ required: true })}
                  className="form-control input-txt-bx"
                  type="text"
                  name="name"
                  placeholder={t('event.EnterDisplayName')}
                />
              </FormGroup>

              <Input
                type="select"
                name="account_id"
                className="form-control digits"
                innerRef={register({ required: true })}
                style={{
                  border: '1.5px solid #868686',
                  marginBottom: '5px'
                }}
                required
                onChange={(e) => {
                  setSelectedAccount(e.target.value)
                }}
              >
                <option value="">{t('dashboard.selectMT5ToClaim')}</option>

                {competitionAccounts?.length > 0 &&
                  competitionAccounts.map((account, index) => {
                    return (
                      <option key={index} value={account.id}>
                        {account.account_login} {account?.plan?.name} -{' '}
                        {formatter.format(account?.balance)}
                      </option>
                    )
                  })}
              </Input>

              <div style={{ display: 'flex', marginLeft: '20px', marginTop: '10px' }}>
                <Input type="checkbox" required />
                <p>
                  {t('event.ByCheckingThis')}
                  <span style={{ cursor: 'pointer', color: '#f9b600' }}>
                    <a
                      href="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/TradeHall+Demo+Contest+2022+Terms+and+Conditions.pdf"
                      target="_blank"
                      style={{ color: 'black' }}
                    >
                      {' '}
                      {t('event.TermsAndConditions')}
                    </a>
                  </span>
                </p>
              </div>

              <div className="m-t-20" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button color="primary" disabled={loading}>
                  {t('event.Join')}
                </Button>

                <Button color="secondary" disabled={loading}>
                  <a href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/TradeHall+Demo+Contest+2022+Terms+and+Conditions.pdf' target='_blank'>
                    T&Cs
                  </a>
                </Button>
              </div>

            </CardBody>
            <div style={{ display: 'flex', justifyContent: 'center' }} className="m-b-20">

              {loading ? (
                <div>
                  <Spinner size="lg" color="warning" />
                </div>
              ) : (
                ''
              )}
            </div>
          </Card>
        </Col>
      </Form> */}
    </>
  )
}

export default RegisterChampionContest
