import React, { useState, useEffect, Fragment } from 'react'
import { Input, Label, Button } from 'reactstrap'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const CommissionFilters = ({ types, handleFilter, setSearchType, filters }) => {
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const { t } = useTranslation()
  useEffect(() => {
    let filterObject = { ...filters }

    if (fromDate != null) {
      filterObject['from_date'] = fromDate
    }else{
      filterObject['from_date'] = false
    }
    if (toDate != null) {
      filterObject['to_date'] = toDate
    }else{
      filterObject['to_date'] = false
    }
    
    handleFilter(filterObject)
    if(fromDate == null){
      setFromDate(null)
    }
    if(toDate == null){
      setToDate(null)
    }
   
    // eslint-disable-next-line
  }, [fromDate, toDate])

  return (
    <Fragment>
      <div className="product-filter">
        <h6 className="f-w-600">{t('partner.searchType')}</h6>
        <div className="checkbox-animated mt-0">
          {types.map((type, index) => {
            return (
              <Label className="d-block" key={type.id}>
                <Input
                  className="radio_animated"
                  id={type.id}
                  type="radio"
                  name="type"
                  onClick={(e) => {
                    setSearchType(type.id)
                  }}
                />
                {type.name}
              </Label>
            )
          })}
        </div>
      </div>

      <div className="product-filter">
        <h6 className="f-w-600">{t('partner.From')}</h6>

        <Input
          className="form-control digits"
          type="date"
          defaultValue = {fromDate == null ? 'yyyy-mm-dd' : ''}
          // {d == null && (value = 'yyyy-mm-dd')}
          // defaultValue ={'2019-02-02'}
          onChange={(e) => {
            setFromDate(moment(e.currentTarget.value).startOf('day').subtract(8, 'hours').format('yyyy-MM-DD HH:mm:ss'))
          }}
        />
         {/* <Button type = 'primary'onClick = {() => {setFromDate(null)}} style = {{borderRadius: '50%', height: '20px', width: '20px'}}>x</Button> */}
      </div>
      <div className="product-filter">
        <h6 className="f-w-600">{t('partner.To')}</h6>
        <Input
          className="form-control digits"
          type="date"
          placeholder="yyyy-dd-mm"
          // defaultValue={toDate}
          onChange={(e) => {
            setToDate(moment(e.currentTarget.value).endOf('day').subtract(8, 'hours').format('yyyy-MM-DD HH:mm:ss'))
          }}
        />
      </div>
      {/* <Button type = 'primary'  onClick = {() => {setToDate(null)}} style = {{borderRadius: '50%', height: '20px', width: '20px'}}>x</Button> */}
    </Fragment>
  )
}

export default CommissionFilters
