import React, { useEffect } from 'react'
import { Row, Col, Badge } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'

import {
  HoverCard,
  ResponsiveCard,
  ResponsiveIcon,
  ResponsiveImage,
  ResponsiveDiv
} from '../../components.style'
import { useTranslation } from 'react-i18next'

import awepayIcon from './awepayIcon.svg'
import usdtIcon from './UsdtIcon.svg'
import mcPaymentIcon from './mcpayment.svg'
import ewalletIcon from './ewalletIcon.svg'
import creditCardIcon from './creditCardIcon.svg'
import epayIcon from './epayIcon.svg'

const SelectPaymentMethod = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const payments = useSelector((state) => state.payment.payments)
  const deposit = useSelector((state) => state.deposit)

  const availablePaymentsMethods = payments.filter((item) => {
    const result = item?.supportedCurrencies.find((currency) => currency.allow_deposit == true)
    if (result) {
      return item
    }
  })

  useEffect(() => {
    // set default payments id
    if (availablePaymentsMethods?.length > 0 && deposit.wallet.method?.id == null) {
      dispatch({
        type: 'SET_DEPOSIT_WALLET_METHOD',
        method: { id: availablePaymentsMethods[0].id, handle: availablePaymentsMethods[0].handle }
      })
    }
    // eslint-disable-next-line
  }, [availablePaymentsMethods])

  return (
    <>
      <h5>{t('financial.selectPaymentMethod')}</h5>

      <Row>
        {availablePaymentsMethods?.length > 0 &&
          availablePaymentsMethods.map((payment, index) => {
            return (
              <Col sm="12" lg="6" key={payment.id}>
                <HoverCard
                  isSelected={deposit?.wallet?.method?.id === payment.id ? '#f9b600' : null}
                  onClick={() => {
                    dispatch({
                      type: 'SET_DEPOSIT_WALLET_METHOD',
                      method: { id: payment.id, handle: payment.handle }
                    })
                  }}
                  style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}
                >
                  <ResponsiveCard>
                    {payment.handle === 'awepay' && (
                      <ResponsiveImage
                        src={awepayIcon}
                        alt="bank transfer"
                        style={{ width: '50px' }}
                      />
                    )}
                    {(payment.handle === 'bluepay' || payment.handle === 'multichain') && (
                      <ResponsiveImage src={usdtIcon} alt="tether" style={{ width: '50px' }} />
                    )}
                    {payment.handle === 'wire' && (
                      <ResponsiveImage
                        src="/wire_transfer.png"
                        alt="wire transfer"
                        style={{ width: '50px' }}
                      />
                    )}
                    {payment.handle === 'mcpayment' && (
                      <ResponsiveImage
                        src={mcPaymentIcon}
                        alt="instant tranfer"
                        style={{ width: '50px' }}
                      />
                    )}
                    {payment.handle === 'mcpayment_ewallet' && (
                      <ResponsiveImage
                        src={ewalletIcon}
                        alt="instant tranfer"
                        style={{ width: '50px' }}
                      />
                    )}

                    {payment.handle === 'mcpayment_credit_card' && (
                      <ResponsiveImage
                        src={creditCardIcon}
                        alt="credit card"
                        style={{ width: '50px' }}
                      />
                    )}

                    {payment.handle === 'epay' && (
                      <ResponsiveImage
                        src={epayIcon}
                        alt="credit card"
                        style={{ width: '50px' }}
                      />
                    )}
                    <ResponsiveDiv>
                      {/* {payment.handle === 'mcpayment' && (
                        <Badge color="success" className="m-b-5">{t("financial.Recommended")}</Badge>
                      )} */}

                      <h6>{`${payment.name}`}</h6>
                      <p>{payment.funding_time} {payment.handle != 'mcpayment_credit_card' && <span> - {payment.fee_description}</span>}</p>

                      {/* {(payment.handle === 'awepay') && (
                        <p>{t("financial.SupportedCurrency")}: {payment.supportedCurrencies.filter(item => item.allow_deposit == true).map(item => item.currency.unit).join(', ')}</p>
                      )}

                      {(payment.handle === 'multichain') && (
                        <p>{t("financial.Support")}: {payment.supportedCurrencies.filter(item => item.allow_deposit == true).map(item => item.currency.unit).join(', ')}</p>
                      )} */}

                      {/* {payment.handle === 'mcpayment' && (
                        <p>{t("financial.SupportedCurrency")}: SGD</p>
                      )} */}




                      <p>

                        {payment.handle == 'multichain'
                          ? t('financial.Support')
                          : t('financial.SupportedCurrency')}
                        :{' '}
                        {payment?.supportedCurrencies
                          ?.filter((item) => item.allow_deposit == true)
                          .map((item) => item.currency.unit)
                          .join(', ')}
                      </p>

                      <p>
                        {payment.handle == 'mcpayment_ewallet'
                          && t('financial.Support') + ` : ${' '}`
                        }

                        {payment.handle == 'mcpayment_ewallet' && 'Boost, TouchNGo, GrabPay'}
                      </p>


                    </ResponsiveDiv>
                  </ResponsiveCard>
                </HoverCard>
              </Col>
            )
          })}
      </Row>
    </>
  )
}

export default SelectPaymentMethod
