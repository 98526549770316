import client from "../request"

const getAll = () => {
  return client.get("/me/transfers")
}

const get = (id) => {
  return client.get(`/me/transfers/${id}`)
}

const create = (data) => {
  return client.post(`/me/transfers`, data)
}

const createMt5Transfer = (data) => {
  return client.post(`/me/accounts/transfers`, data)
}

export default {
  getAll,
  get,
  create,
  createMt5Transfer
}
