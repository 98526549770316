import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'

import {
  HoverCard,
  ResponsiveCard,
  ResponsiveIcon,
  ResponsiveImage,
  ResponsiveDiv
} from '../../components.style'

import { useTranslation } from 'react-i18next'

import usdtIcon from '../../deposit/wallet/UsdtIcon.svg'

const SelectPaymentMethod = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const payments = useSelector((state) => state.payment.payments)
  const withdrawal = useSelector((state) => state.withdrawal)

  // filter the mcpayment in withdraw
  // const excludeMcpPayments = payments.filter((payment) => payment?.handle !== 'mcpayment')
  const availablePaymentsMethods = payments.filter((item) => {
    const result = item?.supportedCurrencies.find((currency) => currency.allow_withdraw === true)
    if (result) {
      return item
    }
  })

  useEffect(() => {
    if (payments?.length > 0) {
      dispatch({
        type: 'SET_WITHDRAWAL_WALLET_METHOD',
        method: { id: payments[0].id, handle: payments[0].handle }
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <h5>{t('financial.selectPaymentMethod')}</h5>

      <Row>
        {availablePaymentsMethods?.length > 0 &&
          availablePaymentsMethods.map((payment, index) => {
            return (
              <Col sm="12" lg="6" key={payment.id}>
                <HoverCard
                  isSelected={withdrawal?.wallet?.method?.id === payment.id ? '#f9b600' : null}
                  onClick={() => {
                    dispatch({
                      type: 'SET_WITHDRAWAL_WALLET_METHOD',
                      method: { id: payment.id, handle: payment.handle }
                    })
                  }}
                >
                  <ResponsiveCard>
                    {payment.handle === 'awepay' && (
                      <ResponsiveIcon className="icofont icofont-bank" />
                    )}
                    {payment.handle === 'bluepay' && (
                      <ResponsiveImage src="/tether.png" alt="tether" />
                    )}
                    {payment.handle === 'wire' && (
                      <ResponsiveImage src="/wire_transfer.png" alt="wire transfer" />
                    )}

                    {payment.handle === 'multichain' && (
                      <ResponsiveImage src={usdtIcon} alt="multichain" />
                    )}

                    {payment.handle === 'awepay' && (
                      <ResponsiveDiv>
                        <h6>{`${payment.name}`}</h6>
                        <p>{`3-5 days`}</p>
                        <p>
                          {t('financial.Support')}:{' '}
                          {payment?.supportedCurrencies
                            ?.filter((item) => item.allow_withdraw === true)
                            .map((item) => item.currency.unit)
                            .join(', ')}
                        </p>
                      </ResponsiveDiv>
                    )}

                    {payment.handle === 'multichain' && (
                      <ResponsiveDiv>
                        <h6>{`${payment.name}`}</h6>
                        <p>{`${payment.funding_time} with fee depends on network`}</p>
                        <p>
                          {t('financial.Support')}:{' '}
                          {payment?.supportedCurrencies
                            ?.filter((item) => item.allow_withdraw === true)
                            .map((item) => item.currency.unit)
                            .join(', ')}
                        </p>
                      </ResponsiveDiv>
                    )}

                    {payment.handle === 'wire' && (
                      <ResponsiveDiv>
                        <h6>{`${payment.name}`}</h6>
                        <p>{`${payment.funding_time} with fee depends on bank`}</p>
                        <p>
                          {t('financial.Support')}:{' '}
                          {payment?.supportedCurrencies
                            ?.filter((item) => item.allow_withdraw === true)
                            .map((item) => item.currency.unit)
                            .join(', ')}
                        </p>
                      </ResponsiveDiv>
                    )}
                  </ResponsiveCard>
                </HoverCard>
              </Col>
            )
          })}
      </Row>
    </>
  )
}

export default SelectPaymentMethod
