import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { useTranslation } from 'react-i18next'



const Paragraph = styled.p`
  margin: 0;

  @media only screen and (max-width: 575px) {
    margin: auto;
  }
`

const Footer = (props) => {
  const { t } = useTranslation()
  const Year = new Date();
  return (
    <>
      <footer className="footer">
        <Container>
          <Row>
            <Col md="6" className="footer-copyright">
              {/* <p className="mb-0">{t("footer.support")}</p> */}
              <p className="mb-0">support@tradehall.co</p>
            </Col>
            <Col
              sm="12"
              md="6"
              style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
            >
              <Paragraph>{t("footer.copyright")} {Year.getFullYear()}</Paragraph>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
