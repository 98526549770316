import React from 'react'

import {
    Container
} from 'reactstrap'

import Breadcrumb from '../../layout/breadcrumb'
import Vouchers from './allVouchers/index'
import { useTranslation } from 'react-i18next'


const Voucher = () => {
    const { t } = useTranslation()
    // const [showGraph, setShowGraph] = useState(true)

    return <>
        <Breadcrumb parent={t("voucher.Vouchers")} title={t("voucher.Vouchers")}></Breadcrumb>
        <Container style={{ paddingBottom: 40 }}>
            <Vouchers />
        </Container>

    </>
}

export default Voucher