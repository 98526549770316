
let vePakage

export default vePakage = {
    goldenClub: {
        benefits: [


            {
                name: 'vePackageInfo.VETradercamp',
                included: 'yes'
            }
            ,
            {
                name: 'vePackageInfo.VTradeSystem',
                included: 'yes'
            },
            {
                // name: '个人专属顾问',
                // name: 'vePackageInfo.ExclusivePersonalConsultant',
                name: 'vePackageInfo.ExclusivePersonalConsultant',
                included: 'yes'
            },
            {
                name: 'vePackageInfo.VEEchofinSocialNetworkFirstMonthFree',
                included: 'yes'
            },
            {
                name: 'vePackageInfo.VEEchofinTradingSignalsFirstMonthFree',
                included: 'yes'
            },





        ]
    },

    diamondClub: {
        benefits: [
            
            {
                name: 'vePackageInfo.LiveTradingAccess',
                included: 'yes'
            },
            {
                // name: '个人专属顾问',
                name: 'vePackageInfo.ECNPROAccount',
                included: 'yes'
            }
            ,
            {
                name: 'vePackageInfo.RiskManagingTradingSystem',
                included: 'yes'
            },

            {
                name: 'vePackageInfo.VEEchofinSocialNetworkFirst3MonthsFree',
                included: 'yes'
            },
            {
                name: 'vePackageInfo.VEEchofinTradingSignalsFirst3MonthsFree',
                included: 'yes'
            },


        ]
    },

    VVIP: {
        benefits: [
            {
                // name: '个人专属顾问',
                name: 'vePackageInfo.ECNVIPAccount',
                included: 'yes'
            },
            {
                name: 'vePackageInfo.HourOnlineExpertAdvice',
                included: 'yes',
                support: 3
            },
            {
                name: 'vePackageInfo.SpecialTradeUpTo',
                included: 'yes',
                support: 3
            }
            ,
            {
                // name: '个人专属顾问',
                name: 'vePackageInfo.CreditBonusOnFirstDeposit',
                included: 'yes',
                support: 3
            },
            {
                name: 'vePackageInfo.VEEchofinSocialNetworkFirst6MonthsFree',
                included: 'yes'
            },
            {
                name: 'vePackageInfo.VEEchofinTradingSignalsFirst6MonthsFree',
                included: 'yes'
            },

        ]
    }

}


