import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Breadcrumb from '../../../layout/breadcrumb'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import CampusRegisterForm from './registrationForm/index'

import _ from 'lodash'
import {
  Button,
  CardBody,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Form,
  Spinner
} from 'reactstrap'

import styled from 'styled-components'

import { ResponsiveDiv } from '../../common/components.style'

import DemoSettings from '../../common/settings'
import MasterPassword from '../../common/settings/master_password'
import InvestorPassword from '../../common/settings/investor_password'

import AccountService from '../../../network/services/account'
import EventService from '../../../network/services/event'

import LeaderboardTable from './leaderboard'
import { useForm } from 'react-hook-form'

import SweetAlert from 'sweetalert2'

// import EventBanner from './17052021Campus.svg'

import moment from 'moment'

const Event = () => {
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm({})

  const [loading, setLoading] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const selectedAccount = useSelector((state) => state.dashboard.account)

  //   const campusCompetition = useSelector((state) =>
  //     state.event?.competitions?.competitions?.filter(
  //       (item) => item.name === 'Tradehall Campus CFD Trading Contest Malaysia 2021'
  //     )
  //   )

  //   const campusCompetitionStartDate = moment(campusCompetition[0]?.start_date).format('YYYY-MM-DD')
  //   const currentDate = moment().format('YYYY-MM-DD')

  const USDformatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  // const eventLeaderboardResponse = await EventService.getLeaderBoardResult({
  //     competition_id: competitionId
  // })
  // dispatch({
  //     type: 'SET_LEADERBOARD_STATUS',
  //     leaderBoard: eventLeaderboardResponse
  // })

  const [showModal, setShowModal] = useState(false)
  const [selectedSetting, setSelectedSetting] = useState(null)

  const [resetModal, setResetModal] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const restToggleModal = () => {
    setResetModal(!resetModal)
  }

  const user = useSelector((state) => state.user)

  const verification = useSelector((state) => _.last(state.user.verifications))
  const [myCompetitionResult, setMyCompetitionResult] = useState({})

  const fetchCompetitionResponse = async () => {
    // Hardcode competition id
    setIsBusy(true)
    const myCompetitionResponse = await EventService.myCompetitionResult({
      competition_id: 2
    })

    setMyCompetitionResult(myCompetitionResponse)
    setIsBusy(false)
  }

  const competitionAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter((e) => e.account_type === 3)
    } else if (state.account.accounts?.length == 0) {
      return []
    }

    return null
  })

  const registedAccount = competitionAccounts?.filter(
    (account) =>
      parseInt(account?.account_login) === myCompetitionResult?.my_participation?.account_login
  )

  //   console.log('competitionAccounts', competitionAccounts)
  //   console.log('myCompetitionResult?.my_participation_result', myCompetitionResult?.my_participation)
  //   console.log('registedAccount', registedAccount)

  useEffect(() => {
    // Hardcode competition id
    init()
    fetchCompetitionResponse()
    // eslint-disable-next-line
  }, [])

  const currencies = useSelector((state) => state.currency.currencies)
  //Form is set to false in initial
  const dispatch = useDispatch()

  const toOrdinalSuffix = (num) => {
    const int = parseInt(num),
      digits = [int % 10, int % 100],
      ordinals = ['st', 'nd', 'rd', 'th'],
      oPattern = [1, 2, 3, 4],
      tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
      ? int + ordinals[digits[0] - 1]
      : int + ordinals[3]
  }

  const settings = [
    {
      id: 1,
      title: t('demo.titleChangeMaster'),
      cta: <MasterPassword setShowModal={setShowModal} />
    },
    {
      id: 2,
      title: t('demo.titleChangeInvestor'),
      cta: <InvestorPassword setShowModal={setShowModal} />
    }
  ]

  const init = async () => {
    const accountResponse = await AccountService.getContestAccount()
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
  }

  useEffect(() => {
    if (selectedSetting != null && selectedAccount != null) {
      setShowModal(true)
    }
  }, [selectedSetting, selectedAccount])

  useEffect(() => {
    if (showModal === false) {
      // fetch account
      if (selectedAccount != null) {
        init()
      }

      setSelectedSetting(null)
      dispatch({
        type: 'SET_DASHBOARD_ACCOUNT',
        account: null
      })
    }
    // eslint-disable-next-line
  }, [showModal])

  const [accountIsEligible, setAccountIsEligible] = useState(null)

  const checkIsEligible = (id) => {
    const selectedAccount = competitionAccounts?.filter((account) => account?.id === parseInt(id))
    // console.log(selectedAccount[0])
    if (parseFloat(selectedAccount[0]?.mt5_trade?.Balance) >= 100) {
      //   console.log('true')
      setAccountIsEligible(true)
    } else {
      //   console.log('false')
      setAccountIsEligible(false)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    setIsBusy(true)

    // console.log('data', data)
    try {
      // Competition ID for Road to Champion
      const competitionId = 2
      const result = await EventService.restCompetitionRoadToChampion({
        competition_id: competitionId,
        account_id: data['account_id']
      })

      setLoading(false)
      if (result) {
        SweetAlert.fire({
          title: t('competition.YourResetAccountPending'),
          icon: 'success'
        }).then(() => {
          window.location.reload()
        })
        setIsBusy(false)
      }
    } catch (error) {
      setIsBusy(false)
      setLoading(false)
      console.log(error)

      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }

  return (
    <>
      <Breadcrumb title={t('event.contest')} parent="Road to Champion" />
      <CampusRegisterForm/>
      {competitionAccounts == null && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50vh'
          }}
        >
          <Spinner />
        </div>
      )}

      {user?.profile !== {} &&
        verification?.status === 1 &&
        competitionAccounts?.length > 0 ? (
          <div style={{ padding: '0px' }}>
            {myCompetitionResult?.my_participation === null ? (
              //hide the registration form
              // <CampusRegisterForm /> 
              ''
            ) : isBusy === false ? (
              <>
                {/* <img className="d-block" style={{ width: '80%', margin: '0 auto' }} src={EventBanner} alt='event banner' /> */}
                <Row style={{ margin: '0px 0px 0px 0px' }}>
                  <Col
                    lg="7"
                    style={{ paddingRight: '15px' }}
                    className="d-block d-md-block d-lg-block d-xl-block padding-verify-right m-t-15"
                  >
                    <img
                      src="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Road+to+Champion+contest+inside+CRM-02.svg"
                      alt="road to champion"
                    />
                    {myCompetitionResult?.my_participation?.status === 1 &&
                      registedAccount?.length > 0
                      ? registedAccount.map((account) => {
                        const currency = currencies.find((e) => e.id === account.currency_id)
                        return (
                          <Card
                            style={{ height: 'fit-content', margin: '0px', borderRadius: '0px' }}
                            className="card-absolute"
                            key={account.id}
                          >
                            <CardHeader className="bg-primary">
                              <h6 style={{ margin: 0 }}>{`#${account.account_login} - ${account.plan?.name ?? '-'
                                }`}</h6>
                            </CardHeader>
                            <div className="card-right">
                              <ResponsiveDiv>
                                <DemoSettings
                                  settings={settings}
                                  account={account}
                                  setSelectedSetting={setSelectedSetting}
                                  setResetModal={setResetModal}
                                />
                              </ResponsiveDiv>
                            </div>

                            {myCompetitionResult?.my_participation?.switch_account_status ===
                              null ||
                              myCompetitionResult?.my_participation?.switch_account_status === 1 ||
                              myCompetitionResult?.my_participation?.switch_account_status === 2 ? (
                                <>
                                  <CardBody
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-around',
                                      paddingTop: '0px'
                                    }}
                                    id='card-365-event'
                                  >
                                    <Row>
                                      <Col
                                        className="text-center"
                                        lg="4"
                                        xs="12"
                                        style={{ padding: '0' }}
                                      >
                                        <div
                                          style={{
                                            margin: '0 20px',
                                            padding: '10px 0px',
                                            border: '1px solid #c4c4c4',
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                          }}
                                        >
                                          <h6>
                                            {`${myCompetitionResult?.my_ranking === null
                                              ? '-'
                                              : toOrdinalSuffix(myCompetitionResult?.my_ranking)
                                              }`}
                                          </h6>
                                          <p>{t('event.MYRANKING')}</p>
                                        </div>
                                      </Col>

                                      <Col
                                        className="text-center"
                                        lg="4"
                                        xs="12"
                                        style={{ padding: '0' }}
                                      >
                                        <div
                                          style={{
                                            margin: '0 20px',
                                            padding: '10px 0px',
                                            border: '1px solid #c4c4c4',
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                          }}
                                        >
                                          <h6>
                                            {`${account?.balance === null
                                              ? '-'
                                              : USDformatter.format(account?.balance) ?? '-'
                                              }`}
                                          </h6>
                                          <p>{t('demo.balance')}</p>
                                        </div>
                                      </Col>

                                      <Col
                                        className="text-center"
                                        lg="4"
                                        xs="12"
                                        style={{ padding: '0' }}
                                      >
                                        <div
                                          style={{
                                            margin: '0 20px',
                                            padding: '10px 0px',
                                            border: '1px solid #c4c4c4',
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                          }}
                                        >
                                          <h6>
                                            {`${myCompetitionResult?.my_participation_result === null
                                              ? '-'
                                              : myCompetitionResult?.my_participation_result
                                                ?.overall_growth + '%' ?? '-'
                                              }`}
                                          </h6>
                                          <p>{t('event.GROWTH')}</p>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="m-t-20">
                                      <Col
                                        className="text-center"
                                        lg="4"
                                        xs="12"
                                        style={{ padding: '0' }}
                                      >
                                        <div
                                          style={{
                                            margin: '0 20px',
                                            padding: '10px 0px',
                                            border: '1px solid #c4c4c4',
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                          }}
                                        >
                                          <h6>
                                            {`${myCompetitionResult?.my_participation_result === null
                                              ? '-'
                                              : USDformatter.format(
                                                myCompetitionResult?.my_participation_result
                                                  ?.overall_profit
                                              ) ?? '-'
                                              }`}
                                          </h6>
                                          <p>{t('event.TOTALPROFIT')}</p>
                                        </div>
                                      </Col>
                                      <Col
                                        className="text-center"
                                        lg="4"
                                        xs="12"
                                        style={{ padding: '0' }}
                                      >
                                        <div
                                          style={{
                                            margin: '0 20px',
                                            padding: '10px 0px',
                                            border: '1px solid #c4c4c4',
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                          }}
                                        >
                                          <h6>
                                            {account?.leverage === null
                                              ? '-'
                                              : `1:${account?.leverage}` ?? '-'}
                                          </h6>
                                          <p>{t('demo.leverage')}</p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </>
                              ) : (
                                <CardBody
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                    marginTop: '0',
                                    paddingTop: '0px'
                                  }}
                                >
                                  <Row className="m-t-20">
                                    <Col
                                      className="text-center"
                                      lg="4"
                                      xs="12"
                                      style={{ padding: '0' }}
                                    >
                                      <div
                                        style={{
                                          margin: '0 20px',
                                          padding: '10px 0px',
                                          border: '1px solid #c4c4c4',
                                          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                        }}
                                      >
                                        <h6>
                                          {`${myCompetitionResult?.my_participation === null
                                            ? '-'
                                            : myCompetitionResult?.my_participation?.account_login
                                            }`}
                                        </h6>
                                        <p>{t('competition.OriginalAccount')}</p>
                                      </div>
                                    </Col>

                                    <Col
                                      className="text-center"
                                      lg="4"
                                      xs="12"
                                      style={{ padding: '0' }}
                                    >
                                      <div
                                        style={{
                                          margin: '0 20px',
                                          padding: '10px 0px',
                                          border: '1px solid #c4c4c4',
                                          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                        }}
                                      >
                                        <h6>
                                          {`${myCompetitionResult?.my_participation === null
                                            ? '-'
                                            : myCompetitionResult?.my_participation
                                              ?.switching_account_login
                                            }`}
                                        </h6>
                                        <p>{t('competition.ResettingAccount')}</p>
                                      </div>
                                    </Col>

                                    <Col
                                      className="text-center"
                                      lg="4"
                                      xs="12"
                                      style={{ padding: '0' }}
                                    >
                                      <div
                                        style={{
                                          margin: '0 20px',
                                          padding: '10px 0px',
                                          border: '1px solid #c4c4c4',
                                          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                        }}
                                      >
                                        <h6>
                                          {`${myCompetitionResult?.my_participation === null
                                            ? '-'
                                            : myCompetitionResult?.my_participation
                                              ?.switch_account_status === 0 &&
                                            t('competition.Pending')
                                            }`}
                                        </h6>
                                        <p>{t('competition.ResetStatus')}</p>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              )}
                          </Card>
                        )
                      })
                      : ''}
                  </Col>
                  <Col lg="5" style={{ paddingRight: '15px' }} className="m-t-15">
                    <Card style={{ height: 'fit-content' }}>
                      <CardBody
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: '#103c5a',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div style={{ color: 'white' }}>
                          <h3>{t('competition.TNC')}</h3>
                          <p className="m-t-20">1. {t('competition.RegistrationRunsPeriod')}</p>
                          <p>2. {t('competition.ContestRunsPeriod')}</p>
                          <p>3. {t('competition.CompleteLegalAge')}</p>
                          <p>4. {t('competition.DisableUntilContestStarts')}</p>
                          <p>5. {t('competition.EANotAllowed')}</p>
                        </div>
                        <Button
                          color="primary"
                          style={{
                            color: 'white',
                            float: 'right',
                            width: '50%',
                            alignSelf: 'flex-end',
                            marginTop:'20px'
                          }}
                        >
                          <a
                            style={{ color: 'white' }}
                            href="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/T%26C+Road+to+Champion+Contest.pdf"
                            target="_blank"
                          >
                            {t('competition.ViewDetails')}
                          </a>
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
                  <Spinner />
                )}

            <Col style={{ margin: '15px auto' }}>
              <h3 class="text-center" style={{ color: '#113b5a' }}>
                {t('event.LEADERBOARD')}
              </h3>
              <LeaderboardTable />
            </Col>
          </div>
        ) : (
          <Row style={{ margin: '0px 0px 0px 0px' }}>
            <Col lg="6" style={{ paddingRight: '15px' }} className="m-t-15">
              {!user?.profile?.user_id ||
                user?.verifications[0]?.status !== 1 ||
                competitionAccounts?.length == 0 ? (
                  <Card style={{ height: 'fit-content' }}>
                    <CardBody
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#103c5a',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div style={{ color: 'white' }}>
                        <h3>{t('competition.TNC')}</h3>
                        <p className="m-t-20">
                          1. {t('competition.FillInPersonalDetails')}
                          {user?.profile?.user_id ? (
                            <i
                              className="fa fa-check-circle"
                              style={{ color: 'green', fontSize: 20, marginRight: 4 }}
                            ></i>
                          ) : (
                              <Link to="/account">{t('competition.FillInNow')}</Link>
                            )}
                        </p>

                        <p>
                          2. {t('competition.VerificationApproved')}
                          {user?.verifications[0]?.status === 1 ? (
                            <i
                              className="fa fa-check-circle"
                              style={{ color: 'green', fontSize: 20, marginRight: 4 }}
                            ></i>
                          ) : user?.verifications[0]?.status === 0 ? (
                            <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 25 }}></i>
                          ) : (
                                user?.verifications[0]?.status !== 1 && (
                                  <Link to="/account#verification">Go verify</Link>
                                )
                              )}
                        </p>

                        <p>
                          3. {t('competition.CreateAtLeastOneAccount')}{' '}
                          {competitionAccounts?.length > 0 ? (
                            <i
                              className="fa fa-check-circle"
                              style={{ color: 'green', fontSize: 20, marginRight: 4 }}
                            ></i>
                          ) : (
                              <Link to="/competition_account">{t('competition.OpenAccount')}</Link>
                            )}
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                ) : (
                  ''
                )}
            </Col>
          </Row>
        )}

      {selectedSetting !== null && selectedAccount !== null && (
        <Modal isOpen={showModal} backdrop={true} centered={true}>
          <ModalHeader toggle={toggleModal}>{settings[selectedSetting].title}</ModalHeader>
          <ModalBody>{settings[selectedSetting].cta}</ModalBody>
        </Modal>
      )}

      <Modal isOpen={resetModal} centered={true}>
        <ModalHeader toggle={restToggleModal}>{t('competition.ResetAccount')}</ModalHeader>
        <ModalBody>
          <Form
            className="theme-form verify-auth-form"
            id="student-event-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormGroup>
              {/* Check condition for reset button, hide button when is submmiting  */}

              {myCompetitionResult?.my_participation?.switch_account_status === null ||
                myCompetitionResult?.my_participation?.switch_account_status === 1 ||
                myCompetitionResult?.my_participation?.switch_account_status === 2 ? (
                  <>
                    <Label className="m-t-20">{t('competition.ResetAccount')}</Label>
                    <Input
                      className="form-control"
                      type="select"
                      name="account_id"
                      innerRef={register({ required: true })}
                      onChange={(e) => {
                        checkIsEligible(e.target.value)
                      }}
                    >
                      <option value="" min_deposits="">
                        {t('user.Select')}
                      </option>

                      {competitionAccounts?.map((account) => {
                        //   console.log('account', account)
                        return <option value={account?.id}>{account?.account_login}</option>
                      })}
                    </Input>
                    {accountIsEligible === false ? (
                      <p style={{ color: 'red' }}>{t('competition.MinBalanceMessage')}</p>
                    ) : accountIsEligible === true ? (
                      <p style={{ color: 'green' }}>{t('competition.MinBalanceMessage')}</p>
                    ) : (
                          ''
                        )}
                    <div className="m-t-20">
                      <span>
                        <strong>{t('competition.TakeNote')}</strong>
                        <br />
                        <p>1. {t('competition.CloseAllTradesBeforeReset')}</p>
                        <p>2. {t('competition.RightsWillBeDisabled')} </p>
                        <p>3. {t('competition.WillProcessRequest')}</p>
                        <p>4. {t('competition.NewStatisticsCalculation')}</p>
                      </span>
                    </div>

                    <div style={{ display: 'flex', marginLeft: '20px', marginTop: '20px' }}>
                      <Input type="checkbox" required />
                      <p>{t('competition.UnderstandAndAgree')}</p>
                    </div>

                    <div
                      style={{
                        float: 'right',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={
                          loading || accountIsEligible === false || accountIsEligible === null
                        }
                      >
                        {/* < Button style={{ marginTop: '10px' }} color="primary" disabled={loading}> */}
                        {t('competition.Reset')}
                      </Button>

                      {loading ? (
                        <div
                          style={{
                            marginLeft: '10px'
                          }}
                        >
                          <Spinner size="md" style={{ color: '#f9b600' }} />
                        </div>
                      ) : (
                          ''
                        )}
                    </div>
                  </>
                ) : (
                  <p>{t('competition.CannotResetAccount')}</p>
                )}
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Event
