import React from "react";

import Logo from "../../components/header/logo";
import SidebarTrigger from "../../components/header/sidebar_trigger";

const Leftbar = (props) => {
  return (
    <>
      <div className="main-header-left">
        <Logo />
        <SidebarTrigger />
      </div>
    </>
  );
};

export default Leftbar;
