import React, { useState } from 'react'
import { Row, Col, CardBody, Button, Tooltip } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { HelpCircle } from 'react-feather'

import ReturnIcon from './return.svg'
import WalletIcon from './wallet.svg'

import './wallet.scss'

const ResponsiveButton = styled(Button)`
  color: #f9b600!important;
  background-color: white!important;
  border-color: #f9b600 !important;

  &:hover {
    color: white!important;
    background-color: #f9b600!important;
  }
`

const DashboardWallet = () => {
  const { t } = useTranslation()
  let history = useHistory()
  const dispatch = useDispatch()
  const wallets = useSelector((state) => state.wallet.wallets)

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const [withDrawTooltipOpen, setWithDrawTooltipOpen] = useState(false);

  const withDrawToggle = () => setWithDrawTooltipOpen(!withDrawTooltipOpen);

  const [TransferTooltipOpen, setTransferTooltipOpen] = useState(false);

  const TransferToggle = () => setTransferTooltipOpen(!TransferTooltipOpen);

  return (

    wallets.map((wallet) => {
      return (

        <>
          <Row style={{
            padding: '15px 20px 15px 35px',
            height: '285px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginBottom: '20px',
            marginRight: '0px',
            marginLeft: '0px',
            backgroundColor: '#103c5a',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
            borderRadius: '5px',
            color:'white'
          }}>
            <Row style={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
              <div style={{ display: 'flex' }}>
                <h5 style={{ marginBottom: '0', fontWeight: 'normal', marginRight: '5px' }}>{t('dashboard.WalletBalance')}</h5>

              </div>

              <img
                id="withdrawIcon"
                style={{ width: '20px', marginRight: '15px', cursor: 'pointer' }}
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/financial#withdrawal`)
                }}
                src={ReturnIcon}
                alt='Withdraw Icon'
              />
              <Tooltip placement="right" isOpen={withDrawTooltipOpen} target="withdrawIcon" toggle={withDrawToggle}>
                Withdraw from MT5 <br />or Wallet
              </Tooltip>

            </Row>

            <Row style={{ margin: '20px 0px 0px -15px', justifyContent: 'space-between', alignItems: 'center' }}>

              <div>
                <img style={{ width: '70px' }} src={WalletIcon} alt='wallet balance' />
              </div>
              <h1 style={{ marginBottom: '0px', fontWeight: '300' }}>{currencyFormatter.format(wallet?.balance)}</h1>


            </Row>

            <Row style={{ justifyContent: 'flex-end' }}>

              <Button
                color="primary"
                style={{ fontSize: '14px', padding: '0.375rem 31px', boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)", marginRight:'10px' }}
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/financial#deposit`)
                }}
              >
                {t('dashboard.deposit')}
              </Button>


              <Button
                id="TransferIcon"
                color="primary"
                style={{ fontSize: '14px', marginRight: '15px', boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/financial#transfer`)
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {t('dashboard.Transfer')}
                  {/* <HelpCircle id='transferIcon' style={{ width: '20px', marginLeft: '5px', cursor: 'pointer' }} /> */}
                </div>
              </Button>

              <Tooltip placement="right" isOpen={TransferTooltipOpen} target="TransferIcon" toggle={TransferToggle}>
                Transfer to MT5 or Wallet
              </Tooltip>

            </Row>
          </Row>
        </>
      )
    })


  )
}

export default DashboardWallet
