import React from 'react'
import { Media } from 'reactstrap'
import moment from 'moment'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import IdentityUpload from './identity_upload'
import { useTranslation } from 'react-i18next'

const IdentityTable = ({ reupload }) => {
  const { t } = useTranslation()

  const translateStatus = (status) => {
    switch (status) {
      case 0:
        return t("verifyAuth.Pending")
      case 1:
        return t("verifyAuth.Verified")
      case 2:
        return t("verifyAuth.Failed")
      case 3:
        return t("verifyAuth.Rejected")
      default:
        return '-'
    }
  }

  const verification = useSelector((state) => _.last(state.user.verifications))
  if (verification == null || reupload) {
    return <IdentityUpload />
  }

  return (
    <>
      <div className="blog-box blog-grid text-center">
        <a href={verification.id_front} target="_blank" rel="noopener noreferrer">
          <Media className="img-fluid top-radius-blog" src={verification.id_front} alt="" />
        </a>
        <div className="blog-details-main">
          <ul className="blog-social">
            <li className="digits">
              {moment(verification.created_at).format('yyyy-MM-DD hh:mmA')}
            </li>
            <li className="digits" style={{ fontWeight: 'bold' }}>
              {translateStatus(verification.status)}
            </li>
            <li className="digits">{t("verifyAuth.Front")}</li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="blog-box blog-grid text-center">
        <a href={verification.id_back} target="_blank" rel="noopener noreferrer">
          <Media className="img-fluid top-radius-blog" src={verification.id_back} alt="" />
        </a>
        <div className="blog-details-main">
          <ul className="blog-social">
            <li className="digits">
              {moment(verification.created_at).format('yyyy-MM-DD hh:mmA')}
            </li>
            <li className="digits" style={{ fontWeight: 'bold' }}>
              {translateStatus(verification.status)}
            </li>
            <li className="digits">{t("verifyAuth.Back")}</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default IdentityTable
