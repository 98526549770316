import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import NewProfile from "./details/new_profile"
import { Card, CardBody, Spinner } from "reactstrap"
import { useEffect } from "react"
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom'

const UserDetail = () => {
  const profile = useSelector((state) => state.user.profile)
  const user = useSelector((state) => state.user)
  // new
  useEffect(() => {
    // if (user?.user?.first_deposit_status === null ||
    //   user?.user?.first_deposit_status === 2) {
    //   window.location.href = '/welcomeBonus'
    // }

    if (user?.user?.is_profile_completed || user?.profile?.user_id) {
      window.location.href = '/dashboard'
    }

  }
    , [])

  if (profile != null && _.isEmpty(profile)) {
    return <NewProfile />
  }

  // loading
  return (
    <Card>
      <CardBody style={{ textAlign: "center" }}>
        <Spinner />
      </CardBody>
    </Card>
  )
}

export default UserDetail
