import React, { useState, useRef, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import EventService from '../../../../network/services/event'
import { useTranslation } from 'react-i18next'

const BonusTable = () => {
  const { t } = useTranslation()
  const columns = [
    {
      name: t('event.Rank'),
      selector: (row, index) => leaderboardResult?.indexOf(row) + 1,
      sortable: true
    },
    {
      name: t('event.Name'),
      selector: (row) => row.participant.name,
      sortable: true,
      format: (row) => {
        return row.participant?.name
      }
    },
    // {
    //   name: t('event.University'),
    //   selector: (row) => row.participant.university,
    //   sortable: true,
    //   width: '250px',
    //   format: (row) => {
    //     return row.participant?.university
    //   }
    // },
    {
      name: t('event.PortfolioValue'),
      selector: (row) => row.portfolioValue,
      sortable: true,
      format: (row) => {
        return row.portfolioValue + 'USD'
      }
    },
    // {
    //   name: t('event.Growth'),
    //   selector: 'returnRate',
    //   sortable: true,
    //   format: (row) => {
    //     return row.returnRate + '%'
    //   }
    // },
    {
      name: t('event.Profit'),
      selector: 'overall_profit',
      sortable: true,
      format: (row) => {
        return row.overall_profit.toFixed(2) + 'USD'
      }
    }
  ]

  const [leaderboardResult, setLeaderboardResult] = useState([])

  // const eventLeaderboardResponse = await EventService.getLeaderBoardResult({
  //     competition_id: competitionId
  // })
  // dispatch({
  //     type: 'SET_LEADERBOARD_STATUS',
  //     leaderBoard: eventLeaderboardResponse
  // })

  const fetchLeaderboard = async () => {
    // Hardcode competition id
    const leaderResponse = await EventService.getLeaderBoardResult({
      competition_id: 5
    })
    setLeaderboardResult(leaderResponse.leaderboard.filter((item) => item.overall_profit > 0))
  }

  useEffect(() => {
    // Hardcode competition id
    fetchLeaderboard()
  }, [])

  // const leaderboard = useSelector((state) => state?.event?.leaderBoard?.leaderboard)
  // console.log('new leaderboard', leaderboardResult)
  // console.log('leaderboard', leaderboard)
  // const [isBusy, setIsBusy] = useState(false)
  // const [total, setTotal] = useState(0)
  // const [data, setData] = useState(leaderboardResult)
  // const [sidebaron, setSidebaron] = useState(true)
  // const [filters, setFilters] = useState(null)
  // const [searchKeyword, setSearchKeyword] = useState('')
  const wrapperRef = useRef()

  // const handlePageChange = async (page) => {
  //   setIsBusy(true)
  //   const result = await UserService.getBonuses({ page: page, filters: filters })
  //   setData(result.bonuses?.data ?? [])
  //   setTotal(result.bonuses?.meta?.total ?? 0)
  //   setIsBusy(false)
  // }

  // const handleFilter = async () => {
  //   setIsBusy(true)
  //   const result = await UserService.getBonuses({
  //     filters: {
  //       ...filters
  //     }
  //   })
  //   setData(result.bonuses?.data ?? [])
  //   setTotal(result.bonuses?.meta?.total ?? 0)
  //   setIsBusy(false)
  // }

  // const onClickFilter = () => {
  //   if (sidebaron) {
  //     setSidebaron(false)
  //     wrapperRef.current.classList.add('sidebaron')
  //   } else {
  //     setSidebaron(true)
  //     wrapperRef.current.classList.remove('sidebaron')
  //   }
  // }

  // const handleSearchKeyword = (keyword) => {
  //   setSearchKeyword(keyword)
  //   setFilters({
  //     ...filters,
  //     account_login: keyword
  //   })
  // }

  // useEffect(() => {
  //   handleFilter()
  //   // eslint-disable-next-line
  // }, [filters])

  return (
    <>
      <div className="product-wrapper" ref={wrapperRef}>
        {/* <Row>

        <Col xl="3">
          <div className="product-sidebar">
            <div className="filter-section">
              <Card className="m-0">
                <CardHeader>
                  <h6 className="mb-0 f-w-600">
                    {t("promotion.Filters")}
                    <span className="pull-right">
                      <i className="fa fa-chevron-down toggle-data" onClick={onClickFilter}></i>
                    </span>
                  </h6>
                </CardHeader>
                <div className="left-filter">
                  <div style={{ padding: 16 }} />
                  <BonusFilters handleFilter={setFilters} filters={filters} />
                </div>
              </Card>
            </div>
          </div>
        </Col>
        <Col xl="9" sm="12">
          <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Input
              className="form-control"
              type="text"
              placeholder={t("promotion.SearchAccount")}
              defaultValue={searchKeyword}
              onChange={(e) => handleSearchKeyword(e.target.value)}
            />
          </div>
        </Col>
      </Row> */}

        <div id="leaderboardTable" className="product-wrapper-grid" style={{ marginTop: 16 }}>
          <DataTable
            noHeader
            data={leaderboardResult.filter((account) => account?.overall_profit > 0)}
            columns={columns}
            striped={true}
            center={true}
            subHeader
            subHeaderComponent={
              <p style={{ textAlign: 'left', height: 'auto', marginBottom: '4px' }}>
                <p style={{ marginBottom: '0px', fontWeight: 'bold' }}>
                  {t('event.ReminderLabel')}{' '}
                </p>
                <p style={{ marginBottom: '3px' }}>
                  {t('event.Reminder1', {
                    criteria:
                      sessionStorage.getItem('language') == 'zh'
                        ? '正利润(大于0美金)'
                        : 'positive profit(over 0USD)'
                  })}
                </p>
               
                <p style={{ marginBottom: '3px' }}>
                  {t('event.Reminder2')}
                </p>
                
                {/* <p style={{ marginBottom: '3px' }}>
                  {t('event.Reminder2Part1')}{' '}
                  <a href="https://www.facebook.com/investmentrealityshow" target="_blank">
                    谁是王者
                  </a>{' '}
                  {t('event.Reminder2Part2', { date: '1/8/2021' })}
                </p> */}
              </p>
            }
            subHeaderAlign="left"
            // pagination
            // paginationComponentOptions={{ noRowsPerPage: true }}
            // paginationServer
            // paginationTotalRows={total}
            // onChangePage={handlePageChange}
            // progressPending={isBusy}
          />
        </div>
      </div>
    </>
  )
}

export default BonusTable
