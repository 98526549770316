import client from "../request"

const create = (data) => {
  const formData = new FormData()
  formData.append("file", data)

  return client.post("/files", formData)
}

export default {
  create,
}
