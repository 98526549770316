import React from "react";
import { Card, CardBody, Button } from "reactstrap";
import HistoryTable from "./table";
import { useTranslation } from 'react-i18next'

const WalletHistory = ({ setOption }) => {
  const { t } = useTranslation()
  return (
    <>
      <Card>
        <CardBody>
          <HistoryTable />
        </CardBody>
      </Card>

      <Button
        color="primary"
        onClick={() => {
          setOption(null);
        }}
      >
        {t("financial.back")}
      </Button>
    </>
  );
};

export default WalletHistory;
