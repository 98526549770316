import client from "../request"

const getAll = () => {
  return client.get("/legals")
}

const get = (id) => {
  return client.get(`/legals/${id}`)
}

export default {
  getAll,
  get,
}
