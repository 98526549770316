import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

import Breadcrumb from '../../layout/breadcrumb'
import LandingPage from './landing_page'
import Statistic from './statistic'
import Ranking from './ranking'

import AccountService from '../../network/services/account'
import EventService from '../../network/services/event'

import { useTranslation } from 'react-i18next'

const Partner = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()


  const [myEventDetails, setMyEventDetails] = useState(null)
  const [qualifyAccounts, setQualifyAccounts] = useState()

  const tabs = {
    beneficial: t("referralFriend.HowItWork"),
    carnival: 'Carnival',
    ranking: 'Ranking'
  }

  const init = async () => {
    const accountResponse = await AccountService.getAll()
    const eventResponse = await EventService.carnivalDetails(1)

    setQualifyAccounts(
      accountResponse?.accounts?.filter((acc) => {
        return (acc?.account_type === 0 || acc?.account_type === 2) && (acc?.package_id !== 7 && acc?.package_id !== 9)
      })
    )

    setMyEventDetails(eventResponse?.event_participation)

  }


  useEffect(() => {
    init()
  }, [])

  
  const [activeTab, setActiveTab] = useState(myEventDetails !== null ? 'Carnival' : 'beneficial')

  return (
    <>
      <Breadcrumb title='Carnival' parent='TradeHall Carnival 2022' />
      <Container>
        <Col lg='12' className="d-none d-md-block d-lg-block d-xl-block" style={{ padding: '0px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', borderRadius: '15px' }}>
          <Card>
            <Row>
              <Col>
                <Nav tabs className="border-tab" style={{ marginBottom: '0px' }}>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'beneficial' ? 'active' : ''}
                      onClick={() => setActiveTab('beneficial')}
                    >
                      <i className="fa fa-building"></i>
                      {tabs.beneficial}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'Carnival' ? 'active' : ''}
                      onClick={() => setActiveTab('Carnival')}
                    >
                      <i className="fa fa-flag"></i>
                      {tabs.carnival}
                    </NavLink>
                  </NavItem>


                  <NavItem>
                    <NavLink
                      className={activeTab === 'Ranking' ? 'active' : ''}
                      onClick={() => setActiveTab('Ranking')}
                    >
                      <i className="fa fa-bar-chart"></i>
                      {tabs.ranking}
                    </NavLink>
                  </NavItem>


                </Nav>
              </Col>
            </Row>
          </Card>
        </Col>

        <Row>
          <Col sm="12" lg="4" xl="3" className="project-list d-md-none d-lg-none d-xl-none">
            <Card>
              <Row>
                <Col>
                  <Nav tabs className="border-tab">
                    <NavItem>
                      <NavLink
                        className={activeTab === 'beneficial' ? 'active' : ''}
                        onClick={() => setActiveTab('beneficial')}
                      >
                        <i className="fa fa-building"></i>
                        {tabs.beneficial}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'Carnival' ? 'active' : ''}
                        onClick={() => setActiveTab('Carnival')}
                      >
                        <i className="fa fa-flag"></i>
                        {tabs.carnival}
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={activeTab === 'Ranking' ? 'active' : ''}
                        onClick={() => setActiveTab('Ranking')}
                      >
                        <i class="fa fa-bar-chart"></i>
                        {tabs.ranking}
                      </NavLink>
                    </NavItem>

                  </Nav>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col sm="12" lg="12" xl="12">
            <TabContent activeTab={activeTab}>

              <TabPane tabId="beneficial">
                <LandingPage />
              </TabPane>
              <TabPane tabId="Carnival">
                <Statistic />
              </TabPane>
              <TabPane tabId="Ranking">
                <Ranking myEventDetails={myEventDetails}/>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Partner
