import React, { Fragment, useState, useEffect } from 'react'
import { Col, Card, CardBody, Button, Form, Input, FormGroup, Label, Spinner } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
// import ImageUploader from 'react-images-upload'
// import imageCompression from 'browser-image-compression'
// import SocialProfileService from '../../../network/services/social'
import FilesService from '../../../../network/services/files'
import EventService from '../../../../network/services/event'
// import AccountService from "../../../network/services/account"
import _ from 'lodash'
import styled from 'styled-components'

import { useHistory, Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

const MobileGap = styled.div`
  padding: 0;

  @media (max-width: 575px) {
    padding: 10px;
  }
`

const Step1 = () => {
  const { t, i18n } = useTranslation()

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const dispatch = useDispatch()
  const competitionAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter((e) => e.account_type === 3)
    }

    return []
  })

  const campusCompetition = useSelector((state) =>
    state.event?.competitions?.competitions?.filter(
      (item) => item.name === 'Tradehall Campus CFD Trading Contest Malaysia 2021'
    )
  )

  const [name, setName] = useState('')
  const [avatar, setAvatar] = useState([])

  const [otherOption, setOtherOption] = useState('')
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [accountIsEligible, setAccountIsEligible] = useState(null)

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toolTiptoggle = () => setTooltipOpen(!tooltipOpen)

  const { register, handleSubmit } = useForm({})

  const [loading, setLoading] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const history = useHistory()
  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  // const init = async () => {
  //   // live accounts
  //   const result = await AccountService.getAll({ filters: { account_type: 0 } })
  //   setAccounts(result.accounts)
  // }

  // useEffect(() => {
  //   init()
  //   // eslint-disable-next-line
  // }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    setIsBusy(true)
  
    try {
      // Competition ID for Road to Champion
      const competitionId = 2
      const result = await EventService.submitCompetitionLive({
        name: data['name'],
        competition_id: competitionId,
        account_id: data['account_id'],
        is_attend_live: data['is_attend_live']
      })

      setLoading(false)
      if (result) {
        SweetAlert.fire({
          title: t('competition.RegisterSuccessful'),
          icon: 'success'
        }).then(() => {
          window.location.reload()
        })
        setIsBusy(false)
      }
    } catch (error) {
      setIsBusy(false)
      setLoading(false)
      console.log(error)

      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }

  const checkIsEligible = (id) => {
    const selectedAccount = competitionAccounts?.filter((account) => account?.id === parseInt(id))
    
    if (parseFloat(selectedAccount[0]?.mt5_trade?.Balance) >= 100) {
      
      setAccountIsEligible(true)
    } else {
      
      setAccountIsEligible(false)
    }
  }

  return (
    <>
      <Form
        className="theme-form verify-auth-form"
        id="student-event-form"
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
      >
        <Col lg="6" id="term-col">
          <Card
            style={{
              height: '500px',
              backgroundColor: '#103c5a',
              color: 'white',
              borderRadius: '0px'
            }}
          >
            <CardBody
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <div>
                <h4>{t('competition.TNC')}</h4>
                <p className="m-t-20">1. {t('competition.RegistrationRunsPeriod')}</p>
                <p>2. {t('competition.MinBalance')}</p>
                <p>3. {t('competition.CompleteLegalAge')}</p>
                <p>4. {t('competition.DisableUntilContestStarts')}</p>
                <p>5. {t('competition.EANotAllowed')}</p>
                <Button color="primary" style={{ color: 'white' }}>
                  <a
                    style={{ color: 'white' }}
                    href="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/T%26C+Road+to+Champion+Contest.pdf"
                    target="_blank"
                  >
                    {t('competition.ViewDetails')}
                  </a>
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col lg="6" className="padding-verify-left" id="form-col">
          <Card style={{ borderRadius: '0px', height: '500px' }}>
            <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
              <h4>Road To Champion {t('competition.Contest')}</h4>

              <FormGroup>
                <Label className="m-t-20">{t('competition.SelectContestAccount')}</Label>
                <Input
                  className="form-control"
                  type="select"
                  name="account_id"
                  innerRef={register({ required: true })}
                  onChange={(e) => {
                    checkIsEligible(e.target.value)
                  }}
                >
                  <option value="" min_deposits="">
                    {t('user.Select')}
                  </option>

                  {competitionAccounts?.map((account) => {
                    
                    return <option value={account?.id}>{account?.account_login}</option>
                  })}
                </Input>
                {accountIsEligible === false ? (
                  <p style={{ color: 'red' }}>
                    {t('competition.MinBalanceMessage')}{' '}
                    <Link to="/financial#deposit">{t('competition.DepositNow')}</Link>
                  </p>
                ) : accountIsEligible === true ? (
                  <p style={{ color: 'green' }}>{t('competition.MinBalanceMessage')}</p>
                ) : (
                      ''
                    )}
              </FormGroup>

              <FormGroup>
                <Label className="m-t-10">{t('event.DisplayName')} </Label>
                <Input
                  innerRef={register({ required: true })}
                  className="form-control input-txt-bx"
                  type="text"
                  name="name"
                  placeholder={t('event.EnterDisplayName')}
                />
              </FormGroup>

              <FormGroup>
                <Label className="m-t-20">{t('competition.JoinFbLive')}</Label>
                <Input
                  className="form-control"
                  type="select"
                  name="is_attend_live"
                  innerRef={register({ required: true })}
                >
                  <option value="">{t('user.Select')}</option>

                  <option value={true}>{t('competition.JoinFbLiveYes')}</option>

                  <option value={false}>{t('competition.JoinFbLiveNo')}</option>
                </Input>
              </FormGroup>

              <div style={{ display: 'flex', marginLeft: '20px', marginTop: '20px' }}>
                <Input type="checkbox" required />
                <p>
                  {t('event.ByCheckingThis')}
                  <span style={{ cursor: 'pointer', color: '#f9b600' }}>
                    <a
                      href="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/T%26C+Road+to+Champion+Contest.pdf"
                      target="_blank"
                    >
                      {' '}
                      {t('event.TermsAndConditions')}
                    </a>
                  </span>
                </p>
              </div>
            </CardBody>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="m-b-20 m-r-20 ">
              <Button
                color="primary"
                disabled={loading || accountIsEligible === false || accountIsEligible === null}
              >
                {/* <Button color="primary" disabled={loading}> */}
                {t('competition.SubmitRegistration')}
              </Button>
              {loading ? (
                <div
                  style={{
                    marginLeft: '10px'
                  }}
                >
                  <Spinner size="lg" color="warning" />
                </div>
              ) : (
                  ''
                )}
            </div>
          </Card>
        </Col>
      </Form>
    </>
  )
}

export default Step1
