import React from 'react'
import { Card, Button, CardHeader, CardBody, Spinner, Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const Referral = () => {
  const { t } = useTranslation()
  const partners = useSelector((state) => state.user.partners)

  return (
    <>
      <Card>
        <CardHeader>
          <h5>{t("partner.referralLink")}</h5>
        </CardHeader>
        <CardBody>
          {partners != null ? (
            partners?.length > 0 ? (
              partners.map((partner) => {
                return (
                  <Card key={partner.id}>
                    <CardHeader className="bg-secondary" style={{ padding: 20 }}>
                <h5 className="text-center">{t("partner.IB")}-{partner?.ib_code}</h5>
                    </CardHeader>
                    <CardBody style={{ padding: 16, textAlign: 'center' }}>
                      <p>
                        {`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${partner?.referral_code}`}
                      </p>
                      <Row style={{ marginTop: 12 }}>
                        <Col className="text-right">
                          <CopyToClipboard
                            text={`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${partner?.referral_code}`}
                          >
                            <Button
                              color="primary"
                              onClick={() => {
                                toast.success(`${t("partner.linkCopiedToClipboard")}`, {
                                  position: toast.POSITION.BOTTOM_RIGHT
                                })
                              }}
                            >
                              {t("partner.copyLink")}
                            </Button>
                          </CopyToClipboard>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                )
              })
            ) : (
              <p>No record found</p>
            )
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Spinner color="warning"/>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default Referral
