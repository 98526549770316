import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ResponsiveLine } from '@nivo/line'
import {
    Row,
    Col,
    Card,
    Button,
    Input,
    CardFooter,
} from 'reactstrap'

import { Link, NavLink } from 'react-router-dom'


import PammService from '../../../network/services/pammManager'
import UserService from '../../../network/services/user'
import AccountService from '../../../network/services/account'


import Pagination from 'react-js-pagination'

import { useTranslation } from 'react-i18next'

import './pammManager.scss'

import "intro.js/introjs.css";
import { Steps } from 'intro.js-react';

const PammManager = (props) => {

    const { t } = useTranslation()
    const [pammManager, setPammManager] = useState([])
    const dispatch = useDispatch()


    // Filter manager list
    const [searchTerm, setSearchTerm] = useState("")

    const [searchResults, setSearchResults] = useState([])
    const [teamId, setTeamId] = useState(null)
    const [partnerTeamIds, setPartnerTeamIds] = useState([])
    const handleChange = event => {
        setSearchTerm(event.target.value)
    };


    // Pagination
    const todosPerPage = 9;
    const [activePage, setCurrentPage] = useState(1);

    const indexOfLastTodo = activePage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = searchResults.slice(indexOfFirstTodo, indexOfLastTodo);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    };


    const init = async () => {
        try {
            const result = await PammService.getAll()
            const userInfo = await UserService.getMyself()
            const accountResponse = await AccountService.getAll()

            dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })

            setTeamId(userInfo.user.referred_by?.parent_user?.partner?.team_id ?? null)

            if (userInfo.partners.length > 0) {
                const partnerTeamArray = []
                await userInfo.partners.forEach(item => partnerTeamArray.push(item.team_id))
                setPartnerTeamIds(partnerTeamArray)
            }

            setPammManager(result.pamms.data ?? {})
            setSearchResults(result.pamms.data ?? {})


        } catch (error) {
            setPammManager({})

        }


    }

    const vePackageAccounts = useSelector((state) => {
        if (state.account.accounts?.length > 0) {
            return state.account.accounts.filter((e) => (e.package_id === 4 || e.package_id === 5 || e.package_id === 6))
        } else if (state.account.accounts?.length === 0) {
            return []
        } else {
            return null
        }
    })


    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        const results = pammManager.filter(user =>
            user.name.toLowerCase().includes(searchTerm)
        );
        setSearchResults(results);
    }, [searchTerm]);

    let totalGrowth = 0
    let allGrowthData = []

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    // const liveAccounts = useSelector((state) => {
    //     if (state.account.accounts?.length > 0) {
    //         return state.account.accounts.filter((e) => (e.account_type === 0 || e.account_type === 2))
    //     } else if (state.account.accounts?.length === 0) {
    //         return []
    //     } else {
    //         return null
    //     }
    // })

    // console.log('liveAccounts', liveAccounts)

    const followButton = (user) => {
        const pammManagerTeamsIds = user.pammManagerTeams.map(item => item.team_id)
        if (user.pammManagerTeams.length > 0) {

            if (vePackageAccounts.length > 0) {
                return <Link to={`${process.env.PUBLIC_URL}/pamm/${user.id}/subscribe`}><Button style={{ padding: '5px 10px' }} color="primary">{t("pamm.follow")}</Button></Link>
            }

            if (pammManagerTeamsIds.includes(teamId) || partnerTeamIds.some(partnerTeamId => pammManagerTeamsIds.includes(partnerTeamId))) {
                return <Link to={`${process.env.PUBLIC_URL}/pamm/${user.id}/subscribe`}><Button disabled={user.is_follow_disabled} style={{ padding: '5px 10px' }} color="primary">{t("pamm.follow")}</Button></Link>
            }
            if (user.is_follow_disabled) {
                return <Link to={`${process.env.PUBLIC_URL}/pamm/${user.id}/subscribe`}><Button disabled={user.is_follow_disabled} style={{ padding: '5px 10px' }} color="primary">{t("pamm.follow")}</Button></Link>
            }



            if (user?.email) {
                return <a href={`mailto:${user?.email}?subject=Request to subscribe PAMM ${user.name}`} style={{ padding: '5px 10px', borderRadius: '5px' }} class="btn-primary">{t("pamm.contactPamm")}</a>
            } else {
                return <a href={`mailto:support@tradehall.co?subject=Request to subscribe PAMM ${user.name}`} style={{ padding: '5px 10px', borderRadius: '5px' }} class="btn-primary">{t("pamm.contactAdmin")}</a>
            }
        }
        return <Link to={`${process.env.PUBLIC_URL}/pamm/${user.id}/subscribe`}><Button disabled={user.is_follow_disabled} style={{ padding: '5px 10px' }} color="primary">{t("pamm.follow")}</Button></Link>
    }

    const [stepsEnabled, setStepsEnabled] = useState(true)
    const steps = [
        {
            element: '#total-fund-size',
            intro: t('intro.pammManager.step1'),
        },
        {
            element: '#line-graph',
            intro: t('intro.pammManager.step2'),
        },
        {
            element: '#total-followers',
            intro: t('intro.pammManager.step3'),
        },
        {
            element: '#monthly-growth',
            intro: t('intro.pammManager.step4'),
        },
        {
            element: '#total-growth',
            intro: t('intro.pammManager.step5'),
        },
        {
            element: '#min-subscribe',
            intro: t('intro.pammManager.step6'),
        }


    ]

    const getLatestPerformanceIndex = (performances) => {
        const performancesYears = performances.map(item => item.year)

        const latestPerformanceYear = Math.max(...performancesYears)
        const latestYearPerformances = performances.filter(item => item.year == latestPerformanceYear)

        const latestYearPerformanceMonths = latestYearPerformances.map(item => item.month)
        const latestPerformanceMonth = Math.max(...latestYearPerformanceMonths)

        const latestPerformance = latestYearPerformances.find(item => item.month == latestPerformanceMonth)
        const latestPerformanceIndex = performances.findIndex(item => item.id == latestPerformance?.id)

        return latestPerformanceIndex ?? 0
    }

    const PammList = currentTodos.map(user => {

        allGrowthData = []
        totalGrowth = 0



        for (let [key, value] of Object.entries(user.performances)) {
            allGrowthData.push({
                "x": value.id,
                "y": totalGrowth + value.monthly_growth
            })
            totalGrowth += value.monthly_growth

        }

        if (allGrowthData.length < 1) {
            allGrowthData.push({
                "x": "0",
                "y": 0
            },
                {
                    "x": "1",
                    "y": 0
                })
        } else if (allGrowthData.length === 1) {
            allGrowthData.unshift({
                "x": "0",
                "y": 0
            })
        }

        return (

            <Col xl='4' lg='6' key={user.id} className="result">
                <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', backgroundColor: 'white', color: 'black' }}>
                    <NavLink to={`pamm/${user.id}/statistic`}>
                        <div style={{ cursor: 'pointer' }}>



                            <Row className="mt-3" style={{ color: 'black', marginLeft: '20px', marginBottom: '10px', alignItems: 'center' }}>
                                <div >
                                    <img width="85" height="80px" alt='pamm avatar' src={user.avatar !== "" ? user.avatar : `https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/User+photo-05.jpg`} />
                                </div>

                                <div className='m-l-20'>
                                    <h6 style={{ margin: '0px' }} >{user.name}</h6>
                                    <p id='total-fund-size' style={{ margin: '0px', marginBottom: "10px" }} >{t("pamm.totalFundSize")}: {user?.statistics?.fund_size}++</p>
                                    {followButton(user)}
                                </div>

                                {/* <Col style={{ color: '#fbc54d' }}>{t("pamm.totalFundSize")}<br></br> <p style={{ color: '#103c5a' }}>{user?.statistics?.fund_size}++</p></Col> */}
                            </Row>

                            {/* <Row className="mt-3" style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                <Col style={{ color: '#fbc54d' }}>{t("pamm.minimum")}<p style={{ color: '#103c5a' }}>{user.min_amount} USD</p></Col>
                                <Col style={{ color: '#fbc54d' }}>{t("pamm.totalFundSize")}<br></br> <p style={{ color: '#103c5a' }}>{user?.statistics?.fund_size}++</p></Col>
                            </Row> */}
                            <div id='line-graph' style={{ height: '200px', width: '100%' }}>
                                {/* Map each graph */}
                                <ResponsiveLine
                                    colors='#f9b600'
                                    data={[{
                                        "id": "profit",
                                        "color": "hsl(294, 70%, 50%)",
                                        "data": allGrowthData

                                    }]}

                                    curve="natural"
                                    type="linear"
                                    enableSlices='x'
                                    defs={[
                                        {
                                            id: 'gradientC',
                                            type: 'linearGradient',
                                            colors: [
                                                { offset: 0, color: '#f7cc51' },
                                                { offset: 20, color: 'inherit' },
                                                { offset: 80, color: '#fff' },
                                                { offset: 100, color: '#fff' },
                                            ],
                                        }
                                    ]}

                                    fill={[
                                        { match: '*', id: 'gradientC' }
                                    ]}

                                    margin={{ top: 30, bottom: 20, left: 0, right: 0 }}
                                    xScale={{ type: 'point' }}
                                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                    yFormat=" >-.2f"
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={null}
                                    axisLeft={null}
                                    enableGridX={false}
                                    enableGridY={false}
                                    enablePoints={false}
                                    pointSize={10}
                                    pointColor={{ theme: 'background' }}
                                    pointBorderWidth={2}
                                    pointBorderColor={{ from: 'serieColor' }}
                                    pointLabelYOffset={-12}
                                    enableArea={true}
                                    useMesh={true}
                                    legends={[]}
                                    isInteractive={true}
                                /></div>
                            {/* <div style={{ display: 'flex', alignItems: 'flex-end' }} className="mt-3">
                                <Col lg="3" >
                                    <img style={{ marginLeft: '20px', marginBottom: '10px', borderRadius: '5px' }} width="60px" height="55px" src={user.avatar !== "" ? user.avatar : `https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/User+photo-05.jpg`} />
                                </Col>
                                <Col lg="9" className="m-b-10">

                                    <h6 className="mt-1" style={{ color: '#fbc54d' }}>{user.name} <img className="mb-1 mobile-center" width="11px" height="12px" src={VerificationIcon} /></h6>
                                    {followButton(user)} 
                                </Col>
                            </div> */}
                        </div>
                    </NavLink>
                    <div className="m-t-20" style={{ display: 'flex', textAlign: "center" }}>
                        <Col id='total-followers' lg="4">
                            <p style={{ lineHeight: '1.3', letterSpacing: '0' }}><b>{user.totalFollowers}</b> <br></br><span style={{ fontSize: '12px' }}>{t("pamm.Followers")}</span></p>
                        </Col>

                        <Col id='monthly-growth' lg="4" style={{ padding: '0', display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                            {/* {console.log('user.performances', user.performances)} */}
                            <p style={{ lineHeight: '1.3', letterSpacing: '0' }}><b>{user?.performances[getLatestPerformanceIndex(user?.performances)]?.monthly_growth.toFixed(2) ?? '0.00'} %</b> <br></br><span style={{ fontSize: '12px' }}>{t("pamm.monthlyGrowth")}</span></p>
                        </Col>

                        <Col id='total-growth' lg="4">
                            <p style={{ lineHeight: '1.3', letterSpacing: '0' }}><b>{totalGrowth.toFixed(2)} %</b> <br></br><span style={{ fontSize: '12px' }}>{t("pamm.growth")}</span></p>
                        </Col>

                    </div>
                    <CardFooter style={{ padding: '20px', backgroundColor: 'white', color: 'black' }}>
                        <Row id='min-subscribe' style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <p style={{ marginBottom: '0px' }}>{t("pamm.minimum")}: {formatter.format(user.min_amount)}</p>
                            {/* {followButton(user)} */}
                        </Row>
                    </CardFooter>
                </Card>
                { localStorage.getItem('pamm-list-onboarding') === null &&
                    <Steps
                        enabled={stepsEnabled}
                        steps={steps}
                        initialStep={0}
                        onExit={() => {
                            localStorage.setItem('pamm-list-onboarding', true)
                            setStepsEnabled(false)
                        }}
                        options={
                            {
                                showProgress: true,
                                doneLabel: 'Understand',
                                exitOnOverlayClick: false
                            }
                        }
                    />
                }
            </Col>


        )

    })



    return (
        <>
            <Row style={{ margin: '0' }}>
                <Input
                    id='searchInput'
                    type="text"
                    placeholder={t("pamm.Search")}
                    value={searchTerm}
                    onChange={handleChange}
                    style={{ marginLeft: '15px', marginBottom: '20px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)' }}
                />
            </Row>
            <Row style={{ marginLeft: '0', marginRight: '0' }}>
                {PammList}
            </Row>
            <div className="pagination " style={{ justifyContent: 'center', fontSize: '16px' }}>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={9}
                    totalItemsCount={pammManager.length}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                    prevPageText={t("pamm.Prev")}
                    nextPageText={t("pamm.Next")}
                    itemClass="paginationLI"
                    activeClass="paginationActiveLi"
                    hideFirstLastPages
                />
            </div>

        </>
    )
}

export default PammManager