import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { Row, Col, Card, CardHeader, Input } from 'reactstrap'

import ReferralService from '../../../../network/services/referral'
import HistoryFilters from './filters'
import { useTranslation } from 'react-i18next'




const HistoryTable = () => {
  const { t } = useTranslation()
  const columns = [
    // {
    //   name: t("financial.ticket"),
    //   selector: 'id',
    //   sortable: true
    // },
    {
      name: t("referralFriend.Name"),
      selector: (row) => row?.child_user?.name,
      sortable: true,
      format: (row) => {
        return row?.child_user.name
      }
    },
    {
      name: t("financial.date"),
      selector: 'created_at',
      sortable: true,
      format: (row) => {
        return moment(row?.created_at).format('yyyy-MM-DD hh:mmA')
      }
    },

    {
      name: t("referralFriend.Status"),
      selector: (row) => row?.referralRewardHistory?.status,
      sortable: true,
      format: (row) => {
        switch (row?.referralRewardHistory?.status) {
          case 0:
            return t("referralFriend.Inactive")
          case 1:
            return (
              <i
                className="fa fa-check-circle"
                style={{ color: 'green', fontSize: 20, marginRight: 4 }}
              ></i>

            )
          case 2:
            return t("referralFriend.expired")

          default:
            return '-'
        }
      }
    },

    {
      name: 'Total Lot Size',
      selector: (row) => row?.referralRewardHistory?.traded_lot_size >= 2 ?
        <span style={{ color: 'green' }}>{row?.referralRewardHistory?.traded_lot_size} </span>
        : <span>{row?.referralRewardHistory?.traded_lot_size} / 2.00</span>,
      sortable: true
    },

    {
      name: t("referralFriend.MoneyEarn"),
      selector: (row) => row?.referralRewardHistory?.money_earned,
      sortable: true
    },

  ]

  const types = [
    {
      id: '',
      name: t("financial.all")
    },
    {
      id: 1,
      name: t("referralFriend.Active")
    },
    {
      id: 0,
      name: t("referralFriend.Inactive")
    }
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [sidebaron, setSidebaron] = useState(true)
  const [filters, setFilters] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [searchType, setSearchType] = useState('')
  const [searchKeyword, setSearchKeyword] = useState('')
  const wrapperRef = useRef()

  const handlePageChange = async (page) => {
    setIsBusy(true)
    const result = await ReferralService.getAll({ page: page, filters: filters })
    setData(result.my_referral_history?.data)
    setTotal(result.my_referral_history?.meta?.total ?? 0)
    setIsBusy(false)
  }

  const handleFilter = async () => {
    setIsBusy(true)
    const result = await ReferralService.getAll({
      fromDate: filters?.from_date,
      toDate: filters?.to_date,
      status: filters?.searchType
    })
    setData(result.my_referral_history?.data)
    setTotal(result.my_referral_history?.meta?.total ?? 0)

    setIsBusy(false)
  }

  const init = async () => {
    try {
      setIsBusy(true)
      const result = await ReferralService.getAll()
      setData(result.my_referral_history?.data ?? [])
      setTotal(result.my_referral_history?.meta?.total ?? 0)
      setIsBusy(false)
    } catch (error) {
      // console.log(error)
      setIsBusy(false)
    }
  }

  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false)
      wrapperRef.current.classList.remove('sidebaron')
    } else {
      setSidebaron(true)
      wrapperRef.current.classList.add('sidebaron')
    }
  }

  const handleSearchKeyword = async (keyword) => {
    setSearchKeyword(keyword)
    const result = await ReferralService.getAll({
      fromDate: filters?.from_date,
      toDate: filters?.to_date,
      status: filters?.searchType,
      name: keyword
    })
    setData(result.my_referral_history?.data)
    setTotal(result.my_referral_history?.meta?.total ?? 0)
  }

  useEffect(() => {
    init()
    fetchReferral()
  }, [])

  useEffect(() => {
    handleFilter()
    // eslint-disable-next-line
  }, [filters])

  const [myReferral, setMyReferral] = useState('')

  const fetchReferral = async () => {
    try {
      setIsBusy(true)
      const result = await ReferralService.getAll()
      setMyReferral(result ?? '')
      setIsBusy(false)
    } catch (error) {
      setIsBusy(false)
    }
  }

  return (
    <div className="product-wrapper sidebaron" ref={wrapperRef} >
      <div className="product-grid" style={{ minHeight: '50vh' }}>
        <Row >
          <Col xl="3">
            <div className="product-sidebar">
              <div className="filter-section">
                <Card className="m-0" >
                  <CardHeader>
                    <h6 className="mb-0 f-w-600">
                      {t("financial.filters")}
                      <span className="pull-right">
                        <i className="fa fa-chevron-down toggle-data" onClick={onClickFilter}></i>
                      </span>
                    </h6>
                  </CardHeader>
                  <div className="left-filter">
                    {/* <div style={{ padding: 16 }} /> */}
                    <HistoryFilters types={types} handleFilter={setFilters} setSearchType={setSearchType} filters={filters} setFromDate={setFromDate} setToDate={setToDate} />
                  </div>
                </Card>
              </div>
            </div>
          </Col>
          <Col xl="9" sm="12">
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Input
                className="form-control"
                type="text"
                placeholder="Search Name"
                defaultValue={searchKeyword}
                onChange={(e) => handleSearchKeyword(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <div id="referral-table" className="product-wrapper-grid" style={{ marginTop: 16 }}>
          <DataTable

            noHeader
            data={data}
            columns={columns}
            striped={true}
            center={true}
            pagination
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={total}
            onChangePage={handlePageChange}
            progressPending={isBusy}
          // subHeader
          // subHeaderComponent={`Total Money Earned: ${myReferral?.my_total_rewards} `}
          // subHeaderAlign="right"
          />
        </div>
      </div>
    </div>
  )
}

export default HistoryTable
