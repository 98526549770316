import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, FormGroup, Label, Input, Button, Card, CardHeader, CardBody } from 'reactstrap'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import styled from 'styled-components'
import UserService from '../../../network/services/user'
import FilesService from '../../../network/services/files'
import { AlertCircle } from 'react-feather'
import { bankList } from '../../../network/constants'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
// import Cards from 'react-credit-cards'

import MasterIcon from './MasterCard.png'
import VisaIcon from './visa.png'

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 575px) {
    > button {
      margin-top: 8px;
    }
  }
`

const BankForm = ({ bank, setIsEditable }) => {
  // console.log('bankbankbank', bank)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { register, handleSubmit, errors, watch, setValue, reset, formState } = useForm()
  // const { isSubmitting } = formState
  const [isBusy, setIsBusy] = useState(false)

  const [cardHolderName, setCardHolderName] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [expireDate, setExpireDate] = useState('')
  const [creditCardType, setCreditCardType] = useState()

  const creditCardFront = useSelector((state) => state.verification.creditCardFront)
  const verification = useSelector((state) => state.verification)

  const [errorCreditCardType, setErrorCreditCardType] = useState(false)

  const submitUpdate = async (data) => {

    setErrorCreditCardType(false)

    if (verification?.creditCardFront?.file == null) {
      dispatch({ type: 'SET_CREDITCARD_FRONT', creditCardFront: { error: 'Please select one' } })
      return
    }

    if (creditCardType !== 0 && creditCardType !== 1) {
      setErrorCreditCardType(true)
      return
    }

    setIsBusy(true)
    SweetAlert.fire({
      title: `Make sure your credit card details is correct`,
      html: `<div style={{textAlign:'left'}}>
              <p>Card Holder Name : ${cardHolderName || bank?.card_holder}<p>
              <p>Card Number : ${cardNumber || bank?.card_number}<p>
              <p>Credit Card Type : ${creditCardType === 0 ? 'Visa' : "Master" || bank?.card_type}<p>
              <p>Expired Date : ${expireDate || bank?.expired_date}<p>
              </div>
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t("user.Confirm"),
      cancelButtonText: t('user.Cancel'),
      reverseButtons: true,
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.value) {

        try {
          if (bank == null) {
            const CreditCardFrontRes = await FilesService.create(verification.creditCardFront?.file)
            // console.log('CreditCardFrontRes', CreditCardFrontRes)
            const result = await UserService.createCreditCard({
              card_number: data?.card_number,
              card_holder: data?.card_holder,
              card_type: creditCardType,
              expired_date: data?.expired_date,
              image_prove: CreditCardFrontRes?.data
            })
            // console.log(result)

            if (result.credit_card) {
              reset()
              SweetAlert.fire({
                title: 'Credit Card created successfully!',
                text: t('user.YourRequestWillBe'),
                icon: 'success'
              }).then(() => {
                window.location.reload()
              })
            }
          } else {
            const CreditCardFrontRes = await FilesService.create(verification.creditCardFront?.file)

            const result = await UserService.updateCreditCard(bank.id, {
              card_number: data?.card_number,
              card_holder: data?.card_holder,
              card_type: creditCardType,
              expired_date: data?.expired_date,
              image_prove: CreditCardFrontRes?.data
            })
            // console.log(result)

            if (result.credit_card) {
              reset()
              SweetAlert.fire({
                title: 'Credit Card updated successfully!',
                icon: 'success'
              }).then(() => {
                window.location.reload()
              })
            }
          }
        } catch (error) {
          setIsBusy(false)
          // SweetAlert.fire({
          //   title: error.message,
          //   icon: 'error'
          // })
          const errorCode = error.code
          const errorTranslate = error.translate_params
          SweetAlert.fire({
            title: t(errorCode, errorTranslate),
            icon: 'error'
          })
        }
      }else{
        setIsBusy(false)
      }
    })

  }

  useEffect(() => {
    if (bank != null) {
      setValue('card_holder', bank?.card_holder)
      setValue('card_number', bank?.card_number)
      setValue('expired_date', bank?.expired_date)
      setCreditCardType(bank?.card_type)
    }
    // eslint-disable-next-line
  }, [bank])

  const formatString = (e) => {

    var code = e.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    e.target.value = e.target.value.replace(
      /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
    ).replace(
      /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
    ).replace(
      /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
    ).replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
    ).replace(
      /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
    ).replace(
      /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
    ).replace(
      /\/\//g, '/' // Prevent entering more than 1 `/`
    );

    // console.log('e.target.value', e.target.value)
  }

  const creditCardFormat = (e) => {

    if (e.keyCode !== 8) {
      if (e.target.value.length === 4 || e.target.value.length === 9 || e.target.value.length === 14) {
        e.target.value = e.target.value += ' ';
      }
    }

  }


  const checkCreditCardType = (value) => {
    let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
    let mastercard = new RegExp('^5[1-5][0-9]{14}$');
    let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

    if (value.split(' ').join('').match(visa)) {
      // console.log('visa')
      setCreditCardType(0)
    } else if (value.split(' ').join('').match(mastercard) || value.split(' ').join('').match(mastercard2)) {
      // console.log('master')
      setCreditCardType(1)
    } else {
      // console.log('no valid')
      setCreditCardType('')
    }


  }


  return (
    <Form
      className="theme-form"
      onSubmit={handleSubmit(submitUpdate)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0.75rem',
        border: 'none'
      }}
    >

      {/* <Cards
        cvc=''
        expiry=''
        focus=''
        name=''
        number='' /> */}
      <FormGroup>
        <Label className="col-form-label pt-0">{t('financial.CardHolderName')}</Label>
        <Input
          className="form-control"
          type="text"
          name="card_holder"
          placeholder="eg. Johnson Tay"
          innerRef={register({ required: true })}
          onChange={(e) => {
            setCardHolderName(e.target.value)
          }}
          required
        />
        <span style={{ color: 'red' }}>{errors.account_holder && t('user.NameRequired')}</span>
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label pt-0 m-t-20 pb-0">{t('financial.CardNumber')}</Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            className="form-control"
            name="card_number"
            pattern="(\d{4}\s?){4}"
            placeholder="XXXX XXXX XXXX XXXX"
            maxlength="19"
            onKeyUp={(event) => creditCardFormat(event)}
            innerRef={register({ required: true })}
            onChange={(e) => {
              setCardNumber(e.target.value)
              checkCreditCardType(e.target.value)
            }}
            style={{ width: '50%', marginRight: '10px' }}
            required
          />
          <img style={{ width: '50px', height: '50px', filter: creditCardType === 0 ? '' : 'grayscale(100%)' }} src={VisaIcon} alt='visa' />
          <img style={{ width: '40px', height: '25px', filter: creditCardType === 1 ? '' : 'grayscale(100%)' }} src={MasterIcon} alt='master' />
        </div>


        <span style={{ color: 'red' }}>{errorCreditCardType && 'Please submit available credit card type (visa or master)'}</span>
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label pt-0  m-t-10">{t('financial.ExpiryDate')}</Label>
        <Input
          name='expired_date'
          maxlength='5'
          placeholder="MM/YY"
          type="text"
          onKeyUp={(event) => formatString(event)}
          innerRef={register({ required: true })}
          onChange={(e) => {
            setExpireDate(e.target.value)
          }}
          required
        />
        <span style={{ color: 'red' }}>
          {errors.account_number && t('user.AccountNumberRequired')}
        </span>
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label pt-0  m-t-20">{t('financial.CardFrontPage')}</Label>
        <Input
          type="file"
          name="file"
          accept="image/png, image/jpeg, application/pdf"
          onChange={(e) => {
            dispatch({
              type: 'SET_CREDITCARD_FRONT',
              creditCardFront: { file: e?.currentTarget?.files[0] }
            })
          }}
        />
        <span style={{ color: 'red' }}>{creditCardFront?.error != null && creditCardFront.error}</span>
      </FormGroup>


      <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', marginTop: '25px' }}>
        {/* <CardHeader style={{ padding: 20 }}>

        </CardHeader> */}
        <CardBody style={{ padding: 16 }}>
          <div style={{ display: 'flex' }}>
            <AlertCircle width="20px" style={{ color: '#f9b600', marginRight: '5px' }} />
            <h5 style={{ color: '#f9b600' }}>{t('financial.IMPORTANTNOTE')} !</h5>
          </div>
          <p>
            1. {t('financial.OnlyAcceptVISAorMaster')}
          </p>
          <p>
            2. {t('financial.MakeSureToEnter')}
          </p>
          <p>
            3. {t('financial.ProcessingApprovalTime')}
          </p>
          <p>
            4. {t('financial.TradehallWllNot')}
          </p>
          <p>
            5. {t('financial.ThePurposeOfThis')}
          </p>

        </CardBody>
      </Card>



      {bank != null ? (
        <StyledRow>
          <Button className="mt-2" onClick={() => setIsEditable(false)}>
            {t('user.Cancel')}
          </Button>

          <Button
            color="primary btn-block"
            type="submit"
            style={{ maxWidth: 150, float: bank == null ? 'right' : '' }}
            disabled={isBusy}
            className="mt-2"
          >
            {isBusy ? t("user.Loading") : t("user.Submit")}
          </Button>
        </StyledRow>
      ) : (
          <div style={{ width: '100%' }}>
            <Button
              color="primary btn-block"
              type="submit"
              style={{ maxWidth: 150, float: 'right' }}
              disabled={isBusy}
              className="mt-2"
            >
              {isBusy ? t("user.Loading") : t("user.Submit")}
            </Button>
          </div>
        )}
    </Form>
  )
}

export default BankForm
