import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import _ from 'lodash'

import WalletOption from '../options'
import WalletWithdrawal from './wallet'
import MT5Withdrawal from './mt5'

import { useTranslation } from 'react-i18next'

const Withdrawal = ({ refresh }) => {
  const { t } = useTranslation()
  const [option, setOption] = useState()
  const verification = useSelector((state) => _.last(state.user.verifications))

  // reset options on tab change
  useEffect(() => {
    setOption(null)
  }, [refresh])

  return (
    <>
      {verification?.status !== 1 ? (
        <>
          <h5 className="mb-3">{t("financial.withdraw")}</h5>
          <Card>
            <CardBody>
              <p style={{ color: 'red', fontWeight: 'bold' }}>
              
              {t("financial.yourProfileIsntVerified")}
              </p>
            </CardBody>
          </Card>
        </>
      ) : option == null ? (
        <WalletOption setOption={setOption} type="withdraw" />
      ) : (
        <>
          {option === 1 ? (
            <WalletWithdrawal setOption={setOption} />
          ) : (
            <MT5Withdrawal setOption={setOption} />
          )}
        </>
      )}
    </>
  )
}

export default Withdrawal
