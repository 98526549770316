import React, { useState, useEffect, Fragment } from 'react'
import {
    Col,
    Row,
    Collapse,
    Button,
    Progress
} from 'reactstrap'
import parse from 'html-react-parser'
import {
    BrowserRouter as Router,
    Switch,
    useParams,
    Link,
    useHistory
} from "react-router-dom";

import CourseService from '../../../network/services/courses'

import { useTranslation } from 'react-i18next'

import "./index.scss";

import BackArrow from './left.png'

import logo from './logoTradehall.png'

import {
    Video,
    ChevronDown,
    ChevronUp
} from 'react-feather'

import ReactGA from 'react-ga'


const PammManager = (props) => {

    const history = useHistory();
    const { id } = useParams()

    const { t } = useTranslation()
    // const [pammManager, setPammManager] = useState([])
    const [allCourses, setAllCourses] = useState([])
    // Filter manager list
    const [searchTerm, setSearchTerm] = useState("")

    const [allChapter, setAllChapter] = useState([])

    const [courseResult, setCourseResult] = useState({})

    const [description, setDescription] = useState([])

    const [isCollapse, setIsCollapse] = useState(false)
    const [isChapterOpenId, setIsChapterOpenId] = useState('')
    const toggleCollapse = (chapter) => {
        setIsChapterOpenId(chapter?.id)
        setIsCollapse(true)

    };

    const [selectedVideoIndex, setSelectedVideoIndex] = useState('')
    const [video, setVideo] = useState('')
    const [videoLink, setVideoLink] = useState('')
    const [videoID, setVideoID] = useState('')


    const init = async () => {

        const chaptersResult = await CourseService.getCourseChapter(id)
        setAllChapter(chaptersResult?.chapters)
        setCourseResult(chaptersResult?.course)

    }

    useEffect(() => {
        init()
    }, [video])

    const completeLesson = async () => {
        const completeResult = await CourseService.updateChapterProgress(videoID, {
            playback_status: 2
        })

        const chaptersResult = await CourseService.getCourseChapter(id)
        setAllChapter(chaptersResult?.chapters)
        setCourseResult(chaptersResult?.course)
        // console.log('completeResult', completeResult)
    }

    const updateLesson = async (lesson) => {
        // if (lesson?.userCourseProgresses?.playback_status === 0) {
        //     const completeResult = await CourseService.updateChapterProgress(lesson?.id, {
        //         playback_status: 1
        //     })

        //     const chaptersResult = await CourseService.getCourseChapter(id)
        //     setAllChapter(chaptersResult?.chapters)
        // }
        return
    }



    return (
        <>

            <Row id='course-header' style={{ justifyContent: 'space-between', margin: '0' }}>

                <Link to='/my_courses'>
                    <img src={BackArrow} alt='back arrow' style={{ width: '45px', height: '45px', cursor: 'pointer' }} />
                </Link>
                <img src={logo} alt='Tradehall Logo' style={{ width: '40px' }} />

            </Row>


            <Row id='rowCourses' style={{ margin: '0' }}>
                <Col id='colCoursesSide' lg='3' style={{ backgroundColor: '#f8f8f8', padding: '0', overflow: 'scroll', borderRight: '1px solid #0000001a' }} className='d-none d-md-block d-lg-block d-xl-block'>
                    <div>
                        <img src={allChapter[0]?.course?.cover_image_url} alt='courses background' style={{ width: '100%' }} />
                    </div>



                    <div
                        onClick={() => {
                            setVideoLink('')
                        }}
                        style={{ textAlign: 'center', cursor: 'pointer' }}>
                        <h3>{allChapter[0]?.course?.name}</h3>
                    </div>

                    <div>
                        <div className="text-center">{courseResult.course_progress}% Completed</div>
                        <Progress value={courseResult.course_progress} style={{ backgroundColor: '#f9b600' }} />
                    </div>

                    <hr />


                    {allChapter?.map((chapter, index) => {
                        return (
                            <>
                                {/* {console.log('chapter', chapter)} */}
                                <div style={{ display: 'flex', flexDirection: 'column', padding: '0 40px', cursor: 'pointer' }}>
                                    <div onClick={() => { toggleCollapse(chapter) }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <h6 style={{ marginBottom: '0px' }} >{chapter?.name}</h6>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h6 style={{ marginBottom: '0', marginRight: '15px' }}>
                                                {chapter?.lessons?.filter(lesson => {
                                                    return lesson?.userCourseProgresses?.playback_status === 2
                                                }).length}

                                                /{chapter?.lessons?.length}</h6>
                                            {isChapterOpenId === index + 1 && isCollapse ? <ChevronUp /> : <ChevronDown />}


                                        </div>
                                    </div>
                                    <Collapse isOpen={isChapterOpenId === allChapter[index].id && isCollapse}>
                                        <div style={{ marginLeft: '15px', marginTop: '10px' }}>
                                            {chapter?.lessons?.map((lesson, index) => {
                                                return (
                                                    <>
                                                        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px', marginTop: '20px' }}>
                                                            <Video width='20px' style={{ marginRight: '10px' }} />
                                                            <div style={{ marginTop: '2px' }}>
                                                                <p
                                                                    onClick={() => {
                                                                        setVideoLink(lesson?.video_url)
                                                                        setVideoID(lesson?.id)
                                                                        setVideo(lesson)
                                                                        updateLesson(lesson)
                                                                        setSelectedVideoIndex(index + 1)
                                                                        setDescription(lesson.description)
                                                                        ReactGA.event({
                                                                            category: 'Chapters',
                                                                            action: 'Click video',
                                                                            label: 'Click video'
                                                                        })
                                                                    }}
                                                                    style={{ marginBottom: '0px', cursor: 'pointer', color: videoID === lesson.id && '#f9b600' }}>{index + 1}. {lesson?.name}
                                                                </p>
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {lesson?.userCourseProgresses?.playback_status === 1 && <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 20 }}></i>}
                                                                {lesson?.userCourseProgresses?.playback_status === 2 && <i className="fa fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>}
                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            })}
                                            {/* <p style={{ marginBottom: '10px' }}>1. What is Forex? (10.45)</p>
                                        <p style={{ marginBottom: '0px' }}>2. How to Trade? (5.10)</p> */}
                                        </div>
                                    </Collapse>
                                </div>

                                <hr />
                            </>
                        )
                    })}


                </Col>

                <Col lg='9' style={{ padding: '0' }}>
                    {/* <h3 style={{ position: 'absolute', left: '50%', top: "60px", transform: 'translate(-50%, -50%)' }}>殿豪超值优惠 （配套A)</h3> */}
                    {videoLink === '' &&
                        <div style={{ whiteSpace: 'pre-wrap', padding: '10% 10% 0% 10%' }}>
                            <h5>{allChapter[0]?.course?.description}</h5>
                        </div>
                    }
                    {videoLink &&

                        <div>
                            <div style={{ padding: '6% 0 50% 0', position: 'relative', backgroundColor: videoLink && 'black' }}>
                                <iframe src={videoLink}
                                    frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowfullscreen
                                    style={{ position: 'absolute', top: '0', left: '0' }}
                                    id='frame-vimeo'
                                    title="Screen Recording 2021-11-29 at 11.09.29 AM.mov"
                                    onLoad={async () => {
                                        if (video?.userCourseProgresses?.playback_status === 0) {
                                            const completeResult = await CourseService.updateChapterProgress(videoID, {
                                                playback_status: 1
                                            })

                                            const chaptersResult = await CourseService.getCourseChapter(id)
                                            setAllChapter(chaptersResult?.chapters)
                                            setCourseResult(chaptersResult?.course)
                                        }
                                    }}
                                >
                                </iframe>
                            </div>
                            <script src="https://player.vimeo.com/api/player.js"></script>
                        </div>
                    }



                    {/* {console.log('lesson', video)} */}
                    {videoLink && video?.userCourseProgresses?.playback_status !== 2 &&
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Button onClick={() => { completeLesson() }}>Complete</Button>
                        </div>
                    }

                    {description != '' && description != null &&
                         <div className='ml-4 mr-4' style={{ marginTop: '20px' }}>
                            <p>{parse(description)}</p>
                        </div>
                    }



                    {/* <iframe
                        src={videoLink}
                        // width="1920"
                        // height="1080"
                        id='frame-vimeo'
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                        title="如何交易 - 突破，假突破 (字幕)HB">

                    </iframe> */}
                    {/* <script src="https://player.vimeo.com/api/player.js"></script> */}
                </Col>
            </Row>


            {/* Mobile size sidebar */}
            <Row className='d-block d-md-none' style={{ margin: '15px 0 0 0 ', height: '100%' }}>
                <Col>
                    <h5>{allChapter[0]?.course?.name}</h5>
                </Col>

                {allChapter?.map((chapter, index) => {
                    return (
                        <>

                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0 15px 20px' }}>
                                <div onClick={() => { toggleCollapse(chapter) }} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h5 style={{ marginBottom: '10px' }} >{chapter?.name}</h5>

                                    <div style={{ display: 'flex', marginTop: '2px' }}>
                                        <h6 style={{ marginBottom: '0', marginTop: '2px', marginRight: '15px' }}>
                                            {chapter?.lessons?.filter(lesson => {
                                                return lesson?.userCourseProgresses?.playback_status === 2
                                            }).length}

                                            /{chapter?.lessons?.length}</h6>
                                        {isChapterOpenId === index + 1 && isCollapse ? <ChevronUp /> : <ChevronDown />}


                                    </div>
                                </div>
                                <Collapse isOpen={isChapterOpenId === allChapter[index].id && isCollapse}>
                                    <div style={{ marginLeft: '15px' }}>
                                        {chapter?.lessons?.map((lesson, index) => {
                                            return (
                                                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                                    <Video width='20px' style={{ marginRight: '10px' }} />
                                                    <p
                                                        onClick={() => {
                                                            setVideoLink(lesson?.video_url)
                                                            setVideoID(lesson?.id)
                                                            setVideo(lesson)
                                                            updateLesson(lesson)
                                                            setSelectedVideoIndex(index + 1)
                                                            window.scrollTo(0, 0)
                                                            ReactGA.event({
                                                                category: 'Chapters',
                                                                action: 'Click video',
                                                                label: 'Click video'
                                                            })
                                                        }}
                                                        style={{ marginBottom: '0px', cursor: 'pointer', color: videoID === lesson.id && '#f9b600' && '#f9b600' }}>{index + 1}. {lesson?.name}
                                                    </p>

                                                    <div style={{ marginLeft: 'auto', marginTop: '5px' }}>
                                                        {lesson?.userCourseProgresses?.playback_status === 1 && <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 20 }}></i>}
                                                        {lesson?.userCourseProgresses?.playback_status === 2 && <i className="fa fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>}
                                                    </div>

                                                </div>
                                            )
                                        })}
                                    </div>
                                </Collapse>
                            </div>

                            <hr />
                        </>
                    )
                })}

            </Row>

        </>
    )
}

export default PammManager