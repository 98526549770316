import React, { useState, useEffect } from 'react'
import {
    Row,
    Input,
    Card,
    Col,
    Button,
    Form,
    CardBody,
    Badge
} from 'reactstrap'

import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import './pammManager.scss'

import "intro.js/introjs.css";

import VoucherService from '../../../network/services/voucher'
import CreditBonusVoucher from './voucher/creditBonus'
import WelcomeBonusVoucher from './voucher/vecourse'
import { useForm } from 'react-hook-form'
import SweetAlert, { swal } from 'sweetalert2'
import moment from 'moment'
import ClaimedVouchersTable from './claimed/index'

const Courses = (props) => {
    let history = useHistory()
    const dispatch = useDispatch()
    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: path })
    }

    const [openModal, setOpenModal] = useState(false)
    const toggleModal = () => {
        setOpenModal(!openModal)
    }

    const { register, handleSubmit, errors, reset } = useForm()

    const [selectedVoucher, setSelectedVoucher] = useState()
    const [isAccountRequired, setIsAccountRequired] = useState(false)
    const [availableVouchers, setAvailableVouchers] = useState([])

    const [claimedVouchers, setClaimedVouchers] = useState([])

    const [promocode, setPromocode] = useState('')

    const { t } = useTranslation()

    const fetchAvailablePromo = async () => {
        const result = await VoucherService.availableVouchers()

        setAvailableVouchers(result?.vouchers?.filter(voucher => {
            return voucher?.status === 0
        }))

        setClaimedVouchers(result?.vouchers?.filter(voucher => {
            return voucher?.status === 1
        }))

    }

    useEffect(() => {
        fetchAvailablePromo()
    }, [promocode])

    const checkVoucherType = (voucherType) => {
        if (voucherType === 0) {
            return 'Welcome Bonus'
        } else if (voucherType === 2) {
            return 'Credit Bonus'
        } else if (voucherType === 3) {
            return 'VE Course'
        }
    }

    const checkIsVoucherUsageExpired = (voucher) => {
        if (
          moment() >
          moment(voucher.created_at).add({ days: 30 })
        ) {
          return true
        } else {
          return false
        }
      }

    const calculateVoucherRemainingTime = (voucher) => {
        return moment(voucher.created_at).add({ days: 30 }).diff(moment(), 'days')
    }
      

    const popUpModal = (isAccountRequired) => {
        setIsAccountRequired(isAccountRequired)
    }

    const onSubmit = async data => {

        // console.log('data', data)

        try {
            if (data !== '') {
                const result = await VoucherService.claimVoucher(data)
                SweetAlert.fire({
                    title: t("voucher.VoucherSuccessfullyRedeemed"),
                    html: t("voucher.ClickTheVoucherToClaimNow"),
                    icon: 'success'
                }).then(() => {
                    window.location.href = '/vouchers'
                })
            } else {
                errors.showMessages()
            }

        } catch (error) {

            const errorCode = error.code
            const errorTranslate = error.translate_params
            SweetAlert.fire({
                title: t(errorCode, errorTranslate),
                icon: 'error'
            })

        }

    }


    return (
        <>
            <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <Row style={{ margin: '0' }}>

                    <div style={{ marginRight: "15px" }}>
                        <Input
                            name="promocode"
                            innerRef={register({ required: true })}
                            type="text"
                            placeholder={t("voucher.VoucherCode")}
                            onChange={(e) => {
                                setPromocode(e.target.value)
                            }}
                            required
                        />
                    </div>

                    <div style={{ marginTop: "2px" }}>
                        <Button >
                            {t("voucher.Redeem")}
                        </Button>
                    </div>

                </Row>
            </Form>


            <Row style={{ marginTop: '25px' }}>
                {/* {console.log('availableVouchers', availableVouchers)} */}
                {availableVouchers?.map(voucher => {
                    return (
    
                        <Col lg='6' style={{ marginBottom: '10px' }}>
                            <Card style={{ border: '1px solid #333', backgroundColor: checkIsVoucherUsageExpired(voucher) == true ? '#a7a7a7' : 'white'}}>
                                <Row
                                    onClick={() => {
                                        if(checkIsVoucherUsageExpired(voucher) == false){
                                        toggleModal()
                                        setSelectedVoucher(voucher)
                                        popUpModal(voucher?.promocode?.is_account_required)
                                        }
                                    }}
                                    style={{ cursor: checkIsVoucherUsageExpired(voucher) == true ? 'not-allowed' :'pointer' }}
                                >
                                    <Col lg='5' xs='5' style={{ paddingRight: '0px' }}>
                                        <img style={{ width: '100%', height: '150px', borderRadius: '13px 0px 0px 13px', borderRight: '1px dashed #333' }} src={voucher?.promocode?.image_url} />
                                    </Col>

                                    <Col lg='7' xs='7' style={{ padding: '0px', borderRadius: '0px 5px 5px 0px' }}>

                                        <CardBody style={{ paddingTop: '0px', paddingBottom: '0px', marginTop: '20px' }}>
                                            <Row style={{ flexDirection: 'column' }}>
                                                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                    <h4>{voucher?.promocode?.description}</h4>

                                                </div>


                                                <div className=' d-lg-flex d-xl-flex' style={{ marginBottom: '0.5rem' }}>
                                                    {voucher?.promocode?.free_credit > 0 && <Badge color='success' style={{ marginRight: '5px' }}>Free Credit</Badge>}
                                                    {voucher?.promocode?.bonus_credit_percentage > 0 && <Badge color='success' style={{ marginRight: '5px' }}>MT5 Deposit Bonus Credit</Badge>}
                                                </div>

                                                <div className=' d-lg-flex d-xl-flex' style={{ marginBottom: '0.5rem' }}>
                                                    {voucher?.promocode?.promocodeCourses.length > 0 && <Badge color='success' style={{ marginRight: '5px' }}>Courses</Badge>}
                                                </div>

                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '25px' }}>
                                                    {/* {voucher?.promocode?.expired_date && <p>Valid Until: {voucher?.promocode?.expired_date}</p>} */}
                                                    <p style={{ margin: '0',color: checkIsVoucherUsageExpired(voucher) == true ? 'red' : 'green' }}>{checkIsVoucherUsageExpired(voucher) == true ?  'Expired' : `${calculateVoucherRemainingTime(voucher)} days left`}</p>

                                                </div>
                                            </Row>

                                        </CardBody>

                                    </Col>


                                </Row>
                            </Card>
                        </Col>

                    )
                })}


            </Row>


            <div style={{ marginTop: '10px' }} >

                <ClaimedVouchersTable />

            </div>



            { isAccountRequired == true && <CreditBonusVoucher openModal={openModal} toggleModal={() => toggleModal()} voucherDetails={selectedVoucher} />}
            { isAccountRequired == false && <WelcomeBonusVoucher openModal={openModal} toggleModal={() => toggleModal()} voucherDetails={selectedVoucher} />}

        </>
    )
}

export default Courses