import { SET_CURRENCIES } from "../actionTypes";

const initial_state = {
  currencies: [],
};

export default (state = initial_state, action) => {
  // console.log(action);

  switch (action.type) {
    case SET_CURRENCIES:
      return { ...state, currencies: action.currencies };

    default:
      return { ...state };
  }
};
