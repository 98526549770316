import React, { useState } from 'react'
import StepZilla from 'react-stepzilla'
import { Container, Card, CardBody, Col, Row } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Breadcrumb from '../../layout/breadcrumb'
import DemoForm from './form'
import DemoAccountList from './list'
import { useTranslation } from 'react-i18next'

const DemoAccountRegistration = () => {
  const { t } = useTranslation()
  const [stage, setStage] = useState(0)
  let history = useHistory()
  const dispatch = useDispatch()
  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  return (
    <>
      <Breadcrumb
        parent={
          <Link
            to="#"
            onClick={() => {
              navigate(`${process.env.PUBLIC_URL}/demo`)
            }}
          >
            {t("demo.demoAccount")}
          </Link>
        }
        title="Register"
      />
      <Container>
        <Card>
          <CardBody>
            <h5>{t("demo.demoTraderAccountRegistration")}</h5>
            <div className="p-2" />
            <Row>
              <Col
                sm={12}
                md={6}
                className={`u-pearl ${stage === 0 && 'current'} ${stage > 0 && 'done'}`}
              >
                <span className="u-pearl-number">1</span>
                <span className="u-pearl-title">{t("demo.selectAccountType")}</span>
              </Col>
              <Col
                sm={12}
                md={6}
                className={`u-pearl ${stage === 1 && 'current'} ${stage > 1 && 'done'}`}
              >
                <span className="u-pearl-number">2</span>
                <span className="u-pearl-title">{t("demo.createAccount")}</span>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <StepZilla
          steps={[
            {
              name: t("demo.Step1"),
              component: <DemoAccountList />
            },
            {
              name: t("demo.Step2"),
              component: <DemoForm />
            }
          ]}
          showSteps={false}
          onStepChange={(index) => {
            setStage(index)
          }}
          backButtonText = {t('financial.Previous')}
          nextButtonText = {t('financial.Next')}
        />
        <div className="p-5" />
      </Container>
    </>
  )
}

export default DemoAccountRegistration
