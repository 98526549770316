import client from "../request"
import _ from "lodash"

const getAll = (props = null) => {
  let page = props?.page ?? 1
  let limit = props?.limit ?? 10
  let filters = props?.filters ?? null
  let filterString = ""

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }

  return client.get(`/plans?page=${page}&limit=${limit}${filterString}`)
}

const get = (id) => {
  return client.get(`/plans/${id}`)
}

export default {
  getAll,
  get,
}
