import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, CardBody } from 'reactstrap'
import DataTable from 'react-data-table-component'

import LegalService from '../../../network/services/legal'
import { useTranslation } from 'react-i18next'

const LegalDocuments = () => {
  const { t } = useTranslation()
  const columns = [
    {
      name: t("partner.name"),
      selector: 'name'
    },
    {
      name: '',
      button: true,
      width: '120px',
      cell: (row) => (
        <a href={`${row?.file_url ?? '#'}`} target="_blank">
          <Button color="primary">{t("partner.View")}</Button>
        </a>
      )
    }
  ]

  const [data, setData] = useState([])
  const init = async () => {
    const response = await LegalService.getAll()
    // console.log(response)
    setData(response.legals)
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      <Card>
        <CardHeader>
          <h5>{t("partner.legalDocuments")}</h5>
        </CardHeader>
        <CardBody>
          <div className="table-responsive product-table">
            <DataTable noHeader columns={columns} data={data} />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default LegalDocuments
