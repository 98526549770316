import React, { useState } from "react"
import DataTable from "react-data-table-component"
import { useTranslation } from 'react-i18next'


const RiskTable = () => {
  const { t } = useTranslation()
  const tableData = [
    {
      id: 321247,
      category: t("risk.stopRisk"),
      account: 512345,
      type: 1,
      symbol: 1,
      price: 1,
      profit: 1,
      volume: 1,
      opened: 1,
      closed: 1,
    },
    {
      id: 321248,
      category: t("risk.stopOut"),
      account: 512345,
      type: 1,
      symbol: 1,
      price: 1,
      profit: 1,
      volume: 1,
      opened: 1,
      closed: 1,
    },
  ]
  
  const columns = [
    {
      name: t("risk.ticket"),
      selector: "id",
      sortable: true,
    },
    {
      name: t("risk.ticket"),
      selector: "category",
      sortable: true,
    },
    {
      name: t("risk.category"),
      selector: "account",
      sortable: true,
    },
    {
      name: t("risk.type"),
      selector: "type",
      sortable: true,
    },
    {
      name: t("risk.symbol"),
      selector: "symbol",
      sortable: true,
    },
    {
      name: t("risk.price"),
      selector: "price",
      sortable: true,
    },
    {
      name: t("risk.profit"),
      selector: "profit",
      sortable: true,
    },
    {
      name: t("risk.volume"),
      selector: "volume",
      sortable: true,
    },
    {
      name: t("risk.opened"),
      selector: "open",
      sortable: true,
    },
    {
      name: t("risk.closed"),
      selector: "close",
      sortable: true,
    },
  ]
  const [data, setData] = useState(tableData)

  return <DataTable noHeader data={data} columns={columns} striped={true} center={true} />
}

export default RiskTable
