import React, { useState, useRef, useEffect } from "react"
import DataTable from "react-data-table-component"
import moment from "moment"
import { Link } from 'react-router-dom'

import VoucherService from '../../../../network/services/voucher'
import { useTranslation } from 'react-i18next'

const PendingTable = () => {
  const { t } = useTranslation()
  const columns = [
    {
      name: t("voucher.VoucherCode"),
      selector: "code",
      sortable: true,
      width: '200px',
      format: (row) => {
        return row?.promocode?.code ?? "-"
      }
    },
    {
      name: t("voucher.VoucherName"),
      selector: "name",
      sortable: true,
      width: '350px',
      format: (row) => {
        return row?.promocode?.description ?? "-"
      }
    },
    {
      name: t("pammSubsccription.date"),
      selector: "created_at",
      sortable: true,
      width: "200px",
      format: (row) => {
        return moment(row?.updated_at).format("yyyy-MM-DD")
      },
    },

    {
      name: t("pammSubsccription.status"),
      selector: "status",
      sortable: true,
      width: '200px',
      format: (row) => {
        switch (row?.status) {
          case 0:
            return 'Available'
          case 1:
            return 'Claimed'
          default:
            return "-"
        }
      },
    },

  ]

  const [isBusy, setIsBusy] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [filters, setFilters] = useState(null)
  const [searchKeyword, setSearchKeyword] = useState("")
  const wrapperRef = useRef()
  const [claimedVouchers, setClaimedVouchers] = useState([])


  const init = async () => {
    setIsBusy(true)

    const result = await VoucherService.availableVouchers()

    setClaimedVouchers(result?.vouchers?.filter(voucher => {
      return voucher?.status === 1
    }))


    setData(result?.vouchers?.filter(voucher => {
      return voucher?.status === 1
    }) ?? [])
    setIsBusy(false)
  }


  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
  }, [filters])

  return (



    claimedVouchers.length > 0 ?
      <>
        <h4>{t("voucher.VouchersHistory")}</h4>
        <DataTable
          noHeader
          data={data}
          columns={columns}
          striped={true}
          center={true}
          progressPending={isBusy}
        />
      </>
      : ''


  )
}

export default PendingTable
