import React, { useMemo, useState, useEffect } from 'react'
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Card,
    Container,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    Spinner
} from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import AccountService from '../../../network/services/account'
import { useTranslation } from 'react-i18next'

const DemoForm = () => {
    const { t } = useTranslation()
    let history = useHistory()
    const dispatch = useDispatch()
    const [currency, setCurrency] = useState(null)
    const [result, setResult] = useState(null)
    const [error, setError] = useState(null)
    const account = useSelector((state) => state.demo.account)
    const currencies = useSelector((state) => state.currency.currencies)
    const { register, handleSubmit, errors, formState } = useForm()
    const { isSubmitting } = formState
    const [isbusy, setIsbusy] = useState(true)


    useEffect(() => {
        // console.log(data)

        init()

    }, [])

    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: 'none' })
    }

    const init = async () => {

        const checkDemo = await AccountService.getAll({
            filters: {
                account_type: 1,
            },
        })

        if (checkDemo?.accounts.length !== 0) {
            navigate(`${process.env.PUBLIC_URL}/dashboard`)
        } else {
            const result = await AccountService.createDemo({
                plan_id: 5,
                currency_id: 1,
                leverage: 200,
                amount: 10000
            })
            setResult(result?.account)
            setIsbusy(false)
        }

    }

    const verifyProfile = () => {
        window.location.href = '/verify-details'
    }

    const skipVerify = () => {
        window.location.href = '/dashboard'
    }

    return (
        <>
            <Card
                style={{ alignItems: 'center', backgroundColor: '#f8f8f8', boxShadow: 'none' }}
            >
                <Card style={{ marginTop: '50px', padding: '20px' }}>
                    <img
                        id='img-header-logo'
                        className="img-fluid for-light"
                        width='200px'
                        src={require("../../../assets/images/logo/logoTradeHall.svg")}
                        alt=""
                        style={{ alignSelf: 'center' }}
                    />
                    <h5 style={{ alignSelf: 'center', marginTop: '10px' }}>{t('demo.congratulation')}</h5>
                    <p style={{ textAlign: 'center' }}>
                        {t('demo.DemoActivated')}
                    </p>

                    <img
                        src="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/card+design-03.png" width='100%' maxWidth='500px' style={{ alignSelf: 'center', maxWidth: '400px' }}
                    />


                    <div className='mt-2 p-l-10 p-r-10' >
                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                            <p style={{ fontSize: '16px', lineHeight: '1.5', marginBottom: '0px' }}>{t("demo.terminalLogin")}</p>
                            <p style={{ fontSize: '16px', fontWeight: '500', lineHeight: '1.5', marginBottom: '0px' }}>{result?.account_login}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                            <p style={{ fontSize: '16px', lineHeight: '1.5', marginBottom: '0px' }}>{t("demo.mainPassword")}</p>
                            <p style={{ fontSize: '16px', fontWeight: '500', lineHeight: '1.5', marginBottom: '0px' }}>{result?.password_main}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                            <p style={{ fontSize: '16px', lineHeight: '1.5', marginBottom: '0px' }}>{t("demo.investorPassword")}</p>
                            <p style={{ fontSize: '16px', fontWeight: '500', lineHeight: '1.5', marginBottom: '0px' }}>{result?.password_investor}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                            <p style={{ fontSize: '16px', lineHeight: '1.5', marginBottom: '0px' }}>{t("demo.accountLeverage")}</p>
                            <p style={{ fontSize: '16px', fontWeight: '500', lineHeight: '1.5', marginBottom: '0px' }}>1:200</p>
                        </div>
                        {/* <div style={{ display: "flex", justifyContent: 'space-between'}}>
                            <p>{t("demo.balanceAmount")}</p>
                            <p>10000</p>
                        </div> */}
                        {/* <li>Phone Password: {result?.password_phone}</li> */}
                        {/* <li>Server Name: {result?.plan?.group}</li> */}
                        {/* <li>Account Type: {result?.account_name}</li> */}
                        {/* <li>Account Currency: {result?.account_name}</li> */}
                    </div>
                    <p className='mt-4 mb-4' style={{ lineHeight: '1' }}>
                        *{t("live.KindlyCheckYour")}
                    </p>
                    {isbusy == false ?
                        <>
                            <Button
                                color='primary'
                                style={{
                                    color: 'black',
                                    borderRadius: '12px'
                                }}
                                onClick={verifyProfile}
                            >
                                {t('demo.openLive')}
                            </Button>
                            <Button
                                color='transparent-color'
                                className='mt-2'
                                style={{
                                    color: 'black',
                                    borderRadius: '12px'
                                }}
                                onClick={skipVerify}
                            >
                                {t('demo.Skip')}
                            </Button>
                        </>
                        : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spinner style={{ color: '#f9b700' }} />
                        </div>
                    }
                </Card>
            </Card>

        </>
    )
}

export default DemoForm
