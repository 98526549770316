import client from "../request"

const getAllCompetition = (props) => {
  let date = props?.date
  let status = props?.status
  return client.get(`/competitions?currentRunningCompetition=${date}&status=${status}`)
}

const getMyCompetition = (data) => {
  const competitionId = data.competition_id
  return client.get(`/trading_competition?competition_id=${competitionId}`)
}

const submitCompetition = (data) => {
  return client.post(`/trading_competition`, data)
}

const getLeaderBoardResult = (data) => {
  const competitionId = data.competition_id
  return client.get(`/trading_competition/leaderboard?competition_id=${competitionId}`)
}

const myCompetitionResult = (data) => {
  const competitionId = data.competition_id
  return client.get(`/trading_competition/my_comp_result?competition_id=${competitionId}`)
}

const submitCompetitionLive = (data) => {
  return client.post(`/trading_competition_live`, data)
}

const restCompetitionRoadToChampion = (data) => {
  return client.post(`/trading_competition_live/change`, data)
}

const registerCarnival = (data) => {
  return client.post(`/events`, data)
}

const carnivalDetails = (id) => {
  return client.get(`/events/${id}`)
}

const myCarnivalResult = (id, leaderBoardType) => {
  return client.get(`/events/${id}/leaderboard?type=${leaderBoardType}`)
}


export default {
  getMyCompetition,
  submitCompetition,
  getLeaderBoardResult,
  myCompetitionResult,
  getAllCompetition,
  submitCompetitionLive,
  restCompetitionRoadToChampion,
  registerCarnival,
  carnivalDetails,
  myCarnivalResult
}