import React from 'react'
import { Spinner } from 'reactstrap'
import { useSelector } from 'react-redux'
import parse from 'html-react-parser'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

const CustomDiv = styled.div`
  img {
    height: 100%;
    width: 100%;
  }
`

const AnnouncementModal = () => {
  const { t } = useTranslation()
  const announcements = useSelector((state) => state.announcement.announcements)
  return (
    <div>
      {announcements != null ? (
        announcements?.length > 0 ? (
          announcements.map((announcement) => {
            return (
              <CustomDiv
                style={{
                  img: {
                    height: '100%',
                    width: '100%'
                  }
                }}
              >
                <h5 className="text-center">{announcement?.title}</h5>
                {parse(announcement?.notice)}
              </CustomDiv>
            )
          })
        ) : (
          <p>{t("dashboard.noRecordFound")}</p>
        )
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default AnnouncementModal
