import client from '../request'

const getAll = () => {
    return client.get('/courses')
}


const getCourseChapter = (id) => {
    return client.get(`/courses/${id}/chapters`)
}

const updateChapterProgress = (id, data) => {
    return client.put(`/courses/update_lesson_progress/${id}`, data)
}



export default {
    getAll,
    getCourseChapter,
    updateChapterProgress
}
