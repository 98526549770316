import React, { useState, useEffect } from 'react'
import { Row, Form, Col, Card, CardBody, Button } from 'reactstrap'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import StepZilla from 'react-stepzilla'
import SweetAlert from 'sweetalert2'

import SelectCurrency from './currency'
import SelectPaymentMethod from './payment'
import ChooseAmount from './amount'
import WalletService from '../../../../network/services/wallet'
import PaymentService from '../../../../network/services/payment'
import { useTranslation } from 'react-i18next'

const WalletWithdrawal = ({ setOption }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const withdrawal = useSelector((state) => state.withdrawal)
  const [stage, setStage] = useState(0)
  const methods = useForm()

  // use redux for payment_id & wallet_id
  // because form doesn't work with stepzilla
  const handleWithdraw = async (data) => {
    SweetAlert.fire({
      title: t("financial.areYouSureWithdraw"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t("financial.confirm"),
      cancelButtonText: t("financial.cancel"),
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        try {
          data['wallet_id'] = withdrawal.wallet.id
          data['payment_id'] = withdrawal.wallet.method.id
          // console.log(data)

          // payment id of usdt in database is 2
          if (withdrawal.wallet.method.id === 2) {
            // currency id of usdt in database is 3
            data['currency_id'] = 3
          }

          if (data !== '') {
            let result = await WalletService.withdraw(data)
            // console.log(result)

            if (result?.withdraw?.id) {
              // success
              methods.reset()

              SweetAlert.fire({
                title: t("financial.withdrawSuccessfully"),
                text: t("financial.yourRequestWillBeReviewByAdmin"),
                icon: 'success'
              }).then(() => {
                window.location.reload()
              })
            }
          } else {
            methods.errors.showMessages()
          }
        } catch (error) {
          // SweetAlert.fire({
          //   title: error.message,
          //   icon: 'error'
          // })
          const errorCode = error.code
          const errorTranslate = error.translate_params
          SweetAlert.fire({
            title: t(errorCode, errorTranslate),
            icon: 'error'
          })
        }
      }
    })
  }

  const init = async () => {
    const walletResponse = await WalletService.get()
    // console.log(walletResponse)
    dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })

    const paymentResponse = await PaymentService.getAll()
    // console.log(paymentResponse)
    dispatch({ type: 'SET_PAYMENTS', payments: paymentResponse.payments })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col
              sm={12}
              md={4}
              className={`u-pearl ${stage === 0 && 'current'} ${stage > 0 && 'done'}`}
            >
              <span className="u-pearl-number">1</span>
              <span className="u-pearl-title">{t("financial.selectCurrency")}</span>
            </Col>
            <Col
              sm={12}
              md={4}
              className={`u-pearl ${stage === 1 && 'current'} ${stage > 1 && 'done'}`}
            >
              <span className="u-pearl-number">2</span>
              <span className="u-pearl-title">{t("financial.selectPaymentMethod")}</span>
            </Col>
            <Col sm={12} md={4} className={`u-pearl ${stage === 2 && 'current'}`}>
              <span className="u-pearl-number">3</span>
              <span className="u-pearl-title">{t("financial.chooseAmount")}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <FormProvider {...methods}>
        <Form className="theme-form" onSubmit={methods.handleSubmit(handleWithdraw)}>
          <StepZilla
            steps={[
              {
                name: t("financial.Step1"),
                component: <SelectCurrency />
              },
              {
                name: t("financial.Step2"),
                component: <SelectPaymentMethod />
              },
              {
                name: t("financial.Step3"),
                component: <ChooseAmount />
              }
            ]}
            showSteps={false}
            onStepChange={(index) => {
              setStage(index)
            }}
            backButtonText={t('financial.Previous')}
            nextButtonText={t('financial.Next')}
          />
        </Form>
      </FormProvider>

      {stage === 0 && (
        <Button
          color="primary"
          onClick={() => {
            setOption(null)
          }}
        >
          {t("financial.back")}
        </Button>
      )}

      {/* Add gap */}
      <div style={{ padding: 24, clear: 'both' }} />
    </>
  )
}

export default WalletWithdrawal
