import React from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import ClientTree from './tree'
import { useTranslation } from 'react-i18next'

const Clients = () => {
  const { t } = useTranslation()
  return (
    <Card>
      {/* <CardHeader>
        <h5>{t("partner.attractedClients")}</h5>
      </CardHeader> */}
      {/* <CardBody> */}
        <ClientTree />
      {/* </CardBody> */}
    </Card>
  )
}

export default Clients
