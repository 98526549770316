import React, { useState } from 'react'
import { Container, Row, Col, Card, Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

import FriendBanner from './IntiveFriend.svg'
import Step1 from './Step1.svg'
import Step2 from './Step2.svg'
import Step3 from './Step3.svg'
import { useSelector } from 'react-redux';

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'

import './landing.scss'

import { Steps } from 'intro.js-react';

const LandingPage = () => {

    const { t } = useTranslation()

    const [sliderValue, setSliderValue] = useState(0)

    const totalWalletDeposit = useSelector(state => state?.wallet?.totalDeposit)
    const totalAccountDeposit = useSelector(state => state?.wallet?.totalAccountDeposit)

    const userReferralCOde = useSelector(state => state?.user?.user?.referral_code)

    const useStyles = makeStyles((theme) => ({

        margin: {
            height: theme.spacing(3),
        },
    }));

    const marks = [
        {
            value: 0,
            label: '1',
        },
        {
            value: 6,
            label: '6',
        },
        {
            value: 11,
            label: '11',
        },
        // {
        //     value: 60,
        //     label: '60',
        // },
        // {
        //     value: 80,
        //     label: '80',
        // },
        // {
        //     value: 100,
        //     label: '100',
        // },
    ];

    function valuetext(value) {
        setSliderValue(value);
        return `${value}`;
    }

    const classes = useStyles();

    const rangeActiveClient = () => {
        if (sliderValue >= 0 && sliderValue <= 5) {
            return '1 - 5'
        } else if (sliderValue >= 6 && sliderValue <= 10) {
            return '6 - 10'
        } else if (sliderValue >= 11) {
            return t("referralFriend.moreThan11")
        } else if (sliderValue >= 60 && sliderValue <= 79) {
            return '60 - 79'
        } else if (sliderValue >= 80 && sliderValue <= 99) {
            return '80 - 89'
        } else {
            return '> 100'
        }
    }

    const priceActiveClient = () => {
        if (sliderValue >= 0 && sliderValue <= 5) {
            return '50'
        } else if (sliderValue >= 6 && sliderValue <= 10) {
            return '70'
        } else if (sliderValue >= 11) {
            return '100'
        }
        // else if (sliderValue >= 60 && sliderValue <= 79) {
        //     return '60 - 79'
        // } else if (sliderValue >= 80 && sliderValue <= 99) {
        //     return '80 - 89'
        // } else {
        //     return '> 100'
        // }
    }

    const [stepsEnabled, setStepsEnabled] = useState(true)
    const steps = [
        {
            element: '#refer-step-start',
            intro: t("intro.referAndEarn.start"),
        },
        {
            element: '#refer-step-1',
            intro: t("intro.referAndEarn.step1"),
        },
        {
            element: '#refer-step-2',
            intro: t("intro.referAndEarn.step2"),
        },
        {
            element: '#refer-step-3',
            intro: t("intro.referAndEarn.step3"),
            position: 'bottom'
        },
        {
            element: '#refer-step-4',
            intro: t("intro.referAndEarn.step4"),
            position: 'bottom'
        }
    ]

    return (
        <Row >
            <Col lg='12' style={{ marginBottom: '25px', color: 'black' }} >
                <div style={{
                    backgroundImage: `url(${FriendBanner})`,
                    backgroundSize: 'cover',
                    height: '400px',
                    padding: ' 0 15px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    borderRadius: '15px',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                }}>
                    <h1 className='inviteFriend-m-l-100' >{t("referralFriend.REFERRAL")}<br />{t("referralFriend.ANDEARN")}</h1>
                    <p className='inviteFriend-m-l-100' style={{ fontSize: "24px" }}>{t("referralFriend.InviteYourFriend")} <br /> {t("referralFriend.earnCommision")} </p>

                    {totalAccountDeposit >= 200 ?
                        <>
                            <CopyToClipboard
                                text={`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${userReferralCOde}`}
                            >
                                <Button
                                    className='inviteFriend-m-l-100'
                                    style={{ width: 'fit-content' }}
                                    onClick={() => {
                                        toast.success(`${t("partner.linkCopiedToClipboard")}`, {
                                            position: toast.POSITION.BOTTOM_RIGHT
                                        })
                                    }}
                                >
                                    {t("referralFriend.InviteFriends")}
                                </Button>

                            </CopyToClipboard>
                            <p className='inviteFriend-m-l-100'>
                                <i>*{t("referralFriend.UseThisLink")}</i>
                            </p>
                        </>
                        :
                        <>
                            <p className='inviteFriend-m-l-100'>
                                <i>*{t("referralFriend.YouNeedATtLeast")}</i>
                            </p>
                            <div className='inviteFriend-m-l-100'>
                                {totalWalletDeposit < 200 &&
                                    <Link to='/financial'>
                                        <Button id='refer-step-1' color="primary">1. {t("dashboard.depositToWallet")}</Button>
                                    </Link>
                                }

                                <Link to='/financial'>
                                    <Button id='refer-step-2' color="primary" className='referDepositMT5'>2. {t("dashboard.depositToMT5")}</Button>
                                </Link>
                            </div>
                        </>
                    }
                </div>
            </Col>

            {totalAccountDeposit >= 200 &&
                // Mobile Size
                <>
                    <Col lg='12' >
                        <div
                            className='d-xs-flex d-sm-flex d-mg-none d-lg-none d-xl-none'
                            style={{
                                backgroundColor: 'white',
                                marginTop: '0px',
                                padding: '30px',
                                textAlign: 'left',
                                marginBottom: '50px',
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: '15px',
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                color: 'black'
                            }}
                        >
                            <h3 style={{ textAlign: 'center' }}>{t("referralFriend.UnderstandHowToInvite")}</h3>
                            <p style={{ textAlign: 'center', fontSize: '18px' }}>{t("referralFriend.JoinOurInvite")}</p>
                            {/* <img src={Step} alt="Step invite friend" /> */}
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginBottom: '25px' }}>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={Step1} alt="step 1" style={{ width: '75px' }} />
                                    <h5 >{t("referralFriend.CopyYourReferral")}</h5>
                                </div>


                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={Step2} alt="step 2" style={{ width: '75px' }} />
                                    <h5 >{t("referralFriend.PasteTheReferralLinkToYour")}</h5>
                                </div>


                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={Step3} alt="step 3" style={{ width: '75px' }} />
                                    <h5 >{t("referralFriend.WaitForYourFriendsFinished")}</h5>
                                </div>


                            </div>

                            <div id='stepInviteFriend' style={{ marginTop: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h6 style={{ color: '#0f3c5a', marginBottom: '0px', marginRight: '25px' }}>{process.env.REACT_APP_BASE_URL}/sign_up?referral_code={userReferralCOde}</h6>
                                <div>
                                    <CopyToClipboard
                                        text={`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${userReferralCOde}`}
                                    >
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                toast.success(`${t("partner.linkCopiedToClipboard")}`, {
                                                    position: toast.POSITION.BOTTOM_RIGHT
                                                })
                                            }}
                                        >
                                            {t("partner.copyLink")}
                                        </Button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    </Col>

                    {/* Desktop version */}
                    <Col

                        lg='12'
                    >
                        <div
                            className='d-none d-mg-flex d-lg-flex d-xl-flex'
                            style={{
                                backgroundColor: 'white',
                                marginTop: '15px',
                                padding: '30px',
                                textAlign: 'center',
                                marginBottom: '50px',
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: '15px',
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                color: 'black'
                            }}
                        >
                            <h3>{t("referralFriend.UnderstandHowToInvite")}</h3>
                            <p style={{ fontSize: '18px' }}>{t("referralFriend.JoinOurInvite")}</p>
                            {/* <img src={Step} alt="Step invite friend" /> */}
                            <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '25px' }}>

                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                                    <img src={Step1} alt="step 1" style={{ width: '125px', marginLeft: '-25px' }} />
                                    <h3 style={{ marginLeft: '-25px', marginBottom: '-0.5rem' }}>{t("referralFriend.CopyYour")} <br />{t("referralFriend.referralLink")}</h3>
                                </div>


                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                                    <img src={Step2} alt="step 2" style={{ width: '125px', marginLeft: '-25px' }} />
                                    <h3 style={{ marginLeft: '-25px', marginBottom: '-0.5rem' }}>{t("referralFriend.PasteTheReferralLink")} <br /> {t("referralFriend.toYourFriends")}</h3>
                                </div>


                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                                    <img src={Step3} alt="step 3" style={{ width: '125px', marginLeft: '-25px' }} />
                                    <h3 style={{ marginLeft: '-25px', marginBottom: '-0.5rem' }}>{t("referralFriend.WaitForYourFriends")} <br />{t("referralFriend.finishedRegistration")}</h3>
                                </div>


                            </div>

                            <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <p style={{ color: '#0f3c5a', marginBottom: '0px', marginRight: '25px', fontSize: '18px' }}>{process.env.REACT_APP_BASE_URL}/sign_up?referral_code={userReferralCOde}</p>
                                <div>
                                    <CopyToClipboard
                                        text={`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${userReferralCOde}`}
                                    >
                                        <Button

                                            onClick={() => {
                                                toast.success(`${t("partner.linkCopiedToClipboard")}`, {
                                                    position: toast.POSITION.BOTTOM_RIGHT
                                                })
                                            }}
                                        >
                                            {t("partner.copyLink")}
                                        </Button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    </Col>
                </>

            }

            <Col >
                <Card style={{ padding: '30px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', backgroundColor: '#0f3c5a', color: 'white' }}>
                    <h3>{t("event.TermsAndConditions")}</h3>
                    <h5 id='refer-step-3'>{t("referralFriend.HowToRefer")}</h5>
                    <p>1. {t("referralFriend.TotalDepositMust")}</p>
                    <p>2. {t("referralFriend.AllClientsAreEligible")}</p>
                    <p>3. {t("referralFriend.OnlyNewClients")}</p>
                    <p>4. {t("referralFriend.TheCommissionIs")}</p>
                    <p>5. {t("referralFriend.TheClientCanTransfer")}</p>
                    <br />

                    <h5>{t("referralFriend.HowToEarn")}</h5>
                    <p>1. {t("referralFriend.FriendShouldSuccessfully")}</p>
                    <p>2. {t("referralFriend.FriendDepositsAMinimum")}</p>
                    <p>3. {t("referralFriend.TradingCompletesTrading")}
                        <br />
                    </p>

                    <a style={{ textAlign: 'center' }} href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tnc/TradeHall+Refer+and+Earn.pdf' target='_blank' rel="noopener noreferrer">
                        <Button color='primary' >{t("dashboard.View")}</Button>
                    </a>
                </Card>
            </Col>

            <Col lg='12' >
                <div
                    style={{
                        backgroundColor: 'white',
                        padding: '30px',
                        textAlign: 'center',
                        marginBottom: '50px',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '15px',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',

                    }}
                    id='refer-step-4'
                >
                    <h3 style={{ marginTop: '25px', textAlign: 'center', marginBottom: '50px' }}>{t("referralFriend.DragTheBarTo")}</h3>


                    <div className={classes.root} style={{ margin: '0 auto', display: 'flex' }} id="slider-referral">
                        <div>
                            <h1 style={{ color: '#f9b600' }}>{sliderValue === 0 ? 1 : sliderValue}</h1>
                            <p>{t("referralFriend.ActiveFriends")}</p>
                        </div>
                        <Slider
                            defaultValue={0}
                            getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider-always"
                            max={11}
                            min={0}
                            step={6}
                            marks={marks}
                            // valueLabelDisplay="on"
                            className="mr-5 ml-5"
                            color="primary"
                        />

                        <div>
                            <h1 style={{ color: '#f9b600' }}>${priceActiveClient()}</h1>
                            <p>{t("referralFriend.MoneyEarn")}</p>
                        </div>

                    </div>




                    <h3>{t("referralFriend.From")} {rangeActiveClient()} {t("referralFriend.activeFriends")}</h3>
                    <p style={{ fontSize: '16px' }}>{t("referralFriend.YouCanReceive")} <span style={{ color: '#f9b600' }}>${priceActiveClient()}</span> {t("referralFriend.fromEachFriend")}
                        <br />
                        <span style={{ color: "red" }}>{t("referralFriend.MakeSureYourFriends")}</span>
                    </p>

                    <p style={{ fontSize: '14px', textDecorationLine: 'underline' }}>
                        <a href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tnc/TradeHall+Refer+and+Earn.pdf' target='_blank' rel="noopener noreferrer">
                            Terms and Conditions
                        </a>
                    </p>
                </div>
            </Col>

            { localStorage.getItem('refer-friend-onboarding') === null &&
                <Steps
                    enabled={stepsEnabled}
                    steps={steps}
                    initialStep={0}
                    onExit={() => {
                        localStorage.setItem('refer-friend-onboarding', true)
                        setStepsEnabled(false)
                    }}
                    className='refer-friend-onboarding'
                    options={
                        {
                            showProgress: true,
                            doneLabel: 'Understand',
                            exitOnOverlayClick: false
                        }
                    }
                />

            }

        </Row >
    )
}

export default LandingPage