import React, { useState } from 'react'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'

import Breadcrumb from '../../layout/breadcrumb'
import SupportForm from './form'
import TicketTable from './table'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const CustomerSupport = (props) => {
  const { t } = useTranslation()
  const [refresh, setRefresh] = useState(null)

  let history = useHistory()
  const dispatch = useDispatch()

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  return (
    <>
      <Breadcrumb title={t("support.customerSupport")} />
      <Container>
        <Row>

          <Col lg='7' sm="12">
            <Card style={{
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
            }}>
              <CardBody>
                <TicketTable refresh={refresh} />
              </CardBody>
            </Card>

            <Col>
              <h6>{t('faqs.NeedMoreHelpReview')}
                <span
                  style={{ color: "#f9b600", cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/faqs`)
                  }} >
                  {t('faqs.faqs')}
                </span>
                {t('faqs.Now')}
              </h6>
            </Col>
          </Col>



          <Col lg='5' sm="12">
            <Card style={{
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
            }}>
              <CardHeader>
                <h5>{t("support.submitTicket")}</h5>
              </CardHeader>
              <CardBody>
                <SupportForm setRefresh={setRefresh} />
              </CardBody>
            </Card>
          </Col>



        </Row>

      </Container>
    </>
  )
}

export default CustomerSupport
