import React, { useState, useEffect } from "react"
import { Card, CardBody, CardHeader, Col, Row, Spinner } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"

import PlanService from "../../network/services/plan"
import { useTranslation } from 'react-i18next'

const DemoAccountList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const account = useSelector((state) => state.demo.account)

  const [accounts, setAccounts] = useState(null)

  const init = async () => {
    // demo accounts
    const result = await PlanService.getAll({
      filters: {
        account_type: 1,
      },
    })
    setAccounts(result.plans)

    if (result.plans?.length > 0) {
      dispatch({ type: "SET_DEMO_ACCOUNT", account: result.plans[0] })
    }
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="text-center">
      <Row>
        {accounts != null ? (
          accounts?.length > 0 &&
          accounts.map((item, index) => {
            return (
              <Col xl="3 xl-50" md="4" key={item.id}>
                <Card
                  className="card-absolute"
                  key={index}
                  onClick={() => {
                    dispatch({ type: "SET_DEMO_ACCOUNT", account: item })
                  }}
                  style={{
                    borderColor: account?.id === item.id ? "#f9b600" : null,
                  }}
                >
                  <CardHeader className="bg-secondary">
                    <h5>{item.name}</h5>
                  </CardHeader>
                  <CardBody className="p-3">
                    <div>
                      <p className="m-t-10">{item.description}</p>
                      <p>{t("demo.commission")} {item.commission} </p>
                      <p>{item.spread}</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )
          })
        ) : (
          <Col>
            <Card>
              <CardBody>
                <Spinner color="warning"/>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default DemoAccountList
