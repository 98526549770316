import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Spinner
} from 'reactstrap'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import { ToastContainer, toast } from "react-toastify";

import Breadcrumb from '../../layout/breadcrumb'
import Wallet from './wallet'

import WalletService from '../../network/services/wallet'
import ReferralModal from './referral_modal'
import AnnouncementModal from './announcement_modal'
import Carousel from './carousel'

import { useTranslation } from 'react-i18next'

import moment from 'moment'
import EventService from '../../network/services/event'
import AccountService from '../../network/services/account'

import PlanService from "../../network/services/plan"

import Transaction from './transaction'
import News from './news'

import backgroundWelcomeBonus from './background-01.svg'

import "intro.js/introjs.css";
// import introJs from 'intro.js'
import { Steps, Hints } from 'intro.js-react';

import PammMangerList from './PammManager/index'

import ReactGA from 'react-ga'

const ResponsiveCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
`

const ResponsiveVerifyButton = styled(Button)`
  background-color: #103c5a !important;
  border-color: #103c5a !important;
  margin-left: 12px;
  @media (max-width: 575px) {
    align-self: center;
  }
`

const ResponsiveParagraph = styled.p`
  margin: 0;
  text-align: left;
  @media (max-width: 575px) {
    margin-bottom: 8px;
    text-align: center;
  }
`

const ActionBarButton = styled(Button)`
  background-color: #0f3c5a00 !important;
  color:#103c5a !important;
  margin-left: 12px;
  border: 2px solid transparent !important;
  border-color: #103c5a !important;
  font-weight: 500 !important;
  
  &:hover {
    color: white!important;
  }
`



const Dashboard = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const promotions = useSelector((state) => state.promotion.promotions)
  const verification = useSelector((state) => _.last(state.user.verifications))
  const bank = useSelector((state) => _.last(state.user.banks))
  const showAnnouncement = useSelector((state) => state.announcement.showAnnouncement)
  const [myCompetitionRegistrations, setMyCompetitionRegistrations] = useState(null)
  const competitions = useSelector((state) => state.event?.competitions.competitions)
  const totalDeposit = useSelector((state) => state.wallet?.totalDeposit)
  const profile = useSelector((state) => state.user.profile)

  const isVerifying = useSelector((state) => state.user.verifications)

  const [isbusy, setIsbusy] = useState(false)
  const [liveAccount, setLiveAccount] = useState('')
  const [currentLiveAccount, setCurrentLiveAccount] = useState('')

  const ToastButton = () => (
    <div id='toastButtonDiv' style={{ marginRight: '20px' }}>
      <Button color='primary' style={{ color: 'black' }}>{t('dashboard.RedeemPackage')}</Button>
    </div>
  );

  // const campusCompetition = useSelector((state) =>
  //   state.event?.competitions?.competitions?.filter(
  //     (item) => item.name === 'Tradehall Campus CFD Trading Contest Malaysia 2021'
  //   )
  // )
  // const campusCompetitionStartDate = moment(campusCompetition[0]?.start_date).format('YYYY-MM-DD')
  // const campusCompetitionEndDate = moment(campusCompetition[0]?.end_date).format('YYYY-MM-DD')
  const currentDate = moment().utc().format('YYYY-MM-DD')

  // const [myCampusRegistrationRecord, setMyCampusRegistrationRecord] = useState([])

  // const [myRoadToChampionRecord, setMyRoadToChampionRecord] = useState([])

  // const RoadToChampionCompetition = useSelector((state) =>
  //   state.event?.competitions?.competitions?.filter(
  //     (item) => item.name === 'Tradehall Road to Champion Contest'
  //   )
  // )
  // const RoadToChampionStartDate = moment(RoadToChampionCompetition[0]?.start_date).format(
  //   'YYYY-MM-DD'
  // )
  // const RoadToChampionEndDate = moment(RoadToChampionCompetition[0]?.end_date).format('YYYY-MM-DD')

  const fetchMyRegistrationRecord = async () => {
    const myRegistrations = []
    for (let i = 0; i < competitions.length; i++) {
      const record = await EventService.getMyCompetition({ competition_id: competitions[i].id })
      myRegistrations.push(record?.submitted_registrations)
    }

    setMyCompetitionRegistrations(myRegistrations)
    // Hardcode competition Campus competition = 1
    // const campusRecord = await EventService.getMyCompetition({
    //   competition_id: 1
    // })
    // const RoadToChampionRecord = await EventService.getMyCompetition({
    //   competition_id: 2
    // })
    // setMyCampusRegistrationRecord(campusRecord?.submitted_registrations)
    // setMyRoadToChampionRecord(RoadToChampionRecord?.submitted_registrations)
    //loop through available competitions and fetch my registration records
  }


  const demo = async () => {
    // demo accounts
    setIsbusy(true)
    const result = await AccountService.getAll({
      filters: {
        account_type: 1,
      },

    })
    const resultLive = await AccountService.getAll({
      filters: {
        account_type: 0,
      },
    })
    setLiveAccount(resultLive)

    setIsbusy(false)

    if (result?.accounts.length !== 0) {
      navigate(`${process.env.PUBLIC_URL}/dashboard`)
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/demo_get`)
    }
  }


  useEffect(() => {
    // Hardcode competition id
    fetchMyRegistrationRecord()
    // eslint-disable-next-line
    // if(demoAccounts === 0){
    //   window.location.href='./demo_get'
    // }
    // console.log(demoAccounts,'121')
    demo()
  }, [])


  const init = async () => {
    const walletResponse = await WalletService.get()
    dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })
    dispatch({
      type: 'SET_BALANCE',
      balance: walletResponse.wallet_balance
    })
    dispatch({
      type: 'SET_TOTAL_DEPOSIT',
      totalDeposit: walletResponse.total_deposit
    })
    dispatch({
      type: 'SET_TOTAL_WITHDRAWAL',
      totalWithdrawal: walletResponse.total_withdrawal
    })
    dispatch({
      type: 'SET_TOTAL_ACCOUNT_DEPOSIT',
      totalAccountDeposit: walletResponse.total_account_deposit
    })
    dispatch({
      type: 'SET_TOTAL_ACCOUNT_WITHDRAWAL',
      totalAccountWithdraw: walletResponse.total_account_withdraw
    })
    const accountResponse = await AccountService.getAll()
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })

    const liveAccounts = accountResponse?.accounts?.filter(liveaccount => liveaccount?.account_type === 0)
    setCurrentLiveAccount(liveAccounts)
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: 'none' })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line

  }, [])

  useEffect(() => {
    if (user?.is_starter_pack_eligible) {

      toast.warn(t('dashboard.YouHavePackagePromotions'), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,

        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'toast-dashboard',
        onClick: () => {
          navigate(`${process.env.PUBLIC_URL}/live`)
        },
        closeButton: ToastButton

      })

    }
  }, [])

  // modal
  const [showReferral, setShowReferral] = useState(false)
  const toggleModal = () => {
    setShowReferral(!showReferral)
  }

  const toggleAnnouncementModal = () => {
    dispatch({ type: 'SET_ANNOUNCEMENTS_INIT', showAnnouncement: false })
  }

  const [stepsEnabled, setStepsEnabled] = useState(true)
  const steps = [
    {
      element: '#first-step',
      intro: t('intro.dashboard.step1'),
    },
    {
      element: '#action-bar',
      intro: t('intro.dashboard.step2'),
    },
    {
      element: '#button-to-deposit-wallet',
      intro: totalDeposit === 0 ? t('intro.dashboard.step3.noDeposit') : t('intro.dashboard.step3.Hasdeposit'),
    },
    {
      element: '#button-to-KYC',
      intro: t('intro.dashboard.step4'),
    }
  ];


  return (
    <>
      {/* Toastify if user able to apply starter package */}
      {isbusy ?
        <Container
          style={{
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spinner color="warning" />
        </Container> : ""
      }

      {(totalDeposit === 0 || (verification?.status !== 1 && verification?.status !== 0 || isVerifying === [])) &&

        <>
          <Alert id='action-bar' style={{ top: "30px", marginLeft: '15px', marginRight: '15px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }} color="primary">
            <div id='div-action-bar' >
              <div className="m-r-20" style={{ color: 'black' }}>{t('dashboard.ActionNeedToTake')}</div>
              <div id='div-button-action-bar'>
                {/* {
                  totalDeposit === 0 &&
                  <div className="m-r-20">
                    <Link to='/financial'>
                      <ActionBarButton id='button-to-deposit-wallet'>{t('dashboard.FundToWallet')}</ActionBarButton>
                    </Link>
                  </div>
                } */}

                {/* {
                  verification?.status !== 1 && verification?.status !== 0 &&
                  <div className="m-r-20">
                    <Link to='/account#verification'>
                      <ActionBarButton id='button-to-KYC'>{t('dashboard.VerifyProfile')}</ActionBarButton>
                    </Link>
                  </div>
                } */}

                {
                  // if user is not complete profile
                  profile != null && !_.isEmpty(profile) ?
                  '' :
                  <div className="m-r-20">
                    <Link to='/verify-details'>
                      <ActionBarButton id='button-to-KYC'>{t('dashboard.VerifyProfile')}</ActionBarButton>
                    </Link>
                  </div>
                  

                }

                {
                  bank?.status !== 1 &&
                  <div className="m-r-20">
                    <Link to='/account#bank'>
                      <ActionBarButton id='button-to-KYC'>{t('dashboard.VerifyBankDetails')}</ActionBarButton>
                    </Link>
                  </div>
                }
              </div>

            </div>
            {(localStorage.getItem('homepage-onboarding') === null && (verification?.status !== 1 || isVerifying === [])) &&
              <Steps
                enabled={stepsEnabled}
                steps={steps}
                initialStep={0}
                onExit={() => {
                  localStorage.setItem('homepage-onboarding', true)
                  setStepsEnabled(false)
                }}
                options={
                  {
                    showProgress: true,
                    doneLabel: 'Understand',
                    exitOnOverlayClick: false
                  }
                }

              />}
          </Alert>

        </>
      }



      <Breadcrumb title={t('dashboard.dashboard')} />
      <Container style={{ paddingBottom: 40 }}>
        <Row>
          <Col>
            {verification?.status === 0 && (
              <Card>
                <CardBody
                  className="p-4"
                  style={{ backgroundColor: '#f9b600', borderRadius: '15px' }}
                >
                  <ResponsiveCard>
                    <ResponsiveParagraph style={{ color: 'black', fontWeight: 'bold' }}>
                      {t('dashboard.yourRecentApplicationWasPending')}
                    </ResponsiveParagraph>
                    <ResponsiveVerifyButton
                      color="primary"
                      onClick={() => {
                        navigate(`${process.env.PUBLIC_URL}/account#verification`)
                      }}
                    >
                      {t('myPamm.Check')}
                    </ResponsiveVerifyButton>
                  </ResponsiveCard>
                </CardBody>
              </Card>
            )}

            {verification?.status === 3 && verification?.reason && (
              <Card>
                <CardBody
                  className="p-4"
                  style={{ backgroundColor: '#ffc4c4', borderRadius: '15px' }}
                >
                  {/* <ResponsiveCard>
                    <ResponsiveParagraph style={{ color: '#963737', fontWeight: 'bold' }}>
                      {t('dashboard.yourProfileIsntVerified')}
                    </ResponsiveParagraph>
                    <ResponsiveVerifyButton
                      color="primary"
                      onClick={() => {
                        navigate(`${process.env.PUBLIC_URL}/account#verification`)
                      }}
                    >
                      {t('dashboard.verify')}
                    </ResponsiveVerifyButton>
                  </ResponsiveCard> */}

                  {(
                    <>
                      <ResponsiveParagraph style={{ color: '#ab0505' }}>
                        {t('dashboard.yourRecentApplicationWasRejected')}
                      </ResponsiveParagraph>
                      <ResponsiveParagraph style={{ color: '#ab0505' }}>{`${t(
                        'dashboard.reason'
                      )}: ${verification?.reason}`}</ResponsiveParagraph>
                    </>
                  )}
                </CardBody>
              </Card>
            )}

            {/* {bank?.status !== 1 && (
              <Card>
                <CardBody
                  className="p-4"
                  style={{ backgroundColor: '#ffc4c4', borderRadius: '15px' }}
                >
                  <ResponsiveCard>
                    <ResponsiveParagraph style={{ color: '#963737', fontWeight: 'bold' }}>
                      {t('dashboard.yourBankDetailsIsntVerified')}
                    </ResponsiveParagraph>
                    <ResponsiveVerifyButton
                      color="primary"
                      onClick={() => {
                        navigate(`${process.env.PUBLIC_URL}/account#bank`)
                      }}
                    >
                      {t('dashboard.verify')}
                    </ResponsiveVerifyButton>
                  </ResponsiveCard>
                  {bank?.status === 3 && bank?.reason && (
                    <>
                      <ResponsiveParagraph style={{ color: '#ab0505' }}>
                        {t('dashboard.yourRecentApplicationWasRejected')}
                      </ResponsiveParagraph>
                      <ResponsiveParagraph style={{ color: '#ab0505' }}>{`${t(
                        'dashboard.reason'
                      )}: ${bank?.reason}`}</ResponsiveParagraph>
                    </>
                  )}
                </CardBody>
              </Card>
            )} */}

            {/* {(user.role?.handle === 'ib' ||
              user.role?.handle === 'pib' ||
              user.role?.handle === 'mib') && (
                <Card>
                  <CardBody className="p-4">
                    <ResponsiveCard>
                      <ResponsiveParagraph>{t("dashboard.inviteFriendsAndEarnMoney")}</ResponsiveParagraph>
                      <ResponsiveInviteButton
                        color="primary"
                        onClick={() => {
                          setShowReferral(true)
                        }}
                      >
                        {t("dashboard.invite")}
                      </ResponsiveInviteButton>
                    </ResponsiveCard>
                  </CardBody>
                </Card>
              )} */}

            {/* {verification?.status === 1 && myCompetitionRegistrations && (
              <>
                {competitions.map((competition, index) => (
                  
                  <Card>
                    <CardBody
                      className="p-4"
                      style={{
                        textAlign: 'center',
                        backgroundColor:
                          myCompetitionRegistrations[index][0]?.status === 0
                            ? '#fff3cd'
                            : myCompetitionRegistrations[index][0]?.status === 1
                              ? '#D4EDD3'
                              : myCompetitionRegistrations[index][0]?.status === 2 && '#f8d7d9',

                        borderRadius: '5px',
                        color:
                          myCompetitionRegistrations[index][0]?.status === 0
                            ? '#8c670e'
                            : myCompetitionRegistrations[index][0]?.status === 1
                              ? '#1e6137'
                              : myCompetitionRegistrations[index][0]?.status === 2 && '#7e2a26'
                      }}
                    >
                      <ResponsiveCard>
                        <ResponsiveParagraph>
                          <span style={{ fontWeight: 'bold' }}>
                            {competition.status === 1 &&
                              currentDate < moment(competition.start_date).utcOffset(0).format('YYYY-MM-DD')
                              ? competition.name + ` ${t('dashboard.isOpenNow')}`
                              : competition.name + ` ${t('dashboard.isStartNow')}`}
                          </span>
                          <br />
                          {currentDate <=
                            moment(competition.end_registration_date).utcOffset(0).format('YYYY-MM-DD')
                            ? `${t('dashboard.RegistrationDeadline', {
                              date: moment(competition.end_registration_date).utcOffset(0).format('YYYY-MM-DD')
                            })}(GMT+0)`
                            : `${t('dashboard.CompetitionStartFrom')} ${moment(
                              competition.start_date
                            ).utcOffset(0).format('YYYY-MM-DD')}(GMT+0) ${t('dashboard.until')} ${moment(
                              competition.end_date
                            ).utcOffset(0).format('YYYY-MM-DD')}(GMT+0)`}
                        </ResponsiveParagraph>

                        <ResponsiveParagraph>
                          <span style={{ fontWeight: 'bold' }}>
                            {t('dashboard.RegistrationStatus')}
                          </span>
                          <br />{' '}
                          {myCompetitionRegistrations[index]?.length > 0
                            ? myCompetitionRegistrations[index][0].status === 0
                              ? t('dashboard.Pending')
                              : myCompetitionRegistrations[index][0].status === 1
                                ? t('dashboard.Success')
                                : myCompetitionRegistrations[index][0].status === 2 &&
                                `${t('dashboard.RejectBecauseOf')} ${myCompetitionRegistrations[index][0]?.reason
                                  ? myCompetitionRegistrations[index][0]?.reason
                                  : ''
                                }`
                            : t('dashboard.YouHaveNotJoin')}{' '}
                          {currentDate <=
                            moment(competition.end_registration_date).utcOffset(0).format('YYYY-MM-DD')
                            ? ''
                            : `(${t('event.RegistrationIsClosed')})`}
                        </ResponsiveParagraph>

                        <ResponsiveVerifyButton
                          color="primary"
                          onClick={() => {
                            competition.account_type_joined == 3
                              ? navigate(`${process.env.PUBLIC_URL}/event/road_to_champion`)
                              : navigate(`${process.env.PUBLIC_URL}/event/campus_competition`)
                          }}
                        >
                          {myCompetitionRegistrations[index]?.length > 0 ||
                            currentDate >
                            moment(competition.end_registration_date).utcOffset(0).format('YYYY-MM-DD')
                            ? t('dashboard.View')
                            : t('dashboard.Participate')}
                        </ResponsiveVerifyButton>
                      </ResponsiveCard>
                    </CardBody>
                  </Card>
                ))}
              </>
            )} */}


          </Col>
        </Row>

        {/* <Row style={{ justifyContent: 'center', alignItems: 'center' }} className="m-2">
          <Carousel />
        </Row> */}

        {/* <Row>

          {promotions != null ? (
            promotions?.length > 0 &&
            promotions.map((promo) => {
              return (
                <>
                  <h4 style={{ color: '#103c5a' }} className="mt-5 mb-2">{t("dashboard.promotions")}</h4>
                  <Col lg='4' key={promo.id}>
                    <Banner promotion={promo} />
                  </Col>
                </>
              )
            })
          ) : (
              <Col>
                <Card>
                  <CardBody style={{ textAlign: 'center' }}>
                    <Spinner />
                  </CardBody>
                </Card>
              </Col>
            )}
        </Row> */}

        <Row>
          <Col lg="5" >
            <Wallet />
          </Col>

          <Col lg="7">
            <Carousel />
          </Col>
        </Row>

        {/* {(user.first_deposit_status != 1 && user.is_funnel_completed == false) && (
          <Row className="m-t-10">
            <Col>
              <Card style={{
                borderRadius: '0px', backgroundColor: 'rgb(196 196 197 / 30%)',
                backgroundImage: `url(${backgroundWelcomeBonus})`,
                backgroundSize: 'cover',
                color: 'white'
              }}>
                <CardBody style={{ height: '250px' }}>
                  <Row style={{ height: '160px', alignItems: 'center' }}>
                    <Col>
                      <h1 style={{ fontWeight: 'normal' }}>{t('dashboard.WelcomeBonusRedeem')}</h1>
                      <p>{t('dashboard.RedeemBonusAnytime')}</p>
                      <Link to='/financial'>
                        <Button color="primary" >
                          {t('dashboard.RedeemBonus')}
                        </Button>
                      </Link>
                    </Col>

                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )} */}



        <Row style={{ marginTop: '15px' }}>

          <Col lg='5'>
            <Card style={{
              padding: '15px 20px',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
              borderRadius: '5px',
            }}>
              <Row style={{ padding: '0px 15px', justifyContent: 'space-between', alignItems: 'center' }}>
                <h5 style={{ fontWeight: 'normal' }}>{t('dashboard.recentTransactions')}</h5>
                <p
                  style={{ marginBottom: '0', cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/financial#history`)
                    ReactGA.event({
                      category: 'Click from dashboard PAMM',
                      label: 'Click from dashboard PAMM'
                    })
                  }}
                >
                  {t('dashboard.viewMore')}
                </p>
              </Row>
              <Transaction />
            </Card>
          </Col>



          <Col lg='7'>
            <Card style={{
              padding: '15px 20px',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
              borderRadius: '5px',
            }}>
              <Row style={{ padding: '0px 15px', justifyContent: 'space-between', alignItems: 'center' }}>
                <h5 style={{ fontWeight: 'normal' }}>PAMM Manager {t('pamm.statistics')}</h5>
                <p
                  style={{ marginBottom: '0', cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/pamm`)
                  }}
                >
                  View More
                </p>
              </Row>
              {/* <News /> */}
              <PammMangerList />
            </Card>
          </Col>





        </Row>

      </Container>

      {
        showReferral && (
          <Modal isOpen={showReferral} backdrop={true} centered={true}>
            <ModalHeader toggle={toggleModal}>{t('dashboard.inviteFriendsAndEarnMoney')}</ModalHeader>
            <ModalBody>
              <ReferralModal />
            </ModalBody>
          </Modal>
        )
      }

      {
        <Modal isOpen={showAnnouncement} backdrop={true} centered={true}>
          <ModalHeader
            style={{
              backgroundColor: 'rgb(15, 60, 90)',
              color: 'white',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {t('dashboard.importantAnnouncement')}
          </ModalHeader>
          <ModalBody>
            <AnnouncementModal />
            <Button
              style={{ display: 'block', margin: '0 auto' }}
              color="primary"
              onClick={toggleAnnouncementModal}
            >
              {t('dashboard.noted')}
            </Button>
          </ModalBody>
        </Modal>
      }



    </>
  )
}

export default Dashboard
