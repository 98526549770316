import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Table,
  Row,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Input
} from 'reactstrap'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import PartnerSettings from './settings'
import MasterPassword from '../../common/settings/master_password'
import InvestorPassword from '../../common/settings/investor_password'
import ChangeLeverage from '../../common/settings/leverage'

import PartnerService from '../../../network/services/partner'
import UserService from '../../../network/services/user'
import { useTranslation } from 'react-i18next'
// import RegistrationForm from './registrationForm'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'

import ActiveClient from './icon2.svg'
import TotalClient from './icon1.svg'
import Caption from './icon4.svg'
import MonthlyEarn from './icon3.svg'

import { HelpCircle, User } from 'react-feather'
import partner from '../../../network/services/partner'
const StyledCol = styled.div`
  text-align: center;
  margin-left: 10px;
  @media (max-width: 1549px) {
    margin-bottom: 12px;
  }

  @media (min-width: 1550px) {
    width: 33.33%;
  }
`

const IbRoom = () => {
  const { t } = useTranslation()
  let history = useHistory()
  const dispatch = useDispatch()
  const partners = useSelector((state) => state.user.partners)
  const currencies = useSelector((state) => state.currency.currencies)
  const [currency, setCurrency] = useState(null)
  const user = useSelector((state) => state?.user?.user)
  const [totalCommissions, setTotalCommissions] = useState(null)
  const [totalMonthCommissions, setTotalMonthCommissions] = useState(null)
  const [partnerAccountDetails, setPartnerAccountDetails] = useState(null)
  const [clientsCount, setClientsCount] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [tutorialModal, setTutorialModal] = useState(false)
  const toggleTutorial = () => setTutorialModal(!tutorialModal)

  useEffect(() => {
    // get first ib account currency
    if (partners?.length > 0) {
      setCurrency(
        currencies.find((e) => {
          return e.id === partners[0].account?.currency_id
        })
      )
    }
    // eslint-disable-next-line
  }, [partners])

  const init = async () => {
    setIsLoading(true)

    const partnerResponse = await PartnerService.get()

    dispatch({ type: 'SET_AGENT', partners: partnerResponse.partners })
    setTotalCommissions(partnerResponse.total_commissions)
    setTotalMonthCommissions(partnerResponse.monthly_commissions)
    setPartnerAccountDetails(partnerResponse.partner_account_details)
    setClientsCount(partnerResponse?.clients.length ?? 0)
    // let clientTotal = partnerResponse.partners.map(item => item.)
    setIsLoading(false)
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  // modal
  const selectedAccount = useSelector((state) => state.dashboard.account)
  const [showModal, setShowModal] = useState(false)
  const [selectedSetting, setSelectedSetting] = useState(null)
  const toggleModal = () => {
    setShowModal(!showModal)
  }
  const settings = [
    {
      id: 1,
      title: t('partner.changeMasterPassword'),
      cta: <MasterPassword setShowModal={setShowModal} />
    },
    {
      id: 2,
      title: t('partner.changeInvestorPassword'),
      cta: <InvestorPassword setShowModal={setShowModal} />
    },
    {
      id: 3,
      title: t('partner.changeLeverage'),
      cta: <ChangeLeverage setShowModal={setShowModal} />
    }
  ]

  useEffect(() => {
    if (selectedSetting != null && selectedAccount != null) {
      setShowModal(true)
    }
  }, [selectedSetting, selectedAccount])

  useEffect(() => {
    if (showModal === false) {
      // fetch account
      if (selectedAccount != null) {
        init()
      }

      setSelectedSetting(null)
      dispatch({
        type: 'SET_DASHBOARD_ACCOUNT',
        account: null
      })
    }
    // eslint-disable-next-line
  }, [showModal])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  return (
    <>
      {partners != null ? (
        partners?.length > 0 ? (
          partners.map((partner) => {
            return (
              <>
                {/* <CardHeader>
                  <h5>{partners != null && partners?.length > 0 ? t("partner.IBAccounts") : 'Open new Introducing broker account'}</h5>
                </CardHeader> */}
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col>
                          <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                            <CardBody style={{ padding: '20px 40px' }}>
                              {/* <Row style={{ justifyContent: 'space-between' }}> */}
                              <div>
                                
                                <h6>
                                  {t('partner.Total')} <br />
                                  {t('partner.Revenue')}
                                </h6>
                              </div>

                              
                              <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                {isLoading ? (
                                  <Spinner />
                                ) : (
                                    formatter.format(totalCommissions) ?? '-'
                                  )}
                              </h3>
                              {/* </div> */}
                              {/* </Row> */}
                            </CardBody>
                          </Card>
                        </Col>

                        <Col>
                          <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                            <CardBody style={{ padding: '20px 40px' }}>
                              {/* <Row style={{ justifyContent: 'space-between' }}> */}
                              <div>
                                {/* <img
                                    src={ActiveClient}
                                    alt="Active Client"
                                    style={{ width: '60px' }}
                                  /> */}
                                <h6>
                                  {t('partner.ThisMonth')} <br />
                                  {t('partner.Revenue')}{' '}
                                </h6>
                              </div>

                              {/* <p
                                    className="text-right"
                                    style={{ marginBottom: '0px', color: '#f9b700' }}
                                  >
                                    {currency?.name ?? ''}
                                  </p> */}
                              <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                {isLoading ? (
                                  <Spinner />
                                ) : (
                                    formatter.format(totalMonthCommissions) ?? '-'
                                  )}
                              </h3>

                              {/* </Row> */}
                            </CardBody>
                          </Card>
                        </Col>

                        <Col>
                          <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                            <CardBody style={{ padding: '20px 40px' }}>
                              <div>
                                {/* <img
                                    src={ActiveClient}
                                    alt="Active Client"
                                    style={{ width: '60px' }}
                                  /> */}
                                <h6>
                                  {t('partner.Account')} <br />
                                  {t('partner.Balance')}{' '}
                                </h6>
                              </div>

                              <div style={{ alignSelf: 'flex-end' }}>
                                <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                  {isLoading ? (
                                    <Spinner />
                                  ) : (
                                      formatter.format(
                                        partnerAccountDetails?.Balance
                                      ) ?? '-'
                                    )}
                                </h3>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col>
                          <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                            <CardBody style={{ padding: '20px 40px' }}>
                              <div>
                                {/* <img
                                    src={TotalClient}
                                    alt="Total Client"
                                    style={{ width: '60px' }}
                                  /> */}
                                <h6>
                                  {t('partner.Total')} <br /> {t('partner.Clients')}
                                </h6>
                              </div>

                              <h3 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                {isLoading ? <Spinner /> : clientsCount ?? '-'}
                              </h3>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>

                      <Row>
                        {/* <Col>
                          <Card>
                            <CardBody style={{ padding: '20px 40px' }}>
                              <Row style={{ justifyContent: 'space-between' }}>
                                <div>
                                  <img
                                    src={MonthlyEarn}
                                    alt="Montly Earn"
                                    style={{ width: '60px' }}
                                  />
                                  <h6>Equity</h6>
                                </div>

                                <div style={{ alignSelf: 'flex-end' }}>
                                  <p
                                    className="text-right"
                                    style={{ marginBottom: '0px', color: '#f9b700' }}
                                  >
                                    {currency?.name ?? ''}
                                  </p>
                                  <h1
                                    className="text-right"
                                    style={{ marginBottom: '0px', color: '#f9b700' }}
                                  >
                                    {partner?.account?.equity === null
                                      ? '-'
                                      : partner?.account?.equity}
                                  </h1>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col> */}

                        {/* <Col>
                          <Card>
                            <CardBody style={{ padding: '20px 40px' }}>
                              <Row style={{ justifyContent: 'space-between' }}>
                                <div>
                                  <img
                                    src={MonthlyEarn}
                                    alt="Montly Earn"
                                    style={{ width: '60px' }}
                                  />
                                  <h6>Leverage</h6>
                                </div>

                                <div style={{ alignSelf: 'flex-end' }}>
                                  
                                  <h1 style={{ marginBottom: '0px', color: '#f9b700' }}>
                                    {partner?.account?.leverage
                                      ? `1 : ${partner?.account?.leverage}`
                                      : '-'}
                                  </h1>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col> */}
                      </Row>

                      {/* {achiementBox()} */}
                    </Col>
                  </Row>

                  <Row style={{ alignItems: 'flex-start' }} key={partner.id}>
                    <Col lg={7}>
                      <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                        <CardHeader className="bg-secondary" style={{ padding: 20 }}>
                          <h5 className="text-center">
                            {t('partner.IB')}-{partner?.ib_code}
                          </h5>
                        </CardHeader>
                        <CardBody style={{ padding: 16 }}>
                          {/* <p className="text-left" style={{ padding: '0.75rem' }}>
                            {t("partner.clientsAccounts")} : {partner?.clients?.length ?? 0}
                          </p> */}

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'flex-start'
                            }}
                          >
                            <div className="m-r-20">
                              {/* <h3 style={{ marginBottom: '0px' }}>{user?.name}</h3> */}
                              {/* <p>Lifetime Earnings</p> */}
                              {/* <h5 style={{ marginBottom: '0px' }}>Active clients : {myReferral?.my_successful_referrals}</h5> */}
                            </div>
                            {/* <div style={{ textAlign: 'center' }}>
                                    <h1 style={{ marginBottom: '0px' }}>{myReferral?.my_total_rewards} USD</h1>
                                    <p>Total earned amount</p>
                                </div> */}
                          </div>

                          {/* <Table borderless>
                            <tbody>
                              <tr>
                                <td className="text-left" width="50%">
                                  {t("partner.clientsAccounts")} : {partner?.clients?.length ?? 0}
                                </td>
                                <td>{`${partner?.account?.balance === null
                                  ? '-'
                                  : partner?.account?.balance?.toFixed(2)
                                  } ${currency?.name ?? ''}`}</td>
                              </tr>
                              <tr>
                                <td className="text-left" width="50%">
                                  {t("partner.balance")}: {`${partner?.account?.balance === null
                                    ? '-'
                                    : partner?.account?.balance?.toFixed(2)
                                    } ${currency?.name ?? ''}`}
                                </td>
                                <td>{`${partner?.account?.balance === null
                                  ? '-'
                                  : partner?.account?.balance?.toFixed(2)
                                  } ${currency?.name ?? ''}`}</td>
                              </tr>
                              <tr>
                                <td className="text-left">{t("partner.equity")} : {`${partner?.account?.equity === null ? '-' : partner?.account?.equity
                                  } ${currency?.name ?? ''}`}</td>
                                <td>{`${partner?.account?.equity === null ? '-' : partner?.account?.equity
                                  } ${currency?.name ?? ''}`}</td>
                              </tr>
                              <tr>
                                <td className="text-left">{t("partner.freeMargin")} :
                                {`${partner?.account?.free_margin < 0
                                    ? '-'
                                    : partner?.account?.free_margin
                                    } ${currency?.name ?? ''}`}
                                </td>
                                <td>{`${partner?.account?.free_margin < 0
                                  ? '-'
                                  : partner?.account?.free_margin
                                  } ${currency?.name ?? ''}`}</td>
                              </tr>
                              <tr>
                                <td className="text-left">{t("partner.leverage")} :
                                {partner?.account?.leverage < 0
                                    ? '-'
                                    : `1:${partner?.account?.leverage}`}
                                </td>
                                <td>
                                  {partner?.account?.leverage < 0
                                    ? '-'
                                    : `1:${partner?.account?.leverage}`}
                                </td>
                              </tr>
                            </tbody>
                          </Table> */}

                          <Row style={{ padding: 28, flexDirection: 'column' }}>
                            <h6>
                              {t('partner.InviteLink')}{' '}
                              <span
                                style={{ color: '#f9b700', cursor: 'pointer' }}
                                onClick={toggleTutorial}
                              >
                                <HelpCircle width="20px" />
                              </span>{' '}
                            </h6>
                            <CardBody
                              style={{
                                padding: '0',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Input
                                type="text"
                                style={{ fontSize: '14px', marginRight: '15px', width: '70%' }}
                                defaultValue={`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${partner?.referral_code}`}
                                disabled
                              />

                              <Modal
                                id="tutorialModal"
                                isOpen={tutorialModal}
                                toggle={toggleTutorial}
                              >
                                <ModalHeader toggle={toggleTutorial}>
                                  {t('partner.InviteLink')}
                                </ModalHeader>
                                <ModalBody></ModalBody>
                              </Modal>

                              <Row>
                                <Col className="text-left">
                                  <CopyToClipboard
                                    // text={`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${partner?.referral_code}`}
                                    text={`${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${partner?.referral_code}`}
                                  >
                                    <Button
                                      color="secondary"
                                      onClick={() => {
                                        toast.success(`${t('partner.linkCopiedToClipboard')}`, {
                                          position: toast.POSITION.BOTTOM_RIGHT
                                        })
                                      }}
                                    >
                                      {t('partner.copyLink')}
                                    </Button>
                                  </CopyToClipboard>
                                </Col>
                              </Row>
                            </CardBody>
                          </Row>

                          <Row
                            className="m-5"
                            style={{ marginTop: 12, justifyContent: 'space-between' }}
                          ></Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={5}>
                      <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                        <CardHeader className="bg-secondary" style={{ padding: 20 }}>
                          <h5 className="text-center">{t('pammSubsccription.termsConditions')} </h5>
                        </CardHeader>
                        <CardBody style={{ padding: 16 }}>
                          {/* <p className="text-left" style={{ padding: '0.75rem' }}>
                            {t("partner.clientsAccounts")} : {partner?.clients?.length ?? 0}
                          </p> */}
                          <p>
                            1. {t('partner.IncomeWill')}{' '}
                            <a
                              href="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/IB/IB+Agreement.pdf"
                              target="_blank"
                            >
                              {t('partner.RevenueDetails')}
                            </a>
                            .
                          </p>
                          <p>
                            2. {t('partner.TheWithdrawProcess')} <Link to="/financial/#withdrawal">{t('partner.Withdraw')}</Link> {t('partner.pageIfYou')}
                          </p>
                          <p>
                            3. {t('partner.ForTermsAndConditions')}{' '}
                            <a
                              href="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/IB/IB+Agreement.pdf"
                              target="_blank"
                            >
                              {t('partner.partnerAgreement')}
                            </a>
                            .
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col sm={12} md={6} lg={12} xl={6}>
                      <Card className="bg-dark">
                        <CardBody>
                          <h6>
                            {t("partner.inviteTradersToJoin")}
                          </h6>
                          <span>{t("partner.howItWorks")}</span>
                          <p>
                            {t("partner.copyYourUniqueURLAndShare")}
                          </p>
                          <p>
                            {t("partner.passYourUniqueIBCodeNumber")}
                          </p>
                        </CardBody>
                      </Card>
                    </Col> */}
                  </Row>
                </CardBody>
              </>
            )
          })
        ) : (
            <>
              {/* <Row>

                <Col>
                  <Card>
                    <CardHeader>
                      <h5>Advantage become IB</h5>
                    </CardHeader>
                    <CardBody>

                    </CardBody>
                  </Card>
                </Col>

                <Col>

                  <RegistrationForm />
                </Col>
              </Row> */}
            </>
          )
      ) : (
          <div style={{ textAlign: 'center' }}>
            <Spinner />
          </div>
        )}

      {selectedSetting !== null && selectedAccount !== null && (
        <Modal isOpen={showModal} backdrop={true} centered={true}>
          <ModalHeader toggle={toggleModal}>{settings[selectedSetting].title}</ModalHeader>
          <ModalBody>{settings[selectedSetting].cta}</ModalBody>
        </Modal>
      )}
    </>
  )
}

export default IbRoom
