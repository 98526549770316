import React from 'react'

import Breadcrumb from '../../layout/breadcrumb'
import PammManager from './PammManager/index'
import { Container } from "reactstrap";


const Pamm = () => {

    // const [showGraph, setShowGraph] = useState(true)

    return <>
        <Breadcrumb parent="PAMM" title="PAMM"></Breadcrumb>
        <Container>
            {/* {showGraph ? <PammManager/> : <PammManagerTable/>}  */}
            <PammManager />
        </Container>

    </>
}

export default Pamm