import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Grid } from "react-feather"

// import configDB from "../../../data/customizer/config"
import { TRIGGER_SIDEBAR } from "../../../redux/actionTypes"

const SidebarTrigger = () => {
  const isSidebarClosed = useSelector((state) => state.header.isSidebarClosed)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isSidebarClosed) {
      document.querySelector(".page-main-header").className = "page-main-header close_icon"
      document.querySelector(".main-nav").className = "main-nav close_icon "
      // + configDB.data.settings.sidebar_background_setting
    } else {
      document.querySelector(".page-main-header").className = "page-main-header"
      document.querySelector(".main-nav").className = "main-nav "
      // + configDB.data.settings.sidebar_background_setting
    }
  }, [isSidebarClosed])

  return (
    <div className="toggle-sidebar" onClick={() => dispatch({ type: TRIGGER_SIDEBAR })}>
      <Grid className="status_toggle middle" id="sidebar-toggle" />
    </div>
  )
}

export default SidebarTrigger
