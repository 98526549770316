import React, { useMemo, useState, useEffect } from 'react'
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import AccountService from '../../network/services/account'
import { useTranslation } from 'react-i18next'

const DemoForm = () => {
  const { t } = useTranslation()
  let history = useHistory()
  const dispatch = useDispatch()
  const [currency, setCurrency] = useState(null)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)
  const account = useSelector((state) => state.demo.account)
  const currencies = useSelector((state) => state.currency.currencies)
  const { register, handleSubmit, errors, formState } = useForm()
  const { isSubmitting } = formState

  // leverage
  const leverageOptions = useMemo(() => {
    let available = []
    let maxLeverage = account.leverage
    if (maxLeverage > 0) {
      if (maxLeverage >= 10) {
        available.push(10)
      }

      if (maxLeverage >= 50) {
        available.push(50)
      }

      if (maxLeverage >= 100) {
        available.push(100)
      }

      if (maxLeverage >= 200) {
        available.push(200)
      }

      if (maxLeverage >= 500) {
        available.push(500)
      }

      if (maxLeverage >= 1000) {
        available.push(1000)
      }
    }

    return available
  }, [account])

  // modal
  const [showModal, setShowModal] = useState(false)
  const toggle = () => {
    setShowModal(!showModal)
  }

  useEffect(() => {
    if (showModal !== true && result !== null) {
      navigate(`${process.env.PUBLIC_URL}/demo`)
    }
    // eslint-disable-next-line
  }, [showModal])

  const handleCreateAccount = async (data) => {
    // console.log(data)

    try {
      data['plan_id'] = account?.id
      data['currency_id'] = currency?.id

      if (data !== '') {
        const result = await AccountService.createDemo(data)
        // console.log(result)

        // success
        if (result?.account?.id) {
          setResult(result.account)
        }
      } else {
        errors.showMessages()
      }
    } catch (error) {
      setError(error.message)
    }
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  useEffect(() => {
    if (result != null) {
      toggle()
    }
    // eslint-disable-next-line
  }, [result])

  useEffect(() => {
    let selectedCurrency = currencies.find((e) => e.id === account.currency_id)
    setCurrency(selectedCurrency ?? '')
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Card >
        <CardBody>
          <Form className="theme-form" onSubmit={handleSubmit(handleCreateAccount)}>
            <Row>
              <Col>
                <FormGroup>
                  <Label>{t("demo.accountType")}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    name="type"
                    defaultValue={account?.name ?? ''}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>{t("demo.currency")}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    name="currency"
                    defaultValue={currency?.name ?? ''}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>{t("demo.leverage")}</Label>
                  <Input
                    type="select"
                    name="leverage"
                    className="form-control digits"
                    innerRef={register({ required: true })}
                  >
                    <option value="200">200</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>{t("demo.amount")}</Label>
                  <Input
                    type="select"
                    name="amount"
                    className="form-control digits"
                    innerRef={register({ required: true })}
                  >
                    <option value="1000">1000</option>
                    <option value="3000">3000</option>
                    <option value="5000">5000</option>
                    <option value="10000">10000</option>
                    <option value="30000">30000</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            {error != null && <p style={{ color: 'red' }}>{error}</p>}
            <Row className="m-t-15">
              <Col>
                <FormGroup className="mb-0" style={{ float: 'right' }}>
                  <Button color="primary" disabled={isSubmitting}>
                    {isSubmitting ? t("demo.loading") : t("demo.submit")}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <Modal isOpen={showModal} backdrop={true} centered={true}>
        <ModalHeader toggle={toggle}>MetaTrader 5</ModalHeader>
        <ModalBody>
          <div>
            <h6>{t("demo.demoAccountCreated")}</h6>
            <ul>
              <li>{t("demo.terminalLogin")} {result?.account_login}</li>
              <li>{t("demo.mainPassword")} {result?.password_main}</li>
              <li>{t("demo.investorPassword")} {result?.password_investor}</li>
              {/* <li>Phone Password: {result?.password_phone}</li> */}
              {/* <li>Server Name: {result?.plan?.group}</li> */}
              {/* <li>Account Type: {result?.account_name}</li> */}
              {/* <li>Account Currency: {result?.account_name}</li> */}
              <li>{t("demo.accountLeverage")} 1:{result?.plan?.leverage}</li>
              <li>{t("demo.balanceAmount")} {result?.balance?.toFixed(2)}</li>
            </ul>
          </div>
        </ModalBody>
        {/* <ModalFooter>
          <Button
            onClick={() => {
              navigate(`${process.env.PUBLIC_URL}/platform`)
            }}
          >
            Download MT5
          </Button>
        </ModalFooter> */}
      </Modal>
    </>
  )
}

export default DemoForm
