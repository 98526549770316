import React, { useEffect, useState, useRef } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { Row, Col, Card, CardHeader, Input } from 'reactstrap'

import AccountService from '../../../../network/services/account'
import HistoryFilters from './filters'
import { useTranslation } from 'react-i18next'

const HistoryTable = () => {
  const { t } = useTranslation()
  const columns = [
    {
      name: t('financial.ticket'),
      selector: 'id',
      sortable: true
    },
    {
      name: t('financial.date'),
      selector: 'created_at',
      sortable: true,
      width: '200px',
      format: (row) => {
        return moment(row.created_at).format('yyyy-MM-DD hh:mmA')
      }
    },
    {
      name: t('financial.type'),
      selector: 'txn_type',
      sortable: true,
      width: '200px',
      format: (row) => {
        switch (row.txn_type) {
          case 0:
            return t('financial.depositToMT5')
          case 1:
            return t('financial.withdrawFromMT5')
          case 2:
            return t('financial.transferOfMT5')
          case 4:
            return t('financial.bonus')
          default:
            return '-'
        }
      }
    },
    {
      name: t('financial.accountNumber'),
      selector: 'account.account_login',
      sortable: true,
      format: (row) => {
        if (row.txn_type == 2) {
          const accountLogin =
            row?.from_account_transfer?.from_account?.account_login ??
            row?.to_account_transfer?.from_account?.account_login
          if (accountLogin) {
            return accountLogin
          } else {
            return '-'
          }
        }
        return row?.account?.account_login ?? '-'
      }
    },
    {
      name: t('financial.toAccountNumber'),
      selector: 'account',
      sortable: true,
      format: (row) => {
        // console.log(row)
        switch (row.txn_type) {
          case 0:
          case 1:
          case 10:
          case 11:
            return '-'
          case 2: {
            const accountLogin =
              row?.from_account_transfer?.to_account?.account_login ??
              row?.to_account_transfer?.to_account?.account_login
            if (accountLogin) {
              return accountLogin
            } else {
              return '-'
            }
          }
          default:
            return '-'
        }
      }
    },
    {
      name: t('financial.wallet'),
      selector: 'wallet.id',
      sortable: true,
      format: (row) => {
        return row?.wallet?.id ?? '-'
      }
    },
    {
      name: t('financial.currency'),
      selector: 'currency_unit',
      sortable: true,
      format: (row) => {
        return row.currency_unit ?? '-'
      }
    },
    {
      name: t('financial.amount'),
      selector: 'amount',
      sortable: true,
      right: true,
      format: (row) => {
        return row.debit_amount > 0 ? row.debit_amount?.toFixed(2) : row.credit_amount?.toFixed(2)
      }
    },
    {
      name: t('financial.status'),
      selector: 'status',
      sortable: true,
      format: (row) => {
        switch (row.status) {
          case 0:
            return t('financial.pending')
          case 1:
            return t('financial.success')
          case 2:
            return t('financial.failed')
          case 3:
            return t('financial.rejected')
          default:
            return '-'
        }
      }
    },
    {
      name: t('financial.reason'),
      selector: 'reason',
      sortable: true,
      width: '400px',
      format: (row) => {
        switch (row.txn_type) {
          case 0:
            return row?.account_deposit?.reason ?? ''
          case 1:
            return row?.account_withdraw?.reason ?? ''
          case 2:
            return row?.from_account_transfer != null
              ? row?.from_account_transfer?.reason ?? ''
              : row?.to_account_transfer?.reason ?? ''
          default:
            return ''
        }
      }
    }
  ]

  const types = [
    {
      id: -1,
      name: t('financial.all')
    },
    {
      id: 0,
      name: t('financial.deposit')
    },
    {
      id: 1,
      name: t('financial.withdraw')
    },
    {
      id: 2,
      name: t('financial.transfer')
    }
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [sidebaron, setSidebaron] = useState(true)
  const [filters, setFilters] = useState(null)
  const [searchKeyword, setSearchKeyword] = useState('')
  const wrapperRef = useRef()

  const handlePageChange = async (page) => {
    setIsBusy(true)
    const result = await AccountService.getTransactions({ page: page, filters: filters })
    setData(result.account_transactions.data)
    setTotal(result.account_transactions?.meta?.total ?? 0)
    setIsBusy(false)
  }

  const handleFilter = async () => {
    setIsBusy(true)
    const result = await AccountService.getTransactions({
      filters: filters
    })
    setData(result.account_transactions.data)
    setTotal(result.account_transactions?.meta?.total ?? 0)
    setIsBusy(false)
  }

  const init = async () => {
    setIsBusy(true)
    const result = await AccountService.getTransactions()

    setTotal(result.account_transactions?.meta?.total ?? 0)
    setData(result.account_transactions?.data ?? [])
    setIsBusy(false)
  }

  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false)
      wrapperRef.current.classList.add('sidebaron')
    } else {
      setSidebaron(true)
      wrapperRef.current.classList.remove('sidebaron')
    }
  }

  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword)
    setFilters({
      ...filters,
      account_login: keyword
    })
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    handleFilter()
  }, [filters])

  return (
    <div className="product-wrapper" ref={wrapperRef}>
      <div className="product-grid" style={{ minHeight: '50vh' }}>
        <Row>
          <Col xl="3">
            <div className="product-sidebar">
              <div className="filter-section">
                <Card className="m-0">
                  <CardHeader>
                    <h6 className="mb-0 f-w-600">
                      {t('financial.filters')}
                      <span className="pull-right">
                        <i className="fa fa-chevron-down toggle-data" onClick={onClickFilter}></i>
                      </span>
                    </h6>
                  </CardHeader>
                  <div className="left-filter">
                    <div style={{ padding: 16 }} />
                    <HistoryFilters types={types} handleFilter={setFilters} filters={filters} />
                  </div>
                </Card>
              </div>
            </div>
          </Col>
          <Col xl="9" sm="12">
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Input
                className="form-control"
                type="text"
                placeholder={t('financial.searchAccount')}
                defaultValue={searchKeyword}
                onChange={(e) => handleSearchKeyword(e.target.value)}
              />
            </div>
          </Col>
        </Row>

        <div className="product-wrapper-grid" style={{ marginTop: 16 }}>
          <DataTable
            noHeader
            data={data}
            columns={columns}
            striped={true}
            center={true}
            pagination
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={total}
            onChangePage={handlePageChange}
            isBusy={isBusy}
          />
        </div>
      </div>
    </div>
  )
}

export default HistoryTable
