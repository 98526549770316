import client from '../request'

const getAll = (props = null) => {
    let page = props?.page ?? 1
    let limit = props?.limit ?? 10
    let status = props?.status ?? ''
    let fromDate = props?.fromDate ?? ''
    let toDate = props?.toDate ?? ''
    let name = props?.name ?? ''

    // console.log(`/my_referral_history?page=${page}&limit=${limit}&status=${status}&fromDate=${fromDate}&toDate=${toDate}`)
    return client.get(`/my_referral_history?page=${page}&limit=${limit}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&name=${name}`)

}

export default {
    getAll
}
