import React, { useState, useRef, useEffect } from "react"
import DataTable from "react-data-table-component"
import moment from "moment"
import { Link } from 'react-router-dom'

import PammSubscriptionService from '../../../../network/services/pammSubscription'
import { useTranslation } from 'react-i18next'

const PendingTable = () => {
  const { t } = useTranslation()
  const columns = [

    {
      name: t("pammSubsccription.managerName"),
      selector: "name",
      sortable: true,
      width: '200px',
      format: (row) => {
        return row.pammManager.name ?? "-"
      }
    },
    {
      name: t("pammSubsccription.date"),
      selector: "created_at",
      sortable: true,
      width: "200px",
      format: (row) => {
        return moment(row.created_at).format("yyyy-MM-DD")
      },
    },
    {
      name: t("pammSubsccription.account"),
      selector: "Account",
      sortable: true,
      width: '200px',
      format: (row) => {
        return row.account.account_login ?? "-"
      }
    },
    {
      name: t("pammSubsccription.statistic"),
      selector: "Statistic",
      sortable: true,
      format: (row) => {
        return <Link to={`pamm/${row.pamm_manager_id}/statistic`} style={{ color: '#204661' }}>VIEW</Link>
      }
    },
    {
      name: t("pammSubsccription.status"),
      selector: "status",
      sortable: true,
      width: '200px',
      format: (row) => {
        switch (row.status) {
          case 0:
            return 'Pending for subscribe'
          case 1:
            return t("pammSubsccription.active")
          case 2:
            return <span style={{ color: 'red' }}>Rejected</span>
          case 3:
            return t("pammSubsccription.pendingForUnsubscribe")
          default:
            return "-"
        }
      },
    },
    {
      name: "Reason",
      selector: "Reason",
      sortable: true,
      width: '800px',
      format: (row) => {
        return row?.reason ?? "-"
      }
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [filters, setFilters] = useState(null)
  const [searchKeyword, setSearchKeyword] = useState("")
  const wrapperRef = useRef()


  const init = async () => {
    setIsBusy(true)

    const result = await PammSubscriptionService.getAll()

    const subscribePendingReject = result?.pending?.concat(result?.rejected_subscriptions)

    const ActualPendingAndReject = subscribePendingReject?.filter(record => record?.status === 0 || (record?.status === 2 && record?.record_type === 2))

    setData(ActualPendingAndReject ?? [])
    setIsBusy(false)
  }


  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
  }, [filters])

  return (
    <div className="product-wrapper" ref={wrapperRef} >
      <div className="product-grid" >
        <div className="product-wrapper-grid" style={{ marginTop: 5, marginLeft: '15px', marginBottom: '20px' }}>

          <h4>Subscribe Record</h4>
          <DataTable
            noHeader
            data={data}
            columns={columns}
            striped={true}
            center={true}
            progressPending={isBusy}
          />
        </div>
      </div>
    </div>
  )
}

export default PendingTable
