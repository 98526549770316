import { SET_DEPOSIT_CURRENCY } from '../actionTypes'

const initial_state = {
    deposit_currency: []
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_DEPOSIT_CURRENCY:
      return { ...state, deposit_currency: action.deposit_currency }

    default:
      return { ...state }
  }
}
