import client from '../request'
import _ from 'lodash'

const getAll = (props) => {
  let filters = props?.filters ?? null
  let filterString = ''

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }

  return client.get(`/me/accounts?foo=bar${filterString}`)
}

const getLiveAndIbAccount = (props) => {

  return client.get(`/me/accounts?type=0,2`)
}

const getDemoAccount = (props) => {

  return client.get(`/me/accounts?type=1`)
}

const getContestAccount = (props) => {

  return client.get(`/me/accounts?type=3`)
}

const get = (id) => {
  return client.get(`/me/accounts/${id}`)
}

const getIdle = () => {
  return client.get(`/me/accounts/idle`)
}

const getTransactions = (props = null) => {
  let page = props?.page ?? 1
  let limit = props?.limit ?? 10
  let filters = props?.filters ?? null
  let filterString = ''

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }

  // only find live and ib accounts
  return client.get(
    `/me/account_transactions?page=${page}&limit=${limit}${filterString}&account_types[]=0&account_types[]=2`
  )
}

const getOpenOrders = (id, pagination) => {
  let page = pagination?.page ?? 1
  let limit = pagination?.limit ?? 10

  return client.get(`/me/accounts/${id}/mt5/open_orders?page=${page}&limit=${limit}`)
}

const getOpenOrdersCount = (id) => {
  return client.get(`/me/accounts/${id}/mt5/open_orders/count`)
}

const getAccountProfit = (id) => {
  return client.get(`/me/accounts/${id}/mt5/profit`)
}

const getClosedOrders = (id, pagination) => {
  let page = pagination?.page ?? 1
  let limit = pagination?.limit ?? 10

  return client.get(`/me/accounts/${id}/mt5/closed_orders?page=${page}&limit=${limit}`)
}

const deposit = (id, data) => {
  return client.post(`/me/accounts/${id}/deposits`, data)
}

const withdraw = (id, data) => {
  return client.post(`/me/accounts/${id}/withdraws`, data)
}

const changeMasterPassword = (id, data) => {
  return client.put(`/me/accounts/${id}/master_password`, data)
}

const changeInvestorPassword = (id, data) => {
  return client.put(`/me/accounts/${id}/investor_password`, data)
}

const changeLeverage = (id, data) => {
  return client.put(`/me/accounts/${id}/leverage`, data)
}

const changeStopRisk = (id, data) => {
  return client.put(`/me/accounts/${id}/stop_risk`, data)
}

const createDemo = (data) => {
  return client.post(`/me/accounts/demo`, data)
}

const createLive = (data) => {
  return client.post(`/me/accounts/live`, data)
}

const createCompetition = () => {
  return client.post(`/me/accounts/competition_live`)
}

const checkAccountCredit = (id) => {
  return client.get(`/me/accounts/${id}/mt5/credit`)
}

const upgradePackage = (id, data) => {
  return client.post(`/me/accounts/${id}/upgrade_package`, data)
}

export default {
  getAll,
  get,
  getIdle,
  deposit,
  withdraw,
  getOpenOrders,
  getClosedOrders,
  getOpenOrdersCount,
  getAccountProfit,
  createDemo,
  createLive,
  getTransactions,
  changeInvestorPassword,
  changeLeverage,
  changeMasterPassword,
  changeStopRisk,
  createCompetition,
  checkAccountCredit,
  upgradePackage,
  getContestAccount,
  getLiveAndIbAccount,
  getDemoAccount
}
