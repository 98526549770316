import React, { useState, useEffect } from 'react'
import {
  Container,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner
} from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import Breadcrumb from '../../layout/breadcrumb'
import ActiveLiveAccounts from './accounts'

import { useTranslation } from 'react-i18next'
import WalletService from '../../network/services/wallet'
import AccountService from '../../network/services/account'
import SweetAlert from 'sweetalert2'
import moment from 'moment'

const CompetitionAccounts = () => {
  const { t } = useTranslation()
  let history = useHistory()
  const dispatch = useDispatch()
  const verification = useSelector((state) => _.last(state.user.verifications))
  const competitions = useSelector((state) => state.event?.competitions.competitions)
  const [isContestRegistrationPeriod, setIsContestRegistrationPeriod] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  const competitionAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter((e) => e.account_type === 3)
    } else if (state.account.accounts?.length === 0) {
      return []
    } else {
      return null
    }
  })

  const [showResultModal, setShowResultModal] = useState(false)
  const [error, setError] = useState(null)
  const toggleResult = () => {
    setShowResultModal(!showResultModal)

    // if (showResultModal === true) {
    //   window.location.reload()
    // }
  }

  const [result, setResult] = useState(null)

  const registerCompetition = async () => {
    try {
      SweetAlert.fire({
        title: t('competition.Sure.CreateAccount'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('financial.confirm'),
        cancelButtonText: t('financial.cancel'),
        reverseButtons: true
      }).then(async (result) => {
        setIsLoading(true)
        if (result.value) {
          const CompetitionResult = await AccountService.createCompetition()
          if (CompetitionResult?.account?.id) {
            setResult(CompetitionResult.account)
          }
        }
        setIsLoading(false)
      })
    } catch (error) {
      setError(error.message)
    }
  }

  useEffect(() => {
    if (result != null) {
      toggleResult()
    }
    // eslint-disable-next-line
  }, [result])

  const init = async () => {
    const accountResponse = await AccountService.getContestAccount()
    const walletResponse = await WalletService.get()

    dispatch({ type: 'SET_TOTAL_DEPOSIT', totalDeposit: walletResponse.total_deposit })
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [showResultModal])

  useEffect(() => {
    const currentDateTime = moment().format('yyyy-MM-DD HH:mm:ss')

    const liveContestsInRegistrationPeriod = competitions.filter((item) => {
      if (
        moment(item.start_registration_date).format('yyyy-MM-DD HH:mm:ss') <= currentDateTime &&
        moment(item.end_registration_date).format('yyyy-MM-DD HH:mm:ss') >= currentDateTime &&
        item.account_type_joined === 3
      ) {
        return item
      }
    })
    if (liveContestsInRegistrationPeriod.length > 0) {
      setIsContestRegistrationPeriod(true)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Breadcrumb
        title={t('competition.ContestAccount')}
        action={
         
          (competitionAccounts?.length < 3 ? (
            <div className="pull-right">
              <Button
                disabled={isLoading || !isContestRegistrationPeriod}
                onClick={() => {
                  registerCompetition()
                }}
                color="primary"
              >
                {t('live.register')} {isLoading && <Spinner size="sm" />}
              </Button>
              {error && <p>{error}</p>}
            </div>
          ) : (
            ''
          ))
        }
      />
      <Container>
        {/* {verification?.status !== 1 ? (
          <>
            <Card>
              <CardBody>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <h6 style={{ margin: 0 }}>{t('live.pleaseVerifAccount')}</h6>
                  <Button
                    color="primary"
                    onClick={() => {
                      navigate(`${process.env.PUBLIC_URL}/account#verification`)
                    }}
                  >
                    {t('live.proceed')}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </>
        ) : (
          <ActiveLiveAccounts />
        )} */}
        <ActiveLiveAccounts />
      </Container>

      <Modal isOpen={showResultModal} backdrop={true} centered={true}>
        <ModalHeader toggle={toggleResult}>MetaTrader 5</ModalHeader>
        <ModalBody>
          <div>
            <h6>{t('competition.Created.ContestAccount')}</h6>
            <ul>
              <li>
                {t('live.terminalLogin')} {result?.account_login}
              </li>
              <li>
                {t('live.mainPassword')} {result?.password_main}
              </li>
              <li>
                {t('live.investorPassword')} {result?.password_investor}
              </li>
              <li>
                {t('live.accountLeverage')} 1:{result?.leverage}
              </li>
              <li>
                {t('live.balanceAmount')} {result?.balance?.toFixed(2)}
              </li>
            </ul>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CompetitionAccounts
