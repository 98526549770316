import React, { useEffect, useState } from 'react'
import {
    Col,
    Card,
    Row,
} from 'reactstrap'

import { ResponsiveLine } from '@nivo/line'

import { useParams } from 'react-router-dom'

import PammManagerStatistic from '../../../network/services/pammManagerStatistic'

import { ResponsiveBar } from '@nivo/bar'

import _ from 'lodash'
import './pammManagerStatistic.css'
import { useTranslation } from 'react-i18next'

const GrowthLineGraph = (props) => {
    const { t } = useTranslation()
    const [pammManagerDetails, setPammManagerDetails] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    let [dataTotalGrowth, setDataTotalGrowth] = useState(0)

    let totalGrowth = 0
    let [allGrowthData, seAllGrowthData] = useState([])

    let data = []

    useEffect(() => {
        totalGrowth = 0
        const search = async () => {
            setIsLoading(true)
            const result = await PammManagerStatistic.get(props.id)
            
            setPammManagerDetails(result?.details)


            let CalculateResult = result?.details?.performances?.map(performance => {
                // console.log('result?.details?.performances',result?.details?.performances)
                data.push({
                    "x": performance?.id,
                    "y": totalGrowth + performance?.monthly_growth
                })
                totalGrowth += performance?.monthly_growth
            })

            setDataTotalGrowth(totalGrowth)
            seAllGrowthData(data)
            
        }

        search()
    }, [])

    console.log('allGrowthData', allGrowthData)

    return (
        <>

            <h5 style={{ marginBottom: "1rem", textAlign: 'center' }}>{t('pamm.TotalGrowth')}: {dataTotalGrowth.toFixed(2)}% </h5>
            <ResponsiveLine
                colors='#f9b600'
                data={[{
                    "id": "profit",
                    "color": "hsl(294, 70%, 50%)",
                    "data": allGrowthData

                }]}

                curve="linear"
                type="linear"
                enableSlices='x'
                defs={[
                    {
                        id: 'gradientC',
                        type: 'linearGradient',
                        colors: [
                            { offset: 0, color: '#f7cc51' },
                            { offset: 20, color: 'inherit' },
                            { offset: 80, color: '#fff' },
                            { offset: 100, color: '#fff' },
                        ],
                    }
                ]}

                fill={[
                    { match: '*', id: 'gradientC' }
                ]}

                margin={{ top: 30, bottom: 20, left: 10, right: 10 }}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                enableGridX={false}
                enableGridY={false}

                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                enableArea={true}
                useMesh={true}
                legends={[]}
                isInteractive={true}
            />

        </>


    )
}

export default GrowthLineGraph
