import React, { useEffect } from 'react'
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'sweetalert2'

import WalletService from '../../../../network/services/wallet'
import TransferService from '../../../../network/services/transfer'
import { useTranslation } from 'react-i18next'

const TransferForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const wallets = useSelector((state) => state.wallet.wallets)
  const currencies = useSelector((state) => state.currency.currencies)
  const { register, handleSubmit, errors, reset } = useForm()

  const handleTransfer = async (data) => {
    SweetAlert.fire({
      title: t("financial.areYouSureTransfer"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t("financial.confirm"),
      cancelButtonText: t("financial.cancel"),
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        try {
          if (data !== '') {
            const result = await TransferService.create(data)
            // console.log(result)

            // success
            if (result?.transfer?.id) {
              reset()
              SweetAlert.fire({
                title: t("financial.transferSuccessfully"),
                text: t("financial.yourRequestWillBeReviewByAdmin"),
                icon: 'success'
              }).then(() => {
                window.location.reload()
              })
            }
          } else {
            errors.showMessages()
          }
        } catch (error) {
          SweetAlert.fire({
            title: error.message,
            icon: 'error'
          })
        }
      }
    })
  }

  const init = async () => {
    const walletResponse = await WalletService.get()
    // console.log(walletResponse)
    dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <Form className="theme-form" onSubmit={handleSubmit(handleTransfer)}>
      <Row>
        <Col>
          <FormGroup>
            <Label>{t("financial.walletCurrency")}</Label>
            <Input
              type="select"
              name="currency_id"
              className="form-control digits"
              innerRef={register({ required: true })}
            >
              {wallets?.length > 0 &&
                wallets.map((wallet) => {
                  const currency = currencies.find((e) => {
                    return e.id === wallet.currency_id
                  })

                  return (
                    <option key={wallet.id} value={wallet.currency_id}>{`${
                      wallet.currency?.name
                    } ${t("financial.availableBalance")} ${wallet.balance?.toFixed(2)} ${currency?.name}`}</option>
                  )
                })}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>{t("financial.transferAmount")}</Label>
            <Input
              className="form-control"
              type="number"
              name="amount"
              step=".01"
              innerRef={register({ required: true })}
            />
            <span style={{ color: 'red' }}>{errors.amount && t("financial.amountIsRequired")}</span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>{t("financial.toAccountEmail")}</Label>
            <Input
              className="form-control"
              type="email"
              name="to_email"
              innerRef={register({ required: true })}
            />
            <span style={{ color: 'red' }}>{errors.receiver && t("financial.emailIsRequired")}</span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-0">
            <Button color="success" className="mr-0 mt-2 pull-right">
              {t("financial.submit")}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default TransferForm
