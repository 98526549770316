import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Container, Spinner, Button } from 'reactstrap'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import SweetAlert from 'sweetalert2'

import Breadcrumb from '../../layout/breadcrumb'

import TransactionService from '../../network/services/transaction'
import { useTranslation } from 'react-i18next'

const PaymentPostback = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [success, setSuccess] = useState(true)
  const [transaction, setTransaction] = useState(null)
  let history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  const fetchTransaction = async (id) => {
    try {
      if (id != null) {
        const result = await TransactionService.get(id)
        // console.log(result)
        setTransaction(result.transaction ?? {})
      } else {
        setTransaction({})
      }
    } catch (error) {
      console.log(error)
      // SweetAlert.fire({
      //   title: error.message,
      //   icon: 'error'
      // })
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    // const status = params.get('status')
    // if (status?.toLowerCase() === 'success') {
    //   setSuccess(true)
    // } else {
    //   setSuccess(false)
    // }

    const id = params.get('transaction_id')
    fetchTransaction(id)
    // eslint-disable-next-line
  }, [location])

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  return (
    <>
      <Breadcrumb title={t('payment.payment')} />
      <Container className="col-lg-6">
        {success != null && transaction != null ? (
          !_.isEmpty(transaction) && success ? (
            <>
              <div style={{ backgroundColor: '#f8f8f8', textAlign: 'center' }}>
                <img
                  width="100px"
                  height="100px"
                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUwNy4yIDUwNy4yIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MDcuMiA1MDcuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGNpcmNsZSBzdHlsZT0iZmlsbDojMzJCQTdDOyIgY3g9IjI1My42IiBjeT0iMjUzLjYiIHI9IjI1My42Ii8+DQo8cGF0aCBzdHlsZT0iZmlsbDojMEFBMDZFOyIgZD0iTTE4OC44LDM2OGwxMzAuNCwxMzAuNGMxMDgtMjguOCwxODgtMTI3LjIsMTg4LTI0NC44YzAtMi40LDAtNC44LDAtNy4yTDQwNC44LDE1MkwxODguOCwzNjh6Ii8+DQo8Zz4NCgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTI2MCwzMTAuNGMxMS4yLDExLjIsMTEuMiwzMC40LDAsNDEuNmwtMjMuMiwyMy4yYy0xMS4yLDExLjItMzAuNCwxMS4yLTQxLjYsMEw5My42LDI3Mi44DQoJCWMtMTEuMi0xMS4yLTExLjItMzAuNCwwLTQxLjZsMjMuMi0yMy4yYzExLjItMTEuMiwzMC40LTExLjIsNDEuNiwwTDI2MCwzMTAuNHoiLz4NCgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTM0OC44LDEzMy42YzExLjItMTEuMiwzMC40LTExLjIsNDEuNiwwbDIzLjIsMjMuMmMxMS4yLDExLjIsMTEuMiwzMC40LDAsNDEuNmwtMTc2LDE3NS4yDQoJCWMtMTEuMiwxMS4yLTMwLjQsMTEuMi00MS42LDBsLTIzLjItMjMuMmMtMTEuMi0xMS4yLTExLjItMzAuNCwwLTQxLjZMMzQ4LjgsMTMzLjZ6Ii8+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg=="
                />
                <h3 style={{ color: '#34b97c' }}>{t('payment.thankYou')}</h3>
                {success == true ? (
                  <p style={{ fontWeight: 'bold' }}>{t('payment.yourPaymentSuccess')}</p>
                ) : (
                    <p style={{ fontWeight: 'bold' }}>{t('payment.yourPaymentFailed')}</p>
                  )}
              </div>
              <Card style={{ textAlign: 'center' }}>
                <CardBody style={{ color: '#69726E' }}>
                  <h4>{t('payment.transactionInformation')}</h4>
                  <p>
                    <b>{t('payment.ticket')}</b> {transaction?.id}
                  </p>
                  <p>
                    <b>{t('payment.amount')}</b> {transaction?.debit_amount?.toFixed(2)}
                  </p>
                  <p>
                    <b>{t('payment.currency')}</b> {transaction?.currency_unit}
                  </p>
                  <p>
                    <b>{t('payment.method')}</b> {transaction?.deposit?.payment_method}
                  </p>
                </CardBody>
              </Card>
              <div style={{ textAlign: 'center' }}>
                <p>
                  {t('payment.remark')} <br></br>
                  {t('payment.pleaseContact')} <b>support@tradehall.co</b>
                </p>
              </div>

              {user?.user?.is_funnel_completed ? (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    color="primary"
                    style={{ borderRadius: '12px 12px 12px 12px' }}
                    onClick={() => {
                      navigate(`${process.env.PUBLIC_URL}/dashboard`)
                    }}
                  >
                    {t('payment.Continue')}
                  </Button>
                </div>
              ) : (
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      color="primary"
                      style={{ borderRadius: '12px 12px 12px 12px' }}
                      onClick={() => {
                        navigate(`${process.env.PUBLIC_URL}/verify-details`)
                      }}
                    >
                      {t('payment.Continue')}
                    </Button>
                  </div>
                )}
            </>
          ) : (
              <>
                <div style={{ backgroundColor: '#f8f8f8', textAlign: 'center' }}>
                  <img
                    width="100px"
                    height="100px"
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUwNy4yIDUwNy4yIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MDcuMiA1MDcuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGNpcmNsZSBzdHlsZT0iZmlsbDojRjE1MjQ5OyIgY3g9IjI1My42IiBjeT0iMjUzLjYiIHI9IjI1My42Ii8+DQo8cGF0aCBzdHlsZT0iZmlsbDojQUQwRTBFOyIgZD0iTTE0Ny4yLDM2OEwyODQsNTA0LjhjMTE1LjItMTMuNiwyMDYuNC0xMDQsMjIwLjgtMjE5LjJMMzY3LjIsMTQ4TDE0Ny4yLDM2OHoiLz4NCjxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkY7IiBkPSJNMzczLjYsMzA5LjZjMTEuMiwxMS4yLDExLjIsMzAuNCwwLDQxLjZsLTIyLjQsMjIuNGMtMTEuMiwxMS4yLTMwLjQsMTEuMi00MS42LDBsLTE3Ni0xNzYNCgljLTExLjItMTEuMi0xMS4yLTMwLjQsMC00MS42bDIzLjItMjMuMmMxMS4yLTExLjIsMzAuNC0xMS4yLDQxLjYsMEwzNzMuNiwzMDkuNnoiLz4NCjxwYXRoIHN0eWxlPSJmaWxsOiNENkQ2RDY7IiBkPSJNMjgwLjgsMjE2TDIxNiwyODAuOGw5My42LDkyLjhjMTEuMiwxMS4yLDMwLjQsMTEuMiw0MS42LDBsMjMuMi0yMy4yYzExLjItMTEuMiwxMS4yLTMwLjQsMC00MS42DQoJTDI4MC44LDIxNnoiLz4NCjxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkY7IiBkPSJNMzA5LjYsMTMzLjZjMTEuMi0xMS4yLDMwLjQtMTEuMiw0MS42LDBsMjMuMiwyMy4yYzExLjIsMTEuMiwxMS4yLDMwLjQsMCw0MS42TDE5Ny42LDM3My42DQoJYy0xMS4yLDExLjItMzAuNCwxMS4yLTQxLjYsMGwtMjIuNC0yMi40Yy0xMS4yLTExLjItMTEuMi0zMC40LDAtNDEuNkwzMDkuNiwxMzMuNnoiLz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K"
                  />
                  <h3 style={{ color: '#f15349' }}>{t('payment.sorry')}</h3>
                  <p style={{ fontWeight: 'bold' }}>{t('payment.youPaymentIsUnsuccess')}</p>
                </div>
                <Card style={{ textAlign: 'center' }}>
                  <CardBody style={{ color: '#69726E' }}>
                    <div className="col-lg-6"></div>
                    <h4>{t('payment.invalidTransaction')}</h4>
                    <p style={{ fontWeight: 'bold' }}>{t('payment.pleaseContactCustomerSupport')}</p>
                  </CardBody>
                </Card>
                <div style={{ textAlign: 'center' }}>
                  <p>
                    {t('payment.remark')} <br></br>
                    {t('payment.pleaseContact')} <b>support@tradehall.co</b>
                  </p>
                </div>

                {user?.user?.is_funnel_completed ? (
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      color="primary"
                      style={{ borderRadius: '12px 12px 12px 12px' }}
                      onClick={() => {
                        navigate(`${process.env.PUBLIC_URL}/dashboard`)
                      }}
                    >
                      {t('payment.Continue')}
                    </Button>
                  </div>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        color="primary"
                        style={{ borderRadius: '12px 12px 12px 12px' }}
                        onClick={() => {
                          navigate(`${process.env.PUBLIC_URL}/verify-details`)
                        }}
                      >
                        {t('payment.Continue')}
                      </Button>
                    </div>
                  )}
              </>
            )
        ) : (
            <>
              <Card>
                <CardBody style={{ textAlign: 'center' }}>
                  <Spinner />
                </CardBody>
              </Card>
            </>
          )}
      </Container>
    </>
  )
}

export default PaymentPostback