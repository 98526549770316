import React, { useEffect, useState } from 'react'
import {
    Col,
    Card,
    Spinner,
    Input,
    CardBody
} from 'reactstrap'
import { useParams } from 'react-router-dom'

import PammManagerStatistic from '../../../network/services/pammManagerStatistic'

import { ResponsiveBar } from '@nivo/bar'

import _ from 'lodash'
import './pammManagerStatistic.css'
import { useTranslation } from 'react-i18next'

const GrowthGraph = () => {
    const { t } = useTranslation()
    const { id } = useParams()

    const [monthlyPerformance, setMonthlyPerformance] = useState([])
    const [growthResult, setGrowthResult] = useState([])

    const getCurrentYear = new Date().getFullYear()

    const userAvailableYears = []
    const [availableYears, setAvailableYears] = useState([])
    const [onClickMonth, setOnClickMonth] = useState(getCurrentYear)
    const [isLoading, setIsLoading] = useState(false)


    const init = async () => {

        try {
            // Reset the month Statistic
            const allMonthStatistic = [
                {
                    month: 'Jan',
                    Profit: 0,
                    color: '#398930'
                },
                {
                    month: 'Feb',
                    Profit: 0,
                    color: '#398930'
                },
                {
                    month: 'Mar',
                    Profit: 0,
                    color: '#398930'
                },
                {
                    month: 'Apr',
                    Profit: 0,
                    color: '#398930'
                },
                {
                    month: 'May',
                    Profit: 0,
                    color: '#398930'
                },
                {
                    month: 'Jun',
                    Profit: 0,
                    color: '#398930'
                },
                {
                    month: 'Jul',
                    Profit: 0,
                    color: '#398930'
                },
                {
                    month: 'Aug',
                    Profit: 0,
                    color: '#398930'
                },
                {
                    month: 'Sep',
                    Profit: 0,
                    color: '#398930'
                },
                {
                    month: 'Oct',
                    Profit: 0,
                    color: '#398930'
                },
                {
                    month: 'Nov',
                    Profit: 0,
                    color: '#398930'
                },
                {
                    month: 'Dec',
                    Profit: 0,
                    color: '#398930'
                }
            ]
            // Tranform the graph X axis from number to string
            const months = ['BLANK', "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            let growthData = []



            const search = async () => {
                setIsLoading(true)
                const result = await PammManagerStatistic.get(id)

                setGrowthResult(result?.growth)

                result.growth.forEach(item => {
                    for (let [key, value] of Object.entries(item)) {
                        userAvailableYears.push(parseInt(key))
                        growthData = []
                        if (Math.max(parseInt(key) && parseInt(key) == onClickMonth)) {

                            for (const property in value) {
                                growthData.push({
                                    "month": months[value[property].month.toString()],
                                    "Profit": value[property].monthly_growth.toString(),
                                    "color": value[property].monthly_growth < 0 ? '#ffc3c4' : '#398930'
                                })
                            }

                        }
                    }

                    // Merge the new user available with the allMonthStatistic
                    const userAllMonthStatistic = allMonthStatistic
                    const userAvailableMonthStatistic = growthData

                    const merged = _.merge(_.keyBy(userAllMonthStatistic, 'month'), _.keyBy(userAvailableMonthStatistic, 'month'));
                    const values = _.values(merged);

                    setMonthlyPerformance(values)
                })

                setAvailableYears(userAvailableYears.reverse())
                setIsLoading(false)
            }


            search()


        } catch (error) {
            window.location.reload()
        }

    }

    useEffect(() => {
        init()
    }, [onClickMonth])

    return (
        <>
            <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                {isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', top: '50%' }}><Spinner size="lg" color="warning" /></div> :
                    <CardBody className="m-3" style={{ padding: '0' }}>
                        <div style={{ textAlign: 'left' }}>
                            <div className="mt-3 ml-3" >
                                <Input
                                    style={{ width: '45%' }}
                                    type="select"
                                    className="form-control digits mb-2"
                                    value={onClickMonth}
                                    onChange={e => { setOnClickMonth(parseInt(e.target.value)) }}
                                >
                                    {/* check condition when user dont want year 2021 performance */}
                                    {availableYears.includes(new Date().getFullYear()) ? '' : <option value={new Date().getFullYear()} >Year - {new Date().getFullYear()}</option>}
                                    {/* {console.log(availableYears)} */}
                                    {availableYears.map((year, index) => {
                                        return <option key={index} value={year} >{t("pamm.year")} - {year}</option>
                                    })}
                                </Input>
                            </div>

                            <div style={{ height: '330px' }}>

                                <ResponsiveBar

                                    data={monthlyPerformance}
                                    keys={['Profit']}
                                    indexBy="month"
                                    tooltip={({ id, value }) => `${id} : ${value}%`}
                                    margin={{ top: 50, right: 0, bottom: 50, left: 0 }}
                                    padding={0.45}
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={monthlyPerformance.map(c => c.color)}
                                    colorBy="index"
                                    label={d => `${d.value}%`}
                                    labelFormat={d => <tspan fontSize={10} fontWeight={'bold'} y={-10}>{d}</tspan>}
                                    defs={[
                                        {
                                            id: 'dots',
                                            type: 'patternDots',
                                            background: 'inherit',
                                            color: '#f9b700',
                                            size: 4,
                                            padding: 1,
                                            stagger: true
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: '#f9b700',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10
                                        }
                                    ]}
                                    // borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 0,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: t("pamm.Month"),
                                        legendPosition: 'middle',
                                        legendOffset: 32
                                    }}
                                    axisLeft={null}
                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'top-right',
                                            direction: 'row',
                                            justify: false,
                                            translateX: 30,
                                            translateY: -50,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 20,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolSize: 30,
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    enableGridY={false}
                                />
                            </div>
                            <div style={{ textAlign: 'center', color: '#828282' }}>
                                <p>{t("pamm.pastPerformanceIsNotIndicative")}</p>
                            </div>
                        </div>
                    </CardBody>
                }
            </Col>
        </>

    )
}

export default GrowthGraph
