
let vePakage

export default vePakage = {
    packageA: {
        benefits: [
            
                // name: '个人专属顾问',
                // name: 'vePackageInfo.ExclusivePersonalConsultant',
                // name: 'live.10ExtraCreditBonus',
                // included: 'yes'
            
            {
                name: 'live.BeginnerGuideToForex',
                included: 'yes'
            }
            ,
            {
                name: 'live.MVPCourse',
                included: 'yes'
            }



        ]
    },

    packageB: {
        benefits: [
            
                // name: '个人专属顾问',
                // name: 'live.30ExtraCreditBonus',
                // included: 'yes'
            
            {
                name: 'live.VTradeSystemVideoCourse',
                included: 'yes'
            }

        ]
    },

    packageC: {
        benefits: [
            
                // name: '个人专属顾问',
                // name: 'live.50ExtraCreditBonus',
                // included: 'yes'
            
            {
                // name: '个人专属顾问',
                name: 'live.OnlineTutorialIn',
                included: 'yes'
            }

        ]
    }

}


