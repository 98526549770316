import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Link } from 'react-router-dom'

import Loader from "./layout/loader";
import Header from "./layout/header";
import Sidebar from "./layout/sidebar";
import Footer from "./layout/footer";

import ReactGA, { ga } from 'react-ga'

import FeedbackService from './network/services/feedback'
import SweetAlert from 'sweetalert2'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader, Form, Row, Col, FormGroup, Input, Button, Label, Spinner, Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next'

import StarRatings from 'react-star-ratings';
import feedback from "./network/services/feedback";



const App = ({ children }) => {



  const [modal, setModal] = useState(false);
  const toggle = () => {
    setRating()
    setModal(!modal);
  }

  const { t } = useTranslation()
  const { register, handleSubmit, errors, reset } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [isEmpty, setIsEmpty] = useState(false)

  const [rating, setRating] = useState()

  // Google analysis
  useEffect(() => {
    if (window.location.hostname === "secure.tradehall.co") {
      ReactGA.initialize('UA-178645755-2')
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }, [])

  // Khee Hao Local google testing
  // useEffect(() => {
  //   ReactGA.initialize('UA-186987014-1')
  //   ReactGA.pageview(window.location.pathname + window.location.search)
  // })


  const submitFeedback = async (data) => {
    setIsLoading(true)
    try {

      if (data !== '' && rating >= 1) {
        const result = await FeedbackService.createCrmFeedback({
          feedback: message,
          rating: rating
        })

        if (result) {
          setRating()
          setIsLoading(false)
          reset()
          SweetAlert.fire({
            title: t("feedback.ThankYouForSending"),
            text: t("feedback.WeSincerelyAppreciate"),
            icon: 'success'
          }).then(() => {
            toggle()
            setIsLoading(false)
          })
        }
      } else {
        setRating(0)
        errors.showMessages()
        setIsLoading(false)
        setIsEmpty(true)
      }
    } catch (error) {
      setRating(0)
      setIsEmpty(true)
      setIsLoading(false)
    }
  }



  return (
    <>
      <Loader />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">

        {
          window.location.toString().includes("verify-details") ||
            window.location.toString().includes("create-first-mt5") ||
            window.location.toString().includes("verify-KYC") ||
            window.location.toString().includes("chapters")
            ? '' : <Header />}

        <div className="page-body-wrapper sidebar-icon" >
          {
            window.location.toString().includes("verify-details") ||
              window.location.toString().includes("create-first-mt5") ||
              window.location.toString().includes("verify-KYC") ||
              window.location.toString().includes("chapters") ? '' :

              <>
                <Sidebar />
              </>

          }




          {
            window.location.toString().includes("verify-details") ||
              window.location.toString().includes("create-first-mt5") ||
              window.location.toString().includes("verify-KYC") ||
              window.location.toString().includes("chapters") ?
              <div >
                {children}
                <div
                  onClick={toggle}
                  style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '30px',
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '50%',
                    border: '1px solid black',
                    cursor: 'pointer',
                    zIndex: '1'
                  }} >
                  <img src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/survey.svg' style={{ width: '32px', margin: '0 auto' }} alt='feedbackIcon' />

                  <Row>
                    <Modal centered isOpen={modal} toggle={toggle} >
                      <ModalHeader toggle={toggle} style={{ backgroundColor: '#f9b600', color: 'white' }}>
                        <h4>{t("feedback.YourOpinionMatters")}</h4>
                        <p>{t("feedback.YouWillHelpOur")}</p>
                      </ModalHeader>
                      <ModalBody>
                        <Form className="theme-form" onSubmit={handleSubmit(submitFeedback)}>
                          {/* <Form className="theme-form"> */}
                          <Row>
                            <Col>
                              <Label>{t("feedback.OverallExperience")}</Label>
                              <FormGroup>

                                <StarRatings
                                  rating={rating}
                                  starRatedColor="#f9b600"
                                  starHoverColor="#f9b600"
                                  changeRating={(value) => {

                                    setRating(value)
                                  }}
                                  numberOfStars={5}
                                  name='rating'
                                  starDimension='35px'
                                  innerRef={register({ required: true })}
                                  required
                                />
                                <br /><span style={{ color: 'red' }}>{rating === 0 && t("feedback.CannotLeaveRating")}</span>

                              </FormGroup>
                              <FormGroup>
                                <Input
                                  type="textarea"
                                  name="feedback"
                                  placeholder={t("feedback.WriteFeedback")}
                                  innerRef={register({ required: true })}
                                  style={{ height: '100px' }}
                                  onChange={(e) => { setMessage(e.target.value) }}
                                />
                                <span style={{ color: 'red' }}>{errors.feedback && t("feedback.CannotLeaveMessage")}</span>
                              </FormGroup>
                            </Col>
                          </Row>


                          <Row>
                            <Col className='text-right'>
                              <FormGroup className="mb-0">
                                <Button color="primary" className="mt-1" style={{ fontSize: '12px' }} disabled={isLoading}>
                                  {t("feedback.Send")}
                                </Button>
                                {isLoading && <Spinner />}

                              </FormGroup>

                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </Row>
                </div>
              </div>
              :
              <div className="page-body" style={{ position: 'relative' }}>
                {children}
                <div
                  onClick={toggle}
                  style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '30px',
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '50%',
                    border: '1px solid black',
                    cursor: 'pointer',
                    zIndex: '1'
                  }} >
                  <img src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/survey.svg' style={{ width: '32px', margin: '0 auto' }} alt='feedbackIcon' />

                  <Row>
                    <Modal centered isOpen={modal} toggle={toggle} >
                      <ModalHeader toggle={toggle} style={{ backgroundColor: '#f9b600', color: 'white' }}>
                        <h4>{t("feedback.YourOpinionMatters")}</h4>
                        <p>{t("feedback.YouWillHelpOur")}</p>
                      </ModalHeader>
                      <ModalBody>
                        <Form className="theme-form" onSubmit={handleSubmit(submitFeedback)}>
                          {/* <Form className="theme-form"> */}
                          <Row>
                            <Col>
                              <Label>{t("feedback.OverallExperience")}</Label>
                              <FormGroup>

                                <StarRatings
                                  rating={rating}
                                  starRatedColor="#f9b600"
                                  starHoverColor="#f9b600"
                                  changeRating={(value) => {

                                    setRating(value)
                                  }}
                                  numberOfStars={5}
                                  name='rating'
                                  starDimension='35px'
                                  innerRef={register({ required: true })}
                                  required
                                />
                                <br /><span style={{ color: 'red' }}>{rating === 0 && t("feedback.CannotLeaveRating")}</span>

                              </FormGroup>
                              <FormGroup>
                                <Input
                                  type="textarea"
                                  name="feedback"
                                  placeholder={t("feedback.WriteFeedback")}
                                  innerRef={register({ required: true })}
                                  style={{ height: '100px' }}
                                  onChange={(e) => { setMessage(e.target.value) }}
                                />
                                <span style={{ color: 'red' }}>{errors.feedback && t("feedback.CannotLeaveMessage")}</span>
                              </FormGroup>
                            </Col>
                          </Row>


                          <Row>
                            <Col className='text-right'>
                              <FormGroup className="mb-0">
                                <Button color="primary" className="mt-1" style={{ fontSize: '12px' }} disabled={isLoading}>
                                  {t("feedback.Send")}
                                </Button>
                                {isLoading && <Spinner />}

                              </FormGroup>

                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </Row>
                </div>
              </div>
          }



          {
            window.location.toString().includes("verify-details") ||
              window.location.toString().includes("create-first-mt5") ||
              window.location.toString().includes("verify-KYC") ||
              window.location.toString().includes("chapters") ? '' : <Footer />}
        </div>

      </div>
      <ToastContainer />

    </>
  );
};

export default App;
