import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import SignInForm from './sign_in'
import { useTranslation } from 'react-i18next'

import SettingService from '../../network/services/setting'
import 'animate.css'
import LanguageIcon from '../../language.png'

const Authentication = () => {

  const [logo, setLogo] = useState(null)
  const { t , i18n } = useTranslation()

  const [languageSelected, setLanguageSelected] = useState('en')

  const language = sessionStorage.getItem('language')

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    sessionStorage.setItem('language', language)
  }

  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false)

  const languageToggle = () => setLanguageDropdownOpen((prevState) => !prevState)


  const init = async () => {
    try {
      const result = await SettingService.getAll()
      if (result.settings) {
        let logo2 = result.settings.find((s) => {
          return s.handle === 'crm_logo_url'
        })

        setLogo(logo2 ?? {})
      } else {
        setLogo({})
      }
    } catch (error) {
      setLogo({})
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      {logo != null ? (
        <Container
          style={{
            maxWidth: '100%',
            // backgroundColor: '#f8f8f8',
            backgroundImage: "url(https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/LoginPageBG.svg)",
            backgroundSize: 'cover'
          }} >

          <Dropdown
                isOpen={languageDropdownOpen}
                onMouseLeave={() => setLanguageDropdownOpen(false)}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '30px',
                  paddingRight: '30px',
                  backgroundColor: 'transparent',
                  zIndex:'6'
                }}
              >
                <DropdownToggle
                  onMouseEnter={() => setLanguageDropdownOpen(true)}
                  id="langugeDropdown"
                >
                  <img
                    src={LanguageIcon}
                    alt="languageIcon"
                    width="18px"
                    height="18px"
                    className="m-r-5"
                  />
                  {language === 'en' && 'EN'}
                  {language === 'zh' && 'ZH'}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => changeLanguage('en')}>English</DropdownItem>
                  <DropdownItem onClick={() => changeLanguage('zh')}>中文简体</DropdownItem>
                </DropdownMenu>
              </Dropdown>
          <Row className="h-100-d-center m-0" style={{ paddingBottom: "0px" }} >
            <Col lg="12" id="login-col" style={{ textAlign: 'left', marginRight:'0px', marginTop:'25px'}}>
              <SignInForm logo={logo} />
            </Col>
          </Row>
        </Container>
      ) : (
          <>
            <Container
              style={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spinner color="warning" />
            </Container>
          </>
        )}
    </>
  )
}

export default Authentication


