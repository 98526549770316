import React, { useState, useEffect } from 'react'
import { Row, Form, Col, Card, CardBody, Button, Spinner } from 'reactstrap'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import StepZilla from 'react-stepzilla'
import SweetAlert from 'sweetalert2'
import { useHistory } from 'react-router-dom'

import SelectAccount from './account'
import SelectCurrency from './currency'
import ChooseAmount from './amount'
import WalletService from '../../../../network/services/wallet'
import AccountService from '../../../../network/services/account'
import { useTranslation } from 'react-i18next'
import account from '../../../../network/services/account'

const MT5Deposit = ({ setOption }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const deposit = useSelector((state) => state.deposit)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const liveAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter((e) => e.account_type !== 1)
    } else if (state.account.accounts?.length === 0) {
      return []
    } else {
      return null
    }
  })
  const methods = useForm()
  const [stage, setStage] = useState(0)


  // use redux for payment_id & wallet_id
  // because form doesn't work with stepzilla
  const handleDeposit = async (data) => {
    const selectedAccount = liveAccounts.filter(account => account?.id === deposit.mt5.account)
    if(selectedAccount[0].balance < 0){
      SweetAlert.fire({
        title: `<div style="color:red">${t("financial.YourAccountBalanceisNegative")}</div>`, 
        html: `${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
                <br/>
                <br/>
                <div>
                          <p style="margin-bottom:0px;"
                          >*${t("financial.KindlyTakeNote")}*</p>
                          <p>
                              1. ${t("financial.TheBalanceAfterYouDepositWillNot")}<br/>${t("financial.beTheSameAsYourDepositAmount")}
                              <br/>
                              <br/>
                              <span style="color:#f9b600">${t("financial.ForExample")} </span> $${selectedAccount[0].balance}(${t("financial.ToAccountBalance")}) + <br/>$${data.amount} (${t("financial.DepositAmount")}) = $${(parseFloat(selectedAccount[0].balance)  + parseFloat (data.amount)).toFixed(2) } (${t("financial.ResultingBalance")})
                          </p>
                        </div>
                `,
        input: 'text',
        inputValue: '',
        inputPlaceholder: '',
        inputValidator: (result) => {
          if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
            return t("financial.PleaseEnterThe")
          }
        },
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t("financial.confirm"),
        cancelButtonText: t("financial.cancel"),
        reverseButtons: true,

      }).then(async (result) => {
        if(result.value){
          SweetAlert.fire({
              title: t('financial.areYouSureDeposit'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: t('financial.confirm'),
              cancelButtonText: t('financial.cancel'),
              reverseButtons: true
            }).then(async (result) => {
              if (result.value) {
                setIsSubmitting(true)
                try {
                  data['account_id'] = deposit.mt5.account
                  data['wallet_id'] = deposit.mt5.wallet
                  if (!data.promotion_id) {
                    data.promotion_id = null
                  } else {
                    data.promotion_id = parseInt(data.promotion_id)
                  }
                  //negative balance check
                  if (data !== '') {
                    let result = await AccountService.deposit(deposit.mt5.account, {
                      account_id: data.account_id ,
                      amount: data.amount,
                      promocode: data.promocode,
                      promotion_id: data.promotion_id,
                      wallet_id: data.wallet_id,
                      negative_balance_deposit: true
                    })

                    // success
                    if (result?.transaction?.id) {
                      methods.reset()
        
                      SweetAlert.fire({
                        title: t('financial.depositSuccessfully'),
                        html: `Kindly check the deposit amount in MT5 account`,
                        icon: 'success'
                      }).then(() => {
                        window.location.href = `${process.env.PUBLIC_URL}/live`
                        setIsSubmitting(false)
                      })
                    }
                  } else {
                    methods.errors.showMessages()
                    setIsSubmitting(false)
                  }
                } catch (error) {
                  // SweetAlert.fire({
                  //   title: error.message,
                  //   icon: 'error'
                  // })
                  setIsSubmitting(false)
                  const errorCode = error.code
                  const errorTranslate = error.translate_params
                  SweetAlert.fire({
                    title: t(errorCode, errorTranslate),
                    icon: 'error'
                  })
                }
              }
            })
        }
      })
    }else{
      SweetAlert.fire({
          title: t('financial.areYouSureDeposit'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: t('financial.confirm'),
          cancelButtonText: t('financial.cancel'),
          reverseButtons: true
        }).then(async (result) => {
          if (result.value) {
            setIsSubmitting(true)
            try {
              data['account_id'] = deposit.mt5.account
              data['wallet_id'] = deposit.mt5.wallet
              if (!data.promotion_id) {
                data.promotion_id = null
              } else {
                data.promotion_id = parseInt(data.promotion_id)
              }
              //checked positive balance
              if (data !== '') {

                let result = await AccountService.deposit(deposit.mt5.account,{
                  account_id: data.account_id ,
                  amount: data.amount,
                  promocode: data.promocode,
                  promotion_id: data.promotion_id,
                  wallet_id: data.wallet_id,
                  negative_balance_deposit: false
                })
    
                // success
                if (result?.transaction?.id) {
                  methods.reset()
    
                  SweetAlert.fire({
                    title: t('financial.depositSuccessfully'),
                    html: `Kindly check the deposit amount in MT5 account`,
                    icon: 'success'
                  }).then(() => {
                    window.location.href = `${process.env.PUBLIC_URL}/live`
                    setIsSubmitting(false)
                  })
                }
              } else {
                methods.errors.showMessages()
                setIsSubmitting(false)
              }
            } catch (error) {
              // SweetAlert.fire({
              //   title: error.message,
              //   icon: 'error'
              // })
              setIsSubmitting(false)
              const errorCode = error.code
              const errorTranslate = error.translate_params
              SweetAlert.fire({
                title: t(errorCode, errorTranslate),
                icon: 'error'
              })
            }
          }
        })
    }
    // console.log('data', data)
    // SweetAlert.fire({
    //   title: t('financial.areYouSureDeposit'),
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonText: t('financial.confirm'),
    //   cancelButtonText: t('financial.cancel'),
    //   reverseButtons: true
    // }).then(async (result) => {
    //   if (result.value) {
    //     setIsSubmitting(true)
    //     try {
    //       data['account_id'] = deposit.mt5.account
    //       data['wallet_id'] = deposit.mt5.wallet
    //       if (!data.promotion_id) {
    //         data.promotion_id = null
    //       } else {
    //         data.promotion_id = parseInt(data.promotion_id)
    //       }
    //       if (data !== '') {
    //         let result = await AccountService.deposit(deposit.mt5.account, data)

    //         // success
    //         if (result?.transaction?.id) {
    //           methods.reset()

    //           SweetAlert.fire({
    //             title: t('financial.depositSuccessfully'),
    //             html: `Kindly check the deposit amount in MT5 account`,
    //             icon: 'success'
    //           }).then(() => {
    //             window.location.href = `${process.env.PUBLIC_URL}/live`
    //             setIsSubmitting(false)
    //           })
    //         }
    //       } else {
    //         methods.errors.showMessages()
    //         setIsSubmitting(false)
    //       }
    //     } catch (error) {
    //       // SweetAlert.fire({
    //       //   title: error.message,
    //       //   icon: 'error'
    //       // })
    //       setIsSubmitting(false)
    //       const errorCode = error.code
    //       const errorTranslate = error.translate_params
    //       SweetAlert.fire({
    //         title: t(errorCode, errorTranslate),
    //         icon: 'error'
    //       })
    //     }
    //   }
    // })
  }


  const init = async () => {
    const walletResponse = await WalletService.get()
    dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })

    const accountResponse = await AccountService.getAll()
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {liveAccounts != null ? (
        liveAccounts?.length > 0 ? (
          <>
            <Card>
              <CardBody>
                <Row>
                  <Col
                    sm={12}
                    md={4}
                    className={`u-pearl ${stage === 0 && 'current'} ${stage > 0 && 'done'}`}
                  >
                    <span className="u-pearl-number">1</span>
                    <span className="u-pearl-title">{t('financial.selectYourMT5Account')}</span>
                  </Col>
                  <Col
                    sm={12}
                    md={4}
                    className={`u-pearl ${stage === 1 && 'current'} ${stage > 1 && 'done'}`}
                  >
                    <span className="u-pearl-number">2</span>
                    <span className="u-pearl-title">{t('financial.chooseWalletCurrency')}</span>
                  </Col>
                  <Col sm={12} md={4} className={`u-pearl ${stage === 2 && 'current'}`}>
                    <span className="u-pearl-number">3</span>
                    <span className="u-pearl-title">{t('financial.chooseAmount')}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <FormProvider {...methods}>
              <Form className="theme-form" onSubmit={methods.handleSubmit(handleDeposit)}>
                <StepZilla
                  steps={[
                    {
                      name: t('financial.Step1'),
                      component: <SelectAccount />
                    },
                    {
                      name: t('financial.Step2'),
                      component: <SelectCurrency />
                    },
                    {
                      name: t('financial.Step3'),
                      component: <ChooseAmount isSubmitting={isSubmitting} />
                    }
                  ]}
                  showSteps={false}
                  onStepChange={(index) => {
                    setStage(index)
                  }}
                  backButtonText={t('financial.Previous')}
                  nextButtonText={t('financial.Next')}
                />
              </Form>
            </FormProvider>
          </>
        ) : (
            <>
              <Card>
                <CardBody>
                  <p>{t('financial.noActiveAccounts')}</p>
                  <Button onClick={() => navigate(`${process.env.PUBLIC_URL}/live`)}>
                    {t('financial.registerOneHere')}
                  </Button>
                </CardBody>
              </Card>
            </>
          )
      ) : (
          <div style={{ textAlign: 'center' }}>
            <Spinner color="warning" />
          </div>
        )}

      {stage === 0 && liveAccounts && (
        <Button
          color="primary"
          onClick={() => {
            setOption(null)
          }}
        >
          {t('financial.back')}
        </Button>
      )}

      {/* Add gap */}
      <div style={{ padding: 24, clear: 'both' }} />
    </>
  )
}

export default MT5Deposit
