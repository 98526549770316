import React, { Fragment, useState, useEffect } from "react"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"

import AccountService from "../../network/services/account"
import { useTranslation } from 'react-i18next'

const ColorDropdown = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedAccount = useSelector((state) => state.monitoring.account)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [accounts, setAccounts] = useState()
  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const init = async () => {
    // live accounts
    const result = await AccountService.getAll({ filters: { account_type: 0 } })
    setAccounts(result.accounts)
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="dropdown-basic">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="secondary" style={{ minWidth: 200 }}>
          {selectedAccount?.account_login ?? t("monitoring.selectAccountNumber")}
        </DropdownToggle>
        <DropdownMenu className="dropdown-content">
          {accounts?.length > 0 &&
            accounts.map((account, index) => {
              return (
                <Fragment key={account.id}>
                  <DropdownItem
                    onClick={() => {
                      dispatch({ type: "SET_MONITORING_ACCOUNT", account: account })
                    }}
                  >
                    {account.account_login}
                  </DropdownItem>
                  {index !== accounts.length - 1 && <DropdownItem divider />}
                </Fragment>
              )
            })}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default ColorDropdown
