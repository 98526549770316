import React from 'react'

function pammAgree() {
    return (
        <>
            <h6 style={{ fontWeight: '400' }}>1. Introduction</h6>
            <p>
                1.1 These Terms, together with the LPOA, contain all the provisions relating
                to the provision of PAMM Account service. Each client or potential client,
                who wants to become an Investor or PAMM Manager assumes full
                obligation in acquaintance and understanding the content of these
                documents before using the service.
            </p>
            <p>
                1.2 It is acknowledged that the Customer’s registration as the PAMM
                assumes that the Customer agrees to these Terms. Therefore, by
                registering as a PAMM Manager or Investor client accepts the Offer with
                the Broker.
            </p>
            <p>
                1.3 It is acknowledged that the PAMM Account service is not for the investor
                trust management service for managing funds. PAMM Account service is
                intended only for investor's possibility follows the trading strategies,
                which was selected by PAMM Manager of specific PAMM Account
            </p>
            <p>
                1.4 Each decision regarding the acceptance or rejection of any particular
                offer is a private choice of potential investors. The Broker on its part has
                no effect on these decisions of potential investors.
            </p>

            <br />

            <h6 style={{ fontWeight: '400' }}>2. Glossary of Terms</h6>
            <p>
                2.1 Unless indicated to the contrary, the terms used in the PAMM terms Offer
                from the list below will have the following meanings and may be used
                both as in singular as plural.
            </p>
            <p>
                2.1.1 Investor means Client who invested funds to one or more PAMM
                accounts;
            </p>
            <p>
                2.1.2 PAMM Manager is Customer under the control of which is one or
                more PAMM Accounts;
            </p>
            <p>
                2.1.3 PAMM Manager’s Offer is set of parameters for the commercial
                terms that are offered by the PAMM Manager for potential
                investors;
            </p>
            <p>
                2.1.4 PAMM Manager’s Capital – is funds deposited and managed by the
                PAMM Manager in the composition of the funds of investors who
                invested funds within the PAMM Account;
            </p>
            <p>
                2.1.5 The minimum initial investment is a condition of the Manager’s
                Offer for the minimum acceptable amount of the initial deposit on
                PAMM Account;
            </p>
            <p>
                2.1.6 PAMM Account is account which is managed by the Manager
                under the Terms of the Manager’s Offer and combines the funds of
                all investors that accepted the terms of the PAMM Manager’s Offer;
            </p>
            <p>
                2.1.7 Rollover is a regular event, moment of approach of which is
                determined in accordance with these Terms and Conditions, used
                for collecting and updating statistics on the PAMM Account for the
                calculation and paying of remuneration to the PAMM Manager and
                processing of requests for deposit / withdrawal of funds;
            </p>
            <p>
                2.1.8 Terms means these PAMM Accounts Terms;
            </p>
            <p>
                2.1.9 Other terms that were not defined above have the meanings
                assigned to them in the LPOA.
            </p>

            <br />

            <h6 style={{ fontWeight: '400' }}>3. PAMM ACCOUNT</h6>
            <p>
                3.1 The mission of the PAMM Account service is to provide investors the
                possibility of transferring rights to the PAMM Manager (as its agent) for
                making trading operations on PAMM Account in Investors’ interests.
            </p>
            <p>
                3.2 All trading operations without exceptions on investment account are
                made only by Managing Trader. In accordance with this, the investor can
                not have any claims to the Broker relatively to trade on the investment
                account.
            </p>

            <br />

            <h6 style={{ fontWeight: '400' }}>4. PAMM MANAGER’S OFFER</h6>
            <p>
                4.1 In order to inform investors about terms of the PAMM Account, PAMM
                Manager is obliged to publish the relevant PAMM-Manager’s Offer. Based
                on the parameters specified in the PAMM Manager’s Offer, the Company
                calculates the remuneration payable to PAMM Manager and other terms
                and conditions applicable to each specific PAMM Account.
            </p>
            <p>
                4.2 Acceptance of the PAMM Manager’s Offer by Investor is not a contract
                between the investor and the PAMM Manager. Acceptance of the
                PAMM-Manager’s Offer represents the desire of investors to the Broker
                to follow a trading strategy of the specific PAMM Account. Authorizes
                the Broker to automatically execute of transactions on the PAMM Account
                of the PAMM Manager using the funds invested by the Investor
                to the Investment Account. Also authorizes the Broker to calculate the
                remuneration and pay from the PAMM Account to PAMM Manager.
            </p>
            <p>
                4.3 PAMM Manager will not create any orders (open or close order) on the
                Settlement Date and 2 days buffer period after Settlement Date.
            </p>
            <p>
                4.4 Activation Terms of PAMM Manager’s Offer presented on the website
                and customer portal of the Broker.
            </p>

            <br />

            <h6 style={{ fontWeight: '400' }}>5. INVESTOR’S FUNDS</h6>
            <p>
                5.1 Investor is able to transfer funds to MT5 account and withdraw funds
                from MT5 account via customer portal after registration with Broker.
                Once Investor has completed the subscription for PAMM Manager’s
                service, Investor’s MT5 account is disabled to trade and the account is
                attached and the balance amount is credited to PAMM Account
                accordingly.
            </p>
            <p>
                5.2 Realized profit and loss of each trade will be shown in Investor MT5
                account and PAMM Portal as well. Performance fee, Management fee,
                and other fee as agreed before subscription will be incurred when
                settlement day
            </p>
            <p>
                5.3 The first day of every month will be the Settlement Date for all PAMM
                Manager. And the time zone will based on TradeHall server time which is
                GMT+0.
            </p>
            <p>
                5.4 Early withdrawal from subscription (Detachment) of PAMM Account can
                incur cost when it does not take place on assigned period. Early
                detachment may incur loss from unrealized trades and incur penalty fee
                which is stated under the terms of the PAMM Portal.
            </p>
            <p>
                5.5 The investor is not allowed to withdraw profit, subscribe, unsubscribe
                and deposit on the settlement day. Penalty will be applied if the investor
                request to withdraw or unsubscribe on the settlement date.
                <br />
                The investor will be assigned with 2 days as a buffer period. Any
                withdrawal or unsubscribe will not affect to any funds during this period.
                <br />
                For example:
                If the settlement date is on 1-7-2021 (GMT+0), the investor is not allowed
                to withdraw or unsubscribe on this day, penalty will be applied if the
                investor request to withdraw or unsubscribe. The investor may withdraw
                or unsubscribe to PAMM Manager between 2-7-2021 (GMT+0) to
                3-7-2021 (GMT+0) without any penalty.
            </p>
            <p>
                5.6 The penalty of early withdrawal from subscription (Detachment) is 30%
                of balance amount in PAMM account.
            </p>
            <p>
                5.7 Depositing funds on the PAMM Account or the detachment of account
                from the PAMM Account will be made within the next Rollover after
                receiving request by Broker from investor.
            </p>
            <p>
                5.8 When Investor send a request to the Broker for account detachment
                from the PAMM Account, the Investor's responsibility to understand all
                the terms and condition in advance. Otherwise, Broker may still proceed
                the request without reminder.
            </p>

            <br />

            <h6 style={{ fontWeight: '400' }}>6. ROLLOVER</h6>
            <p>
                6.1 Rollover is carried out according to the rollover graphic of the specific
                PAMM Account. Rollover period of the specific PAMM Account is set by
                PAMM Manager and can be shift on condition that the interests of all
                parties in the PAMM system (the trader, investors and partners) are taken
                into account. The time of each Rollover can take several minutes (no
                more than 1 hour).
            </p>
            <p>
                6.2 Rollover is used for collecting and updating statistics on the PAMM
                Account, for the calculation and paying of remuneration to the PAMM
                Manager and processing of requests for deposit / withdrawal of funds.
            </p>

            <br />

            <h6 style={{ fontWeight: '400' }}>7. CALCULATIONS AND TRANSFERS</h6>
            <p>
                7.1 Funds of PAMM Account are calculated according to the following
                principle:
            </p>
            <p>
                7.1.1 Profit or loss for each open position increases or decreases the
                funds accordingly;
            </p>
            <p>
                7.1.2 Payment of remuneration to the PAMM Manager reduces the
                funds;
            </p>
            <p>
                7.1.3 Deposit funds to PAMM Account, respectively, increases the funds;
            </p>

            <br />

            <h6 style={{ fontWeight: '400' }}>8. WARRANTIES, REPRESENTATIONS AND UNDERTAKINGS</h6>
            <p>
                8.1 In addition to taking over guarantees and assurances in accordance with
                the terms of the Customer Agreement, each investor and PAMM
                Manager further guarantee and undertake to assure that:
            </p>
            <p>
                8.1.1 He/she has fully read and understood these Terms and agrees to
                comply with them;
            </p>
            <p>
                8.1.2 His/her activities related to any of the PAMM Account fully comply
                with any applicable law;
            </p>
            <p>
                8.1.3 He/she in a timely manner informs the Broker about existence of
                disputable situation or any claim relatively to service of the PAMM
                account in accordance with the provisions of the PAMM
                Agreement and LPOA on the procedure for resolving disputes.
            </p>

            <p>
                8.2 Manager further warrants, undertakes and assures that:
            </p>
            <p>
                8.2.1 He/she will carry out transactions in the interest of investors;
            </p>
            <p>
                8.2.2 He/she will assist the Broker to the extent necessary to resolve any
                disputable issues or complaints received from investors;
            </p>
            <p>
                8.2.3 He/she will not claim actions that may create the appearance that
                he/she is affiliated with the Broker, or cooperates with the Broker.
                In particular, it is not allowed to use the logo of the Company in any
                documents of PAMM Manager;
            </p>
            <p>
                8.2.4 in the event of a dispute, the Broker reserves the right to block all
                funds on the accounts of Manager until full settlement of the
                dispute.
            </p>

            <br />

            <h6 style={{ fontWeight: '400' }}>9. PAMM ACCOUNT LIQUIDATION</h6>
            <p>
                9.1 Upon liquidation of PAMM Account all opened orders on this account will
                be automatically closed at the current price and all pending orders will be
                automatically canceled.
            </p>
            <p>
                9.2 Manager for its part has the right to initiate PAMM Account liquidation,
                giving the Broker a corresponding request by confirmation email , in
                which case the PAMM Account will be liquidated.
            </p>
            <p>
                9.3 After the account liquidation, the funds on this account is automatically
                sharing between all participants of the account and the account is
                removing from the PAMM Portal.
            </p>
            <p>
                9.4 Upon PAMM Account liquidation, the Broker sends appropriate
                notifications via email or portal message to Manager and Clients.
            </p>

            <br />

            <h6 style={{ fontWeight: '400' }}>10. STATEMENT OF RISKS</h6>
            <p>
                10.1 The investor must be aware of the risks inherent in the PAMM Account
                service (such risks are in addition to the risks that are described in the risk
                Declaration), which can lead to substantial losses up to the loss of all
                funds invested on the PAMM Account:
            </p>
            <p>
                10.1.1 The Broker does not have opportunities to fully verify the degree
                of professionalism and experience of PAMM Managers,
                respectively it is possible that the skills and knowledge of PAMM
                Manager to manage the PAMM Account may not be enough;
            </p>
            <p>
                10.1.2 PAMM Manager can disrupt the obligation to manage the PAMM
                Account with respect to the best interests of investors, which the
                Broker may not have the information, so that the funds invested to
                PAMM Account, can be lost;
            </p>
            <p>
                10.1.3 in the case of non-compliance with the privacy of identification
                data by PAMM Manager, access to the PAMM Account by
                unauthorized person, in consequence of that, the funds invested to
                PAMM-Account, may be lost;
            </p>
            <p>
                10.1.4 The positive results of the PAMM Account management in the past,
                cannot be regarded as a precondition for an unambiguous positive
                result in the future on the same PAMM Account;
            </p>

            <p>
                10.2 If the Broker has grounds for supposing the action of PAMM Manager as
                a violation of these Terms and Conditions, the Broker reserves the right
                on its discretion to use the funds placed by PAMM Manager in the
                Company, in order to meet the claims of investors who have placed their
                funds to the PAMM Account of the PAMM Manager.
            </p>

            <p>
                10.3 Information provided in this section (list of risks) is not (and is not meant
                to be) exhaustive. Every PAMM Manager undertakes to make an
                informed decision about managing the PAMM Account before this PAMM
                Account is registered. Each investor undertakes to assess all possible
                risks before investing money in the PAMM Account, and if necessary,
                seek professional advice on the matter.
            </p>

            <br />

            <h6 style={{ fontWeight: '400' }}>11. OTHER CONDITIONS</h6>
            <p>
                11.1 Terms are in addition to the provisions of the “LPOA Agreement”
                concluded by the Broker with each PAMM Manager and each investor.
                The relevant provisions of the “LPOA Agreement” shall apply in the event
                that anything not expressly regulated
                by these Terms and Conditions.
            </p>
            <p>
                11.2 In order to avoid doubt, the provisions of the “LPOA Agreement” about
                limitation of liability, settlement of disputes, the notification and other
                provisions are applicable to the Broker and the customer, acting as
                PAMM Manager and Investors in accordance with these Terms and
                Conditions.
            </p>
            <p>
                11.3 These Terms and Conditions settled and shall be construed in
                accordance with the substantive laws of the ASIC and Saint Vincent and
                the Grenadines. The parties hereby agree to the exclusive jurisdiction of
                the courts of the ASIC and Saint Vincent and the Grenadines in respect of
                any dispute arising under these Terms and Conditions.
            </p>

        </>
    )
}

export default pammAgree
