import React, { useMemo, useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  ModalBody,
  Modal
} from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import AuthService from '../../../../../network/services/auth'
import PaymentService from '../../../../../network/services/payment'
import { useTranslation } from 'react-i18next'

import SweetAlert from 'sweetalert2'
const MultichainWithdrawalAmount = () => {
  const { t } = useTranslation()
  const { register, errors, formState, watch } = useFormContext()
  const { isSubmitting } = formState

  const watchAmount = watch('amount')
  const wallets = useSelector((state) => state.wallet.wallets)
  const payments = useSelector((state) => state.payment.payments)
  const selectedWalletId = useSelector((state) => state.withdrawal.wallet.id)
  const { user, twoFA } = useSelector((state) => state.user)
  const [otherOption, setOtherOption] = useState('')

  const selectedPayment = useMemo(() => {
    return payments.find((e) => e.handle === 'multichain')
    // eslint-disable-next-line
  }, [payments])

  const [supportedCurrency, setSupportedCurrency] = useState(selectedPayment?.supportedCurrencies)
  const [receivedAmount, setReceivedAmount] = useState(0)

  const selectedWallet = useMemo(() => {
    return wallets.find((e) => e.id === selectedWalletId)
    // eslint-disable-next-line`
  }, [selectedWalletId])

  const [authError, setAuthError] = useState(null)
  const [authPending, setAuthPending] = useState(null)
  const send2fa = async () => {
    try {
      const result = await AuthService.sendVerification({
        email: user.email
      })

      if (result.success) {
        setAuthPending(
          `${t('financial.pleaseCheck')} ${user.email} ${t('financial.forAuthenticationCode')}`
        )
        setAuthError(null)
      } else {
        setAuthPending(null)
        setAuthError(result.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  // modal
  const [showAlertModal, setShowAlertModal] = useState(false)
  const toggleModal = () => {
    setShowAlertModal(!showAlertModal)
  }

  const [timer, setTimer] = useState(3)

  useEffect(() => {
    // exit early when we reach 0
    if (!timer) return

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimer(timer - 1)
    }, 1000)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timer])

  const [selectedBlockChain, setSelectedBlockChain] = useState('')
  const [multichainFeesResult, setMultichainFeesResult] = useState(null)

  useEffect(() => {
    const amount =
      watchAmount -
      watchAmount * (multichainFeesResult?.charge_percentage / 100) -
      multichainFeesResult?.withdraw_fee

    setReceivedAmount(parseFloat(amount.toFixed(4)))
    // eslint-disable-next-line
  }, [watchAmount, multichainFeesResult])

  const [selectedUsdtId, setSelectedUsdtId] = useState('')

  const handleUsdtChange = async (e) => {
    setTimer(3)
    setSelectedUsdtId(e.target.value)

    const blockchain = supportedCurrency?.find(
      (item) => item.currency_id === parseInt(e.target.value)
    )

    // console.log('blockchain', blockchain)
    if (blockchain) {
      setSelectedBlockChain(blockchain?.currency?.unit)
    }

    const network = blockchain?.currency?.unit.includes('ERC') ? 'ERC20' : 'TRC20'
    try {
      const fees = await PaymentService.getMultichainWithdrawalFees({ network: network })
      
      setMultichainFeesResult(fees)
    } catch (error) {
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }

    toggleModal()
  }

  return (
    <>
      <Card>
        <CardHeader>
          <h5>{t('financial.chooseAmount')}</h5>
          <p style={{ margin: 0 }}>{`${t(
            'financial.availableBalance'
          )} ${selectedWallet?.balance?.toFixed(2)} ${selectedWallet?.currency?.name}`}</p>
          {selectedBlockChain != null && (
            <span style={{ color: 'green' }}>
              {t('financial.withdrawalFeeDetails', {
                withdraw_fee: multichainFeesResult?.withdraw_fee.toFixed(4),
                charge_percentage: multichainFeesResult?.charge_percentage.toFixed(2)
              })}
            </span>
          )}
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm={12}>
              <FormGroup>
                <Label>{t('financial.chooseNetwork')}</Label>
                <Input
                  type="select"
                  name="currency_id"
                  innerRef={register({ required: true })}
                  // style={{ width: '50%' }}
                  required
                  onChange={(e) => {
                    handleUsdtChange(e)
                  }}
                >
                  <option value=""> {t('financial.pleaseSelectANetwork')}</option>
                  {/* <option value='USDT ERC20'>USDT(ERC20)</option>
                                    <option value='USDT TRON'>USDT(TRON)</option> */}
                  {supportedCurrency?.length > 0 &&
                    supportedCurrency?.map((currency) => {
                      if (currency?.currency?.unit === 'USDT(TRC20)') {
                        return (
                          <option value={currency?.currency?.id}>
                            {currency?.currency?.unit} (Recommended)
                          </option>
                        )
                      } else {
                        return (
                          <option value={currency?.currency?.id}>{currency?.currency?.unit}</option>
                        )
                      }
                    })}
                </Input>
              </FormGroup>
            </Col>

            {/* {console.log('selectedBlockChain', selectedBlockChain)} */}
            {selectedBlockChain !== '' && (
              <Modal isOpen={showAlertModal} backdrop={true} centered={true}>
                <ModalBody style={{ textAlign: 'center' }}>
                  {/* <AlertCircle centered={true} width="75px" height='75px' style={{ color: '#f9b600', marginBottom: '25px' }} /> */}
                  {selectedBlockChain === 'USDT(ERC20)' && (
                    <img
                      style={{ width: '200px', height: '200px', marginBottom: '25px' }}
                      src="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/USDT+Icon-03.svg"
                      alt="erc20"
                    />
                  )}
                  {selectedBlockChain === 'USDT(TRC20)' && (
                    <img
                      style={{ width: '200px', height: '200px', marginBottom: '25px' }}
                      src="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/USDT+Icon-04.svg"
                      alt="trc20"
                    />
                  )}
                  <h4>
                    {t('financial.selected')}{' '}
                    <span style={{ color: '#f9b600' }}>{selectedBlockChain}</span>
                  </h4>
                  <p style={{ color: 'grey' }}>{t('financial.ensureTheNetwork')}</p>
                  <div>
                    <Button
                      style={{ marginTop: '15px' }}
                      disabled={timer !== 0}
                      onClick={() => {
                        toggleModal()
                      }}
                    >
                      {t('financial.IUnderstand')} {timer > 0 && timer}
                    </Button>
                  </div>
                </ModalBody>
              </Modal>
            )}

            {selectedBlockChain !== '' && (
              <>
                <Col sm={6}>
                  <FormGroup>
                    <Label>
                      {t('financial.withdrawalAmount')}
                      <span
                        style={{ color: 'green', textTransform: 'uppercase' }}
                      >{` (${selectedPayment?.currency?.base_currency})`}</span>
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      step=".01"
                      name="amount"
                      innerRef={register({
                        required: t('financial.amountIsRequired'),
                        min: {
                          // value: 100 / selectedPayment?.currency?.withdraw_rate ?? 100,
                          value: user.min_withdraw_amount.toString(),
                          message: t('plan.min.withdraw', { amount: user.min_withdraw_amount })
                        }
                      })}
                    />

                    <Input
                      className="form-control"
                      type="number"
                      name="network_fee"
                      innerRef={register({ required: true })}
                      value={multichainFeesResult?.withdraw_fee}
                      hidden
                    />
                    <span style={{ color: 'red', display: 'block' }}>
                      {errors.amount && errors.amount.message}
                    </span>
                    {receivedAmount > 0 ? (
                      <span style={{ color: 'green' }}>
                        {`~ ${t('financial.youWillReceiveApproximately')}`} {receivedAmount}
                        USDT
                      </span>
                    ) : watchAmount && receivedAmount <= 0 ? (
                      <span style={{ color: 'red' }}>
                        {`~ ${t('financial.coverNetworkFee', {
                          withdraw_charge:
                            ((multichainFeesResult?.charge_percentage / 100) * (watchAmount ?? 0) + multichainFeesResult?.withdraw_fee).toFixed(4)
                        })}`}
                      </span>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label>{t('financial.walletAddress')}</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="recipient_address"
                      innerRef={register({ required: true })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.recipient_address && t('financial.addressIsRequired')}
                    </span>
                  </FormGroup>
                </Col>
                <Col sm={8}>
                  <FormGroup>
                    <Label>{t('financial.Reason')} </Label>
                    <Input
                      className="form-control"
                      type="select"
                      name="withdraw_reason"
                      innerRef={register({ required: true })}
                      onChange={(e) => {
                        setOtherOption(e.target.value)
                      }}
                    >
                      <option value="">{t('financial.SelectReason')}</option>

                      <option value="Need funds urgently">
                        {t('financial.NeedFundsUrgently')}
                      </option>
                      <option value="Profit withdrawal">{t('financial.ProfitWithdrawal')}</option>
                      <option value="Competitors provide better benefits & services">
                        {t('financial.CompetitorsProvideBetterBenefits')}
                      </option>
                      <option value="The system is not user-friendly">
                        {t('financial.TheSystemIsNot')}
                      </option>
                      <option>{t('financial.Other')}</option>
                    </Input>
                    <span style={{ color: 'red', marginTop: '10px' }}>
                      {errors.withdraw_reason && t('financial.PleaseAtLeastSelect')}
                    </span>
                  </FormGroup>

                  {otherOption === 'Other' && (
                    <FormGroup>
                      <Label className="m-t-10">{t('financial.OtherReason')} </Label>
                      <Input
                        innerRef={register({ required: true })}
                        className="form-control input-txt-bx"
                        type="text"
                        name="withdraw_reason"
                        placeholder={t('financial.EnterYourReason')}
                      />
                    </FormGroup>
                  )}
                  {otherOption === '其他' && (
                    <FormGroup>
                      <Label className="m-t-10">{t('financial.OtherReason')} </Label>
                      <Input
                        innerRef={register({ required: true })}
                        className="form-control input-txt-bx"
                        type="text"
                        name="withdraw_reason"
                        placeholder={t('financial.EnterYourReason')}
                      />
                    </FormGroup>
                  )}
                  {otherOption === 'Competitors provide better benefits & services' && (
                    <FormGroup>
                      <Label className="m-t-10">{t('financial.competitorName')}</Label>
                      <Input
                        innerRef={register({ required: true })}
                        className="form-control input-txt-bx"
                        type="text"
                        name="competitor"
                        placeholder={t('financial.EnterCompetitorName')}
                      />
                    </FormGroup>
                  )}
                </Col>
                {twoFA != null && (
                  <>
                    <Col sm={6}>
                      <FormGroup>
                        <Label>{t('financial.2FACode')}</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="auth_code"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: 'red' }}>
                          {errors.auth_code && t('financial.2FACodeIsRequired')}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button
                          color="primary btn-block"
                          onClick={send2fa}
                          style={{ maxWidth: 250 }}
                        >
                          {t('financial.sendAuthenticationCode')}
                        </Button>
                        <span style={{ color: 'green', display: 'block' }}>{authPending}</span>
                        <span style={{ color: 'red', display: 'block' }}>{authError}</span>
                      </FormGroup>
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>

          {selectedBlockChain !== '' && (
            <Button
              color="primary btn-block"
              type="submit"
              style={{ maxWidth: 150, float: 'right', marginTop: '25px' }}
              disabled={receivedAmount <= 0 ? true : false}
            >
              {isSubmitting ? t('financial.loading') : t('financial.submit')}
            </Button>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default MultichainWithdrawalAmount
