import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import Breadcrumb from '../../layout/breadcrumb'

import NotificationService from '../../network/services/notification'
import { Col, Row, Card, CardBody, Spinner } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'


import { useTranslation } from 'react-i18next'
import moment from 'moment'

const Notification = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()


    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: 'none' })
    }

    const [unReadNotification, setUnReadNotification] = useState([])

    const init = async () => {
        const result = await NotificationService.getAll()
        setUnReadNotification(result?.user_notifications?.data)

    }

    useEffect(() => {
        init()

    }, [])

    const readNotification = async (id) => {
        const readNotification = await NotificationService.readTheNotification(id)
        const notificationResponse = await NotificationService.getAll()
        const filterUnreadResponse = notificationResponse?.user_notifications?.data.filter(notification => notification.is_read == false)

        dispatch({
            type: 'SET_UNREAD_NOTIFICATION',
            unread_notifications: filterUnreadResponse
        })
    }

    const getthetitle = (type) => {
        if (type == 0) {
            return t('notification.NotiTitleDeposit');
        } else if (type == 1) {
            return t('notification.NotiTitleWithdrawal');
        } else if (type == 2) {
            return t('notification.NotiTitleMT5Withdrawal');
        } else if (type == 3) {
            return t('notification.NotiTitleMT5Transfer');
        } else if (type == 4) {
            return t('notification.NotiTitleVerification');
        } else if (type == 5) {
            return t('notification.NotiTitleBank');
        } else if (type == 6) {
            return t('notification.NotiTitleCreditCard');
        } else if (type == 7) {
            return t('notification.NotiTitlePackageApplication');
        } else if (type == 8) {
            return t('notification.NotiTitlePammSubscription');
        } else if (type == 9) {
            return t('notification.NotiTitleLeverage');
        } else if (type == 10) {
            return t('notification.NotiTitleSupportTicket');
        } else {
            return t('notification.NotiStatusPending');
        }
    }

    const getthestatus = (status) => {
        if (status == 0) {
            return t('notification.NotiStatusPending');
        } else if (status == 1) {
            return t('notification.NotiStatusSuccess');
        } else if (status == 2) {
            return t('notification.NotiStatusFailed');
        } else if (status == 3) {
            return t('notification.NotiStatusRejected');
        }
    }

    const getIcon = (type) =>{
        if (type == 0) {
          return <img style={{width:'50px', height:'50px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Notification-Deposit.svg'></img>
        } else if (type == 1) {
          return <img style={{width:'50px', height:'50px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Notification-Withdrawal.svg'></img>;
        } else if (type == 2) {
          return <img style={{width:'50px', height:'50px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Notification-Withdrawal.svg'></img>;
        } else if (type == 3) {
          return <img style={{width:'50px', height:'50px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_Transfer.svg'></img>;
        } else if (type == 4) {
          return <img style={{width:'50px', height:'50px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Notification-Verification.svg'></img>;
        } else if (type == 5) {
          return <img style={{width:'50px', height:'50px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_Bank.svg'></img>;
        } else if (type == 6) {
          return <img style={{width:'50px', height:'50px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_Credit+Card.svg'></img>;
        } else if (type == 7) {
          return <img style={{width:'50px', height:'50px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_Package+Application.svg'></img>;
        } else if (type == 8) {
          return <img style={{width:'50px', height:'50px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_Leverage.svg'></img>;
        } else if (type == 9) {
          return <img style={{width:'50px', height:'50px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_PAMM+Subscription.svg'></img>;
        } else if (type == 10) {
          return <img style={{width:'50px', height:'50px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_Feedback.svg'></img>;
        } else {
          return <img style={{width:'50px', height:'50px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Notification-Deposit.svg'></img>;
        }
      }

    const notifications = (notification) => {
        // deposit notification
        if (notification.type == 0) {
            
            return `${t('notification.NotiDepositIs',
                        { 
                            status: getthestatus(notification?.deposit?.status) 
                        }
                    )}`

        } else if
            // withdraw notification
            (notification?.type == 1) {
            
                return `${t('notification.NotiWithdrawalIs',
                            { 
                                status: getthestatus(notification?.withdraw?.status) 
                            }
                        )}`

        } else if
            // mt5 withdrawal notification
            (notification?.type == 2) {
            
                return `${t('notification.NotiMT5WithdrawalIs',
                            { 
                                status: getthestatus(notification?.account_withdraw?.status) 
                            }
                        )}`

        } else if
            // mt5 transfer notification
            (notification?.type == 3) {
            
                return `${t('notification.NotiMT5TransferIs',
                            { 
                                status: getthestatus(notification?.account_transfer?.status) 
                            }
                        )}`
 

            
        } else if
            // kyc notification
            (notification?.type == 4) {
            
                return `${t('notification.NotiVerificationIs',
                                { 
                                    status: getthestatus(notification?.verification?.status) 
                                }
                            )}`

        } else if
            // bank notification 
            (notification?.type == 5) {
            
                return `${t('notification.NotiBankIs',
                            {   
                                status: getthestatus(notification?.bank?.status) 
                            }  
                        )}`

        } else if
            // credit card notification 
            (notification?.type == 6) {
            
                return `${t('notification.NotiCreditCardIs',
                            { 
                                status: getthestatus(notification?.credit_card?.status) 
                            }
                        )}`

        } else if
            // package upgrade notification 
            (notification?.type == 7) {
            
                return `${t('notification.NotiPackageApplicationIs',
                        { 
                            status: getthestatus(notification?.package_application?.status) 
                        }
                        )}`

        } else if
            // pamm subscription notification
            (notification?.type == 8) {
            
                return `${t('notification.NotiPammSubscriptionIs',
                            { 
                                status: getthestatus(notification?.pamm_subscription?.status), 
                                pamm: notification?.pamm_subscription?.pammManager?.name
                            }
                        )}`

            
        } else if
            // leverage notification 
            (notification?.type == 9) {
            
                return `${t('notification.NotiLeverageIs',
                            { 
                                status: getthestatus(notification?.leverage?.status) 
                            }
                        )} `
    
        } else if
            // support ticket notification
            (notification?.type == 10) {
            
                return `${t('notification.NotiDescriptionNewMessage'
                            
                        )}`

        }

    }

    return (
        <>
            <Breadcrumb
                title={t('notification.Notification')}
            />

            <Row style={{ marginTop: '15px', justifyContent: 'center', marginLeft: '0', marginRight: '0' }}>

                <Col lg='6'>
                    <Card style={{
                        padding: '15px 20px',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        borderRadius: '5px',
                        backgroundColor: 'white'
                    }}>
                        <Row style={{ padding: '0px 15px', justifyContent: 'space-between', alignItems: 'center', color: 'black' }}>
                            <h5 style={{ fontWeight: 'normal' }}>{t('notification.NotificationsNewMessage')}</h5>
                            {/* <p
                                style={{ marginBottom: '0', cursor: 'pointer' }}
                                onClick={() => {
                                    navigate(`${process.env.PUBLIC_URL}/support`)
                                }}
                            >
                                {t('notification.ViewMore')}
                            </p> */}
                        </Row>

                        <div
                            style={{
                                overflow: 'auto',
                                marginTop: '20px',

                            }}
                            id="transactionsDiv"
                        >
                            {unReadNotification != null ? (
                                unReadNotification?.length > 0 ? (
                                    unReadNotification.filter(readed => readed.is_read == false).map((notification) => (
                                        <Link style={{ color: "black" }} onClick={() => readNotification(notification?.id)} to={`/notification/${notification?.id}`}>
                                            <Card
                                                key={notification?.id}
                                                className="recentTransaction darkCardTransaction"
                                                style={{
                                                    marginBottom: '0px',
                                                    padding: '10px 0px',
                                                    cursor: 'pointer',
                                                    backgroundColor: 'white',
                                                    borderRadius: '0px'
                                                }}
                                            >
                                            <Row style={{alignItems:'center', padding:'10px'}}>
                                                <Col lg='2' xs='2' style={{ textAlign:'center'}}>
                                                        {getIcon(notification?.type)}
                                                </Col>
                                            
                                                <Col>
                                                    
                                                    
                                                        {/* <Col lg='' style={{ display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ fontWeight: 'normal', marginBottom: '0', color: '#f9b600' }}>
                                                                {t('dashboard.ticket')}: {notification?.id}
                                                            </p>
                                                        </Col> */}
                                                    
                                                        <Col>
                                                            <h5 style={{marginBottom:'8px'}}>{getthetitle(notification?.type)}</h5>
                                                            <p style={{lineHeight:'13px'}}>{notifications(notification)}</p>

                                                        </Col>

                                                    
                                                        <Col>
                                                            <p style={{ marginBottom: '0px', color: '#888888',lineHeight:'5px' }}>
                                                                {moment(notification?.created_at).format('YYYY-MM-DD HH:mm')}
                                                            </p>
                                                        </Col>
                                                    
                                                </Col>
                                            </Row>

                                            </Card>
                                        </Link>
                                    ))
                                ) : (
                                    <Card
                                        style={{
                                            height: '100px',
                                            overflow: 'hidden',
                                            borderRadius: '0px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <p style={{ fontSize: '20px', margin: 'auto 0' }}>
                                            {t('notification.NoAnyMessage')}
                                        </p>
                                    </Card>
                                )
                            ) : (
                                <Col>
                                    <Card>
                                        <CardBody style={{ width: '100%', height: '100px', textAlign: 'center' }}>
                                            <Spinner />
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}
                        </div>


                    </Card>
                </Col>

            </Row>

        </>
    )
}

export default Notification
