import React, { useState, useRef, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { Row, Col, Card, CardHeader, Input } from 'reactstrap'
import moment from 'moment'

import UserService from '../../../network/services/user'
import BonusFilters from './filters'
import { useTranslation } from 'react-i18next'


const BonusTable = () => {
  const { t } = useTranslation()
  const columns = [
    {
      name: t("promotion.Ticket"),
      selector: 'id',
      sortable: true
    },
    {
      name: t("promotion.Account"),
      selector: 'account',
      sortable: true,
      format: (row) => {
        return row.account?.account_login ?? '-'
      }
    },
    {
      name: t("promotion.Date"),
      selector: 'created_at',
      sortable: true,
      width: '200px',
      format: (row) => {
        return moment(row.created_at).format('yyyy-MM-DD hh:mmA')
      }
    },
    {
      name: t("promotion.Amount"),
      selector: 'bonus_amount',
      sortable: true,
      right: true
    },
    {
      name: t("promotion.Status"),
      selector: 'status',
      sortable: true,
      format: (row) => {
        switch (row.status) {
          case 0:
            return t("promotion.Pending")
          case 1:
            return t("promotion.Success")
          case 2:
            return t("promotion.Failed")
          default:
            return '-'
        }
      }
    },
    {
      name: t("promotion.Reason"),
      selector: 'reason',
      sortable: true
    }
  ]
  const [isBusy, setIsBusy] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [sidebaron, setSidebaron] = useState(true)
  const [filters, setFilters] = useState(null)
  const [searchKeyword, setSearchKeyword] = useState('')
  const wrapperRef = useRef()

  const handlePageChange = async (page) => {
    setIsBusy(true)
    const result = await UserService.getBonuses({ page: page, filters: filters })
    setData(result.bonuses?.data ?? [])
    setTotal(result.bonuses?.meta?.total ?? 0)
    setIsBusy(false)
  }

  const handleFilter = async () => {
    setIsBusy(true)
    const result = await UserService.getBonuses({
      filters: {
        ...filters
      }
    })
    setData(result.bonuses?.data ?? [])
    setTotal(result.bonuses?.meta?.total ?? 0)
    setIsBusy(false)
  }

  const init = async () => {
    setIsBusy(true)
    // bonus
    const result = await UserService.getBonuses()
    // console.log(result.bonuses)

    setTotal(result.bonuses?.meta?.total ?? 0)
    setData(result.bonuses?.data ?? [])
    setIsBusy(false)
  }

  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false)
      wrapperRef.current.classList.add('sidebaron')
    } else {
      setSidebaron(true)
      wrapperRef.current.classList.remove('sidebaron')
    }
  }

  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword)
    setFilters({
      ...filters,
      account_login: keyword
    })
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    handleFilter()
    // eslint-disable-next-line
  }, [filters])

  return (
    <div className="product-wrapper" ref={wrapperRef}>
      <div className="product-grid" style={{ minHeight: '50vh' }}>
        <Row>
          <Col xl="3">
            <div className="product-sidebar">
              <div className="filter-section">
                <Card className="m-0">
                  <CardHeader>
                    <h6 className="mb-0 f-w-600">
                      {t("promotion.Filters")}
                      <span className="pull-right">
                        <i className="fa fa-chevron-down toggle-data" onClick={onClickFilter}></i>
                      </span>
                    </h6>
                  </CardHeader>
                  <div className="left-filter">
                    <div style={{ padding: 16 }} />
                    <BonusFilters handleFilter={setFilters} filters={filters} />
                  </div>
                </Card>
              </div>
            </div>
          </Col>
          <Col xl="9" sm="12">
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Input
                className="form-control"
                type="text"
                placeholder={t("promotion.SearchAccount")}
                defaultValue={searchKeyword}
                onChange={(e) => handleSearchKeyword(e.target.value)}
              />
            </div>
          </Col>
        </Row>

        <div className="product-wrapper-grid" style={{ marginTop: 16 }}>
          <DataTable
            noHeader
            data={data}
            columns={columns}
            striped={true}
            center={true}
            pagination
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={total}
            onChangePage={handlePageChange}
            progressPending={isBusy}
          />
        </div>
      </div>
    </div>
  )
}

export default BonusTable
