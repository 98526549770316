import React from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from 'react-i18next'

const NationalId = () => {
  const { t } = useTranslation()
  return (
    <>
      <Row>
        <Col md="12" style={{ textAlign: 'center' }}>
          {/* For Laptop Version */}
          <div className="d-none d-lg-flex d-xl-flex verify-KYC-image ">
            <div>
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotBlur.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustNotBeBlur")}</p>
            </div>
            <div>
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotCut.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustNotBeCutOff")}</p>
            </div>
          </div>
          <div className='d-none d-lg-flex d-xl-flex m-t-20 verify-KYC-image'>
            <div>
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustColor.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustBeInColor")}</p>
            </div>
            <div>
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/goodExampleId.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.Example")}</p>
            </div>
          </div>


          {/* For Tab and Mobile Version */}
          <div className="d-flex d-lg-none d-xl-none verify-KYC-Mobileimage ">

            <div className="text-center-sm m-b-5">
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotCut.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustNotBeCutOff")}</p>
            </div>
            <div className="text-center-sm m-b-5">
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustColor.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustBeInColor")}</p>
            </div>

            <div className="text-center-sm m-b-5">
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotBlur.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustNotBeBlur")}</p>
            </div>
            <div className="text-center-sm m-b-5">
              <img src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/goodExampleId.png" alt="" className="KYC-example-image" />
              <p>{t("verifyAuth.MustBeValid")}</p>
            </div>
          </div>
        </Col>

      </Row>
    </>
  )
}

export default NationalId
