import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import Breadcrumb from '../../layout/breadcrumb'
import Deposit from './deposit'
import Withdrawal from './withdrawal'
import History from './history'
import Bonus from './bonus'
import Transfer from './transfer'
import { useTranslation } from 'react-i18next'

import WalletService from '../../network/services/wallet'

const Financial = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tabs = {
    deposit: t("financial.deposit"),
    withdrawal: t("financial.withdrawal"),
    transfer: t("financial.walletTransfer"),
    history: t("financial.depositWithdrawalHistory"),
    bonus: t("financial.manageBonus")
  }

  const init = async () => {
    const walletResponse = await WalletService.get()
    dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })
    dispatch({
      type: 'SET_BALANCE',
      balance: walletResponse.wallet_balance
    })
    dispatch({
      type: 'SET_TOTAL_DEPOSIT',
      totalDeposit: walletResponse.total_deposit
    })
    dispatch({
      type: 'SET_TOTAL_WITHDRAWAL',
      totalWithdrawal: walletResponse.total_withdrawal
    })
    dispatch({
      type: 'SET_TOTAL_ACCOUNT_DEPOSIT',
      totalAccountDeposit: walletResponse.total_account_deposit
    })
    dispatch({
      type: 'SET_TOTAL_ACCOUNT_WITHDRAWAL',
      totalAccountWithdraw: walletResponse.total_account_withdraw
    })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const [activeTab, setActiveTab] = useState('deposit')
  const [refresh, setRefresh] = useState(null)
  let location = useLocation()
  let history = useHistory()

  useEffect(() => {
    if (!isEmpty(location.hash)) {
      setActiveTab(location.hash.replace('#', ''))
    } else {
      setActiveTab('deposit')
    }
  }, [location])

  useEffect(() => {
    setRefresh(Math.random())
  }, [activeTab])

  const setTab = (val) => {
    setRefresh(Math.random())
    history.push('#' + val)
  }

  return (
    <>
      <Breadcrumb parent={t('financial.Financial')} title={tabs[activeTab]} />
      <Container>
        <Row>
          <Col sm="12" lg="4" xl="3" className="project-list">
            <Card>
              <Row>
                <Col>
                  <Nav tabs className="border-tab">
                    <NavItem>
                      <NavLink
                        className={activeTab === 'deposit' ? 'active' : ''}
                        onClick={() => setTab('deposit')}
                      >
                        <i className="fa fa-bank"></i>
                        {t("financial.deposit")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'withdrawal' ? 'active' : ''}
                        onClick={() => setTab('withdrawal')}
                      >
                        <i className="fa fa-money"></i>
                        {t("financial.withdrawal")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'transfer' ? 'active' : ''}
                        onClick={() => setTab('transfer')}
                      >
                        <i className="fa fa-exchange"></i>
                        {t("financial.transfer")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'history' ? 'active' : ''}
                        onClick={() => setTab('history')}
                      >
                        <i className="fa fa-history"></i>
                        {t("financial.history")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'bonus' ? 'active' : ''}
                        onClick={() => setTab('bonus')}
                      >
                        <i className="fa fa-percent"></i>
                        {t("financial.bonus")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col sm="12" lg="8" xl="9">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="deposit">
                <Deposit refresh={refresh} />
              </TabPane>
              <TabPane tabId="withdrawal">
                <Withdrawal refresh={refresh} />
              </TabPane>
              <TabPane tabId="transfer">
                <Transfer />
              </TabPane>
              <TabPane tabId="history">
                <History refresh={refresh} />
              </TabPane>
              <TabPane tabId="bonus">
                <Bonus />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Financial
