import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Media, CardBody, Spinner } from 'reactstrap'
import styled from 'styled-components'
import _ from 'lodash'

import Breadcrumb from '../../layout/breadcrumb'
import SettingService from '../../network/services/setting'
import { useTranslation } from 'react-i18next'

const HoverCard = styled(Card)`
  background: ${(props) => props?.background ?? 'rgb(15, 60, 90)'};
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
`

const Link = styled.a`
  color: white;

  &:hover {
    color: white;
  }
`

const Platform = () => {
  const { t } = useTranslation()
  const [settings, setSettings] = useState({})
  const init = async () => {
    try {
      const result = await SettingService.getAll()
      // console.log(result)

      setSettings(result.settings ?? {})
    } catch (error) {
      setSettings({})
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      <Breadcrumb title={t("platform.platforms")} />
      <Container>
        <Card>
          <CardBody>
            <h5>{t("platform.getYourMT5")}</h5>
            <p>{t("platform.oneOfTheMostPowerful")}</p>
          </CardBody>
        </Card>
        {settings != null ? (
          settings?.length > 0 && (
            <Row>
              <Col sm="6" lg="4">
                <HoverCard background="rgb(15, 60, 90)">
                  <Link
                    href={settings?.find((s) => s.handle === 'platform_windows_url')?.value ?? '#'}
                    target="_blank"
                  >
                    <CardBody>
                      <Media>
                        <i className="icofont icofont-brand-windows" style={{ fontSize: 40 }}></i>
                        <Media body>
                          <h6
                            className="pull-right"
                            style={{ padding: 0, margin: 0, fontSize: 30, color: 'white' }}
                          >
                            {t("platform.windows")}
                          </h6>
                        </Media>
                      </Media>
                    </CardBody>
                  </Link>
                </HoverCard>
              </Col>
              <Col sm="6" lg="4">
                <HoverCard background="rgb(15, 60, 90)">
                  <Link
                    href={settings?.find((s) => s.handle === 'platform_android_url')?.value ?? '#'}
                    target="_blank"
                  >
                    <CardBody>
                      <Media>
                        <i
                          className="icofont icofont-brand-android-robot"
                          style={{ fontSize: 40 }}
                        ></i>
                        <Media body>
                          <h6
                            className="pull-right"
                            style={{ padding: 0, margin: 0, fontSize: 30, color: 'white' }}
                          >
                            {t("platform.android")}
                          </h6>
                        </Media>
                      </Media>
                    </CardBody>
                  </Link>
                </HoverCard>
              </Col>
              <Col sm="6" lg="4">
                <HoverCard background="rgb(15, 60, 90)">
                  <Link
                    href={settings?.find((s) => s.handle === 'platform_ios_url')?.value ?? '#'}
                    target="_blank"
                  >
                    <CardBody>
                      <Media>
                        <i
                          className="icofont icofont-brand-apple"
                          style={{ fontSize: 40, color: '#F9F6EF' }}
                        ></i>
                        <Media body>
                          <h6
                            className="pull-right"
                            style={{ padding: 0, margin: 0, fontSize: 30, color: '#F9F6EF' }}
                          >
                            iOS
                          </h6>
                        </Media>
                      </Media>
                    </CardBody>
                  </Link>
                </HoverCard>
              </Col>
              <Col sm="6" lg="4">
                <HoverCard background="rgb(15, 60, 90)">
                  <Link
                    href={settings?.find((s) => s.handle === 'platform_web_url')?.value ?? '#'}
                    target="_blank"
                  >
                    <CardBody>
                      <Media>
                        <i className="icofont icofont-monitor" style={{ fontSize: 40 }}></i>
                        <Media body>
                          <h6
                            className="pull-right"
                            style={{ padding: 0, margin: 0, fontSize: 30, color: 'white' }}
                          >
                            {t("platform.web")}
                          </h6>
                        </Media>
                      </Media>
                    </CardBody>
                  </Link>
                </HoverCard>
              </Col>
              <Col sm="6" lg="4">
                <HoverCard background="rgb(15, 60, 90)">
                  <Link
                    href={settings?.find((s) => s.handle === 'platform_mac_url')?.value ?? '#'}
                    target="_blank"
                  >
                    <CardBody>
                      <Media>
                        <i className="icofont icofont-brand-apple" style={{ fontSize: 40 }}></i>
                        <Media body>
                          <h6
                            className="pull-right"
                            style={{ padding: 0, margin: 0, fontSize: 30, color: 'white' }}
                          >
                            Mac OS
                          </h6>
                        </Media>
                      </Media>
                    </CardBody>
                  </Link>
                </HoverCard>
              </Col>
            </Row>
          )
        ) : (
          <Spinner color="warning"/>
        )}
      </Container>
    </>
  )
}

export default Platform
