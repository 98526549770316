import React, { useRef, useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  FormGroup,

  FormText
} from 'reactstrap'
import { NavLink, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import Breadcrumb from '../../../layout/breadcrumb'
import FeedbackService from '../../../network/services/feedback'
import FilesService from '../../../network/services/files'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-feather'

const SupportReply = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [feedback, setFeedback] = useState([])
  const user = useSelector((state) => state.user.user)
  const verification = useSelector((state) => state.verification)

  const fetchFeedback = async () => {
    const result = await FeedbackService.get(id)
    setFeedback(result.feedbacks)
  }

  useEffect(() => {
    fetchFeedback()
    // eslint-disable-next-line
  }, [])

  const { register, handleSubmit, reset } = useForm()
  const submitUpdate = async (data) => {
    // console.log('data', data)
    try {

      if (data.file.length !== 0) {
        const attachmentRes = await FilesService.create(verification.attachment?.file)
        data.file = attachmentRes?.data
      } else {
        data.file = null
      }
      // const attachmentRes = await FilesService.create(verification.attachment?.file)

      const result = await FeedbackService.reply(id, {
        content: data?.content,
        attachment: data?.file ?? ''
      })
      if (result.feedback) {
        setFeedback(result.feedback)
        reset()
        fetchFeedback()
        // console.log('setFeedback(result.feedback)', result.feedback)
        dispatch({
          type: 'SET_ATTACHMENT',
          attachment: null
        })


      }
    } catch (error) {
      console.log(error)
      // SweetAlert.fire({
      //   title: error.message,
      //   icon: 'error'
      // })
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }

  const messageEl = useRef(null)
  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' })
      })
    }
  }, [])

  return (
    <>
      <Breadcrumb title={t("support.customerSupport")} >
        <NavLink style={{ color: 'black' }} to="/support">Customer Support</NavLink>
      </Breadcrumb>

      <Container>

        <Form
          onSubmit={handleSubmit(submitUpdate)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0.75rem'
          }}
        >
          <div className="m-b-20">
            <h5 style={{ fontWeight: '400' }}>Subject: {feedback?.subject}</h5>
          </div>
          <Row>

            <Col className="call-chat-body">
              {/* <h5>
                {t("support.subject")}: {feedback?.subject}
              </h5>
              <p style={{ marginBottom: '5px' }}>{t("support.message")}: {feedback?.message}</p>
              <p>
                {t("support.Status")}: <span style={{ color: '#f9b600' }}>{feedback?.status === 1 && t("support.InProgress")}</span> <span style={{ color: "green" }}>{feedback?.status === 2 && t("support.Resolved")}</span>
              </p> */}
              <Card>
                <CardBody className="p-0">
                  <Row className="chat-box">
                    <Col className="pr-0 chat-right-aside">
                      <div className="chat">
                        <div className="chat-history chat-msg-box custom-scrollbar" ref={messageEl}>
                          <ul id='reply-list'>
                            <li className="clearfix">
                              <div
                                className={`message my-message float-right
                                  `}
                                style={{
                                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                }}
                              >
                                <div
                                  className="message-data"
                                  style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                  <span className="message-data-time">{feedback?.user?.name}</span>
                                  <span className="message-data-time">
                                    {moment(feedback?.created_at).format('yyyy-MM-DD hh:mmA')}
                                  </span>
                                </div>
                                {feedback?.message}
                              </div>
                            </li>
                            {feedback?.replies?.length > 0 ? (
                              feedback?.replies?.map((item, index) => {
                                return (
                                  <li key={index} className="clearfix">
                                    <div
                                      className={`message my-message ${item.user_id !== user?.id ? '' : 'float-right'
                                        }`}
                                      style={{
                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                      }}
                                    >
                                      <div
                                        className="message-data"
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                      >
                                        {item.user_id !== user?.id ?
                                          <span className="message-data-time">TradeHall Support Team</span>
                                          :
                                          <span className="message-data-time">{item.user?.name}</span>
                                        }
                                        <span className="message-data-time">
                                          {moment(item.created_at).format('yyyy-MM-DD hh:mmA')}
                                        </span>
                                      </div>

                                      {item.user_id !== user?.id ?
                                        // For Admin site
                                        item?.attachment &&
                                        <a href={item?.attachment} target='_blank' rel="noopener noreferrer">
                                          <Row style={{ marginLeft: '0', marginRight: '0' }}>
                                            <Link width='16px' style={{ marginRight: '5px' }} />
                                            <span>Attachment</span>
                                          </Row>
                                        </a>
                                        :
                                        item?.attachment &&
                                        // For User site
                                        <a href={item?.attachment} target='_blank' rel="noopener noreferrer">
                                          <Row style={{ marginLeft: '0', marginRight: '0' }}>
                                            <Link width='16px' style={{ marginRight: '5px' }} />
                                            <span>Attachment</span>
                                          </Row>
                                        </a>
                                      }

                                      {item.content}
                                    </div>
                                  </li>
                                )
                              })
                            ) : (
                                // <div style={{ textAlign: 'center' }}>
                                //   <h5 style={{ marginTop: 12 }}>{t("support.startConversation")}</h5>
                                // </div>
                                ''
                              )}
                          </ul>
                        </div>
                        {feedback?.status !== 2 ?
                          <div className="chat-message clearfix">
                            <Row>
                              <Col xl="12" className="d-flex">
                                <FormGroup>

                                  <Input
                                    type="file"
                                    name="file"
                                    accept="image/png, image/jpeg, application/pdf"
                                    innerRef={register}
                                    onChange={(e) => {
                                      dispatch({
                                        type: 'SET_ATTACHMENT',
                                        attachment: { file: e?.currentTarget?.files[0] }
                                      })
                                    }} />
                                  <FormText color="muted">.jpg, .png, pdf, max 10 MB</FormText>

                                </FormGroup>
                              </Col>

                              <Col xl="12" className="d-flex">

                                <InputGroup className="text-box">
                                  <Input
                                    type="text"
                                    className="form-control input-txt-bx"
                                    placeholder="Type a message......"
                                    name="content"
                                    innerRef={register}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="primary">{t("support.send")}</Button>
                                  </InputGroupAddon>
                                </InputGroup>
                              </Col>
                            </Row>
                          </div>
                          :
                          <div className="chat-message clearfix">
                            <Row >
                              <Col xl="12" className="d-flex" style={{ justifyContent: 'center' }}>
                                <NavLink to='/support'>
                                  <p> This ticket has been resolved</p>
                                </NavLink>
                              </Col>
                            </Row>
                          </div>
                        }

                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
}

export default SupportReply