import React, { useEffect, useState } from "react"
import WalletOption from "../options"
import WalletHistory from "./wallet"
import MT5History from "./mt5"

const History = ({ refresh }) => {
  const [option, setOption] = useState()

  // reset options on tab change
  useEffect(() => {
    setOption(null)
  }, [refresh])

  return (
    <>
      {option == null ? (
        <WalletOption setOption={setOption} type="history" />
      ) : (
        <>
          {option === 1 ? (
            <WalletHistory setOption={setOption} />
          ) : (
            <MT5History setOption={setOption} />
          )}
        </>
      )}
    </>
  )
}

export default History
