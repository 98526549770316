import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import LiveSettings from '../common/settings'
import MasterPassword from '../common/settings/master_password'
import InvestorPassword from '../common/settings/investor_password'

import { ResponsiveDiv, StyledRow } from '../common/components.style'

import AccountService from '../../network/services/account'
import EventService from '../../network/services/event'
import WalletService from '../../network/services/wallet'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
import _ from 'lodash'

import { Steps } from 'intro.js-react';

const ResponsiveCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
`

const ResponsiveVerifyButton = styled(Button)`
  background-color: #103c5a !important;
  border-color: #103c5a !important;
  margin-left: 12px;
  @media (max-width: 575px) {
    align-self: center;
  }
`


const ResponsiveParagraph = styled.p`
  margin: 0;
  text-align: center;
  @media (max-width: 575px) {
    margin-bottom: 8px;
  }
  `


const CompetitionLiveAccounts = () => {
  let history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currencies = useSelector((state) => state.currency.currencies)
  const total_deposit = useSelector((state) => state.wallet.totalDeposit)

  const currentDate = moment().utc().format('YYYY-MM-DD')

  const [myCompetitionRegistrations, setMyCompetitionRegistrations] = useState(null)
  const verification = useSelector((state) => _.last(state.user.verifications))
  const competitions = useSelector((state) => state.event?.competitions.competitions)


  const [stepsEnabled, setStepsEnabled] = useState(true)
  const steps = [
    {
      element: '#first-step',
      intro: t('intro.contestAccount.step1'),
    },
    {
      element: '#event-banner',
      intro: t('intro.contestAccount.step2'),
    },
    {
      element: '#step-after-register',
      intro: t('intro.contestAccount.step3'),
    },
    {
      element: '#deposit-button',
      intro: t('intro.contestAccount.step4'),
    }

  ]

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  // competition accounts
  const competitionAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter((e) => e.account_type === 3)
    } else if (state.account.accounts?.length === 0) {
      return []
    } else {
      return null
    }
  })
  const selectedAccount = useSelector((state) => state.dashboard.account)

  const init = async () => {
    const accountResponse = await AccountService.getContestAccount()
    const walletResponse = await WalletService.get()

    dispatch({ type: 'SET_TOTAL_DEPOSIT', totalDeposit: walletResponse.total_deposit })
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  // modal
  const [showModal, setShowModal] = useState(false)
  const [selectedSetting, setSelectedSetting] = useState(null)
  const toggleModal = () => {
    setShowModal(!showModal)
  }
  let settings = [
    {
      id: 1,
      title: t('live.titleChangeMaster'),
      cta: <MasterPassword setShowModal={setShowModal} />
    },
    {
      id: 2,
      title: t('live.titleChangeInvestor'),
      cta: <InvestorPassword setShowModal={setShowModal} />
    },
    // {
    //   id: 3,
    //   title: t('live.titleChangeLeverage'),
    //   cta: <ChangeLeverage setShowModal={setShowModal} />
    // }
  ]

  if (total_deposit <= 0) {
    settings.pop()
    console.log('when')
  }

  useEffect(() => {
    if (selectedSetting != null && selectedAccount != null) {
      setShowModal(true)
    }
  }, [selectedSetting, selectedAccount])

  useEffect(() => {
    if (showModal === false) {
      // fetch account
      if (selectedAccount != null) {
        init()
      }

      setSelectedSetting(null)
      dispatch({
        type: 'SET_DASHBOARD_ACCOUNT',
        account: null
      })
    }
    // eslint-disable-next-line
  }, [showModal])

  const fetchMyRegistrationRecord = async () => {
    const myRegistrations = []
    for (let i = 0; i < competitions.length; i++) {
      const record = await EventService.getMyCompetition({ competition_id: competitions[i].id })
      myRegistrations.push(record?.submitted_registrations)
    }

    setMyCompetitionRegistrations(myRegistrations)

  }

  useEffect(() => {
    // Hardcode competition id
    fetchMyRegistrationRecord()
    // eslint-disable-next-line
  }, [])


  return (
    <>
      {competitionAccounts != null ? (
        <>
          {/* {verification?.status === 1 && myCompetitionRegistrations && (
            <>
              {competitions.map((competition, index) => (
                <Card id='event-banner' style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                  <CardBody
                    className="p-4"
                    style={{
                      textAlign: 'center',
                      backgroundColor:
                        myCompetitionRegistrations[index][0]?.status === 0
                          ? '#fff3cd'
                          : myCompetitionRegistrations[index][0]?.status === 1
                            ? '#D4EDD3'
                            : myCompetitionRegistrations[index][0]?.status === 2 && '#f8d7d9',

                      borderRadius: '5px',
                      color:
                        myCompetitionRegistrations[index][0]?.status === 0
                          ? '#8c670e'
                          : myCompetitionRegistrations[index][0]?.status === 1
                            ? '#1e6137'
                            : myCompetitionRegistrations[index][0]?.status === 2 && '#7e2a26'
                    }}
                  >
                    <ResponsiveCard>
                      <ResponsiveParagraph>
                        <span style={{ fontWeight: 'bold' }}>
                          {competition.status === 1 &&
                            currentDate < moment(competition.start_date).utcOffset(0).format('YYYY-MM-DD')
                            ? competition.name + ` ${t('dashboard.isOpenNow')}`
                            : competition.name + ` ${t('dashboard.isStartNow')}`}
                        </span>
                        <br />
                        {currentDate <=
                          moment(competition.end_registration_date).utcOffset(0).format('YYYY-MM-DD')
                          ? `${t('dashboard.RegistrationDeadline', {
                            date: moment(competition.end_registration_date).utcOffset(0).format('YYYY-MM-DD')
                          })}(GMT+0)`
                          : `${t('dashboard.CompetitionStartFrom')} ${moment(
                            competition.start_date
                          ).utcOffset(0).format('YYYY-MM-DD')}(GMT+0) ${t('dashboard.until')} ${moment(
                            competition.end_date
                          ).utcOffset(0).format('YYYY-MM-DD')}(GMT+0)`}
                      </ResponsiveParagraph>

                      <ResponsiveParagraph>
                        <span style={{ fontWeight: 'bold' }}>
                          {t('dashboard.RegistrationStatus')}
                        </span>
                        <br />{' '}
                        {myCompetitionRegistrations[index]?.length > 0
                          ? myCompetitionRegistrations[index][0].status === 0
                            ? t('dashboard.Pending')
                            : myCompetitionRegistrations[index][0].status === 1
                              ? t('dashboard.Success')
                              : myCompetitionRegistrations[index][0].status === 2 &&
                              `${t('dashboard.RejectBecauseOf')} ${myCompetitionRegistrations[index][0]?.reason
                                ? myCompetitionRegistrations[index][0]?.reason
                                : ''
                              }`
                          : t('dashboard.YouHaveNotJoin')}{' '}
                        {currentDate <=
                          moment(competition.end_registration_date).utcOffset(0).format('YYYY-MM-DD')
                          ? ''
                          : `(${t('event.RegistrationIsClosed')})`}
                      </ResponsiveParagraph>

                      <ResponsiveVerifyButton
                        color="primary"
                        onClick={() => {
                          competition.account_type_joined === 3
                            ? navigate(`${process.env.PUBLIC_URL}/event/road_to_champion`)
                            : navigate(`${process.env.PUBLIC_URL}/event/campus_competition`)
                        }}
                      >
                        {myCompetitionRegistrations[index]?.length > 0 ||
                          currentDate >
                          moment(competition.end_registration_date).utcOffset(0).format('YYYY-MM-DD')
                          ? t('dashboard.View')
                          : t('dashboard.Participate')}
                      </ResponsiveVerifyButton>
                    </ResponsiveCard>
                  </CardBody>
                </Card>
              ))}
            </>
          )} */}

          <Row style={{ justifyContent: 'flex-end', marginRight: '0', marginLeft: '0' }}>
            <p>
              {t('competition.ContestAccount')}: {competitionAccounts?.length} / 3
              </p>
          </Row>

          <Row>
            <Col xl="6">
              <Card style={{ height: 'fit-content', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>

                <CardBody className="text-left">
                  <h5>{t('competition.TNC')}</h5>
                  <p id='step-after-register' className="m-t-20">1. {t('competition.AfterRegisterAContest')} {' '}
                    <Link to='/event'>{t('competition.contestPage')}</Link> {' '}
                    {t('competition.toParticipateContest')}
                  </p>
                  <p >

                    2. {t('live.masterPasswordIs')} <strong>{t('competition.Trading')}</strong>{' '}
                    {t('competition.ForMT5Account')}.
                    </p>
                  <p>
                    3. {t('live.inverterPasswordIs')}{' '}
                    <strong>{t('competition.Reviewing')}</strong> {t('competition.ForMT5Account')}
                      .
                    </p>
                  <p>4. {t('competition.ParticipateContest')}</p>
                  <p>5. {t('competition.MaximumAccounts')}</p>
                  <p>6. {t('competition.ContestAccountIsNot')}</p>
                  <p>7. {t('competition.EachContestAccountCanOnly')}</p>
                  <p>8. {t('competition.WithdrawCondition')}</p>
                  <p>9. {t('competition.RegisterContestRegistrationPeriod')}</p>
                  {/* <p>6. </p> */}
                </CardBody>
              </Card>
            </Col>

            {competitionAccounts?.length > 0 ? (
              competitionAccounts.map((account) => {
                const currency = currencies.find((e) => {
                  return e.id === account.currency_id
                })

                return (
                  <Col xl="6" key={account.id}>
                    <Card className="card-absolute" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                      <CardHeader className="bg-primary" style={{ boxShadow: '4px 4px 7px 0 rgb(0 0 0 / 20%)' }}>
                        <h6 style={{ margin: 0 }}>
                          {`#${account.account_login} ${account.plan?.name}`}
                        </h6>
                      </CardHeader>
                      <div className="card-right">
                        <ResponsiveDiv>
                          <Button
                            id='deposit-button'
                            onClick={() => {
                              navigate(`${process.env.PUBLIC_URL}/financial`)
                            }}
                          >
                            {t('live.deposit')}
                          </Button>

                          <LiveSettings
                            settings={settings}
                            setSelectedSetting={setSelectedSetting}
                            account={account}
                          />


                        </ResponsiveDiv>
                      </div>
                      <CardBody className="text-center">
                        <StyledRow>
                          <Col xs="12">
                            <h6>{account.plan?.name ?? '-'}</h6>
                            <p>{t('live.accountType')}</p>
                          </Col>
                          <Col xs="12">
                            <h6>
                              {account?.leverage === null ? '-' : `1:${account?.leverage}` ?? '-'}
                            </h6>
                            <p>{t('live.leverage')}</p>
                          </Col>
                          <Col xs="12">
                            <h6>{`${account?.balance === null
                              ? '-'
                              : account?.balance?.toFixed(2) ?? '-'
                              } ${currency?.name ?? ''}`}</h6>
                            <p>{t('live.balance')}</p>
                          </Col>
                          <Col xs="12">
                            <h6>{`${account?.credit ?? '-'} ${currency?.name ?? ''}`}</h6>
                            <p>{t('live.credit')}</p>
                          </Col>
                        </StyledRow>
                      </CardBody>

                      {competitions?.length > 0 && localStorage.getItem('context-account-onboarding') === null &&
                        <Steps
                          enabled={stepsEnabled}
                          steps={steps}
                          initialStep={0}
                          onExit={() => {
                            localStorage.setItem('context-account-onboarding', true)
                            setStepsEnabled(false)
                          }}
                          options={
                            {
                              showProgress: true,
                              doneLabel: 'Understand',
                              exitOnOverlayClick: false
                            }
                          }
                        />
                      }
                    </Card>
                  </Col>

                )
              })
            ) : (
                <Col xl="6">
                  <Card>
                    <CardBody className="text-center">
                      <h6 style={{ textAlign: 'center' }}>
                        {t('competition.NoActive')}
                        {sessionStorage.getItem('language') === 'en' ? <br /> : ''}
                        {t('competition.ContestAccount')}{' '}
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
              )}
          </Row>
        </>
      ) : (
          <div style={{ textAlign: 'center' }}>
            <Spinner />
          </div>
        )}

      {selectedSetting !== null && selectedAccount !== null && (
        <Modal isOpen={showModal} backdrop={true} centered={true}>
          <ModalHeader toggle={toggleModal}>{settings[selectedSetting].title}</ModalHeader>
          <ModalBody>{settings[selectedSetting].cta}</ModalBody>
        </Modal>
      )}
    </>
  )
}

export default CompetitionLiveAccounts
