import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

if (sessionStorage.getItem('language') == 'null' || sessionStorage.getItem('language') == null) {
  if (localStorage.getItem('i18nextLng') != 'null' && localStorage.getItem('i18nextLng') != null) {
    sessionStorage.setItem('language', localStorage.getItem('i18nextLng'))
  } else {
    sessionStorage.setItem('language', 'en')
  }
}

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: sessionStorage.getItem('language') ?? 'en',
  debug: false,
  detection: {
    order: ['queryString', 'cookie']
  },
  cookieMinutes: 10,
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  }
})

export default i18n