import { SET_ACCOUNTS } from "../actionTypes";

const initial_state = {
  accounts: null,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_ACCOUNTS:
      return { ...state, accounts: action.accounts };

    default:
      return { ...state };
  }
};
