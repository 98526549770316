import React, { useEffect, useState, useMemo } from 'react'
import { Row, Form, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import StepZilla from 'react-stepzilla'
import QRCode from 'react-qr-code'
import SweetAlert from 'sweetalert2'

import SelectCurrency from './currency'
import SelectPaymentMethod from './payment'
import ChooseAmount from './amount'

import WalletService from '../../../../network/services/wallet'
import PaymentService from '../../../../network/services/payment'
import FilesService from '../../../../network/services/files'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
const DepositModal = ({ qrCode, showModal, toggle }) => {
  //share data

  const { t } = useTranslation()
  const deposit = useSelector((state) => state.deposit)
  const payments = useSelector((state) => state.payment.payments)
  const selectedPayment = useMemo(() => {
    return payments.find((e) => e.id === deposit?.wallet?.method?.id)
    // eslint-disable-next-line
  }, [deposit])
  const { watch } = useFormContext()
  const watchAmount = watch('amount')
  return (
    <Modal isOpen={showModal} backdrop={true} centered={true}>
      <ModalHeader toggle={toggle}>{t('financial.pleaseDepositToTheAddress')}</ModalHeader>
      <ModalBody>
        <div style={{ textAlign: 'center' }}>
          <p>{qrCode}</p>
          {qrCode && <QRCode value={qrCode} />}
          {watchAmount != null && (
            <p>
              {`${t(
                'financial.pleasePay'
              )} ${selectedPayment?.currency?.abbreviation?.toUpperCase()} ${
                watchAmount > 0 ? watchAmount : '0.00'
              }`}
            </p>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}
const WalletDeposit = ({ setOption }) => {
  let history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const deposit = useSelector((state) => state.deposit)
  const methods = useForm()
  const [stage, setStage] = useState(0)

  // modal
  const [showModal, setShowModal] = useState(false)
  const [qrCode, setQrCode] = useState(null)
  const toggle = () => {
    if (showModal === true) {
      methods.reset()
    }

    setShowModal(!showModal)
  }

  useEffect(() => {
    // user close modal
    if (showModal === false && qrCode != null) {
      window.location.reload()
    }
    // eslint-disable-next-line
  }, [showModal])

  // use redux for payment_id & wallet_id
  // because form doesn't work with stepzilla
  const handleDeposit = async (data) => {
    if (deposit.wallet.method.handle !== 'mcpayment_credit_card') {
      //If payment method is not credit card mc pament
      SweetAlert.fire({
        title: t('financial.areYouSureDeposit'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('financial.confirm'),
        cancelButtonText: t('financial.cancel'),
        reverseButtons: true
      }).then(async (result) => {
        if (result.value) {
          try {
            data['wallet_id'] = deposit.wallet.id
            data['payment_id'] = deposit.wallet.method.id

            if (data.file?.length > 0) {
              const receipt = await FilesService.create(data.file[0])
              // console.log(receipt)
              data['attachment'] = receipt.data
            }

            if (data !== '') {
              let result = await WalletService.deposit(data)
              // console.log(result)
              try {
                // awepay
                if (deposit.wallet.method.handle === 'awepay') {
                  document.write(result.body_html)
                }

                // bluepay
                if (deposit.wallet.method.handle === 'bluepay') {
                  setQrCode(result.body?.paymentAddr)
                  setShowModal(true)
                }

                // wire
                if (deposit.wallet.method.handle === 'wire') {
                  if (result.code === 'deposit.submitted.successful') {
                    SweetAlert.fire({
                      title: t('financial.depositSubmittedSuccesfully'),
                      icon: 'success'
                    }).then(() => {
                      window.location.reload()
                    })
                  }
                }

                if (
                  deposit.wallet.method.handle === 'mcpayment' ||
                  deposit.wallet.method.handle === 'mcpayment_ewallet' ||
                  deposit.wallet.method.handle === 'epay'
                ) {
                  window.location.href = result.payment_url
                }
              } catch (error) {
                // console.log(error)

                // SweetAlert.fire({
                //   title: error.message,
                //   icon: 'error'
                // })
                const errorCode = error.code
                const errorTranslate = error.translate_params
                SweetAlert.fire({
                  title: t(errorCode, errorTranslate),
                  icon: 'error'
                })
              }
            } else {
              methods.errors.showMessages()
            }
          } catch (error) {
            // SweetAlert.fire({
            //   title: error.message,
            //   icon: 'error'
            // })
            const errorCode = error.code
            const errorTranslate = error.translate_params
            SweetAlert.fire({
              title: t(errorCode, errorTranslate),
              icon: 'error'
            })
          }
        }
      })
    } else if (deposit.wallet.method.handle === 'mcpayment_credit_card') {
      // payment is creidtCard_mcpayment
      // console.log('i am data data1234', data)
      SweetAlert.fire({
        title: 'Important Note',
        html: `
            
                        <div class='text-left' style="margin-top:20px;">
                            
                        <p>
                          1. ${t('financial.ThisSubmissionWillNotBeDirectly')}
                        </p>
                        <p>
                          2. ${t('financial.YouWouldStillNeedToEnter')}
                        </p>
                        <p style='color:red;'>
                          3. ${t('financial.MakeSureTheCreditCard')}
                        </p>
                        <p style='color:red;'>
                          4. ${t('financial.IfYouTypeDifferent')}
                        </p>
                        <p>
                          5. ${t('financial.YouMayOnlyWithdrawYourFund')}
                        </p>
        
                        </div>

                        <div class='text-center'  style="margin-top:20px;">
                        ${t('financial.Type')} <strong>"CONFIRM"</strong> ${t(
          'financial.toConfirm'
        )}
                    </div>
                        `,
        input: 'text',
        inputValue: '',
        inputPlaceholder: '',
        inputValidator: (result) => {
          if (result.toLocaleLowerCase() !== 'confirm' && result !== '同意') {
            return t('financial.PleaseEnterThe')
          }
        },
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('financial.confirm'),
        cancelButtonText: t('financial.cancel'),
        reverseButtons: true
      }).then(async (result) => {
        if (result.value) {
          try {
            data['wallet_id'] = deposit.wallet.id
            data['payment_id'] = deposit.wallet.method.id

            if (data !== '') {
              let result = await WalletService.deposit(data)

              if (
                deposit.wallet.method.handle === 'mcpayment' ||
                deposit.wallet.method.handle === 'mcpayment_ewallet' ||
                deposit.wallet.method.handle === 'mcpayment_credit_card'
              ) {
                window.location.href = result.payment_url
              }
            } else {
              methods.errors.showMessages()
            }
          } catch (error) {
            // SweetAlert.fire({
            //   title: error.message,
            //   icon: 'error'
            // })
            const errorCode = error.code
            const errorTranslate = error.translate_params
            SweetAlert.fire({
              title: t(errorCode, errorTranslate),
              icon: 'error'
            })
          }
        }
      })
    }
  }

  const init = async () => {
    const walletResponse = await WalletService.get()
    // console.log(walletResponse)
    dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })

    const paymentResponse = await PaymentService.getAll()
    // console.log(paymentResponse)
    dispatch({ type: 'SET_PAYMENTS', payments: paymentResponse.payments })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col
              sm={12}
              md={4}
              className={`u-pearl ${stage === 0 && 'current'} ${stage > 0 && 'done'}`}
            >
              <span className="u-pearl-number">1</span>
              <span className="u-pearl-title">{t('financial.selectCurrency')}</span>
            </Col>
            <Col
              sm={12}
              md={4}
              className={`u-pearl ${stage === 1 && 'current'} ${stage > 1 && 'done'}`}
            >
              <span className="u-pearl-number">2</span>
              <span className="u-pearl-title">{t('financial.selectPaymentMethod')}</span>
            </Col>
            <Col sm={12} md={4} className={`u-pearl ${stage === 2 && 'current'}`}>
              <span className="u-pearl-number">3</span>
              <span className="u-pearl-title">{t('financial.chooseAmount')}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <FormProvider {...methods}>
        <Form className="theme-form" onSubmit={methods.handleSubmit(handleDeposit)}>
          <StepZilla
            steps={[
              {
                name: t('financial.Step1'),
                component: <SelectCurrency />
              },
              {
                name: t('financial.Step2'),
                component: <SelectPaymentMethod />
              },
              {
                name: t('financial.Step3'),
                component: <ChooseAmount />
              }
            ]}
            showSteps={false}
            onStepChange={(index) => {
              setStage(index)
              history.push({
                pathname: '/financial',
                search: `?step=${index}`
              })
            }}
            backButtonText={t('financial.Previous')}
            nextButtonText={t('financial.Next')}
          />
          <DepositModal qrCode={qrCode} showModal={showModal} toggle={toggle} />
        </Form>
      </FormProvider>

      {stage === 0 && (
        <Button
          color="primary"
          onClick={() => {
            setOption(null)
          }}
        >
          {t('financial.back')}
        </Button>
      )}

      {/* Add gap */}
      <div style={{ padding: 24, clear: 'both' }} />
    </>
  )
}

export default WalletDeposit
