import React, { useState, useEffect } from 'react'
import { Row, Col, Form, FormGroup, Label, Input, Button, Spinner, Tooltip } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import SweetAlert from 'sweetalert2'

import AccountService from '../../../../network/services/account'
import TransferService from '../../../../network/services/transfer'
import { useTranslation } from 'react-i18next'

import PAMMSUBSCRIBE from '../../../../network/services/pammSubscription'

import { AlertCircle } from 'react-feather'
import moment from 'moment'

import { HelpCircle } from 'react-feather'

const TransferForm = () => {
  const { t } = useTranslation()
  const currencies = useSelector((state) => state.currency.currencies)
  const [accounts, setAccounts] = useState(null)


  const [nextSettlementTooltipOpen, setNextSettlementTooltipOpen] = useState(false);
  const nextSettlementToggle = () => setNextSettlementTooltipOpen(!nextSettlementTooltipOpen);
  const [activePammAccountId, setActivePammAccountId] = useState([])

  const [selectedAccountFromPamm, setSelectedAccountFromPamm] = useState([])
  const [selectedAccountId, setSelectedAccountId] = useState('')
  const [unsubscribeIsPendingId, setunsubscribeIsPendingId] = useState([])

  const [isSubscribePamm, setIsSubscribePamm] = useState(false)

  const [isBusy, setIsBusy] = useState(false)

  const fetchActivePammAccount = async () => {
    const response = await PAMMSUBSCRIBE.getAll()

    const isActiveAccount = response?.active.map((item) => item.account_id)
    const isUnsubscribeStillPending = response?.pending.map((item) => item?.status === 3 && item.account_id)

    setActivePammAccountId(isActiveAccount.concat(isUnsubscribeStillPending))
    setunsubscribeIsPendingId(isUnsubscribeStillPending)
    setSelectedAccountFromPamm(response?.active?.filter(item => item?.account_id === selectedAccountId))
  }


  useEffect(() => {
    fetchActivePammAccount()
  }, [selectedAccountId])

  const checkIsSubscribePamm = (e) => {
    if (activePammAccountId.includes(parseInt(e))) {
      setIsSubscribePamm(true)
      setSelectedAccountId(parseInt(e))
    } else {
      setIsSubscribePamm(false)
    }
  }

  // Condition check the first time to show the error red pamm message
  let today = moment().format('YYYY-MM-DD')

  let settlementDate
  let settlementDateTomorrow1
  let settlementDateTomorrow2

  if (selectedAccountFromPamm[0]?.pammManager?.settlement_period == "Monthly") {
    if (moment().format('YYYY-MM-DD') != moment().startOf('month').format('YYYY-MM-DD') &&
      today != moment().startOf('month').add(1, 'days').format('YYYY-MM-DD') &&
      today != moment().startOf('month').add(2, 'days').format('YYYY-MM-DD')) {

      settlementDate = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD')
      settlementDateTomorrow1 = moment().add(1, 'M').startOf('month').add(1, 'days').format('YYYY-MM-DD')
      settlementDateTomorrow2 = moment().add(1, 'M').startOf('month').add(2, 'days').format('YYYY-MM-DD')
    } else {
      settlementDate = moment().startOf('month').format('YYYY-MM-DD')
      settlementDateTomorrow1 = moment().startOf('month').add(1, 'days').format('YYYY-MM-DD')
      settlementDateTomorrow2 = moment().startOf('month').add(2, 'days').format('YYYY-MM-DD')
    }
  } else {
    settlementDate = moment(selectedAccountFromPamm[0]?.pammManager?.settlement_date).format("YYYY-MM-DD")
    settlementDateTomorrow1 = moment(selectedAccountFromPamm[0]?.pammManager?.settlement_date).add(1, 'days').format('YYYY-MM-DD')
    settlementDateTomorrow2 = moment(selectedAccountFromPamm[0]?.pammManager?.settlement_date).add(2, 'days').format('YYYY-MM-DD')
  }

  // form
  const { register, handleSubmit, errors, reset } = useForm()
  const handleTransfer = async (data) => {

    const checkAccountCredit = await AccountService.checkAccountCredit(data?.from_account_id)
    const selectedFromAccount = accounts.filter(account => account?.id === parseInt(data?.from_account_id))
    const selectedToAccount = accounts.filter(account => account?.id === parseInt(data?.to_account_id))



    // Condition check the pop up
    let settlementDate_tomorrow1 =
      selectedAccountFromPamm[0]?.pammManager?.settlement_period == 'Monthly'
        ? moment().startOf('month').add(1, 'days').format('YYYY-MM-DD')
        : moment(selectedAccountFromPamm[0]?.pammManager?.settlement_date).add(1, 'days').format('YYYY-MM-DD')

    let settlementDate_tomorrow2 =
      selectedAccountFromPamm[0]?.pammManager?.settlement_period == 'Monthly'
        ? moment().startOf('month').add(2, 'days').format('YYYY-MM-DD')
        : moment(selectedAccountFromPamm[0]?.pammManager?.settlement_date).add(2, 'days').format('YYYY-MM-DD')

    let is_action_date_1 = today === settlementDate_tomorrow1
    let is_action_date_2 = today === settlementDate_tomorrow2
    console.log(selectedToAccount[0].balance < 0, 123)

    //check is negative balance
    if (selectedToAccount[0].balance < 0) {
      SweetAlert.fire({
        title: `<div style="color:red">${t("financial.YourAccountBalanceisNegative")}</div>`,
        html: `${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
                <br/>
                <br/>
                <div>
                          <p style="margin-bottom:0px;"
                          >*${t("financial.KindlyTakeNote")}*</p>
                          <p>
                              1. ${t("financial.TheBalanceAfterYouTransferredWillNot")}<br/>${t("financial.beTheSameAsYourTransferAmount")}
                              <br/>
                              <br/>
                              <span style="color:#f9b600">${t("financial.ForExample")}:</span> $${selectedToAccount[0].balance}(${t("financial.ToAccountBalance")}) + <br/>$${data.amount} (${t("financial.TransferAmount")}) = $${(parseFloat(selectedToAccount[0].balance)  + parseFloat (data.amount)).toFixed(2) } (${t("financial.ResultingBalance")})
                          </p>
                        </div>
                `,
        input: 'text',
        inputValue: '',
        inputPlaceholder: '',
        inputValidator: (result) => {
          if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
            return t("financial.PleaseEnterThe")
          }
        },
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t("financial.confirm"),
        cancelButtonText: t("financial.cancel"),
        reverseButtons: true,

      }).then(async (result) => {
        if (result.value) {
          if (isSubscribePamm &&
            !is_action_date_1 && !is_action_date_2
          ) {
            // PAMM Subscriber who contain credit 

            if (checkAccountCredit?.credit_balance !== 0) {
              SweetAlert.fire({
                title: `#${selectedFromAccount[0]?.account_login} ${t("financial.contain")} $${checkAccountCredit?.credit_balance} ${t("financial.credit")} `,
                html: `${t("financial.Type")} "<strong>${t("financial.AGREE")}</strong>" ${t("financial.toConfirm")} 
                          <br/>
                          <p style="color:red;">${t("financial.OnceYouConfirm")} <strong>${t("financial.VOIDED")}</strong> ${t("financial.partiallyOrEntirely")}</p>
                          <div>
                                  <p style="margin-bottom:0px;"
                                  >*${t("financial.KindlyTakeNote")}*</p>
                                  <p>
                                      1. ${t("financial.ByTypingAGREE")}
                                      <br/>
                                      2. ${t("financial.TheCreditWillOnly")}
                                  </p>
                                </div>
                          `,
                input: 'text',
                inputValue: '',
                inputPlaceholder: '',
                inputValidator: (result) => {
                  if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                    return t("financial.PleaseEnterThe")
                  }
                },
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t("financial.confirm"),
                cancelButtonText: t("financial.cancel"),
                reverseButtons: true,

              }).then(async (result) => {
                if (result.value) {
                  SweetAlert.fire({
                    title: `${t("financial.YouAreSubscribingTransfer")} ${selectedAccountFromPamm[0]?.pammManager?.penalty_percent}% ${t("financial.fundDirectly")}`,
                    html: `
                    <div style="margin-top:10px;">
                      ${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
                    </div>
      
                    <div >
                                <p style="margin-bottom:0px;margin-top:10px;"
                                >*${t("financial.KindlyTakeNote")}*</p>
                                <p>
                                    1. ${t("financial.ByTypingAgreeDeduct")} ${selectedAccountFromPamm[0]?.pammManager?.penalty_percent}% ${t('financial.fundDirectly')}
                                    <br/>
                                    2. ${t("financial.IfYouHave")} <a href='/support' target="_blank">${t("financial.customerService")}</a> ${t("financial.orReferThe")} <a
                                    href="${selectedAccountFromPamm[0]?.op_url}"
                                    target="_blank"
                                >
                                    <span>5.4 ${t("pamm.termsConditions")}</span>
                                </a>.  
                                </p>
                              </div>
      
                              <div class='text-center'>
                              
                      </div>
      
                    
                    
                `,
                    input: 'text',
                    inputValue: '',
                    inputPlaceholder: '',
                    inputValidator: (result) => {
                      if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                        return t("financial.PleaseEnterThe")
                      }
                    },
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t("financial.confirm"),
                    cancelButtonText: t("financial.cancel"),
                    reverseButtons: true
                  }).then(async (result) => {
                    if (result.value) {
                      SweetAlert.fire({
                        title: t("financial.areYouSureTransfer"),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: t("financial.confirm"),
                        cancelButtonText: t("financial.cancel"),
                        reverseButtons: true
                      }).then(async (result) => {
                        if (result.value) {
                          try {
                            if (data !== '') {
                              const result = await TransferService.createMt5Transfer({
                                from_account_id: data.from_account_id,
                                amount: data.amount,
                                to_account_id: data.to_account_id,
                                is_voiding_credit: true,
                                negative_balance_deposit: true
                              })
                              // console.log(result)

                              // success
                              if (result?.account_transfer?.id) {
                                reset()

                                SweetAlert.fire({
                                  title: t("financial.transferSuccessfully"),
                                  text: t("financial.yourRequestWillBeReviewByAdmin"),
                                  icon: 'success'
                                }).then(() => {
                                  window.location.reload()
                                })
                              }
                            } else {
                              errors.showMessages()
                            }
                          } catch (error) {
                            // SweetAlert.fire({
                            //   title: error.message,
                            //   icon: 'error'
                            // })
                            const errorCode = error.code
                            const errorTranslate = error.translate_params
                            SweetAlert.fire({
                              title: t(errorCode, errorTranslate),
                              icon: 'error'
                            })
                          }
                        }
                      })
                    }
                  })
                }
              })
            } else if (unsubscribeIsPendingId.includes(selectedAccountId)) {
              //user's unsubsribe is pending

              SweetAlert.fire({
                title: `You are unubscribe pending with PAMM`,
                html: `
                    <p>Please wait for approved to process this operation</p>
                  `,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Close',
                cancelButtonText: t('financial.cancel'),
                reverseButtons: true
              })

            }

            else {
              // PAMM Subscriber who do not contain credit 
              SweetAlert.fire({
                title: `${t("financial.YouAreSubscribingTransfer")} ${selectedAccountFromPamm[0]?.pammManager?.penalty_percent}% ${t("financial.fundDirectly")}`,
                html: `
                <div style="margin-top:10px;">
                  ${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
                </div>
      
                <div >
                            <p style="margin-bottom:0px;margin-top:10px;"
                            >*${t("financial.KindlyTakeNote")}*</p>
                            <p>
                                1. ${t("financial.ByTypingAgreeDeduct")} ${selectedAccountFromPamm[0]?.pammManager?.penalty_percent}% ${t('financial.fundDirectly')}
                                <br/>
                                2. ${t("financial.IfYouHave")} <a href='/support' target="_blank">${t("financial.customerService")}</a> ${t("financial.orReferThe")} <a
                                href="${selectedAccountFromPamm[0]?.op_url}"
                                target="_blank"
                            >
                                <span>5.4 ${t("pamm.termsConditions")}</span>
                            </a>.  
                            </p>
                          </div>
      
                          <div class='text-center'>
                          
                  </div>
      
                
                
            `,
                input: 'text',
                inputValue: '',
                inputPlaceholder: '',
                inputValidator: (result) => {
                  if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                    return t("financial.PleaseEnterThe")
                  }
                },
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t("financial.confirm"),
                cancelButtonText: t("financial.cancel"),
                reverseButtons: true
              }).then(async (result) => {
                if (result.value) {
                  SweetAlert.fire({
                    title: t("financial.areYouSureTransfer"),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t("financial.confirm"),
                    cancelButtonText: t("financial.cancel"),
                    reverseButtons: true
                  }).then(async (result) => {
                    if (result.value) {
                      try {
                        if (data !== '') {
                          const result = await TransferService.createMt5Transfer({
                            from_account_id: data.from_account_id,
                            amount: data.amount,
                            to_account_id: data.to_account_id,
                            is_voiding_credit: false,
                            negative_balance_deposit: true
                          })
                          // console.log(result)

                          // success
                          if (result?.account_transfer?.id) {
                            reset()

                            SweetAlert.fire({
                              title: t("financial.transferSuccessfully"),
                              text: t("financial.yourRequestWillBeReviewByAdmin"),
                              icon: 'success'
                            }).then(() => {
                              window.location.reload()
                            })
                          }
                        } else {
                          errors.showMessages()
                        }
                      } catch (error) {
                        // SweetAlert.fire({
                        //   title: error.message,
                        //   icon: 'error'
                        // })
                        const errorCode = error.code
                        const errorTranslate = error.translate_params
                        SweetAlert.fire({
                          title: t(errorCode, errorTranslate),
                          icon: 'error'
                        })
                      }
                    }
                  })
                }
              })
            }


          } else {
            // Normal User who  contain credit 
            if (checkAccountCredit?.credit_balance !== 0) {
              SweetAlert.fire({
                title: `#${selectedFromAccount[0]?.account_login} ${t("financial.contain")} $${checkAccountCredit?.credit_balance} ${t("financial.credit")} `,
                html: `${t("financial.Type")} "<strong>${t("financial.AGREE")}</strong>" ${t("financial.toConfirm")} 
                          <br/>
                          <p style="color:red;">${t("financial.OnceYouConfirm")} <strong>${t("financial.VOIDED")}</strong> ${t("financial.partiallyOrEntirely")}</p>
                          <div>
                                  <p style="margin-bottom:0px;"
                                  >*${t("financial.KindlyTakeNote")}*</p>
                                  <p>
                                      1. ${t("financial.ByTypingAGREE")}
                                      <br/>
                                      2. ${t("financial.TheCreditWillOnly")}
                                  </p>
                                </div>
                          `,
                input: 'text',
                inputValue: '',
                inputPlaceholder: '',
                inputValidator: (result) => {
                  if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                    return t("financial.PleaseEnterThe")
                  }
                },
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t("financial.confirm"),
                cancelButtonText: t("financial.cancel"),
                reverseButtons: true,

              }).then(async (result) => {
                if (result.value) {
                  SweetAlert.fire({
                    title: t("financial.areYouSureTransfer"),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t("financial.confirm"),
                    cancelButtonText: t("financial.cancel"),
                    reverseButtons: true
                  }).then(async (result) => {
                    if (result.value) {
                      try {
                        if (data !== '') {
                          const result = await TransferService.createMt5Transfer({
                            from_account_id: data.from_account_id,
                            amount: data.amount,
                            to_account_id: data.to_account_id,
                            is_voiding_credit: true,
                            negative_balance_deposit: true
                          })
                          // console.log(result)

                          // success
                          if (result?.account_transfer?.id) {
                            reset()

                            SweetAlert.fire({
                              title: t("financial.transferSuccessfully"),
                              text: t("financial.yourRequestWillBeReviewByAdmin"),
                              icon: 'success'
                            }).then(() => {
                              window.location.reload()
                            })
                          }
                        } else {
                          errors.showMessages()
                        }
                      } catch (error) {
                        // SweetAlert.fire({
                        //   title: error.message,
                        //   icon: 'error'
                        // })
                        const errorCode = error.code
                        const errorTranslate = error.translate_params
                        SweetAlert.fire({
                          title: t(errorCode, errorTranslate),
                          icon: 'error'
                        })
                      }
                    }
                  })
                }
              })
            } else {
              // Normal User who do not contain credit 
              SweetAlert.fire({
                title: t("financial.areYouSureTransfer"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t("financial.confirm"),
                cancelButtonText: t("financial.cancel"),
                reverseButtons: true
              }).then(async (result) => {
                if (result.value) {
                  try {
                    if (data !== '') {
                      const result = await TransferService.createMt5Transfer({
                        from_account_id: data.from_account_id,
                        amount: data.amount,
                        to_account_id: data.to_account_id,
                        is_voiding_credit: false,
                        negative_balance_deposit: true
                      })
                      // console.log(result)

                      // success
                      if (result?.account_transfer?.id) {
                        reset()

                        SweetAlert.fire({
                          title: t("financial.transferSuccessfully"),
                          text: t("financial.yourRequestWillBeReviewByAdmin"),
                          icon: 'success'
                        }).then(() => {
                          window.location.reload()
                        })
                      }
                    } else {
                      errors.showMessages()
                    }
                  } catch (error) {
                    // SweetAlert.fire({
                    //   title: error.message,
                    //   icon: 'error'
                    // })
                    const errorCode = error.code
                    const errorTranslate = error.translate_params
                    SweetAlert.fire({
                      title: t(errorCode, errorTranslate),
                      icon: 'error'
                    })
                  }
                }
              })
            }
          }
        }
      })
    }
    // Normal balance
    else if(selectedToAccount[0].balance >= 0) {
      if (isSubscribePamm &&
        !is_action_date_1 && !is_action_date_2
      ) {
        // PAMM Subscriber who contain credit 

        if (checkAccountCredit?.credit_balance !== 0) {
          SweetAlert.fire({
            title: `#${selectedFromAccount[0]?.account_login} ${t("financial.contain")} $${checkAccountCredit?.credit_balance} ${t("financial.credit")} `,
            html: `${t("financial.Type")} "<strong>${t("financial.AGREE")}</strong>" ${t("financial.toConfirm")} 
                      <br/>
                      <p style="color:red;">${t("financial.OnceYouConfirm")} <strong>${t("financial.VOIDED")}</strong> ${t("financial.partiallyOrEntirely")}</p>
                      <div>
                              <p style="margin-bottom:0px;"
                              >*${t("financial.KindlyTakeNote")}*</p>
                              <p>
                                  1. ${t("financial.ByTypingAGREE")}
                                  <br/>
                                  2. ${t("financial.TheCreditWillOnly")}
                                
                              </p>
                            </div>
                      `,
            input: 'text',
            inputValue: '',
            inputPlaceholder: '',
            inputValidator: (result) => {
              if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                return t("financial.PleaseEnterThe")
              }
            },
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("financial.confirm"),
            cancelButtonText: t("financial.cancel"),
            reverseButtons: true,

          }).then(async (result) => {
            if (result.value) {
              SweetAlert.fire({
                title: `${t("financial.YouAreSubscribingTransfer")} ${selectedAccountFromPamm[0]?.pammManager?.penalty_percent}% ${t("financial.fundDirectly")}`,
                html: `
                <div style="margin-top:10px;">
                  ${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
                </div>
  
                <div >
                            <p style="margin-bottom:0px;margin-top:10px;"
                            >*${t("financial.KindlyTakeNote")}*</p>
                            <p>
                                1. ${t("financial.ByTypingAgreeDeduct")} ${selectedAccountFromPamm[0]?.pammManager?.penalty_percent}% ${t('financial.fundDirectly')}
                                <br/>
                                2. ${t("financial.IfYouHave")} <a href='/support' target="_blank">${t("financial.customerService")}</a> ${t("financial.orReferThe")} <a
                                href="${selectedAccountFromPamm[0]?.op_url}"
                                target="_blank"
                            >
                                <span>5.4 ${t("pamm.termsConditions")}</span>
                            </a>.  
                            </p>
                          </div>
  
                          <div class='text-center'>
                          
                  </div>
  
                
                
            `,
                input: 'text',
                inputValue: '',
                inputPlaceholder: '',
                inputValidator: (result) => {
                  if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                    return t("financial.PleaseEnterThe")
                  }
                },
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t("financial.confirm"),
                cancelButtonText: t("financial.cancel"),
                reverseButtons: true
              }).then(async (result) => {
                if (result.value) {
                  SweetAlert.fire({
                    title: t("financial.areYouSureTransfer"),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t("financial.confirm"),
                    cancelButtonText: t("financial.cancel"),
                    reverseButtons: true
                  }).then(async (result) => {
                    if (result.value) {
                      try {
                        if (data !== '') {
                          const result = await TransferService.createMt5Transfer({
                            from_account_id: data.from_account_id,
                            amount: data.amount,
                            to_account_id: data.to_account_id,
                            is_voiding_credit: true,
                            negative_balance_deposit: false
                          })
                          // console.log(result)

                          // success
                          if (result?.account_transfer?.id) {
                            reset()

                            SweetAlert.fire({
                              title: t("financial.transferSuccessfully"),
                              text: t("financial.yourRequestWillBeReviewByAdmin"),
                              icon: 'success'
                            }).then(() => {
                              window.location.reload()
                            })
                          }
                        } else {
                          errors.showMessages()
                        }
                      } catch (error) {
                        // SweetAlert.fire({
                        //   title: error.message,
                        //   icon: 'error'
                        // })
                        const errorCode = error.code
                        const errorTranslate = error.translate_params
                        SweetAlert.fire({
                          title: t(errorCode, errorTranslate),
                          icon: 'error'
                        })
                      }
                    }
                  })
                }
              })
            }
          })
        } else if (unsubscribeIsPendingId.includes(selectedAccountId)) {
          //user's unsubsribe is pending

          SweetAlert.fire({
            title: `You are unubscribe pending with PAMM`,
            html: `
                <p>Please wait for approved to process this operation</p>
              `,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Close',
            cancelButtonText: t('financial.cancel'),
            reverseButtons: true
          })

        }

        else {
          // PAMM Subscriber who do not contain credit 
          SweetAlert.fire({
            title: `${t("financial.YouAreSubscribingTransfer")} ${selectedAccountFromPamm[0]?.pammManager?.penalty_percent}% ${t("financial.fundDirectly")}`,
            html: `
            <div style="margin-top:10px;">
              ${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
            </div>
  
            <div >
                        <p style="margin-bottom:0px;margin-top:10px;"
                        >*${t("financial.KindlyTakeNote")}*</p>
                        <p>
                            1. ${t("financial.ByTypingAgreeDeduct")} ${selectedAccountFromPamm[0]?.pammManager?.penalty_percent}% ${t('financial.fundDirectly')}
                            <br/>
                            2. ${t("financial.IfYouHave")} <a href='/support' target="_blank">${t("financial.customerService")}</a> ${t("financial.orReferThe")} <a
                            href="${selectedAccountFromPamm[0]?.op_url}"
                            target="_blank"
                        >
                            <span>5.4 ${t("pamm.termsConditions")}</span>
                        </a>.  
                        </p>
                      </div>
  
                      <div class='text-center'>
                      
              </div>
  
            
            
        `,
            input: 'text',
            inputValue: '',
            inputPlaceholder: '',
            inputValidator: (result) => {
              if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                return t("financial.PleaseEnterThe")
              }
            },
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("financial.confirm"),
            cancelButtonText: t("financial.cancel"),
            reverseButtons: true
          }).then(async (result) => {
            if (result.value) {
              SweetAlert.fire({
                title: t("financial.areYouSureTransfer"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t("financial.confirm"),
                cancelButtonText: t("financial.cancel"),
                reverseButtons: true
              }).then(async (result) => {
                if (result.value) {
                  try {
                    if (data !== '') {
                      const result = await TransferService.createMt5Transfer({
                        from_account_id: data.from_account_id,
                        amount: data.amount,
                        to_account_id: data.to_account_id,
                        is_voiding_credit: false,
                        negative_balance_deposit: false

                      })
                      // console.log(result)

                      // success
                      if (result?.account_transfer?.id) {
                        reset()

                        SweetAlert.fire({
                          title: t("financial.transferSuccessfully"),
                          text: t("financial.yourRequestWillBeReviewByAdmin"),
                          icon: 'success'
                        }).then(() => {
                          window.location.reload()
                        })
                      }
                    } else {
                      errors.showMessages()
                    }
                  } catch (error) {
                    // SweetAlert.fire({
                    //   title: error.message,
                    //   icon: 'error'
                    // })
                    const errorCode = error.code
                    const errorTranslate = error.translate_params
                    SweetAlert.fire({
                      title: t(errorCode, errorTranslate),
                      icon: 'error'
                    })
                  }
                }
              })
            }
          })
        }


      } else {
        // Normal User who  contain credit 
        if (checkAccountCredit?.credit_balance !== 0) {
          SweetAlert.fire({
            title: `#${selectedFromAccount[0]?.account_login} ${t("financial.contain")} $${checkAccountCredit?.credit_balance} ${t("financial.credit")} `,
            html: `${t("financial.Type")} "<strong>${t("financial.AGREE")}</strong>" ${t("financial.toConfirm")} 
                      <br/>
                      <p style="color:red;">${t("financial.OnceYouConfirm")} <strong>${t("financial.VOIDED")}</strong> ${t("financial.partiallyOrEntirely")}</p>
                      <div>
                              <p style="margin-bottom:0px;"
                              >*${t("financial.KindlyTakeNote")}*</p>
                              <p>
                                  1. ${t("financial.ByTypingAGREE")}
                                  <br/>
                                  2. ${t("financial.TheCreditWillOnly")}
                              </p>
                            </div>
                      `,
            input: 'text',
            inputValue: '',
            inputPlaceholder: '',
            inputValidator: (result) => {
              if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                return t("financial.PleaseEnterThe")
              }
            },
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("financial.confirm"),
            cancelButtonText: t("financial.cancel"),
            reverseButtons: true,

          }).then(async (result) => {
            if (result.value) {
              SweetAlert.fire({
                title: t("financial.areYouSureTransfer"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t("financial.confirm"),
                cancelButtonText: t("financial.cancel"),
                reverseButtons: true
              }).then(async (result) => {
                if (result.value) {
                  try {
                    if (data !== '') {
                      const result = await TransferService.createMt5Transfer({
                        from_account_id: data.from_account_id,
                        amount: data.amount,
                        to_account_id: data.to_account_id,
                        is_voiding_credit: true,
                        negative_balance_deposit: false

                      })
                      // console.log(result)

                      // success
                      if (result?.account_transfer?.id) {
                        reset()

                        SweetAlert.fire({
                          title: t("financial.transferSuccessfully"),
                          text: t("financial.yourRequestWillBeReviewByAdmin"),
                          icon: 'success'
                        }).then(() => {
                          window.location.reload()
                        })
                      }
                    } else {
                      errors.showMessages()
                    }
                  } catch (error) {
                    // SweetAlert.fire({
                    //   title: error.message,
                    //   icon: 'error'
                    // })
                    const errorCode = error.code
                    const errorTranslate = error.translate_params
                    SweetAlert.fire({
                      title: t(errorCode, errorTranslate),
                      icon: 'error'
                    })
                  }
                }
              })
            }
          })
        } else {
          // Normal User who do not contain credit 
          SweetAlert.fire({
            title: t("financial.areYouSureTransfer"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("financial.confirm"),
            cancelButtonText: t("financial.cancel"),
            reverseButtons: true
          }).then(async (result) => {
            if (result.value) {
              try {
                if (data !== '') {
                  const result = await TransferService.createMt5Transfer({
                    from_account_id: data.from_account_id,
                    amount: data.amount,
                    to_account_id: data.to_account_id,
                    is_voiding_credit: false,
                    negative_balance_deposit: false

                  })
                  // console.log(result)

                  // success
                  if (result?.account_transfer?.id) {
                    reset()

                    SweetAlert.fire({
                      title: t("financial.transferSuccessfully"),
                      text: t("financial.yourRequestWillBeReviewByAdmin"),
                      icon: 'success'
                    }).then(() => {
                      window.location.reload()
                    })
                  }
                } else {
                  errors.showMessages()
                }
              } catch (error) {
                // SweetAlert.fire({
                //   title: error.message,
                //   icon: 'error'
                // })
                const errorCode = error.code
                const errorTranslate = error.translate_params
                SweetAlert.fire({
                  title: t(errorCode, errorTranslate),
                  icon: 'error'
                })
              }
            }
          })
        }
      }
    }
  }

  const init = async () => {
    // live accounts and competition only
    const accountResponse = await AccountService.getAll()
    const liveAndCompetition = accountResponse.accounts.filter((e) => e.account_type !== 1)
    setAccounts(liveAndCompetition)
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  if (accounts != null) {
    if (accounts?.length > 1) {
      return (
        <Form className="theme-form" onSubmit={handleSubmit(handleTransfer)}>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("financial.fromAccount")}</Label>
                <Input
                  type="select"
                  name="from_account_id"
                  className="form-control digits"
                  innerRef={register({ required: true })}
                  defaultValue=""
                  onChange={(e) => checkIsSubscribePamm(e.target.value)}
                >
                  <option value="" disabled>
                    {t("financial.selectOne")}
                  </option>
                  {accounts?.length > 0 &&
                    accounts.map((account) => {
                      const currency = currencies.find((e) => {
                        return e.id === account.currency_id
                      })

                      return (
                        <option key={account.id} value={account.id}>{`${account.account_login} - ${account.plan?.name
                          } - ${t("financial.availableBalance")} ${account.balance?.toFixed(2)} ${currency?.name
                          }`}</option>
                      )
                    })}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("financial.toAccount")}</Label>
                <Input
                  type="select"
                  name="to_account_id"
                  className="form-control digits"
                  innerRef={register({ required: true })}
                  defaultValue=""
                >
                  <option value="" disabled>
                    {t("financial.selectOne")}
                  </option>
                  {accounts?.length > 0 &&
                    accounts.map((account) => {
                      const currency = currencies.find((e) => {
                        return e.id === account.currency_id
                      })

                      return (
                        <option key={account.id} value={account.id}>{`${account.account_login
                          } - ${t("financial.availableBalance")} ${account.balance?.toFixed(2)} ${currency?.name
                          }`}</option>
                      )
                    })}
                </Input>


              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("financial.transferAmount")}</Label>
                <Input
                  className="form-control"
                  type="number"
                  step=".01"
                  name="amount"
                  innerRef={register({ required: true })}
                />
                <span style={{ color: 'red' }}>{errors.amount && t("financial.amountIsRequired")}</span>
              </FormGroup>
            </Col>
          </Row>

          {
            isSubscribePamm &&
            <>
              <p style={{ marginBottom: '10px', marginTop: '20px' }}>
                Pamm Manager : {selectedAccountFromPamm[0]?.pammManager?.name}
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t("pamm.SettlementPeriod")} {selectedAccountFromPamm[0]?.pammManager?.settlement_period}
              </p>

              {
                (
                  today !== settlementDateTomorrow1
                  &&
                  today !== settlementDateTomorrow2
                )
                  ?
                  <>
                    <p>
                      {t("pamm.settlementDate")} {settlementDate}(GMT +0)
                      <HelpCircle id="nextSettlement" style={{ width: '18px', color: '#f9b700', marginLeft: '3px', cursor: 'pointer' }} />
                      <Tooltip placement='left' id='settlementTooltip' style={{ textAlign: 'left', backgroundColor: '#f7f7f7', color: 'black' }} isOpen={nextSettlementTooltipOpen} target="nextSettlement" toggle={nextSettlementToggle}>
                        <h5>{t("pamm.PleaseTakeNote")}</h5>
                        {/* <p>1. {t("pamm.SettlementDateSubscriber")} {pammManagerDetails.penalty_percent}%<strong></strong></p> */}
                        <p>1. {t("pamm.IfYouDecide")} <strong>{t("pamm.withdraw")}</strong> {t("pamm.or")} <strong>{t("pamm.unsubscribe")}</strong> {t("pamm.BesideSettlement")} <span style={{ color: 'red', fontWeight: 'bold' }}>{t("pamm.deduct")} {selectedAccountFromPamm[0]?.pammManager?.penalty_percent}%</span> {t("pamm.fromYourFund")}</p>
                        <p>2. {t("pamm.ThisSettlementWillHappenOn")} {settlementDate} (GMT +0) 00:00:00</p>
                          3. {t("pamm.TheInvestorIsNotAllowed")}
                        <br /><br />
                        {t("pamm.TheInvestorWillBeAssigned")} <span style={{ color: 'green', fontWeight: 'bold' }}>{t("pamm.afterTheSettlementDate")}</span> {t("pamm.AsBufferPeriod")}
                        <br /><br />
                        {t("pamm.ForExample")}
                        {t("pamm.IfTheSettlementDateIsOn")}
                        <span style={{ fontWeight: 'bold' }}> {settlementDate}  (GMT +0) 00:00:00</span>
                        {t("pamm.theInvestorIsNotAllowed")}
                        <span style={{ fontWeight: 'bold' }}>
                          {settlementDateTomorrow1} (GMT +0) 00:00:00 </span>
                        {t("pamm.to")}
                        <span style={{ fontWeight: 'bold' }}> {settlementDateTomorrow2} (GMT +0) 23:59:59
                        </span>
                        {t("pamm.WithoutAnyPenalty")}
                      </Tooltip>
                    </p>


                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <AlertCircle width="15px" style={{ color: 'red', marginRight: '5px' }} />
                      <p style={{ color: 'red' }}>{t("financial.TheAccountYou")}({selectedAccountFromPamm[0]?.pammManager?.name}), {t("financial.Kindly")} {t("financial.unsubscribe")} {t("financial.firstToProcess")}</p>
                    </div>
                  </>
                  :
                  <p style={{ color: '#f9b600' }}>**{t("financial.AbleToTransfer")}</p>
              }

            </>
          }
          <Row>
            <Col>
              <FormGroup className="mb-0">
                <Button disabled={isBusy} color='primary' className="mr-0 mt-2 pull-right">
                  {isBusy ? 'Loading...' : t("financial.submit")}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      )
    } else {
      return <p>{t("financial.needMinimumOf2Accounts")}</p>
    }
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Spinner color="warning" />
    </div>
  )
}

export default TransferForm
