import React, { useState, useEffect } from 'react'
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import Dropzone from 'react-dropzone-uploader'
import SweetAlert from 'sweetalert2'
import AccountService from '../../network/services/account'

import { BASE_URL } from '../../network/constants'
import FeedbackService from '../../network/services/feedback'
import { useTranslation } from 'react-i18next'

const SupportForm = ({ setRefresh }) => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors, reset } = useForm()
  const [attachment, setAttachment] = useState(null)
  const [mt5Account, setMT5Account] = useState(null)
  const init = async () => {
    const accountResponse = await AccountService.getAll()
    // console.log('accountResponse', accountResponse)
    setMT5Account(accountResponse?.accounts)
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const handleSupport = async (data) => {
    try {
      data['file_url'] = attachment
      // console.log(data)

      if (data !== '') {
        const result = await FeedbackService.create(data)
        // console.log(result)

        if (result.feedback?.id) {
          reset()
          setAttachment(null)

          SweetAlert.fire({
            title: t("support.ticketSubmitted"),
            text: t("support.weHaveReceiveYourQuery"),
            icon: 'success'
          }).then(() => {
            window.location.reload()
          })
        }
      } else {
        errors.showMessages()
      }
    } catch (error) {
      // SweetAlert.fire({
      //   title: error?.message ?? t("support.pleaseContactCustomerSupport"),
      //   icon: 'error'
      // })
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }

  const getUploadParams = ({ meta }) => {
    const token = localStorage.getItem('token')

    return {
      url: BASE_URL + '/files',
      headers: { Authorization: `Bearer ${token}` }
    }
  }

  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    // upload successfully
    if (status === 'done') {
      if (xhr.response) {
        setAttachment(JSON.parse(xhr?.response).data)
      }
    }

    // remove attachment
    if (status === 'removed') {
      setAttachment(null)
    }
  }

  return (
    <Form className="theme-form" onSubmit={handleSubmit(handleSupport)}>
      <Row className='mb-2'>
        <Col>
          <FormGroup>
            <Label>{t("support.ticketType")}</Label>
            <Input
              type="select"
              name="type"
              className="form-control digits"
              innerRef={register({ required: true })}
            >
              <option value="1">{t("support.commonQuestions")}</option>
              <option value="6">{t("support.PAMM")}</option>
              <option value="2">{t("support.payments")}</option>
              <option value="3">{t("support.partnership")}</option>
              {/* <option value="4">{t("support.complaint")}</option> */}
              <option value="5">{t("support.technicalIssues")}</option>
              <option value="7">{t("support.Verification")}</option>
              <option value="8">{t("support.Others")}</option>

            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          <FormGroup>
            <Label>{t("support.MT5AccountAffected")}</Label>
            <Input
              type="select"
              name="account_login"
              className="form-control digits"
              innerRef={register({ required: true })}
            >
              <option value='-'>{t("support.SelectMT5AccountAffected")}</option>
              {mt5Account?.map(account => {
                return <option value={account?.account_login}>{account?.account_login}</option>
              })}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          <FormGroup>
            <Label>{t("support.subject")}</Label>
            <Input
              className="form-control"
              type="text"
              name="subject"
              innerRef={register({ required: true })}
            />
            <span style={{ color: 'red' }}>{errors.subject && t("support.subjectIsRequired")}</span>
          </FormGroup>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          <FormGroup>
            <Label>{t("support.message")}</Label>
            <Input
              className="form-control"
              type="textarea"
              name="message"
              innerRef={register({ required: true })}
            />
            <span style={{ color: 'red' }}>{errors.message && t("support.messageIsRequired")}</span>
          </FormGroup>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          <FormGroup>
            <Label>{t("support.attachment")}</Label>
            <Dropzone
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              maxFiles={1}
              multiple={false}
              canCancel={true}
              inputContent={t("support.dropFile")}
              styles={{
                dropzone: { width: '100%', height: 50 },
                dropzoneActive: { borderColor: 'green' }
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="m-t-15">
        <Col>
          <FormGroup className="mb-0" style={{ float: 'right' }}>
            <Button color="primary" >
              {t("demo.submit")}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default SupportForm
