import React, { useState, useEffect, Fragment } from 'react'
import { Input, Label } from 'reactstrap'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
const HistoryFilters = ({ types, handleFilter, filters }) => {
  const [typeFilter, setTypeFilter] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
   
    // console.log(typeFilter, fromDate, toDate)
    let filterObject = { ...filters }

    if (typeFilter != null && typeFilter >= 0) {
      filterObject['txn_type'] = typeFilter
    }
    if (fromDate != null) {
      filterObject['from_date'] = fromDate
    }
    if (toDate != null) {
      filterObject['to_date'] = toDate
    }

    handleFilter(filterObject)
    // eslint-disable-next-line
  }, [typeFilter, fromDate, toDate])

  return (
    <Fragment>
      <div className="product-filter">
        <h6 className="f-w-600">{t("financial.type")}</h6>
        <div className="checkbox-animated mt-0">
          {types.map((type) => {
            return (
              <Label className="d-block" key={type.id}>
                <Input
                  className="radio_animated"
                  id={type.id}
                  type="radio"
                  name="type"
                  onClick={(e) => setTypeFilter(type.id)}
                />
                {type.name}
              </Label>
            )
          })}
        </div>
      </div>

      <div className="product-filter">
        <h6 className="f-w-600">{t("financial.from")}</h6>
        <Input
          className="form-control digits"
          type="date"
          defaultValue={fromDate}
          onChange={(e) => {
            setFromDate(moment(e.currentTarget.value).utc().format())
          }}
        />
      </div>

      <div className="product-filter">
        <h6 className="f-w-600">{t("financial.to")}</h6>
        <Input
          className="form-control digits"
          type="date"
          defaultValue={toDate}
          onChange={(e) => {
            setToDate(moment(e.currentTarget.value).utc().format())
          }}
        />
      </div>

    </Fragment>
  )
}

export default HistoryFilters
