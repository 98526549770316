import React, { useMemo, useState } from 'react'
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Label, Input, Button } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import AuthService from '../../../../../network/services/auth'
import { useTranslation } from 'react-i18next'

const BluepayAmount = () => {
  const { t } = useTranslation()
  const { register, errors, formState, watch } = useFormContext()
  const { isSubmitting } = formState

  const watchAmount = watch('amount')
  const wallets = useSelector((state) => state.wallet.wallets)
  const payments = useSelector((state) => state.payment.payments)
  const selectedWalletId = useSelector((state) => state.withdrawal.wallet.id)
  const { user, twoFA } = useSelector((state) => state.user)
  const [otherOption, setOtherOption] = useState('')
  const selectedPayment = useMemo(() => {
    return payments.find((e) => e.handle === 'bluepay')
    // eslint-disable-next-line
  }, [payments])

  const selectedWallet = useMemo(() => {
    return wallets.find((e) => e.id === selectedWalletId)
    // eslint-disable-next-line
  }, [selectedWalletId])

  const [authError, setAuthError] = useState(null)
  const [authPending, setAuthPending] = useState(null)
  const send2fa = async () => {
    try {
      const result = await AuthService.sendVerification({
        email: user.email
      })

      if (result.success) {
        setAuthPending(
          `${t('financial.pleaseCheck')} ${user.email} ${t('financial.forAuthenticationCode')}`
        )
        setAuthError(null)
      } else {
        setAuthPending(null)
        setAuthError(result.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    <>
      <Card>
        <CardHeader>
          <h5>{t('financial.chooseAmount')}</h5>
          <p style={{ margin: 0 }}>{`${t(
            'financial.availableBalance'
          )} ${selectedWallet?.balance?.toFixed(2)} ${selectedWallet?.currency?.name}`}</p>
          <span style={{ color: 'green' }}>
            {`Rate of ${(selectedPayment?.currency?.withdraw_rate).toFixed(2)} ${
              selectedPayment?.currency?.unit
            } per ${selectedPayment?.currency?.base_currency?.toUpperCase()}`}
          </span>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label>
                  {t('financial.withdrawalAmount')}
                  <span
                    style={{ color: 'green', textTransform: 'uppercase' }}
                  >{` (${selectedPayment?.currency?.base_currency})`}</span>
                </Label>
                <Input
                  className="form-control"
                  type="number"
                  step=".01"
                  name="amount"
                  innerRef={register({
                    required: t('financial.amountIsRequired'),
                    min: {
                      // value: 100 / selectedPayment?.currency?.withdraw_rate ?? 100,
                      value: 100,
                      message: t('financial.minimumWithdrawalOf100USD')
                    }
                  })}
                />
                <span style={{ color: 'red', display: 'block' }}>
                  {errors.amount && errors.amount.message}
                </span>
                <span style={{ color: 'green' }}>
                  {`~ ${t(
                    'financial.youWillReceiveApproximately'
                  )} ${selectedPayment?.currency?.abbreviation?.toUpperCase()}`}
                  {(watchAmount * selectedPayment?.currency?.withdraw_rate).toFixed(2)}
                </span>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label>{t('financial.walletAddress')}</Label>
                <Input
                  className="form-control"
                  type="text"
                  name="recipient_address"
                  innerRef={register({ required: true })}
                />
                <span style={{ color: 'red' }}>
                  {errors.recipient_address && t('financial.addressIsRequired')}
                </span>
              </FormGroup>
            </Col>
            {twoFA != null && (
              <>
                <Col sm={6}>
                  <FormGroup>
                    <Label>{t('financial.2FACode')}</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="auth_code"
                      innerRef={register({ required: true })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.auth_code && t('financial.2FACodeIsRequired')}
                    </span>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label>&nbsp;</Label>
                    <Button color="primary btn-block" onClick={send2fa} style={{ maxWidth: 250 }}>
                      {t('financial.sendAuthenticationCode')}
                    </Button>
                    <span style={{ color: 'green', display: 'block' }}>{authPending}</span>
                    <span style={{ color: 'red', display: 'block' }}>{authError}</span>
                  </FormGroup>
                </Col>
              </>
            )}
            <Col sm={6}>
              <FormGroup>
                <Label style={{ fontSize: '20px', fontWeight: '500' }} className="m-t-20">
                  {t('financial.Reason')}{' '}
                </Label>
                <Input
                  className="form-control"
                  type="select"
                  name="withdraw_reason"
                  innerRef={register({ required: true })}
                  onChange={(e) => {
                    setOtherOption(e.target.value)
                  }}
                >
                  <option value="">{t('financial.SelectReason')}</option>

                  <option value="Need funds urgently">{t('financial.NeedFundsUrgently')}</option>
                  <option value="Profit withdrawal">{t('financial.ProfitWithdrawal')}</option>
                  <option value="Competitors provide better benefits & services">
                    {t('financial.CompetitorsProvideBetterBenefits')}
                  </option>
                  <option value="The system is not user-friendly">
                    {t('financial.TheSystemIsNot')}
                  </option>
                  <option>{t('financial.Other')}</option>
                </Input>
                <span style={{ color: 'red', marginTop: '10px' }}>
                  {errors.withdraw_reason && t('financial.PleaseAtLeastSelect')}
                </span>
              </FormGroup>

              {otherOption === 'Other' && (
                <FormGroup>
                  <Label className="m-t-10">{t('financial.OtherReason')} </Label>
                  <Input
                    innerRef={register({ required: true })}
                    className="form-control input-txt-bx"
                    type="text"
                    name="withdraw_reason"
                    placeholder={t('financial.EnterYourReason')}
                  />
                </FormGroup>
              )}
              {otherOption === '其他' && (
                <FormGroup>
                  <Label className="m-t-10">{t('financial.OtherReason')} </Label>
                  <Input
                    innerRef={register({ required: true })}
                    className="form-control input-txt-bx"
                    type="text"
                    name="withdraw_reason"
                    placeholder={t('financial.EnterYourReason')}
                  />
                </FormGroup>
              )}
              {otherOption === 'Competitors provide better benefits & services' && (
                <FormGroup>
                  <Label className="m-t-10">{t('financial.competitorName')}</Label>
                  <Input
                    innerRef={register({ required: true })}
                    className="form-control input-txt-bx"
                    type="text"
                    name="competitor"
                    placeholder={t('financial.EnterCompetitorName')}
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
          <Button color="primary btn-block" type="submit" style={{ maxWidth: 150, float: 'right' }}>
            {isSubmitting ? t('financial.loading') : t('financial.submit')}
          </Button>
        </CardBody>
      </Card>
    </>
  )
}

export default BluepayAmount
