import client from "../request"

const availableVouchers = () => {
    return client.get("/me/vouchers")
}

const claimVoucher = (data) => {
    return client.post(`/me/vouchers/claim`, data)
}

const applyVoucher = (data) => {
    return client.post(`/me/vouchers/apply`, data)
}

export default {
    availableVouchers,
    claimVoucher,
    applyVoucher
}
