import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Button,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    Input,

} from 'reactstrap'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'
import { useForm } from 'react-hook-form'

import AccountService from '../../network/services/account'

import PartibanStarterPackage from './PartibanStarterPack/packageInfo'

import './index.scss'

import ScrollDownIcon from './down-arrow.png'
import GreenChecked from './checked.png'
import Package1 from './package-01.png'
import Package2 from './Package-02.png'
import Package3 from './Package-03.png'

function StaterPackage({ account, accountDetails, partibanStarterUpgradeModal, setPartibanStarterUpgradeModal, upgradePartibanStarterModalToggle }) {

    const { t } = useTranslation()
    const { register } = useForm()


    const [personalDetailsModal, setPersonalDetailsModal] = useState(false)
    const [packageSelect, setPackageSelect] = useState('')
    const [applicateName, setApplicateName] = useState('')
    const [identity, setIdentity] = useState('')
    const [isBusy, setIsBusy] = useState(false)
    const [selectedPackage, setSelectedPackage] = useState(2)

    const personalDetailsModalToggle = () => {

        setApplicateName('')
        setIdentity('')
        setPersonalDetailsModal(!personalDetailsModal)
    }


    const handlePackageApplication = () => {

        setIsBusy(true)
        SweetAlert.fire({
            title: 'Are you sure you want to upgrade package?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('financial.confirm'),
            cancelButtonText: t('financial.cancel'),
            reverseButtons: true
        }).then(async (result) => {
            setIsBusy(false)
            if (result.value) {
                try {

                    let result = await AccountService.upgradePackage(accountDetails.id, {
                        package_id: packageSelect
                    })

                    if (result?.package_application?.id) {
                        SweetAlert.fire({
                            title: 'Applied Package Successfully',
                            icon: 'success'
                        }).then(() => {
                            setIsBusy(false)
                            setPartibanStarterUpgradeModal(!partibanStarterUpgradeModal)
                            window.location.reload()
                        })
                    }

                } catch (error) {

                    setIsBusy(false)
                    const errorCode = error.code
                    const errorTranslate = error.translate_params
                    SweetAlert.fire({
                        title: t(errorCode, errorTranslate),
                        icon: 'error'
                    }).then(() => {
                        window.location.href = '/financial'
                    })
                }
            }
        })
    }

    const faqItems = [
        
        
        {
            heading: t('live.WhereCanIViewTheGrants'),
            content: t('live.YouJustNeedToLog')
        },
       
        {
            heading: t('live.CanIWithdrawMyFunds'),
            content: 'Yes. you can withdraw at any time. '
        }
    ]

    return (

        <Modal
            id="upgrade-live-modal"
            isOpen={account.id === accountDetails?.id && partibanStarterUpgradeModal}
            toggle={upgradePartibanStarterModalToggle}
            backdrop='static'

        >
            <ModalHeader toggle={upgradePartibanStarterModalToggle} centered className='modalHeader-Package' >

            </ModalHeader>
            <ModalBody>
                {/* Control CSS display property  */}

                <Row style={{ justifyContent: 'center', paddingBottom: '20px' }}>
                    <h2 style={{ color: 'white', fontWeight: '300' }}>
                        First Step Package
                    </h2>
                </Row>
                <div
                    className="d-block d-sm-block d-md-flex d-lg-flex d-xl-flex"
                    style={{ justifyContent: 'space-between', marginTop: '20px' }}
                >
                    <Col lg='1'></Col>
                    <Col lg='3' md='4'>
                        <Card
                            style={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                textAlign: 'center',

                            }}
                            onClick={() => {
                                setSelectedPackage(10)
                            }}
                            className='packageEnglishCard responsiveCardPartibanStarterPack'
                        >
                            <div className='mt-3'>
                                <h3>First Step 100</h3>
                                <h6>Min Deposit $100</h6>
                            </div>

                            <img src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Pricing+table-01.png' alt='package' style={{ width: '100%' }} />

                            <div
                                style={{
                                    padding: '1rem 0',
                                }}
                            >

                                <Button
                                    style={{
                                        width: '75%',
                                        margin: '10px auto 15px auto',
                                    }}
                                    className='packageButton'
                                    onClick={() => {

                                        setPackageSelect(10)
                                        personalDetailsModalToggle(account)
                                    }}
                                >
                                    Upgrade to First Step 100

                                </Button>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ margin: '0 auto' }}>
                                        {PartibanStarterPackage?.firstStep100?.benefits?.map((benefit, index) => {
                                            return (
                                                <div style={{ display: 'flex' }} key={index}>
                                                    <div style={{ marginRight: '10px' }}>
                                                        <img src={GreenChecked} style={{ width: '16px' }} alt='greenChecked' />
                                                    </div>
                                                    <p style={{ marginBottom: "10px", fontSize: '15px' }}>
                                                        {t(benefit?.name)}
                                                    </p>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>

                        </Card>


                    </Col>

                    <Col lg='3' md='4'>

                        <Card
                            style={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                textAlign: 'center',

                            }}
                            onClick={() => {
                                setSelectedPackage(11)
                            }}
                            className='packageEnglishCard responsiveCardPartibanStarterPack'
                        >
                            <div className='mt-3'>
                                <h3>First Step 300</h3>
                                <h6>Min Deposit $300</h6>
                            </div>

                            <img src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Pricing+table-02.png' alt='package' style={{ width: '100%' }} />

                            <div
                                style={{
                                    padding: '1rem 0',

                                }}
                            >

                                <Button
                                    style={{
                                        width: '75%',
                                        margin: '10px auto 15px auto',

                                    }}
                                    className='packageButton'
                                    onClick={() => {

                                        setPackageSelect(11)
                                        personalDetailsModalToggle(account)
                                    }}
                                >
                                    Upgrade to First Step 300

                                </Button>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ margin: '0 auto' }}>
                                        <p style={{ marginBottom: "10px", fontSize: '16px' }}>
                                            Everything in First Step 100, plus:
                                        </p>
                                        {PartibanStarterPackage?.firstStep300?.benefits?.map((benefit, index) => {
                                            return (
                                                <div style={{ display: 'flex' }} key={index}>
                                                    <div style={{ marginRight: '10px' }}>
                                                        <img src={GreenChecked} style={{ width: '16px' }} alt='greenChecked' />
                                                    </div>
                                                    <p style={{ marginBottom: "10px", fontSize: '15px' }}>
                                                        {t(benefit?.name)}
                                                    </p>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>

                        </Card>

                    </Col>

                    <Col lg='3' md='4'>
                        <Card
                        
                            style={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                textAlign: 'center',
                            }}
                            onClick={() => {
                                setSelectedPackage(12)
                            }}
                            className='packageEnglishCard responsiveCardPartibanStarterPack'
                        >
                            <div className='mt-3'>

                                <h3>First Step 500</h3>
                                <h6>Min Deposit $500</h6>
                            </div>

                            <img src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Pricing+table-03.png' alt='package' style={{ width: '100%' }} />

                            <div
                                style={{
                                    padding: '1rem 0',

                                }}
                            >

                                <Button
                                    style={{
                                        width: '75%',
                                        margin: '10px auto 15px auto',

                                    }}
                                    className='packageButton'
                                    onClick={() => {

                                        setPackageSelect(12)
                                        personalDetailsModalToggle(account)
                                    }}
                                >
                                    Upgrade to First Step 500
                                </Button>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ margin: '0 auto' }}>
                                        <p style={{ marginBottom: "10px", fontSize: '15px' }}>
                                            Everything in First Step 300, plus:
                                        </p>
                                        {PartibanStarterPackage?.firstStep500?.benefits?.map((benefit, index) => {
                                            return (
                                                <div style={{ display: 'flex' }} key={index}>
                                                    <div style={{ marginRight: '10px' }}>
                                                        <img src={GreenChecked} style={{ width: '16px' }} alt='greenChecked' />
                                                    </div>
                                                    <p style={{ marginBottom: "10px", fontSize: '15px', textAlign: 'left' }}>
                                                        {t(benefit?.name)}
                                                        <br />
                                                        {t(benefit?.name) === 'Online Tutorial in' && (
                                                            <>
                                                                1. {t('live.Breakthrough')}
                                                                <br />
                                                                    2. {t('live.ManageFloating')}
                                                                <br />
                                                                    3. {t('live.RiskManagementStrategy')}
                                                                <br />
                                                                    4. {t('live.FindBestEntryPoint')}
                                                            </>
                                                        )
                                                        }
                                                    </p>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>

                        </Card>


                    </Col>
                    <Col lg='1'></Col>
                </div>

                <Row style={{ justifyContent: 'center', marginBottom: '25px', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ marginBottom: '10px', color: 'white' }}>{t('live.ScrollFor')}</p>
                    <img src={ScrollDownIcon} alt='scroll down icon' style={{ width: '30px' }} />
                </Row>

                <Row style={{ justifyContent: 'center', backgroundColor: '#F5F7FA', padding: '2rem 0rem' }}>
                    <Col lg='8'>
                        <h5 style={{ textAlign: 'center', marginBottom: '25px' }}>{t('live.FrequentlyAskedQuestions')}</h5>

                        <Accordion allowZeroExpanded allowMultipleExpanded>
                            {faqItems.map((item, index) => (
                                <AccordionItem key={index}  >
                                    <AccordionItemHeading>
                                        <AccordionItemButton style={{ backgroundColor: '#FFF' }}>
                                            {item.heading}
                                        </AccordionItemButton>

                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        {item.content}
                                        {item.heading === t('live.WhatBenefitsCanIget') &&
                                            <a href={sessionStorage.getItem('language') === 'en' ? 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/partiban/Starter+Pack_PAG-05.png' : 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/partiban/Starter+Pack_PAG-05.png'} target='_blank'>
                                                {t('live.ClickForMoreDetails')}
                                            </a>
                                        }
                                    </AccordionItemPanel>

                                </AccordionItem>
                            ))}
                        </Accordion>

                    </Col>
                </Row>

                {/* Modal for personal details  */}
                <Modal
                    // id="upgrade-live-modal"
                    isOpen={account.id === accountDetails?.id && personalDetailsModal}
                    toggle={personalDetailsModalToggle}
                    centered
                >
                    <ModalHeader toggle={personalDetailsModalToggle} centered>
                        Please Check The Details
                                      </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label className="col-form-label pt-0">
                                MT5 Login ID
                                </Label>
                            {/* For Submit  */}
                            <Input
                                className="form-control"
                                type="text"
                                name="account_id"
                                innerRef={register({ required: true })}
                                defaultValue={account.id}
                                disabled
                                hidden
                            >
                            </Input>

                            {/* For Showing */}
                            <Input
                                className="form-control"
                                type="text"
                                defaultValue={account.account_login + ' - $' + account.balance}
                                disabled

                            ></Input>

                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="col-form-label pt-0">
                                        Package Select
                                        </Label>
                                    {/* For Submit  */}
                                    <Input
                                        className="form-control"
                                        type="text"
                                        name="account_id"
                                        innerRef={register({ required: true })}
                                        defaultValue={account.id}
                                        disabled
                                        hidden
                                    >
                                    </Input>

                                    {/* For Showing */}
                                    <Input
                                        className="form-control"
                                        type="text"
                                        defaultValue={
                                            packageSelect === 10 ? 'First Step 100'
                                                : packageSelect === 11 ? 'First Step 300'
                                                    : packageSelect === 12 && 'First Step 500'
                                        }
                                        disabled

                                    ></Input>
                                </Col>

                                <Col>
                                    <Label className="col-form-label pt-0">
                                        {t('live.MinDeposit')}
                                    </Label>

                                    <Input
                                        className="form-control"
                                        type="text"
                                        defaultValue={
                                            packageSelect === 10 ? '$100'
                                                : packageSelect === 11 ? '$300'
                                                    : packageSelect === 12 && '$500'
                                        }
                                        disabled

                                    ></Input>
                                </Col>
                            </Row>
                        </FormGroup>

                        {/* <FormGroup>
                            <Label>{t('live.ReceivedCreditBonus')}</Label>

                            <Input
                                className="form-control"
                                type="text"
                                defaultValue={
                                    packageSelect === 10 ? '10%'
                                        : packageSelect === 11 ? '30%'
                                            : packageSelect === 12 && `50% - Maximum Bonus Credit Capped At $250`
                                }
                                disabled

                            ></Input>
                        </FormGroup> */}


                        {/* <a href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/partiban/Starter+Pack_PAG-05.png' style={{ textDecoration: 'underline' }} target='_blank'>
                            {t('live.ReadTermsConditions')}
                        </a> */}


                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => handlePackageApplication()} disabled={isBusy} color='primary' style={{ float: 'right' }}>{t('demo.submit')}</Button>
                            {isBusy && <Spinner color='warning' />}

                        </div>
                    </ModalBody>
                </ Modal>

            </ModalBody>
        </Modal>


    )
}

export default StaterPackage
