import React, { useState, useEffect, useRef } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { Row, Col, Card, CardHeader, Input } from 'reactstrap'

import UserService from '../../../network/services/user'
import CommissionFilters from './filters'
import { useTranslation } from 'react-i18next'


const CommissionTable = () => {
  const { t } = useTranslation()
  const columns = [
    {
      name: t("partner.order"),
      selector: 'txn_id',
      sortable: true
    },
    {
      name: t("partner.level"),
      selector: 'level',
      sortable: true
    },
    {
      name: t("partner.client"),
      selector: 'client',
      sortable: true,
      width: '250px',
      format: (row) => {
        return row.from_user?.email ?? '-'
      }
    },
    {
      name: t("partner.MT5Login"),
      selector: 'client_login',
      sortable: true,
      format: (row) => {
        return row.from_account?.account_login ?? '-'
      }
    },
    {
      name: t("partner.symbol"),
      selector: 'symbol',
      sortable: true
    },
    {
      name: t("partner.volume"),
      selector: 'volume',
      sortable: true,
      right: true
    },
    {
      name: t("partner.commission"),
      selector: 'amount',
      sortable: true,
      right: true
    },
    {
      name: t("partner.currency"),
      selector: 'currency_unit',
      sortable: true
    },
    {
      name: t("partner.time"),
      selector: 'created_at',
      sortable: true,
      width: '200px',
      format: (row) => {
        return moment(row.created_at).format('yyyy-MM-DD hh:mmA')
      }
    }
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [sidebaron, setSidebaron] = useState(true)
  const [filters, setFilters] = useState(null)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchType, setSearchType] = useState('account_login')
  const [totalCommission, setTotalCommission] = useState(0)
  const wrapperRef = useRef()

  const handlePageChange = async (page) => {
    setIsBusy(true)
    const result = await UserService.getCommissions({ page: page, filters: filters })
    setData(result.commissions?.data ?? [])
    setTotal(result.commissions?.meta?.total ?? 0)
    setIsBusy(false)
  }

  const handleFilter = async () => {
    setIsBusy(true)
    if (filters?.from_date == false) {
      delete filters.from_date
    }
    if (filters?.to_date == false) {
      delete filters.to_date
    }
    const result = await UserService.getCommissions({
      filters
    })
    const commissionResult = await UserService.getCommissionsTotal({
      filters
    })
    setData(result.commissions.data)
    setTotalCommission(commissionResult.total)
    setTotal(result.commissions?.meta?.total ?? 0)
    setIsBusy(false)
  }

  const init = async () => {
    try {
      setIsBusy(true)
      const result = await UserService.getCommissions()
      // console.log(result)

      const commissionResult = await UserService.getCommissionsTotal()
      // console.log(commissionResult)

      setTotal(result.commissions?.meta?.total ?? 0)
      setData(result.commissions?.data ?? [])
      setTotalCommission(commissionResult.total)
      setIsBusy(false)
    } catch (error) {
      console.log(error)
      setIsBusy(false)
    }
  }

  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false)
      wrapperRef.current.classList.add('sidebaron')
    } else {
      setSidebaron(true)
      wrapperRef.current.classList.remove('sidebaron')
    }
  }

  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword)
    const searchFilter = { ...filters }
    searchFilter[searchType] = keyword
    setFilters(searchFilter)
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    handleFilter()
    // eslint-disable-next-line
  }, [filters])

  return (
    <div className="product-wrapper" ref={wrapperRef}>
      <div className="product-grid" style={{ minHeight: '50vh' }}>
        <Row>
          <Col xl="3">
            <div className="product-sidebar">
              <div className="filter-section">
                <Card className="m-0">
                  <CardHeader>
                    <h6 className="mb-0 f-w-600">
                      {t("partner.filters")}
                      <span className="pull-right">
                        <i className="fa fa-chevron-down toggle-data" onClick={onClickFilter}></i>
                      </span>
                    </h6>
                  </CardHeader>
                  <div className="left-filter">
                    <div style={{ padding: 16 }} />
                    <CommissionFilters
                      types={[
                        {
                          id: 'account_login',
                          name: t("partner.SearchByAccount")
                        },
                        {
                          id: 'from_email',
                          name: t("partner.SearchByEmail")
                        }
                      ]}
                      handleFilter={setFilters}
                      setSearchType={setSearchType}
                      filters={filters}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Col>
          <Col xl="9" sm="12">
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Input
                className="form-control"
                type="text"
                placeholder={searchType == 'account_login' ? t('partner.SearchByAccount') : t('partner.SearchByEmail')}
                defaultValue={searchKeyword}
                onChange={(e) => handleSearchKeyword(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <div style={{ padding: 10 }} />
        <Row>
          <Col>
            <div style={{ textAlign: 'right' }}>
              <h6>{t("partner.totalCommission")} {totalCommission} USD</h6>
            </div>
          </Col>
        </Row>

        <div className="product-wrapper-grid" style={{ marginTop: 16 }}>
          <DataTable
            noHeader
            data={data}
            columns={columns}
            striped={true}
            center={true}
            pagination
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={total}
            paginationRowsPerPageOptions={[10]}
            onChangePage={handlePageChange}
            style={{ paddingBottom: 8 }}
            progressPending={isBusy}
            subHeader
            subHeaderComponent={
              <p style={{ textAlign: 'right', height: 'auto', marginBottom: '4px', fontWeight: '500', color:'green' }}>
                *{t("partner.StatsAreUpdated")}
              </p>
            }
            subHeaderAlign="right"
          />
        </div>
      </div>
    </div>
  )
}

export default CommissionTable
