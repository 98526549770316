import React, { useState, useEffect } from 'react'
import { Card, CardBody, Button, Spinner, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import _ from 'lodash'

import AddressTable from './KYC/address_table'
import IdentityTable from './KYC/identity_table'

import UserService from '../../../network/services/user'
import VerificationService from '../../../network/services/verification'
import FilesService from '../../../network/services/files'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '../../../language.png'


const Verification = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isBusy, setIsBusy] = useState(false)
  const [reupload, setReupload] = useState(false)
  const profile = useSelector((state) => state.user.profile)
  const userVerification = useSelector((state) => _.last(state.user.verifications))
  const verification = useSelector((state) => state.verification)
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const userVerificationStatus = useSelector((state) => _.last(state.user.verifications))

  const userLanguage = sessionStorage.getItem('language')

  const user = useSelector((state) => state.user)
  // new
  useEffect(() => {
    if (user?.user?.is_profile_completed === false
    ) {
      window.location.href = '/verify-details'

    }

    if (userVerificationStatus?.status === 1 || userVerificationStatus?.status === 0) {
      window.location.href = '/dashboard'
    } else if (userVerificationStatus?.status === 3) {
      window.location.href = '/account#verification'
    }


  }

    , [])

  const changeLanguage = (userLanguage) => {
    i18n.changeLanguage(userLanguage)
    sessionStorage.setItem('language', userLanguage)
  }

  const [selectedKYCPage, setSelectedKYCPage] = useState(1)


  const checkValidateStep1 = () => {
    setSelectedKYCPage(2)
  }

  const checkPreviousValidateStep2 = () => {
    setSelectedKYCPage(1)
  }


  const handleVerification = async (e) => {
    // console.log(verification)

    if (verification.idFront?.file == null) {
      console.log('missing id front')
      dispatch({ type: 'SET_ID_FRONT', idFront: { error: 'Please select one' } })
      return
    }

    if (verification.idBack?.file == null) {
      console.log('missing id back')
      dispatch({ type: 'SET_ID_BACK', idBack: { error: 'Please select one' } })
      return
    }

    if (verification.addressFront?.file == null) {
      console.log('missing address front')
      dispatch({ type: 'SET_ADDRESS_FRONT', addressFront: { error: 'Please select one' } })
      return
    }

    setIsBusy(true)

    try {
      const idFrontRes = await FilesService.create(verification.idFront?.file)
      const idBackRes = await FilesService.create(verification.idBack?.file)
      const addressFrontRes = await FilesService.create(verification.addressFront?.file)
      // const addressBackRes = await FilesService.create(verification.addressBack)

      const result = await VerificationService.create({
        type: verification.activeTab,
        id_front: idFrontRes.data,
        id_back: idBackRes.data,
        address_front: addressFrontRes.data,
        // debug
        address_back: addressFrontRes.data
      })

      // console.log(result)

      if (result?.verification) {
        SweetAlert.fire({
          title: t("user.ThankYouYourAccount"),
          icon: 'success'
        }).then(() => {
          window.location.href = '/dashboard'
        })
        setIsBusy(false)
      }
    } catch (error) {
      setIsBusy(false)
      console.log(error)

      if (error.message === 'file - File size should be less than 10MB'
        && sessionStorage.getItem('language') == 'en') {
        SweetAlert.fire({
          // title: t(errorCode, errorTranslate),
          title: 'file - File size should be less than 10MB',
          icon: 'error'
        })
      } else if (error.message === 'file - File size should be less than 10MB'
        && sessionStorage.getItem('language') == 'zh') {
        SweetAlert.fire({
          // title: t(errorCode, errorTranslate),
          title: '文件 - 文件不能超过10mb',
          icon: 'error'
        })
      } else {
        SweetAlert.fire({
          // title: t(errorCode, errorTranslate),
          title: error.message,
          icon: 'error'
        })
      }
    }
  }


  const init = async () => {
    const updatedUser = await UserService.getMyself()
    if (updatedUser.verifications) {
      dispatch({ type: 'SET_VERIFICATIONS', verifications: updatedUser.verifications })
    }
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  // exist
  if (profile != null && !_.isEmpty(profile)) {
    return (
      <>
        <Dropdown isOpen={languageDropdownOpen} onMouseLeave={() => setLanguageDropdownOpen(false)} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '30px', paddingRight: '30px', backgroundColor: '#f8f8f8' }}>
          <DropdownToggle onMouseEnter={() => setLanguageDropdownOpen(true)} id="langugeDropdown" style={{ paddingRight: '30px' }}  >
            <img src={LanguageIcon} alt="languageIcon" width='18px' height="18px" className="m-r-5" />
            {userLanguage === "en" && "EN"}
            {userLanguage === "zh" && "ZH"}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => changeLanguage('en')} >English</DropdownItem>
            <DropdownItem onClick={() => changeLanguage('zh')}>中文简体</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', padding: '1.5rem', height: '110%' }}>
          <Col lg='5' className="d-none d-md-block d-lg-block d-xl-block padding-verify-right">
            <Card style={{ height: selectedKYCPage === 1 ? '740px' : '769px' }} >
              <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <img
                    id='img-header-logo'
                    className="img-fluid for-light"
                    width='200px'
                    src={require("../../../assets/images/logo/logoTradeHall.svg")}
                    alt=""
                  />

                </div>

                <div style={{ marginTop: '50px' }}>
                  <h3>{t("verifyAuth.OpenNewAccount")}</h3>
                  <p>{t("verifyAuth.CreateAnAccount")}</p>
                  <p style={{ fontSize: '16px', color: '#898989' }}> {t("verifyAuth.Step2Fill")}</p>
                  <p style={{ fontSize: '16px', color: '#898989' }}> {t("verifyAuth.Step1Deposit")}</p>
                  <p style={{ fontSize: '16px', color: '#f9b700' }}> {t("verifyAuth.Step3Submit")}</p>
                </div>

                <div style={{ marginTop: '50px' }}>
                  <p style={{ marginBottom: '0' }}>{t("verifyAuth.RulesOfKYC")}</p>
                  <p style={{ marginBottom: '5px' }}>1. {t("verifyAuth.AllInformationAndDetails")}</p>
                  <p style={{ marginBottom: '5px' }}>2. {t("verifyAuth.AllScannedFiles")}</p>
                  <p style={{ marginBottom: '5px' }}>3. {t("verifyAuth.MustBeValid")}</p>
                  <p style={{ marginBottom: '5px' }}>4. {t("verifyAuth.BothSide")}</p>
                  <p style={{ marginBottom: '5px' }}>5. {t("verifyAuth.TheEdgesDocuments")}</p>
                </div>

                <div style={{ marginTop: 'auto' }}>
                  <p style={{ marginBottom: '0' }}>{t("verifyAuth.RemindToUser")}</p>
                  {/* <hr style={{marginBottom:'5px', marginTop:'5px'}} /> */}
                  <p style={{ marginBottom: '5px' }}>1. {t("verifyAuth.TheBonusGiven")}</p>
                  <p style={{ marginBottom: '5px' }}>2. {t("verifyAuth.OnceTransactionApproved")}</p>
                  <p style={{ marginBottom: '5px' }}>3. {t("verifyAuth.PersonalDetails")}</p>
                  <p style={{ marginBottom: '5px' }}>4. {t("verifyAuth.KYCNeedWithin")}</p>
                </div>



              </CardBody>
            </Card>
          </Col>
          <Col className="padding-verify-left" lg='5'>
            <Card style={{ display: selectedKYCPage === 1 ? 'block' : 'none' }}>

              <CardBody>

                <div className="d-md-none d-lg-none d-xl-none d-sm-block d-xs-block m-b-20" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img
                    id='img-header-logo'
                    className="img-fluid for-light"
                    width='200px'
                    src={require("../../../assets/images/logo/logoTradeHall.svg")}
                    alt=""
                  />
                </div>
                <p className='d-md-none d-lg-none d-xl-none' style={{ fontSize: '16px', textAlign: 'center' }}> {t("verifyAuth.Step3Submit")}</p>
                <h5 className='text-center-sm'>{t("verifyAuth.ProofOfIdentity")}</h5>

                <IdentityTable reupload={reupload} />

                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} className='m-t-20' >


                  <div style={{ display: 'flex' }}>

                    <Link to="/dashboard">
                      <p className="m-r-20" style={{ fontSize: '14px', color: '#0f3c5a' }} >
                        {t("verifyAuth.Skip")}
                      </p>
                    </Link>
                    <button
                      className="verify-auth-button"
                      style={{ maxWidth: 150 }}
                      disabled={verification.idFront.file === null || verification.idBack.file === null}
                      onClick={() => checkValidateStep1()}
                    >
                      {t("verifyAuth.Next")}
                    </button>
                  </div>
                </div>
              </CardBody>

            </Card>
            <Card style={{ display: selectedKYCPage === 2 ? 'block' : 'none', height: 'fit-content' }}>

              <CardBody>
                <div className="d-md-none d-lg-none d-xl-none d-sm-block d-xs-block m-b-20" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img
                    id='img-header-logo'
                    className="img-fluid for-light"
                    width='200px'
                    src={require("../../../assets/images/logo/logoTradeHall.svg")}
                    alt=""
                  />
                </div>
                <p className='d-md-none d-lg-none d-xl-none' style={{ fontSize: '16px', textAlign: 'center', marginBottom: '0px' }}> Step 3. Submit KYC Identification</p>
                <h5 className='text-center-sm'>{t("user.ProofOfAddress")}</h5>

                <AddressTable reupload={reupload} />

                {(userVerification == null || reupload) && (
                  <>
                    <h6 className="m-t-20">
                      {t("user.ByClickingConfirmProceedYouConfirmThat")}
                    </h6>
                    <div className="m-t-20" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                      <p
                        style={{ maxWidth: 150, marginBottom: '0px' }}
                        onClick={() => checkPreviousValidateStep2()}
                      >
                        {t("verifyAuth.Previous")}
                      </p>

                      <Button color="primary" onClick={handleVerification} disabled={isBusy || verification.addressFront.file === null}>
                        {isBusy ? t("user.Loading") : t("user.Submit")}
                      </Button>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    )
  }

  // loading
  return (
    <Card>
      <CardBody style={{ textAlign: 'center' }}>
        <Spinner />
      </CardBody>
    </Card>
  )
}

export default Verification
