import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Row, CardBody, Card, CardHeader, Button, CardFooter, Badge, Col } from 'reactstrap'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const HoverCard = styled(Card)`
  background: ${(props) => props?.background ?? 'rgb(15, 60, 90)'};
  cursor: pointer;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2), 0 10px 24px 0 rgba(0, 0, 0, 0.19);
  &:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
`

const Banner = ({ promotion }) => {
  const { t } = useTranslation()
  let history = useHistory()
  const dispatch = useDispatch()
  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  return (
    <Row style={{ justifyContent: 'center', marginBottom: '15px' }}>
      <Col lg="12">
        <CardHeader className="p-0">
          <img
            src={promotion.image_url}
            alt={promotion.name}
            style={{ backgroundColor: 'white', width: '100%', borderRadius: '12px 12px 0 0' }}
          />
        </CardHeader>
        <CardBody className="p-4" style={{backgroundColor: 'transparent'}}>
          <h5>{promotion.name}</h5>
          <p>{promotion.description.substring(0, 100)} ...</p>
          <Badge color={promotion.available ? 'success' : 'danger'} pill>
            {promotion.available ? 'Available' : 'Coming Soon'}
          </Badge>
        </CardBody>
        <CardFooter className="p-0">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Link
              to={`promotion/${promotion.id}`}
              style={{ borderRadius: '0 0 0 12px', width: '50%' }}
            >
              <Button
                color="primary"
                style={{ borderRadius: '0 0 0 12px', width: '100%', height: '100%' }}
                // onClick={() => {
                //   navigate(`${process.env.PUBLIC_URL}/financial#deposit`)
                // }}
                disabled={!promotion.available}
                className="buttonFontSize"
              >
                {t("promotion.Apply")}
              </Button>
            </Link>
            <a
              href={promotion.file_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'inline-block', width: '50%' }}
            >
              <Button
                style={{ borderRadius: '0 0 12px 0', width: '100%' }}
                disabled={!promotion.available}
                className="buttonFontSize"
              >
                {t("promotion.TermsConditions")}
              </Button>
            </a>
          </div>
        </CardFooter>
      </Col>
    </Row>
  )
}

export default Banner
