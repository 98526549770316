import { SET_ANNOUNCEMENTS, SET_ANNOUNCEMENTS_INIT } from '../actionTypes'

const initial_state = {
  announcements: null,
  showAnnouncement: true
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_ANNOUNCEMENTS:
      return { ...state, announcements: action.announcements }
    case SET_ANNOUNCEMENTS_INIT:
      return { ...state, showAnnouncement: action.showAnnouncement }
    default:
      return { ...state }
  }
}
