import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
    Row,
    Col,
    Card,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner
} from 'reactstrap'

import PammPendingTable from './pending/index'

import PammSubscriptionService from '../../../network/services/pammSubscription'
import Unsubscribe from './unsubscribe_modal'

import './active.css'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

const PammActiveSubscription = (props) => {
    const { t } = useTranslation()
    const [activeSubscribe, setActiveSubscribe] = useState([])
    const activeList = activeSubscribe

    const [selectedSubcribe, setSelectedSubcribe] = useState([])
    const [isBusy, setIsBusy] = useState(false)

    // modal for unsubscribe
    const [showUnsubscribe, setShowUnsubscribe] = useState(false)
    const toggleModal = () => {
        setShowUnsubscribe(!showUnsubscribe)
    }

    const init = async () => {
        try {
            setIsBusy(true)
            const result = await PammSubscriptionService.getAll()
            setActiveSubscribe(result.active ?? {})
            setIsBusy(false)
        } catch (error) {
            setIsBusy(false)
            console.log(error)
        }
        setIsBusy(false)
    }

    useEffect(() => {
        init()
    }, [])


    const PammActiveList = activeList.map(user => {
        
        return (

            <Col key={user.id} lg="4">

                <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>

                    <Row style={{ justifyContent: 'center' }}>
                        <div className="mt-3" style={{ textAlign: 'center' }}>
                            <a href={`/pamm/${user?.pammManager?.id}/statistic`} target='_blank' rel="noopener noreferrer">
                                <img id="imageSize"
                                    style={{ borderRadius: '10px' }}
                                    src={user.pammManager.avatar !== "" ? user.pammManager.avatar : `https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/User+photo-05.jpg`}
                                    alt={user.pammManager.name} />
                            </a>
                            <h5 className="mt-2" style={{ fontWeight: '400' }}>{user?.pammManager?.name}</h5>
                        </div>
                    </Row>

                    <div className="mt-2" style={{ textAlign: 'center' }}>
                        <p>Account: {user?.account?.account_login}</p>
                        <p>Start Date: {moment(user?.created_at).format('yyyy-MM-DD')}</p>
                        <p><a href={user?.lpoa_url} target="_blank">LPOA Agreement</a></p>
                        <p><a href={user?.op_url} target="_blank">PAMM Agreement</a></p>
                    </div>

                    <Row style={{ justifyContent: 'center' }}>
                        <Button
                            onClick={() => {
                                setShowUnsubscribe(true)
                                setSelectedSubcribe(user)
                            }}
                            style={{ cursor: 'pointer', fontWeight: 'bold', marginBottom: '20px' }} id="smallerFontSize" color="secondary">
                            {t("pammSubsccription.Unsubscribe")}
                        </Button>
                    </Row>

                </Card>
            </Col>


        )
    })

    return (
        <>

            <Row style={{ margin: '0' }}>

                {isBusy ? <><Spinner size="md" color="warning"></Spinner><h4>{t("pammSubsccription.loading")}...</h4><br /></> : ''}

                {activeList.length > 0 && PammActiveList}

                {/* Show when no any active manager */}
                {activeList.length <= 0 && <Link to="/pamm">
                    <Button color="primary" style={{ marginLeft: '15px' }}>
                        {t("pammSubsccription.SubscribeHere")}
                    </Button>
                </Link>}


            </Row>

            <div className="mt-3">
                
                <PammPendingTable />
            </div>


            {showUnsubscribe && (
                <Modal isOpen={showUnsubscribe} backdrop={true} centered={true}>
                    <ModalHeader toggle={toggleModal}></ModalHeader>
                    <ModalBody>
                        <Unsubscribe activeUserData={selectedSubcribe} />
                    </ModalBody>
                    <ModalFooter><Button onClick={toggleModal} color="primary">{t("pammSubsccription.Close")} </Button></ModalFooter>
                </Modal>

            )}
        </>
    )
}

export default PammActiveSubscription

