import { SET_MY_AVAILABLE_PROMOTIONS, SET_LIFETIME_PROMOTIONS, SET_PROMOTIONS } from '../actionTypes'

const initial_state = {
  promotions: [],
  lifetimePromotions: []
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_PROMOTIONS:
      return { ...state, promotions: action.promotions }

    case SET_LIFETIME_PROMOTIONS:
      return { ...state, lifetimePromotions: action.lifetimePromotions }

    case SET_MY_AVAILABLE_PROMOTIONS:
      return { ...state, myAvailablePromotions: action.myAvailablePromotions }

    default:
      return { ...state }
  }
}
