import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Card, CardBody, Spinner, Button } from 'reactstrap'
import BankForm from './form'
import BankCard from './bank_card'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    justify-content: flex-start;

    > button {
      margin-top: 8px;
    }
  }
`
const ResponsiveButton = styled(Button)`
  background-color: #963737 !important;
  border-color: #b35855 !important;
`
const ResponsiveParagraph = styled.p`
  margin: 0;

  @media (max-width: 575px) {
    margin-bottom: 8px;
  }
`
const BankDetail = () => {
  const { t } = useTranslation()
  const userCreditCard = useSelector((state) => _.last(state.user.credit_cards))
  const creditCards = useSelector((state) => state.user.credit_cards)
  const [createNew, setCreateNew] = useState(false)
  const [showRejected, setShowRejected] = useState(false)
  const [reupload, setReupload] = useState(false)

  if (creditCards != null) {
    if (creditCards?.length > 0) {
      return (
        <>
          {userCreditCard?.status !== 1 && userCreditCard?.status !== 0 && (
            <Card>
              <CardBody style={{ backgroundColor: '#ffc4c4', borderRadius: '10px' }}>
                <StyledRow>
                  <p style={{ margin: 0, color: '#963737' }}>
                    <strong>{t('dashboard.yourCreditCardDetailsIsntVerified')}</strong>
                  </p>
                  {/* {userCreditCard?.status === 3 && !reupload && (
                    <ResponsiveButton color="primary" onClick={() => setReupload(true)}>
                      Reupload
                    </ResponsiveButton>
                  )} */}
                </StyledRow>

                {userCreditCard?.status === 3 && userCreditCard?.reason && (
                  <>
                    <ResponsiveParagraph style={{ color: '#ab0505' }}>
                    {t("user.YourRecentApplication")}
                    </ResponsiveParagraph>
                    <ResponsiveParagraph
                      style={{ color: '#ab0505' }}
                    >{t('user.Reason')}{`: ${userCreditCard?.reason}`}</ResponsiveParagraph>
                  </>
                )}
              </CardBody>
            </Card>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => setShowRejected(!showRejected)}>
            {t("user.Show")}{showRejected ? t("user.VerifiedOnly") : t("user.All")}
            </Button>
            {creditCards[creditCards.length - 1].status !== 0 &&
              (!createNew ? (
                <Button
                  color="primary"
                  onClick={() => {
                    setCreateNew(true)
                  }}
                >
                  {t('user.AddNewCreditCard')}
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={() => {
                    setCreateNew(false)
                  }}
                >
                  {t("user.Cancel")}
                </Button>
              ))}
          </div>
          <div style={{ padding: 15 }} />

          {creditCards[creditCards.length - 1].status !== 0 && createNew && (
            <Card>
              <CardBody>
                <BankForm />
              </CardBody>
            </Card>
          )}

          {creditCards.map((creditCard) => {
            if (!showRejected && creditCard.status === 3) {
              return <React.Fragment key={creditCard.id}></React.Fragment> 
            }

            return <BankCard key={creditCard.id} creditCard={creditCard} />
          })}
        </>
      )
    }

    return (
      <>
        <Card>
          <CardBody>
            <BankForm />
          </CardBody>
        </Card>
      </>
    )
  }

  // loading
  return (
    <Card>
      <CardBody style={{ textAlign: 'center' }}>
        <Spinner color="warning"/>
      </CardBody>
    </Card>
  )
}

export default BankDetail
