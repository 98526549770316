
let starterPartibanPackage

export default starterPartibanPackage = {
    firstStep100: {
        benefits: [


            // {
            //     name: '10% Extra Bonus',
            //     included: 'yes'
            // }
            // ,
            {
                name: 'Introduction to Forex',
                included: 'yes'
            },
            {
                // name: '个人专属顾问',
                // name: 'vePackageInfo.ExclusivePersonalConsultant',
                name: 'Sneak Peak on SLH',
                included: 'yes'
            }
        ]
    },

    firstStep300: {
        benefits: [
            
            // {
            //     name: '30% Extra Bonus',
            //     included: 'yes'
            // }
            // ,
            {
                name: 'Introduction to Forex',
                included: 'yes'
            },
            {
                // name: '个人专属顾问',
                // name: 'vePackageInfo.ExclusivePersonalConsultant',
                name: 'Sneak Peak on SLH',
                included: 'yes'
            },
            {
                // name: '个人专属顾问',
                name: 'Bootscamp Session',
                included: 'yes'
            }
            


        ]
    },

    firstStep500: {
        benefits: [
            // {
            //     name: '50% Extra Bonus',
            //     included: 'yes'
            // }
            // ,
            {
                name: 'Introduction to Forex',
                included: 'yes'
            },
            {
                // name: '个人专属顾问',
                // name: 'vePackageInfo.ExclusivePersonalConsultant',
                name: 'Sneak Peak on SLH',
                included: 'yes'
            },
            {
                // name: '个人专属顾问',
                name: 'Bootscamp Session',
                included: 'yes'
            },
            {
                name: 'Partiban Bonus',
                included: 'yes'
            }
        ]
    }

}


