import client from '../request'

const get = () => {
    return client.get('/pamms/applications/check_canapply')
}

const create = (data) => {
    return client.post(`/pamms/applications`, data)
}

const editPammAccount = (id, data) => {
    return client.put(`/pamms/applications/${id}`, data)
}

const getIdle = () => {
    return client.get(`/me/accounts/idle`)
  }

export default {
    get,
    create,
    editPammAccount,
    getIdle
}
