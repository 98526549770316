import React, { useState, useRef, useEffect } from "react"
import DataTable from "react-data-table-component"
import moment from "moment"

import EditForm from './editRecord'

import { Button, Card, CardBody, Col } from 'reactstrap'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ResponsiveCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ResponsiveInviteButton = styled(Button)`
  background-color: #b35855 !important;
  border-color: #b35855 !important;
  margin-left: 12px;
  @media (max-width: 575px) {
    align-self: flex-end;
  }
`

const ResponsiveParagraph = styled.p`
  margin: 0;

  @media (max-width: 575px) {
    margin-bottom: 8px;
  }
`



const PammRecord = (props) => {
    const { t } = useTranslation()
    const columns = [

        {
            name: t("myPamm.account"),
            selector: "account",
            sortable: true,
            width: '200px',
            format: (row) => {
                return row.account_id ?? "-"
            }
        },
        {
            name: t("myPamm.previousBrokerage"),
            selector: "previous_brokerage",
            sortable: true,
            width: '200px',
            format: (row) => {
                return row.previous_brokerage ?? "-"
            }
        },
        {
            name: t("myPamm.tradeDuration"),
            selector: "trade_duration",
            sortable: true,
            width: '200px',
            format: (row) => {
                switch (row.trade_duration) {
                    case 0:
                        return <p>{t("myPamm.noExperience")}</p>
                    case 1:
                        return <p>{t("myPamm.lessThan1Year")}</p>
                    case 2:
                        return <p>1 - 5 {t("myPamm.years")}</p>
                    case 3:
                        return <p>5 - 10 {t("myPamm.years")}</p>
                    case 4:
                        return <p>{t("myPamm.over10Years")}</p>
                    default:
                        return "-"
                }
            },
        },
        {
            name: t("myPamm.tradeRecord"),
            selector: "trade_duration",
            sortable: true,
            width: '200px',
            format: (row) => {
                return <a href={row.trade_statement_url} target="_blank">{t("myPamm.Check")}</a> ?? '-'
            }
        },
        {
            name: t("myPamm.dateCreated"),
            selector: "created_at",
            sortable: true,
            width: "200px",
            format: (row) => {
                return moment(row.created_at).format("yyyy-MM-DD")
            },
        },
        {
            name: t("myPamm.status"),
            selector: "status",
            sortable: true,
            width: '200px',
            format: (row) => {
                switch (row.status) {
                    case 0:
                        return <p>{t("myPamm.pending")}</p>
                    case 1:
                        return <p style={{ color: 'green' }}>{t("myPamm.active")}</p>
                    case 2:
                        return <p style={{ color: 'red' }}>{t("myPamm.reject")}</p>
                    default:
                        return "-"
                }
            },
        }
    ]
    
    const [isBusy, setIsBusy] = useState(false)
    const wrapperRef = useRef()
    const data = []
    const [editAble, setEditAble] = useState(false)


    const init = async () => {
        setIsBusy(true)
        data.push(props.pammRecord)
        setIsBusy(false)

    }


    useEffect(() => {
        init()
    }, [])


    return (
        <>
            {props.pammRecord.status === 2 ? <>
                <Col style={{ paddingRight: '0', paddingLeft: '0' }}>
                    <Card>
                        <CardBody className="p-4" style={{ backgroundColor: '#ffc4c4', borderRadius: '5px' }}>
                            <ResponsiveCard>
                                <ResponsiveParagraph style={{ color: '#963737', fontWeight: 'bold' }}>{t("myPamm.yourRegistrationHadBeenReject")}</ResponsiveParagraph>
                                <ResponsiveInviteButton
                                    
                                    onClick={() => {
                                        setEditAble(!editAble)
                                    }}
                                >
                                    {t("myPamm.edit")}
                    </ResponsiveInviteButton>

                            </ResponsiveCard>
                            <ResponsiveParagraph style={{ color: '#963737', fontWeight: 'bold' }}>{t("myPamm.reason")} {props.pammRecord.reason}</ResponsiveParagraph>
                        </CardBody>
                    </Card>
                </Col>

            </> : ''}

            {editAble ? <EditForm previousData={props.pammRecord} /> : ''}

            <div className="product-wrapper" ref={wrapperRef} >
                <div className="product-grid" style={{ minHeight: "50vh" }}>
                    <div className="product-wrapper-grid" >
                        <h5>{t("myPamm.myPAMMRecord")}</h5>
                        <DataTable
                            noHeader
                            data={[props.pammRecord]}
                            columns={columns}
                            striped={true}
                            center={true}
                            progressPending={isBusy}
                        />
                    </div>
                </div>
            </div>
        </>

    )
}

export default PammRecord
