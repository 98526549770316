import client from '../request'
import _ from 'lodash'

const getAll = () => {
    return client.get('/user_notifications?page=1&limit=999')
}

//user_notifications_count = number
const getUnredNotication = () => {
    return client.get(`/user_notifications/unread`)
}

const readTheNotification = (id) => {
    return client.put(`/user_notifications/${id}`)
}

const getSingleNoti = (id) => {
    return client.get(`/user_notifications/${id}`)
}

export default {
    getAll,
    getUnredNotication,
    readTheNotification,
    getSingleNoti
}
