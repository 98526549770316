import React from 'react'

import {
    Container
} from 'reactstrap'

import Breadcrumb from '../../layout/breadcrumb'
import Courses from './allCourses/index'



const Pamm = () => {

    // const [showGraph, setShowGraph] = useState(true)

    return <>
        <Breadcrumb parent="Courses" title="Courses"></Breadcrumb>
        <Container style={{ paddingBottom: 40 }}>
            {/* {showGraph ? <PammManager/> : <PammManagerTable/>}  */}
            <Courses />
        </Container>

    </>
}

export default Pamm