import React, { useState, useEffect } from 'react'
import { Row, Form, Col, Card, CardBody, Button, Spinner } from 'reactstrap'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import StepZilla from 'react-stepzilla'
import SweetAlert from 'sweetalert2'
import { useHistory } from 'react-router-dom'

import ChooseAmount from './amount'
import SelectCurrency from './currency'
import SelectAccount from './account'
import WalletService from '../../../../network/services/wallet'
import AccountService from '../../../../network/services/account'
import { useTranslation } from 'react-i18next'

import PammSubscribe from '../../../../network/services/pammSubscription'
import moment from 'moment'

const MT5Withdrawal = ({ setOption }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const withdrawal = useSelector((state) => state.withdrawal)
  const selectedAccountId = useSelector((state) => state.withdrawal.mt5.account)
  const liveAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter((e) => e.account_type !== 1)
    } else if (state.account.accounts?.length == 0) {
      return []
    } else {
      return null
    }
  })
  const methods = useForm()
  const [stage, setStage] = useState(0)

  const [activePammAccountId, setActivePammAccountId] = useState([])
  const [unsubscribeIsPendingId, setunsubscribeIsPendingId] = useState([])
  const [selectedAccountFromPamm, setSelectedAccountFromPamm] = useState([])

  const fetchActivePammAccount = async () => {
    const response = await PammSubscribe.getAll()

    const isActiveAccount = response?.active.map((item) => item.account_id)
    const isUnsubscribeStillPending = response?.pending.map((item) => item?.status === 3 && item.account_id)

    
    setunsubscribeIsPendingId(isUnsubscribeStillPending)
    setActivePammAccountId(isActiveAccount.concat(isUnsubscribeStillPending))

    setSelectedAccountFromPamm(
      response?.active?.filter((item) => item.account_id === selectedAccountId)
    )
  }

  useEffect(() => {
    fetchActivePammAccount()
  }, [selectedAccountId])


  let today = moment().format('YYYY-MM-DD')
  // use redux for payment_id & wallet_id
  // because form doesn't work with stepzilla
  const handleWithdraw = async (data) => {

    let checkAccountCredit = await AccountService.checkAccountCredit(withdrawal.mt5.account)
    // console.log('checkAccountCredit', checkAccountCredit)

    const selectedAccount = liveAccounts.filter(account => account?.id === withdrawal.mt5.account)

    let settlementDate_tomorrow1 =
      selectedAccountFromPamm[0]?.pammManager?.settlement_period == 'Monthly'
        ? moment().startOf('month').add(1, 'days').format('YYYY-MM-DD')
        : moment(selectedAccountFromPamm[0]?.pammManager?.settlement_date).add(1, 'days').format('YYYY-MM-DD')

    let settlementDate_tomorrow2 =
      selectedAccountFromPamm[0]?.pammManager?.settlement_period == 'Monthly'
        ? moment().startOf('month').add(2, 'days').format('YYYY-MM-DD')
        : moment(selectedAccountFromPamm[0]?.pammManager?.settlement_date).add(2, 'days').format('YYYY-MM-DD')

    let is_action_date_1 = today === settlementDate_tomorrow1
    let is_action_date_2 = today === settlementDate_tomorrow2


    if (
      activePammAccountId.includes(withdrawal.mt5.account) &&
      is_action_date_1 === false && is_action_date_2 === false
    ) {

      if (checkAccountCredit?.credit_balance !== 0) {
        // PAMM Subsribed user when have credit
        SweetAlert.fire({
          title: `#${selectedAccount[0]?.account_login} ${t("financial.contain")} $${checkAccountCredit?.credit_balance} ${t("financial.credit")} `,
          html: `${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
                  <br/>
                  <p style="color:red;">${t("financial.OnceYouConfirm")} <strong>${t("financial.VOIDED")}</strong> ${t("financial.partiallyOrEntirely")}</p>
                  <div>
                            <p style="margin-bottom:0px;"
                            >*${t("financial.KindlyTakeNote")}*</p>
                            <p>
                                1. ${t("financial.ByTypingAGREE")}
                                <br/>
                                2. ${t("financial.TheCreditWillOnly")}
                                <br/>
                                3. ${t("financial.IfYouHave")} <Link to='/support'>${t("financial.customerService")}</Link>. 
                            </p>
                          </div>
                  `,
          input: 'text',
          inputValue: '',
          inputPlaceholder: '',
          inputValidator: (result) => {
            if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
              return t("financial.PleaseEnterThe")
            }
          },
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: t("financial.confirm"),
          cancelButtonText: t("financial.cancel"),
          reverseButtons: true,

        }).then(async (result) => {
          if (result.value) {
            SweetAlert.fire({
              title: `${t('financial.YouAreSubscribing')} ${selectedAccountFromPamm[0]?.pammManager?.penalty_percent
                }% ${t('financial.fundDirectly')}`,
              html: `
                <div style="margin-top:10px;">
                  ${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
                </div>

                <div >
                            <p style="margin-bottom:0px;margin-top:10px;"
                            >*${t("financial.KindlyTakeNote")}*</p>
                            <p>
                                1. ${t("financial.ByTypingAgreeDeduct")} ${selectedAccountFromPamm[0]?.pammManager?.penalty_percent}% ${t('financial.fundDirectly')}
                                <br/>
                                2. ${t("financial.IfYouHave")} <a href='/support' target="_blank">${t("financial.customerService")}</a> ${t("financial.orReferThe")} <a
                                href="${selectedAccountFromPamm[0]?.op_url}"
                                target="_blank"
                            >
                                <span>5.4 ${t("pamm.termsConditions")}</span>
                            </a>.  
                            </p>
                          </div>

                          <div class='text-center'>
                          
                  </div>

                
                
            `,
              input: 'text',
              inputValue: '',
              inputPlaceholder: '',
              inputValidator: (result) => {
                if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                  return t("financial.PleaseEnterThe")
                }
              },
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: t('financial.confirm'),
              cancelButtonText: t('financial.cancel'),
              reverseButtons: true
            }).then(async (result) => {
              if (result.value) {
                SweetAlert.fire({
                  title: t('financial.areYouSureWithdraw'),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: t('financial.confirm'),
                  cancelButtonText: t('financial.cancel'),
                  reverseButtons: true
                }).then(async (result) => {
                  if (result.value) {
                    try {
                      data['account_id'] = withdrawal.mt5.account
                      data['wallet_id'] = withdrawal.mt5.wallet
                      // console.log(data)

                      if (data !== '') {
                        let result = await AccountService.withdraw(withdrawal.mt5.account, {
                          amount: data.amount,
                          is_voiding_credit: true,
                          account_id: withdrawal.mt5.account,
                          wallet_id: withdrawal.mt5.wallet
                        })
                        // console.log(result)

                        if (result?.transaction?.id) {
                          methods.reset()

                          SweetAlert.fire({
                            title: t('financial.withdrawSuccessfully'),
                            text: t('financial.yourRequestWillBeReviewByAdmin'),
                            icon: 'success'
                          }).then(() => {
                            window.location.reload()
                          })
                        }
                      } else {
                        methods.errors.showMessages()
                      }
                    } catch (error) {
                      // SweetAlert.fire({
                      //   title: error.message,
                      //   icon: 'error'
                      // })
                      const errorCode = error.code
                      const errorTranslate = error.translate_params
                      SweetAlert.fire({
                        title: t(errorCode, errorTranslate),
                        icon: 'error'
                      })
                    }
                  }
                })
              }
            })
          }
        })
      } else if (unsubscribeIsPendingId.includes(withdrawal.mt5.account)) {
        //user's unsubsribe is pending

        SweetAlert.fire({
          title: `You are unubscribe pending with PAMM`,
          html: `
              <p>Please wait for approved to process this operation</p>
            `,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Close',
          cancelButtonText: t('financial.cancel'),
          reverseButtons: true
        })

      }

      else {
        // PAMM Subscribed user when not have credit
        SweetAlert.fire({
          title: `${t('financial.YouAreSubscribing')} ${selectedAccountFromPamm[0]?.pammManager?.penalty_percent
            }% ${t('financial.fundDirectly')}`,
          html: `
                <div style="margin-top:10px;">
                  ${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
                </div>

                <div >
                            <p style="margin-bottom:0px;margin-top:10px;"
                            >*${t("financial.KindlyTakeNote")}*</p>
                            <p>
                                1. ${t("financial.ByTypingAgreeDeduct")} ${selectedAccountFromPamm[0]?.pammManager?.penalty_percent}% ${t('financial.fundDirectly')}
                                <br/>
                                2. ${t("financial.IfYouHave")} <a href='/support' target="_blank">${t("financial.customerService")}</a> ${t("financial.orReferThe")} <a
                                href="${selectedAccountFromPamm[0]?.op_url}"
                                target="_blank"
                            >
                                <span>5.4 ${t("pamm.termsConditions")}</span>
                            </a>.  
                            </p>
                          </div>

                          <div class='text-center'>
                          
                  </div>

                
                
            `,
          input: 'text',
          inputValue: '',
          inputPlaceholder: '',
          inputValidator: (result) => {
            if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
              return t("financial.PleaseEnterThe")
            }
          },
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: t('financial.confirm'),
          cancelButtonText: t('financial.cancel'),
          reverseButtons: true
        }).then(async (result) => {
          if (result.value) {
            SweetAlert.fire({
              title: t('financial.areYouSureWithdraw'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: t('financial.confirm'),
              cancelButtonText: t('financial.cancel'),
              reverseButtons: true
            }).then(async (result) => {
              if (result.value) {
                try {
                  data['account_id'] = withdrawal.mt5.account
                  data['wallet_id'] = withdrawal.mt5.wallet
                  // console.log(data)

                  if (data !== '') {
                    let result = await AccountService.withdraw(withdrawal.mt5.account, {
                      amount: data.amount,
                      is_voiding_credit: false,
                      account_id: withdrawal.mt5.account,
                      wallet_id: withdrawal.mt5.wallet
                    })
                    // console.log(result)

                    if (result?.transaction?.id) {
                      methods.reset()

                      SweetAlert.fire({
                        title: t('financial.withdrawSuccessfully'),
                        text: t('financial.yourRequestWillBeReviewByAdmin'),
                        icon: 'success'
                      }).then(() => {
                        window.location.reload()
                      })
                    }
                  } else {
                    methods.errors.showMessages()
                  }
                } catch (error) {
                  // SweetAlert.fire({
                  //   title: error.message,
                  //   icon: 'error'
                  // })
                  const errorCode = error.code
                  const errorTranslate = error.translate_params
                  SweetAlert.fire({
                    title: t(errorCode, errorTranslate),
                    icon: 'error'
                  })
                }
              }
            })
          }
        })
      }

    }
    else {
      // Normal user who not subscribe PAMM but contain credit
      if (checkAccountCredit?.credit_balance !== 0) {

        SweetAlert.fire({
          title: `#${selectedAccount[0]?.account_login} ${t("financial.contain")} $${checkAccountCredit?.credit_balance} ${t("financial.credit")} `,
          html: `${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
                  <br/>
                  <p style="color:red;">${t("financial.OnceYouConfirm")} <strong>${t("financial.VOIDED")}</strong> ${t("financial.partiallyOrEntirely")}</p>
                  <div>
                            <p style="margin-bottom:0px;"
                            >*${t("financial.KindlyTakeNote")}*</p>
                            <p>
                                1. ${t("financial.ByTypingAGREE")}
                                <br/>
                                2. ${t("financial.TheCreditWillOnly")}
                            </p>
                          </div>
                  `,
          input: 'text',
          inputValue: '',
          inputPlaceholder: '',
          inputValidator: (result) => {
            if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
              return t("financial.PleaseEnterThe")
            }
          },
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: t("financial.confirm"),
          cancelButtonText: t("financial.cancel"),
          reverseButtons: true,

        }).then(async (result) => {
          if (result.value) {
            SweetAlert.fire({
              title: t('financial.areYouSureWithdraw'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: t('financial.confirm'),
              cancelButtonText: t('financial.cancel'),
              reverseButtons: true
            }).then(async (result) => {
              if (result.value) {
                try {
                  data['account_id'] = withdrawal.mt5.account
                  data['wallet_id'] = withdrawal.mt5.wallet
                  // console.log(data)

                  if (data !== '') {
                    let result = await AccountService.withdraw(withdrawal.mt5.account, {
                      amount: data.amount,
                      is_voiding_credit: true,
                      account_id: withdrawal.mt5.account,
                      wallet_id: withdrawal.mt5.wallet
                    })
                    // console.log(result)

                    if (result?.transaction?.id) {
                      methods.reset()

                      SweetAlert.fire({
                        title: t('financial.withdrawSuccessfully'),
                        text: t('financial.yourRequestWillBeReviewByAdmin'),
                        icon: 'success'
                      }).then(() => {
                        window.location.reload()
                      })
                    }
                  } else {
                    methods.errors.showMessages()
                  }
                } catch (error) {
                  // SweetAlert.fire({
                  //   title: error.message,
                  //   icon: 'error'
                  // })
                  const errorCode = error.code
                  const errorTranslate = error.translate_params
                  SweetAlert.fire({
                    title: t(errorCode, errorTranslate),
                    icon: 'error'
                  })
                }
              }
            })
          }
        })


      } else {
        // Normal user who not subscribe PAMM without contain credit
        SweetAlert.fire({
          title: t('financial.areYouSureWithdraw'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: t('financial.confirm'),
          cancelButtonText: t('financial.cancel'),
          reverseButtons: true
        }).then(async (result) => {
          if (result.value) {
            try {
              data['account_id'] = withdrawal.mt5.account
              data['wallet_id'] = withdrawal.mt5.wallet
              // console.log(data)

              if (data !== '') {
                let result = await AccountService.withdraw(withdrawal.mt5.account, {
                  amount: data.amount,
                  is_voiding_credit: false,
                  account_id: withdrawal.mt5.account,
                  wallet_id: withdrawal.mt5.wallet
                })
                // console.log(result)

                if (result?.transaction?.id) {
                  methods.reset()

                  SweetAlert.fire({
                    title: t('financial.withdrawSuccessfully'),
                    text: t('financial.yourRequestWillBeReviewByAdmin'),
                    icon: 'success'
                  }).then(() => {
                    window.location.reload()
                  })
                }
              } else {
                methods.errors.showMessages()
              }
            } catch (error) {
              // SweetAlert.fire({
              //   title: error.message,
              //   icon: 'error'
              // })
              const errorCode = error.code
              const errorTranslate = error.translate_params
              SweetAlert.fire({
                title: t(errorCode, errorTranslate),
                icon: 'error'
              })
            }
          }
        })
      }
    }


  }

  const init = async () => {
    const walletResponse = await WalletService.get()
    // console.log(walletResponse)
    dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })

    const accountResponse = await AccountService.getAll()
    // console.log(accountResponse)
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {liveAccounts != null ?
        liveAccounts?.length > 0 ? (
          <>
            <Card>
              <CardBody>
                <Row>
                  <Col
                    sm={12}
                    md={4}
                    className={`u-pearl ${stage === 0 && 'current'} ${stage > 0 && 'done'}`}
                  >
                    <span className="u-pearl-number">1</span>
                    <span className="u-pearl-title">{t('financial.selectYourAccount')}</span>
                  </Col>
                  <Col
                    sm={12}
                    md={4}
                    className={`u-pearl ${stage === 1 && 'current'} ${stage > 1 && 'done'}`}
                  >
                    <span className="u-pearl-number">2</span>
                    <span className="u-pearl-title">{t('financial.chooseWalletCurrency')}</span>
                  </Col>
                  <Col sm={12} md={4} className={`u-pearl ${stage === 2 && 'current'}`}>
                    <span className="u-pearl-number">3</span>
                    <span className="u-pearl-title">{t('financial.chooseAmount')}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <FormProvider {...methods}>
              <Form className="theme-form" onSubmit={methods.handleSubmit(handleWithdraw)}>
                <StepZilla
                  steps={[
                    {
                      name: t('financial.Step1'),
                      component: <SelectAccount />
                    },
                    {
                      name: t('financial.Step2'),
                      component: <SelectCurrency />
                    },
                    {
                      name: t('financial.Step3'),
                      component: <ChooseAmount />
                    }
                  ]}
                  showSteps={false}
                  onStepChange={(index) => {
                    setStage(index)
                  }}
                  backButtonText={t('financial.Previous')}
                  nextButtonText={t('financial.Next')}
                />
              </Form>
            </FormProvider>
          </>
        ) : (
            <>
              <Card>
                <CardBody>
                  <p>{t('financial.noActiveAccounts')}</p>
                  <Button onClick={() => navigate(`${process.env.PUBLIC_URL}/live`)}>
                    {t('financial.registerOneHere')}
                  </Button>
                </CardBody>
              </Card>
            </>
          ) : (
          <div style={{ textAlign: 'center' }}>
            <Spinner color="warning" />
          </div>
        )}

      {stage === 0 && liveAccounts && (
        <Button
          color="primary"
          onClick={() => {
            setOption(null)
          }}
        >
          {t('financial.back')}
        </Button>
      )}

      {/* Add gap */}
      <div style={{ padding: 24, clear: 'both' }} />
    </>
  )
}

export default MT5Withdrawal
