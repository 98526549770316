import client from "../request"

const get = () => {
  return client.get(`/me/wallets`)
}

const deposit = (data) => {
  return client.post(`/me/deposits`, data)
}

const withdraw = (data) => {
  return client.post(`/me/withdraws`, data)
}

export default {
  get,
  deposit,
  withdraw,
}
