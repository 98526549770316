import React, { useEffect } from 'react'
import { Container, Button, Card, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import Breadcrumb from '../../layout/breadcrumb'
import ActiveLiveAccounts from './accounts'

import SweetAlert from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import user from '../../network/services/user'

const LiveAccounts = () => {
  const { t } = useTranslation()
  let history = useHistory()
  const dispatch = useDispatch()
  const verification = useSelector((state) => _.last(state.user.verifications))
  const user = useSelector((state) => state.user.user)
  const profile = useSelector((state) => state.user.profile)

  const liveAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter((e) => (e.account_type === 0 || e.account_type === 2))
    } else if (state.account.accounts?.length === 0) {
      return []
    } else {
      return null
    }
  })

  function navigate(path) {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }
  

  const checkEligible = () => {
    if (liveAccounts?.length > 0) {
      navigate(`${process.env.PUBLIC_URL}/live/register`)
    } else if (liveAccounts?.length === 0 && user?.is_asic === 1) {
      navigate(`${process.env.PUBLIC_URL}/live/register`)
    } else if (liveAccounts?.length === 0 && !_.isEmpty(profile)) {
      //mt5 disconnect
      SweetAlert.fire({

        title: t("live.OfferLive"),
        html: t("live.YouCanCreate"),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Confirm',

      })
        .then(async (result) => {
          if (result.value) {
            navigate(`${process.env.PUBLIC_URL}/create-first-mt5`)
          }

        })
    }
     else if (liveAccounts?.length === 0 && _.isEmpty(profile)) {
      //mt5 disconnect
      SweetAlert.fire({

        title: t("live.OfferLive"),
        html: t("live.YouCanCreate"),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Confirm',

      })
        .then(async (result) => {
          if (result.value) {
            navigate(`${process.env.PUBLIC_URL}/verify-details`)
          }

        })
    }
  }



  return (
    <>

      <Breadcrumb
        title={t("live.liveAccounts")}
        action={
          (
            <div className="pull-right">

              <Button color="primary" disabled={liveAccounts?.length >= 7} onClick={() => checkEligible()}
              >{t("live.register")}</Button>
            </div>
          )
        }
      />
      <Container>
        {/* {verification?.status !== 1 ? (
            <>
              <Card>
                <CardBody>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <h6 style={{ margin: 0 }}>{t("live.pleaseVerifAccount")}</h6>
                    <Button
                      color="primary"
                      onClick={() => {
                        navigate(`${process.env.PUBLIC_URL}/account#verification`)
                      }}
                    >
                      {t("live.proceed")}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </>
          ) : ( */}
        <ActiveLiveAccounts />
        {/* )} */}
      </Container>

    </>
  )
}

export default LiveAccounts
