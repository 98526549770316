import React from 'react'
import { useSelector } from 'react-redux'
import AwepayAmount from './awepay'
import BluepayAmount from './bluepay'
import WireAmount from './wire'
import MultiChain from './multichain'

const ChooseAmount = () => {
  const method = useSelector((state) => state.withdrawal.wallet.method)

  if (method.handle === 'awepay') {
    return <AwepayAmount />
  } else if (method.handle === 'bluepay') {
    return <BluepayAmount />
  } else if (method.handle === 'wire') {
    return <WireAmount />
  } else if (method.handle === 'multichain') {
    return <MultiChain />
  }

  return <></>
}

export default ChooseAmount
