import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Card,
    Badge,
    Container
} from 'reactstrap'

import CourseService from '../../../network/services/courses'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'



import "intro.js/introjs.css";
import { Steps } from 'intro.js-react';
import Breadcrumb from '../../../layout/breadcrumb'
import ReactGA from 'react-ga'

const Courses = (props) => {
    let history = useHistory()
    const dispatch = useDispatch()
    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: path })
    }

    const { t } = useTranslation()
    // const [pammManager, setPammManager] = useState([])
    const [allCourses, setAllCourses] = useState([])

    const [availableCourses, setAvailableCourses] = useState([])
    const [notAvailableCourses, setNotAvailableCourses] = useState([])
    const [starterCourse, setStarterCourse] = useState([])
    const [premiumCourse, setPremiumCourse] = useState([])
    const [otherCourse, setOtherCourse] = useState([])

    const [searchResults, setSearchResults] = useState([])


    const init = async () => {
        const coursesResult = await CourseService.getAll()
        setAllCourses(coursesResult?.courses)

        // console.log('coursesResult', coursesResult)
        setAvailableCourses(coursesResult?.courses?.filter((course => {
            return course.is_course_available
        })))

       

    }

    useEffect(() => {
        init()
        // eslint-disable-next-line
    }, [])

    const toChapterPage = (course, courseID) => {

        if (course?.is_course_available) {

            window.open(`/courses/${course?.id}/chapters`, '_blank'); 
            // navigate(`${process.env.PUBLIC_URL}/courses/${course?.id}/chapters`)
        } else if (courseID === 9 || courseID === 10) {
            SweetAlert.fire({
                title: `${t('courses.ThisCourseIsBelongsTo')} ${course?.name}`,
                html: `<p>${t('courses.YouMay')} Find <a href="/support" target="_blank">Customer Service</a> To View This Content</p>`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: t('financial.confirm'),
                reverseButtons: true
            })
        }
        else {
            SweetAlert.fire({
                title: `${t('courses.ThisCourseIsBelongsTo')} ${course?.name}`,
                html: `<p>${t('courses.YouMay')} <a href="/live" target="_blank">${t('courses.Upgrade')}</a> ${t('courses.ToThisPackageToViewThisContent')}</p>`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: t('financial.confirm'),
                reverseButtons: true
            })
        }
    }



    return (
        <>
            <Breadcrumb parent="My Courses" title="My Courses"></Breadcrumb>
            <Container style={{ paddingBottom: 40 }}>
                {availableCourses.length >= 1 &&
                    <>
                        <Row style={{ marginTop: "25px" }}>

                            {availableCourses?.map(course => {
                                return (
                                    course?.status &&
                                    <Col key={course.id} xl='3' lg='3' className="result">
                                        {/* <Link to='courses/course/123'> */}
                                        <Card
                                            onClick={() => {
                                                toChapterPage(course)
                                                ReactGA.event({
                                                    category: 'Courses',
                                                    action: 'Click available courses ',
                                                    label: 'Click available courses '
                                                })
                                            }}
                                            style={{
                                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                backgroundColor: 'white',
                                                color: 'black',

                                            }}
                                        >

                                            <div style={{
                                                cursor: course?.is_course_available === false ? 'not-allowed' : 'pointer',
                                                filter: course?.is_course_available === false ? 'contrast(0.5)' : ''
                                            }}>

                                                <div >
                                                    {
                                                        course?.is_course_available === false &&
                                                        <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>

                                                    }
                                                    <img alt='course background' src={course?.cover_image_url}
                                                        style={{
                                                            width: '100%',
                                                            borderTopLeftRadius: '15px',
                                                            borderTopRightRadius: '5px',
                                                            height: '165px'
                                                        }}
                                                    />

                                                </div>

                                                <Row className="mt-3" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center', justifyContent: 'space-between' }}>

                                                    <Badge color={
                                                        course?.difficulty === 'Beginner' ? 'success' :
                                                            course?.difficulty === 'Intermediate' ? 'secondary' :
                                                                course?.difficulty === 'Advanced' ? 'danger' :
                                                                    'success'}>
                                                        {course?.difficulty}
                                                    </Badge>
                                                    <Badge style={{ marginRight: '30px', textTransform:'capitalize' }} color='dark'>{course?.course_language}</Badge>

                                                </Row>

                                                <Row style={{ color: 'black', marginLeft: '20px', marginBottom: '10px', marginTop: '10px', alignItems: 'center' }}>

                                                    <h4>{course?.name}</h4>

                                                </Row>

                                                <Row className="mt-2" style={{ color: 'black', marginLeft: '20px', marginBottom: '0px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <p style={{ marginBottom: '0', marginTop: '6px' }}>{course?.author}</p>
                                                    </div>

                                                    <div style={{ marginRight: '30px' }}>
                                                        <h5 style={{ margin: '0', textAlign: 'right', color: '#EEC256' }}>{course?.course_progress}% </h5>
                                                        <p >{t('courses.Complete')}</p>
                                                    </div>

                                                </Row>

                                            </div>


                                        </Card>
                                        {/* </Link> */}

                                    </Col>
                                )

                            })}


                        </Row>
                    </>
                }
            </Container>
        </>
    )
}

export default Courses
