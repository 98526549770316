import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Progress,
  Badge,
  FormGroup,
  Label,
  Input,
  CardTitle,
  CardText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'

import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import LiveSettings from '../common/settings'
import MasterPassword from '../common/settings/master_password'
import InvestorPassword from '../common/settings/investor_password'
import ChangeLeverage from '../common/settings/leverage'
import Deposit from '../common/settings/deposit'
import { Steps } from 'intro.js-react'
// import RedeemBonus from '../common/settings/redeem_bonus'

import { StyledRow, ResponsiveLiveDiv } from '../common/components.style'

import AccountService from '../../network/services/account'
import WalletService from '../../network/services/wallet'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import styled from 'styled-components'

import './index.scss'

import ScrollDownIcon from './down-arrow.png'
import GreenChecked from './checked.png'
import DiamondBackground from './DiamondBackground.png'
import StarterPack from './StarterPack.png'
import 'react-accessible-accordion/dist/fancy-example.css'

import VePackageModal from './vePackage.jsx'
import StarterPackageModal from './starterPackage.jsx'
import PartibanPremiumPackageModal from './partibanPremiumPack.jsx'
import PartibanStarterPackageModal from './partibanStarterPackage.jsx'



const ResponsiveParagraph = styled.p`
  @media (max-width: 578px) {
    font-size: 10px;
  }
`

const Responsiveh6 = styled.h6`
  @media (max-width: 578px) {
    font-size: 11px;
  }
`

const ActiveLiveAccounts = () => {
  let history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user.user)
  const currencies = useSelector((state) => state.currency.currencies)
  const total_deposit = useSelector((state) => state.wallet.totalDeposit)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [courseLanguageSelected, setCourseLanguage] = useState('Chinese Course')

  const toggle = () => {
    setDropdownOpen(!dropdownOpen)
  }

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
  // const user = useSelector((state) => state.user.user)

  // console.log(total_deposit, 'td')

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  // live accounts
  const liveAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter((e) => (e.account_type === 0 || e.account_type === 2))
    } else if (state.account.accounts?.length === 0) {
      return []
    } else {
      return null
    }
  })
  const selectedAccount = useSelector((state) => state.dashboard.account)
  const init = async () => {
    const accountResponse = await AccountService.getLiveAndIbAccount()
    const walletResponse = await WalletService.get()


    dispatch({ type: 'SET_TOTAL_DEPOSIT', totalDeposit: walletResponse.total_deposit })
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  // modal
  const [showModal, setShowModal] = useState(false)
  const [selectedSetting, setSelectedSetting] = useState(null)
  const toggleModal = () => {
    setShowModal(!showModal)
  }
  let settings = [
    {
      id: 1,
      title: t('dashboard.titleChangeMaster'),
      cta: <MasterPassword setShowModal={setShowModal} />
    },
    {
      id: 2,
      title: t('dashboard.titleChangeInvestor'),
      cta: <InvestorPassword setShowModal={setShowModal} />
    },
    {
      id: 3,
      title: t('dashboard.titleChangeLeverage'),
      cta: <ChangeLeverage setShowModal={setShowModal} />
    },
    // {
    //   id: 4,
    //   title: 'Redeem Bonus',
    //   cta: <RedeemBonus setShowModal={setShowModal} />
    // },
    // {}
    {
      id: 4,
      title: 'Deposit',
      cta: <Deposit />
    }
  ]

  if (total_deposit <= 0) {
    settings.pop()
    // console.log('when')
  }

  useEffect(() => {
    if (selectedSetting != null && selectedAccount != null) {
      setShowModal(true)
    }
  }, [selectedSetting, selectedAccount])

  useEffect(() => {
    if (showModal === false) {
      // fetch account
      if (selectedAccount != null) {
        init()
      }

      setSelectedSetting(null)
      dispatch({
        type: 'SET_DASHBOARD_ACCOUNT',
        account: null
      })
    }
    // eslint-disable-next-line
  }, [showModal])

  const [bonusModal, setBonusModal] = useState(false)
  const [accountDetails, setAccountDetails] = useState(null)

  const bonusToggle = (account) => {
    // console.log(account)
    setAccountDetails(account)
    setBonusModal(!bonusModal)
  }

  const [upgradeModal, setUpgradeModal] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(() => setStepsEnabled(true), 3000)
  }, [upgradeModal])

  const upgradeModalToggle = (account) => {
    // console.log(account)

    setAccountDetails(account)
    setUpgradeModal(!upgradeModal)
  }

  const [packageInfo, setPackageInfoModal] = useState(false)
  const packageInfoModalToggle = (account) => {

    setPackageInfoModal(!packageInfo)

    setTimeout(() => {
      setStepsPackageInfoEnabled(true)
    }, 2000)

  }

  const [vePremiumUpgradeModal, setVePremiumUpgradeModal] = useState(false)

  const upgradeVePremiumModalToggle = () => {
    // console.log(account)

    setVePremiumUpgradeModal(!vePremiumUpgradeModal)
  }

  const [partibanPremiumUpgradeModal, setPartibanPremiumUpgradeModal] = useState(false)

  const upgradePartibanPremiumModalToggle = () => {
    // console.log(account)

    setPartibanPremiumUpgradeModal(!partibanPremiumUpgradeModal)
  }


  const [partibanStarterUpgradeModal, setPartibanStarterUpgradeModal] = useState(false)

  const upgradePartibanStarterModalToggle = () => {
    // console.log(account)

    if (user?.is_starter_pack_eligible) {
      setPartibanStarterUpgradeModal(!partibanStarterUpgradeModal)

    } else {
      setNotAbleRedeemModal(!notAbleRedeemModal)
    }
  }

  const [notAbleRedeemModal, setNotAbleRedeemModal] = useState(false)

  const notAbleRedeemModalToggle = () => {
    setNotAbleRedeemModal(!notAbleRedeemModal)
  }

  const [starterPackageUpgradeModal, setStaterPackageUpgradeModal] = useState(false)

  const upgradeStarterPackageModalToggle = () => {
    // console.log(account)

    //check if user is able to redeem starter pack
    if (user?.is_starter_pack_eligible) {
      setStaterPackageUpgradeModal(!starterPackageUpgradeModal)
    } else {
      setNotAbleRedeemModal(!notAbleRedeemModal)
    }
  }

  const [stepsEnabled, setStepsEnabled] = useState(false)
  const steps = [
    {
      element: '#iAmFirst',
      intro: t('live.ThisIsThePage')
    },
    {
      element: '#starter-pack',
      intro: t('live.YouMayCheckTheDetails')
    },
    {
      element: '#premium-pack',
      intro: t('live.YouMayAlsoCheckTheDetails')
    },
    {
      element: '#faq',
      intro: t('live.IfYouHaveAnyQuestions')
    }
  ]

  const [stepsPackageInfoEnabled, setStepsPackageInfoEnabled] = useState(false)
  const stepsPackageInfo = [
    {
      element: '#packageName',
      intro: "this is package name"
    },
    {
      element: '#EForms',
      intro: t('live.YouMayCheckTheDetails')
    },
    {
      element: '#Echofin',
      intro: t('live.YouMayAlsoCheckTheDetails')
    },
    {
      element: '#OnlineTutorial',
      intro: t('live.IfYouHaveAnyQuestions')
    }
  ]

  const faqItems = [
    {
      heading: t('live.WhatIsTheDifference'),
      content: t('live.TheStarterPackIs')
    },
    {
      heading: t('live.HowDoTKnow'),
      content: t('live.IfYouAreNewToInvesting')
    }
  ]

  const premiumFaqItems = [
    {
      heading: 'What is the difference between the First Step Package and the Premium Package?',
      content: 'The First Step Package is designed to give the now ice investor a quick start, wile the Partiban Premium Package is a hands-on course that gives the student an in-depth understanding of the fundamentals and technical aspects of forex trading from A-Z.'
    },
    {
      heading: t('live.HowDoTKnow'),
      content: 'If you are new to investing end do not have the relevant experience and knowledge, we recommend the First Step Package. If you have the investment experience and basic knowledge rind want to learn and master more practical strategies and methods in greater depth, then Partiban Premium Package is perfect for you.'
    }
  ]

  const premiumBenefits = [
    {
      name: t('live.TalksForBeginners')
    },
    {
      name: t('live.FOREXIntermediateCourse')
    },
    {
      name: t('live.VEOnlineLibrary')
    },
    {
      name: t('live.WeeklyReview')
    }
  ]

  const premiumEnglishBenefits = [
    {
      name: 'Introduction to Forex'
    },
    {
      name: 'Stop Lose Hunting Program'
    },
    {
      name: 'Webinar Series'
    },
    {
      name: 'Private Telegram Group'
    }
  ]

  const handleStepChange = (newStepIndex, newElement) => {
    if (newStepIndex == 1) {
      window.scrollTo(0, 0)
    }
  }
  return (
    <>
      {liveAccounts != null ? (
        <>
          <Row style={{ justifyContent: 'space-between', marginRight: '0', marginLeft: '0' }}>
            {/* <h5 style={{ marginBottom: 20 }}>{t('live.liveAccounts')}</h5> */}

            <p style={{ fontSize: '12px' }}>
              {t('live.masterPasswordIs')}
              <strong>{t('live.trade')}</strong> {t('live.MT5LiveAccount')}
              <br></br>
              {t('live.inverterPasswordIs')}
              <strong>{t('live.review')}</strong> {t('live.MT5LiveAccount')}
            </p>

            <p style={{ fontSize: '16px' }}>
              {t('live.liveAccounts')}: {liveAccounts?.length} / 7
            </p>
          </Row>
          <Row>
            {liveAccounts?.length > 0 ? (
              liveAccounts.map((account) => {
                const currency = currencies.find((e) => {
                  return e.id === account.currency_id
                })

                return (
                  <Col lg="6" md="6" key={account.id}>
                    <Card
                      className="card-absolute"
                      style={{
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                      }}
                    >
                      <div className="live-card-right">
                        <ResponsiveLiveDiv
                          className="d-sm-block"
                          style={{
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            padding: '20px 40px 0px 40px'
                          }}
                        >
                          {(account.package_id === 4 ||
                            account.package_id === 5 ||
                            account.package_id === 6 ||
                            account.package_id === 13 ||
                            account.package_id === 14 ||
                            account.package_id === 15
                          ) &&
                            account?.account_type !== 2 && (
                              <>
                                <Button
                                  onClick={() => {
                                    packageInfoModalToggle(account)
                                  }}
                                >
                                  {t('live.PackageInfo')}
                                </Button>

                                <Modal
                                  id="recent-transaction-modal"
                                  isOpen={packageInfo}
                                  toggle={packageInfoModalToggle}
                                >
                                  <ModalHeader toggle={packageInfoModalToggle} centered={true}>
                                    {t('live.PackageInfo')}
                                  </ModalHeader>
                                  <ModalBody>
                                    <>
                                      <p id='packageName'>{t('live.PackageName')} : {account?.package?.name}</p>
                                      <p>
                                        {t('live.ApplicationSubmittedOn')} :{' '}
                                        {moment(account?.package?.created_at).format(
                                          'yyyy-MM-DD HH:mm:ss'
                                        )}
                                      </p>
                                      <p id='EForms'>
                                        E-forms :{' '}
                                        <a
                                          href={account?.latestPackageApplication?.enrollment_form}
                                          style={{
                                            textDecoration: 'underline',
                                            marginRight: '10px'
                                          }}
                                          target="_blank"
                                        >
                                          {t('live.EnglishEForm')}
                                        </a>
                                        <a
                                          href={
                                            account?.latestPackageApplication
                                              ?.enrollment_form_chinese
                                          }
                                          style={{ textDecoration: 'underline' }}
                                          target="_blank"
                                        >
                                          {t('live.ChineseEForm')}
                                        </a>
                                      </p>
                                      <p id='Echofin'>
                                        Chat Group :{' '}
                                        <a
                                          href={account.package.group_chat_url}
                                          style={{ textDecoration: 'underline' }}
                                          target="_blank"
                                        >
                                          {t('live.ClickToRegister')}
                                        </a>
                                      </p>
                                      <p id='OnlineTutorial'>
                                        Online Tutorial :{' '}
                                        <a
                                          href='/my_courses'
                                          style={{ textDecoration: 'underline' }}

                                        >
                                          Courses
                                        </a>
                                      </p>
                                    </>
                                  </ModalBody>
                                </Modal>

                                {/* {stepsPackageInfoEnabled == true && packageInfo &&
                                  localStorage.getItem('package-info-onboarding') === null && (
                                    <Steps
                                      enabled={stepsPackageInfoEnabled}
                                      steps={stepsPackageInfo}
                                      onBeforeChange={handleStepChange}
                                      initialStep={0}
                                      onExit={() => {
                                        localStorage.setItem('package-info-onboarding', true)
                                        setStepsEnabled(false)
                                      }}
                                      options={{
                                        showProgress: true,
                                        doneLabel: 'Understand',
                                        exitOnOverlayClick: false
                                      }}
                                    />
                                  )} */}
                              </>
                            )}

                          {(account.package_id === null ||
                            account.package_id === 1 ||
                            account.package_id === 2 ||
                            account.package_id === 3 ||
                            account.package_id === 10 ||
                            account.package_id === 11 ||
                            account.package_id === 12) &&
                            account?.account_type !== 2 && (
                              <Button
                                color="primary"
                                style={{ color: 'black', border: '1px solid black' }}
                                onClick={() => {
                                  upgradeModalToggle(account)
                                }}
                              >
                                {t('live.PackageUpgrade')}
                              </Button>
                            )}

                          <LiveSettings
                            settings={settings}
                            setSelectedSetting={setSelectedSetting}
                            account={account}
                          />

                          <Modal
                            id="recent-transaction-modal"
                            isOpen={account.id === accountDetails?.id && bonusModal}
                            toggle={bonusToggle}
                          >
                            <ModalHeader toggle={bonusToggle} centered={true}>
                              Redeem Bonus
                            </ModalHeader>
                            <ModalBody>
                              <>
                                <div className="text-center">
                                  {accountDetails?.redeemable_lot_size} of{' '}
                                  {accountDetails?.package?.lot_size_per_redemption}
                                </div>
                                <Progress
                                  value={accountDetails?.redeemable_lot_size}
                                  max={accountDetails?.package?.lot_size_per_redemption}
                                />
                                <br />
                                <p style={{ textAlign: 'center' }}>
                                  {accountDetails?.package?.lot_size_per_redemption} lot size redeem{' '}
                                  {accountDetails?.package?.amount_per_redemption} USD
                                </p>
                              </>
                            </ModalBody>
                          </Modal>

                          {/* Person Who fill in personal details  */}
                          {upgradeModal && (
                            <Modal
                              id="package-live-modal"
                              isOpen={account.id === accountDetails?.id && upgradeModal}
                              toggle={upgradeModalToggle}
                              centered
                              backdrop="static"
                            >
                              <ModalHeader
                                toggle={upgradeModalToggle}
                                className="modalHeader-Package"
                              ></ModalHeader>
                              <ModalBody
                                style={{
                                  padding: '1rem 1rem 0rem 1rem',

                                  backgroundColor: '#041a2c'
                                }}
                              >
                                <Row style={{ justifyContent: 'center', paddingBottom: '0px' }}>
                                  <h2 style={{ color: 'white', fontWeight: '300' }}>
                                    {t('live.SelectYourPackage')}
                                  </h2>
                                </Row>

                                <Row style={{ justifyContent: 'center', paddingBottom: '5px' }}>
                                  <h4 style={{ color: 'white', fontWeight: '300' }}>
                                    ( Chinese / English )
                                  </h4>
                                </Row>

                                <Row style={{ justifyContent: 'center', paddingBottom: '5px' }}>
                                  <h6 style={{ color: 'white', fontWeight: '300', textAlign: "center" }}>
                                    *Only one language starter pack is allowed to subscribe
                                  </h6>
                                </Row>

                                {/* <Row style={{ justifyContent: 'center', paddingBottom: '10px' }}>
                                  <Dropdown isOpen={dropdownOpen} toggle={() => { toggle() }}>
                                    <DropdownToggle id='course_language_dropdown' caret>
                                      {courseLanguageSelected}
                                    </DropdownToggle>
                                    <DropdownMenu >
                                      <DropdownItem onClick={(e) => { setCourseLanguage(e.target.innerHTML) }}>Chinese</DropdownItem>
                                      <DropdownItem onClick={(e) => { setCourseLanguage(e.target.innerHTML) }}>English</DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </Row> */}

                                <div style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                                  <div style={{ marginRight: '10px' }}>
                                    <Button
                                      color={courseLanguageSelected === 'Chinese Course' ? 'primary' : 'secondary'}
                                      onClick={(e) => { setCourseLanguage(e.target.innerHTML) }}
                                    >
                                      Chinese Course
                                    </Button>
                                  </div>

                                  <div>
                                    <Button
                                      color={courseLanguageSelected === 'English Course' ? 'primary' : 'secondary'}
                                      onClick={(e) => { setCourseLanguage(e.target.innerHTML) }}>
                                      English Course
                                    </Button>
                                  </div>
                                </div>

                                {courseLanguageSelected === 'Chinese Course' ?
                                  <>
                                    <Row style={{ justifyContent: 'space-evenly', marginTop: '25px' }}>
                                      <Col lg="2"></Col>
                                      <Col lg="4" id="starter-pack" >
                                        <Card
                                          id="cardStarterPack"
                                          body
                                          style={{
                                            marginBottom: '15px',
                                            cursor: user?.is_starter_pack_eligible
                                              ? 'pointer'
                                              : 'not-allowed',
                                            padding: '0',

                                          }}
                                          onClick={() => {
                                            upgradeStarterPackageModalToggle(account)
                                          }}
                                        >
                                          {!user?.is_starter_pack_eligible && (
                                            <div
                                              style={{
                                                backgroundColor: '#f9b600',
                                                position: 'absolute',
                                                color: 'white',
                                                padding: '0.5rem',
                                                borderRadius: '5px',
                                                left: '15px',
                                                top: '15px'
                                              }}
                                            >
                                              <h6 style={{ marginBottom: '0px' }}>{t('live.Redeemed')}</h6>
                                            </div>
                                          )}

                                          <img
                                            src={StarterPack}
                                            alt="starter background"
                                            style={{

                                              borderTopLeftRadius: '5px',
                                              borderTopRightRadius: '5px',
                                              width: "100%"
                                            }}
                                          />


                                          <hr className="package-hr" />
                                          <CardTitle
                                            tag="h3"
                                            style={{
                                              color: '#041a2c',
                                              paddingBottom: '10px',
                                              fontSize: '30px',
                                              textAlign: 'center'
                                            }}
                                          >
                                            {t('live.StarterPackage')}
                                          </CardTitle>
                                          <CardText style={{ textAlign: 'center', fontSize: '15px' }}>
                                            {t('live.ClaimYourFirstUpTo50Bonus')}
                                            <br />
                                            <div style={{ display: 'flex' }}>
                                              <div style={{ margin: '10px auto 0' }}>
                                                {/* <div style={{ display: 'flex' }}>
                                                  <div style={{ marginRight: '10px' }}>
                                                    <img
                                                      src={GreenChecked}
                                                      style={{ width: '16px' }}
                                                      alt="greenChecked"
                                                    />
                                                  </div>
                                                  <p style={{ marginBottom: '10px', fontSize: '15px' }}>
                                                    {t('live.CreditBonusUp')}
                                                  </p>
                                                </div> */}

                                                <div style={{ display: 'flex' }}>
                                                  <div style={{ marginRight: '10px' }}>
                                                    <img
                                                      src={GreenChecked}
                                                      style={{ width: '16px' }}
                                                      alt="greenChecked"
                                                    />
                                                  </div>
                                                  <p style={{ marginBottom: '10px', fontSize: '15px' }}>
                                                    {t('live.BeginnerGuideToForex')}
                                                  </p>
                                                </div>

                                                <div style={{ display: 'flex' }}>
                                                  <div style={{ marginRight: '10px' }}>
                                                    <img
                                                      src={GreenChecked}
                                                      style={{ width: '16px' }}
                                                      alt="greenChecked"
                                                    />
                                                  </div>
                                                  <p style={{ marginBottom: '10px', fontSize: '15px' }}>
                                                    {t('live.MVPCourse')}
                                                  </p>
                                                </div>

                                                {/* <div style={{ display: 'flex' }}>
                                              <div style={{ marginRight: '10px' }}>
                                                <img
                                                  src={GreenChecked}
                                                  style={{ width: '16px' }}
                                                  alt="greenChecked"
                                                />
                                              </div>
                                              <p style={{ marginBottom: '10px', fontSize: '15px' }}>
                                                {t('live.MVPCourse')}
                                              </p>
                                            </div> */}
                                              </div>
                                            </div>
                                            {/* <div style={{ marginTop: '35px' }}>
                                              <a
                                                href={sessionStorage.getItem('language') === 'en' ? 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Starter+Pack+EN-06.svg' : 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Starter+Pack+CN-06.svg'}
                                                style={{ textDecoration: 'underline' }}
                                                target="_blank"
                                              >
                                                {t('live.MoreDetails')}
                                              </a>
                                            </div> */}
                                          </CardText>
                                        </Card>
                                      </Col>

                                      <Col lg="4" id="premium-pack" >
                                        <Card
                                          id="cardPremiumPack"
                                          body
                                          style={{
                                            marginBottom: '15px',
                                            cursor: 'pointer',
                                            padding: '0'
                                          }}
                                          onClick={() => {
                                            upgradeVePremiumModalToggle(account)
                                          }}
                                        >

                                          <img
                                            src={DiamondBackground}
                                            alt="premium background"
                                            style={{

                                              borderTopLeftRadius: '5px',
                                              borderTopRightRadius: '5px',
                                              width: '100%'
                                            }}
                                          />


                                          <hr className="package-hr" />
                                          <CardTitle
                                            tag="h4"
                                            style={{
                                              color: '#041a2c',
                                              paddingBottom: '10px',
                                              fontSize: '30px',
                                              textAlign: 'center'
                                            }}
                                          >
                                            {t('live.PremiumPackage')}
                                          </CardTitle>
                                          <CardText style={{ textAlign: 'center', fontSize: '15px' }}>
                                            {t('live.RoadToMasterInTrading')}
                                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                              <div style={{ margin: '10px auto 0 ' }}>
                                                {premiumBenefits?.map((benefit, index) => {
                                                  return (
                                                    <div style={{ display: 'flex' }} key={index}>
                                                      <div style={{ marginRight: '10px' }}>
                                                        <img
                                                          src={GreenChecked}
                                                          style={{ width: '16px' }}
                                                          alt="greenChecked"
                                                        />
                                                      </div>
                                                      <p
                                                        style={{
                                                          marginBottom: '10px',
                                                          fontSize: '15px'
                                                        }}
                                                      >
                                                        {benefit.name}
                                                      </p>
                                                    </div>
                                                  )
                                                })}
                                              </div>
                                            </div>

                                            <a
                                              href={sessionStorage.getItem('language') === 'en' ? 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/CRM+package-01.jpg' : 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/CRM+package-01.jpg'}
                                              style={{
                                                textDecoration: 'underline',
                                                fontSize: '15px'
                                              }}
                                              target='_blank'
                                            >
                                              {t('live.MoreDetails')}
                                            </a>

                                          </CardText>
                                        </Card>
                                      </Col>
                                      <Col lg="2"></Col>
                                    </Row>

                                    <Row
                                      style={{
                                        justifyContent: 'center',
                                        marginTop: '20px',
                                        marginBottom: '25px',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <p style={{ marginBottom: '10px', color: 'white' }}>
                                        {t('live.ScrollFor')}
                                      </p>
                                      <img
                                        src={ScrollDownIcon}
                                        alt="scroll down icon"
                                        style={{ width: '30px' }}
                                      />
                                    </Row>

                                    <Row
                                      style={{
                                        justifyContent: 'center',
                                        backgroundColor: '#F5F7FA',
                                        padding: '2rem 0rem'
                                      }}
                                    >
                                      <Col lg="8" id="faq">
                                        <h5 style={{ textAlign: 'center', marginBottom: '25px' }}>
                                          {t('live.FrequentlyAskedQuestions')}
                                        </h5>

                                        <Accordion allowZeroExpanded allowMultipleExpanded>
                                          {faqItems.map((item, index) => (
                                            <AccordionItem key={index}>
                                              <AccordionItemHeading>
                                                <AccordionItemButton
                                                  style={{ backgroundColor: '#FFF' }}
                                                >
                                                  {item.heading}
                                                </AccordionItemButton>
                                              </AccordionItemHeading>
                                              <AccordionItemPanel>{item.content}</AccordionItemPanel>
                                            </AccordionItem>
                                          ))}
                                        </Accordion>
                                      </Col>
                                    </Row>
                                  </>
                                  :
                                  <>
                                    <Row style={{ justifyContent: 'space-evenly', marginTop: '25px' }}>
                                      <Col lg="2"></Col>
                                      <Col lg="4" id="starter-pack" >
                                        <Card
                                          id="cardStarterEnglishPack"
                                          body
                                          style={{
                                            marginBottom: '15px',
                                            cursor: user?.is_starter_pack_eligible
                                              ? 'pointer'
                                              : 'not-allowed',
                                            padding: '0',

                                          }}
                                          onClick={() => {
                                            upgradePartibanStarterModalToggle(account)
                                          }}
                                        >
                                          {!user?.is_starter_pack_eligible && (
                                            <div
                                              style={{
                                                backgroundColor: '#f9b600',
                                                position: 'absolute',
                                                color: 'white',
                                                padding: '0.5rem',
                                                borderRadius: '5px',
                                                left: '15px',
                                                top: '15px'
                                              }}
                                            >
                                              <h6 style={{ marginBottom: '0px' }}>{t('live.Redeemed')}</h6>
                                            </div>
                                          )}

                                          <img
                                            src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/partiban/Poster_Starter+Pack+500.jpg'
                                            alt="starter background"
                                            style={{

                                              borderTopLeftRadius: '5px',
                                              borderTopRightRadius: '5px',
                                              width: "100%"
                                            }}
                                          />


                                          <hr className="package-hr" />
                                          <CardTitle
                                            tag="h3"
                                            style={{
                                              color: '#041a2c',
                                              paddingBottom: '10px',
                                              fontSize: '30px',
                                              textAlign: 'center'
                                            }}
                                          >
                                            First Step Package
                                          </CardTitle>
                                          <CardText style={{ textAlign: 'center', fontSize: '15px' }}>
                                            {t('live.ClaimYourFirstUpTo50Bonus')}
                                            <br />
                                            <div style={{ display: 'flex' }}>
                                              <div style={{ margin: '10px auto 0' }}>
                                                {/* <div style={{ display: 'flex' }}>
                                                  <div style={{ marginRight: '10px' }}>
                                                    <img
                                                      src={GreenChecked}
                                                      style={{ width: '16px' }}
                                                      alt="greenChecked"
                                                    />
                                                  </div>
                                                  <p style={{ marginBottom: '10px', fontSize: '15px' }}>
                                                    {t('live.CreditBonusUp')}
                                                  </p>
                                                </div> */}

                                                <div style={{ display: 'flex' }}>
                                                  <div style={{ marginRight: '10px' }}>
                                                    <img
                                                      src={GreenChecked}
                                                      style={{ width: '16px' }}
                                                      alt="greenChecked"
                                                    />
                                                  </div>
                                                  <p style={{ marginBottom: '10px', fontSize: '15px' }}>
                                                    Introduction to Forex
                                                  </p>
                                                </div>

                                                <div style={{ display: 'flex' }}>
                                                  <div style={{ marginRight: '10px' }}>
                                                    <img
                                                      src={GreenChecked}
                                                      style={{ width: '16px' }}
                                                      alt="greenChecked"
                                                    />
                                                  </div>
                                                  <p style={{ marginBottom: '10px', fontSize: '15px' }}>
                                                    Bootscamp session
                                                  </p>
                                                </div>

                                                {/* <div style={{ display: 'flex' }}>
                                              <div style={{ marginRight: '10px' }}>
                                                <img
                                                  src={GreenChecked}
                                                  style={{ width: '16px' }}
                                                  alt="greenChecked"
                                                />
                                              </div>
                                              <p style={{ marginBottom: '10px', fontSize: '15px' }}>
                                                {t('live.MVPCourse')}
                                              </p>
                                            </div> */}
                                              </div>
                                            </div>
                                            {/* <div style={{ marginTop: '35px' }}>
                                              <a
                                                href={sessionStorage.getItem('language') === 'en' ? 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/partiban/Starter+Pack_PAG-05.png' : 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/partiban/Starter+Pack_PAG-05.png'}
                                                style={{ textDecoration: 'underline' }}
                                                target="_blank"
                                              >
                                                {t('live.MoreDetails')}
                                              </a>
                                            </div> */}
                                          </CardText>
                                        </Card>
                                      </Col>

                                      <Col lg="4" id="premium-pack" >
                                        <Card
                                          id="cardEnglishPremiumPack"
                                          body
                                          style={{
                                            marginBottom: '15px',
                                            cursor: 'pointer',
                                            padding: '0'
                                          }}
                                          onClick={() => {
                                            upgradePartibanPremiumModalToggle(account)
                                          }}
                                        >

                                          <img
                                            src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/partiban/Poster_Premium+Pack+10000.jpg'
                                            alt="premium background"
                                            style={{

                                              borderTopLeftRadius: '5px',
                                              borderTopRightRadius: '5px',
                                              width: '100%'
                                            }}
                                          />


                                          <hr className="package-hr" />
                                          <CardTitle
                                            tag="h4"
                                            style={{
                                              color: '#041a2c',
                                              paddingBottom: '10px',
                                              fontSize: '30px',
                                              textAlign: 'center'
                                            }}
                                          >
                                            {t('live.PremiumPackage')}
                                          </CardTitle>
                                          <CardText style={{ textAlign: 'center', fontSize: '15px' }}>
                                            {t('live.RoadToMasterInTrading')}
                                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                              <div style={{ margin: '10px auto 0 ' }}>
                                                {premiumEnglishBenefits?.map((benefit, index) => {
                                                  return (
                                                    <div style={{ display: 'flex' }} key={index}>
                                                      <div style={{ marginRight: '10px' }}>
                                                        <img
                                                          src={GreenChecked}
                                                          style={{ width: '16px' }}
                                                          alt="greenChecked"
                                                        />
                                                      </div>
                                                      <p
                                                        style={{
                                                          marginBottom: '10px',
                                                          fontSize: '15px'
                                                        }}
                                                      >
                                                        {benefit.name}
                                                      </p>
                                                    </div>
                                                  )
                                                })}
                                              </div>
                                            </div>
                                            <div className='mt-2'>
                                              <a
                                                href={sessionStorage.getItem('language') === 'en' ? 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/partiban/Premium+Pack+Comparison-01.jpg' : 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/partiban/Premium+Pack+Comparison-01.jpg'}
                                                style={{
                                                  textDecoration: 'underline',
                                                  fontSize: '15px'
                                                }}
                                                target='_blank'
                                              >
                                                {t('live.MoreDetails')}
                                              </a>
                                            </div>
                                          </CardText>
                                        </Card>
                                      </Col>
                                      <Col lg="2"></Col>
                                    </Row>

                                    <Row
                                      style={{
                                        justifyContent: 'center',
                                        marginTop: '20px',
                                        marginBottom: '25px',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <p style={{ marginBottom: '10px', color: 'white' }}>
                                        {t('live.ScrollFor')}
                                      </p>
                                      <img
                                        src={ScrollDownIcon}
                                        alt="scroll down icon"
                                        style={{ width: '30px' }}
                                      />
                                    </Row>

                                    <Row
                                      style={{
                                        justifyContent: 'center',
                                        backgroundColor: '#F5F7FA',
                                        padding: '2rem 0rem'
                                      }}
                                    >
                                      <Col lg="8" id="faq">
                                        <h5 style={{ textAlign: 'center', marginBottom: '25px' }}>
                                          {t('live.FrequentlyAskedQuestions')}
                                        </h5>

                                        <Accordion allowZeroExpanded allowMultipleExpanded>
                                          {premiumFaqItems.map((item, index) => (
                                            <AccordionItem key={index}>
                                              <AccordionItemHeading>
                                                <AccordionItemButton
                                                  style={{ backgroundColor: '#FFF' }}
                                                >
                                                  {item.heading}
                                                </AccordionItemButton>
                                              </AccordionItemHeading>
                                              <AccordionItemPanel>{item.content}</AccordionItemPanel>
                                            </AccordionItem>
                                          ))}
                                        </Accordion>
                                      </Col>
                                    </Row>
                                  </>

                                }

                              </ModalBody>
                              {stepsEnabled == true &&
                                localStorage.getItem('package-upgrade-onboarding') === null && (
                                  <Steps
                                    enabled={stepsEnabled}
                                    steps={steps}
                                    onBeforeChange={handleStepChange}
                                    initialStep={0}
                                    onExit={() => {
                                      localStorage.setItem('package-upgrade-onboarding', true)
                                      setStepsEnabled(false)
                                    }}
                                    options={{
                                      showProgress: true,
                                      doneLabel: 'Understand',
                                      exitOnOverlayClick: false
                                    }}
                                  />
                                )}
                            </Modal>
                          )}

                          <Modal
                            isOpen={account.id === accountDetails?.id && notAbleRedeemModal}
                            toggle={notAbleRedeemModalToggle}
                            centered
                          >
                            <ModalHeader toggle={notAbleRedeemModalToggle}>
                              {t('live.YouAlreadyRedeemed')}
                            </ModalHeader>
                            {/* <ModalBody style={{padding:'1rem 1rem 0rem 1rem'}}>
                           
                            
                          </ModalBody> */}
                          </Modal>

                          {/* Starter package modal */}
                          {starterPackageUpgradeModal && (
                            <StarterPackageModal
                              account={account}
                              accountDetails={accountDetails}
                              starterPackageUpgradeModal={starterPackageUpgradeModal}
                              setStaterPackageUpgradeModal={setStaterPackageUpgradeModal}
                              upgradeStarterPackageModalToggle={(account) => {
                                upgradeStarterPackageModalToggle(account)
                              }}
                            />
                          )}

                          {/* VE premium package modal */}
                          {vePremiumUpgradeModal && (
                            <VePackageModal
                              account={account}
                              accountDetails={accountDetails}
                              vePremiumUpgradeModal={vePremiumUpgradeModal}
                              setVePremiumUpgradeModal={setVePremiumUpgradeModal}
                              upgradeVePremiumModalToggle={(account) => {
                                upgradeVePremiumModalToggle(account)
                              }}
                            />
                          )}

                          {/* Partiban premium package modal */}
                          {partibanStarterUpgradeModal && (
                            <PartibanStarterPackageModal
                              account={account}
                              accountDetails={accountDetails}
                              partibanStarterUpgradeModal={partibanStarterUpgradeModal}
                              setPartibanStarterUpgradeModal={setPartibanStarterUpgradeModal}
                              upgradePartibanStarterModalToggle={(account) => {
                                upgradePartibanStarterModalToggle(account)
                              }}
                            />
                          )}

                          {/* Partiban premium package modal */}
                          {partibanPremiumUpgradeModal && (
                            <PartibanPremiumPackageModal
                              account={account}
                              accountDetails={accountDetails}
                              partibanPremiumUpgradeModal={partibanPremiumUpgradeModal}
                              setPartibanPremiumUpgradeModal={setPartibanPremiumUpgradeModal}
                              upgradePartibanPremiumModalToggle={(account) => {
                                upgradePartibanPremiumModalToggle(account)
                              }}
                            />
                          )}
                        </ResponsiveLiveDiv>
                      </div>
                      <CardBody
                        className="text-left"
                        style={{ paddingTop: '10px', paddingBottom: '20px' }}
                      >
                        <Row style={{ marginBottom: '15px' }}>
                          <Col>
                            <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                              MT5 LOGIN ID
                            </ResponsiveParagraph>
                            <Responsiveh6>{`#${account.account_login} `}</Responsiveh6>
                            {/* <h6>{account.plan?.name ?? '-'}</h6> */}
                          </Col>

                          <Col>
                            <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                              {t('live.accountType')}
                            </ResponsiveParagraph>
                            <Responsiveh6>{account.plan?.name}</Responsiveh6>
                            {/* <h6>{account.plan?.name ?? '-'}</h6> */}
                          </Col>

                          <Col>
                            <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                              {t('live.leverage')}
                            </ResponsiveParagraph>
                            <Responsiveh6>
                              {account?.leverage === null ? '-' : `1:${account?.leverage}` ?? '-'}
                            </Responsiveh6>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                              {t('live.balance')}
                            </ResponsiveParagraph>
                            <Responsiveh6>{`${account?.balance === null
                              ? '-'
                              : formatter?.format(account?.balance) ?? '-'
                              } `}</Responsiveh6>
                          </Col>
                          <Col>
                            <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                              {t('live.credit')}
                            </ResponsiveParagraph>
                            <Responsiveh6>{`${formatter?.format(account?.credit) ?? '-'
                              } `}</Responsiveh6>
                          </Col>

                          <Col>
                            <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                              {t('live.Class')}
                            </ResponsiveParagraph>

                            {/* <h6 style={{
                              color: account.package_id == 1 || account.package_id == 2 || account.package_id == 3 ? "#E782A9"
                                :
                                account.package_id == 4 || account.package_id == 5 || account.package_id == 6 ? '#39207C'
                                  :
                                  account.package_id == 7 ? '#48D597'
                                    :
                                    account.package_id == 8 ? '#F0EC74'
                                      :
                                      account.package_id == 9 ? '#00A300'
                                        :
                                        "#B1B3B3",

                            }}>{account?.package?.name ? account?.package?.name : '-'}</h6> */}
                            <Responsiveh6>
                              <Badge
                                style={{
                                  backgroundColor:
                                    account?.account_type === 2 ? '#108EE9' :
                                      account.package_id == 1 ||
                                        account.package_id == 2 ||
                                        account.package_id == 3 ||
                                        account.package_id == 10 ||
                                        account.package_id == 11 ||
                                        account.package_id == 12
                                        ? '#E782A9'
                                        : account.package_id == 4 ||
                                          account.package_id == 5 ||
                                          account.package_id == 6 ||
                                          account.package_id == 13 ||
                                          account.package_id == 14 ||
                                          account.package_id == 15 ||
                                          account.package_id == 19
                                          ? '#39207C'
                                          : account.package_id == 7
                                            ? '#48D597'
                                            : account.package_id == 8
                                              ? '#F0EC74'
                                              : account.package_id == 9
                                                ? '#00A300' :
                                                account.package_id == 16 ||
                                                  account.package_id == 17 ?
                                                  '#E609D3'
                                                  : account.package_id == 18 ? '#bd4040':
                                                   account.package_id == 21 ? '#514496'
                                                   :'#B1B3B3'
                                }}
                              >
                                {
                                  account?.account_type === 2 ? 'IB Account' :
                                    account?.package?.name ? account?.package?.name : 'Normal Account'}
                              </Badge>
                            </Responsiveh6>
                          </Col>
                        </Row>
                      </CardBody>
                      {account?.hasOwnProperty('latestPackageApplication') &&
                        account?.latestPackageApplication?.status == 0 &&
                        (account.package_id === null ||
                          account.package_id === 1 ||
                          account.package_id === 2 ||
                          account.package_id === 3) && (
                          <CardFooter
                            style={{
                              padding: '20px 40px',
                              color: 'black',
                              backgroundColor: '#f9b600'
                            }}
                          >
                            <p style={{ marginBottom: '0px' }}>
                              {t('live.ApplicationSubmittedOn')} :{' '}
                              {moment(account?.latestPackageApplication?.created_at).format(
                                'yyyy-MM-DD HH:mm:ss'
                              )}
                            </p>
                            {t('live.UpgradeStatusProcess')}
                            <br />( {t('live.NeedToWait')} )
                          </CardFooter>
                        )}

                      {account?.hasOwnProperty('latestPackageApplication') &&
                        account?.latestPackageApplication?.status == 2 &&
                        (account.package_id === null ||
                          account.package_id === 1 ||
                          account.package_id === 2 ||
                          account.package_id === 3) && (
                          <CardFooter
                            style={{
                              padding: '20px 40px',
                              color: 'black',
                              backgroundColor: '#ffc4c4'
                            }}
                          >
                            <p style={{ marginBottom: '0px' }}>
                              {t('live.ApplicationSubmittedOn')} :{' '}
                              {moment(account?.latestPackageApplication?.created_at).format(
                                'yyyy-MM-DD HH:mm:ss'
                              )}
                            </p>
                            <p style={{ marginBottom: '0px' }}>{t('live.PremiumStatusRejected')}</p>
                            <p style={{ marginBottom: '0px' }}>
                              {t('live.Reason')} : {account?.latestPackageApplication?.reason}
                            </p>
                          </CardFooter>
                        )}
                    </Card>
                  </Col>
                )
              })
            ) : (
                <Col>
                  <h6 style={{ textAlign: 'center' }}>{t('live.noActiveAccount')}</h6>
                </Col>
              )}
          </Row>
        </>
      ) : (
          <div style={{ textAlign: 'center' }}>
            <Spinner color="warning" />
          </div>
        )}

      {selectedSetting !== null && selectedAccount !== null && (
        <Modal isOpen={showModal} backdrop={true} centered={true}>
          <ModalHeader toggle={toggleModal}>{settings[selectedSetting].title}</ModalHeader>
          <ModalBody>{settings[selectedSetting].cta}</ModalBody>
        </Modal>
      )}
    </>
  )
}

export default ActiveLiveAccounts
