import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  Spinner,
  CardBody
} from 'reactstrap'

import TransactionService from '../../../network/services/transaction'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-feather'
import styled from 'styled-components'

import moment from 'moment'

import './transaction.scss'

import ReceptionIcon from './invoice.svg'

const DashboardTransaction = () => {
  const { t } = useTranslation()
  const [transactions, setTransactions] = useState(null)

  const [transactionDetails, setTransactionDetails] = useState(null)

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const init = async () => {
    const transactionResponse = await TransactionService.getAll()
    // setTransactions([])
    setTransactions(transactionResponse.transactions.data)
  }

  //   const navigate = (path) => {
  //     history.push(path)
  //     dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  //   }

  useEffect(() => {
    init()
  }, [])

  const [modal, setModal] = useState(false)

  const toggle = (transaction) => {
    setTransactionDetails(transaction)
    setModal(!modal)
  }

  return (
    <div
      style={{
        height: '500px',
        overflow: 'auto',
        marginTop: '20px'
      }}
      id="transactionsDiv"
    >
      {transactions != null ? (
        transactions?.length > 0 ? (
          transactions?.map((transaction) => (
            <Card
              key={transaction?.id}
              className="recentTransaction darkCardTransaction"
              onClick={() => toggle(transaction)}
              style={{
                marginBottom: '0px',
                padding: '10px 0px',
                cursor: 'pointer',

                borderRadius: '0px'
              }}
            >
              <Row>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={ReceptionIcon}
                    alt="recepit icon"
                    style={{ width: '20px', marginRight: '5px' }}
                  />
                  <p style={{ fontWeight: 'normal', marginBottom: '0' }}>
                    {t('dashboard.ticket')}: {transaction?.id}
                  </p>
                </Col>
                <Col className="text-right">
                  {transaction?.status === 1 && (
                    <i className="fa fa-check-circle" style={{ color: 'green', fontSize: 25 }}></i>
                  )}

                  {transaction?.status === 0 && (
                    <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 25 }}></i>
                  )}

                  {transaction?.status === 4 && transaction?.txn_type === 1 && (
                    <i className="fa fa-clock-o" style={{ color: '#f9b600', fontSize: 25 }}></i>
                  )}

                  {(transaction?.status === 2 || transaction?.status === 3) && (
                    <i className="fa fa-times-circle" style={{ color: 'red', fontSize: 25 }}></i>
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <h5 style={{ fontWeight: 'normal' }}>
                    {transaction?.txn_type === 0 && t('dashboard.depositToWallet')}
                    {transaction?.txn_type === 1 && t('dashboard.withDrawFromWallet')}
                    {transaction?.txn_type === 2 && t('dashboard.transferOfWallet')}
                    {transaction?.txn_type === 3 && t('dashboard.referralProgramReward')}

                    {transaction?.txn_type === 10 && t('dashboard.depositToMT5')}
                    {transaction?.txn_type === 11 && t('dashboard.withdrawFromMT5')}
                    {transaction?.txn_type === 20 && t('dashboard.walletAdjustment')}
                  </h5>
                </Col>
                <Col className="text-right">
                  <h5 style={{ fontWeight: 'normal' }}>
                    {transaction?.debit_amount > 0
                      ? `+${currencyFormatter.format(transaction?.debit_amount)}`
                      : currencyFormatter.format(-transaction?.credit_amount)}
                  </h5>
                </Col>
              </Row>

              <Row>
                <Col>
                  <p style={{ marginBottom: '0px', color: '#888888' }}>
                    {moment(transaction?.updated_at).format('YYYY-MM-DD HH:mm')}
                  </p>
                </Col>
                <Col className="text-right">
                  <p style={{ marginBottom: '0px', color: '#888888' }}>USD</p>
                </Col>
              </Row>

              <Modal
                id="recent-transaction-modal"
                isOpen={transaction.id === transactionDetails?.id && modal}
                toggle={toggle}
              >
                <ModalHeader toggle={toggle}>
                  {t('dashboard.ticket')}: {transaction?.id}
                </ModalHeader>
                <ModalBody>
                  {/* <p>
                    {t('dashboard.ticket')}: {transaction?.id}
                  </p> */}
                  <p>
                    {t('dashboard.date')}:{' '}
                    {moment(transaction?.updated_at).format('YYYY-MM-DD HH:mm')}
                  </p>
                  {transaction?.txn_type !== 3 && (
                    <>
                      {' '}
                      <p>
                        {t('dashboard.action')}:{' '}
                        {transaction?.txn_type === 0 && t('dashboard.depositToWallet')}
                        {transaction?.txn_type === 1 && t('dashboard.withDrawFromWallet')}
                        {transaction?.txn_type === 2 && t('dashboard.transferOfWallet')}
                        {transaction?.txn_type === 10 && t('dashboard.depositToMT5')}
                        {transaction?.txn_type === 11 && t('dashboard.withdrawFromMT5')}
                        {transaction?.txn_type === 20 && t('dashboard.walletAdjustment')}
                      </p>
                      <p>
                        {t('dashboard.method')}:{' '}
                        {transaction?.txn_type === 0 && transaction?.deposit?.payment_method}
                        {transaction?.txn_type === 1 && transaction?.withdraw?.payment_method}
                        {transaction?.txn_type === 2 && t('dashboard.transferOfWallet')}
                        {transaction?.txn_type === 10 && t('dashboard.depositToMT5')}
                        {transaction?.txn_type === 11 && t('dashboard.withdrawFromMT5')}
                        {transaction?.txn_type === 20 && t('dashboard.walletAdjustment')}
                      </p>{' '}
                    </>
                  )}

                  {transaction?.txn_type === 3 && (
                    <>
                      <p>
                        {t('dashboard.description')}: {t('dashboard.referralProgramReward')}
                      </p>
                      <p>
                        {' '}
                        {t('dashboard.referredUser')}:{' '}
                        {transaction?.client_referral_reward?.clientReferral?.child_user?.email}{' '}
                      </p>
                    </>
                  )}

                  <p>
                    {t('dashboard.amount')}:{' '}
                    {transaction?.debit_amount > 0
                      ? transaction?.debit_amount.toFixed(2)
                      : transaction?.credit_amount.toFixed(2)}
                    USD
                  </p>
                  <p>
                    {t('dashboard.status')}:{' '}
                    {transaction?.status === 0 && (
                      <>
                        <span style={{ color: '#f9b600' }}>{t('dashboard.pending')}</span>
                      </>
                    )}
                    {transaction?.status === 1 && (
                      <>
                        <span style={{ color: 'green' }}>{t('dashboard.success')}</span>
                      </>
                    )}
                    {transaction?.status === 2 && (
                      <>
                        <span style={{ color: 'red' }}>{t('dashboard.failed')}</span>
                      </>
                    )}
                    {transaction?.status === 3 && (
                      <>
                        <span style={{ color: 'red' }}>{t('dashboard.rejected')}</span>
                      </>
                    )}
                    {transaction?.status === 4 && transaction?.txn_type && (
                      <>
                        <span style={{ color: '#f9b600' }}>{t('dashboard.pending')}</span>
                      </>
                    )}
                  </p>

                  {/* {transaction?.status == 2 && (
                    <p>
                      {t('dashboard.reason')}: {t('dashboard.BankPaymentFailed')}
                    </p>
                  )} */}

                  {(transaction?.status == 2 || transaction?.status == 3) && (
                    <p>
                      {t('dashboard.reason')}:{' '}
                      {transaction?.txn_type === 0 &&
                        (transaction?.deposit?.reason ?? t('dashboard.BankPaymentFailed'))}
                      {transaction?.txn_type === 1 && transaction?.withdraw?.reason}
                      {transaction?.txn_type === 2 && transaction?.transfer?.reason}
                      {transaction?.txn_type === 10 && transaction?.account_deposit?.reason}
                      {transaction?.txn_type === 11 && transaction?.account_withdraw?.reason}
                    </p>
                  )}
                </ModalBody>
              </Modal>
            </Card>
          ))
        ) : (
          <Card
            style={{
              height: '100px',
              overflow: 'hidden',
              borderRadius: '0px',
              textAlign: 'center'
            }}
          >
            <p style={{ fontSize: '20px', margin: 'auto 0' }}>
              {t('dashboard.noRecentTransactions')}
            </p>
          </Card>
        )
      ) : (
        <Col>
          <Card>
            <CardBody style={{ width: '100%', height: '100px', textAlign: 'center' }}>
              <Spinner />
            </CardBody>
          </Card>
        </Col>
      )}
    </div>
  )
}

export default DashboardTransaction
