import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import StepZilla from 'react-stepzilla'
import { Container, Card, CardBody, Col, Row, Button } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import WalletService from '../../network/services/wallet'

import Breadcrumb from '../../layout/breadcrumb'
import LiveForm from './form'
import LiveAccountList from './list'

import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const LiveAccountRegistration = () => {
  const { t } = useTranslation()
  const [stage, setStage] = useState(0)
  const [walletResult, setWalletResult] = useState(null)
  const [totalDepositAndTransfer, setTotalDepositAndTransfer] = useState(0)
  const account = useSelector((state) => state.live.account)
  let history = useHistory()
  const dispatch = useDispatch()
  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  const init = async () => {
    const walletResponse = await WalletService.get()
    dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })
    setWalletResult(walletResponse)
    const totalDepositAndTransfer =
      walletResponse?.total_deposit + walletResponse?.total_transfer_to_me
    setTotalDepositAndTransfer(totalDepositAndTransfer)
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Breadcrumb
        parent={
          <Link
            to="#"
            onClick={() => {
              navigate(`${process.env.PUBLIC_URL}/live`)
            }}
          >
            {t('live.liveAccounts')}
          </Link>
        }
        title="Register"
      />
      <Container>
        <Card>
          <CardBody>
            <h5>{t('live.liveTraderAccountRegistration')}</h5>
            <div className="p-2" />
            <Row>
              <Col
                sm={12}
                md={6}
                className={`u-pearl ${stage === 0 && 'current'} ${stage > 0 && 'done'}`}
              >
                <span className="u-pearl-number">1</span>
                <span className="u-pearl-title">{t('live.selectAccountType')}</span>
              </Col>
              <Col
                sm={12}
                md={6}
                className={`u-pearl ${stage === 1 && 'current'} ${stage > 1 && 'done'}`}
              >
                <span className="u-pearl-number">2</span>
                <span className="u-pearl-title">{t('live.createAccount')}</span>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {walletResult ? (
          <Card style={{ height: '100px', width: '100%', display: 'block', marginBottom: '30px' }}>
            <div style={{ width: '50%', float: 'left', textAlign: 'center' }}>
              <div id="liveTotalDeposit" style={{ fontSize: '20px', marginTop: '20px' }}>
                {t('live.totalDeposit')}:{' '}
                <span style={{ fontWeight: '500' }}>{totalDepositAndTransfer.toFixed(2)}</span>
              </div>
              <div id="liveWalletBalance" style={{ fontSize: '20px' }}>
                {t('live.walletBalance')}:{' '}
                <span style={{ fontWeight: '500' }}>{walletResult?.wallet_balance.toFixed(2)}</span>
              </div>
            </div>
            <div style={{ width: '50%', float: 'right', textAlign: 'center' }}>
              <Button
                id="liveDepositButton"
                color="primary"
                style={{ width: '200px', marginTop: '30px' }}
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/financial#deposit`)
                }}
              >
                <span id="liveDepositButtonText">{t('live.depositNow')}</span>
              </Button>
            </div>
          </Card>
        ) : (
          ''
        )}
        <StepZilla
          steps={[
            {
              name: t('live.Step1'),
              component: <LiveAccountList />
            },
            {
              name: t('live.Step2'),
              component: <LiveForm />
            }
          ]}
          showNavigation={
            stage === 0 ? (totalDepositAndTransfer >= account.min_deposit ? true : false) : true
          }
          showSteps={false}
          onStepChange={(index) => {
            setStage(index)
          }}
          backButtonText={t('financial.Previous')}
          nextButtonText={t('financial.Next')}
        />
        <div className="p-5" />
      </Container>
    </>
  )
}

export default LiveAccountRegistration
