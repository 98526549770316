import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import Breadcrumb from '../../../layout/breadcrumb'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import CampusRegisterForm from './registrationForm/index'

import _ from 'lodash'
import { Button, CardBody, Row, Col, Card, CardHeader, Modal, ModalHeader, ModalBody, Spinner, Container } from 'reactstrap'

import styled from 'styled-components'

import { ResponsiveDiv, StyledRow } from '../../common/components.style'

import DemoSettings from '../../common/settings'
import MasterPassword from '../../common/settings/master_password'
import InvestorPassword from '../../common/settings/investor_password'

import AccountService from '../../../network/services/account'
import EventService from '../../../network/services/event'

import LeaderboardTable from './leaderboard'

import moment from 'moment'


const Event = () => {

    const history = useHistory()
    const demoAccounts = useSelector(state => state.account?.accounts?.filter(item => item.account_type === 1))

    const { t } = useTranslation()
    const selectedAccount = useSelector((state) => state.dashboard.account)

    const verification = useSelector((state) => _.last(state.user.verifications))
    const campusCompetition = useSelector(state => state.event?.competitions?.competitions?.filter(item => item.name === 'Tradehall Campus CFD Trading Contest Malaysia 2021'))

    const [isEligible, setIsEligible] = useState(false)
    const [myRegistrationRecord, setMyRegistrationRecord] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [selectedSetting, setSelectedSetting] = useState(null)
    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const user = useSelector((state) => state.user)

    const [myCompetitionResult, setMyCompetitionResult] = useState({})

    const fetchCompetitionResponse = async () => {
        // Hardcode competition id
        setIsBusy(true)

        const myCompetitionResponse = await EventService.myCompetitionResult({
            competition_id: 4
        })

        setMyCompetitionResult(myCompetitionResponse)
        setIsBusy(false)


    }

    const [isBusy, setIsBusy] = useState(false)

    const fetchMyRegistrationRecord = async () => {
        // Hardcode competition id

        const record = await EventService.getMyCompetition({
            competition_id: 4
        })
        setMyRegistrationRecord(record?.submitted_registrations)

        setIsEligible(record?.is_eligible)


    }


    useEffect(() => {
        // Hardcode competition id
        fetchMyRegistrationRecord()
        fetchCompetitionResponse()

    }, [])

    const dispatch = useDispatch()

    const [registedAccount, setRegistedAccount] = useState([])




    const toOrdinalSuffix = (num) => {
        const int = parseInt(num),
            digits = [int % 10, int % 100],
            ordinals = ['st', 'nd', 'rd', 'th'],
            oPattern = [1, 2, 3, 4],
            tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
        return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
            ? int + ordinals[digits[0] - 1]
            : int + ordinals[3]
    }

    const settings = [
        {
            id: 1,
            title: t("dashboard.titleChangeMaster"),
            cta: <MasterPassword setShowModal={setShowModal} />
        },
        {
            id: 2,
            title: t("dashboard.titleChangeInvestor"),
            cta: <InvestorPassword setShowModal={setShowModal} />
        }
    ]

    const init = async () => {
        const accountResponse = await AccountService.getDemoAccount()
        dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (selectedSetting != null && selectedAccount != null) {
            setShowModal(true)
        }
    }, [selectedSetting, selectedAccount])

    useEffect(() => {
        if (showModal === false) {
            // fetch account
            if (selectedAccount != null) {
                init()
            }

            setSelectedSetting(null)
            dispatch({
                type: 'SET_DASHBOARD_ACCOUNT',
                account: null
            })
        }
        // eslint-disable-next-line
    }, [showModal])

    const participantIsEligible = () => {

        if (
            myRegistrationRecord?.length === 0
        
            || (user.user.is_competition_eligible && myRegistrationRecord?.length === 0)
            
            || isEligible  && myRegistrationRecord?.length === 0
        ) {

            return <CampusRegisterForm />

        } else if (myRegistrationRecord?.length > 0) {

            const accounts = demoAccounts?.filter(account => {
                return parseInt(account?.account_login) === myRegistrationRecord[0]?.account_login
            })



            return accounts?.length > 0 && (
                accounts?.map((account) => {
                    return (

                        <>

                            <Row>
                                <Col lg="6" id="term-col">
                                    <div >
                                        <img src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/CRM+Banner-01+(4).jpg' alt='tradehall contest' style={{ width: '100%' }} />
                                    </div>

                                    <Card style={{ padding: '24px 0px', borderRadius: '0' }}>


                                        <div style={{ padding: '0 40px' }}>
                                            <div >
                                                <h5>{t('event.TermsAndConditions')}</h5>
                                            </div>

                                            <div >
                                                <p>
                                                    1. {t('event.TheContestIsStartsOn')}
                                                </p>

                                            </div>
                                            <div >
                                                <p>
                                                    2. {t('event.ThisIsAOneMonth')}
                                                </p>

                                            </div>

                                            <div >
                                                <p>
                                                    3. {t('event.TheDefaultLeverageIs')}
                                                </p>
                                            </div>

                                            <div >
                                                <p>
                                                    4. {t('event.InitialVirtualDeposit')}
                                                </p>
                                            </div>

                                            <div >
                                                <p>
                                                    5. {t('event.AtTheExpirationOf')}
                                                </p>
                                            </div>

                                            <div >
                                                <p>
                                                    6. {t('event.AtTheEndOfTheTrading')}
                                                </p>
                                            </div>

                                            <div >
                                                <p>
                                                    7. {t('event.ThePrizesWillBeTransferred')}
                                                </p>
                                            </div>

                                            <div>
                                                <a href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/TradeHall+Demo+Contest+2022+Terms+and+Conditions.pdf' target='_blank' style={{ color: 'black' }}>

                                                    <Button color='primary'>
                                                        {t('event.TermsAndConditions')}
                                                    </Button>
                                                </a>

                                            </div>
                                        </div>

                                    </Card>
                                </Col>

                                <Col lg="6" id="form-col">
                                    <Card style={{ borderRadius: '0px' }} >
                                        <CardBody style={{ display: 'flex', flexDirection: 'column', paddingBottom: '10px' }}>
                                            <h3>TradeHall Demo Contest 2022</h3>
                                            {/* <div style={{padding:'20 0'}}></div> */}
                                            {/* <p>26/02/2022 - 26/07/2022</p> */}

                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <h5>Account Login : {account?.account?.account_login === null ? '-' : `${account?.account_login}` ?? '-'}</h5>

                                            </div>
                                            <div>
                                                <DemoSettings
                                                    settings={settings}
                                                    account={account}
                                                    setSelectedSetting={setSelectedSetting}
                                                />
                                                <br/>
                                                <p >*You have the right to change your demo account password by clicking the settings</p>
                                            </div>
                                        </CardBody>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="m-t-30  ">

                                            <Card style={{ height: '520px', margin: '0px', borderRadius: '0px' }} className="card-absolute">
                                                {/* <CardHeader className="bg-primary">
                            <h6 style={{ margin: 0 }}>{`#${account.account_login} - ${t("demo.demo")}`}</h6>
                        </CardHeader> */}

                                                <CardBody className="text-center" style={{ display: 'flex', paddingBottom:'0' }}>
                                                    <StyledRow>
                                                        <Col xs="12">
                                                            <h6>{`${myCompetitionResult?.my_participation?.name}`}</h6>
                                                            <p>{t('event.PARTICIPANTNAME')}</p>
                                                        </Col>
                                                        <Col xs="12">
                                                            <h6>{account?.plan?.name ?? '-'}</h6>
                                                            <p>{t("demo.accountType")}</p>
                                                        </Col>
                                                        <Col xs="12">
                                                            <h6>
                                                                {account?.leverage === null ? '-' : `1:${account?.leverage}` ?? '-'}
                                                            </h6>
                                                            <p>{t("demo.leverage")}</p>
                                                        </Col>
                                                        

                                                    </StyledRow>

                                                    <StyledRow>
                                                        <Col xs="12">
                                                            <h6>
                                                                {/* {`${currentDate < campusCompetitionStartDate ? '-' : (myCompetitionResult?.my_ranking === null ? '-' : toOrdinalSuffix(myCompetitionResult?.my_ranking))}`} */}
                                                                {(myCompetitionResult?.my_ranking === null ? '-' : toOrdinalSuffix(myCompetitionResult?.my_ranking))}

                                                            </h6>
                                                            <p>{t("event.MYRANKING")}</p>
                                                        </Col>
                                                        <Col xs="12">
                                                            <h6>
                                                                {`${account?.balance === null
                                                                    ? '- '
                                                                    : account?.balance?.toFixed(2) ?? '- '
                                                                    } USD`}
                                                            </h6>
                                                            <p>{t("demo.balance")}</p>
                                                        </Col>
                                                       
                                                        <Col xs="12">
                                                            <h6>
                                                            {`${account?.balance === null
                                                                    ? '- '
                                                                    : ((account?.balance - 10000 )/ 10000 * 100).toFixed(2) + '%' ?? '- '
                                                                    } USD`}
                                                            </h6>
                                                            <p>{t("event.RETURNRATE")}</p>
                                                        </Col>
                                                        


                                                    </StyledRow>

                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <div style={{ padding: '0px' }}>

                                <Col style={{ margin: '15px auto' }}>
                                    <h3 class="text-center" style={{ color: '#113b5a' }}>{t("event.LEADERBOARD")}</h3>
                                    <LeaderboardTable />
                                </Col>


                            </div>
                        </>

                    )
                })
            )


        }



    }



    return (
        <>
            <Breadcrumb
                title={t("event.contest")}
                parent='TradeHall Demo Contest 2022'
            />

            <Container style={{ padding: '0px' }}>




                {isBusy === false ?
                    participantIsEligible()
                    :
                    <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner color='warning'></Spinner>
                    </Row>}



            </Container>
            {selectedSetting !== null && selectedAccount !== null && (
                <Modal isOpen={showModal} backdrop={true} centered={true}>
                    <ModalHeader toggle={toggleModal}>{settings[selectedSetting].title}</ModalHeader>
                    <ModalBody>{settings[selectedSetting].cta}</ModalBody>
                </Modal>
            )}
        </>
    )
}

export default Event