import {
    SET_PAMM_TRADE_URL
  } from '../actionTypes'
  
  const initial_state = {
    // lpoa: {
    //   file: null,
    //   error: null
    // },
    // op: {
    //   file: null,
    //   error: null
    // }
    tradeURL: {
        file: null,
        error: null
      }
  }
  
  export default (state = initial_state, action) => {
    switch (action.type) {
  
      case SET_PAMM_TRADE_URL:
        return { ...state, tradeURL: action.tradeURL }

      default:
        return { ...state }
    }
  }
  