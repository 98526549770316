import React, { useState } from "react";
import Breadcrumb from '../../layout/breadcrumb'
import { useTranslation } from 'react-i18next'
import { Col, Row, Button, Container } from "reactstrap";
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import moment from 'moment'
const Event = () => {
    const { t } = useTranslation()

    const campusCompetition = useSelector(state => state.event?.competitions?.competitions?.filter(item => item.name === 'Tradehall Campus CFD Trading Contest Malaysia 2021'))

    const roadToChampionCompetition = useSelector(state => state.event?.competitions?.competitions?.filter(item => item.name === 'Tradehall Road to Champion Contest'))
    const roadToChampionCompetitionStartDate = moment(roadToChampionCompetition[0]?.start_date).format("YYYY-MM-DD")
    return (
        <>
            <Breadcrumb
                title={t("event.contest")}
            // parent={t("event.allContest")}
            />
            <Container>
                <Row style={{ justifyContent: "flex-start" }}>
                    <Col lg='4' style={{ padding: '0px', marginBottom: '30px', width: '100%' }}>
                        {/* <Link to='/event/demo_contest_2022'> */}

                        <div style={{ maxWidth: '100%' }}>
                            <img src="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/WhatsApp+Image+2022-11-09+at+3.56.12+PM.jpeg" alt="elite cup" style={{ backgroundColor: '#dddddd', margin: '0 15px', width: '93%' }} />
                        </div>
                        {/* </Link> */}
                        <div style={{ display: 'flex', margin: '0 15px' }}>
                            <Col style={{ padding: '0' }}>
                                <Link to='/event/eliteCup'>
                                    <Button id="mobileFontSize" color='primary' style={{ width: '100%', borderRadius: '0' }} >
                                        {t("dashboard.apply")}
                                        {/* {t("dashboard.StayTuned")} */}
                                    </Button>
                                </Link>
                            </Col>

                            <Col style={{ padding: '0' }}>
                                <a href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/T%26C+TradeHall+Elite+Cup+2022+updated.pdf'
                                    target="_blank">
                                    <Button id="mobileFontSize" style={{ width: '100%', borderRadius: '0' }}  >{t("dashboard.TermsAndConditions")}</Button>
                                </a>
                            </Col>


                        </div>
                    </Col>

                    <Col lg='4' style={{ padding: '0px', marginBottom: '30px', width: '100%' }}>
                        {/* <Link to='/event/demo_contest_2022'> */}

                        <div style={{ maxWidth: '100%' }}>
                            <img src="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/WhatsApp+Image+2022-11-09+at+3.56.12+PM+(1).jpeg" alt="champion cup" style={{ backgroundColor: '#dddddd', margin: '0 15px', width: '93%' }} />
                        </div>
                        {/* </Link> */}
                        <div style={{ display: 'flex', margin: '0 15px' }}>
                            <Col style={{ padding: '0' }}>
                                <Link to='/event/championCup'>
                                    <Button id="mobileFontSize" color='primary' style={{ width: '100%', borderRadius: '0' }} >
                                        {t("dashboard.apply")}
                                        {/* {t("dashboard.StayTuned")} */}
                                    </Button>
                                </Link>
                            </Col>

                            <Col style={{ padding: '0' }}>
                                <a href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/T%26C+TradeHall+Champion+Cup+2022+updated.pdf'
                                    target="_blank">
                                    <Button id="mobileFontSize" style={{ width: '100%', borderRadius: '0' }}  >{t("dashboard.TermsAndConditions")}</Button>
                                </a>
                            </Col>


                        </div>
                    </Col>

                    <Col lg='4' style={{ padding: '0px', marginBottom: '30px', width: '100%' }}>
                        {/* <Link to='/event/demo_contest_2022'> */}

                        <div style={{ maxWidth: '100%' }}>
                            <img src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/CRM+Banner-01+(3).jpg' alt="campus competition" style={{ backgroundColor: '#dddddd', margin: '0 15px', width: '93%', cursor: 'not-allowed' }} />
                        </div>
                        {/* </Link> */}
                        <div style={{ display: 'flex', margin: '0 15px' }}>
                            <Col style={{ padding: '0' }}>
                                <Link to='/event/demo_contest_2022'>
                                    <Button disabled id="mobileFontSize" color='primary' style={{ width: '100%', borderRadius: '0' }} >
                                        {t("dashboard.apply")}
                                        {/* {t("dashboard.StayTuned")} */}
                                    </Button>
                                </Link>
                            </Col>

                            <Col style={{ padding: '0' }}>
                                <a href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/TradeHall+Demo+Contest+2022+Terms+and+Conditions.pdf'
                                    target="_blank">
                                    <Button disabled id="mobileFontSize" style={{ width: '100%', borderRadius: '0' }}  >{t("dashboard.TermsAndConditions")}</Button>
                                </a>
                            </Col>


                        </div>
                    </Col>

                    <Col lg='4' style={{ padding: '0px', marginBottom: '30px' }}>
                        {/* <Link to='/event/road_to_champion'> */}
                        <img src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Road+to+Champion+contest+inside+CRM-02.svg' alt="campus competition" style={{ backgroundColor: 'white', margin: '0 15px', cursor: 'not-allowed' }} />
                        {/* </Link> */}
                        <div style={{ display: 'flex', margin: '0 15px' }}>
                            <Col style={{ padding: '0' }}>
                                <Link to='/event/road_to_champion'>
                                    <Button disabled id="mobileFontSize" color='primary' style={{ width: '100%', borderRadius: '0' }} >
                                        {/* {moment().format('YYYY-MM-DD') < roadToChampionCompetitionStartDate ? t("dashboard.apply") : t("dashboard.View")}
                                         */}
                                        {t("dashboard.StayTuned")}
                                    </Button>
                                </Link>
                            </Col>
                            <Col style={{ padding: '0' }}>
                                <a href="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/T%26C+Road+to+Champion+Contest.pdf"
                                    target="_blank">
                                    <Button disabled id="mobileFontSize" style={{ width: '100%', borderRadius: '0' }}  >{t("dashboard.TermsAndConditions")}</Button>
                                </a>
                            </Col>


                        </div>
                    </Col>

                    <Col lg='4' style={{ padding: '0px', marginBottom: '30px' }}>
                        {/* <Link to='/event/campus_competition'> */}
                        <img src='https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/campus/CampusCRM.svg' alt="campus competition" style={{ backgroundColor: '#dddddd', margin: '0 15px', cursor: 'not-allowed' }} />
                        {/* </Link> */}
                        <div style={{ display: 'flex', margin: '0 15px' }}>
                            <Col style={{ padding: '0' }}>
                                <Link to='/event/campus_competition'>
                                    <Button disabled id="mobileFontSize" color='primary' style={{ width: '100%', borderRadius: '0' }} >
                                        {/* {moment().format('YYYY-MM-DD') < campusCompetitionStartDate ? t("dashboard.apply") : t("dashboard.View")} */}
                                        {t("dashboard.StayTuned")}
                                    </Button>
                                </Link>
                            </Col>
                            <Col style={{ padding: '0' }}>
                                <a href={sessionStorage.getItem('language') == 'en'
                                    ? campusCompetition[0]?.terms_and_conditions
                                    : campusCompetition[0]?.terms_and_conditions_chinese}
                                    target="_blank">
                                    <Button disabled id="mobileFontSize" style={{ width: '100%', borderRadius: '0' }}  >{t("dashboard.TermsAndConditions")}</Button>
                                </a>
                            </Col>


                        </div>
                    </Col>



                </Row>
            </Container>
        </>
    );
};

export default Event;
