import client from '../request'

const getAll = () => {
  return client.get(`/payments`)
}

const get = (id) => {
  return client.get(`/payments/${id}`)
}

const getMultichainDepositFees = (data) => {
  const network = data.network
  return client.get(`/deposit_fees?network=${network}`)
}

const getMultichainWithdrawalFees = (data) => {
  const network = data.network
  return client.get(`/withdraw_fees?network=${network}`)
}

export default {
  getAll,
  get,
  getMultichainDepositFees,
  getMultichainWithdrawalFees
}
