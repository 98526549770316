import React, { useEffect, useState } from 'react'
import {
  Row,
  Badge,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import DemoSettings from '../common/settings'
import MasterPassword from '../common/settings/master_password'
import InvestorPassword from '../common/settings/investor_password'

import { ResponsiveDiv, StyledRow } from '../common/components.style'

import AccountService from '../../network/services/account'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

const ActiveDemoAccounts = () => {
  const { t } = useTranslation()
  let history = useHistory()
  const dispatch = useDispatch()
  const currencies = useSelector((state) => state.currency.currencies)
  const ResponsiveParagraph = styled.p`
  @media (max-width: 578px) {
    font-size: 10px;
  }
`

  const Responsiveh6 = styled.h6`
    @media (max-width: 578px) {
      font-size: 11px;
    }
  `

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  // demo accounts && exclude competition account
  const demoAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter(
        (e) => e.account_type === 1 && e.is_competition_account === false
      )
    } else if (state.account.accounts?.length == 0) {
      return []
    } else {
      return null
    }
  })
  const selectedAccount = useSelector((state) => state.dashboard.account)
  const init = async () => {
    const accountResponse = await AccountService.getDemoAccount()
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
  }

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  // modal
  const [showModal, setShowModal] = useState(false)
  const [selectedSetting, setSelectedSetting] = useState(null)
  const toggleModal = () => {
    setShowModal(!showModal)
  }
  const settings = [
    {
      id: 1,
      title: t('dashboard.titleChangeMaster'),
      cta: <MasterPassword setShowModal={setShowModal} />
    },
    {
      id: 2,
      title: t('dashboard.titleChangeInvestor'),
      cta: <InvestorPassword setShowModal={setShowModal} />
    }
    // {
    //   id: 3,
    //   title: 'Change Leverage',
    //   cta: <ChangeLeverage setShowModal={setShowModal} />
    // }
  ]

  useEffect(() => {
    if (selectedSetting != null && selectedAccount != null) {
      setShowModal(true)
    }
  }, [selectedSetting, selectedAccount])

  useEffect(() => {
    if (showModal === false) {
      // fetch account
      if (selectedAccount != null) {
        init()
      }

      setSelectedSetting(null)
      dispatch({
        type: 'SET_DASHBOARD_ACCOUNT',
        account: null
      })
    }
    // eslint-disable-next-line
  }, [showModal])

  return (
    <>
      {demoAccounts != null ? (
        <>

          <Row style={{ justifyContent: 'space-between', marginRight: '0', marginLeft: '0' }}>
            {/* <h5 style={{ marginBottom: 20 }}>{t('live.liveAccounts')}</h5> */}

            <p style={{ fontSize: '12px' }}>
              {t('live.masterPasswordIs')}
              <strong>{t('live.trade')}</strong> {t('live.MT5LiveAccount')}
              <br></br>
              {t('live.inverterPasswordIs')}
              <strong>{t('live.review')}</strong> {t('live.MT5LiveAccount')}
            </p>

            <p style={{ fontSize: '16px' }} >
              {t('demo.demoAccount')}: {demoAccounts?.length}
            </p>

          </Row>
          <Row>
            {demoAccounts?.length > 0 ? (
              demoAccounts.map((account) => {
                const currency = currencies.find((e) => e.id === account.currency_id)
                return (
                  <Col xl="6" lg='6' key={account.id}>
                    <Card className="card-absolute" key={account.id}
                      style={{
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                      }}
                    >
                      {/* <CardHeader className="bg-primary" style={{ boxShadow: '4px 4px 7px 0 rgb(0 0 0 / 20%)' }}>
                        <h6 style={{ margin: 0 }}>{`#${account.account_login} - ${t(
                          'demo.demo'
                        )}`}</h6>
                      </CardHeader> */}
                      <div className="live-card-right">
                        <ResponsiveDiv
                          // <Button
                          //   onClick={() => {
                          //     navigate(`${process.env.PUBLIC_URL}/financial`)
                          //   }}
                          // >
                          //   {t('demo.deposit')}
                          // </Button> 

                          className="d-sm-block"
                          style={{
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            padding: '20px 0px 0px 40px'
                          }}
                        >

                          <DemoSettings
                            settings={settings}
                            account={account}
                            setSelectedSetting={setSelectedSetting}
                          />
                        </ResponsiveDiv>
                        </div>
                      {/* <CardBody className="text-center">
                        <StyledRow>
                          <Col xs="12">
                            <h6>{account.plan?.name ?? '-'}</h6>
                            <p>{t('demo.accountType')}</p>
                          </Col>
                          <Col xs="12">
                            <h6>
                              {account?.leverage === null ? '-' : `1:${account?.leverage}` ?? '-'}
                            </h6>
                            <p>{t('demo.leverage')}</p>
                          </Col>
                          <Col xs="12">
                            <h6>
                              {`${account?.balance === null
                                ? '-'
                                : account?.balance?.toFixed(2) ?? '-'
                                } ${currency?.name ?? ''}`}
                            </h6>
                            <p>{t('demo.balance')}</p>
                          </Col>
                        </StyledRow>
                      </CardBody> */}
                        <CardBody
                          className="text-left"
                          style={{ paddingTop: '10px', paddingBottom: '20px'}}
                        >
                          <Row style={{ marginBottom: '15px' }}>
                            <Col>
                              <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                                MT5 LOGIN ID
                              </ResponsiveParagraph>
                              <Responsiveh6>{`#${account.account_login} `}</Responsiveh6>
                              {/* <h6>{account.account_login}</h6> */}
                            </Col>

                            <Col>
                              <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                                {t('demo.accountType')}
                              </ResponsiveParagraph>
                              <Responsiveh6>{account.plan?.name}</Responsiveh6>
                              {/* <h6>{account.plan?.name ?? '-'}</h6> */}
                            </Col>

                            <Col>
                              <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                                {t('demo.leverage')}
                              </ResponsiveParagraph>
                              <Responsiveh6>
                                {account?.leverage === null ? '-' : `1:${account?.leverage}` ?? '-'}
                              </Responsiveh6>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                                {t('demo.balance')}
                              </ResponsiveParagraph>
                              <Responsiveh6>{`${account?.balance === null
                                ? '-'
                                : formatter?.format(account?.balance?.toFixed(2)) ?? '-'
                                } `}</Responsiveh6>
                              
                            </Col>

                            <Col>
                              <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                                {t('demo.credit')}
                              </ResponsiveParagraph>
                              <Responsiveh6>{`${formatter?.format(account?.credit) ?? '-'
                                } `}</Responsiveh6>
                              
                            </Col>

                            <Col>
                              <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                                CLASS
                              </ResponsiveParagraph>
                              <Responsiveh6>
                                <Badge
                                    style={{
                                      backgroundColor:
                                        account?.account_type === 2 ? '#108EE9' :
                                          account.package_id == 1 ||
                                            account.package_id == 2 ||
                                            account.package_id == 3 ||
                                            account.package_id == 10 ||
                                            account.package_id == 11 ||
                                            account.package_id == 12
                                            ? '#E782A9'
                                            : account.package_id == 4 ||
                                              account.package_id == 5 ||
                                              account.package_id == 6 ||
                                              account.package_id == 13 ||
                                              account.package_id == 14 ||
                                              account.package_id == 15
                                              ? '#39207C'
                                              : account.package_id == 7
                                                ? '#48D597'
                                                : account.package_id == 8
                                                  ? '#F0EC74'
                                                  : account.package_id == 9
                                                    ? '#00A300' :
                                                    account.package_id == 16 ||
                                                      account.package_id == 17 ?
                                                      '#E609D3'
                                                      : account.package_id == 18 ? '#bd4040'
                                                      : account.package_id == 21 ? '#514496'
                                                      :'#B1B3B3'
                                    }}
                                  >
                                    {
                                      account?.account_type === 2 ? 'IB Account' :
                                        account?.package?.name ? account?.package?.name : 'Demo Account'}
                                  </Badge>  
                                </Responsiveh6>
                              </Col>
                            </Row>
                          </CardBody>
                    </Card>
                  </Col>
                )
              })
            ) : (
              <Col>
                <h6 style={{ textAlign: 'center' }}>{t('demo.noActiveAccount')}</h6>
              </Col>
            )}
          </Row>
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Spinner color="warning" />
        </div>
      )}

      {selectedSetting !== null && selectedAccount !== null && (
        <Modal isOpen={showModal} backdrop={true} centered={true}>
          <ModalHeader toggle={toggleModal}>{settings[selectedSetting].title}</ModalHeader>
          <ModalBody>{settings[selectedSetting].cta}</ModalBody>
        </Modal>
      )}
    </>
  )
}

export default ActiveDemoAccounts
