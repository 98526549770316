import React, { useState, useEffect } from "react"
import { Col, Row, Button, Input, Form, FormGroup, Tooltip } from "reactstrap"
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'

import PammService from '../../../network/services/pammUnsubscribe'
import SettingService from '../../../network/services/setting'
import { useTranslation } from 'react-i18next'

import { HelpCircle } from 'react-feather'
import moment from 'moment'

const ReferralModal = (props) => {
    const { t } = useTranslation()
    const { handleSubmit, errors } = useForm()
    const [logo, setLogo] = useState(null)

    const [nextSettlementTooltipOpen, setNextSettlementTooltipOpen] = useState(false);
    const nextSettlementToggle = () => setNextSettlementTooltipOpen(!nextSettlementTooltipOpen);

    const init = async () => {
        try {
            const result = await SettingService.getAll()
            if (result.settings) {
                let logo2 = result.settings.find((s) => {
                    return s.handle === 'crm_logo_url'
                })

                setLogo(logo2 ?? {})
            } else {
                setLogo({})
            }
        } catch (error) {
            setLogo({})
        }
    }

    useEffect(() => {
        init()
    }, [])


    // Condition check the first time to show the error penalthy pamm message
    let today = moment().format('YYYY-MM-DD')

    let settlementDate
    let settlementDateTomorrow1
    let settlementDateTomorrow2

    if (props?.activeUserData?.pammManager?.settlement_period == "Monthly") {
        if (moment().format('YYYY-MM-DD') != moment().startOf('month').format('YYYY-MM-DD') &&
            today != moment().startOf('month').add(1, 'days').format('YYYY-MM-DD') &&
            today != moment().startOf('month').add(2, 'days').format('YYYY-MM-DD')
        ) {
            settlementDate = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD')
            settlementDateTomorrow1 = moment().add(1, 'M').startOf('month').add(1, 'days').format('YYYY-MM-DD')
            settlementDateTomorrow2 = moment().add(1, 'M').startOf('month').add(2, 'days').format('YYYY-MM-DD')
        } else {
            settlementDate = moment().startOf('month').format('YYYY-MM-DD')
            settlementDateTomorrow1 = moment().startOf('month').add(1, 'days').format('YYYY-MM-DD')
            settlementDateTomorrow2 = moment().startOf('month').add(2, 'days').format('YYYY-MM-DD')
        }
    } else {
        settlementDate = moment(props?.activeUserData?.pammManager?.settlement_date).format("YYYY-MM-DD")
        settlementDateTomorrow1 = moment(props?.activeUserData?.pammManager?.settlement_date).add(1, 'days').format('YYYY-MM-DD')
        settlementDateTomorrow2 = moment(props?.activeUserData?.pammManager?.settlement_date).add(2, 'days').format('YYYY-MM-DD')
    }


    const handleSupport = async (data) => {

        let is_action_date_1 = today === settlementDateTomorrow1
        let is_action_date_2 = today === settlementDateTomorrow2

        if (is_action_date_1 === false && is_action_date_2 === false) {
            //Not settlement date
            SweetAlert.fire({
                title: `${t('financial.YouAreSubscribing')} ${props?.activeUserData?.pammManager?.penalty_percent
                    }% ${t('financial.fundDirectly')}`,
                html: `
                  <div style="margin-top:10px;">
                    ${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
                  </div>
    
                  <div >
                              <p style="margin-bottom:0px;margin-top:10px;"
                              >*${t("financial.KindlyTakeNote")}*</p>
                              <p>
                                  1. ${t("financial.ByTypingAgreeDeduct")} ${props?.activeUserData?.pammManager?.penalty_percent}% ${t('financial.fundDirectly')}
                                  <br/>
                                  2. ${t("financial.IfYouHave")} <a href='/support' target="_blank">${t("financial.customerService")}</a> ${t("financial.orReferThe")} <a
                                  href="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/PAMM+Agreement.pdf"
                                  target="_blank"
                              >
                                  <span>5.4 ${t("pamm.termsConditions")}</span>
                              </a>.  
                              </p>
                            </div>
    
                            <div class='text-center'>
                            
                    </div> 
              `,
                input: 'text',
                inputValue: '',
                inputPlaceholder: '',
                inputValidator: (result) => {
                    if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                        return t("financial.PleaseEnterThe")
                    }
                },
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('financial.confirm'),
                cancelButtonText: t('financial.cancel'),
                reverseButtons: true
            }).then(async (result) => {
                if (result.value) {
                    try {
                        if (data !== '') {

                            const result = await PammService.create(props.activeUserData.pammManager.id, {
                                account_login: parseInt(props.activeUserData.account.account_login)
                            })

                            if (result) {
                                SweetAlert.fire({
                                    title: t("pammSubsccription.requestSubmitted"),
                                    text: t("pammSubsccription.weHaveReceiveYourQuery"),
                                    icon: 'success'
                                }).then(() => {
                                    window.location.reload()
                                })
                            }
                        } else {
                            errors.showMessages()
                        }
                    } catch (error) {
                        SweetAlert.fire({
                            title: error?.message ?? t("pammSubsccription.pleaseContactCustomerSupport"),
                            icon: 'error'
                        })
                    }

                }
            })
        } else {
            // Is settlement date
            try {
                if (data !== '') {

                    const result = await PammService.create(props.activeUserData.pammManager.id, {
                        account_login: parseInt(props.activeUserData.account.account_login)
                    })

                    if (result) {
                        SweetAlert.fire({
                            title: t("pammSubsccription.requestSubmitted"),
                            text: t("pammSubsccription.weHaveReceiveYourQuery"),
                            icon: 'success'
                        }).then(() => {
                            window.location.reload()
                        })
                    }
                } else {
                    errors.showMessages()
                }
            } catch (error) {
                SweetAlert.fire({
                    title: error?.message ?? t("pammSubsccription.pleaseContactCustomerSupport"),
                    icon: 'error'
                })
            }



        }


    }

    return (
        <div>
            {logo?.value && (
                <div style={{ textAlign: 'center' }}>
                    <img
                        src={logo.value}
                        alt="logo"
                        style={{ maxHeight: 175, maxWidth: 175 }}
                    />
                </div>
            )}
            <div style={{ textAlign: "center" }}>
                <h5 className="mt-3 ">{t("pammSubsccription.isItReallyOver")}</h5>
                <p className="mb-3">{t("pammSubsccription.areYouSure")} <strong style={{ color: 'red' }}>{t("pammSubsccription.unsubscribe")}</strong>?</p>
                <Form className="theme-form" onSubmit={handleSubmit(handleSupport)}>


                    <p style={{ marginBottom: '10px', marginTop: '20px', fontWeight: '500' }}>
                        Pamm Manager : {props?.activeUserData?.pammManager?.name}
                    </p>

                    <p style={{ marginBottom: '10px', fontWeight: '500' }}>
                        {t("pamm.SettlementPeriod")} {props?.activeUserData?.pammManager?.settlement_period}
                    </p>

                    {(
                        today !== settlementDateTomorrow1
                        &&
                        today !== settlementDateTomorrow2
                    )
                        ?
                        <>
                            <p style={{ fontWeight: '500' }}>
                                {t("pamm.settlementDate")} {settlementDate}(GMT +0)
                    <HelpCircle id="nextSettlement" style={{ width: '18px', color: '#f9b700', marginLeft: '3px', cursor: 'pointer' }} />
                                <Tooltip placement='left' id='settlementTooltip' style={{ textAlign: 'left', backgroundColor: '#f7f7f7', color: 'black' }} isOpen={nextSettlementTooltipOpen} target="nextSettlement" toggle={nextSettlementToggle}>
                                    <h5>{t("pamm.PleaseTakeNote")}</h5>
                                    {/* <p>1. {t("pamm.SettlementDateSubscriber")} {pammManagerDetails.penalty_percent}%<strong></strong></p> */}
                                    <p>1. {t("pamm.IfYouDecide")} <strong>{t("pamm.withdraw")}</strong> {t("pamm.or")} <strong>{t("pamm.unsubscribe")}</strong> {t("pamm.BesideSettlement")} <span style={{ color: 'red', fontWeight: 'bold' }}>{t("pamm.deduct")} {props.activeUserData?.penalty_percent}%</span> {t("pamm.fromYourFund")}</p>
                                    <p>2. {t("pamm.ThisSettlementWillHappenOn")} {settlementDate} (GMT +0) 00:00:00</p>
                      3. {t("pamm.TheInvestorIsNotAllowed")}
                                    <br /><br />
                                    {t("pamm.TheInvestorWillBeAssigned")} <span style={{ color: 'green', fontWeight: 'bold' }}>{t("pamm.afterTheSettlementDate")}</span> {t("pamm.AsBufferPeriod")}
                                    <br /><br />
                                    {t("pamm.ForExample")}
                                    {t("pamm.IfTheSettlementDateIsOn")}
                                    <span style={{ fontWeight: 'bold' }}> {settlementDate} (GMT +0) 00:00:00</span>
                                    {t("pamm.theInvestorIsNotAllowed")}
                                    <span style={{ fontWeight: 'bold' }}>
                                        {settlementDateTomorrow1} (GMT +0) 00:00:00 </span>
                                    {t("pamm.to")}
                                    <span style={{ fontWeight: 'bold' }}> {settlementDateTomorrow2} (GMT +0) 23:59:59
                      </span>
                                    {t("pamm.WithoutAnyPenalty")}
                                </Tooltip>
                            </p>

                        </>
                        :
                        <p style={{ color: '#f9b600' }}>**{t("financial.AbleToWithdraw")}</p>
                    }


                    <FormGroup style={{ margin: '30px' }}>
                        <h6 className="mb-3">{t("pammSubsccription.pleaseVerifyYourSelected")}</h6>


                        <Row className="mb-3">
                            <Col lg="8">
                                <Input type="text" value={props.activeUserData.account.account_login} disabled />
                            </Col>
                            <Col lg="4">
                                <FormGroup className="mb-0">
                                    <Button color="secondary" className="mb-3" style={{ fontSize: '12px' }}>
                                        {t("pammSubsccription.UNSUBSCRIBE")}
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>

                {
                    today !== settlementDateTomorrow1
                        &&
                        today !== settlementDateTomorrow2
                        ?
                        <Row className="mb-3">
                            <Col>
                                <img src="/refer_1.svg" alt="refer_1" style={{ height: 50, marginBottom: 12 }} />
                                <p>{t("pammSubsccription.youWillBeCharge")} {props?.activeUserData?.pammManager?.penalty_percent}% {t("pammSubsccription.fee")}</p>
                                
                            </Col>
                        </Row>
                        :
                        <p style={{ color: '#f9b600' }}>**{t("financial.AbleToUnsubscribe")}</p>
                }

            </div>
        </div>
    )
}

export default ReferralModal