import {
  SET_WITHDRAWAL_WALLET_ID,
  SET_WITHDRAWAL_WALLET_METHOD,
  SET_WITHDRAWAL_MT5_ACCOUNT,
  SET_WITHDRAWAL_MT5_WALLET
} from '../actionTypes'

const initial_state = {
  wallet: {
    id: null,
    method: { id: null, handle: null }
  },
  mt5: {
    account: null,
    wallet: null
  }
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_WITHDRAWAL_WALLET_ID:
      return { ...state, wallet: { ...state.wallet, id: action.id } }

    case SET_WITHDRAWAL_WALLET_METHOD:
      return { ...state, wallet: { ...state.wallet, method: action.method } }

    case SET_WITHDRAWAL_MT5_ACCOUNT:
      return { ...state, mt5: { ...state.mt5, account: action.account } }

    case SET_WITHDRAWAL_MT5_WALLET:
      return { ...state, mt5: { ...state.mt5, wallet: action.wallet } }

    default:
      return { ...state }
  }
}
