import {
    SET_UNREAD_NOTIFICATION,

} from "../actionTypes";

const initial_state = {
    unread_notifications: []
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case SET_UNREAD_NOTIFICATION:
            return { ...state, unread_notifications: action.unread_notifications };

        default:
            return { ...state };
    }
};
