import React, { useState, useEffect } from 'react'
import {
    Col,
    Card,
    CardBody,
    Button,
    Spinner,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'

import SweetAlert from 'sweetalert2'

import AccountService from '../../../network/services/account'
import Auth from "../../../network/services/auth"
import _ from "lodash"

import { useTranslation } from 'react-i18next'
import LanguageIcon from '../../../language.png'
import styled from 'styled-components'

import './verify.scss'

import ReactGA from 'react-ga'

const RemindParagraph = styled.p`
  margin-bottom: '5px';
`


const Step1 = () => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()

    const [currency, setCurrency] = useState(null)
    const [result, setResult] = useState(null)
    const account = useSelector((state) => state.live.account)

    const user = useSelector((state) => state?.user)
    const currencies = useSelector((state) => state.currency.currencies)

    useEffect(() => {
        let selectedCurrency = currencies.find((e) => e.id === account.currency_id)
        setCurrency(selectedCurrency?.name ?? '')
        // eslint-disable-next-line
    }, [])

    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const language = sessionStorage.getItem('language')
    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
        sessionStorage.setItem('language', language)
    }

    const logOut = () => {
        Auth.logout()
    }

    const backHome = () => {
        window.location.href = '/dashboard'
    }

    // create live account
    const handleCreateAccount = async () => {
        setSubmitting(true)
        const result = await AccountService.createLive({
            plan_id: 1,
            currency_id: 1,
            leverage: 200
        })

        // success
        if (result?.account?.id) {
            setSubmitting(false)
            setResult(result.account)
        }

    }


    useEffect(async () => {

        // Check live acc length
        setLoading(true)
        const accountResponse = await AccountService.getLiveAndIbAccount()
        dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })

        const liveAccounts = accountResponse?.accounts?.filter(liveaccount => liveaccount?.account_type === 0)

        try {
            // if user is not asic and live account length === 0
            if (liveAccounts?.length === 0 && user?.user?.is_asic === 0) {
                handleCreateAccount()
                ReactGA.event({
                    category: 'Auto Open MT5 Account',
                    label: 'auto create MT5 account first time'
                })
                setLoading(false)
            } else if (liveAccounts?.length >= 1) {
                // when contain live acc
                window.location.href = '/financial#deposit'
            } else if (user?.user?.is_asic === 1) {
                //when user is ASIC
                SweetAlert.fire({

                    title: t("live.YourAccountIsUnderASIC"),
                    html: t("live.YouCanCreate"),
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                    reverseButtons: true
                })
                    .then(async (result) => {
                        if (result.value) {
                            window.location.href = '/financial#deposit'
                        }

                    })
            }
            setLoading(false)
        } catch (error) {
            //if any error return
            if (error) {
                SweetAlert.fire({
                    title: t("live.Reconnecting"),
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: t("financial.confirm"),
                    cancelButtonText: t("financial.cancel"),
                    reverseButtons: true
                })

                if (liveAccounts?.length === 0 && user?.user?.is_asic === 0) {
                    handleCreateAccount()
                    ReactGA.event({
                        category: 'Auto Open MT5 Account',
                        label: 'auto create MT5 account second time'
                    })
                    setLoading(false)
                } else if (liveAccounts?.length >= 1) {
                    window.location.href = '/financial#deposit'
                } else if (user?.user?.is_asic === 1) {
                    SweetAlert.fire({

                        title: t("live.YourAccountIsUnderASIC"),
                        html: t("live.YouCanCreate"),
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: t("financial.confirm"),
                        cancelButtonText: 'Cancel',
                        reverseButtons: true
                    })
                        .then(async (result) => {
                            if (result.value) {
                                window.location.href = '/financial#deposit'
                            }

                        })
                }
                setLoading(false)

            }
        }

    }
        , []
    )

    return (
        <>

            <Dropdown isOpen={languageDropdownOpen} onMouseLeave={() => setLanguageDropdownOpen(false)} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '30px', paddingRight: '30px', backgroundColor: '#f8f8f8' }}>
                <DropdownToggle onMouseEnter={() => setLanguageDropdownOpen(true)} id="langugeDropdown" style={{ paddingRight: '30px' }}  >
                    <img src={LanguageIcon} alt="languageIcon" width='18px' height="18px" className="m-r-5" />
                    {language === "en" && "EN"}
                    {language === "zh" && "ZH"}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => changeLanguage('en')} >English</DropdownItem>
                    <DropdownItem onClick={() => changeLanguage('zh')}>中文简体</DropdownItem>
                </DropdownMenu>
            </Dropdown>

            <div className="theme-form verify-auth-form"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: '0.75rem',
                    height: '100vh'
                }}>

                <Col lg='5' style={{ paddingRight: '0' }} className="d-none d-md-block d-lg-block d-xl-block padding-verify-right">
                    <Card style={{ height: '600px' }} >
                        <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <img
                                    id='img-header-logo'
                                    className="img-fluid for-light"
                                    width='250px'
                                    src={require("../../../assets/images/logo/logoTradeHall.svg")}
                                    alt=""
                                />
                                <p style={{ cursor: 'pointer' }} onClick={backHome}>{t("verifyAuth.back")}</p>
                            </div>

                            <div style={{ marginTop: '50px' }}>
                                <h3>{t("verifyAuth.OpenNewAccount")}</h3>
                                <p>{t("verifyAuth.CreateAnAccount")}</p>

                                <p style={{ fontSize: '16px', color: '#898989' }}> {t("verifyAuth.Step2Fill")}</p>
                                <p style={{ fontSize: '16px', color: '#f9b700' }}> {t("verifyAuth.Step1Deposit")}</p>
                                <p style={{ fontSize: '16px', color: '#898989' }}> {t("verifyAuth.Step3Submit")}</p>
                            </div>

                            <div style={{ marginTop: 'auto' }}>
                                <RemindParagraph style={{ marginBottom: '0' }}>{t("verifyAuth.RemindToUser")}</RemindParagraph>
                                <RemindParagraph>1. {t("verifyAuth.TheBonusGiven")}</RemindParagraph>
                                <RemindParagraph>2. {t("verifyAuth.OnceTransactionApproved")}</RemindParagraph>
                                <RemindParagraph>3. {t("verifyAuth.PersonalDetails")}</RemindParagraph>
                                <RemindParagraph>4. {t("verifyAuth.KYCNeedWithin")}</RemindParagraph>
                            </div>

                        </CardBody>
                    </Card>
                </Col>

                <Col lg='7' style={{ paddingRight: '0' }} >
                    <Card  >
                        <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='d-flex d-sm-flex d-md-none' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <img
                                    id='img-header-logo'
                                    className="img-fluid for-light"
                                    width='250px'
                                    src={require("../../../assets/images/logo/logoTradeHall.svg")}
                                    alt=""
                                />
                                <p style={{ cursor: 'pointer' }} onClick={backHome}>{t("verifyAuth.back")}</p>
                            </div>


                            {result ?
                                <>
                                    <div style={{ marginTop: '50px', textAlign: 'left', marginBottom: '20px' }}>
                                        <h4 style={{ fontWeight: '400', marginLeft: '7px' }}>{t("live.CreateNewMT5Account")}</h4>
                                    </div>

                                    <Card
                                        className="card-absolute "
                                        style={{
                                            borderColor: '#f9b600',
                                            backgroundColor: 'white'
                                        }}
                                    >

                                        <CardBody className="p-3">
                                            <div
                                                style={{
                                                    color: 'rgba(43,43,43,1)',
                                                    marginBottom: '25px',

                                                }}
                                            >
                                                <p style={{ fontSize: '18px' }}><strong>MT5 {t("live.terminalLogin")}</strong> {result?.account_login}</p>
                                                <p style={{ fontSize: '18px' }}><strong>{t("live.mainPassword")}</strong> {result?.password_main}</p>
                                                <p style={{ fontSize: '18px' }}><strong>{t("live.investorPassword")}</strong> {result?.password_investor}</p>
                                                <p style={{ fontSize: '18px' }}><strong>{t("live.accountLeverage")}</strong> 1:{result?.leverage}</p>
                                                <p style={{ fontSize: '18px' }}><strong>{t("live.balanceAmount")}</strong> {result?.balance?.toFixed(2)}</p>
                                            </div>

                                            <p>{t("live.WeveSentYour")}
                                                <br />
                                        *{t("live.KindlyCheckYour")}</p>
                                        </CardBody>
                                    </Card>
                                </>
                                :

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Spinner style={{ color: '#f9b700' }} />
                                </div>

                            }

                            <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                                <a href='/financial#deposit'>
                                    <Button id='next-button-to-deposit' disabled={!result} color='primary'>Direct To Deposit</Button>
                                </a>

                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </div>



        </>
    )
}

export default Step1