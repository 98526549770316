import { SET_PAMM_MANAGER } from '../actionTypes'

const initial_state = {
  pammsManager: []
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_PAMM_MANAGER:
      return { ...state, pammManager: action.pammManager }

    default:
      return { ...state }
  }
}
