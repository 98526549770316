import React, { useState, useEffect } from 'react'
import { ResponsiveLine } from '@nivo/line'
import {
    Row,
    Col,
    Card,
    Button,
    Input,
    CardFooter,
} from 'reactstrap'

import { Link, NavLink } from 'react-router-dom'

import PammService from '../../../network/services/pammManager'
import UserService from '../../../network/services/user'


import { useTranslation } from 'react-i18next'

import './pammManager.scss'

import "intro.js/introjs.css";
import { Steps, Hints } from 'intro.js-react';

import ReactGA from 'react-ga'

const PammManager = (props) => {

    const { t } = useTranslation()
    const [pammManager, setPammManager] = useState([])

    // Filter manager list
    const [searchTerm, setSearchTerm] = useState("")

    const [searchResults, setSearchResults] = useState([])
    const [teamId, setTeamId] = useState(null)
    const [partnerTeamIds, setPartnerTeamIds] = useState([])
    const handleChange = event => {
        setSearchTerm(event.target.value)
    };

    // Pagination
    const todosPerPage = 9;
    const [activePage, setCurrentPage] = useState(1);

    const indexOfLastTodo = activePage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = searchResults.slice(indexOfFirstTodo, indexOfLastTodo);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    };

    const init = async () => {
        try {
            const result = await PammService.getAll()
            const userInfo = await UserService.getMyself()
            setTeamId(userInfo.user.referred_by?.parent_user?.partner?.team_id ?? null)

            if (userInfo.partners.length > 0) {
                const partnerTeamArray = []
                await userInfo.partners.forEach(item => partnerTeamArray.push(item.team_id))
                setPartnerTeamIds(partnerTeamArray)
            }

            setPammManager(result.pamms.data ?? {})
            setSearchResults(result.pamms.data ?? {})

        } catch (error) {
            setPammManager({})

        }


    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        const results = pammManager.filter(user =>
            user.name.toLowerCase().includes(searchTerm)
        );
        setSearchResults(results);
    }, [searchTerm]);

    let totalGrowth = 0
    let allGrowthData = []

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const followButton = (user) => {
        const pammManagerTeamsIds = user.pammManagerTeams.map(item => item.team_id)

        if (user.pammManagerTeams.length > 0) {
            if (pammManagerTeamsIds.includes(teamId) || partnerTeamIds.some(partnerTeamId => pammManagerTeamsIds.includes(partnerTeamId))) {
                return <Link to={`${process.env.PUBLIC_URL}/pamm/${user.id}/subscribe`}><Button disabled={user.is_follow_disabled} style={{ padding: '5px 10px' }} color="primary">{t("pamm.follow")}</Button></Link>
            }
            if (user.is_follow_disabled) {
                return <Link to={`${process.env.PUBLIC_URL}/pamm/${user.id}/subscribe`}><Button disabled={user.is_follow_disabled} style={{ padding: '5px 10px' }} color="primary">{t("pamm.follow")}</Button></Link>
            }

            if (user?.email) {
                return <a href={`mailto:${user?.email}?subject=Request to subscribe PAMM ${user.name}`} style={{ padding: '5px 10px', borderRadius: '5px' }} class="btn-primary">{t("pamm.contactPamm")}</a>
            } else {
                return <a href={`mailto:support@tradehall.co?subject=Request to subscribe PAMM ${user.name}`} style={{ padding: '5px 10px', borderRadius: '5px' }} class="btn-primary">{t("pamm.contactAdmin")}</a>
            }
        }
        return <Link to={`${process.env.PUBLIC_URL}/pamm/${user.id}/subscribe`}><Button disabled={user.is_follow_disabled} style={{ padding: '5px 10px' }} color="primary">{t("pamm.follow")}</Button></Link>
    }


    const PammList = currentTodos.map(user => {

        allGrowthData = []
        totalGrowth = 0



        for (let [key, value] of Object.entries(user.performances)) {
            allGrowthData.push({
                "x": value.id,
                "y": totalGrowth + value.monthly_growth
            })
            totalGrowth += value.monthly_growth

        }

        if (allGrowthData.length < 1) {
            allGrowthData.push({
                "x": "0",
                "y": 0
            },
                {
                    "x": "1",
                    "y": 0
                })
        } else if (allGrowthData.length == 1) {
            allGrowthData.unshift({
                "x": "0",
                "y": 0
            })
        }

        return (

            <Col key={user.id} className="result" id='dashboard-PAMM'>
                <NavLink onClick={() => {
                    ReactGA.event({
                        category: 'Click from dashboard PAMM',
                        label: 'Click from dashboard PAMM'
                    })
                }}
                    to={`pamm/${user.id}/statistic`}
                    style={{ color: 'inherit' }}
                >
                    <Row style={{ alignItems: 'center', textAlign: 'center' }}>
                        <Col lg='4'>


                            <div style={{ cursor: 'pointer' }}>
                                <div id='line-graph' style={{ height: '125px', width: '100%' }}>
                                    {/* Map each graph */}
                                    <ResponsiveLine
                                        colors='#f9b600'
                                        data={[{
                                            "id": "profit",
                                            "color": "hsl(294, 70%, 50%)",
                                            "data": allGrowthData

                                        }]}

                                        curve="natural"
                                        type="linear"
                                        enableSlices='x'
                                        defs={[
                                            {
                                                id: 'gradientC',
                                                type: 'linearGradient',
                                                colors: [
                                                    { offset: 0, color: '#f7cc51' },
                                                    { offset: 20, color: 'inherit' },
                                                    { offset: 80, color: '#fff' },
                                                    { offset: 100, color: '#fff' },
                                                ],
                                            }
                                        ]}

                                        fill={[
                                            { match: '*', id: 'gradientC' }
                                        ]}

                                        margin={{ top: 30, bottom: 20, left: 0, right: 0 }}
                                        xScale={{ type: 'point' }}
                                        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                        yFormat=" >-.2f"
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={null}
                                        axisLeft={null}
                                        enableGridX={false}
                                        enableGridY={false}
                                        enablePoints={false}
                                        pointSize={10}
                                        pointColor={{ theme: 'background' }}
                                        pointBorderWidth={2}
                                        pointBorderColor={{ from: 'serieColor' }}
                                        pointLabelYOffset={-12}
                                        enableArea={true}
                                        useMesh={true}
                                        legends={[]}
                                        isInteractive={true}
                                    /></div>

                            </div>

                        </Col>

                        <Col lg='4' >

                            <h6 style={{ margin: '0px' }} >{user.name}</h6>
                        </Col>
                        {/* <Col lg='4' >
                        <h6>{user?.performances[user?.performances?.length - 1]?.monthly_growth.toFixed(2)}%</h6>
                    </Col> */}

                        <Col lg='4' >
                            <h6><span className='d-block d-sm-block d-md-none'>{t('pamm.growth')} </span>{totalGrowth.toFixed(2)} %</h6>
                        </Col>

                    </Row>
                </NavLink>
            </Col>


        )

    })



    return (
        <>

            <Row
                style={{
                    marginTop: '20px',
                    marginLeft: '0',
                    marginRight: '0',
                    flexDirection: 'column'
                }}>
                <div className='d-none d-lg-flex d-xl-flex' style={{ display: 'flex', wordWrap: 'normal', alignItems: 'flex-end', textAlign: 'center' }}>
                    <Col lg='4'>
                        <p style={{ marginBottom: '0' }}>{t('pamm.statistics')}</p>
                    </Col>

                    <Col lg='4' >
                        <p style={{ marginBottom: '0' }}>{t('user.Name')} </p>
                    </Col>


                    <Col lg='4'>
                        <p style={{ marginBottom: '0' }}>{t('pamm.growth')}</p>
                    </Col>

                    {/* <Col lg='2'>
                        <p style={{ marginBottom: '0' }}>This Month Growth</p>
                    </Col> */}
                </div>

                <div style={{ height: '455px', overflow: 'auto' }}>
                    {PammList}
                </div>

            </Row>

        </>
    )
}

export default PammManager