import React, { useState, Fragment } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import './index.scss'

const AccountSettings = ({ account, setSelectedSetting, settings, setResetModal }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // dropdown
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)

  return (
    <div className="dropdown-account">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret id='DropdownSettings' >
          {t("common.settings")}
        </DropdownToggle>
        <DropdownMenu className="dropdown-content">
          {settings.map((setting, index) => {
            return (
              <Fragment key={setting.id}>
                <DropdownItem
                  onClick={() => {
                    dispatch({
                      type: 'SET_DASHBOARD_ACCOUNT',
                      account: account
                    })
                    setSelectedSetting(index)
                  }}
                >
                  {setting.title}
                </DropdownItem>
                {index !== settings.length - 1 && <DropdownItem divider />}
              </Fragment>
            )
          })}

          {/* {window.location.toString().includes('road_to_champion') &&
            <>
              <DropdownItem divider />
              <DropdownItem
              onClick={() => 
                setResetModal(true)
              }
              >
                {t('competition.ResetAccount')}
            </DropdownItem>
            </>
          } */}

        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default AccountSettings
