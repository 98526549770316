import React, { useEffect, useState } from "react"
import WalletOption from "../options"
import WalletDeposit from "./wallet"
import MT5Deposit from "./mt5"

const Deposit = ({ refresh }) => {
  const [option, setOption] = useState()

  // reset options on tab change
  useEffect(() => {
    setOption(null)
  }, [refresh])

  return (
    <>
      {option == null ? (
        <WalletOption setOption={setOption} type="deposit" />
      ) : (
          <>
            {option === 1 ? (
              <div>
                <WalletDeposit id='wallet-deposit-option' setOption={setOption} />
              </div>
            ) : (
                <MT5Deposit id='mt5-deposit-option' setOption={setOption} />
              )}
          </>
        )}
    </>
  )
}

export default Deposit
