import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Button,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    Input,

} from 'reactstrap'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'
import { useForm } from 'react-hook-form'

import AccountService from '../../network/services/account'

import StarterPackage from './StarterPackage/packageInfo'

import './index.scss'

import ScrollDownIcon from './down-arrow.png'
import GreenChecked from './checked.png'
import Package1 from './package-01.png'
import Package2 from './Package-02.png'
import Package3 from './Package-03.png'

function StaterPackage({ account, accountDetails, starterPackageUpgradeModal, setStaterPackageUpgradeModal, upgradeStarterPackageModalToggle }) {

    const { t } = useTranslation()
    const { register } = useForm()


    const [personalDetailsModal, setPersonalDetailsModal] = useState(false)
    const [packageSelect, setPackageSelect] = useState('')
    const [applicateName, setApplicateName] = useState('')
    const [identity, setIdentity] = useState('')
    const [isBusy, setIsBusy] = useState(false)
    const [selectedPackage, setSelectedPackage] = useState(2)

    const personalDetailsModalToggle = () => {

        setApplicateName('')
        setIdentity('')
        setPersonalDetailsModal(!personalDetailsModal)
    }


    const handlePackageApplication = () => {

        setIsBusy(true)
        SweetAlert.fire({
            title: 'Are you sure you want to upgrade package?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('financial.confirm'),
            cancelButtonText: t('financial.cancel'),
            reverseButtons: true
        }).then(async (result) => {
            setIsBusy(false)
            if (result.value) {
                try {

                    let result = await AccountService.upgradePackage(accountDetails.id, {
                        package_id: packageSelect
                    })

                    if (result?.package_application?.id) {
                        SweetAlert.fire({
                            title: 'Applied Package Successfully',
                            icon: 'success'
                        }).then(() => {
                            setIsBusy(false)
                            setStaterPackageUpgradeModal(!starterPackageUpgradeModal)
                            window.location.reload()
                        })
                    }

                } catch (error) {

                    setIsBusy(false)
                    const errorCode = error.code
                    const errorTranslate = error.translate_params
                    SweetAlert.fire({
                        title: t(errorCode, errorTranslate),
                        icon: 'error'
                    }).then(() => {
                        window.location.href = '/financial'
                    })
                }
            }
        })
    }

    const faqItems = [
       
     
        {
            heading: t('live.WhereCanIViewTheGrants'),
            content: t('live.YouJustNeedToLog')
        },
        {
            heading: t('live.IsThereAMemberGroup'),
            content: t('live.NoForGroupBenefits')

        },
        {
            heading: t('live.IsThisCourseAvailable'),
            content: t('live.AbsolutelyMVPStudentsWill')
        },
        
        {
            heading: t('live.CanIRewatchThe'),
            content: t('live.YesThisCourseContentHas')
        },
        {
            heading: t('live.CanIWithdrawMyFunds'),
            content: t('live.YesYouCanWithdrawAtAnyTime')
        }
    ]

    return (

        <Modal
            id="upgrade-live-modal"
            isOpen={account.id === accountDetails?.id && starterPackageUpgradeModal}
            toggle={upgradeStarterPackageModalToggle}
            backdrop='static'

        >
            <ModalHeader toggle={upgradeStarterPackageModalToggle} centered className='modalHeader-Package' >

            </ModalHeader>
            <ModalBody>
                {/* Control CSS display property  */}

                <Row style={{ justifyContent: 'center', paddingBottom: '20px' }}>
                    <h2 style={{ color: 'white', fontWeight: '300' }}>
                        {t('live.StarterPackage')} in VE Team
                    </h2>
                </Row>
                <div
                    className="d-block d-sm-block d-md-flex d-lg-flex d-xl-flex"
                    style={{ justifyContent: 'space-between', marginTop: '20px' }}
                >
                    <Col lg='1'></Col>
                    <Col lg='3' md='4'>
                        <img src={Package1} alt='package' style={{ width: '100%' }} />
                        <Card
                            style={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',

                                textAlign: 'center',

                            }}
                            onClick={() => {
                                setSelectedPackage(1)
                            }}
                            className='packageCard responsiveCardStarterPack'
                        >
                            <div
                                style={{
                                    padding: '1rem 0',
                                }}
                            >

                                <Button
                                    style={{
                                        width: '75%',
                                        margin: '10px auto 15px auto',
                                    }}
                                    className='packageButton'
                                    onClick={() => {

                                        setPackageSelect(1)
                                        personalDetailsModalToggle(account)
                                    }}
                                >
                                    {t('live.UpgradeToPackageA')}
                                </Button>

                                <div style={{ display: 'flex' }}>
                                    <div style={{ margin: '0 auto' }}>
                                        {StarterPackage?.packageA?.benefits?.map((benefit, index) => {
                                            return (
                                                <div style={{ display: 'flex' }} key={index}>
                                                    <div style={{ marginRight: '10px' }}>
                                                        <img src={GreenChecked} style={{ width: '16px' }} alt='greenChecked' />
                                                    </div>
                                                    <p style={{ marginBottom: "10px", fontSize: '15px' }}>
                                                        {t(benefit?.name)}
                                                    </p>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>

                        </Card>


                    </Col>

                    <Col lg='3' md='4'>
                        <img src={Package2} alt='package' style={{ width: '100%' }} />
                        <Card
                            style={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                textAlign: 'center',

                            }}
                            onClick={() => {
                                setSelectedPackage(2)
                            }}
                            className='packageCard responsiveCardStarterPack'
                        >
                            <div
                                style={{
                                    padding: '1rem 0',

                                }}
                            >

                                <Button
                                    style={{
                                        width: '75%',
                                        margin: '10px auto 15px auto',

                                    }}
                                    className='packageButton'
                                    onClick={() => {

                                        setPackageSelect(2)
                                        personalDetailsModalToggle(account)
                                    }}
                                >
                                    {t('live.UpgradeToPackageB')}
                                </Button>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ margin: '0 auto' }}>
                                        <p style={{ marginBottom: "10px", fontSize: '16px' }}>
                                            {t('live.EverythingInPackageA')}
                                        </p>
                                        {StarterPackage?.packageB?.benefits?.map((benefit, index) => {
                                            return (
                                                <div style={{ display: 'flex' }} key={index}>
                                                    <div style={{ marginRight: '10px' }}>
                                                        <img src={GreenChecked} style={{ width: '16px' }} alt='greenChecked' />
                                                    </div>
                                                    <p style={{ marginBottom: "10px", fontSize: '15px' }}>
                                                        {t(benefit?.name)}
                                                    </p>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>

                        </Card>

                    </Col>

                    <Col lg='3' md='4'>
                        <img src={Package3} alt='package' style={{ width: '100%' }} />
                        <Card
                            
                            style={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                textAlign: 'center',
                            }}
                            onClick={() => {
                                setSelectedPackage(3)
                            }}
                            className='packageCard responsiveCardStarterPack'
                        >
                            <div
                                style={{
                                    padding: '1rem 0',

                                }}
                            >

                                <Button
                                    style={{
                                        width: '75%',
                                        margin: '10px auto 15px auto',

                                    }}
                                    className='packageButton'
                                    onClick={() => {

                                        setPackageSelect(3)
                                        personalDetailsModalToggle(account)
                                    }}
                                >
                                    {t('live.UpgradeToPackageC')}
                                </Button>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ margin: '0 auto' }}>
                                        <p style={{ marginBottom: "10px", fontSize: '15px' }}>
                                            {t('live.EverythingInPackageB')}
                                        </p>
                                        {StarterPackage?.packageC?.benefits?.map((benefit, index) => {
                                            return (
                                                <div style={{ display: 'flex' }} key={index}>
                                                    <div style={{ marginRight: '10px' }}>
                                                        <img src={GreenChecked} style={{ width: '16px' }} alt='greenChecked' />
                                                    </div>
                                                    <p style={{ marginBottom: "10px", fontSize: '15px', textAlign: 'left' }}>
                                                        {t(benefit?.name)}
                                                        <br />
                                                        {t(benefit?.name) === 'Online Tutorial in' && (
                                                            <>
                                                                1. {t('live.Breakthrough')}
                                                                <br />
                                                                    2. {t('live.ManageFloating')}
                                                                <br />
                                                                    3. {t('live.RiskManagementStrategy')}
                                                                <br />
                                                                    4. {t('live.FindBestEntryPoint')}
                                                            </>
                                                        )
                                                        }
                                                    </p>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>

                        </Card>


                    </Col>
                    <Col lg='1'></Col>
                </div>

                <Row style={{ justifyContent: 'center', marginBottom: '25px', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ marginBottom: '10px', color: 'white' }}>{t('live.ScrollFor')}</p>
                    <img src={ScrollDownIcon} alt='scroll down icon' style={{ width: '30px' }} />
                </Row>

                <Row style={{ justifyContent: 'center', backgroundColor: '#F5F7FA', padding: '2rem 0rem' }}>
                    <Col lg='8'>
                        <h5 style={{ textAlign: 'center', marginBottom: '25px' }}>{t('live.FrequentlyAskedQuestions')}</h5>

                        <Accordion allowZeroExpanded allowMultipleExpanded>
                            {faqItems.map((item, index) => (
                                <AccordionItem key={index}  >
                                    <AccordionItemHeading>
                                        <AccordionItemButton style={{ backgroundColor: '#FFF' }}>
                                            {item.heading}
                                        </AccordionItemButton>

                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        {item.content}
                                        {item.heading === t('live.WhatBenefitsCanIget') &&
                                            <a href={sessionStorage.getItem('language') === 'en' ? 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Starter+Pack+EN-06.svg' : 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Starter+Pack+CN-06.svg'} target='_blank'>
                                                {t('live.ClickForMoreDetails')}
                                            </a>
                                        }
                                    </AccordionItemPanel>

                                </AccordionItem>
                            ))}
                        </Accordion>

                    </Col>
                </Row>

                {/* Modal for personal details  */}
                <Modal
                    // id="upgrade-live-modal"
                    isOpen={account.id === accountDetails?.id && personalDetailsModal}
                    toggle={personalDetailsModalToggle}
                    centered
                >
                    <ModalHeader toggle={personalDetailsModalToggle} centered>
                        Please Check The Details
                                      </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label className="col-form-label pt-0">
                                MT5 Login ID
                                </Label>
                            {/* For Submit  */}
                            <Input
                                className="form-control"
                                type="text"
                                name="account_id"
                                innerRef={register({ required: true })}
                                defaultValue={account.id}
                                disabled
                                hidden
                            >
                            </Input>

                            {/* For Showing */}
                            <Input
                                className="form-control"
                                type="text"
                                defaultValue={account.account_login + ' - $' + account.balance}
                                disabled

                            ></Input>

                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="col-form-label pt-0">
                                        Package Select
                                        </Label>
                                    {/* For Submit  */}
                                    <Input
                                        className="form-control"
                                        type="text"
                                        name="account_id"
                                        innerRef={register({ required: true })}
                                        defaultValue={account.id}
                                        disabled
                                        hidden
                                    >
                                    </Input>

                                    {/* For Showing */}
                                    <Input
                                        className="form-control"
                                        type="text"
                                        defaultValue={
                                            packageSelect === 1 ? 'Package A'
                                                : packageSelect === 2 ? 'Package B'
                                                    : packageSelect === 3 && 'Package C'
                                        }
                                        disabled

                                    ></Input>
                                </Col>

                                <Col>
                                    <Label className="col-form-label pt-0">
                                        {t('live.MinDeposit')}
                                    </Label>

                                    <Input
                                        className="form-control"
                                        type="text"
                                        defaultValue={
                                            packageSelect === 1 ? '$100'
                                                : packageSelect === 2 ? '$300'
                                                    : packageSelect === 3 && '$500'
                                        }
                                        disabled

                                    ></Input>
                                </Col>
                            </Row>
                        </FormGroup>

                        {/* <FormGroup>
                            <Label>{t('live.ReceivedCreditBonus')}</Label>

                            <Input
                                className="form-control"
                                type="text"
                                defaultValue={
                                    packageSelect === 1 ? '10%'
                                        : packageSelect === 2 ? '30%'
                                            : packageSelect === 3 && `50% - Maximum Bonus Credit Capped At $250`
                                }
                                disabled

                            ></Input>
                        </FormGroup> */}


                        {/* <Link to='/' style={{ textDecoration: 'underline' }}>
                            {t('live.ReadTermsConditions')}
                        </Link> */}


                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => handlePackageApplication()} disabled={isBusy} color='primary' style={{ float: 'right' }}>{t('demo.submit')}</Button>
                            {isBusy && <Spinner color='warning' />}

                        </div>
                    </ModalBody>
                </ Modal>

            </ModalBody>
        </Modal>


    )
}

export default StaterPackage
