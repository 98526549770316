import client from '../request'
import _ from 'lodash'

const getMyself = () => {
  return client.get('/me')
}

const getCommissions = (props = null) => {
  let page = props?.page ?? 1
  let limit = props?.limit ?? 10
  let sort = props?.sort ?? 'created_at:desc'
  let filters = props?.filters ?? null
  let filterString = ''

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }

  return client.get(`/me/commissions?page=${page}&limit=${limit}&sort=${sort}${filterString}`)
}

const getCommissionsTotal = (props = null) => {
  let filters = props?.filters ?? null
  let filterString = ''

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }

  return client.get(`/me/commissions/total?foo=bar${filterString}`)
}

const getCommissionsThisMonth = (props = null) => {
  let filters = props?.filters ?? null
  let filterString = ''

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }

  return client.get(`/me/commissions/month${filterString}`)
}

const getBonuses = (props = null) => {
  let page = props?.page ?? 1
  let limit = props?.limit ?? 10
  let filters = props?.filters ?? null
  let filterString = ''

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }

  return client.get(`/me/bonuses?page=${page}&limit=${limit}${filterString}`)
}

const createProfile = (data) => {
  return client.post('/me/profiles', data)
}

const updateProfile = (id, data) => {
  return client.put(`/me/profiles/${id}`, data)
}

const changePassword = (data) => {
  return client.post(`/auth/change_password`, data)
}

const update2fa = (data) => {
  return client.post(`/me/two_factor`, data)
}

const createBank = (data) => {
  return client.post('/me/banks', data)
}

const updateBank = (id, data) => {
  return client.put(`/me/banks/${id}`, data)
}

const createCreditCard = (data) => {
  return client.post('/me/credit_cards', data)
}

const updateCreditCard = (id, data) => {
  return client.put(`/me/credit_cards/${id}`, data)
}

const verifyEmail = (data) => {
  return client.post('/users/available', data)
}

// const submitASIC = (data) => {
//   return client.post('/me/asic_transferral', data)
// }

// const getASIC = () => {
//   return client.get('/me/asic_transferral')
// }

export default {
  getMyself,
  createProfile,
  updateProfile,
  createBank,
  updateBank,
  verifyEmail,
  changePassword,
  update2fa,
  getCommissions,
  getCommissionsTotal,
  getBonuses,
  getCommissionsThisMonth,
  createCreditCard,
  updateCreditCard
}
