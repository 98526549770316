import React, { useEffect, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";
import "./index.scss";

import store from "./store";
import ScrollToTop from "./layout/scroll_to_top";
import AppWrapper from "./app_wrapper";
import './i18n'

const Root = (props) => {
  const abortController = new AbortController();

  useEffect(() => {
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter basename={`/`}>
          <ScrollToTop />
          <AppWrapper />
        </BrowserRouter>
        </Suspense>
      </Provider>
    </>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
