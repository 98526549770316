import { SET_MAIN_MENU, SET_ACTIVE_MENU } from "../actionTypes"
import { MENUITEMS } from "./menu"

const initial_state = {
  menuItems: MENUITEMS,
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_MAIN_MENU:
      return { ...state, menuItems: action.menuItems }

    case SET_ACTIVE_MENU:
      let path = action.path.split("#")[0]
      let clonedItems = [...state.menuItems]
      let item = clonedItems.find((items) => {
        if (items.path === path) {
          return items
        }

        if (!items.children) {
          return null
        }

        items.children.filter((subItems) => {
          if (subItems.path === path) {
            return subItems
          }

          return null
        })

        return null
      })

      clonedItems.filter((menuItem) => {
        if (menuItem !== item) {
          menuItem.active = false
        }

        if (menuItem.children && menuItem.children.includes(item)) {
          menuItem.active = true
        }

        if (menuItem.children) {
          menuItem.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              menuItem.active = true
              submenuItems.active = true
              return true
            } else {
              return false
            }
          })
        }

        return menuItem
      })

      if (item != null) {
        item.active = true
      }

      return { menuItems: clonedItems }

    default:
      return { ...state }
  }
}
