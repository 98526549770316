import React, { useEffect, useState } from 'react'
import MyPammAccountService from '../../../network/services/myPammAccount'

import { useForm } from 'react-hook-form'
import { Card, Col, Row, FormGroup, Input, Form, Button, CardHeader, Spinner, Label } from 'reactstrap'
import SweetAlert from 'sweetalert2'

import FilesService from '../../../network/services/files'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'

const EditForm = (props) => {
    const { t } = useTranslation()
    const [accounts, setAccounts] = useState()
    const [selectedAccount, setSelectedAccount] = useState()
    const [error, setError] = useState(null)
    const dispatch = useDispatch()

    const pammTrade = useSelector((state) => state.pammTrade)
    const [isSending, setIsSending] = useState(false)

    const { register, handleSubmit, errors, reset } = useForm()

    const onSubmit = async data => {

        SweetAlert.fire({
            title: t("myPamm.areYouSureSubmit"),
            html: ``,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("myPamm.confirm"),
            cancelButtonText: t("myPamm.cancel"),
            reverseButtons: true
        }).then(async (result) => {
            setIsSending(true)
            if (result.value) {
                try {
                    const tradeUrlRes = await FilesService.create(pammTrade.tradeURL?.file)
                    if (data !== '') {
                        // console.log('I am result',data)
                        const result = await MyPammAccountService.editPammAccount(props.previousData.id, {
                            account_id: data.account_id,
                            previous_brokerage: data.previous_brokerage,
                            trade_duration: data.trade_duration,
                            trade_statement_url: tradeUrlRes.data
                        })

                        // console.log(result)

                        SweetAlert.fire({
                            title: t("myPamm.thankYou"),
                            html: `${t("myPamm.yourRequestWillReviewByAdmin")} <br/>${t("myPamm.pleaseCheckYourApplication")}`,
                            icon: 'success'
                        }).then(function () {
                            window.location.href = `/mypamm_account`;
                        })

                        if (result.user) {
                            reset()
                            setError(null)

                        }
                        setIsSending(false)
                    } else {
                        setIsSending(false)
                        errors.showMessages()
                    }

                } catch (error) {
                    // SweetAlert.fire({
                    //     title: `${error.message}`,
                    //     icon: "error"
                    // })
                    const errorCode = error.code
                    const errorTranslate = error.translate_params
                    SweetAlert.fire({
                        title: t(errorCode, errorTranslate),
                        icon: 'error'
                    })
                    setError(error.message)
                    setIsSending(false)
                }
            }
        })
    }

    const init = async () => {

        const result = await MyPammAccountService.getIdle()
        setAccounts(result.idle)
        // console.log(result)
    }

    useEffect(() => {
        init()
        // console.log('123123',props.previousData)
    }, [])

    return (

        <>
            {/* {console.log("abuli abu,", accounts)} */}
            <Form className="theme-form" onSubmit={handleSubmit(onSubmit)} >
                <FormGroup style={{ margin: '5px' }}>

                    <Card >
                        <CardHeader><h5>{t("myPamm.resubmitRegistrationAccount")}</h5></CardHeader>
                        <Row style={{ margin: '0' }} >
                            <Col className="ml-2 mt-3 mr-2 ">


                                <Label>{t("myPamm.selectMT5LIVEAccount")}</Label>
                                <Input
                                    type="select"
                                    name="account_id"
                                    className="form-control digits mb-3"
                                    innerRef={register({ required: true })}
                                    style={{ width: '50%' }}
                                    required
                                    defaultValue={props.previousData.account_id}
                                >
                                    <option value="">{t("myPamm.pleaseSelectAnAccount")}</option>
                                    {accounts?.length > 0 &&
                                        accounts.map((account, index) => {
                                            return (
                                                <option key={index} value={account.account_login} onChange={(e) => { (setSelectedAccount(e.target.value)) }} >{account.account_login}</option>
                                            )
                                        })}
                                </Input>

                                <Label className="mt-4">{t("myPamm.previousBrokerage")}</Label>
                                <Input
                                    type="text"
                                    name="previous_brokerage"
                                    className="form-control digits mb-3"
                                    innerRef={register({ required: true })}
                                    style={{ width: '50%' }}
                                    required
                                    defaultValue={props.previousData.previous_brokerage}
                                />

                                <Label className="mt-4">{t("myPamm.tradeDuration")}</Label>
                                <Input
                                    type="select"
                                    name="trade_duration"
                                    className="form-control digits mb-3"
                                    innerRef={register({ required: true })}
                                    style={{ width: '50%' }}
                                    required
                                >
                                    <option value="">{t("myPamm.pleaseSelectYourTradeDuration")}</option>
                                    <option value="0">{t("myPamm.noExperience")}</option>
                                    <option value="1">{t("myPamm.lessThan1Year")}</option>
                                    <option value="2">1 - 5 {t("myPamm.years")}</option>
                                    <option value="3">5 - 10 {t("myPamm.years")}</option>
                                    <option value="4">{t("myPamm.over10Years")}</option>

                                </Input>

                                <Label className="mt-4">{t("myPamm.tradeRecord")}<small>*{t("myPamm.pleaseUploadTradeRecord")}</small></Label>

                                <Input
                                    className="mb-3"
                                    type="file"
                                    name="file"
                                    accept="image/png, image/jpeg, application/pdf"
                                    required
                                    onChange={(e) => {
                                        dispatch({ type: 'SET_PAMM_TRADE_URL', tradeURL: { file: e?.currentTarget?.files[0] } })
                                    }}

                                />



                                <Input className="mb-3" type="checkbox" required style={{ marginLeft: '0' }} />

                                <p className="mb-3" style={{ marginLeft: '1.25rem', marginBottom: '0' }}>{t("myPamm.byClicking")} <strong>{t("myPamm.submit")}</strong>{t("myPamm.youAgreeToOur")} <a style={{ fontSize: '' }}
                                    target="_blank">{t("myPamm.termsConditions")}</a>
                                </p>

                                {error != null && <span className="mb-3" style={{ color: 'red' }}>{error}</span>}

                                <FormGroup className="mb-0">
                                    <Button disabled={isSending} color="primary" className="mt-1 mb-3" style={{ fontSize: '12px' }}>
                                        {t("myPamm.submit")}
                                    </Button>
                                    {isSending ? <Spinner className="ml-2" color="warning" size="lg" /> : ''}
                                </FormGroup>
                            </Col>
                        </Row>
                    </Card>
                </FormGroup>
            </Form>
        </>

    )
}

export default EditForm