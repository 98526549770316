import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, CardHeader, CardBody, Spinner } from 'reactstrap'

import Breadcrumb from '../../layout/breadcrumb'
import Notification from './index'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useParams, Route } from 'react-router-dom'
import NotificationService from '../../network/services/notification'
import moment from 'moment'

const SingleNotification = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [currentNoti, setCurrentNoti] = useState([])

  const fetchCurrentNoti = async () => {
    const result = await NotificationService.getSingleNoti(id)
    setCurrentNoti(result?.user_notification)
    // console.log(currentNoti,'123')
  }

  useEffect(() => {
    fetchCurrentNoti()
  },[])

  const getthetitle = (type) => {
        if (type == 0) {
            return t('notification.NotiTitleDeposit');
        } else if (type == 1) {
            return t('notification.NotiTitleWithdrawal');
        } else if (type == 2) {
            return t('notification.NotiTitleMT5Withdrawal');
        } else if (type == 3) {
            return t('notification.NotiTitleMT5Transfer');
        } else if (type == 4) {
            return t('notification.NotiTitleVerification');
        } else if (type == 5) {
            return t('notification.NotiTitleBank');
        } else if (type == 6) {
            return t('notification.NotiTitleCreditCard');
        } else if (type == 7) {
            return t('notification.NotiTitlePackageApplication');
        } else if (type == 8) {
            return t('notification.NotiTitlePammSubscription');
        } else if (type == 9) {
            return t('notification.NotiTitleLeverage');
        } else if (type == 10) {
            return t('notification.NotiTitleSupportTicket');
        } else {
            return t('notification.NotiStatusPending');
        }
    }

    const getthestatus = (status) => {
        if (status == 0) {
            return t('notification.NotiStatusPending');
        } else if (status == 1) {
            return t('notification.NotiStatusSuccess');
        } else if (status == 2) {
            return t('notification.NotiStatusFailed');
        } else if (status == 3) {
            return t('notification.NotiStatusRejected');
        }
    }

    const getIcon = (type) =>{
      if (type == 0) {
        return <img style={{width:'87px', height:'87px',marginTop:'-100px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Notification-Deposit.svg'></img>
      } else if (type == 1) {
        return <img style={{width:'87px', height:'87px',marginTop:'-100px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Notification-Withdrawal.svg'></img>;
      } else if (type == 2) {
        return <img style={{width:'87px', height:'87px',marginTop:'-100px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Notification-Withdrawal.svg'></img>;
      } else if (type == 3) {
        return <img style={{width:'87px', height:'87px',marginTop:'-100px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_Transfer.svg'></img>;
      } else if (type == 4) {
        return <img style={{width:'87px', height:'87px',marginTop:'-100px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Notification-Verification.svg'></img>;
      } else if (type == 5) {
        return <img style={{width:'87px', height:'87px',marginTop:'-100px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_Bank.svg'></img>;
      } else if (type == 6) {
        return <img style={{width:'87px', height:'87px',marginTop:'-100px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_Credit+Card.svg'></img>;
      } else if (type == 7) {
        return <img style={{width:'87px', height:'87px',marginTop:'-100px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_Package+Application.svg'></img>;
      } else if (type == 8) {
        return <img style={{width:'87px', height:'87px',marginTop:'-100px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_Leverage.svg'></img>;
      } else if (type == 9) {
        return <img style={{width:'87px', height:'87px',marginTop:'-100px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_PAMM+Subscription.svg'></img>;
      } else if (type == 10) {
        return <img style={{width:'87px', height:'87px',marginTop:'-100px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Icon+Notification_Feedback.svg'></img>;
      } else {
        return <img style={{width:'87px', height:'87px',marginTop:'-100px'}} src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Notification-Deposit.svg'></img>;
      }
    }

    const notifications = (notification) => {
        // deposit notification
        if (notification.type == 0) {
            
            return `${t('notification.NotiDepositDetails',
                        {
                            status: getthestatus(notification?.deposit?.status),
                            amount: notification?.deposit?.transaction?.debit_amount.toFixed(2)
                        }
                    )}`

        } else if
            // withdraw notification
            (notification?.type == 1) {
            
                return `${t('notification.NotiWithdrawalDetails',
                            {
                                status: getthestatus(notification?.withdraw?.status),
                                amount: notification?.withdraw?.transaction?.credit_amount.toFixed(2),
                                method: notification?.withdraw?.payment_method
                            }
                        )}`

        } else if
            // mt5 withdrawal notification
            (notification?.type == 2) {
            
                return ` ${t('notification.NotiMT5WithdrawalDetails',
                            {
                                status: getthestatus(notification?.account_withdraw?.status),
                                amount: notification?.account_withdraw?.amount.toFixed(2),
                                account: notification?.account_withdraw?.account.account_login
                            }
                        )}`

        } else if
            // mt5 transfer notification
            (notification?.type == 3) {
            
                return `${t('notification.NotiMT5TransferDetails',
                            {
                                status: getthestatus(notification?.account_transfer?.status),
                                amount: notification?.account_transfer?.amount.toFixed(2),
                                to_account: notification?.account_transfer?.to_account.account_login,
                                from_account: notification?.account_transfer?.from_account.account_login
                            }
                        )}`


            
        } else if
            // kyc notification
            (notification?.type == 4) {
            
                return `${t('notification.NotiVerificationIs',
                                { 
                                    status: getthestatus(notification?.verification?.status) 
                                }
                            )}`

        } else if
            // bank notification 
            (notification?.type == 5) {
            
                return `${t('notification.NotiBankIs',
                            {   
                                status: getthestatus(notification?.bank?.status) 
                            }  
                        )}`

        } else if
            // bank notification 
            (notification?.type == 6) {
            
                return `${t('notification.NotiCreditCardIs',
                            { 
                                status: getthestatus(notification?.credit_card?.status) 
                            }
                        )}`

        } else if
            // package upgrade notification 
            (notification?.type == 7) {
            
                return `${t('notification.NotiPackageApplicationDetails',
                            {
                                status: getthestatus(notification?.package_application?.status),
                                account: notification?.package_application?.account.account_login,
                                package: notification?.package_application?.package?.name
                            }
                        )}`

        } else if
            // pamm subscription notification
            (notification?.type == 8) {
            
                return `${t('notification.NotiPammSubscriptionIs',
                            { 
                                status: getthestatus(notification?.pamm_subscription?.status),
                                pamm: notification?.pamm_subscription?.pammManager?.name
                            }
                        )}`

            
        } else if
            // leverage notification 
            (notification?.type == 9) {
            
                return `${t('notification.NotiLeverageDetails',
                            {
                                status: getthestatus(notification?.leverage?.status),
                                ori_value: notification?.leverage?.original_value,
                                new_value: notification?.leverage?.new_value,
                                account: notification?.leverage?.account.account_login
                            }
                        )}`

        } else if
            // support ticket notification
            (notification?.type == 10) {
            
                return `${t('notification.NotiDescriptionNewMessage',
                            { 
                                status: getthestatus(notification?.feedback?.status)
                            }
                        )}`

        }

    }

  return (
    <>  
            <Breadcrumb
                title={t('notification.Notification')}
            />
            <Row style={{ marginTop: '15px', justifyContent: 'center', marginLeft: '0', marginRight: '0' }}>

                <Col lg='6'>
                    <Card style={{
                        padding: '15px 20px',
                        boxShadow: '0px 4px 20px #0000000D',
                        borderRadius: '5px',
                        backgroundColor: 'white',
                        marginBottom:'10px'
                    }}>

                        <div
                            style={{
                                overflow:'visible',
                                marginTop: '20px',
                                textAlign:'center'

                            }}
                            id="transactionsDiv"
                        >
                            <div>
                              {getIcon(currentNoti?.type)}
                            </div>
                            <h5>{getthetitle(currentNoti?.type)}</h5>
                              
                            <p style={{ marginBottom: '0px', color: '#9099A3' }}>
                                  {moment(currentNoti?.created_at).format('YYYY-MM-DD HH:mm')}
                            </p>
                        </div>
                            
                    </Card>

                    <Card style={{
                        padding: '15px 20px',
                        boxShadow: '0px 4px 20px #0000000D',
                        borderRadius: '5px',
                        backgroundColor: 'white',
                        textAlign:'center'
                       
                    }}>
                      <p>{notifications(currentNoti)}</p>
                    </Card> 
                </Col>

                </Row>

    </>
  )
}

export default SingleNotification
