import React, { useState, useRef, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { Row, Col, Card, CardHeader, Input } from 'reactstrap'
import moment from 'moment'
import { useSelector } from 'react-redux'
import EventService from '../../../../network/services/event'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'


const BonusTable = () => {
  const { t } = useTranslation()
  const columns = [
    {
      name: t("promotion.Ticket"),
      selector: 'id',
      width: '100px',
      sortable: true
    },
    {
      name: "Nick Name",
      width: '200px',
      selector: 'name',
      sortable: true
    },
    {
      name: t("promotion.Account"),
      selector: 'account_login',
      sortable: true,
      format: (row) => {
        return row.account_login ?? '-'
      }
    },
    {
      name: t("promotion.Date"),
      selector: 'created_at',
      sortable: true,
      width: '200px',
      format: (row) => {
        return moment(row.created_at).format('yyyy-MM-DD hh:mmA')
      }
    },
    {
      name: t("promotion.Status"),
      selector: 'status',
      sortable: true,
      format: (row) => {
        switch (row.status) {
          case 0:
            return t("promotion.Pending")
          case 1:
            return t("promotion.Success")
          case 2:
            return t("promotion.Failed")
          default:
            return '-'
        }
      }
    },
    {
      name: t("promotion.Reason"),
      selector: 'reason',
      width: '400px',
      sortable: true
    }
  ]
  const [myRegistrationRecord, setMyRegistrationRecord] = useState([])
  const student_event_status = useSelector((state) => state.event)
  const [isBusy, setIsBusy] = useState(false)
  const [total, setTotal] = useState(0)
  // const [data, setData] = useState(student_event_status?.user_student_competition_profile?.submitted_registrations)
  const wrapperRef = useRef()

  const fetchMyRegistrationRecord = async () => {
    // Hardcode competition id
    const record = await EventService.getMyCompetition({
      competition_id: 1
    })
    setMyRegistrationRecord(record?.submitted_registrations)
  }

  useEffect(() => {
    // Hardcode competition id
    fetchMyRegistrationRecord()

  }, [])


  return (
    <div className="product-wrapper" ref={wrapperRef} style={{ padding: '0px', marginBottom: '30px' }}>
      <div className="product-grid" >

        <div className="product-wrapper-grid" style={{ marginTop: 16 }}>
          <DataTable
            noHeader
            data={myRegistrationRecord}
            columns={columns}
            striped={true}
            center={true}
            // pagination
            // paginationComponentOptions={{ noRowsPerPage: true }}
            // paginationServer
            // paginationTotalRows={total}
            progressPending={isBusy}
          />
        </div>
      </div>
    </div>
  )
}

export default BonusTable
