import React, { useState } from 'react'
import { Form, FormGroup, Input, Label, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSelector } from "react-redux"

import AuthService from '../../network/services/auth'
import UserService from '../../network/services/user'

import styled from 'styled-components'
import _ from "lodash"

import { useTranslation } from 'react-i18next'

import './index.scss'

import LanguageIcon from '../../language.png'

const LoginButton = styled.button` 
  margin-right: 37px;
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 5px 0;
`

const SignInForm = ({ logo }) => {
  const { register, handleSubmit, errors } = useForm()
  const [loginError, setLoginError] = useState(null)
  const { t , i18n } = useTranslation()
  const userVerificationStatus = useSelector((state) => _.last(state.user.verifications))

  const submitAuth = async (data) => {
    try {
      const result = await AuthService.login({
        email: data.email,
        password: data.password
      })

      if (result.success) {
        localStorage.setItem('token', result.data.jwt.token)
        localStorage.setItem('user', JSON.stringify(result.data.user))

        const updatedUser = await UserService.getMyself()

        if (userVerificationStatus?.status === 1 || userVerificationStatus?.status === 0) {
          window.location.href = '/dashboard'
        }
        else if (updatedUser?.user?.is_funnel_completed === false &&
          updatedUser?.user?.is_profile_completed !== true &&
          updatedUser?.user?.is_account_created === false) {
            //removed verify for new registration flow
          window.location.href = '/demo_get'
          // window.location.href = '/dashboard'
        }
        else if (updatedUser?.user?.is_funnel_completed === false &&
          updatedUser?.user?.is_profile_completed !== true &&
          updatedUser?.user?.is_account_created === true) {
            //removed verify for new registration flow
          // window.location.href = '/demo_get'
          window.location.href = '/dashboard'
        }
        // else if (updatedUser?.user?.verification_status === null) {
        //   window.location.href = '/verify-KYC'
        // }
        else if (updatedUser?.verification?.status === 1) {
          window.location.reload()
        } else {
          window.location.href = '/dashboard'
        }
      }
    } catch (error) {
      if (error.message === 'E_INVALID_AUTH_PASSWORD' || error.message === 'E_INVALID_AUTH_UID') {
        setLoginError(t("auth.InvalidCredential"))
      } else {
        setLoginError(error.message ?? t("auth.pleseTryAgain"))
      }
    }
  }

  return (
    <>
      <Row>
        <Col>
          <div className="mb-3" style={{ position:'fixed', top:'20px', left:'20px',zIndex: '3' }}>
            {logo?.value && (
              <img
                src={logo.value}
                // src={newLogo}
                alt="logo"
                style={{ maxHeight: 175, maxWidth: 175 }}
              />
            )}
          </div>


          <div style={{position:'fixed', top:'-85px', right:'-85px', width:'266px', height:'266px', backgroundColor:'#F0F0F0', borderRadius:'50%'}}>
          </div>
          

          <Row style={{justifyContent:'center',zIndex:'6',marginTop:'25px'}}>
            <div className='d-none d-lg-block' width='100%' style={{backgroundImage:"url(https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/HomeLoginBG.svg)",width:'460px', height:'310px',zIndex:'4'}}>
                  {/* <img src={HomeLoginBG} alt='HomeLoginlBG'>
                    
                  </img> */}
                  <p style={{zIndex:'3', color:'white', textAlign:'left',width:'320px',height:'200px', fontSize:'28px',margin:'50px'}}>
                    {t('auth.join')}
                  </p>
              </div>
          <Form
            id="login-form"
            className="theme-form cont"
            onSubmit={handleSubmit(submitAuth)}
            style={{margin:'0px', zIndex:'5',minHeight:'310px', borderRadius: '0px 0px 50px 0px',border:'none'}}
          >

            <h4 style={{ color: '#0f1430' }}>{t("auth.login")}</h4>

            <Label className="col-form-label pt-0">{t("auth.emailAddress")}</Label>

            <FormGroup style={{ display: "flex", backgroundColor: 'transparent', marginBottom: '10px' }}>
              <Input
                id="input-form"
                className="form-control"
                placeholder={t("auth.emailAddress")}
                type="text"
                required=""
                name="email"
                innerRef={register({ required: true })}
                style={{ backgroundColor: "#f8f8f8" }}
              />
            </FormGroup>
            <span style={{ color: 'red' }}>{errors.email && t("auth.emailIsRequire")}</span>

            <Label className="col-form-label">{t("auth.password")}</Label>

            <FormGroup style={{ display: "flex", marginBottom: '15px' }}>
              <Input
                id="input-form"
                className="form-control"
                placeholder={t("auth.password")}
                type="password"
                required=""
                name="password"
                innerRef={register({ required: true })}
                style={{ backgroundColor: "#f8f8f8" }}
              />
            </FormGroup>
            <span style={{ color: 'red' }}>{errors.password && t("auth.passIsRequire")}</span>
            {loginError != null && <span style={{ color: 'red' }}>{loginError}</span>}

            <FormGroup className=" mb-0" style={{ textAlign: 'right' }}>
              <Link to={`${process.env.PUBLIC_URL}/forgot_password`} href="#javascript">
                <p style={{ color: '#f9b700', textAlign: 'right' }}>{t("auth.forgotPassword")}</p>
              </Link>
              <LoginButton id='login_button' className="w-100" color="primary" style={{ backgroundColor: '#f9b700' }} >{t("auth.login")}</LoginButton>
            </FormGroup>

            <div style={{ padding: 10 }} />

            <p style={{ color: '#898989' }}>{t("auth.dontHaveAccount")}
              <Link to={`${process.env.PUBLIC_URL}/sign_up`} href="#javascript">
                <span className="ml-1" style={{ color: '#f9b700' }}>{t("auth.createAccount")}</span>
              </Link>
            </p>

          </Form>
          <div style={{position:'fixed', bottom:'-180px', left:'-128px', width:'428px', height:'428px', backgroundColor:'#EFB935', borderRadius:'50%'}}>

          </div>
          <div style={{position:'fixed',bottom:'10px',left:'20px',zIndex:'1',fontSize:'16px',}}>
            <p style={{color:'black'}}>support@tradehall.co</p>
          </div>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default SignInForm
