import React, { useState, useEffect } from "react";

const DarkMode = () => {
  const [moonlight, setMoonlight] = useState(false);

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light);
      document.body.className = "light";
      localStorage.setItem("layout_version", "light");
    } else {
      setMoonlight(!light);
      document.body.className = "dark-only";
      localStorage.setItem("layout_version", "dark-only");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
  }, []);

  return (
    <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
      <i className={`fa ${moonlight ? "fa-lightbulb-o" : "fa-moon-o"}`}></i>
    </div>
  );
};

export default DarkMode;
