import client from '../request'

const get = (id) => {
  return client.get(`/promotions/${id}`)
}

const create = (id, data) => {
  return client.post(`/promotions/${id}`, data)
}

export default {
  get,
  create
}
