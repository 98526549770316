import { TRIGGER_SEARCH, TRIGGER_SIDEBAR } from "../actionTypes";

const initial_state = {
  isSearching: false,
  isSidebarClosed: true,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case TRIGGER_SEARCH:
      return { ...state, isSearching: !state.isSearching };

    case TRIGGER_SIDEBAR:
      return { ...state, isSidebarClosed: !state.isSidebarClosed };

    default:
      return { ...state };
  }
};
