import React from "react"
import { LogOut, User } from "react-feather"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

import Auth from "../../../network/services/auth"
import { Trans, useTranslation } from 'react-i18next'

const Profile = () => {
  const currentUser = useSelector((state) => state.user.user)
  const logOut = () => {
    Auth.logout()
  }

  const { t, i18n } = useTranslation()

  return (
    <>
      <div className="media profile-media">
        <div className="media-body">
          <span>{currentUser?.name ?? "Name"}</span>
          <p className="mb-0 font-roboto">
            {currentUser?.email ?? t("header.Email")} <i className="middle fa fa-angle-down"></i>
          </p>
        </div>
      </div>
      <ul className="profile-dropdown onhover-show-div">
        <li>
          <Link to={`${process.env.PUBLIC_URL}/account`} href="#javascript">
            <User />
            <span>{t("header.account")}</span>
          </Link>
        </li>
        <li onClick={logOut}>
          <LogOut />
          <span>{t("header.logout")}</span>
        </li>
      </ul>
    </>
  )
}

export default Profile
