import React from "react";
import { Card, CardBody } from "reactstrap";
import IncomeTable from "./table";
import { useTranslation } from 'react-i18next'

const Income = () => {
  const { t } = useTranslation()
  return (
    <Card>
      <CardBody>
        <h5>{t("partner.noIncomeData")}</h5>
      </CardBody>
    </Card>
  );
};

export default Income;
