import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Button, Spinner, Badge } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import _ from 'lodash'
import styled from 'styled-components'

import AddressTable from './address_table'
import IdentityTable from './identity_table'

import UserService from '../../../network/services/user'
import VerificationService from '../../../network/services/verification'
import FilesService from '../../../network/services/files'
import { useTranslation } from 'react-i18next'

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    justify-content: flex-start;

    > button {
      margin-top: 8px;
    }
  }
`

const ResponsiveCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
`

const ResponsiveButton = styled(Button)`
  background-color: #963737 !important;
  border-color: #b35855 !important;
`
const ResponsiveParagraph = styled.p`
  margin: 0;

  @media (max-width: 575px) {
    margin-bottom: 8px;
  }
`

const Verification = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isBusy, setIsBusy] = useState(false)
  const [reupload, setReupload] = useState(false)
  const profile = useSelector((state) => state.user.profile)
  const userVerification = useSelector((state) => _.last(state.user.verifications))
  const verification = useSelector((state) => state.verification)

  const handleVerification = async (e) => {
    // console.log(verification)

    if (verification.idFront?.file == null) {
      console.log('missing id front')
      dispatch({ type: 'SET_ID_FRONT', idFront: { error: 'Please select one' } })
      return
    }

    if (verification.idBack?.file == null) {
      console.log('missing id back')
      dispatch({ type: 'SET_ID_BACK', idBack: { error: 'Please select one' } })
      return
    }

    if (verification.addressFront?.file == null) {
      console.log('missing address front')
      dispatch({ type: 'SET_ADDRESS_FRONT', addressFront: { error: 'Please select one' } })
      return
    }

    setIsBusy(true)

    try {
      const idFrontRes = await FilesService.create(verification.idFront?.file)
      const idBackRes = await FilesService.create(verification.idBack?.file)
      const addressFrontRes = await FilesService.create(verification.addressFront?.file)
      // const addressBackRes = await FilesService.create(verification.addressBack)

      const result = await VerificationService.create({
        type: verification.activeTab,
        id_front: idFrontRes.data,
        id_back: idBackRes.data,
        address_front: addressFrontRes.data,
        // debug
        address_back: addressFrontRes.data
      })

      // console.log(result)

      if (result?.verification) {
        SweetAlert.fire({
          title: t("user.ThankYouYourAccount"),
          icon: 'success'
        }).then(() => {
          navigate(`${process.env.PUBLIC_URL}/dashboard`)
        })
        setIsBusy(false)
      }
    } catch (error) {
      setIsBusy(false)
      console.log(error)
      // const errorCode = error.code
      // const errorTranslate = error.translate_params

      if (error.message === 'file - File size should be less than 10MB'
        && sessionStorage.getItem('language') === 'en') {
        SweetAlert.fire({
          // title: t(errorCode, errorTranslate),
          title: 'file - File size should be less than 10MB',
          icon: 'error'
        })
      } else if (error.message === 'file - File size should be less than 10MB'
        && sessionStorage.getItem('language') === 'zh') {
        SweetAlert.fire({
          // title: t(errorCode, errorTranslate),
          title: '文件 - 文件不能超过10mb',
          icon: 'error'
        })
      } else {
        SweetAlert.fire({
          // title: t(errorCode, errorTranslate),
          title: error.message,
          icon: 'error'
        })
      }


    }
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  const init = async () => {
    const updatedUser = await UserService.getMyself()
    if (updatedUser.verifications) {
      dispatch({ type: 'SET_VERIFICATIONS', verifications: updatedUser.verifications })
    }
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  // exist
  if (profile != null && !_.isEmpty(profile)) {
    return (
      <>

        {userVerification?.status === 0 && (
          <Card>
            <CardBody
              className="p-4"
              style={{ backgroundColor: '#f9b600', borderRadius: '15px' }}
            >
              <ResponsiveCard>
                <ResponsiveParagraph style={{ color: 'black', fontWeight: 'bold' }}>
                  {t("dashboard.yourRecentApplicationWasPending")}
                </ResponsiveParagraph>
              </ResponsiveCard>

            </CardBody>
          </Card>
        )}
        {userVerification?.status !== 1 &&
          userVerification?.status !== 0 && (
            <Card>
              <CardBody style={{ backgroundColor: '#ffc4c4', borderRadius: '15px' }}>
                <StyledRow>
                  <p style={{ margin: 0, color: '#963737' }}>
                    <strong>
                      {t("user.YourProfileIsntVerified")}
                    </strong>
                  </p>
                  {userVerification?.status === 3 && !reupload && (
                    <ResponsiveButton color="primary" onClick={() => setReupload(true)}>
                      {t("user.Reupload")}
                    </ResponsiveButton>
                  )}
                </StyledRow>

                {userVerification?.status === 3 && userVerification?.reason && (
                  <>
                    <ResponsiveParagraph style={{ color: '#ab0505' }}>
                      {t("user.YourRecentApplication")}
                    </ResponsiveParagraph>
                    <ResponsiveParagraph
                      style={{ color: '#ab0505' }}
                    >{`${t("user.Reason")}: ${userVerification?.reason}`}</ResponsiveParagraph>
                  </>
                )}
              </CardBody>
            </Card>
          )}

        {/* {userVerification?.status === 3 && userVerification?.reason && (
          <Card>
            <CardBody>
              <h5>Status: Rejected</h5>
              <p style={{ color: 'red', fontSize: 18 }}>{`Reason: ${userVerification?.reason}`}</p>
            </CardBody>
          </Card>
        )} */}

        {userVerification?.status !== 1
          ?
          <>
            <Card>
              <CardHeader>
                <h5>{t("user.Identification")}</h5>
                <div className="p-1" />
                <p style={{ margin: 0 }}>{t("user.PassportNationalID")}</p>
                <div className="p-0" />
                <p style={{ margin: 0 }}>
                  {t("user.PleaseUploadIdentificationDocument")}
                </p>
              </CardHeader>
              <CardBody>
                <IdentityTable reupload={reupload} />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5>{t("user.ProofOfAddress")}</h5>
                <div className="p-1" />
                <p style={{ margin: 0 }}>
                  {t("user.ProofOfAddressThatIsNotOlderThan")}<strong>180 {t("user.days")}</strong> {t("user.utilityBillBankStatementDrivingLicense")}
                </p>
              </CardHeader>
              <CardBody>
                <AddressTable reupload={reupload} />
              </CardBody>
            </Card>
          </>
          :
          //Verify process is success
          <>

            <Card>
              <CardHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h5 style={{ marginRight: '10px' }}>{t("user.Identification")}</h5>
                  <Badge color="success">Verified</Badge>
                </div>
                <div className="p-1" />
                <p style={{ margin: 0 }}>{t("user.PassportNationalID")}</p>
                <div className="p-0" />

                <p style={{ color: 'green' }}>
                  <strong>Your identification profile has been verified!</strong>
                </p>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h5 style={{ marginRight: '10px' }}>{t("user.ProofOfAddress")}</h5>
                  <Badge color="success">Verified</Badge>
                </div>
                <div className="p-1" />
                <p style={{ margin: 0 }}>
                  {t("user.ProofOfAddressThatIsNotOlderThan")}<strong> 180 {t("user.days")}</strong> {t("user.utilityBillBankStatementDrivingLicense")}
                </p>

                <p style={{ color: 'green' }}>
                  <strong>Your proof of address has been verified!</strong>
                </p>
              </CardHeader>
            </Card>

          </>
        }


        {(userVerification == null || reupload) && (
          <Card>
            <CardBody style={{ textAlign: 'center' }}>
              <h6>
                {t("user.ByClickingConfirmProceedYouConfirmThat")}
              </h6>
              <Button color="primary" onClick={handleVerification} disabled={isBusy}>
                {isBusy ? t("user.Loading") : t("user.Submit")}
              </Button>
            </CardBody>
          </Card>
        )}
      </>
    )
  }

  // new
  if (profile != null && _.isEmpty(profile)) {
    return (
      <Card>
        <CardBody>
          {t("user.PleaseCompleteThe")} <a href="#details">{t("user.MyDetailsVerificationProcess")}</a> {t("user.toContinueTheProcess")}
        </CardBody>
      </Card>
    )
  }

  // loading
  return (
    <Card>
      <CardBody style={{ textAlign: 'center' }}>
        <Spinner color="warning" />
      </CardBody>
    </Card>
  )
}

export default Verification
