import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import {  useDispatch } from 'react-redux'
import _ from 'lodash'

import Breadcrumb from '../../layout/breadcrumb'
import LandingPage from './landing_page'
import Statistic from './statistic'

import { useTranslation } from 'react-i18next'
import WalletService from '../../network/services/wallet'

const Partner = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  
  const tabs = {
    beneficial: t("referralFriend.HowItWork"),
    statistic: t("referralFriend.Statistic"),
  }
  
  const [activeTab, setActiveTab] = useState('beneficial')


  const init = async () => {
    const walletResponse = await WalletService.get()
    dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })
    dispatch({
      type: 'SET_BALANCE',
      balance: walletResponse.wallet_balance
    })
    dispatch({
      type: 'SET_TOTAL_DEPOSIT',
      totalDeposit: walletResponse.total_deposit
    })
    dispatch({
      type: 'SET_TOTAL_WITHDRAWAL',
      totalWithdrawal: walletResponse.total_withdrawal
    })
    dispatch({
      type: 'SET_TOTAL_ACCOUNT_DEPOSIT',
      totalAccountDeposit: walletResponse.total_account_deposit
    })
    dispatch({
      type: 'SET_TOTAL_ACCOUNT_WITHDRAWAL',
      totalAccountWithdraw: walletResponse.total_account_withdraw
    })


  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Breadcrumb parent={t("referralFriend.inviteFriend")} title={tabs[activeTab]} />
      <Container>
        <Col lg='12' className="d-none d-md-block d-lg-block d-xl-block" style={{ padding: '0px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', borderRadius:'15px' }}>
          <Card>
            <Row>
              <Col>
                <Nav tabs className="border-tab" style={{ marginBottom: '0px' }}>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'beneficial' ? 'active' : ''}
                      onClick={() => setActiveTab('beneficial')}
                    >
                      <i className="fa fa-building"></i>
                      {tabs.beneficial}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'statistic' ? 'active' : ''}
                      onClick={() => setActiveTab('statistic')}
                    >
                      <i className="fa fa-flag"></i>
                      {tabs.statistic}
                    </NavLink>
                  </NavItem>

                </Nav>
              </Col>
            </Row>
          </Card>
        </Col>

        <Row>
          <Col sm="12" lg="4" xl="3" className="project-list d-md-none d-lg-none d-xl-none">
            <Card>
              <Row>
                <Col>
                  <Nav tabs className="border-tab">
                    <NavItem>
                      <NavLink
                        className={activeTab === 'beneficial' ? 'active' : ''}
                        onClick={() => setActiveTab('beneficial')}
                      >
                        <i className="fa fa-building"></i>
                        {tabs.beneficial}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'statistic' ? 'active' : ''}
                        onClick={() => setActiveTab('statistic')}
                      >
                        <i className="fa fa-flag"></i>
                        {tabs.statistic}
                      </NavLink>
                    </NavItem>
                    
                  </Nav>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col sm="12" lg="12" xl="12">
            <TabContent activeTab={activeTab}>

            <TabPane tabId="beneficial">
                <LandingPage />
              </TabPane>
              <TabPane tabId="statistic">
                <Statistic />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Partner
