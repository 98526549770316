import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useSelector } from 'react-redux'
import SweetAlert from 'sweetalert2'

import AccountService from '../../../network/services/account'
import { useTranslation } from 'react-i18next'

const ChangeLeverage = ({ setShowModal }) => {
  const { register, handleSubmit, errors } = useForm()
  const account = useSelector((state) => state.dashboard.account)
  const user = useSelector((state) => state.user.user)
  const { t } = useTranslation()

  const handleChange = async (data) => {
    try {
      if (data !== '' && account != null) {
        const result = await AccountService.changeLeverage(account.id, data)

        // success
        if (result?.code === 'leverage.request.submitted') {
          SweetAlert.fire({
            title: t("common.changeSubmmitedSuccess"),
            text: t("common.requestReview"),
            icon: 'success'
          })
          setShowModal(false)
        }
      } else {
        errors.showMessages()
      }
    } catch (error) {
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }

  // leverage
  const leverageOptions = useMemo(() => {
    let available = []
    let maxLeverage = account.plan.leverage
    if (maxLeverage > 0) {
      if (maxLeverage >= 10) {
        available.push(10)
      }

      if (maxLeverage >= 50) {
        available.push(50)
      }

      if (maxLeverage >= 100) {
        available.push(100)
      }

      if (maxLeverage >= 200) {
        available.push(200)
      }

      if (maxLeverage >= 500) {
        available.push(500)
      }

      if (maxLeverage >= 1000) {
        available.push(1000)
      }
    }

    return available
  }, [account])

  return (
    <div>
      {user.is_asic === 0 ?
        <p>
          {t("common.leverageForThisAccount", {accountLeverage: account?.leverage ?? '-'})}
          {/* Your leverage for this account is 1:{account?.leverage ?? '-'}. If you need to change the
        leverage, Your account should have no open orders. */}
      </p> :
        <p>
          {t("common.kindlySendUs")}
        </p>
      }

      <Form className="theme-form" onSubmit={handleSubmit(handleChange)}>
        <FormGroup>
          <Label>{t("common.MT5AccountNumber")}</Label>
          <Input
            className="form-control"
            type="text"
            name="login"
            defaultValue={account?.account_login}
            readOnly
          />
        </FormGroup>

        {user.is_asic === 0 ?
          <FormGroup>
            <Label>{t("common.leverage")}</Label>
            <Input
              type="select"
              name="leverage"
              className="form-control digits"
              innerRef={register({ required: true })}
            >
              {leverageOptions.map((leverage) => {
                return <option value={leverage}>{leverage}</option>
              })}
            </Input>
          </FormGroup>
          :
          ''
        }

        {user.is_asic === 0 ?
          <FormGroup className="mb-0" style={{ float: 'right' }}>
            <Button color="primary">{t("common.confirm")}</Button>
          </FormGroup>
          :
          ''
        }

      </Form>
    </div>
  )
}

export default ChangeLeverage
