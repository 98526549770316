import {
  SET_DEPOSIT_WALLET_ID,
  SET_DEPOSIT_WALLET_METHOD,
  SET_DEPOSIT_MT5_ACCOUNT,
  SET_DEPOSIT_MT5_WALLET,
  SET_DEPOSIT_MT5_ACCOUNT_TYPE,
  SET_DEPOSIT_MT5_ACCOUNT_PACKAGE_ID
} from '../actionTypes'

const initial_state = {
  wallet: {
    id: null,
    method: { id: null, handle: null }
  },
  mt5: {
    account: null,
    wallet: null,
    account_type: null,
    account_package_id: null
  }
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_DEPOSIT_WALLET_ID:
      return { ...state, wallet: { ...state.wallet, id: action.id } }

    case SET_DEPOSIT_WALLET_METHOD:
      return { ...state, wallet: { ...state.wallet, method: action.method } }

    case SET_DEPOSIT_MT5_ACCOUNT:
      return { ...state, mt5: { ...state.mt5, account: action.account } }

    case SET_DEPOSIT_MT5_ACCOUNT_TYPE:
      return { ...state, mt5: { ...state.mt5, account_type: action.account_type } }

    case SET_DEPOSIT_MT5_ACCOUNT_PACKAGE_ID:
      return { ...state, mt5: { ...state.mt5, account_package_id: action.account_package_id } }

    case SET_DEPOSIT_MT5_WALLET:
      return { ...state, mt5: { ...state.mt5, wallet: action.wallet } }

    default:
      return { ...state }
  }
}
