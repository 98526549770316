import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Card, Col, Row, FormGroup, Input, Progress, Button, CardHeader, Spinner, Tooltip, Modal, ModalHeader, ModalBody, ModalFooter, CardBody } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'
import Breadcrumb from '../../../layout/breadcrumb'
import AccountService from "../../../network/services/account"
import PammService from '../../../network/services/pammUploadFile'
import SweetAlert from 'sweetalert2'

import PammManagerStatistic from '../../../network/services/pammManagerStatistic'
import { useDispatch, useSelector } from 'react-redux'

import FilesService from '../../../network/services/files'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { FastForward, HelpCircle } from 'react-feather'

import "intro.js/introjs.css";
import { Steps, Hints } from 'intro.js-react';

import LPOA from './lpoa'
import PammAgreement from './pammAgree'

import './progress.scss'

const PammFollow = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [accounts, setAccounts] = useState()
    const [error, setError] = useState(null)
    const { register, handleSubmit, errors, reset } = useForm()
    const [selectedAccount, setSelectedAccount] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const user = useSelector((state) => state.user)

    const userFullName = `${user?.user?.first_name ?? ''} ${user?.user?.last_name ?? ''}`



    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })

    const [pammManagerDetails, setPammManagerDetails] = useState([])
    const [nextSettlementTooltipOpen, setNextSettlementTooltipOpen] = useState(false);
    const nextSettlementToggle = () => setNextSettlementTooltipOpen(!nextSettlementTooltipOpen);

    const dispatch = useDispatch()
    const lpoa = useSelector((state) => state.pamm.lpoa)
    const op = useSelector((state) => state.pamm.op)
    const pamm = useSelector((state) => state.pamm)

    const [isSending, setIsSending] = useState(false)

    const { id: pammId } = useParams()

    const fetchPammStats = async () => {
        const result = await PammManagerStatistic.get(id)
        setPammManagerDetails(result.details)
    }

    const init = async () => {
        // live accounts
        setIsLoading(true)
        const result = await AccountService.getIdle()
        setAccounts(result.idle)
        setIsLoading(false)
    }

    useEffect(() => {
        init()
        // eslint-disable-next-line
        fetchPammStats()
    }, [])

    const [openButton, SetOpenButton] = useState(false)



    const [stepsEnabled, setStepsEnabled] = useState(true)
    const steps = [
        {
            element: '#select-pamm-account',
            intro: t("pamm.ThisIsTheForm"),
        },
        {
            element: '#scroll-bottom',
            intro: t("pamm.PleaseMakeSureYouRead")
        },
        {
            element: '#name-input',
            intro: t("pamm.MakeSureYouTyped")
        }

    ]

    const onSubmit = async data => {

        setIsSending(true)

        const accountDetails = accounts.filter(account => account?.account_login == selectedAccount)
        const accountCredit = await AccountService.checkAccountCredit(accountDetails[0]?.id)

        setIsSending(false)

        if (accountCredit?.credit_balance !== 0) {
            // If user account contain credit

            SweetAlert.fire({
                title: `#${accountDetails[0]?.account_login} ${t("financial.contain")} $${accountCredit?.credit_balance} ${t("financial.credit")} `,
                html: `${t("financial.Type")} "<strong>${t("financial.AGREE")}</strong>" ${t("financial.toConfirm")} 
                          <br/>
                          <p style="color:red;margin-top:20px;">${t("financial.OnceYouConfirm")}  <strong>${t("financial.VOIDED")}</strong> ${t("financial.partiallyOrEntirely")} </p>
                          
                          <div>
                            <p style="margin-bottom:0px;"
                            >*${t("financial.KindlyTakeNote")}*</p>
                            <p>
                                1. ${t("financial.ByTypingAGREE")}
                                <br/>
                                2. ${t("financial.TheCreditWillOnly")}
                                <br/>
                                3. ${t("financial.IfYouHave")}<a href='/support'>${t("financial.customerService")}</a>. 

                            </p>
                          </div>
                          `,
                input: 'text',
                inputValue: '',
                inputPlaceholder: '',
                inputValidator: (result) => {
                    if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                        return t("financial.PleaseEnterThe")
                    }
                },
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t("financial.confirm"),
                cancelButtonText: t("financial.cancel"),
                reverseButtons: true,

            }).then(async (result) => {
                if (result.value) {
                    SweetAlert.fire({
                        title: `Step 3 : Final Agreement`,
                        html: `
                        
                        <div class="progress">
                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="3" aria-valuemin="0" aria-valuemax="3" style="width: 100%;"></div>
                         </div>
        
                        <div class='text-left' style="margin-top:20px;">
                            
                        <p>
                            ${t("pamm.I")}, ${userFullName}, ${t("pamm.understandAndAgree")} ...
                        </p>
        
                        <p>1. ${t("pamm.IfYouDecide")} <strong>${t("pamm.withdraw")}</strong> ${t("pamm.or")} <strong>${t("pamm.unsubscribe")}</strong> ${t("pamm.BesideSettlement")} <span style="color:red; font-weight: bold;">${t("pamm.deduct")} ${pammManagerDetails.penalty_percent}%</span> ${t("pamm.fromYourFund")}</p>
                        <p>2. ${t("pamm.ThisSettlementWillHappenOn")} <span style="font-weight: bold;">${settlementDate} (GMT +0) 00:00:00</span></p>
                        <p>3. ${t("pamm.TheInvestorIsNotAllowed")}
                        <br /><br />
                        ${t("pamm.TheInvestorWillBeAssigned")} <span style="color: green; font-weight: bold;">${t("pamm.afterTheSettlementDate")}</span> ${t("pamm.AsBufferPeriod")}
                        <br /><br />
                        ${t("pamm.ForExample")}
                        ${t("pamm.IfTheSettlementDateIsOn")} <span style="font-weight: bold;">${settlementDate} (GMT +0) 00:00:00</span>${t("pamm.theInvestorIsNotAllowed")} <span style="font-weight: bold;">${settlementDateTomorrow1} (GMT +0) 00:00:00</span> ${t("pamm.to")} <span style="font-weight: bold;">${settlementDateTomorrow2} (GMT +0) 23:59:59</span>${t("pamm.WithoutAnyPenalty")}
                        <p>
                            
                            <div class='text-left'>
                                <p style="margin-bottom:0px;">Learn More About <a href="${pammManagerDetails.lpoa_url}" target="_blank">LPOA Agreement</a></p>
                                <p>Learn More About <a href="${pammManagerDetails.op_url}" target="_blank">PAMM Agreement</a></p>
                            </div>
        
                            <div class='text-left' style="margin-top:20px; color:red;">
                                <p style="margin-bottom:0px;">*${t("financial.KindlyTakeNote")}</p>
                                <p>1. ${t("pamm.YouWillReceiveAnPDF")}</p>
                            </div>
        
                            <div class='text-center'  style="margin-top:20px;">
                                ${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
                            </div>
                        </div>
                        </div>
                        `,
                        input: 'text',
                        inputValue: '',
                        inputPlaceholder: '',
                        inputValidator: (result) => {
                            if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                                return t("financial.PleaseEnterThe")
                            }
                        },

                        showCancelButton: true,
                        confirmButtonText: t('financial.confirm'),
                        cancelButtonText: t('financial.cancel'),
                        reverseButtons: true
                    }).then(async (result) => {
                        if (result?.value) {
                            try {
                                setIsSending(true)
                                if (data !== '') {
                                    const result = await PammService.create(pammId, {
                                        account_login: selectedAccount,
                                        is_voiding_credit: true
                                    })

                                    SweetAlert.fire({
                                        title: t("pamm.thankYouForYourApplication"),
                                        html: `${t("pamm.pleaseCheckYourApplicationStatus")}`,
                                        icon: 'success'
                                    }).then(function () {
                                        window.location.href = `/mysubscription`;
                                    })

                                    if (result.user) {
                                        reset()
                                        setError(null)

                                    }
                                    setIsSending(false)
                                } else {
                                    setIsSending(false)
                                    errors.showMessages()
                                }

                            } catch (error) {
                                // SweetAlert.fire({
                                //     title: `${error.message}`,
                                //     icon: "error"
                                // })
                                const errorCode = error.code
                                const errorTranslate = error.translate_params
                                SweetAlert.fire({
                                    title: t(errorCode, errorTranslate),
                                    icon: 'error'
                                })
                                setError(error.message)
                                setIsSending(false)
                            }
                        }
                    })
                }
            })
        } else {
            // If user dont have credit, normal case

            SweetAlert.fire({
                title: `Step 3 : Final Agreement`,
                html: `
                
                <div class="progress">
                    <div class="progress-bar bg-success" role="progressbar" aria-valuenow="3" aria-valuemin="0" aria-valuemax="3" style="width: 100%;"></div>
                 </div>

                <div class='text-left' style="margin-top:20px;">
                    
                <p>
                    ${t("pamm.I")}, ${userFullName}, ${t("pamm.understandAndAgree")} ...
                </p>

                <p>1. ${t("pamm.IfYouDecide")} <strong>${t("pamm.withdraw")}</strong> ${t("pamm.or")} <strong>${t("pamm.unsubscribe")}</strong> ${t("pamm.BesideSettlement")} <span style="color:red; font-weight: bold;">${t("pamm.deduct")} ${pammManagerDetails.penalty_percent}%</span> ${t("pamm.fromYourFund")}</p>
                <p>2. ${t("pamm.ThisSettlementWillHappenOn")} <span style="font-weight: bold;">${settlementDate} (GMT +0) 00:00:00</span></p>
                <p>3. ${t("pamm.TheInvestorIsNotAllowed")}
                <br /><br />
                ${t("pamm.TheInvestorWillBeAssigned")} <span style="color: green; font-weight: bold;">${t("pamm.afterTheSettlementDate")}</span> ${t("pamm.AsBufferPeriod")}
                <br /><br />
                ${t("pamm.ForExample")}
                ${t("pamm.IfTheSettlementDateIsOn")} <span style="font-weight: bold;">${settlementDate} (GMT +0) 00:00:00</span>${t("pamm.theInvestorIsNotAllowed")} <span style="font-weight: bold;">${settlementDateTomorrow1} (GMT +0) 00:00:00</span> ${t("pamm.to")} <span style="font-weight: bold;">${settlementDateTomorrow2} (GMT +0) 23:59:59</span>${t("pamm.WithoutAnyPenalty")}
                <p>
                    
                    <div class='text-left'>
                        <p style="margin-bottom:0px;">Learn More About <a href="${pammManagerDetails.lpoa_url}" target="_blank">LPOA Agreement</a></p>
                        <p>Learn More About <a href="${pammManagerDetails.op_url}" target="_blank">PAMM Agreement</a></p>
                    </div>

                    <div class='text-left' style="margin-top:20px; color:red;">
                        <p style="margin-bottom:0px;">*${t("financial.KindlyTakeNote")}</p>
                        <p>1. ${t("pamm.YouWillReceiveAnPDF")}</p>
                    </div>

                    <div class='text-center'  style="margin-top:20px;">
                        ${t("financial.Type")} <strong>"${t("financial.AGREE")}"</strong> ${t("financial.toConfirm")}
                    </div>
                </div>
                </div>
                `,
                input: 'text',
                inputValue: '',
                inputPlaceholder: '',
                inputValidator: (result) => {
                    if (result.toLocaleLowerCase() !== 'agree' && result !== '同意') {
                        return t("financial.PleaseEnterThe")
                    }
                },

                showCancelButton: true,
                confirmButtonText: t('financial.confirm'),
                cancelButtonText: t('financial.cancel'),
                reverseButtons: true
            }).then(async (result) => {

                if (result?.value) {
                    try {
                        setIsSending(true)
                        if (data !== '') {
                            const result = await PammService.create(pammId, {
                                account_login: selectedAccount,
                                is_voiding_credit: false
                            })

                            SweetAlert.fire({
                                title: t("pamm.thankYouForYourApplication"),
                                html: `${t("pamm.pleaseCheckYourApplicationStatus")}`,
                                icon: 'success'
                            }).then(function () {
                                window.location.href = `/mysubscription`;
                            })

                            if (result.user) {
                                reset()
                                setError(null)

                            }
                            setIsSending(false)
                        } else {
                            setIsSending(false)
                            errors.showMessages()
                        }

                    } catch (error) {
                        // SweetAlert.fire({
                        //     title: `${error.message}`,
                        //     icon: "error"
                        // })
                        const errorCode = error.code
                        const errorTranslate = error.translate_params
                        SweetAlert.fire({
                            title: t(errorCode, errorTranslate),
                            icon: 'error'
                        })
                        setError(error.message)
                        setIsSending(false)
                    }
                }
            })
        }


    }

    const [userTypeName, setUserTypeName] = useState('')
    const [errorTypeName, setErrorTypeName] = useState(false)
    const [errorSelectedAcc, setErrorSelectedAcc] = useState('')

    const [alreadyBottom, setAlreadyBottom] = useState(false)

    const [onCheckInput, setOnCheckInput] = useState(false)
    const [onCheckError, setOnCheckError] = useState(false)

    const [LPOAbottom, setLPOAbottom] = useState(false)
    const [PAMMAgreementbottom, setPAMMAgreementbottom] = useState(false)

    const [showLPOAmodal, setShowLPOAmodal] = useState(false)
    const setLPOAToggleModal = () => {

        if (selectedAccount) {
            document.documentElement.scrollTop = 0
            document.body.scrollTop = 0

            setOnCheckInput(false)
            setErrorSelectedAcc('')
            setAlreadyBottom(false)
            setShowLPOAmodal(!showLPOAmodal)
            setUserTypeName('')
            setErrorTypeName(false)
            setLPOAbottom(false)
        } else {
            setErrorSelectedAcc('Please select MT5 Account')
            setOnCheckInput(false)
        }

    }

    const [showPAMMAgreement, setShowPAMMAgreement] = useState(false)

    const setPAMMAgreementToggleModal = () => {
        setOnCheckError(false)
        setShowPAMMAgreement(!showPAMMAgreement)
        setUserTypeName('')
        setErrorTypeName(false)
        setPAMMAgreementbottom(false)
    }

    const handleScroll = (e) => {
        const target = e.target

        if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
            setLPOAbottom(true)
            setPAMMAgreementbottom(true)
            setAlreadyBottom(true)
        }
    }

    const checkNameIsCorrect = () => {

        if (userTypeName.replace(/\s+/g, '') === userFullName.toLocaleUpperCase().replace(/\s+/g, '') && onCheckInput) {
            setUserTypeName('')
            setOnCheckInput(false)
            setOnCheckError(false)
            setErrorTypeName(false)
            setAlreadyBottom(false)
            setShowLPOAmodal(false)
            setPAMMAgreementbottom(false)
            setShowPAMMAgreement(true)
        } else {
            if (!onCheckInput) {
                setOnCheckError(true)
            } else {
                setOnCheckError(false)
            }

            if (userTypeName === userFullName.toLocaleUpperCase()) {
                setErrorTypeName(false)
            } else {
                setErrorTypeName(true)
            }
        }
    }

    const handleEnter = (e) => {

        if (e.key === 'Enter') {
            checkNameIsCorrect()
        }
    }

    const PAMMAgreementCheckNameIsCorrect = () => {

        if (userTypeName.replace(/\s+/g, '') === userFullName.toLocaleUpperCase().replace(/\s+/g, '') && onCheckInput) {
            setUserTypeName('')
            setOnCheckInput(false)
            setOnCheckError(false)
            setErrorTypeName(false)
            setAlreadyBottom(false)
            setShowLPOAmodal(false)
            setPAMMAgreementbottom(false)
            setShowPAMMAgreement(false)
            onSubmit()
        } else {
            if (!onCheckInput) {
                setOnCheckError(true)
            } else {
                setOnCheckError(false)
            }

            if (userTypeName === userFullName.toLocaleUpperCase()) {
                setErrorTypeName(false)
            } else {
                setErrorTypeName(true)
            }
        }
    }





    // get the Date without day

    let settlementDate
    let settlementDateTomorrow1
    let settlementDateTomorrow2

    if (pammManagerDetails.settlement_period == "Monthly") {
        if (moment().format('YYYY-MM-DD') != moment().startOf('month').format('YYYY-MM-DD')) {
            settlementDate = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD')
            settlementDateTomorrow1 = moment().add(1, 'M').startOf('month').add(1, 'days').format('YYYY-MM-DD')
            settlementDateTomorrow2 = moment().add(1, 'M').startOf('month').add(2, 'days').format('YYYY-MM-DD')
        } else {
            settlementDate = moment().startOf('month').format('YYYY-MM-DD')
            settlementDateTomorrow1 = moment().startOf('month').add(1, 'days').format('YYYY-MM-DD')
            settlementDateTomorrow2 = moment().startOf('month').add(2, 'days').format('YYYY-MM-DD')
        }
    } else {
        settlementDate = moment(pammManagerDetails.settlement_date).format("YYYY-MM-DD")
        settlementDateTomorrow1 = moment(pammManagerDetails.settlement_date).add(1, 'days').format('YYYY-MM-DD')
        settlementDateTomorrow2 = moment(pammManagerDetails.settlement_date).add(2, 'days').format('YYYY-MM-DD')
    }




    return (
        <>
            <Breadcrumb title="PAMM"><Link to="/pamm">Pamm</Link> &nbsp; / {pammManagerDetails.name} - {t("pamm.subscribe")}</Breadcrumb>


            <Row style={{ margin: '0' }}>
                <Col lg='8'>

                    <Card style={{ boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 8px 0px' }}>
                        {/* <CardHeader>
                            <h4 style={{ color: '#f9b700' }}>{t("pamm.subscription")}</h4>
                            
                        </CardHeader> */}


                        {accounts?.length === 0 || accounts?.length == null ? (
                            <>
                                <Col style={{ margin: '30px' }}>
                                    <h4 style={{ color: '#113b58' }} className="mb-3">{t("pamm.youDoNotMeetTheRequirements")}</h4>
                                    <p>{t("pamm.pleaseMakeSureHaveAtLeastOneAvailable")}</p>
                                    <Link to={`/live`}><Button className="mb-3">{t("pamm.openNewLiveAccount")}</Button></Link>
                                </Col>
                            </>
                        )

                            : <>
                                <FormGroup style={{ margin: '30px' }}>
                                    {isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', top: '50%' }}><Spinner size="lg" color="warning" /></div> :
                                        <div className="ml-3">

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <h4 style={{ color: '#113b58', fontWeight: '400', marginBottom: '20px' }} className="mb-3">{t("pamm.PAMMINFORMATION")}</h4>
                                                <Link to={`/pamm/${id}/statistic`} style={{ borderRadius: '0 0 0 12px' }}>
                                                    <p style={{ position: 'absolute', right: '20px', top: '16px', textAlign: 'right', fontSize: '20px' }}>X</p>
                                                </Link>

                                            </div>


                                            <div style={{ marginBottom: '15px' }}>
                                                <img src={pammManagerDetails?.avatar} alt='pamm avatar' style={{ width: '105px', height: '100px', borderRadius: '5px' }} />
                                            </div>

                                            <Row >
                                                <Col lg='6'>
                                                    <p style={{ fontSize: '14px', marginBottom: '10px' }}><span style={{ fontWeight: '500' }}>{t("pamm.Name")}</span> {pammManagerDetails.name}</p>
                                                    <p style={{ fontSize: '14px', marginBottom: '10px' }}><span style={{ fontWeight: '500' }}>{t("pamm.minSubscription")}</span> {formatter.format(pammManagerDetails.min_amount)}</p>
                                                    {pammManagerDetails.performance_fee !== null ? <p style={{ fontSize: '14px', marginBottom: '10px' }}><span style={{ fontWeight: '500' }}>{t("pamm.PerformanceFee")}</span> {pammManagerDetails.performance_fee} % </p> : ''}
                                                    {pammManagerDetails.management_fee !== null ? <p style={{ fontSize: '14px', marginBottom: '10px' }}><span style={{ fontWeight: '500' }}>{t("pamm.ManagementFee")}</span> {pammManagerDetails.management_fee} %</p> : ''}
                                                </Col>

                                                <Col lg='6'>
                                                    {pammManagerDetails.flat_fee !== null ? <p style={{ fontSize: '14px', marginBottom: '10px' }}><span style={{ fontWeight: '500' }}>{t("pamm.FlatFee")}</span> {formatter.format(pammManagerDetails.flat_fee)}</p> : ''}
                                                    <p style={{ fontSize: '14px', marginBottom: '3px' }}><span style={{ fontWeight: '500' }}>{t("pamm.SettlementPeriod")}</span> {pammManagerDetails.settlement_period}</p>
                                                    <p style={{ fontSize: '14px', marginBottom: '9px' }}><span style={{ fontWeight: '500' }}>{t("pamm.settlementDate")}</span> {settlementDate} (GMT +0)
                                                            <HelpCircle id="nextSettlement" style={{ width: '14px', color: '#f9b700', marginLeft: '3px', cursor: 'pointer' }} />
                                                        <Tooltip id='settlementTooltip' placement='right' style={{ textAlign: 'left', backgroundColor: '#f7f7f7', color: 'black' }} isOpen={nextSettlementTooltipOpen} target="nextSettlement" toggle={nextSettlementToggle}>
                                                            <h5>{t("pamm.PleaseTakeNote")}</h5>
                                                            {/* <p>1. {t("pamm.SettlementDateSubscriber")} {pammManagerDetails.penalty_percent}%<strong></strong></p> */}
                                                            <p>1. {t("pamm.IfYouDecide")} <strong>{t("pamm.withdraw")}</strong> {t("pamm.or")} <strong>{t("pamm.unsubscribe")}</strong> {t("pamm.BesideSettlement")} <span style={{ color: 'red', fontWeight: 'bold' }}>{t("pamm.deduct")} {pammManagerDetails.penalty_percent}%</span> {t("pamm.fromYourFund")}</p>
                                                            <p>2. {t("pamm.ThisSettlementWillHappenOn")} {settlementDate} (GMT +0) 00:00:00</p>
                                                                3. {t("pamm.TheInvestorIsNotAllowed")}
                                                            <br /><br />
                                                            {t("pamm.TheInvestorWillBeAssigned")} <span style={{ color: 'green', fontWeight: 'bold' }}>{t("pamm.afterTheSettlementDate")}</span> {t("pamm.AsBufferPeriod")}
                                                            <br /><br />
                                                            {t("pamm.ForExample")}
                                                            {t("pamm.IfTheSettlementDateIsOn")} <span style={{ fontWeight: 'bold' }}>{settlementDate} (GMT +0) 00:00:00</span>{t("pamm.theInvestorIsNotAllowed")} <span style={{ fontWeight: 'bold' }}>{settlementDateTomorrow1} (GMT +0) 00:00:00</span> {t("pamm.to")} <span style={{ fontWeight: 'bold' }}>{settlementDateTomorrow2} (GMT +0) 23:59:59</span> {t("pamm.WithoutAnyPenalty")}
                                                        </Tooltip>
                                                    </p>
                                                    <p style={{ fontSize: '14px', marginBottom: '5px' }}><span style={{ fontWeight: '500' }}>{t("pamm.penalty")}</span> {pammManagerDetails.penalty_percent}%</p>
                                                </Col>
                                            </Row>

                                            <div style={{ marginTop: '20px' }}>
                                                <p style={{ marginBottom: '0px' }}>{t("financial.KindlyTakeNote")}</p>
                                                <p style={{ marginBottom: '0px' }}>1. {t("pamm.SettlementWillBeProceed")}</p>
                                                <p>2. {t("pamm.PenaltyWillBeChargedIf")}</p>
                                            </div>
                                        </div>
                                    }

                                    <div style={{ border: '1px solid #d9d9d9', marginLeft: '20px', marginRight: '20px' }}></div>

                                    <div className=" ml-3">
                                        <h4 style={{ color: '#113b58', fontWeight: 400 }} className="mt-3 mb-3">{t("pamm.selectMT5LIVEAccount")}</h4>
                                        <Input

                                            type="select"
                                            name="account_login"
                                            className="form-control digits"
                                            innerRef={register({ required: true })}
                                            id='selectPAMMinput'
                                            style={{ border: "1.5px solid #868686", marginBottom: '5px' }}
                                            required
                                            onChange={(e) => { setSelectedAccount(e.target.value) }}
                                        >
                                            <option value=''>{t("pamm.pleaseSelectAnAccount")}</option>

                                            {accounts?.length > 0 &&
                                                accounts.map((account, index) => {
                                                    return (
                                                        <option key={index} value={account.account_login} >{account.account_login} {account?.plan?.name} - {formatter.format(account?.balance)}</option>
                                                    )
                                                })}
                                        </Input>
                                        {errorSelectedAcc && <p style={{ color: 'red' }}>{errorSelectedAcc}</p>}

                                        <FormGroup className="mb-0" style={{ float: 'right' }}>
                                            <Button onClick={() => { setLPOAToggleModal() }} color="primary" className="mt-1 mb-3" style={{ fontSize: '12px' }}>
                                                {t("pamm.Submit")}
                                            </Button>
                                            {isSending ? <Spinner className="ml-2" color="warning" size="lg" /> : ''}
                                        </FormGroup>
                                    </div>


                                </FormGroup>

                            </>

                        }
                    </Card>
                </Col>

                {showLPOAmodal && selectedAccount && (
                    <Modal isOpen={showLPOAmodal} backdrop={true} centered={true}>

                        <ModalBody>
                            <div style={{ textAlign: 'center' }}>
                                <h4 style={{ fontWeight: '400' }}>{t("pamm.Step")} 1 : LPOA Agreement</h4>
                                <Progress color="primary" value="1" max="3" />
                            </div>

                            <Row style={{ justifyContent: 'center', marginTop: '20px' }}>
                                <Col id='scroll-bottom' lg='10' >

                                    <CardBody onScroll={(e) => handleScroll(e)} style={{ height: '400px', overflow: 'scroll', border: !alreadyBottom ? '1px solid red' : '1px solid green', borderRadius: '5px', textAlign: 'justify' }}>

                                        <LPOA />

                                    </CardBody>
                                    {!alreadyBottom && <p style={{ color: 'red', textAlign: 'center', marginBottom: '10px' }}>{t("pamm.MakeSureYouRead")}</p>}

                                    {alreadyBottom &&
                                        <Row style={{ marginTop: '10px', marginRight: '0', marginLeft: '25px' }}>
                                            <Input checked={onCheckInput} onChange={() => { setOnCheckInput(!onCheckInput) }} type='checkbox' required />
                                            <p style={{ marginBottom: '0' }}>{t("pamm.IUnderstandAndAgree")}</p>
                                        </Row>

                                    }
                                </Col>
                            </Row>

                            <Row style={{ marginTop: '20px' }}>
                                <Col style={{ textAlign: 'center' }}>
                                    {/* {console.log('userFullName.toLocaleUpperCase()', userFullName.toLocaleUpperCase())} */}
                                    <p style={{ fontSize: '14px' }}>{t("pamm.Type")} <strong>"{userFullName.toLocaleUpperCase()}"</strong> {t("pamm.asAgreeTo")}</p>
                                    <Input id='name-input' onChange={(e) => setUserTypeName(e.target.value)} type="text" style={{ width: '80%', margin: '0 auto', height: '2.625em', padding: '0 0.75em' }}></Input>
                                    {errorTypeName && <p style={{ marginBottom: '0px', color: 'red' }}>{t("pamm.PleaseTypeCorrect")}</p>}
                                    {onCheckError && <p style={{ marginBottom: '0px', color: 'red' }}>{t("pamm.PleaseMakeSure")}</p>}
                                </Col>
                            </Row>


                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'space-evenly' }}>
                            <Button onClick={setLPOAToggleModal} color="secondary">{t("pammSubsccription.Close")} </Button>
                            <Button disabled={!LPOAbottom} onKeyDown={handleEnter} onClick={() => checkNameIsCorrect()} color="primary">{t("pamm.Next")}</Button>
                            {/* <Button onKeyDown={handleEnter} onClick={() => checkNameIsCorrect()} color="primary">Next </Button> */}
                        </ModalFooter>

                        {showLPOAmodal && localStorage.getItem('pamm-follow-onboarding') === null &&
                            <Steps
                                enabled={stepsEnabled}
                                steps={steps}
                                initialStep={0}
                                onExit={() => {
                                    localStorage.setItem('pamm-follow-onboarding', true)
                                    setStepsEnabled(false)
                                }}
                                options={
                                    {
                                        showProgress: true,
                                        doneLabel: 'Understand',
                                        exitOnOverlayClick: false
                                    }
                                }
                            />
                        }
                    </Modal>


                )}

                {showPAMMAgreement && selectedAccount && (
                    <Modal isOpen={showPAMMAgreement} backdrop={true} centered={true}>

                        <ModalBody>
                            <div style={{ textAlign: 'center' }}>
                                <h4 style={{ fontWeight: '400' }}>{t("pamm.Step")}  2 : PAMM Agreement</h4>
                                <Progress color="primary" value="2" max="3" />
                            </div>

                            <Row style={{ justifyContent: 'center', marginTop: '20px' }}>
                                <Col lg='10' >

                                    <CardBody
                                        onScroll={(e) => handleScroll(e)}
                                        style={{
                                            height: '400px',
                                            overflow: 'scroll',
                                            border: !alreadyBottom ? '1px solid red' : '1px solid green',
                                            borderRadius: '5px',
                                            textAlign: 'justify'
                                        }}>

                                        <PammAgreement />

                                    </CardBody>
                                    {!alreadyBottom && <p style={{ color: 'red', textAlign: 'center', marginBottom: '10px' }}>{t("pamm.MakeSureYouRead")}</p>}

                                    {alreadyBottom &&
                                        <Row style={{ marginTop: '10px', marginRight: '0', marginLeft: '25px' }}>
                                            <Input checked={onCheckInput} onChange={() => { setOnCheckInput(!onCheckInput) }} type='checkbox' required />
                                            <p style={{ marginBottom: '0' }}>{t("pamm.IUnderstandAndAgree")}</p>
                                        </Row>

                                    }
                                </Col>
                            </Row>

                            <Row style={{ marginTop: '20px' }}>
                                <Col style={{ textAlign: 'center' }}>
                                    <p style={{ fontSize: '14px' }}>{t("pamm.Type")} <strong>"{userFullName.toLocaleUpperCase()}"</strong> {t("pamm.asAgreeTo")}</p>
                                    <Input onChange={(e) => setUserTypeName(e.target.value)} type="text" style={{ width: '80%', margin: '0 auto', height: '2.625em', padding: '0 0.75em' }}></Input>
                                    {errorTypeName && <p style={{ marginBottom: '0px', color: 'red' }}>{t("pamm.PleaseTypeCorrect")}</p>}
                                    {onCheckError && <p style={{ marginBottom: '0px', color: 'red' }}>{t("pamm.PleaseMakeSure")}</p>}
                                </Col>
                            </Row>


                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'space-evenly' }}>
                            <Button onClick={setPAMMAgreementToggleModal} color="secondary">{t("pammSubsccription.Close")} </Button>
                            <Button disabled={!PAMMAgreementbottom} onClick={() => PAMMAgreementCheckNameIsCorrect()} color="primary">{t("pamm.Next")} </Button>
                            {/* <Button onClick={() => PAMMAgreementCheckNameIsCorrect()} color="primary">Next </Button> */}
                        </ModalFooter>
                    </Modal>
                )}

            </Row>



        </>
    )
}

export default PammFollow