import React, { useEffect } from 'react'

const Deposit = () => {

  useEffect(() => {
    window.location.href = './financial'

  }, [])

  return (
    <div>

    </div>
  )
}

export default Deposit
