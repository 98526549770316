import React, { useMemo, useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  Tooltip
} from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import PAMMSUBSCRIBE from '../../../../network/services/pammSubscription'
import AccountService from '../../../../network/services/account'
import { AlertCircle } from 'react-feather'

import moment from 'moment'

import { HelpCircle } from 'react-feather'

const ChooseAmount = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const accounts = useSelector((state) => state.account.accounts)
  const currencies = useSelector((state) => state.currency.currencies)
  const selectedAccountId = useSelector((state) => state.withdrawal.mt5.account)

  const [nextSettlementTooltipOpen, setNextSettlementTooltipOpen] = useState(false)
  const nextSettlementToggle = () => setNextSettlementTooltipOpen(!nextSettlementTooltipOpen)

  const selectedAccount = useMemo(() => {
    return accounts.find((e) => e.id === selectedAccountId)
    // eslint-disable-next-line
  }, [selectedAccountId])
  const currency = useMemo(() => {
    return currencies.find((e) => {
      return e.id === selectedAccount.currency_id
    })
    // eslint-disable-next-line
  }, [selectedAccount])

  const { register, errors, formState } = useFormContext()
  const { isSubmitting } = formState

  const [activePammAccountId, setActivePammAccountId] = useState([])
  const [selectedAccountFromPamm, setSelectedAccountFromPamm] = useState([])

  const fetchActivePammAccount = async () => {
    const response = await PAMMSUBSCRIBE.getAll()

    const isActiveAccount = response?.active.map((item) => item.account_id)
    const isUnsubscribeStillPending = response?.pending.map(
      (item) => item?.status === 3 && item.account_id
    )

    setActivePammAccountId(isActiveAccount.concat(isUnsubscribeStillPending))

    setSelectedAccountFromPamm(
      response?.active?.filter((item) => item.account_id === selectedAccountId)
    )
  }

  const init = async () => {
    const accountResponse = await AccountService.getAll()
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
  }

  useEffect(() => {
    fetchActivePammAccount()
    init()
    // eslint-disable-next-line
  }, [])

  let today = moment().format('YYYY-MM-DD')

  let settlementDate
  let settlementDateTomorrow1
  let settlementDateTomorrow2

  if (selectedAccountFromPamm[0]?.pammManager?.settlement_period == 'Monthly') {
    if (
      moment().format('YYYY-MM-DD') != moment().startOf('month').format('YYYY-MM-DD') &&
      today != moment().startOf('month').add(1, 'days').format('YYYY-MM-DD') &&
      today != moment().startOf('month').add(2, 'days').format('YYYY-MM-DD')
    ) {
      settlementDate = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD')
      settlementDateTomorrow1 = moment()
        .add(1, 'M')
        .startOf('month')
        .add(1, 'days')
        .format('YYYY-MM-DD')
      settlementDateTomorrow2 = moment()
        .add(1, 'M')
        .startOf('month')
        .add(2, 'days')
        .format('YYYY-MM-DD')
    } else {
      settlementDate = moment().startOf('month').format('YYYY-MM-DD')
      settlementDateTomorrow1 = moment().startOf('month').add(1, 'days').format('YYYY-MM-DD')
      settlementDateTomorrow2 = moment().startOf('month').add(2, 'days').format('YYYY-MM-DD')
    }
  } else {
    settlementDate = moment(selectedAccountFromPamm[0]?.pammManager?.settlement_date).format(
      'YYYY-MM-DD'
    )
    settlementDateTomorrow1 = moment(selectedAccountFromPamm[0]?.pammManager?.settlement_date)
      .add(1, 'days')
      .format('YYYY-MM-DD')
    settlementDateTomorrow2 = moment(selectedAccountFromPamm[0]?.pammManager?.settlement_date)
      .add(2, 'days')
      .format('YYYY-MM-DD')
  }

  return (
    <>
      <Card>
        <CardHeader>
          <h5>{t('financial.chooseAmount')}</h5>
          <p style={{ margin: 0, color: '#038004' }}>{`${t(
            'financial.availableBalance'
          )} ${selectedAccount?.balance?.toFixed(2)} ${currency?.name}`}</p>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label>{t('financial.withdrawalAmountUSD')}</Label>
                <Input
                  className="form-control"
                  type="number"
                  step=".01"
                  name="amount"
                  // min={selectedAccount.account_type == 3 ? 0 : 100}
                  innerRef={register({ required: true })}
                />
                <span style={{ color: 'red' }}>
                  {errors.amount && t('financial.amountIsRequired')}
                </span>
              </FormGroup>
            </Col>
          </Row>

          {activePammAccountId.includes(selectedAccountId) && selectedAccountFromPamm.length > 0 && (
            <>
              <p style={{ marginBottom: '10px', marginTop: '20px' }}>
                Pamm Manager : {selectedAccountFromPamm[0]?.pammManager?.name}
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t('pamm.SettlementPeriod')}{' '}
                {selectedAccountFromPamm[0]?.pammManager?.settlement_period}
              </p>

              {today !== settlementDateTomorrow1 && today !== settlementDateTomorrow2 ? (
                <>
                  <p>
                    {t('pamm.settlementDate')} {settlementDate}(GMT +0)
                    <HelpCircle
                      id="nextSettlement"
                      style={{
                        width: '18px',
                        color: '#f9b700',
                        marginLeft: '3px',
                        cursor: 'pointer'
                      }}
                    />
                    <Tooltip
                      placement="left"
                      id="settlementTooltip"
                      style={{ textAlign: 'left', backgroundColor: '#f7f7f7', color: 'black' }}
                      isOpen={nextSettlementTooltipOpen}
                      target="nextSettlement"
                      toggle={nextSettlementToggle}
                    >
                      <h5>{t('pamm.PleaseTakeNote')}</h5>
                      {/* <p>1. {t("pamm.SettlementDateSubscriber")} {pammManagerDetails.penalty_percent}%<strong></strong></p> */}
                      <p>
                        1. {t('pamm.IfYouDecide')} <strong>{t('pamm.withdraw')}</strong>{' '}
                        {t('pamm.or')} <strong>{t('pamm.unsubscribe')}</strong>{' '}
                        {t('pamm.BesideSettlement')}{' '}
                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                          {t('pamm.deduct')}{' '}
                          {selectedAccountFromPamm[0]?.pammManager?.penalty_percent}%
                        </span>{' '}
                        {t('pamm.fromYourFund')}
                      </p>
                      <p>
                        2. {t('pamm.ThisSettlementWillHappenOn')} {settlementDate} (GMT +0) 00:00:00
                      </p>
                      3. {t('pamm.TheInvestorIsNotAllowed')}
                      <br />
                      <br />
                      {t('pamm.TheInvestorWillBeAssigned')}{' '}
                      <span style={{ color: 'green', fontWeight: 'bold' }}>
                        {t('pamm.afterTheSettlementDate')}
                      </span>{' '}
                      {t('pamm.AsBufferPeriod')}
                      <br />
                      <br />
                      {t('pamm.ForExample')}
                      {t('pamm.IfTheSettlementDateIsOn')}
                      <span style={{ fontWeight: 'bold' }}>
                        {' '}
                        {settlementDate} (GMT +0) 00:00:00
                      </span>
                      {t('pamm.theInvestorIsNotAllowed')}
                      <span style={{ fontWeight: 'bold' }}>
                        {settlementDateTomorrow1} (GMT +0) 00:00:00{' '}
                      </span>
                      {t('pamm.to')}
                      <span style={{ fontWeight: 'bold' }}>
                        {' '}
                        {settlementDateTomorrow2} (GMT +0) 23:59:59
                      </span>
                      {t('pamm.WithoutAnyPenalty')}
                    </Tooltip>
                  </p>

                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <AlertCircle width="15px" style={{ color: 'red', marginRight: '5px' }} />
                    <p style={{ color: 'red' }}>
                      {t('financial.TheAccountYou')}, {t('financial.Kindly')}{' '}
                      {t('financial.unsubscribe')} {t('financial.firstToProcess')}
                    </p>
                  </div>
                </>
              ) : (
                <p style={{ color: '#f9b600' }}>**{t('financial.AbleToWithdraw')}</p>
              )}
            </>
          )}

          <Button
            color="primary btn-block"
            type="submit"
            style={{ maxWidth: 150, float: 'right', marginTop: '20px' }}
          >
            {isSubmitting ? t('financial.loading') : t('financial.submit')}
          </Button>
        </CardBody>
      </Card>
    </>
  )
}

export default ChooseAmount
