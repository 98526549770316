import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import Breadcrumb from '../../../layout/breadcrumb'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import CampusRegisterForm from './registrationForm/index'

import _ from 'lodash'
import StatusTable from './summittedTable/index'
import { Button, CardBody, Row, Col, Card, CardHeader, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap'

import styled from 'styled-components'

import { ResponsiveDiv, StyledRow } from '../../common/components.style'

import DemoSettings from '../../common/settings'
import MasterPassword from '../../common/settings/master_password'
import InvestorPassword from '../../common/settings/investor_password'

import AccountService from '../../../network/services/account'
import EventService from '../../../network/services/event'

import LeaderboardTable from './leaderboard'

import moment from 'moment'

const ResponsiveCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ResponsiveVerifyButton = styled(Button)`
  background-color: #b35855 !important;
  border-color: #b35855 !important;
  @media (max-width: 575px) {
    align-self: flex-start;
  }
`


const ResponsiveParagraph = styled.p`
  margin: 0;

  @media (max-width: 575px) {
    margin-bottom: 8px;
  }
`

const Event = () => {

    const history = useHistory()

    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: 'none' })
    }

    const { t } = useTranslation()
    const selectedAccount = useSelector((state) => state.dashboard.account)

    const verification = useSelector((state) => _.last(state.user.verifications))

    const campusCompetition = useSelector(state => state.event?.competitions?.competitions?.filter(item => item.name === 'Tradehall Campus CFD Trading Contest Malaysia 2021'))

    const campusCompetitionStartDate = moment(campusCompetition[0]?.start_date).format("YYYY-MM-DD")
    const campusCompetitionEndDate = moment(campusCompetition[0]?.end_date).format("YYYY-MM-DD")
    const currentDate = moment().format("YYYY-MM-DD")

    const [isEligible, setIsEligible] = useState(false)
    const [myRegistrationRecord, setMyRegistrationRecord] = useState([])

    const [tncModal, setTncModal] = useState(false);

    const tncToggle = () => setTncModal(!tncModal);

    // console.log('currentDate', currentDate)
    // console.log('myRegistrationRecord', myRegistrationRecord)
    // console.log('compare time', currentDate < campusCompetition)

    // const eventLeaderboardResponse = await EventService.getLeaderBoardResult({
    //     competition_id: competitionId
    // })
    // dispatch({
    //     type: 'SET_LEADERBOARD_STATUS',
    //     leaderBoard: eventLeaderboardResponse
    // })

    const [showModal, setShowModal] = useState(false)
    const [selectedSetting, setSelectedSetting] = useState(null)
    const toggleModal = () => {
        setShowModal(!showModal)
    }


    const user = useSelector((state) => state.user)

    const [myCompetitionResult, setMyCompetitionResult] = useState({})

    const fetchCompetitionResponse = async () => {
        // Hardcode competition id
        const myCompetitionResponse = await EventService.myCompetitionResult({
            competition_id: 1
        })

        setMyCompetitionResult(myCompetitionResponse)

    }

    const fetchMyRegistrationRecord = async () => {
        // Hardcode competition id
        const record = await EventService.getMyCompetition({
            competition_id: 1
        })
        setMyRegistrationRecord(record?.submitted_registrations)
        setIsEligible(record?.is_eligible)
    }

    useEffect(() => {
        // Hardcode competition id
        fetchMyRegistrationRecord()
        fetchCompetitionResponse()
    }, [])



    const demoAccounts = useSelector(state => state.account?.accounts?.filter(item => item.account_type === 1))
    const currencies = useSelector((state) => state.currency.currencies)
    //Form is set to false in initial
    const [formOpen, setFormOpen] = useState(false)
    const dispatch = useDispatch()





    const toOrdinalSuffix = (num) => {
        const int = parseInt(num),
            digits = [int % 10, int % 100],
            ordinals = ['st', 'nd', 'rd', 'th'],
            oPattern = [1, 2, 3, 4],
            tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
        return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
            ? int + ordinals[digits[0] - 1]
            : int + ordinals[3]
    }

    const settings = [
        {
            id: 1,
            title: t("dashboard.titleChangeMaster"),
            cta: <MasterPassword setShowModal={setShowModal} />
        },
        {
            id: 2,
            title: t("dashboard.titleChangeInvestor"),
            cta: <InvestorPassword setShowModal={setShowModal} />
        }
    ]

    const init = async () => {
        const accountResponse = await AccountService.getAll()
        dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (selectedSetting != null && selectedAccount != null) {
            setShowModal(true)
        }
    }, [selectedSetting, selectedAccount])

    useEffect(() => {
        if (showModal === false) {
            // fetch account
            if (selectedAccount != null) {
                init()
            }

            setSelectedSetting(null)
            dispatch({
                type: 'SET_DASHBOARD_ACCOUNT',
                account: null
            })
        }
        // eslint-disable-next-line
    }, [showModal])

    const studentParticipateIsEligible = () => {

        if (
            myRegistrationRecord?.length === 0
            && verification?.status === 1
            && moment().format('YYYY-MM-DD HH:mm') < '2021-06-30 18:00'

            || (user.user.is_competition_eligible && myRegistrationRecord?.length === 0)
            && verification?.status === 1
            && moment().format('YYYY-MM-DD HH:mm') < '2021-06-30 18:00'

            || isEligible && verification?.status === 1 && myRegistrationRecord?.length === 0 && moment().format('YYYY-MM-DD HH:mm') < '2021-06-30 18:00'
        ) {

            return <CampusRegisterForm />

        } else if (verification?.status !== 1) {
            return <Card>
                <CardBody
                    className="p-4"
                    style={{ backgroundColor: '#ffc4c4', borderRadius: '5px' }}
                >
                    <ResponsiveCard>
                        <ResponsiveParagraph style={{ color: '#963737', fontWeight: 'bold' }}>
                            {t("dashboard.yourProfileIsntVerified")}
                        </ResponsiveParagraph>
                        <ResponsiveVerifyButton
                            color="primary"
                            onClick={() => {
                                navigate(`${process.env.PUBLIC_URL}/account#verification`)
                            }}
                        >
                            {t("dashboard.verify")}
                        </ResponsiveVerifyButton>
                    </ResponsiveCard>
                    {verification?.status === 3 && verification?.reason && (
                        <>
                            <ResponsiveParagraph style={{ color: '#ab0505' }}>
                                {t("dashboard.yourRecentApplicationWasRejected")}
                            </ResponsiveParagraph>
                            <ResponsiveParagraph
                                style={{ color: '#ab0505' }}
                            >{`${t("dashboard.reason")}: ${verification?.reason}`}</ResponsiveParagraph>
                        </>
                    )}
                </CardBody>
            </Card>
        }

        if (isEligible &&
            myRegistrationRecord?.length <= 3) {

            if (myRegistrationRecord[0]?.status === 0) {
                return (<>
                    <CardBody
                        className="p-4"
                        style={{ backgroundColor: '#fdca42', margin: '0 15px' }}
                    >
                        <ResponsiveCard>
                            <ResponsiveParagraph style={{ color: 'white', fontWeight: 'bold' }}>
                                {t("event.YourSubmissionIsPending")}
                            </ResponsiveParagraph>
                            {/* <ResponsiveVerifyButton
                            color="primary"
                            onClick={() => {
                                navigate(`${process.env.PUBLIC_URL}/account#verification`)
                            }}
                        >
                            {t("dashboard.verify")}
                        </ResponsiveVerifyButton> */}
                        </ResponsiveCard>
                    </CardBody>

                </>
                )
            } else if (myRegistrationRecord[0]?.status === 1) {
                const registedAccount = demoAccounts?.filter(account => parseInt(account?.account_login) === myRegistrationRecord[0].account_login)
                return (
                    <>
                        <Col>
                            <img className="d-block" style={{ width: '100%', margin: '0 auto' }} src='https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/campus/CampusCRM.svg' alt='event banner' />
                        </Col>
                        <Row style={{ margin: '20px 0px 0px 0px' }}>
                            <Col lg='6' style={{ paddingRight: '15px' }} className="d-block d-md-block d-lg-block d-xl-block padding-verify-right m-t-15">
                                {myRegistrationRecord[0]?.status === 1 &&


                                    registedAccount && registedAccount?.length > 0 ? (
                                        registedAccount.map((account) => {
                                            const currency = currencies.find((e) => e.id === account.currency_id)
                                            return (

                                                <Card style={{ height: '520px', margin: '0px', borderRadius: '0px' }} className="card-absolute" key={account.id}>
                                                    <CardHeader className="bg-primary">
                                                        <h6 style={{ margin: 0 }}>{`#${account.account_login} - ${t("demo.demo")}`}</h6>
                                                    </CardHeader>
                                                    <div className="card-right">
                                                        <ResponsiveDiv>
                                                            <DemoSettings
                                                                settings={settings}
                                                                account={account}
                                                                setSelectedSetting={setSelectedSetting}
                                                            />
                                                        </ResponsiveDiv>
                                                    </div>
                                                    <CardBody className="text-center" style={{ display: 'flex' }}>
                                                        <StyledRow>
                                                            <Col xs="12">
                                                                <h6>{account.plan?.name ?? '-'}</h6>
                                                                <p>{t("demo.accountType")}</p>
                                                            </Col>
                                                            <Col xs="12">
                                                                <h6>
                                                                    {account?.leverage === null ? '-' : `1:${account?.leverage}` ?? '-'}
                                                                </h6>
                                                                <p>{t("demo.leverage")}</p>
                                                            </Col>
                                                            <Col xs="12">
                                                                <h6>
                                                                    {`${account?.balance === null
                                                                        ? '-'
                                                                        : account?.balance?.toFixed(2) ?? '-'
                                                                        } ${currency?.name ?? ''}`}
                                                                </h6>
                                                                <p>{t("demo.balance")}</p>
                                                            </Col>

                                                        </StyledRow>

                                                        <StyledRow>
                                                            <Col xs="12">
                                                                <h6>
                                                                    {`${currentDate < campusCompetitionStartDate ? '-' : (myCompetitionResult?.my_ranking === null ? '-' : toOrdinalSuffix(myCompetitionResult?.my_ranking))}`}
                                                                </h6>
                                                                <p>{t("event.MYRANKING")}</p>
                                                            </Col>
                                                            <Col xs="12">
                                                                <h6>
                                                                    {`${myCompetitionResult?.my_participation_result === null
                                                                        ? '-'
                                                                        : myCompetitionResult?.my_participation_result?.portfolioValue}USD`}
                                                                </h6>
                                                                <p>{t("event.PORTFOLIOVALUE")}</p>
                                                            </Col>
                                                            <Col xs="12">
                                                                <h6>
                                                                    {`${myCompetitionResult?.my_participation_result === null
                                                                        ? '-'
                                                                        : myCompetitionResult?.my_participation_result?.returnRate}%`}
                                                                </h6>
                                                                <p>{t("event.RETURNRATE")}</p>
                                                            </Col>
                                                            <Col xs="12">
                                                                <h6>
                                                                    {`${myCompetitionResult?.my_participation_result === null
                                                                        ? '-'
                                                                        : myCompetitionResult?.my_participation_result?.overall_profit.toFixed(2)}USD`}
                                                                </h6>
                                                                <p>{t("event.TOTALPROFIT")}</p>
                                                            </Col>


                                                        </StyledRow>

                                                    </CardBody>
                                                </Card>

                                            )
                                        })
                                    ) : (
                                        <Card style={{ height: '520px', margin: '0px', borderRadius: '0px' }} className="card-absolute">
                                            <div style={{ textAlign: "center", margin: 'auto 0' }}>
                                                <Spinner color="warning"/>
                                            </div>
                                        </Card>
                                    )
                                }
                            </Col>
                            <Col lg='6' style={{ paddingRight: '15px' }} className="m-t-15" >
                                <Card style={{ height: 'fit-content', borderRadius: '0px' }} >
                                    <CardBody style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#103c5a' }}>

                                        <div style={{ color: 'white' }}>
                                            <p>{t("event.CompetitionStartFrom")} {campusCompetitionStartDate} {t("event.until")}  {campusCompetitionEndDate}</p>
                                            <p>{moment().format('YYYY-MM-DD HH:mm') < campusCompetitionStartDate ? '' : 'Registration is Closed'}</p>
                                            <h3>{t("event.TermsAndConditions")}</h3>
                                            <p className='m-t-20'>1. {t("event.AvailableToAllCollege")}</p>
                                            <p>2. {t("event.ClientsMustHaveCompleted")}</p>
                                            <p>3. {t("event.ClientsMustProvide")}</p>
                                            <p>4. {t("event.ClientsCanOnlySubmit")}</p>
                                            <p>5. {t("event.CampusCFDTradingContest")} </p>
                                            <p>6. {t("event.AllTheSettings")} </p>

                                            <a href={sessionStorage.getItem('language') == 'en'
                                                ? campusCompetition[0]?.terms_and_conditions
                                                : campusCompetition[0]?.terms_and_conditions_chinese}
                                                target="_blank">
                                                <Button color="primary" style={{ width: '50%', borderRadius: '0' }}  >{t("dashboard.TermsAndConditions")}</Button>
                                            </a>
                                        </div>





                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )
            }

        } else if (
            isEligible === false &&
            myRegistrationRecord?.length <= 3
        ) {

            if (myRegistrationRecord[0]?.status === 0) {
                return (

                    <CardBody
                        className="p-4"
                        style={{ backgroundColor: '#fdca42', margin: '0 15px' }}
                    >
                        <ResponsiveCard>
                            <ResponsiveParagraph style={{ color: 'white', fontWeight: 'bold' }}>
                                {t("event.YourSubmissionIsPending")}
                            </ResponsiveParagraph>
                            {/* <ResponsiveVerifyButton
                            color="primary"
                            onClick={() => {
                                navigate(`${process.env.PUBLIC_URL}/account#verification`)
                            }}
                        >
                            {t("dashboard.verify")}
                        </ResponsiveVerifyButton> */}
                        </ResponsiveCard>
                    </CardBody>

                )
            } else if (myRegistrationRecord[0]?.status === 1) {
                const registedAccount = demoAccounts?.filter(account => parseInt(account?.account_login) === myRegistrationRecord[0]?.account_login)
                return (
                    <>
                        <Col>
                            <img className="d-block" style={{ width: '100%', margin: '0 auto' }} src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/campus/CampusCRM.svg" alt='event banner' />
                        </Col>
                        <Row style={{ margin: '20px 0px 0px 0px' }}>
                            <Col lg='6' style={{ paddingRight: '15px' }} className="d-block d-md-block d-lg-block d-xl-block padding-verify-right m-t-15">
                                {myRegistrationRecord[0]?.status === 1 &&


                                    registedAccount?.length > 0 ? (
                                        registedAccount?.map((account) => {
                                            const currency = currencies.find((e) => e.id === account.currency_id)
                                            return (

                                                <Card style={{ height: '520px', margin: '0px', borderRadius: '0px' }} className="card-absolute" key={account.id}>
                                                    <CardHeader className="bg-primary">
                                                        <h6 style={{ margin: 0 }}>{`#${account.account_login} - ${t("demo.demo")}`}</h6>
                                                    </CardHeader>
                                                    <div className="card-right">
                                                        <ResponsiveDiv>
                                                            <DemoSettings
                                                                settings={settings}
                                                                account={account}
                                                                setSelectedSetting={setSelectedSetting}
                                                            />
                                                        </ResponsiveDiv>
                                                    </div>
                                                    <CardBody className="text-center" style={{ display: 'flex' }}>
                                                        <StyledRow>
                                                            <Col xs="12">
                                                                <h6>{account.plan?.name ?? '-'}</h6>
                                                                <p>{t("demo.accountType")}</p>
                                                            </Col>
                                                            <Col xs="12">
                                                                <h6>
                                                                    {account?.leverage === null ? '-' : `1:${account?.leverage}` ?? '-'}
                                                                </h6>
                                                                <p>{t("demo.leverage")}</p>
                                                            </Col>
                                                            <Col xs="12">
                                                                <h6>
                                                                    {`${account?.balance === null
                                                                        ? '-'
                                                                        : account?.balance?.toFixed(2) ?? '-'
                                                                        } ${currency?.name ?? ''}`}
                                                                </h6>
                                                                <p>{t("demo.balance")}</p>
                                                            </Col>
                                                        </StyledRow>
                                                        <StyledRow>
                                                            <Col xs="12">
                                                                <h6>
                                                                    {`${myCompetitionResult?.my_ranking === null ? '-' : toOrdinalSuffix(myCompetitionResult?.my_ranking)}`}
                                                                </h6>
                                                                <p>{t("event.MYRANKING")}</p>
                                                            </Col>

                                                            <Col xs="12">
                                                                <h6>
                                                                    {`${myCompetitionResult?.my_participation_result === null
                                                                        ? '-'
                                                                        : myCompetitionResult?.my_participation_result?.portfolioValue}USD`}
                                                                </h6>
                                                                <p>{t("event.PORTFOLIOVALUE")}</p>
                                                            </Col>
                                                            <Col xs="12">
                                                                <h6>
                                                                    {`${myCompetitionResult?.my_participation_result === null
                                                                        ? '-'
                                                                        : myCompetitionResult?.my_participation_result?.returnRate}%`}
                                                                </h6>
                                                                <p>{t("event.RETURNRATE")}</p>
                                                            </Col>
                                                            <Col xs="12">
                                                                <h6>
                                                                    {`${myCompetitionResult?.my_participation_result === null
                                                                        ? '-'
                                                                        : myCompetitionResult?.my_participation_result?.overall_profit.toFixed(2)}USD`}
                                                                </h6>
                                                                <p>{t("event.TOTALPROFIT")}</p>
                                                            </Col>

                                                        </StyledRow>
                                                    </CardBody>
                                                </Card>

                                            )
                                        })
                                    ) : (
                                        ''
                                    )
                                }
                            </Col>
                            <Col lg='6' style={{ paddingRight: '15px' }} className="m-t-15" >
                                <Card style={{ height: 'fit-content' }} >
                                    <CardBody style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#103c5a' }}>

                                        <div style={{ color: 'white' }}>
                                            <p>{t("event.CompetitionStartFrom")} {campusCompetitionStartDate} {t("event.until")} {campusCompetitionEndDate}</p>
                                            <p>{moment().format('YYYY-MM-DD HH:mm') < campusCompetitionStartDate ? '' : t("event.RegistrationIsClosed")}</p>
                                            <h3>{t("event.TermsAndConditions")}</h3>
                                            <p className='m-t-20'>1. {t("event.AvailableToAllCollege")}</p>
                                            <p>2. {t("event.ClientsMustHaveCompleted")}</p>
                                            <p>3. {t("event.ClientsMustProvide")}</p>
                                            <p>4. {t("event.ClientsCanOnlySubmit")}</p>
                                            <p>5. {t("event.CampusCFDTradingContest")} </p>
                                            <p>6. {t("event.AllTheSettings")} </p>

                                            <a href={sessionStorage.getItem('language') == 'en'
                                                ? campusCompetition[0]?.terms_and_conditions
                                                : campusCompetition[0]?.terms_and_conditions_chinese}
                                                target="_blank">
                                                <Button color="primary" style={{ width: '50%', borderRadius: '0' }}  >{t("dashboard.TermsAndConditions")}</Button>
                                            </a>
                                        </div>



                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )
            }

        }

    }



    return (
        <>
            <Breadcrumb
                title={t("event.contest")}
                parent={t("event.CampusCompetition")}
            />


            {/* When user is eligible */}
            {user?.profile?.employed === 'student'
                || (user.user.is_competition_eligible) ?
                <div style={{ padding: '0px' }}>
                    {
                        studentParticipateIsEligible()
                    }

                    {myRegistrationRecord[0]?.status === 2 &&
                        myRegistrationRecord?.length < 3 &&
                        !formOpen && moment().format('YYYY-MM-DD HH:mm') < '2021-06-30 18:00' ?
                        (
                            <>
                                <CardBody
                                    className="p-4"
                                    style={{ backgroundColor: '#ffc4c4', margin: '15px' }}
                                >
                                    <ResponsiveCard>
                                        <ResponsiveParagraph style={{ color: '#963737', fontWeight: 'bold' }}>
                                            {t("event.YourSubmissionIsReject")}
                                            <br />
                                            {t("event.Reason")}: {myRegistrationRecord[0]?.reason}
                                        </ResponsiveParagraph>
                                        <ResponsiveVerifyButton
                                            color="primary"
                                            onClick={() => setFormOpen(true)}
                                        >
                                            {t("event.Resubmit")}
                                        </ResponsiveVerifyButton>
                                    </ResponsiveCard>
                                </CardBody>

                            </>
                        )
                        :
                        (myRegistrationRecord[0] && formOpen && moment().format('YYYY-MM-DD HH:mm') < '2021-06-30 18:00' &&
                            <>
                                <CampusRegisterForm />
                            </>
                        )
                    }

                    {myRegistrationRecord[0]?.status === 2 &&
                        myRegistrationRecord?.length === 3 &&
                        !formOpen && moment().format('YYYY-MM-DD HH:mm') < '2021-06-30 18:00' ?
                        (
                            <>
                                <CardBody
                                    className="p-4"
                                    style={{ backgroundColor: '#ffc4c4', margin: '15px' }}
                                >
                                    <ResponsiveCard>
                                        <ResponsiveParagraph style={{ color: '#963737', fontWeight: 'bold' }}>
                                            {t("event.YourSubmissionIsReject")}
                                            <br />
                                            {t("event.Reason")}: {myRegistrationRecord[0]?.reason}
                                        </ResponsiveParagraph>
                                        <Link to='/support'>
                                            <ResponsiveVerifyButton
                                                color="primary"
                                            >
                                                {t("event.CustomerSupport")}
                                            </ResponsiveVerifyButton>
                                        </Link>
                                    </ResponsiveCard>
                                </CardBody>

                            </>
                        )
                        :
                        ''
                    }


                    {
                        myRegistrationRecord[0]?.status === 1 ? '' :
                            <Col>
                                <h3 class="text-center m-t-15" style={{ color: '#113b5a' }}>{t("event.MySubmmisionRecord")}</h3>
                                <StatusTable />
                            </Col>
                    }


                    <Col style={{ margin: '15px auto' }}>
                        <h3 class="text-center" style={{ color: '#113b5a' }}>{t("event.LEADERBOARD")}</h3>
                        <LeaderboardTable />
                    </Col>


                </div>

                // When user is not eligible
                :

                <Col lg='6' style={{ paddingRight: '0' }} className="d-block d-md-block d-lg-block d-xl-block padding-verify-right">
                    <Card style={{ height: 'fit-content' }} >
                        <CardBody style={{ display: 'flex', flexDirection: 'column' }}>

                            {verification?.status !== 1 && (
                                <Card>
                                    <CardBody
                                        className="p-4"
                                        style={{ backgroundColor: '#ffc4c4', borderRadius: '5px' }}
                                    >
                                        <ResponsiveCard>
                                            <ResponsiveParagraph style={{ color: '#963737', fontWeight: 'bold' }}>
                                                {t("dashboard.yourProfileIsntVerified")}
                                            </ResponsiveParagraph>
                                            <ResponsiveVerifyButton
                                                color="primary"
                                                onClick={() => {
                                                    navigate(`${process.env.PUBLIC_URL}/account#verification`)
                                                }}
                                            >
                                                {t("dashboard.verify")}
                                            </ResponsiveVerifyButton>
                                        </ResponsiveCard>
                                        {verification?.status === 3 && verification?.reason && (
                                            <>
                                                <ResponsiveParagraph style={{ color: '#ab0505' }}>
                                                    {t("dashboard.yourRecentApplicationWasRejected")}
                                                </ResponsiveParagraph>
                                                <ResponsiveParagraph
                                                    style={{ color: '#ab0505' }}
                                                >{`${t("dashboard.reason")}: ${verification?.reason}`}</ResponsiveParagraph>
                                            </>
                                        )}
                                    </CardBody>
                                </Card>
                            )}

                            <div >
                                <h3>{t("event.YouAreNotEligible")}</h3>
                                <h3>{t("event.TermsAndConditions")}</h3>
                                <p className='m-t-20'>1. {t("event.AvailableToAllCollege")}</p>
                                <p>2. {t("event.ClientsMustHaveCompleted")}</p>
                                <p>3. {t("event.ClientsMustProvide")}</p>
                                <p>4. {t("event.ClientsCanOnlySubmit")}</p>
                                <p>5. {t("event.CampusCFDTradingContest")} </p>
                                <p>6. {t("event.AllTheSettings")} </p>

                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Link to='/support'>
                                        <Button style={{ width: '90%' }}>{t("event.CustomerSupport")}</Button>
                                    </Link>

                                    <a href={sessionStorage.getItem('language') == 'en'
                                        ? campusCompetition[0]?.terms_and_conditions
                                        : campusCompetition[0]?.terms_and_conditions_chinese}
                                        target="_blank">
                                        <Button style={{ width: '90%' }} color="primary" >{t("dashboard.TermsAndConditions")}</Button>
                                    </a>
                                </div>


                            </div>

                        </CardBody>
                    </Card>
                </Col>
            }

            {selectedSetting !== null && selectedAccount !== null && (
                <Modal isOpen={showModal} backdrop={true} centered={true}>
                    <ModalHeader toggle={toggleModal}>{settings[selectedSetting].title}</ModalHeader>
                    <ModalBody>{settings[selectedSetting].cta}</ModalBody>
                </Modal>
            )}
        </>
    )
}

export default Event