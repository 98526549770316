import React, { useEffect } from 'react'
import { Row, Col, Spinner, Card, CardBody } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import SweetAlert from 'sweetalert2'

import { HoverCard, ResponsiveCard, ResponsiveIcon, ResponsiveDiv } from '../../components.style'
import { useTranslation } from 'react-i18next'
import AccountService from '../../../../network/services/account'

const SelectAccount = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const liveAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter((e) => e.account_type !== 1)
    }
    return []
  })
  const deposit = useSelector((state) => state.deposit)

  useEffect(() => {
    if (liveAccounts?.length > 0 && deposit?.mt5?.account == null) {
      dispatch({ type: 'SET_DEPOSIT_MT5_ACCOUNT', account: liveAccounts[0].id })
    }
    // eslint-disable-next-line
  }, [liveAccounts])

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const init = async () => {
    const accountResponse = await AccountService.getAll()
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
  }

  return (
    <>
      <h5>{t('financial.selectMT5Account')}</h5>

      <Row>
        {liveAccounts != null ? (
          liveAccounts?.length > 0 &&
          liveAccounts.map((account) => {
            return (
              <Col sm="12" lg="6" key={account.id}>
                <HoverCard 
                   isSelected={deposit?.mt5?.account === account.id ? '#f9b600' : null}
                  onClick={() => {
                    if(account.balance >= 0 || account.balance <0){
                      dispatch({ type: 'SET_DEPOSIT_MT5_ACCOUNT', account: account.id })
                      dispatch({ type: 'SET_DEPOSIT_MT5_ACCOUNT_TYPE', account_type: account.account_type })
                      dispatch({ type: 'SET_DEPOSIT_MT5_ACCOUNT_PACKAGE_ID', account_package_id: account.package_id })}
                    // else{
                    //   SweetAlert.fire({
                    //     title: t("live.BalanceNegative"),
                    //     icon: 'error'
                    //   })
                    //   return null
                      
                    // }
                  }}
                >
                  <ResponsiveCard style={{backgroundColor:account.balance <0 ,borderRadius:'15px'}}>
                    <ResponsiveIcon className="icofont icofont-chart-line-alt" />
                    <ResponsiveDiv>
                      <h6>{`${account.account_login} - ${account.plan?.name}`}</h6>
                      <p style={{font:'13px'}}>{`${t('financial.availableBalanceInAccount')} ${
                        account.balance === null ? '-' : account.balance?.toFixed(2)
                      }`}</p>
                    </ResponsiveDiv>
                  </ResponsiveCard>
                </HoverCard>
              </Col>
            )
          })
        ) : (
          <Col>
            <Card>
              <CardBody style={{ textAlign: 'center' }}>
                <Spinner color="warning"/>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </>
  )
}

export default SelectAccount

// ${account.currency}
