import React, { useState, useRef, useEffect } from "react"
import { Badge } from 'reactstrap'
import DataTable from "react-data-table-component"
import moment from "moment"

import { useParams } from 'react-router-dom'

import PammManagerStatistic from '../../../network/services/pammManagerStatistic'
import { useTranslation } from 'react-i18next'


const TradeRecordsTable = () => {
  const { t } = useTranslation()
  const columns = [
    // {
    //   name: t("pamm.deal"),
    //   selector: "deal",
    //   sortable: true,
    //   width: '150px',

    //   format: (row) => {
    //     return row.deal ?? "-"
    //   }
    // },
    {
      name: t("pamm.type"),
      selector: "type",
      sortable: true,
      width: '150px',
      format: (row) => {
        switch (row.type) {
          case 0:
            return "Buy"
          case 1:
            return "Sell"
          default:
            return "-"
        }
      },
    },
    {
      name: t("pamm.volume"),
      selector: "volume",
      sortable: true,
      width: '150px',
      format: (row) => {
        return (row.volume / 10000).toFixed(2) ?? "-"
      }
    },
    {
      name: t("pamm.symbol"),
      selector: "symbol",
      sortable: true,
      width: '150px',
      format: (row) => {
        return row.symbol ?? "-"
      },
    },
    {
      name: t("pamm.dateOpen"),
      selector: "open_at",
      sortable: true,
      width: '200px',
      format: (row) => {
        return moment(row.open_at).utc().format("yyyy-MM-DD HH:mm")
      },
    },
    {
      name: t("pamm.dateClosed"),
      selector: "closed_at",
      sortable: true,
      width: '200px',
      format: (row) => {
        return moment(row.closed_at).utc().format("yyyy-MM-DD HH:mm")
      },
    },
    {
      name: t("pamm.priceClosed"),
      selector: "close_price",
      sortable: true,
      width: '150px',
      format: (row) => {
        return row.close_price.toFixed(row.digits) ?? "-"
      },
    },
    {
      name: t("pamm.profit"),
      selector: "profit",
      sortable: true,
      width: '300px',
      cell: row => <div style={{ color: row.profit < 0 ? 'red' : 'green' }}>{row.profit}USD {row?.position_close_count >= 2 && <Badge style={{ marginLeft: '5px' }}>Partial Close</Badge>}</div>,
    },

  ]

  const { id } = useParams()
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const wrapperRef = useRef()

  const handlePageChange = async (page) => {
    setIsBusy(true)
    const result = await PammManagerStatistic.get(id, { page: page })
    setData(result.trade_records?.data ?? [])
    setTotal(result.trade_records?.meta?.total ?? 0)
    setIsBusy(false)
  }

  useEffect(() => {
    const init = async () => {
      setIsBusy(true)

      const result = await PammManagerStatistic.get(id)


      setData(result.trade_records?.data ?? [])
      setTotal(result.trade_records?.meta?.total ?? 0)
      setIsBusy(false)
    }

    init()
  }, [])


  return (
    <div className="product-wrapper" ref={wrapperRef} >
      <div className="product-grid" style={{ minHeight: "50vh", marginTop: '15px' }}>
        <p style={{ color: '#103c5a', marginLeft: '5px' }}>{t("pamm.tradeRecord")}</p>
        {/* <p style={{ color: '#828282', marginLeft: '5px' }}>{t("pamm.onlyShow15LatestRecord")}</p> */}
        <div className="product-wrapper-grid" style={{ marginTop: 16, marginBottom: 16 }}>

          <DataTable
            noHeader
            data={data}
            columns={columns}
            striped={true}
            center={true}
            pagination
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={total}
            onChangePage={handlePageChange}
            progressPending={isBusy}
          />
        </div>
      </div>
    </div>
  )
}

export default TradeRecordsTable
