import React from "react";
import { Card, CardBody } from "reactstrap";
import BonusTable from "./table";

const Bonus = () => {
  return (
        <BonusTable />

  );
};

export default Bonus;
