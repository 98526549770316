import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'

import { HoverCard, ResponsiveCard, ResponsiveIcon, ResponsiveDiv } from './components.style'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import backgroundWelcomeBonus from './background-01.svg'
import WalletService from '../../network/services/wallet'

import "intro.js/introjs.css";
import { Steps, Hints } from 'intro.js-react';

const WalletOption = ({ setOption, type, hasWallet = true }) => {
  let history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector((state) => state.user.user)
  const [walletResult, setWalletResult] = useState(null)
  const totalDeposit = useSelector((state) => state?.wallet?.totalDeposit)
  const [firstDepositDone, setFirstDepositDone] = useState(false)
  const [redirectingStep, setRedirectingStep] = useState(null)
  const [navigationLink, setNavigationLink] = useState(null)

  const myAvailablePromotions = useSelector((state) => state.promotion.myAvailablePromotions)
  const firstDepositBonus = myAvailablePromotions.find((item) => item.id == 6)

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  const initCondition = () => {
    if (user.is_funnel_completed == true || user.first_deposit_status == 1) {
      setFirstDepositDone(true) //for existing user also consider done
    } else {
      if (user.first_deposit_status == 0) {
        if (user.is_profile_completed == false) {
          setRedirectingStep('profile')
          setNavigationLink(`${process.env.PUBLIC_URL}/account#details`)
        } else {
          if (user.verification_status != 1 && user.verification_status != 0) {
            setRedirectingStep('verification')
            setNavigationLink(`${process.env.PUBLIC_URL}/account#verification`)
          } else {
            setRedirectingStep('dashboard')
            setNavigationLink(`${process.env.PUBLIC_URL}/dashboard`)
          }
        }
      }
      setFirstDepositDone(false)
    }
  }

  const [totalDepositAndTransfer, setTotalDepositAndTransfer] = useState(0)

  const init = async () => {
    const walletResponse = await WalletService.get()
    dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })
    setWalletResult(walletResponse)
    const totalDepositAndTransfer =
      walletResponse?.total_deposit + walletResponse?.total_transfer_to_me
    setTotalDepositAndTransfer(totalDepositAndTransfer)
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const ResponsiveCardBanner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: center;
    }
  `

  const ResponsiveParagraph = styled.p`
    margin: 0;
    textalign: center;
    @media (max-width: 575px) {
      margin-bottom: 8px;
    }
  `
  const ResponsiveVerifyButton = styled(Button)`
    width: 200px !important;
    background-color: #103c5a !important;
    border-color: #103c5a !important;
    margin-left: 12px;
    @media (max-width: 575px) {
      align-self: center;
    }
  `

  useEffect(() => {
    initCondition()
    // eslint-disable-next-line
  }, [])

  const [stepsEnabled, setStepsEnabled] = useState(true)
  const steps = [
    {
      element: '#first-step',
      intro: t('intro.financial.step1'),
    },
    {
      element: '#wallet-deposit-option',
      intro: t('intro.financial.step2'),
    },
    {
      element: '#mt5-deposit-option',
      intro: t('intro.financial.step3'),
    }
  ]

  const stepsIncludeWelcomeBonus = [
    {
      element: '#first-step',
      intro: t('intro.financial.step1'),
    },
    {
      element: '#welcomeBonusBanner',
      intro: t('intro.financial.ThisIsBanner'),
    },
    {
      element: '#depositWalletButton',
      intro: t('intro.financial.Step1Deposit'),
    },
    {
      element: '#redeemMT5',
      intro: t('intro.financial.Step2Top'),
    },
    {
      element: '#wallet-deposit-option',
      intro: t('intro.financial.step2'),
    },
    {
      element: '#mt5-deposit-option',
      intro: t('intro.financial.step3'),
    }
  ]


  return (
    <>
      <h5 className="mb-3">
        {type === 'deposit'
          ? t('financial.depositTo')
          : type === 'withdraw'
            ? t('financial.withdrawFrom')
            : type === 'transfer'
              ? t('financial.transferTo')
              : t('financial.historyOf')}
      </h5>

      {type === 'deposit' && firstDepositBonus &&
        <Col lg="12">

          {/* <Card
            style={{
              backgroundColor: 'rgb(196 196 197 / 30%)',
              backgroundImage: `url(${backgroundWelcomeBonus})`,
              backgroundSize: 'cover',
              color: 'white'
            }}
            id='welcomeBonusBanner'
          >
            <CardBody style={{ height: 'fit-content', padding: '20px 40px' }}>
              <Row style={{ height: 'fit-content', alignItems: 'center' }}>
                <Col>
                  <h1 style={{ fontWeight: 'normal' }}>{t('dashboard.WelcomeBonusRedeem')}</h1>
                  <p>{t('financial.FollowThese2')}</p>

                  <div id='div-stater-banner' >

                    {totalDepositAndTransfer === 0 &&
                      <Button
                        color="primary"
                        className="m-r-20"
                        onClick={() => setOption(1)}
                        id='depositWalletButton'
                      >
                        1. {t('financial.DepositToWallet')}
                      </Button>
                    }


                    <Button
                      color="primary"
                      className="m-r-20"
                      onClick={() => setOption(2)}
                      id='redeemMT5'
                    >
                      {totalDepositAndTransfer === 0 ? '2.' : ''} {t('financial.RedeemToMT5')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card> */}



        </Col>
      }
      <Row>
        {/* {hasWallet && firstDepositDone == false && type === 'deposit' ? (
          // user.first_deposit_status != 0 ? (
          //   <>
          //     {user.first_deposit_status == 2 && (
          //       <Card style={{ width: '100%', margin: '0px 15px 30px 15px' }}>
          //         <CardBody
          //           className="p-4"
          //           style={{ borderRadius: '5px', backgroundColor: '#f8d7d9' }}
          //         >
          //           <ResponsiveCardBanner>
          //             <ResponsiveParagraph style={{ color: '#7e2a26', fontWeight: 'bold' }}>
          //               {t('financial.FirstDepositFailed')}
          //             </ResponsiveParagraph>
          //           </ResponsiveCardBanner>
          //         </CardBody>
          //       </Card>
          //     )}
          <Col lg="12">
            <a href="/welcomeBonus">
              <Card
                style={{
                  backgroundColor: 'rgb(196 196 197 / 30%)',
                  backgroundImage: `url(${backgroundWelcomeBonus})`,
                  backgroundSize: 'cover',
                  color: 'white',
                  cursor: 'pointer'
                }}
              >
                <CardBody style={{ height: '250px' }}>
                  <Row style={{ height: '160px', alignItems: 'center' }}>
                    <Col>
                      <h1 style={{ fontWeight: 'normal' }}>{t('dashboard.WelcomeBonusRedeem')}</h1>
                      <p>{t('dashboard.RedeemToUnlock')}</p>
                      <Button
                        color="primary"

                      >
                        {t('dashboard.RedeemBonus')}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </a>
          </Col>
        ) : (
          // </>
          // ) : (
          //     <Card style={{ width: '100%', margin: '0px 15px 30px 15px' }}>
          //       <CardBody className="p-4" style={{ borderRadius: '5px', backgroundColor: '#f9b601' }}>
          //         <ResponsiveCardBanner>
          //           <ResponsiveParagraph style={{ color: 'black', fontWeight: 'bold' }}>
          //             {t('financial.FirstDepositPending')}
          //           </ResponsiveParagraph>
          //           <ResponsiveVerifyButton
          //             color="primary"
          //             onClick={() => {
          //               navigate(navigationLink ?? '/dashboard')
          //             }}
          //           >
          //             {redirectingStep == 'profile'
          //               ? t('financial.FillInPersonalDetails')
          //               : redirectingStep == 'verification'
          //                 ? t('financial.FillInVerification')
          //                 : t('financial.GoToDashboard')}
          //           </ResponsiveVerifyButton>
          //         </ResponsiveCardBanner>
          //       </CardBody>
          //     </Card>
          //   )
          ''
        )} */}

        {/* {firstDepositDone == false && user.first_deposit_status != 0 && type !== 'deposit' ? (
          <Col lg="12">
            <a href="/welcomeBonus">
              <Card
                style={{
                  backgroundColor: 'rgb(196 196 197 / 30%)',
                  backgroundImage: `url(${backgroundWelcomeBonus})`,
                  backgroundSize: 'cover',
                  color: 'white',
                  cursor: 'pointer'
                }}
              >
                <CardBody style={{ height: '250px' }}>
                  <Row style={{ height: '160px', alignItems: 'center' }}>
                    <Col>
                      <h1 style={{ fontWeight: 'normal' }}>{t('dashboard.WelcomeBonusRedeem')}</h1>
                      <p>{t('dashboard.RedeemToUnlock')}</p>
                      <Button
                        color="primary"
                      >
                        {t('dashboard.RedeemBonus')}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </a>
          </Col>
        ) : (
          user.first_deposit_status == 0 &&
          type !== 'deposit' && (
            <Card style={{ width: '100%', margin: '0px 15px 30px 15px' }}>
              <CardBody className="p-4" style={{ borderRadius: '5px', backgroundColor: '#f9b601' }}>
                <ResponsiveCardBanner>
                  <ResponsiveParagraph style={{ color: 'black', fontWeight: 'bold' }}>
                    {t('financial.FirstDepositPending')}
                  </ResponsiveParagraph>
                  <ResponsiveVerifyButton
                    color="primary"
                    onClick={() => {
                      navigate(navigationLink ?? '/dashboard')
                    }}
                  >
                    {redirectingStep == 'profile'
                      ? t('financial.FillInPersonalDetails')
                      : redirectingStep == 'verification'
                      ? t('financial.FillInVerification')
                      : t('financial.GoToDashboard')}
                  </ResponsiveVerifyButton>
                </ResponsiveCardBanner>
              </CardBody>
            </Card>
          )
        )} */}

        {hasWallet && (
          <Col sm="12" md="6" >
            <HoverCard id='wallet-deposit-option'>
              <ResponsiveCard onClick={() => setOption(1)}>
                <ResponsiveIcon className="icofont icofont-wallet" />
                <ResponsiveDiv >
                  <h6>
                    {type === 'deposit'
                      ? t('financial.DepositTo') + t('financial.wallet')
                      : type === 'withdraw'
                        ? t('financial.WithdawFrom') + t('financial.wallet')
                        : type === 'transfer'
                          ? t('financial.TransferBetween') + t('financial.wallet')
                          : t('financial.wallet')
                    }
                  </h6>
                  {/* <p>
                    {type === 'deposit'
                      ? t('financial.viaCryptocurrencyOrBankTransfer')
                      : type === 'withdraw'
                        ? t('financial.toBankOrCryptocurrency')
                        : type === 'transfer'
                          ? t('financial.ofOtherUsers')
                          : t('financial.transactions')}
                  </p> */}
                </ResponsiveDiv>
              </ResponsiveCard>
            </HoverCard>
          </Col>
        )}

        {(
          <Col sm="12" md="6" >
            <HoverCard id='mt5-deposit-option'>
              <ResponsiveCard onClick={() => setOption(2)}>
                <ResponsiveIcon className="icofont icofont-chart-line-alt" />
                <ResponsiveDiv >
                  <h6>
                    {type === 'deposit'
                      ? t('financial.DepositTo') + t('financial.MT5Account')
                      : type === 'withdraw'
                        ? t('financial.WithdawFrom') + t('financial.MT5Account')
                        : type === 'transfer'
                          ? t('financial.TransferBetween') + t('financial.MT5Account')
                          : t('financial.MT5Account')
                    }


                  </h6>
                  {/* <p>
                    {type === 'deposit'
                      ? t('financial.toStartYourInvestment')
                      : type === 'withdraw'
                        ? t('financial.toYourWallet')
                        : type === 'transfer'
                          ? t('financial.betweenYourOwnMT5Accounts')
                          : t('financial.transactions')}
                  </p> */}
                </ResponsiveDiv>
              </ResponsiveCard>
            </HoverCard>
          </Col>
        )}
      </Row>

      { localStorage.getItem('financial-onboarding') === null && firstDepositBonus ?
        <Steps
          enabled={stepsEnabled}
          steps={stepsIncludeWelcomeBonus}
          initialStep={0}
          onExit={() => {
            localStorage.setItem('financial-onboarding', true)
            setStepsEnabled(false)
          }}
          options={
            {
              showProgress: true,
              doneLabel: 'Understand',
              exitOnOverlayClick: false
            }
          }
        />
        :
        localStorage.getItem('financial-onboarding') === null &&
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={0}
          onExit={() => {
            localStorage.setItem('financial-onboarding', true)
            setStepsEnabled(false)
          }}
          options={
            {
              showProgress: true,
              doneLabel: 'Understand',
              exitOnOverlayClick: false
            }
          }
        />
      }
    </>
  )
}

export default WalletOption
