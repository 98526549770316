import React, { useState } from 'react'
import { Container, Row, Col, Card } from 'reactstrap'

import Breadcrumb from '../../layout/breadcrumb'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { Link } from 'react-router-dom'

const CustomerSupport = (props) => {

  let history = useHistory()
  const dispatch = useDispatch()

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }
  const { t } = useTranslation()
  const faqItems = [

    {
      heading: t('faqs.HowToSubscribePAMM'),
      engLink: 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tutorial_pdf/PammENG.pdf',
      chiLink: 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tutorial_pdf/PammCHI.pdf'
    },
    {
      heading: t('faqs.HowToDepositThroughUSDT'),
      engLink: 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tutorial_pdf/UsdtENG.pptx.pdf',
      chiLink: 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tutorial_pdf/usdtCHI.pdf'
    },
    {
      heading: t('faqs.HowToApplyStarterPack'),
      engLink: 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tutorial_pdf/starterENG.pdf',
      chiLink: 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tutorial_pdf/starterCHI.pdf'
    },
    {
      heading: t('faqs.HowToApplyForReferAndEarn'),
      engLink: 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tutorial_pdf/ReferCHI.pdf',
      chiLink: 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/tutorial_pdf/ReferENG.pdf'
    }
  ]

  return (
    <>
      <Breadcrumb title={t('faqs.FrequentlyAskedQuestions')} />
      <Container>
        <Row>

          <Col lg='7' sm="12">
            <Card style={{
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
            }}>
              <Accordion allowZeroExpanded allowMultipleExpanded>
                {faqItems.map((item, index) => (
                  <AccordionItem key={index}  >
                    <AccordionItemHeading>
                      <AccordionItemButton style={{ backgroundColor: '#FFF' }}>
                        {item.heading}
                      </AccordionItemButton>

                    </AccordionItemHeading>
                    <AccordionItemPanel>

                      <a
                        href={sessionStorage.getItem('language') === 'en' ? item.engLink : item.chiLink}
                        target='_blank'
                        style={{ textTransform: 'capitalize' }}
                      >
                        {t('live.ClickForMoreDetails')}{t('faqs.About')}{item?.heading}
                      </a>

                    </AccordionItemPanel>

                  </AccordionItem>
                ))}
              </Accordion>
            </Card>
          </Col>


        </Row>
        <Row>

          <Col>
            <h6>{t('faqs.NeedMoreHelpContact')}
              <span
                style={{ color: "#f9b600", cursor: 'pointer' }}
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/support`)
                }} >
                {t('faqs.CustomerSupport')}
              </span>
              {t('faqs.Now')}
            </h6>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CustomerSupport
