import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { Row, Col, Card, CardHeader, Input } from 'reactstrap'

import TransactionService from '../../../../network/services/transaction'
import HistoryFilters from './filters'
import { useTranslation } from 'react-i18next'

const HistoryTable = () => {
  const { t } = useTranslation()
  const columns = [
    {
      name: t('financial.ticket'),
      selector: 'id',
      sortable: true
    },
    {
      name: t('financial.date'),
      selector: 'created_at',
      sortable: true,
      width: '200px',
      format: (row) => {
        return moment(row.created_at).format('yyyy-MM-DD hh:mmA')
      }
    },
    {
      name: t('financial.type'),
      selector: 'txn_type',
      sortable: true,
      width: '200px',
      format: (row) => {
        switch (row.txn_type) {
          case 0:
            return t('financial.depositToWallet')
          case 1:
            return t('financial.withdrawFromWallet')
          case 2:
            return t('financial.transferOfWallet')
          case 3:
            return t('dashboard.ReferralProgram')

          case 10:
            return t('financial.depositToMT5')
          case 11:
            return t('financial.withdrawFromMT5')
          case 20:
            return t('financial.walletAdjustment')
          default:
            return '-'
        }
      }
    },
    {
      name: t('financial.method'),
      selector: 'payment_method',
      width: '200px',
      format: (row) => {
        switch (row.txn_type) {
          case 0:
            return row?.deposit?.payment_method ?? ''
          case 1:
            return row?.withdraw?.payment_method ?? ''
          case 2:
            return t('financial.transferOfWallet')
          case 10:
            return t('financial.depositToMT5')
          case 11:
            return t('financial.withdrawFromMT5')
          case 20:
            return t('financial.walletAdjustment')
          default:
            return ''
        }
      }
    },
    {
      name: t('financial.currency'),
      selector: 'currency_unit',
      sortable: true
    },
    {
      name: t('financial.amount'),
      selector: 'amount',
      sortable: true,
      right: true,
      format: (row) => {
        return row.debit_amount > 0
          ? `+${row.debit_amount?.toFixed(2)}`
          : `-${row.credit_amount?.toFixed(2)}`
      }
    },
    {
      name: t('financial.status'),
      selector: 'status',
      sortable: true,

      format: (row) => {
        switch (row.status) {
          case 0:
            return (
              <span style={{ color: '#f9b600', fontWeight: 'bold' }}>{t('financial.pending')}</span>
            )
          case 1:
            return (
              <span style={{ color: 'green', fontWeight: 'bold' }}>{t('financial.success')}</span>
            )
          case 2:
            return <span style={{ color: 'red', fontWeight: 'bold' }}>{t('financial.failed')}</span>
          case 3:
            return (
              <span style={{ color: 'red', fontWeight: 'bold' }}>{t('financial.rejected')}</span>
            )
          case 4:
            return (
              <span style={{ color: '#f9b600', fontWeight: 'bold' }}>{t('financial.pending')}</span>
            )
          default:
            return '-'
        }
      }
    },
    {
      name: t('financial.reason'),
      selector: 'reason',
      sortable: true,
      width: '400px',
      format: (row) => {
        switch (row.txn_type) {
          case 0:
            return row?.deposit?.reason
          case 1:
            return row?.withdraw?.reason ?? ''
          case 2:
            return row?.transfer?.reason ?? ''
          case 10:
            return row?.account_deposit?.reason ?? ''
          case 11:
            return row?.account_withdraw?.reason ?? ''
          default:
            return ''
        }
      }
    }
  ]

  const types = [
    {
      id: -1,
      name: t('financial.all')
    },
    {
      id: 0,
      name: t('financial.deposit')
    },
    {
      id: 1,
      name: t('financial.withdraw')
    },
    {
      id: 2,
      name: t('financial.transfer')
    }
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [sidebaron, setSidebaron] = useState(true)
  const [filters, setFilters] = useState(null)
  const [searchKeyword, setSearchKeyword] = useState('')
  const wrapperRef = useRef()

  const handlePageChange = async (page) => {
    setIsBusy(true)
    const result = await TransactionService.getAll({ page: page, filters: filters })
    setData(result.transactions.data)
    setTotal(result.transactions?.meta?.total ?? 0)
    setIsBusy(false)
  }

  const handleFilter = async () => {
    setIsBusy(true)
    const result = await TransactionService.getAll({
      filters: filters
    })
    setData(result.transactions.data)
    setTotal(result.transactions?.meta?.total ?? 0)
    setIsBusy(false)
  }

  const init = async () => {
    try {
      setIsBusy(true)
      const result = await TransactionService.getAll()
      // console.log(result.transactions)
      setData(result.transactions?.data ?? [])
      setTotal(result.transactions?.meta?.total ?? 0)
      setIsBusy(false)
    } catch (error) {
      // console.log(error)
      setIsBusy(false)
    }
  }

  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false)
      wrapperRef.current.classList.add('sidebaron')
    } else {
      setSidebaron(true)
      wrapperRef.current.classList.remove('sidebaron')
    }
  }

  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword)
    setFilters({
      ...filters,
      wallet_code: keyword
    })
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    handleFilter()
    // eslint-disable-next-line
  }, [filters])

  return (
    <div className="product-wrapper" ref={wrapperRef}>
      <div className="product-grid" style={{ minHeight: '50vh' }}>
        <Row>
          <Col xl="3">
            <div className="product-sidebar">
              <div className="filter-section">
                <Card className="m-0">
                  <CardHeader>
                    <h6 className="mb-0 f-w-600">
                      {t('financial.filters')}
                      <span className="pull-right">
                        <i className="fa fa-chevron-down toggle-data" onClick={onClickFilter}></i>
                      </span>
                    </h6>
                  </CardHeader>
                  <div className="left-filter">
                    <div style={{ padding: 16 }} />
                    <HistoryFilters types={types} handleFilter={setFilters} filters={filters} />
                  </div>
                </Card>
              </div>
            </div>
          </Col>
          <Col xl="9" sm="12">
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Input
                className="form-control"
                type="text"
                placeholder="Search Wallet"
                defaultValue={searchKeyword}
                onChange={(e) => handleSearchKeyword(e.target.value)}
              />
            </div>
          </Col>
        </Row>

        <div className="product-wrapper-grid" style={{ marginTop: 16 }}>
          <DataTable
            noHeader
            data={data}
            columns={columns}
            striped={true}
            center={true}
            pagination
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={total}
            onChangePage={handlePageChange}
            progressPending={isBusy}
          />
        </div>
      </div>
    </div>
  )
}

export default HistoryTable
