import React from "react"
import { Card, CardHeader, CardBody, Col, Row, Button, Spinner } from "reactstrap"
import { useSelector } from "react-redux"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { toast } from "react-toastify"
import { useTranslation } from 'react-i18next'

const ReferralModal = () => {
  const { t } = useTranslation()
  const partners = useSelector((state) => state.user.partners)

  return (
    <div>
      {partners != null ? (
        partners?.length > 0 ? (
          partners.map((partner) => {
            return (
              <Card key={partner.id}>
                <CardHeader className="bg-secondary" style={{ padding: 20 }}>
                  <h5 className="text-center">{t("dashboard.IB")} -{partner?.ib_code}</h5>
                </CardHeader>
                <CardBody style={{ padding: 16, textAlign: "center" }}>
                  <p>{process.env.REACT_APP_BASE_URL}/sign_up?referral_code={partner?.referral_code}</p>
                  <Row style={{ marginTop: 12 }}>
                    <Col className="text-right">
                      <CopyToClipboard
                        text={
                          `${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=` +
                          partner?.referral_code
                        }
                      >
                        <Button
                          color="primary"
                          onClick={() => {
                            toast.success(t("dashboard.linkCopiedToClipboard"), {
                              position: toast.POSITION.BOTTOM_RIGHT,
                            })
                          }}
                        >
                          {t("dashboard.copyLink")} 
                        </Button>
                      </CopyToClipboard>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )
          })
        ) : (
          <p>{t("dashboard.noRecordFound")} </p>
        )
      ) : (
        <div style={{ textAlign: "center" }}>
          <Spinner />
        </div>
      )}

      <div style={{ textAlign: "center" }}>
        <h5>{t("dashboard.howItWorks")}</h5>
        <Row>
          <Col>
            <img src="/refer_1.svg" alt="refer_1" style={{ height: 50, marginBottom: 12 }} />
            <p>{t("dashboard.shareYourUniqueLink")}</p>
          </Col>
          <Col>
            <img src="/refer_2.svg" alt="refer_2" style={{ height: 50, marginBottom: 12 }} />
            <p>{t("dashboard.yourFriendsBecomeTraders")}</p>
          </Col>
          <Col>
            <img src="/refer_3.svg" alt="refer_3" style={{ height: 50, marginBottom: 12 }} />
            <p>{t("dashboard.youGetRewarded")}</p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ReferralModal
