import React, { useState, useEffect } from 'react'

import {
    Modal,
    ModalBody,
    Row,
    Input,
    Form,
    Spinner,
    Button

} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import AccountService from '../../../../network/services/account'
import VoucherService from '../../../../network/services/voucher'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import SweetAlert, { swal } from 'sweetalert2'



const VeCourseVoucher = (props) => {


    const { t } = useTranslation()
    const [qualifyAccounts, setQualifyAccounts] = useState()
    const [isBusy, setIsBusy] = useState(false)
    const { register, handleSubmit, errors, reset } = useForm()
    const [selectedAccount, setSelectedAccount] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })


    const onClaim = async data => {


        try {
            if (data !== '') {
                setIsSubmitting(true)
                const result = await VoucherService.applyVoucher({
                    voucher_id: props?.voucherDetails?.id,
                    account_id: null
                })
                SweetAlert.fire({
                    title: t("voucher.VoucherSuccessfullyClaimed"),
                    html: t("voucher.CheckYouClaimedCoursesReward"),
                    icon: 'success'
                }).then(() => {
                    window.location.href = '/courses'
                    setIsSubmitting(false)
                })
            } else {
                errors.showMessages()
            }

        } catch (error) {
            setIsSubmitting(false)
            const errorCode = error.code
            const errorTranslate = error.translate_params
            SweetAlert.fire({
                title: t(errorCode, errorTranslate),
                icon: 'error'
            })

        }

    }

    return (
        <>
            <Modal isOpen={props?.openModal} centered={true} toggle={props.toggleModal} >
                <ModalBody
                    style={{
                        padding: '0'
                    }}
                    id="CnyModalBody"
                >
                    <div>
                        <img src={props?.voucherDetails?.promocode?.image_url} style={{ maxWidth: '100%' }} />
                        <Row style={{ justifyContent: 'center', margin: '20px 0px 0px 20px' }}>
                            <div style={{ textAlign: 'left' }}>
                                <Form className="theme-form" onSubmit={handleSubmit(onClaim)} style={{ paddingRight: '20px' }}>

                                    <>
                                        <h4 style={{ fontWeight: 400 }} >{props?.voucherDetails?.promocode?.description}</h4>

                                        <div style={{ textAlign: 'left' }}>
                                            <p style={{ marginBottom: '5px' }}>{t("dashboard.TermsAndConditions")}</p>

                                            <p style = {{whiteSpace: 'pre-wrap'}}>{sessionStorage.getItem('language') == 'en' ? props?.voucherDetails?.promocode?.tnc_english : props?.voucherDetails?.promocode?.tnc_chinese}</p>

                                        </div>

                                        {!isBusy ? (

                                            <>
                                                <div>

                                                    <div style={{ marginTop: '10px' }}>
                                                        <Button
                                                            style={{ width: '100%' }}
                                                            disabled={isSubmitting}
                                                        >
                                                            {t('dashboard.claim')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>

                                        ) : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Spinner color="warning" />
                                                </div>
                                            )}
                                    </>

                                </Form>
                            </div>
                        </Row>
                        <Row style={{ justifyContent: 'center', margin: '10px 0px 0px 20px' }}>
                            <div>
                            <a href={props?.voucherDetails?.promocode?.proceed_url} style={{ textDecoration: 'underline' }} target='_blank' rel="noopener noreferrer">
                                    {t('dashboard.moreDetails')}
                                </a>
                            </div>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default VeCourseVoucher