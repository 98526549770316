import React, { useState, useEffect } from 'react'
import { Row, Col, Card, FormGroup, Spinner, Input, Button, Form } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { useForm } from 'react-hook-form'
import AccountService from '../../../network/services/account'
import EventService from '../../../network/services/event'
import SweetAlert from 'sweetalert2'

import FriendBanner from './LandingPage.png'

import MobileBanner from './mobileLandingPage.png'

import DesktopDetails from './desktop.png'
import MobileDetails from './mobile.png'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import './landing.scss'


const LandingPage = () => {

    const { t } = useTranslation()
    const { register, handleSubmit, errors, reset } = useForm()

    const [qualifyAccounts, setQualifyAccounts] = useState()
    const [myEventDetails, setMyEventDetails] = useState(null)
    const [isBusy, setIsBusy] = useState(false)

    const init = async () => {
        setIsBusy(true)
        const accountResponse = await AccountService.getAll()
        const eventResponse = await EventService.carnivalDetails(1)

        setQualifyAccounts(
            accountResponse?.accounts?.filter((acc) => {
                return (acc?.account_type === 0 || acc?.account_type === 2) && (acc?.package_id !== 7 && acc?.package_id !== 9)
            })
        )

        setMyEventDetails(eventResponse?.event_participation)
        setIsBusy(false)
    }


    useEffect(() => {
        init()
    }, [])

    const faqItems = [
        {
            heading: t("carnival.HowCanIBeRewarded"),
            content: t("carnival.AfterYouComplete3missions")
        },
        {
            heading: t("carnival.WhatIsFreeCredit100For"),
            content: t("carnival.ThisCreditIsForIncreasing")
        },
        {
            heading: t("carnival.HowToCompleteMissions"),
            content: t("carnival.EveryParticipantHasToComplete")
        },
        {
            heading: t("carnival.IfIMissedOutOn"),
            content: t("carnival.NoYouWillBeDropped")

        },
        {
            heading: t("carnival.CanIJoinThe"),
            content: t("carnival.YouCanOnlySignUp"),
        },
        {
            heading: t("carnival.HowCanIGetThe"),
            content: t("carnival.AfterYouCompleteThe"),
        },
        {
            heading: t("carnival.HowToGetLimitedEdition"),
            content: t("carnival.EveryParticipantWillBe"),
        }
    ]

    const registerCarnival = async (data) => {

        try {
            if (data !== '') {
                const result = await EventService.registerCarnival({
                    account_id: data?.account_id,
                    event_id: 1
                })


                if (result) {

                    SweetAlert.fire({
                        title: t("carnival.ThanksForYourParticipation"),
                        text: t("carnival.WishYouHaveAVictoryAtTheCarnival"),
                        icon: 'success'
                    }).then(() => {
                        window.location.reload()
                    })
                }
            } else {
                errors.showMessages()
            }
        } catch (error) {

            const errorCode = error.code
            const errorTranslate = error.translate_params
            SweetAlert.fire({
                title: t(errorCode, errorTranslate),
                icon: 'error'
            })
        }
    }

    return (
        <Form className="theme-form" onSubmit={handleSubmit(registerCarnival)}>
            <Row style={{ backgroundColor: "#0281fa", margin: '0px', borderRadius: '15px', paddingTop: '15px', paddingBottom: '15px' }}>

                {/* Desktop Version */}
                <Col lg='12' style={{ marginBottom: '25px', color: 'black' }} >
                    <Row>
                        <Col sm='12'>
                            <img style={{ width: '100%' }} src={MobileBanner} alt='banner' />
                        </Col>
                        <Col sm='12'>
                            <div id='div-register-carnival' style={{ textAlign: 'center' }}>

                                {!isBusy ?

                                    myEventDetails === null ?
                                        <>
                                            {/* <h2 style={{ color: 'white' }} >{t("carnival.ChallengeAnd")}<br /> {t("carnival.EarnYourNFTReward")}</h2>

                                            <div style={{ width: "100%", marginRight: '20px', display: 'flex', justifyContent: 'center' }}>
                                                <div >
                                                    <Input
                                                        className="form-control"
                                                        type="select"
                                                        name="account_id"
                                                        innerRef={register({ required: true })}
                                                    >
                                                        <option value="" >
                                                            {t("carnival.ChallengeAnd")}
                                                        </option>

                                                        {qualifyAccounts?.map((account) => {

                                                            return <option key={account?.id} value={account?.id}>{account?.account_login} - ${account.balance}</option>
                                                        })}
                                                    </Input>

                                                </div>


                                            </div>


                                            <FormGroup className="mb-0">
                                                <Button color="primary" className="mt-2" >
                                                    {t("carnival.JoinNow")}
                                                </Button>
                                            </FormGroup> */}

                                            <h2 style={{ color: '#f9b600' }} >Registration closed</h2>


                                        </>
                                        :
                                        ''
                                    :
                                    <div
                                        style={{

                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Spinner color="warning" />

                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </Col>



                <Col lg='6'>

                    <Card style={{ padding: '10px', backgroundColor: '#0281fa', color: 'white' }}>
                        <h4>{t("carnival.ChallengesAndRewards")}</h4>
                        <Col lg='12' style={{ marginBottom: '20px', padding: '0px' }}>
                            <img style={{ width: '100%' }} src={DesktopDetails} alt='reward and challenge details' />
                        </Col>
                    </Card>
                </Col>

                <Col lg='6'>
                    <Card style={{ padding: '10px', backgroundColor: '#0281fa', color: 'white' }}>
                        <h4>{t("event.TermsAndConditions")}</h4>
                        <p>1. {t("carnival.ParticipantsMustRegisterBefore")}</p>
                        <p>2. {t("carnival.TheTradingAccountWith")}</p>
                        <p>3. {t("carnival.OnTheLastTradingDayOfEvery")}</p>
                        <p>4. {t("carnival.ParticipantsWhoFailedToComplete")}</p>
                        <p>5. {t("carnival.ThereWillBeNoDeposit")}</p>
                        <p>6. {t("carnival.TheCalculationOfTheMostProfit")}
                            <br />
                                    a) {t("carnival.OnlyTradesThatAreOpenAnd")}
                            <br />
                                    b) {t("carnival.FloatingOrdersWillBeExcluded")}
                            <br />
                                    c) {t("carnival.TheOrdersThatAreNotIncluded")}
                        </p>

                        <br />



                        <a style={{ textAlign: 'center' }} href='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/T%26C+TradeHall+Carnival.pdf' target='_blank' rel="noopener noreferrer">
                            {t("dashboard.View")}
                        </a>
                    </Card>


                </Col>

                <Col lg='12' style={{ marginBottom: '20px' }}>
                    <Card style={{ padding: '30px', backgroundColor: '#0281fa', color: 'white' }}>

                        <h5 style={{ textAlign: 'left', marginBottom: '25px' }}>{t('live.FrequentlyAskedQuestions')}</h5>

                        <Accordion allowZeroExpanded allowMultipleExpanded>
                            {faqItems.map((item, index) => (
                                <AccordionItem key={index}  >
                                    <AccordionItemHeading>
                                        <AccordionItemButton style={{ backgroundColor: '#FFF' }}>
                                            {item.heading}
                                        </AccordionItemButton>

                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        {item.content}
                                        {item.heading === t('live.WhatBenefitsCanIget') &&
                                            <a href={sessionStorage.getItem('language') === 'en' ? 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/T%26C+TradeHall+Carnival.pdf' : 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/T%26C+TradeHall+Carnival.pdf'} target='_blank'>
                                                {t('live.ClickForMoreDetails')}
                                            </a>
                                        }
                                    </AccordionItemPanel>

                                </AccordionItem>
                            ))}
                        </Accordion>
                    </Card>
                </Col>


            </Row>
        </Form>

    )
}

export default LandingPage