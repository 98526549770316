import {
  SET_WALLETS,
  SET_BALANCE,
  SET_TOTAL_DEPOSIT,
  SET_TOTAL_WITHDRAWAL,
  SET_TOTAL_ACCOUNT_DEPOSIT,
  SET_TOTAL_ACCOUNT_WITHDRAWAL
} from '../actionTypes'

const initial_state = {
  wallets: [],
  balance: 0,
  totalDeposit: 0,
  totalWithdrawal: 0,
  totalAccountDeposit: 0,
  totalAccountWithdraw: 0
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_WALLETS:
      return { ...state, wallets: action.wallets }

    case SET_BALANCE:
      return { ...state, balance: action.balance?.toFixed(2) }

    case SET_TOTAL_DEPOSIT:
      return { ...state, totalDeposit: action.totalDeposit }

    case SET_TOTAL_WITHDRAWAL:
      return { ...state, totalWithdrawal: action.totalWithdrawal }

    case SET_TOTAL_ACCOUNT_DEPOSIT:
      return { ...state, totalAccountDeposit: action.totalAccountDeposit }

    case SET_TOTAL_ACCOUNT_WITHDRAWAL:
      return {
        ...state,
        totalAccountWithdrawal: action.totalAccountWithdrawal
      }

    default:
      return { ...state }
  }
}
