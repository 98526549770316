import React from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, FormText, Input } from 'reactstrap'
import styled from 'styled-components'

import Passport from './identity/passport'
import NationalId from './identity/national_id'
// import DrivingLicense from './identity/driving_license'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const MobileGap = styled.div`
  padding: 0;

  @media (max-width: 575px) {
    padding: 10px;
  }
`

const IdentityUpload = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const activeTab = useSelector((state) => state.verification.activeTab)
  const idFront = useSelector((state) => state.verification.idFront)
  const idBack = useSelector((state) => state.verification.idBack)

  return (
    <>
      <Nav className="nav-pills nav-primary">
        <NavItem>
          <NavLink
            className={activeTab === 'passport' ? 'active' : ''}
            onClick={() => dispatch({ type: 'SET_ACTIVE_VERIFICATION', activeTab: 'passport' })}
            style={{ cursor: 'pointer' }}
          >
            {t("user.Passport")}
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={activeTab === 'license' ? 'active' : ''}
            onClick={() => dispatch({ type: 'SET_ACTIVE_VERIFICATION', activeTab: 'license' })}
            style={{ cursor: 'pointer' }}
          >
            Driving License
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            className={activeTab === 'id' ? 'active' : ''}
            onClick={() => dispatch({ type: 'SET_ACTIVE_VERIFICATION', activeTab: 'id' })}
            style={{ cursor: 'pointer' }}
          >
            {t("user.NationalID")}
          </NavLink>
        </NavItem>
      </Nav>

      <div style={{ padding: 16 }} />

      <TabContent activeTab={activeTab}>
        <TabPane tabId="passport">
          <Passport />
        </TabPane>
        {/* <TabPane tabId="license">
          <DrivingLicense />
        </TabPane> */}
        <TabPane tabId="id">
          <NationalId />
        </TabPane>
      </TabContent>

      <hr />
      <p>{t("user.FrontAndBackOfYour")} {activeTab} {t("user.proof")}</p>

      <Row>
        <Col sm={12} md={6}>
          <FormText color="muted" style={{ textTransform: 'capitalize' }}>
            {activeTab} {t("user.Front")}
          </FormText>
          <Input
            type="file"
            name="file"
            accept="image/png, image/jpeg, application/pdf"
            onChange={(e) => {
              dispatch({ type: 'SET_ID_FRONT', idFront: { file: e?.currentTarget?.files[0] } })
            }}
          />
          <FormText color="muted">.jpg, .png, pdf, max 10 MB</FormText>
          <span style={{ color: 'red' }}>{idFront?.error != null && idFront.error}</span>
          <MobileGap />
        </Col>
        <Col sm={12} md={6}>
          <FormText color="muted" style={{ textTransform: 'capitalize' }}>
            {activeTab} {t("user.Back")}
          </FormText>
          <Input
            type="file"
            name="file"
            accept="image/png, image/jpeg, application/pdf"
            onChange={(e) => {
              dispatch({ type: 'SET_ID_BACK', idBack: { file: e?.currentTarget?.files[0] } })
            }}
          />
          <FormText color="muted">.jpg, .png, pdf, max 10 MB</FormText>
          <span style={{ color: 'red' }}>{idBack?.error != null && idBack.error}</span>
        </Col>
      </Row>
    </>
  )
}

export default IdentityUpload
