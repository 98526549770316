import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

import Breadcrumb from "../../layout/breadcrumb";
import RiskTable from "./table";
import { useTranslation } from 'react-i18next'

const RiskManagement = (props) => {
  const { t } = useTranslation()
  return (
    <>
      <Breadcrumb title={t("risk.riskManagement")} />
      <Container>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{t("risk.riskManagement")}</h5>
              </CardHeader>
              <CardBody>
                <RiskTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RiskManagement;
