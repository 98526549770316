import client from '../request'

const getAll = (props = null) => {
//   let page = props?.page ?? 1
//   let limit = props?.limit ?? 10
//   let sort = props?.sort ?? 'created_at:desc'
//   let filters = props?.filters ?? ''

//  return client.get(`/me/feedbacks?page=${page}&limit=${limit}&sort=${sort}${filters}`)
    return client.get('/pamms/subscriptions')
}

// const get = (id) => {
//   return client.get(`/me/feedbacks/${id}`)
// }

// const create = (data) => {
//   return client.post(`/me/feedbacks`, data)
// }

// const reply = (id, data) => {
//   return client.post(`/me/feedbacks/${id}/replies`, data)
// }

export default {
  getAll,
//   get,
//   create,
//   reply
}
