import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import UserDetail from './details'
import BankDetail from './bank'
import CreditCard from './creditCard'
import Verification from './verifications'
import ChangePassword from './password'
import TwoFactorAuthentication from './authentication'
import Breadcrumb from '../../layout/breadcrumb'

import UserService from '../../network/services/user'
// import TransferModal from '../dashboard/transfer_asic_modal'
import { useTranslation } from 'react-i18next'



const User = () => {
  const { t } = useTranslation()
  const tabs = {
    details: t("user.PersonalInformation"),
    bank: t("user.BankDetails"),
    creditCard: t("user.CreditCardDetails"),
    verification: t("user.Verification"),
    password: t("user.ChangePassword"),
    authentication: t("user.TwoFactorAuthentication")
  }

  let location = useLocation()
  let history = useHistory()
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('details')

  // const user = useSelector((state) => state.user.user)
  // const [showTransfer, setShowTransfer] = useState(true)
  // const asicTransfer = useSelector((state) => state.user.asic_transfer)


  const init = async () => {
    const result = await UserService.getMyself()
    // console.log(result)

    dispatch({ type: 'SET_USER', user: result.user })
    dispatch({ type: 'SET_PROFILE', profile: result.profile ?? {} })

    if (result.partners) {
      dispatch({ type: 'SET_AGENT', partners: result.partners })
    }

    // if (result.bank) {
    //   dispatch({ type: 'SET_BANK', bank: result.bank })
    // }

    // if (result.banks) {
    //   dispatch({ type: 'SET_BANKS', banks: result.banks })
    // }

    if (result.verification) {
      dispatch({ type: 'SET_VERIFICATIONS', verifications: result.verifications })
    }

    if (result.user?.auth_required) {
      dispatch({ type: 'SET_TWOFA', twoFA: result.user.auth_required })
    }
  }

  const setTab = (val) => {
    history.push('#' + val)
  }

  useEffect(() => {
    if (!isEmpty(location.hash)) {
      setActiveTab(location.hash.replace('#', ''))
    } else {
      setActiveTab('details')
    }
  }, [location])

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Breadcrumb parent={t("user.UserProfile")} title={tabs[activeTab]} />
      <Container>
        <Row>
          <Col sm="12" lg="4" xl="3" className="project-list">
            <Card>
              <Row>
                <Col>
                  <Nav tabs className="border-tab">
                    <NavItem>
                      <NavLink
                        className={activeTab === 'details' ? 'active' : ''}
                        onClick={() => setTab('details')}
                      >
                        <i className="icofont icofont-wallet"></i>
                        {t("user.PersonalInformation")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'bank' ? 'active' : ''}
                        onClick={() => setTab('bank')}
                      >
                        <i className="icofont icofont-bank "></i>
                        {t("user.BankDetails")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'creditCard' ? 'active' : ''}
                        onClick={() => setTab('creditCard')}
                      >
                        <i className="icofont icofont-credit-card"></i>
                        {t("user.CreditCardDetails")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'verification' ? 'active' : ''}
                        onClick={() => setTab('verification')}
                      >
                        <i className="icofont icofont-ui-check"></i>
                        {t("user.Verification")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'password' ? 'active' : ''}
                        onClick={() => setTab('password')}
                      >
                        <i className="icofont icofont-ui-password"></i>
                        {t("user.ChangePassword")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'authentication' ? 'active' : ''}
                        onClick={() => setTab('authentication')}
                      >
                        <i className="icofont icofont-ui-lock"></i>
                        {t("user.TwoFactorAuthentication")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </Card>
          </Col>

          {/* {console.log('user', user)} */}
          {/* {asicTransfer === false ?
            <Modal isOpen={showTransfer} backdrop={true} centered={true} id='modal_asic' style={{ maxWidth: '90%' }}>
              <ModalHeader
                style={{
                  backgroundColor: 'rgb(15, 60, 90)',
                  color: 'white',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                Important: Update to Your Trading Account
          </ModalHeader>
              <ModalBody>
                <TransferModal isModalShow={showTransfer} />

              </ModalBody>
            </Modal>
            :
            ''
          } */}

          <Col sm="12" lg="8" xl="9">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="details">
                <UserDetail />
              </TabPane>
              <TabPane tabId="bank">
                <BankDetail />
              </TabPane>
              <TabPane tabId="creditCard">
                <CreditCard />
              </TabPane>
              <TabPane tabId="verification">
                <Verification />
              </TabPane>
              <TabPane tabId="password">
                <ChangePassword />
              </TabPane>
              <TabPane tabId="authentication">
                <TwoFactorAuthentication />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default User
