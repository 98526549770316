import React from "react"
import { Button } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"

import { TOGGLE_MONITORING_PRESENTATION } from "../../redux/actionTypes"
import { useTranslation } from 'react-i18next'

const SwitchButton = ({ options }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isGraphical = useSelector((state) => state.monitoring.isGraphical)

  return (
    <>
      <Button
        color="primary"
        outline={isGraphical ? false : true}
        onClick={() => {
          if (!isGraphical) {
            dispatch({ type: TOGGLE_MONITORING_PRESENTATION })
          }
        }}
      >
        {t("monitoring.graphical")}
      </Button>
      <Button
        color="primary"
        outline={!isGraphical ? false : true}
        onClick={() => {
          if (isGraphical) {
            dispatch({ type: TOGGLE_MONITORING_PRESENTATION })
          }
        }}
        style={{ marginLeft: 8 }}
      >
        {t("monitoring.transaction")}
      </Button>
    </>
  )
}

export default SwitchButton
