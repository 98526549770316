import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import WalletService from '../../network/services/wallet'
import PlanService from '../../network/services/plan'
import { useTranslation } from 'react-i18next'

const LiveAccountList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const account = useSelector((state) => state.live.account)
  // const [walletResult, setWalletResult] = useState(null)
  const [accounts, setAccounts] = useState(null)
  const [totalDepositAndTransfer, setTotalDepositAndTransfer] = useState(0)

  const init = async () => {
    // live accounts
    const result = await PlanService.getAll({
      filters: {
        account_type: 0
      }
    })
    const walletResponse = await WalletService.get()
    dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })
    // setWalletResult(walletResponse)
    const totalDepositAndTransfer =
      walletResponse?.total_deposit + walletResponse?.total_transfer_to_me
    setTotalDepositAndTransfer(totalDepositAndTransfer)
    // console.log(result.plans)
    setAccounts(result.plans)

    if (result.plans?.length > 0) {
      let defaultPlan = result.plans[0]
      for (let i = 0; i < result.plans?.length; i++) {
        if (totalDepositAndTransfer >= result.plans[i].min_deposit) {
          defaultPlan = result.plans[i]
        }
      }
      dispatch({ type: 'SET_LIVE_ACCOUNT', account: defaultPlan })
    }
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="text-center">
      <Row>
        {accounts != null ? (
          accounts?.length > 0 &&
          accounts.map((item, index) => {
            return (
              <Col xl="3 xl-50" md="4" key={item.id}>
                <Card
                  className="card-absolute"
                  key={index}
                  onClick={() => {
                    dispatch({ type: 'SET_LIVE_ACCOUNT', account: item })
                  }}
                  style={{
                    borderColor:
                      account?.id === item.id && totalDepositAndTransfer >= item.min_deposit
                        ? '#f9b600'
                        : null,
                    backgroundColor:
                      totalDepositAndTransfer >= item.min_deposit ? 'white' : '	#D3D3D3'
                  }}
                >
                  <CardHeader
                    // className="bg-primary"
                    style={{
                      borderBottomWidth:
                        totalDepositAndTransfer >= item.min_deposit ? '1px' : '0px',
                      backgroundColor:
                        totalDepositAndTransfer >= item.min_deposit ? '#f9b600' : '#808080',
                      color: 'white'
                    }}
                  >
                    <h5>{item.name}</h5>
                  </CardHeader>
                  <CardBody className="p-3">
                    <div
                      style={{
                        color:
                          totalDepositAndTransfer >= item.min_deposit
                            ? 'rgba(43,43,43,1)'
                            : 'rgba(43,43,43,0.7)'
                      }}
                    >
                      <p>
                        {t('live.minimumDeposit')} {item.min_deposit}
                      </p>
                      <p>{item.description}</p>
                      <p>
                        {t('live.commission')} {item.commission}{' '}
                      </p>
                      <p>{item.spread}</p>
                      {/* <p>Max Leverage {item.leverage}</p> */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )
          })
        ) : (
          <Col>
            <Card>
              <CardBody>
                <Spinner color="warning" />
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default LiveAccountList
